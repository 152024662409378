import React, {useState} from "react";
import {Box, Chip} from "@material-ui/core";
import clsx from "clsx";
import CountryModel from "../../models/country.model";
import {useTranslation} from "react-i18next";
import {
    countriesHaveAllIds,
    eeaChCountryIds,
    euCountryIds,
    getCountriesByIds,
    getRegion,
    removeCountries,
    worldIds
} from "../../services/countries";
import {getCountryFlag} from "../../util/flag.util";
import CountrySearch from "./CountrySearch/CountrySearch";
import EButton from "./EButton";
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from "@material-ui/core/styles";

interface Props {
    countries?: CountryModel[]
    onRemoveCountry: (country: CountryModel) => void
    onAddCountry: (country: CountryModel) => void
    addTitle: string;
}

const useStyle = makeStyles(theme => ({
    flag: {
        borderRadius: '50%',
        backgroundSize: 'cover !important'
    },
    multiselectChip: {
        margin: 5
    }
}))

const CountrySelector = (props: Props) => {

    const [countrySearchOpen, setCountrySearchOpen] = useState(false)
    const classes = useStyle()

    const t = useTranslation().t

    const renderNationalities = () => {
        let nationalities = (props.countries && [...props.countries]) || []

        if (countriesHaveAllIds(nationalities, worldIds)) {
            nationalities.unshift(getRegion('world')!);
            nationalities = removeCountries(nationalities, getCountriesByIds(worldIds))
        } else if (countriesHaveAllIds(nationalities, eeaChCountryIds)) {
            nationalities.unshift(getRegion('eeach')!);
            nationalities = removeCountries(nationalities, getCountriesByIds(eeaChCountryIds))
        } else if (countriesHaveAllIds(nationalities, euCountryIds)) {
            nationalities.unshift(getRegion('eu')!);
            nationalities = removeCountries(nationalities, getCountriesByIds(euCountryIds))
        }

        return nationalities.map((country: any) => (
            <Chip
                key={country.id}
                avatar={<div className={clsx(classes.flag, 'flag-icon', getCountryFlag(country))}/>}
                label={country.name}
                size="small"
                onDelete={() => props.onRemoveCountry(country)}
                className={classes.multiselectChip}
            />
        ))
    };

    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            flexWrap={'wrap'}
        >

            {renderNationalities()}

            <EButton
                onClick={() => setCountrySearchOpen(true)}
            >
                <AddIcon/>
                {props.addTitle}
            </EButton>

            <CountrySearch
                open={countrySearchOpen}
                onCountryPicked={(country) => {
                    props.onAddCountry(country);
                    setCountrySearchOpen(false);
                }}
                onClose={() => setCountrySearchOpen(false)}
                title={t('select_country')}
                showRegions
            />

        </Box>
    )
}

export default CountrySelector
