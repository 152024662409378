import {makeStyles} from "@material-ui/core/styles";
import React, {Fragment} from "react";
import {Link} from "@material-ui/core";

export const useAuthCommonStyles = makeStyles(_theme => ({
    component: {
        width: '100%',
        minHeight: '100vh',
        position: "relative"
    },
    coverBackgroundHolder: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        minHeight: '100vh',
    },
    coverBackground: {
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        objectFit: 'cover',
    },
    logo: {
        width: 122,
        height: 26,
        objectFit: 'contain',
        marginTop: 30,
        zIndex: 1,
        cursor: 'pointer'
    },
    title: {
        fontWeight: 300,
        fontSize: 24,
        textAlign: 'center',
        color: '#FFF',
        textTransform: 'capitalize',
        zIndex: 1,
        marginTop: 'auto',
        marginBottom: 20,
        [_theme.breakpoints.up('md')]: {
            marginTop: 'auto',
            fontSize: 36,
            marginBottom: 35
        }
    },
    input: {
        width: '100%'
    },
    inputHalf: {
        width: '100%',
        [_theme.breakpoints.up('md')]: {
            width: '48%'
        }
    },
    error: {
        color: 'red',
        marginTop: 20,
        fontSize: 17
    },
    link: {
        textDecoration: 'none',
        marginTop: 20,
        color: 'grey',
        ['&:hover, &:active, &:visited']: {
            textDecoration: 'none',
            color: 'grey'
        }
    }
}))

export const Background = () => {
    const commonClasses = useAuthCommonStyles()

    return (
        <picture className={commonClasses.coverBackgroundHolder}>
            <source
                srcSet={require('../../assets/images/landing/cover-background.webp').default}
                type="image/webp"
                className={commonClasses.coverBackground}
            />
            <img
                src={require('../../assets/images/landing/cover-background.jpg').default}
                className={commonClasses.coverBackground}
                alt={''}
            />
        </picture>
    )
}

interface AuthCommonProps {
    title?: string
}

export const AuthCommon = ({title}: AuthCommonProps) => {
    const commonClasses = useAuthCommonStyles()

    return (
        <Fragment>
            <Background/>

            <Link href={'/'} style={{zIndex: 1}}>
                <img alt={'Logo'} className={commonClasses.logo}
                     src={require('../../assets/images/icons/logo-white-normal.svg').default}/>
            </Link>

            {
                title && <div className={commonClasses.title}>
                    {title}</div>

            }
        </Fragment>
    )
}
