import {Box, CircularProgress, makeStyles} from '@material-ui/core';
import React, {useEffect} from 'react';
import {Route, Switch, useHistory, useLocation} from 'react-router';
import AdminHeader from "./components/common/AdminHeader";
import Sidebar from "./components/common/Sidebar";
import {useDispatch} from "react-redux";
import UserList from "./components/user/UsersList.component";
import UserDetails from "./components/user/details/UsersDetails.component";
import JobsList from "./components/job/JobsList.component";
import ClubList from "./components/club/ClubsList.component";
import ClubDetails from "./components/club/details/ClubsDetails.component";
import ClubRequestList from "./components/clubRequest/ClubRequestList.component";
import JobDetails from "./components/job/details/JobDetails.component";
import ClubRequestDetails from "./components/clubRequest/details/ClubRequestDetails.component";
import B2C from "./components/b2c/B2C.component";
import Algorithms from "./components/algorithms/Algorithms.component";
import AlgoBug from "./components/algobug/AlgoBugcomponent";
import IndexPage from "./components/landing";
import Privacy from "./components/landing/Privacy";
import TermsOfService from "./components/landing/TermsOfService";
import Imprint from "./components/landing/Imprint";
import {useAuthState, useUser} from "./store/store.utils";
import Login from "./components/auth/Login.component";
import RegistrationFindClub from "./components/auth/RegistrationFindClub";
import RegistrationClubDetails from "./components/auth/RegistrationClubDetails";
import JobsClub from "./components/dashboard/JobsClub";
import {initTokenAuth} from "./store/actions/authActions";
import {isPhAdmin} from "./util/profile.util";
import ClubHeader from "./components/common/ClubHeader";
import ClubAccount from "./components/account/ClubsAccount.component";
import PlayerApplicantInfo from "./components/user/PlayerApplicantInfo.component";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword/ResetPassword";
import UserClubPreview from "./components/club/UserClubPreview.component";
import ClubPublicPreview from "./components/club/ClubPublicPreview.component";
import UserPublicPreview from "./components/user/UserPublicPreview.component";
import UserData from "./components/landing/UserData";
import PrintJobs from "./components/job/PrintJobs.component";
import DownloadApp from "./components/auth/DownloadApp";
import HelpPage from "./components/landing/HelpPage";

const useStyle = makeStyles(theme => ({
    navLink: {
        padding: 20,
        textDecoration: 'none',
        '&:hover, &:visited': {
            textDecoration: 'none'
        },
        color: 'white'
    },
    navLinkActive: {
        borderBottom: '3px solid white'
    },
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.default,
        // padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        minHeight: `calc(100vh - ${theme.spacing(4)}px)`
    },
}))

export const App = () => {

    const classes = useStyle()
    const history = useHistory()
    const location = useLocation()
    const user = useUser()
    const dispatch = useDispatch()
    const authState = useAuthState()
    const isLoggedIn = !!user

    useEffect(() => {
        dispatch(initTokenAuth(history))
    }, [])

    if (authState.loading && !authState.tokenValidationDone) {
        return <CircularProgress/>
    }

    return (
        <Box display={'flex'}>

            {isLoggedIn && isPhAdmin(user) && <Sidebar/>}

            {isLoggedIn && <Box className={'hide-on-print'} style={{width: 20}} />}

            <Box className={classes.content}>
                {isLoggedIn && isPhAdmin(user) && <AdminHeader/>}
                {isLoggedIn && !isPhAdmin(user) && !location.pathname.startsWith("/auth") && <ClubHeader/>}

                <Switch>

                    {/* Auth section */}
                    <Route path={'/auth/login'} component={Login}/>
                    <Route path={'/auth/club'} component={RegistrationFindClub}/>
                    <Route path={'/auth/details'} component={RegistrationClubDetails}/>
                    <Route path={'/auth/forgot-password'} component={ForgotPassword}/>
                    <Route path={'/auth/download-app'} component={DownloadApp}/>
                    <Route path={'/resetPassword'} component={ResetPassword}/>

                    {/* Admin */}
                    <Route path={'/admin/user/:id'} component={UserDetails}/>
                    <Route path={'/admin/user'} component={UserList}/>
                    <Route path={'/admin/b2c'} component={B2C}/>
                    <Route path={'/admin/algorithms'} component={Algorithms}/>
                    <Route path={'/admin/algobug'} component={AlgoBug}/>
                    <Route path={'/admin/clubRequest/:id'} component={ClubRequestDetails}/>
                    <Route path={'/admin/clubRequest'} component={ClubRequestList}/>
                    <Route path={'/admin/club/:id'} component={ClubDetails}/>
                    <Route path={'/admin/club'} component={ClubList}/>
                    <Route path={'/admin/job/:id'} component={JobDetails}/>
                    <Route path={'/admin/job'} component={JobsList}/>
                    <Route path={'/admin/printJobs'} component={PrintJobs}/>

                    {/* Club */}
                    <Route path={'/club/dashboard'} component={JobsClub}/>
                    <Route path={'/club/job/:jobId/application/:id'} component={PlayerApplicantInfo}/>
                    <Route path={'/club/job/:id'} component={JobDetails}/>
                    <Route path={'/club/account'} component={ClubAccount}/>
                    <Route path={'/club/about'} component={UserClubPreview}/>
                    <Route path={'/club/:id'} component={ClubPublicPreview}/>
                    <Route path={'/club/:id/:name'} component={ClubPublicPreview}/>

                    {/* Player */}
                    <Route path={'/user/:id'} component={UserPublicPreview}/>
                    <Route path={'/player/:id'} component={UserPublicPreview}/>
                    <Route path={'/player/:id/:name'} component={UserPublicPreview}/>

                    {/* Common & public */}
                    <Route path={'/imprint'} component={Imprint}/>
                    <Route path={'/terms-of-services'} component={TermsOfService}/>
                    <Route path={'/privacy'} component={Privacy}/>
                    <Route path={'/user-data'} component={UserData}/>
                    <Route path={'/help'} component={HelpPage}/>
                    <Route path={'/'} component={IndexPage}/>


                </Switch>
            </Box>

            {isLoggedIn && <Box className={'hide-on-print'} style={{width: 20}} />}

        </Box>
    );
}

export default App;
