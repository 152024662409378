import UserModel, {AgencyPosition} from "../../models/user.model";
import {
    ADD_SKILL_MEDIA,
    AUTH_IN_PROGRESS,
    LOGOUT,
    SET_AUTH_AGENCY_POSITION,
    SET_AUTH_CLUB,
    SET_AUTH_EMAIL,
    SET_AUTH_FIRST_NAME,
    SET_AUTH_LAST_NAME,
    SET_AUTH_PASSWORD,
    SET_AUTH_PHONE,
    SET_CLUB_UPDATE_IN_PROGRESS,
    SET_AUTH_ERROR,
    SET_LOGIN_OPEN,
    SET_LOGIN_TYPE,
    SET_PROFILE_UPDATE_IN_PROGRESS,
    SET_REGISTRATION_BASIC_INFO,
    SET_TOKEN_VALIDATION_DONE,
    SET_USER,
    SET_USER_PROFILE
} from "../actions/actionTypes";
import ClubModel from "../../models/club.model";

export enum AuthError {
    GENERIC = 'GENERIC',
    USER_NOT_CLUB = 'USER_NOT_CLUB',
    PASSWORD_RESET_GENERIC = 'PASSWORD_RESET_GENERIC',
}

export interface AuthState {
    open: boolean;
    loading: boolean,
    user?: UserModel,
    loginType: string;
    email: string;
    password: string;
    tokenValidationDone: boolean;
    authError?: AuthError;
    profileUpdateInProgress: boolean;
    clubUpdateInProgress: boolean;
    club?: ClubModel;
    agencyPosition?: AgencyPosition;
    phone?: string;
    firstName?: string;
    lastName?: string;
    utm?: any[]
}

const initialState: AuthState = {
    open: false,
    loading: false,
    loginType: '',
    email: '',
    password: '',
    tokenValidationDone: false,
    profileUpdateInProgress: false,
    clubUpdateInProgress: false,
    phone: '',
    firstName: '',
    lastName: ''
};

const authReducer = (state = initialState, action: any): AuthState => {
    switch (action.type) {
        case SET_LOGIN_OPEN:
            return {
                ...state,
                open: action.payload
            };
        case SET_AUTH_EMAIL:
            return {
                ...state,
                email: action.payload
            };
        case SET_AUTH_PASSWORD:
            return {
                ...state,
                password: action.payload
            };
        case SET_AUTH_AGENCY_POSITION:
            return {
                ...state,
                agencyPosition: action.payload
            };
        case AUTH_IN_PROGRESS:
            return {
                ...state,
                loading: action.payload
            };
        case SET_AUTH_ERROR:
            return {
                ...state,
                authError: action.payload
            };
        case SET_USER:
            return {
                ...state,
                user: action.payload
            };
        case SET_AUTH_CLUB:
            return {
                ...state,
                club: action.payload
            };
        case SET_AUTH_PHONE:
            return {
                ...state,
                phone: action.payload
            };
        case ADD_SKILL_MEDIA:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: {
                        ...state.user?.profile,
                        skillMedia: [...(state.user?.profile?.skillMedia || []), action.payload]
                    }
                }
            };
        case SET_USER_PROFILE:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: action.payload
                }
            };
        case SET_PROFILE_UPDATE_IN_PROGRESS:
            return {
                ...state,
                profileUpdateInProgress: action.payload || false
            }
        case SET_CLUB_UPDATE_IN_PROGRESS:
            return {
                ...state,
                clubUpdateInProgress: action.payload || false
            }
        case SET_LOGIN_TYPE:
            return {
                ...state,
                loginType: action.payload
            };
        case SET_TOKEN_VALIDATION_DONE:
            return {
                ...state,
                tokenValidationDone: true
            };

        case SET_REGISTRATION_BASIC_INFO:
            return {
                ...state,
                ...action.payload
            }

        case LOGOUT:
            return {
                ...state,
                user: undefined,
                loginType: '',
                loading: false,
                open: false,
                email: '',
                password: '',
                tokenValidationDone: true
            };
    }
    return state;
};

export default authReducer;

