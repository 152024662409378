import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {createHttpLink} from 'apollo-link-http';
import {setContext} from "apollo-link-context";
import {v4 as uuidv4} from 'uuid';

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?([+-][0-2]\d:[0-5]\d)?Z?$/

function isIsoDateString(value: any): boolean {
    return value && typeof value === "string" && isoDateFormat.test(value)
}

export function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== "object")
        return body

    for (const key of Object.keys(body)) {
        const value = body[key]
        if (isIsoDateString(value)) body[key] = new Date(value)
        else if (typeof value === "object") handleDates(value)
    }
}


const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_ENDPOINT
});

const createAuthLink = () => {
    return setContext((_, {headers}) => {
        const token = localStorage.getItem('token')
        const userId = localStorage.getItem('userId')
        return {
            headers: {
                ...headers,
                authorization: token ? `${token}` : '',
                correlationId: uuidv4(),
                userId: userId || ''
            }
        }
    });
}

export const createAuthLinkExplicit = (token: string) => {
    return setContext((_, {headers}) => {
        return {
            headers: {
                ...headers,
                authorization: token,
                correlationId: uuidv4()
            }
        }
    });
}

export const createApolloClient = (authLink: any) => {
    return new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache({
            addTypename: false
        }),
        defaultOptions: {
            query: {
                fetchPolicy: 'network-only'
            }
        }
    })
}

const client = createApolloClient(createAuthLink());
export default client;
