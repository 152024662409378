import ClubModel from "../models/club.model";
import AlbumModel from "../models/album.model";
import UserModel, {UserTypeId} from "../models/user.model";
import {getEmail} from "../util/profile.util";

export const getClubLogo = (club?: ClubModel, defaultValue?: any) => {
    return club?.logo || defaultValue;
};

export const getClubName = (club?: ClubModel, defaultValue?: any) => {
    return club?.name || defaultValue;
};

export const getClubCityName = (club?: ClubModel, defaultValue?: any) => {
    return club?.location?.name || club?.city?.name || defaultValue
};

export const getClubCountryName = (club?: ClubModel, defaultValue?: any) => {
    return club?.location?.country?.name || club?.country?.name || defaultValue
};

export const getClubLocation = (club?: ClubModel, defaultValue?: any) => {
    const location = [];
    location.push(getClubCityName(club));
    location.push(getClubCountryName(club));
    const locationJoined = location.filter(i => !!i).join(', ');
    return locationJoined || defaultValue;
};

export const getClubGeoCoordinates = (club?: ClubModel, defaultValue?: any): { latitude: number, longitude: number } => {
    if (club?.location?.location?.latitude && club?.location?.location?.longitude) {
        return {
            latitude: club?.location?.location?.latitude,
            longitude: club?.location?.location?.longitude
        }
    }

    return club?.city?.latitude && club?.city?.longitude && {
        latitude: club.city.latitude,
        longitude: club.city.longitude
    } || defaultValue;
};

export const getClubAlbums = (club?: ClubModel, defaultValue?: AlbumModel[]): AlbumModel[] | undefined => {
    return club?.albums?.content || defaultValue;
};

export const getClubAlbumImages = (album?: AlbumModel, defaultValue?: any) => {
    return album?.media?.content || defaultValue;
};

export const getClubAbout = (club?: ClubModel, defaultValue?: any) => {
    return club?.about || defaultValue;
};

export function isUserClubApprovedAdmin(user?: UserModel, club?: ClubModel): boolean {
    return user?.userType?.id === UserTypeId.ADMIN_TYPE_ID || !!club?.clubAdmins?.find(admin => admin?.user?.email?.toLowerCase()?.trim() === getEmail(user)?.toLowerCase()?.trim())?.approved
}

export function isUserApprovedAndClubApproved(user?: UserModel, club?: ClubModel): boolean {
    return !club?.isRequest && isUserClubApprovedAdmin(user, club)
}