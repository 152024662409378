import SubscriptionConfigAdmin from "../models/subscriptionConfigAdmin.model";
import apollo from "./api/apollo";
import {GET_ADMIN_SUBSCRIPTION_CONFIG, UPDATE_ADMIN_SUBSCRIPTION_CONFIG} from "./api/b2c.queries";

export default class B2cService {

    static getAdminConfig = async (userTypeId: number): Promise<SubscriptionConfigAdmin | undefined> => {
        try {
            const response = await apollo.query({
                query: GET_ADMIN_SUBSCRIPTION_CONFIG,
                variables: {
                    userType: {
                        id: userTypeId
                    }
                }
            });

            return response?.data?.subscriptionConfig;
        } catch (error) {
            console.error(error);
        }
    }

    static saveAdminConfig = async (subscriptionConfig: SubscriptionConfigAdmin): Promise<SubscriptionConfigAdmin | undefined> => {
        try {
            const response = await apollo.mutate({
                mutation: UPDATE_ADMIN_SUBSCRIPTION_CONFIG,
                variables: {
                    subscriptionConfig
                }
            });

            return response?.data?.subscriptionConfig;
        } catch (error) {
            console.error(error);
        }
    }
}