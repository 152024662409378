import React, {useEffect, useState} from 'react'
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe,} from '@stripe/react-stripe-js'
import SubscriptionService from "../../services/subscription.service"
import {Box, Button, Card, CircularProgress, FormControlLabel, Switch, TextField} from "@material-ui/core"
import {PaymentMethodModel} from "../../models/StripeModels/paymentMethod.model"
import {useTranslation} from "react-i18next"
import Dialog from "@material-ui/core/Dialog";
import {makeStyles} from "@material-ui/styles";
import {useUser} from "../../store/store.utils";
import AuthService from "../../services/auth.service";

const ELEMENT_OPTIONS = {
    style: {
        base: {
            border: '1px solid #D8D6DE',
            borderRadius: '4px',
            fontSize: '18px',
            color: 'rgba(0,0,0, 0.87)',
            letterSpacing: '0.025em',
            '::placeholder': {
                color: '#D8D6DE',
            },
        },
        invalid: {
            color: '#ff0000',
        },
    },
}

const useStyle = makeStyles({
    container: {
        // width: '100%',
        width: 300
    },
    title: {
        margin: '20px 20px 0 20px',
        textAlign: 'center',
        fontSize: 20
    },
    content: {
        maxWidth: 500,
        width: '100%',
        minHeight: 300
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    label: {
        color: '#6b7c93',
        fontWeight: 300,
        letterSpacing: '0.025em',
        marginTop: 20,
        marginBottom: 10,
        textTransform: 'capitalize'
    },
    nameInput: {
        boxShadow: 'rgba(50, 50, 93, 0.14902) 0px 1px 3px',
    },
    primaryMethodSwitch: {
        marginTop: 10
    },
    actionButtons: {
        width: '100%'
    },
    button: {},
    error: {
        width: '100%',
        marginTop: 20,
        color: 'red',
        textAlign: 'center'
    }
})

interface Props {
    open: boolean
    onDone: (added: boolean) => void
    onExistingMethodsLoaded?: (paymentMethods: PaymentMethodModel[]) => void
}

const AddPaymentMethod = (props: Props) => {

    //region State

    const user = useUser()
    const club = AuthService.getAdminClub(user)
    const stripe = useStripe()
    const elements = useElements()
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodModel[]>([])
    const [fetchingPaymentsInProgress, setFetchingPaymentsInProgress] = useState(false)
    const [useAsDefaultOnSubmit, setUseAsDefaultOnSubmit] = useState(false)
    const [name, setName] = useState('')
    const [addingState, setAddingState] = useState<'adding' | 'error' | ''>('')
    const translation = useTranslation()
    const t = translation.t
    const classes = useStyle()
    const isAdding = addingState === 'adding'

    //endregion State

    //region Handlers

    const getPaymentMethods = async () => {
        setFetchingPaymentsInProgress(true)
        try {
            const response = await SubscriptionService.getStripePaymentMethods(club)
            setPaymentMethods(response || [])
            props.onExistingMethodsLoaded?.(response || [])
            // setShowNewPaymentInput(!response?.length)
        } catch (e) {
            console.error(e)
        } finally {
            setFetchingPaymentsInProgress(false)
        }
    }

    useEffect(() => {
        if (club && props.open) {
            getPaymentMethods()
        }
    }, [user, props.open])

    const handleSubmit = async (event: any) => {
        if (!stripe || !elements || !club?.id) {
            return
        }
        event.preventDefault()
        setAddingState('adding')

        const card = elements.getElement(CardNumberElement)
        if (card === null) {
            setAddingState('error')
            return
        }

        const response = await SubscriptionService.getStripeSetupIntent(club)

        if (!response?.clientSecret) {
            setAddingState('error')
            return
        }

        const cardSetupIntent = await stripe.confirmCardSetup(
            response?.clientSecret,
            {
                payment_method: {
                    card: card,
                    billing_details: {
                        name: name,
                        // address: {
                        //     postal_code: postal
                        // }
                    }
                }
            },
        )

        if (cardSetupIntent.error || !cardSetupIntent.setupIntent?.payment_method) {
            setAddingState('error')
            return
        }

        if (useAsDefaultOnSubmit || !paymentMethods?.length) {
            try {
                await SubscriptionService.setDefaultPaymentMethod(club, cardSetupIntent.setupIntent?.payment_method)
            } catch (e) {
                console.log(e)
            }
        }

        setAddingState('')

        props.onDone(true)
    }

    //endregion Handlers

    //region UI

    const renderPaymentForm = () => {
        return (
            <form onSubmit={handleSubmit} className={classes.form}>
                <label htmlFor="name" className={classes.label}>{t('Full name')}</label>
                <TextField
                    className={classes.nameInput}
                    id="name"
                    variant={'outlined'}
                    size={'small'}
                    required
                    placeholder="John Doe"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                />
                <label htmlFor="cardNumber" className={classes.label}>{t('Card number')}</label>
                <CardNumberElement
                    id="cardNumber"
                    options={{
                        ...ELEMENT_OPTIONS,
                        showIcon: true
                    }}
                />
                <label htmlFor="expiry" className={classes.label}>{t('Card expiration')}</label>
                <CardExpiryElement
                    id="expiry"
                    options={{
                        ...ELEMENT_OPTIONS,
                        placeholder: t('Card expiration placeholder')
                    }}
                />
                <label htmlFor="cvc" className={classes.label}>CVC</label>
                <CardCvcElement
                    id="cvc"
                    options={ELEMENT_OPTIONS}
                />
                {/*<label htmlFor="postal" className={styles.label}>{t('Postal code')}</label>*/}
                {/*<input*/}
                {/*    className={styles.input}*/}
                {/*    id="postal"*/}
                {/*    required*/}
                {/*    placeholder="12345"*/}
                {/*    value={postal}*/}
                {/*    onChange={(e) => {*/}
                {/*        setPostal(e.target.value)*/}
                {/*    }}*/}
                {/*/>*/}

                {
                    !!paymentMethods?.length &&
                    <FormControlLabel
                        className={classes.primaryMethodSwitch}
                        control={<Switch
                            color={'primary'}
                            checked={useAsDefaultOnSubmit}
                            onChange={event => setUseAsDefaultOnSubmit(event.target.checked)}
                        />}
                        label={t('Set as default payment method')}
                    />
                }

                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    marginTop={5}
                >
                    {
                        addingState === 'adding' ?
                            <CircularProgress/> :
                            <Box
                                className={classes.actionButtons}
                                display={'flex'}
                                justifyContent={'space-between'}
                            >
                                <Button
                                    disabled={isAdding}
                                    className={classes.button}
                                    variant={'text'}
                                    color={'secondary'}
                                    onClick={() => props.onDone(false)}
                                >
                                    {t('Cancel')}
                                </Button>


                                <Button
                                    type="submit"
                                    disabled={!stripe}
                                    className={classes.button}
                                    variant={'contained'}
                                    color={'primary'}
                                >
                                    {t('Add')}
                                </Button>
                            </Box>
                    }
                </Box>


                {
                    addingState === 'error' &&
                    <div className={classes.error}>{t('Error while adding payment method')}</div>
                }

            </form>
        )
    }

    return (
        <Dialog
            onBackdropClick={() => !isAdding && props.onDone(false)}
            open={props.open}
            onClose={() => !isAdding && props.onDone(false)}
            maxWidth={false}
        >
            <Card className={classes.container}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} p={2}>

                    {
                        !fetchingPaymentsInProgress &&
                        <div className={classes.title}>{t('Add new payment method')}</div>
                    }

                    <Box
                        p={2}
                        className={classes.content}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        {
                            fetchingPaymentsInProgress ? <CircularProgress/> : renderPaymentForm()
                        }
                    </Box>
                </Box>
            </Card>
        </Dialog>
    )

    //endregion UI
}

export default AddPaymentMethod
