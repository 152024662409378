import React from 'react';
import styles from './PastClubs.module.scss';
import UserModel from "../../../../models/user.model";
import {format} from "date-fns";
import {useTranslation} from "react-i18next";
import {getPreviousClubLogo, getPreviousClubName, getPreviousClubs} from "../../../../util/profile.util";
import {Box} from "@material-ui/core";

interface Props {
    player?: UserModel
}

const PastClubs = (props: Props) => {

    const t = useTranslation().t;

    const renderClubs = () => {
        return getPreviousClubs(props.player, []).map(previousClub => {
            return (
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    className={styles.club}
                >
                    <div className={styles.clubBadge}>
                        <img alt={'Club logo'} src={require('../../../../assets/images/club-logo-hexagon.svg').default}
                             className={styles.clubImgHolder}/>
                        <img alt={'Previous club logo'}
                             src={getPreviousClubLogo(previousClub, require('../../../../assets/images/fake-club.svg').default)}
                             className={styles.clubImg}/>
                    </div>

                    <Box className={styles.clubInfo}>
                        <p className={styles.clubName}>{getPreviousClubName(previousClub, '-')}</p>
                        {/*<p className={styles.teamName}>Senior Team Men</p>*/}
                        {/*<p className={styles.teamName}>U21</p>*/}
                    </Box>

                    <p className={styles.seasons}>
                        {previousClub.seasonFrom && format(new Date(previousClub.seasonFrom), 'MM/yyyy')}
                        -
                        {previousClub.seasonTo && format(new Date(previousClub.seasonTo), 'MM/yyyy')}
                    </p>
                </Box>
            )
        })
    }

    const renderNoClubs = () => {
        return (
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                className={styles.club}
            >
                <div className={styles.clubBadge}>
                    <img alt={''} src={require('../../../../assets/images/club-logo-hexagon.svg').default}
                         className={styles.clubImgHolder}/>
                    <img alt={'Club logo'} src={require('../../../../assets/images/fake-club.svg').default}
                         className={styles.clubImg}/>
                </div>

                <Box className={styles.clubInfo}>
                    <p className={styles.clubName}>{t('no_past_clubs')}</p>
                </Box>
            </Box>
        )
    }

    return (
        <Box className={styles.container}>
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                className={styles.sectionTitle}
            >
                <p>{t('past_clubs')}</p>
                {/*<p className={styles.edit}>Edit</p>*/}
            </Box>

            {getPreviousClubs(props.player, []).length > 0 ? renderClubs() : renderNoClubs()}
        </Box>
    );
}

export default PastClubs;
