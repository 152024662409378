import React from 'react'
import {Box, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AnimatedBox from "./Animated.component";
import {DIMENSIONS, LANDING_COMMON_COLORS} from "./common";
import {useTranslation} from "react-i18next";

const useStyle = makeStyles(_theme => ({
    component: {
        marginTop: 200,
        width: '100%',
        [_theme.breakpoints.down('md')]: {
            marginTop: 100
        }
    },
    title: {
        fontSize: DIMENSIONS.LARGE.TITLE_SIZE,
        color: LANDING_COMMON_COLORS.TITLE,
        marginBottom: 45,
        textAlign: "center",
        paddingLeft: 25,
        paddingRight: 25,
        [_theme.breakpoints.down('md')]: {
            fontSize: DIMENSIONS.SMALL.TITLE_SIZE
        }
    },
    infoContainer: {},
    stepImageWrapper: {
        height: 500,
    },
    stepImage: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    stepInfoHolder: {
        marginTop: 36
    },
    stepNumberHolder: {
        width: 40,
        minWidth: 40,
        height: 40,
        borderRadius: 20,
        background: _theme.palette.primary.main,
        marginRight: 20,
        [_theme.breakpoints.down('md')]: {
            width: 30,
            minWidth: 30,
            height: 30,
            marginRight: 15
        }
    },
    stepNumber: {
        fontSize: 24,
        lineHeight: '24px',
        textAlign: 'center',
        color: '#FFFFFF',
        [_theme.breakpoints.down('md')]: {
            fontSize: 20,
            lineHeight: '20px'
        }
    },
    stepTitle: {
        fontWeight: 500,
        fontSize: 22,
        lineHeight: '26px',
        color: LANDING_COMMON_COLORS.TITLE,
        [_theme.breakpoints.down('md')]: {
            fontSize: 20,
            lineHeight: '20px'
        }
    }
}))

const HowToUsePh = () => {

    const classes = useStyle()
    const t = useTranslation().t

    const renderStep = (imageJpg: any, imageWebp: any, stepNumber: string, stepTitle: string) => (
        <Grid item xs={12} sm={6} md={3}>
            <AnimatedBox
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}

                fadeInThreshold={30}
                fadeIn
            >
                <picture className={classes.stepImageWrapper}>
                    <source
                        className={classes.stepImage}
                        srcSet={imageWebp}
                    />
                    <img
                        alt={'Step ' + stepNumber}
                        src={imageJpg}
                        className={classes.stepImage}
                    />
                </picture>

                <Box
                    display={'flex'}
                    alignItems={'center'}
                    className={classes.stepInfoHolder}
                >
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        className={classes.stepNumberHolder}
                    >
                        <div className={classes.stepNumber}>{stepNumber}</div>
                    </Box>
                    <div className={classes.stepTitle}>{stepTitle}</div>
                </Box>
            </AnimatedBox>
        </Grid>
    )

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={classes.component}
        >
            <div className={classes.title}>
                {t("How to use Playerhunter?")}
            </div>

            <Grid container spacing={4} className={classes.infoContainer}>
                {renderStep(
                    require('../../assets/images/landing/how-to-use-1.png').default,
                    require('../../assets/images/landing/how-to-use-1.webp').default,
                    '1', t('Register'))}
                {renderStep(
                    require('../../assets/images/landing/how-to-use-2.png').default,
                    require('../../assets/images/landing/how-to-use-2.webp').default,
                    '2', t('Publish your job'))}
                {renderStep(
                    require('../../assets/images/landing/how-to-use-3.png').default,
                    require('../../assets/images/landing/how-to-use-3.webp').default,
                    '3', t('Get relevant applicants'))}
                {renderStep(
                    require('../../assets/images/landing/how-to-use-4.png').default,
                    require('../../assets/images/landing/how-to-use-4.webp').default,
                    '4', t('Relax and enjoy the new way of Scouting & Transfers'))}
            </Grid>

        </Box>
    )
}

export default HowToUsePh
