import React, {useEffect, useState} from 'react'
import Dialog from "@material-ui/core/Dialog"
import {useTranslation} from "react-i18next"
import {Box, Button, Card, CircularProgress, Typography} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"
import SubscriptionService from "../../services/subscription.service";
import {SET_SUBSCRIBING_TO_AMATEUR} from "../../store/actions/actionTypes";
import {useSelector, useStore} from "react-redux";
import {BillingState} from "../../store/reducers/billing.reducer";
import {StripeProduct, StripeProductPrice} from "../../models/StripeModels/stripeProduct.model";

const useStyles = makeStyles(_theme => ({
    container: {
        minHeight: 200,
        width: 350
    },
    messageLarge: {
        fontWeight: 'bold',
        marginBottom: 20
    },
    messageSmall: {
        marginTop: 20
    },
    confirmText1: {
        textAlign: 'center'
    },
    confirmText2: {
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 25
    },
    activateButton: {
        marginLeft: 50
    }
}))

interface Props {
    onConfirm: (product: StripeProduct, price: StripeProductPrice) => void
}

const ActivatingAmateurPopup = (props: Props) => {

    const t = useTranslation().t
    const classes = useStyles()
    const store = useStore()
    const {subscribingToAmateurInProgress} = useSelector<any, BillingState>(store => store.billing)
    const [fetchingInProgress, setFetchingInProgress] = useState(false)
    const [product, setProduct] = useState<StripeProduct>()
    const [price, setPrice] = useState<StripeProductPrice>()

    const getStripeProducts = async () => {
        setFetchingInProgress(true);
        try {
            const response = await SubscriptionService.getStripeProducts();
            const product = response.find(product => product.metadata.package === 'AMATEUR');
            setProduct(product);
            setPrice(product?.prices?.[0])
        } catch (e) {
            console.error(e);
        } finally {
            setFetchingInProgress(false);
        }
    }

    useEffect(() => {
        if (subscribingToAmateurInProgress) {
            getStripeProducts()
        }
    }, [subscribingToAmateurInProgress])

    const renderConfirmState = () => {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                className={classes.container}
                p={4}
            >
                <Typography className={classes.messageLarge}>{t('Subscribe to Amateur package?')}</Typography>

                {
                    !product || !price ?
                        <div>{t('Error loading package')}</div> :
                        <Box display={'flex'}>
                            <Button onClick={() => {
                                store.dispatch({type: SET_SUBSCRIBING_TO_AMATEUR, payload: false})
                            }}>
                                {t('Cancel')}
                            </Button>
                            <Button
                                className={classes.activateButton}
                                onClick={() => props.onConfirm(product, price)}
                                variant={'contained'}
                                color={'primary'}
                            >
                                {t('Activate')}
                            </Button>
                        </Box>
                }

            </Box>
        )
    }

    return (
        <Dialog open={subscribingToAmateurInProgress}>
            <Card>
                <Box
                    className={classes.container}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    {
                        fetchingInProgress ? <CircularProgress/> : renderConfirmState()
                    }
                </Box>
            </Card>

        </Dialog>
    )
}

export default ActivatingAmateurPopup
