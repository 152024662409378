import UserModel, {UserAdminInfo, UserProfile, UserTypeId} from "../models/user.model";
import apollo from "./api/apollo";
import {
    ADMIN_UPDATE_EMAIL,
    ADMIN_UPDATE_PASSWORD,
    GET_PUBLIC_USER,
    GET_SUBUSERS_ADMIN,
    GET_USER, GET_USER_ADMIN_INFO,
    GET_USERS_ADMIN,
    REGISTER_SUBUSER,
    REMOVE_SUBUSER,
    SEND_NOTIFICATION,
    UPDATE_PLAYER_SUBSCRIPTION,
    UPDATE_PROFILE,
    UPDATE_USER, UPDATE_USER_ADMIN_INFO
} from "./api/usersQueries";
import Page from "../models/page.model";
import {UserSubscriptionModel} from "../models/userSubscription.model";
import _ from "lodash";
import {NotificationStatus} from "../models/notificationStatus.model";
import {toDate} from "../util/util";

export default class UserService {

    static convertProfileToWebFormat = (profile?: UserProfile): UserProfile => {
        return {
            ...profile,
            createdAt: toDate(profile?.createdAt),
            currentContractFrom: toDate(profile?.currentContractFrom),
            currentContractTo: toDate(profile?.currentContractTo),
            dateOfBirth: toDate(profile?.dateOfBirth),
            secondaryPositions: profile?.secondaryPositions?.slice(0, 2)
        }
    }

    static convertUserToWebFormat = (user: UserModel): UserModel => {
        return {
            ...user,
            profile: UserService.convertProfileToWebFormat(user.profile),
            subscription: user.subscription && {
                ...user.subscription,
                expirationDate: toDate(user.subscription.expirationDate),
                subscriptionDate: toDate(user.subscription.subscriptionDate)
            }
        }
    }

    static convertProfileToApiFormat = (profile?: UserProfile): UserProfile => {
        const converted: UserProfile = {
            ...profile,
            currentClub: profile?.currentClub && {id: profile.currentClub.id},
            currentClubRequest: profile?.currentClubRequest && {id: profile.currentClubRequest.id},
            previousClubs: profile?.previousClubs?.map(previousClub => {
                return {
                    ...previousClub,
                    club: previousClub.club && {id: previousClub.club?.id},
                    clubRequest: previousClub.clubRequest && {id: previousClub.clubRequest?.id},
                }
            }),
            location: !profile?.googleGeolocation && profile?.location || undefined,
            city: !profile?.googleGeolocation && profile?.city || undefined,
            country: !profile?.googleGeolocation && profile?.country || undefined,
            nationalities: profile?.nationalities?.map(n => {
                const cleanNationality = _.cloneDeep(n);
                delete cleanNationality.nativeName
                return cleanNationality
            }),
            externalUrls: profile?.externalUrls?.filter(url => !!url),
            secondaryPositions: profile?.secondaryPositions?.slice(0, 2)
        }
        delete converted.fullName
        delete converted.skillMedia
        delete converted.completion
        return converted
    }

    static convertUserToApiFormat = (user?: UserModel): UserModel => {
        const converted: UserModel = {...user}
        converted.profile = UserService.convertProfileToApiFormat(user?.profile)
        return converted
    }

    static getUsers = async (paging: any, filter: any): Promise<Page<UserModel>> => {
        let response;
        response = await apollo.query({
            query: GET_USERS_ADMIN,
            variables: {
                paging: paging,
                filter: filter
            }
        });

        const content = response.data?.users?.content?.map(UserService.convertUserToWebFormat) || [];
        return {
            ...response.data?.users,
            content
        }
    };

    static getSubusers = async (paging: any, filter: any): Promise<Page<UserModel>> => {
        let response;
        response = await apollo.query({
            query: GET_SUBUSERS_ADMIN,
            variables: {
                paging: paging,
                filter: filter
            }
        });

        const content = response.data?.subUsers?.content?.map(UserService.convertUserToWebFormat) || [];
        return {
            ...response.data?.subUsers,
            content
        }
    };

    static async getUser(id: number, viaPublic: boolean): Promise<UserModel> {
        const response = await apollo.query({
            query: viaPublic ? GET_PUBLIC_USER : GET_USER,
            variables: {
                id: id
            }
        });

        return this.convertUserToWebFormat(viaPublic ? response.data?.publicProfile : response.data?.user);
    }

    static async updateProfile(userId: number, profile: UserProfile) {
        await apollo.mutate({
            mutation: UPDATE_PROFILE,
            variables: {
                profile: this.convertProfileToApiFormat(profile)
            }
        });

        const user = await UserService.getUser(userId, false)
        return user.profile
    }

    static async updateUser(user: UserModel) {
        const response = await apollo.mutate({
            mutation: UPDATE_USER,
            variables: {
                user
            }
        });

        return response.data.user
    }

    static async getUserAdminInfo(id: number): Promise<UserAdminInfo> {
        const response = await apollo.query({
            query: GET_USER_ADMIN_INFO,
            variables: {
                id: id
            }
        });

        return response.data?.userAdminInfo
    }

    static async updateUserAdminInfo(id: number, userAdminInfo: UserAdminInfo): Promise<UserAdminInfo> {
        const response = await apollo.mutate({
            mutation: UPDATE_USER_ADMIN_INFO,
            variables: {
                id,
                userAdminInfo
            }
        });

        return response.data?.updateUserAdminInfo
    }

    static async adminUpdatePassword(user: UserModel, password: string, resetPasswordOnNextLogin: boolean) {
        const response = await apollo.mutate({
            mutation: ADMIN_UPDATE_PASSWORD,
            variables: {
                userId: user.id,
                password: password,
                resetPasswordOnNextLogin: resetPasswordOnNextLogin
            }
        });

        return response.data.adminUpdatePassword
    }

    static async adminUpdateEmail(user: UserModel, email: string) {
        const response = await apollo.mutate({
            mutation: ADMIN_UPDATE_EMAIL,
            variables: {
                userId: user.id,
                email: email
            }
        });

        return response.data.adminUpdateEmail
    }

    static async updateSubscription(user: UserModel, subscription: UserSubscriptionModel): Promise<UserSubscriptionModel> {
        const response = await apollo.mutate({
            mutation: UPDATE_PLAYER_SUBSCRIPTION,
            variables: {
                subscription: {
                    ...subscription,
                    type: 'PREMIUM',
                    user: {
                        id: user.id,
                        userType: user.userType || {id: UserTypeId.REGULAR_TYPE_ID}
                    },
                }
            }
        });

        const newSubscription = response.data.subscription;

        return {
            ...newSubscription,
            expirationDate: newSubscription.expirationDate && new Date(newSubscription.expirationDate),
            subscriptionDate: newSubscription.subscriptionDate && new Date(newSubscription.subscriptionDate)
        }
    }

    static async sendNotification(status: NotificationStatus, user: UserModel): Promise<string> {
        const response = await apollo.mutate({
            mutation: SEND_NOTIFICATION,
            variables: {
                type: status,
                user: user
            }
        });

        return response.data.notification
    }

    static async registerSubuser(parentId: number, firstName: string, lastName: string): Promise<UserModel> {
        const response = await apollo.mutate({
            mutation: REGISTER_SUBUSER,
            variables: {parentId, firstName, lastName}
        })

        return response.data.registerSubUserAdmin
    }

    static async removeSubuser(userId: number): Promise<UserModel> {
        const response = await apollo.mutate({
            mutation: REMOVE_SUBUSER,
            variables: {userId}
        })

        return response.data.removeSubUser
    }

}

