import React, {useState} from 'react';
import ClubModel from "../../../../models/club.model";
import styles from './ClubHeader.module.scss';
import EditClubNotPossiblePopup from "../EditClubHeaderAndLocationPopup/EditClubNotPossiblePopup";
import EditNamePopup from "./EditNamePopup/EditNamePopup";
import CloudinaryService from "../../../../services/cloudinary.service";
import {Box, Card, CircularProgress} from "@material-ui/core";
import UserModel from "../../../../models/user.model";
import {isPhAdmin, isUserApprovedAndClubApproved, isUserClubAdmin} from "../../../../util/profile.util";

let readAndCompressImage: any;
if (typeof window !== 'undefined') {
    readAndCompressImage = require('browser-image-resizer').readAndCompressImage;
}

interface Props {
    club?: ClubModel,
    user?: UserModel,
    onUpdate?: (club: ClubModel) => void;
}

const ClubHeader = (props: Props) => {

    // ***** State ***** //

    const [notAllowedPopupOpen, setNotAllowedPopupOpen] = useState(false);
    const [editPopupOpen, setEditPopupOpen] = useState(false);
    const [uploading, setUploading] = useState(false);

    // ***** State ***** //

    const closeHandler = () => {
        setNotAllowedPopupOpen(false);
    };

    const editHandler = () => {
        if (isPhAdmin(props.user)) {
            setEditPopupOpen(true);
        } else {
            setNotAllowedPopupOpen(true);
        }
    };

    const saveHandler = (text: string) => {
        setEditPopupOpen(false);
        const club = {
            ...props.club,
            name: text
        };
        props.onUpdate?.(club);
    };

    const onChange = async (e: any) => {
        const files: File[] = Array.from(e.target.files);
        if (!files || !files.length) return;
        setUploading(true);

        let resizedImage = await readAndCompressImage(files[0], {
            quality: 0.8,
            maxWidth: 600,
            maxHeight: 600,
            mimeType: files[0].type
        });

        const cloudinaryResponse = await CloudinaryService.upload(resizedImage);
        const url = cloudinaryResponse.secure_url;

        const club = {
            ...props.club,
            logo: url
        };
        props.onUpdate?.(club);

        setUploading(false);
    };

    // ***** Render ***** //

    return (
        <Card className={styles.component}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                width={'100%'}
            >

                <Box className={styles.logoHolder}>
                    {
                        uploading
                            ?
                            <Box
                                className={styles.logo}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <CircularProgress/>
                            </Box>
                            :
                            <img alt={'Club logo'}
                                 src={props.club?.logo || require('../../../../assets/images/fake-club.svg').default}
                                 className={styles.logo}/>
                    }

                    {
                        isUserClubAdmin(props.user, props.club) &&
                        <React.Fragment>
                            <label htmlFor='clubLogo' className={styles.uploadInputArea}>
                                <img alt={'Edit'} src={require('../../../../assets/images/edit-orange.svg').default}
                                     className={styles.logoEdit}/>
                            </label>
                            <input type='file' id='clubLogo' className={styles.uploadInputField}
                                   accept="image/bmp, image/jpeg, image/jpg, image/png"
                                   onChange={onChange}/>
                        </React.Fragment>
                    }
                </Box>

                <Box display={'flex'} className={styles.name}>
                    {/*<img src={Country}/>*/}
                    <p>{props.club?.name || "Unknown"}</p>
                    {
                        isUserApprovedAndClubApproved(props.user, props.club) &&
                        <img alt={'Edit'} src={require('../../../../assets/images/edit-orange.svg').default}
                             className={styles.nameEdit} onClick={editHandler}/>}
                </Box>

                <EditClubNotPossiblePopup open={notAllowedPopupOpen} onClose={closeHandler}/>

                <EditNamePopup
                    open={editPopupOpen}
                    onClose={() => setEditPopupOpen(false)}
                    onSave={saveHandler}
                    club={props.club}
                />

            </Box>
        </Card>
    );

}

// **** Redux **** //

export default ClubHeader;

