import React, {useCallback, useEffect, useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Box, Slider, Typography} from "@material-ui/core"
import debounce from "lodash/debounce"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";

const AgeFilter = () => {

    //region State

    const [fromAgeParam] = useUrlParam({param: 'fromAge', dataType: 'numeric', defaultValue: 0})
    const [toAgeParam] = useUrlParam({param: 'toAge', dataType: 'numeric', defaultValue: 0})

    const [fromAgeLocal, setFromAgeLocal] = useState(fromAgeParam)
    const [toAgeLocal, setToAgeLocal] = useState(toAgeParam)

    const [expanded, setExpanded] = useState(!!fromAgeLocal && !!toAgeLocal)
    const history = useHistory()

    //endregion State

    //region Handlers

    const executeSet = useCallback(debounce((from: any, to: any) => {
        setMultipleParams([
            {name: 'fromAge', value: from, dataType: 'numeric'},
            {name: 'toAge', value: to, dataType: 'numeric'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }, 1000), [])

    useEffect(() => {
        if (fromAgeLocal && toAgeLocal) {
            executeSet(fromAgeLocal, toAgeLocal)
        }
    }, [fromAgeLocal, toAgeLocal])

    //endregion Handlers

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="age-content"
                id="age-header"
            >
                <Typography>Age {!!fromAgeLocal && !!toAgeLocal && `(${fromAgeLocal} - ${toAgeLocal})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Box display={'flex'} alignItems={'center'} style={{width: '100%'}}>
                    <div>{fromAgeLocal || 13}</div>
                    <Slider
                        style={{width: '100% !important', margin: '0 20px'}}
                        onChange={(_, value) => {
                            setFromAgeLocal((value as number[])[0])
                            setToAgeLocal((value as number[])[1])
                        }}
                        value={[fromAgeLocal || 13, toAgeLocal || 40]}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        min={13}
                        max={40}
                        step={1}
                    />
                    <div>{toAgeLocal || 40}</div>
                </Box>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default AgeFilter