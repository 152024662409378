import apollo from "./api/apollo";
import CommonService from "./common.service";
import SkillMediaModel from "../models/skillMedia.model";
import {
    getAgencyPosition,
    getCityName,
    getCountryName,
    getFirstName,
    getFullName,
    getLastName,
    getPhone,
    getProfilePicture
} from "../util/profile.util";
import IntercomService from "./intercom.service";
import {isAfter} from "date-fns";
import UserModel, {UserProfile} from "../models/user.model";
import Team from "../models/team.model";
import CountryModel from "../models/country.model";
import PastClubModel from "../models/pastClub.model";
import {PeopleProperty} from "./analytics/peopleProperty";
import MixpanelService from "./analytics/mixpanel.service";
import {
    CREATE_SKILL_MEDIA,
    DELETE_SKILL_MEDIA,
    GET_ME,
    GET_PUBLIC_USER,
    GET_USER,
    UPDATE_USER_PROFILE
} from "./api/usersQueries";
import AuthService from "./auth.service";
import {getClubCityName, getClubCountryName, getClubLogo} from "./club.util";

export default class ProfileService {

    static convertToApiFormat = (field: string, value: any) => {
        switch (field) {
            case 'nationalities':
                return value.map((nationality: CountryModel) => CommonService.covertWebCountryToApiCountry(nationality));
            case 'city':
                return CommonService.covertWebCityToApiCity(value);
            case 'country':
                return CommonService.covertWebCountryToApiCountry(value);
            case 'currentClub':
                return value ? {id: value.id} : null;
            case 'currentClubRequest':
                return value ? {id: value.id, name: value.name} : null;
            case 'previousClubs':
                return value ? value.map((previousClub: PastClubModel) => {
                    return {
                        ...previousClub,
                        club: previousClub.club ? {id: previousClub.club.id} : null,
                        clubRequest: previousClub.clubRequest ? {id: previousClub.clubRequest.id} : null
                    }
                }) : null;
            case 'gender':
                return value || null
            default:
                return value;
        }
    };

    static cleanup = (profile: any) => {
        const cleaned: UserProfile = {...profile};
        delete cleaned.fullName;
        delete cleaned.skillMedia;
        delete cleaned.completion;
        console.log(cleaned);
        return cleaned;
    };

    static getLevelData = (user: UserModel) => {
        // Check if there is profile at all
        if (!user.profile) {
            return {
                value: 10,
                tooltip: 'User has no profile at all... Falling back to min level (10)!\n'
            }
        }

        let tooltip = '';
        const profile = user.profile;

        const getTopTeam = (teams?: Team[]) => {
            // Sort club teams by level
            const teamNameLevel = teams?.map(currentClubTeam => {
                return {
                    name: currentClubTeam.teamType?.name,
                    level: currentClubTeam.level?.value
                }
            })
                .sort((a, b) => (a.level || 0) - (b.level || 0));

            // Tooltip
            const teamNameLevelTooltipText = teamNameLevel?.map(teamNameLevel => ` -- [${teamNameLevel.name}: ${teamNameLevel.level}]`).join('\n');
            tooltip += `Teams with levels:\n ${teamNameLevelTooltipText}\n\n`;

            const topLevel = teamNameLevel?.[0].level;
            tooltip += `Top level: ${topLevel}\n\n`;

            return topLevel;
        };

        // Checking current club Current club
        if (profile.currentClub || profile.currentClubRequest) {
            tooltip += `User has a current club\n\n`;

            if (profile.currentClubTeams && profile.currentClubTeams.length) {
                tooltip += `User has ${profile.currentClubTeams.length} current club teams\n\n`;

                return {
                    value: getTopTeam(profile.currentClubTeams),
                    tooltip: tooltip
                }
            } else {
                tooltip += 'No teams found\n';

                if (profile.currentClubRequest) {
                    tooltip += 'But user current club is request. Using default level (5)\n\n';
                    return {
                        value: 5,
                        tooltip: tooltip
                    }
                } else {
                    tooltip += 'Will check past clubs now...\n\n';
                }
            }
        }

        if (profile.previousClubs && profile.previousClubs.length) {
            const realClubs = profile.previousClubs.filter(previousClub => previousClub.club && previousClub.previousClubTeams && previousClub.previousClubTeams.length);

            if (!realClubs.length) {
                tooltip += 'User has past clubs but none of them is a real club or has teams. Using default level (5)\n\n';
                return {
                    value: 5,
                    tooltip: tooltip
                }
            }

            const clubsSorted = realClubs.sort((a, b) => isAfter(new Date(a.seasonFrom!), new Date(b.seasonFrom!)) ? -1 : 1);
            return {
                value: getTopTeam(clubsSorted?.[0]?.previousClubTeams),
                tooltip: tooltip
            }
        }

        tooltip += `Couldn't find anything usefull. Using min level (10)\n`;
        return {
            value: 10,
            tooltip: tooltip
        }
    };

    static updateProfile = async (data: { [field: string]: any }) => {
        const profile: any = {};
        Object.keys(data).forEach(key => {
            profile[key] = ProfileService.convertToApiFormat(key, data[key]);
        });

        const response = await apollo.mutate({
            mutation: UPDATE_USER_PROFILE,
            variables: {
                profile: ProfileService.cleanup(profile)
            },
        });

        const profileResponse = response.data.userProfile;
        const user = {profile: data};

        try {
            IntercomService.updateIntercomUser(user);

            MixpanelService.setUserProperties({
                [PeopleProperty.FIRST_NAME]: getFirstName(user),
                [PeopleProperty.LAST_NAME]: getLastName(user),
                [PeopleProperty.NAME]: getFullName(user),
                [PeopleProperty.PHONE]: getPhone(user),
                [PeopleProperty.AVATAR]: getProfilePicture(user),
                [PeopleProperty.SELECTED_CITY]: getCityName(user),
                [PeopleProperty.SELECTED_COUNTRY]: getCountryName(user),
            })

            const club = AuthService.getAdminClub(user)
            MixpanelService.setUserProperties({
                [PeopleProperty.CLUB_NAME]: club?.name,
                [PeopleProperty.CLUB_POSITION]: getAgencyPosition(user)?.toLowerCase(),
                [PeopleProperty.CLUB_CITY]: getClubCityName(club),
                [PeopleProperty.CLUB_COUNTRY]: getClubCountryName(club),
                [PeopleProperty.CLUB_LOGO]: getClubLogo(club)
            })
        } catch (e) {
            console.log(e);
        }

        return profileResponse;
    };

    static createSkillMedia = async (skillMedia: SkillMediaModel) => {
        const response = await apollo.mutate({
            mutation: CREATE_SKILL_MEDIA,
            variables: {
                skillMedia
            },
        });

        return response.data.skillMedia;
    };

    static deleteSkillMedia = async (skillMedia: SkillMediaModel) => {
        const response = await apollo.mutate({
            mutation: DELETE_SKILL_MEDIA,
            variables: {
                id: skillMedia.id
            },
        });

        return response.data.deleteSkillMedia;
    };

    static getMe = async (): Promise<UserModel> => {
        const response = await apollo.query({
            query: GET_ME,
            errorPolicy: 'ignore'
        });

        return response.data.me
    };

    static async getUser(id: number, viaPublic: boolean): Promise<UserModel> {
        const response = await apollo.query({
            query: viaPublic ? GET_PUBLIC_USER : GET_USER,
            variables: {
                id: id
            }
        });

        return viaPublic ? response.data?.publicProfile : response.data?.user
    }

}

