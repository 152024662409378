import React from 'react';
import styles from './CurrentSeasonOverview.module.scss';
import clsx from 'clsx';
import UserModel from "../../../../models/user.model";
import {useTranslation} from "react-i18next";
import {Box} from "@material-ui/core";
import {getUserMainPosition, getUserStat} from "../../../../util/profile.util";

interface Props {
    player?: UserModel
}

const CurrentSeasonOverview = (props: Props) => {

    const t = useTranslation().t;

    return (
        <Box className={styles.container}>
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'} className={styles.currentStats}
            >
                <p>{t("current_season_stats")}</p>
                {/*<p className={styles.edit}>Edit</p>*/}
            </Box>

            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                className={clsx(styles.greyBox, styles.statsFullWidth)}
            >
                <img alt={'Matches'} src={require('../../../../assets/images/player-matches.svg').default}
                     className={styles.icon}/>
                <Box justifyContent={'center'}>
                    <p className={styles.statTitle}>{t("appearances")}</p>
                    <p className={styles.statValue}>{getUserStat(props.player, 'appearances', 0)}</p>
                </Box>
            </Box>

            {
                getUserMainPosition(props.player) === 'Goalkeeper'
                    ?
                    <React.Fragment>
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            width={'100%'}
                            height={50}
                            justifyContent={'space-between'}
                            marginTop={'5px'}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                boxSizing={'border-box'}
                                width={'calc(50% - 2px)'}
                                className={styles.greyBox}
                            >
                                <img alt={'Goals'} src={require('../../../../assets/images/player-goals.svg').default}
                                     className={styles.icon}/>
                                <Box justifyContent={'center'}>
                                    <p className={styles.statTitle}>{t("clean_sheets")}</p>
                                    <p className={styles.statValue}>{getUserStat(props.player, 'cleanSheets', 0)}</p>
                                </Box>
                            </Box>

                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                boxSizing={'border-box'}
                                width={'calc(50% - 2px)'}
                                className={styles.greyBox}
                            >
                                <img alt={'Assists'}
                                     src={require('../../../../assets/images/player-assists.svg').default}
                                     className={styles.icon}/>
                                <Box justifyContent={'center'}>
                                    <p className={styles.statTitle}>{t("goals_received")}</p>
                                    <p className={styles.statValue}>{getUserStat(props.player, 'goalsReceived', 0)}</p>
                                </Box>
                            </Box>
                        </Box>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            boxSizing={'border-box'}
                            width={'100%'}
                            height={50}
                            justifyContent={'space-between'}
                            marginTop={'5px'}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                width={'calc(50% - 2px)'}
                                className={styles.greyBox}
                            >
                                <img alt={'Goals'} src={require('../../../../assets/images/player-goals.svg').default}
                                     className={styles.icon}/>
                                <Box justifyContent={'center'}>
                                    <p className={styles.statTitle}>{t("goals")}</p>
                                    <p className={styles.statValue}>{getUserStat(props.player, 'goals', 0)}</p>
                                </Box>
                            </Box>

                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                width={'calc(50% - 2px)'}
                                className={styles.greyBox}
                            >
                                <img alt={'Assists'}
                                     src={require('../../../../assets/images/player-assists.svg').default}
                                     className={styles.icon}/>
                                <Box justifyContent={'center'}>
                                    <p className={styles.statTitle}>{t("assists")}</p>
                                    <p className={styles.statValue}>{getUserStat(props.player, 'assists', 0)}</p>
                                </Box>
                            </Box>
                        </Box>
                    </React.Fragment>
            }

            <Box
                display={'flex'}
                flexDirection={'row'}
                boxSizing={'border-box'}
                width={'100%'}
                height={50}
                justifyContent={'space-between'}
                marginTop={'5px'}
            >
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    width={'calc(50% - 2px)'}
                    className={styles.greyBox}
                >
                    <img alt={'Yellow cards'} src={require('../../../../assets/images/player-yellow-cards.svg').default}
                         className={styles.icon}/>
                    <Box justifyContent={'center'}>
                        <p className={styles.statTitle}>{t("yellow_cards")}</p>
                        <p className={styles.statValue}>{getUserStat(props.player, 'yellowCards', 0)}</p>
                    </Box>
                </Box>

                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    width={'calc(50% - 2px)'}
                    className={styles.greyBox}
                >
                    <img alt={'Red cards'} src={require('../../../../assets/images/player-red-cards.svg').default}
                         className={styles.icon}/>
                    <Box justifyContent={'center'}>
                        <p className={styles.statTitle}>{t("red_cards")}</p>
                        <p className={styles.statValue}>{getUserStat(props.player, 'redCards', 0)}</p>
                    </Box>
                </Box>
            </Box>

        </Box>
    );
}

export default CurrentSeasonOverview;
