import React, {useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControl,
    FormControlLabel,
    Typography
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";
import {TransferAvailabilityStatus} from "../../../../models/user.model";

const TransferAvailabilityStatusFilter = () => {

    //region State

    const [paramValue] = useUrlParam<string[]>({
        param: 'transferAvailabilityStatus',
        dataType: 'complex',
        defaultValue: []
    })
    const [expanded, setExpanded] = useState(!!paramValue.length)
    const history = useHistory()

    //endregion State

    const handleChangeRole = (role: string) => {
        let updatedStatuses = [...paramValue]
        if (!updatedStatuses.find(r => r === role)) {
            updatedStatuses = [...paramValue, role]
        } else {
            updatedStatuses = paramValue.filter(r => r !== role)
        }

        setMultipleParams([
            {name: 'transferAvailabilityStatus', value: updatedStatuses, dataType: 'complex'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="role-content"
                id="role-header"
            >
                <Typography>Transfer availability {!!paramValue.length && `(${paramValue.join(', ')})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <FormControl component="fieldset">

                    <FormControlLabel
                        checked={!!paramValue.find(f => f === TransferAvailabilityStatus.TRANSFER_AND_LOAN)}
                        onChange={_ => handleChangeRole(TransferAvailabilityStatus.TRANSFER_AND_LOAN)}
                        value={TransferAvailabilityStatus.TRANSFER_AND_LOAN} control={<Checkbox/>}
                        label="Transfer + Loan"
                    />

                    <FormControlLabel
                        checked={!!paramValue.find(f => f === TransferAvailabilityStatus.TRANSFER_ONLY)}
                        onChange={_ => handleChangeRole(TransferAvailabilityStatus.TRANSFER_ONLY)}
                        value={TransferAvailabilityStatus.TRANSFER_ONLY} control={<Checkbox/>}
                        label="Transfer only"
                    />

                    <FormControlLabel
                        checked={!!paramValue.find(f => f === TransferAvailabilityStatus.LOAN_ONLY)}
                        onChange={_ => handleChangeRole(TransferAvailabilityStatus.LOAN_ONLY)}
                        value={TransferAvailabilityStatus.LOAN_ONLY} control={<Checkbox/>}
                        label="Loan only"
                    />

                    <FormControlLabel
                        checked={!!paramValue.find(f => f === TransferAvailabilityStatus.NOT_AVAILABLE)}
                        onChange={_ => handleChangeRole(TransferAvailabilityStatus.NOT_AVAILABLE)}
                        value={TransferAvailabilityStatus.NOT_AVAILABLE} control={<Checkbox/>}
                        label="Not available"
                    />

                </FormControl>
            </AccordionDetails>

        </Accordion>
    )

}

export default TransferAvailabilityStatusFilter