import {Box, Divider, Drawer, IconButton, ListItem, ListItemIcon, ListItemText, makeStyles} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import Logo from '../../assets/images/logo.png'
import {useHistory} from "react-router";
import clsx from "clsx";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {useTranslation} from "react-i18next";
import WorkIcon from '@material-ui/icons/Work';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FunctionsIcon from '@material-ui/icons/Functions';
import BugReportIcon from '@material-ui/icons/BugReport';

const drawerWidth = 240;

const useStyle = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.05)',
        borderRight: 'none',
        overflowX: 'hidden',
    },


    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(6),
    },

    expandButton: {
        marginLeft: 'auto',
        marginRight: 4
    },

    listItem: {
        width: drawerWidth
    },
    listItemText: {
        marginLeft: theme.spacing(1),
    },

    logo: {
        width: 200,
        height: 50,
        objectFit: 'contain',
        paddingLeft: theme.spacing(0.5),
        paddingTop: theme.spacing(2),
        cursor: 'pointer'
    },

    sectionTitle: {
        width: drawerWidth,
        marginTop: 15,
        height: 16,
        color: '#B9B9C3',
        paddingLeft: theme.spacing(2),
        fontSize: 12
    }
}))


const Sidebar = () => {

    const classes = useStyle()
    const history = useHistory()
    const [open, setOpen] = useState(localStorage.getItem('lastSidebarOpen') === 'true')

    const {t, i18n} = useTranslation()

    useEffect(() => {
        localStorage.setItem('lastSidebarOpen', `${open}`)
    }, [open])


    const renderMenuItem = (text: string, icon: any, link: string) => (
        <ListItem
            button
            key={link}
            className={classes.listItem}
            onClick={() => history.push(link)}
            color={'primary'}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} className={classes.listItemText}/>
        </ListItem>
    )

    return (
        <Drawer
            // className={classes.drawer}
            variant="permanent"
            // classes={{
            //     paper: classes.drawerPaper,
            // }}

            className={clsx('hide-on-print', classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}

            anchor="left"
        >
            <Box>
                <img src={Logo} className={classes.logo} onClick={() => setOpen(!open)}/>

                <Divider/>

                <Box display={'flex'}>
                    <IconButton className={classes.expandButton} onClick={() => setOpen(!open)}>
                        {open ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                </Box>

                <Divider/>

                <Box m={2}/>

                {renderMenuItem(t('Users'), <PeopleOutlineIcon/>, '/admin/user')}
                {renderMenuItem(t('Jobs'), <WorkIcon/>, '/admin/job')}
                {renderMenuItem(t('Clubs'), <SportsSoccerIcon/>, '/admin/club')}
                {renderMenuItem(t('Requests'), <NotListedLocationIcon/>, '/admin/clubRequest')}
                {renderMenuItem(t('B2C'), <AttachMoneyIcon/>, '/admin/b2c')}
                {renderMenuItem(t('Algorithms'), <FunctionsIcon/>, '/admin/algorithms')}
                {renderMenuItem(t('AlgoBug'), <BugReportIcon/>, '/admin/algobug')}
            </Box>
        </Drawer>
    )
}

export default Sidebar
