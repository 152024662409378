import React, {useEffect, useState} from 'react'
import {Box, Card, CircularProgress} from "@material-ui/core"
import {useHistory} from "react-router"
import {useListUtilStyle} from "../common/List/listUtil.style"
import {useBreadcrumbs} from "../../util/data/useBreadcrumbs"
import {useTranslation} from "react-i18next";
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import {useTabs} from "../../util/data/useTabs";
import B2cService from "../../services/b2c.service";
import SubscriptionConfigAdmin from "../../models/subscriptionConfigAdmin.model";
import PlayerConfiguration from "./details/Player.component";
import AgentConfiguration from "./details/Agent.component";
import CountryModel from "../../models/country.model";
import {UserTypeId} from "../../models/user.model";

interface Params {
    id: string
}

const B2C = () => {


    const classes = useListUtilStyle()
    const history = useHistory()
    const {t} = useTranslation()

    const [config, setConfig] = useState<SubscriptionConfigAdmin>({});
    const [loading, setLoading] = useState(true);
    const [savingInProgress, setSavingInProgress] = useState(false);
    const [error, setError] = useState(false);

    const tabs = [
        {icon: <PersonIcon/>, label: t('Player')},
        {icon: <SearchIcon/>, label: t('Agent')},
    ]

    const saveHandler = async () => {
        try {
            setSavingInProgress(true);
            setError(false);

            const mapped: SubscriptionConfigAdmin = {
                ...config,
                activeIn: config?.activeIn?.map((country: CountryModel) => {
                    return {
                        id: country.id
                    }
                }),
            }

            const response = await B2cService.saveAdminConfig(mapped);
            if (response) {
                // setConfig(response);
            } else {
                setError(true);
            }
        } catch (e) {
            setError(true);
        } finally {
            setSavingInProgress(false);
        }
    };

    const tabsData = useTabs({
        tabs: tabs,
        showDelete: false,
        canDelete: false,
        showSave: true,
        canSave: true,
        onSave: saveHandler
    })

    const isPlayerConfig = tabsData.tab === 0
    const isAgentConfig = tabsData.tab === 1

    const fetchConfig = async () => {
        setLoading(true)
        const result = await B2cService.getAdminConfig(isPlayerConfig ? UserTypeId.REGULAR_TYPE_ID : UserTypeId.AGENT_TYPE_ID);
        if (result) {
            setConfig(result);
        } else {
            setConfig({});
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchConfig();
    }, [tabsData.tab]);


    const breadcrumbs = useBreadcrumbs({
        path: [
            {label: t('B2C')}
        ]
    })


    //region UI

    return (
        <Box>

            {breadcrumbs}

            <Card>
                {tabsData.renderTabs()}

                {loading && <CircularProgress/>}

                {
                    !loading && tabsData.tab === 0 &&
                    <PlayerConfiguration config={config} setConfig={setConfig}/>
                }

                {
                    !loading && tabsData.tab === 1 &&
                    <AgentConfiguration config={config} setConfig={setConfig}/>
                }

                {error && <p
                    // className={styles.errorMsg}
                >Something went wrong.Please try again later</p>}

            </Card>


        </Box>
    )

    //endregion UI

}

export default B2C
