import React, {useEffect, useState} from 'react'
import {Avatar, Box, Dialog, Grid, Typography} from "@material-ui/core"
import {useHistory} from "react-router"
import {ListColumn} from "../common/List/List"
import {useListData} from "../../util/data/useListData"
import {useListUtilStyle} from "../common/List/listUtil.style"
import {useBreadcrumbs} from "../../util/data/useBreadcrumbs"
import {useTranslation} from "react-i18next";
import {getClubLogo, getClubName} from "../../util/profile.util";
import ClubModel from "../../models/club.model";
import ClubsService from "../../services/clubs.service";
import {getCurrentSubscriptionType} from "../../util/payment.util";
import ClubFilterActions from "./ClubFilterActions.component";
import ExportClubsPopup from "./ExportClubsPopup/ExportClubsPopup";
import CreateClubForm from "../common/CreateClubForm.component";
import {useClubFilters} from "./useClubFilters";

const ClubList = () => {

    //region State

    const classes = useListUtilStyle()
    const history = useHistory()
    const {t} = useTranslation()
    const [exportWhich, setExportWhich] = useState('')
    const [filters] = useClubFilters()
    const [addOpen, setAddOpen] = useState(false)

    const columns: ListColumn[] = [
        {name: t('Id'), id: 'id', width: 'xs'},
        {name: t('Club'), id: 'club', width: 'grow'},
        {name: t('Country'), id: 'country', width: 'm'},
        {name: t('City'), id: 'city', width: 'm'},
        {name: t('Subscription'), id: 'subscription', width: 'm'},
    ]

    const renderCell = (club: ClubModel, column: ListColumn) => {

        switch (column.id) {
            case 'id':
                return (
                    <Typography variant={'body1'}>{club.id}</Typography>
                )
            case 'club':
                return (
                    <React.Fragment>
                        <Avatar className={classes.avatar} src={getClubLogo(club)}/>
                        <Box style={{
                            width: 7,
                            height: 40,
                            marginRight: 10
                        }}/>
                        <Typography variant={'body1'}>
                            {getClubName(club) || '-'}
                        </Typography>

                    </React.Fragment>
                )
            case 'country':
                return (
                    <Typography variant={'body1'}>{club.country?.name || '-'}</Typography>
                )
            case 'city':
                return (
                    <Typography variant={'body1'}>{club.city?.name || '-'}</Typography>
                )
            case 'subscription':
                return (
                    <Typography variant={'body1'}>{getCurrentSubscriptionType(club)}</Typography>
                )
            default:
                return null
        }
    }

    const openClub = (club: ClubModel) => {
        history.push(`/admin/club/${club.id}`)
    }

    const listData = useListData<ClubModel>({
        list: (page, size, search) => ClubsService.getAdminClubs({
            page: page,
            size: size,
            sort: {
                orders: [{prop: 'id', dir: 'desc'}]
            }
        }, filters),
        renderCell,
        columns,
        onItemClick: openClub,
        onItemMiddleClick: club => window.open(`${process.env.REACT_APP_WEB_URL}/admin/club/${club.id}`, '_blank'),
        onAdd: () => setAddOpen(true),
        listId: 'clubsList',
        filters: filters
    })

    useEffect(() => {
        listData.fetchItems()
    }, [filters])

    const breadcrumbs = useBreadcrumbs({
        path: [
            {label: t('Clubs')}
        ]
    })

    //endregion

    //region UI

    return (
        <Box>

            {breadcrumbs}

            <Grid container spacing={3} style={{flex: 1}}>
                <Grid item xs={3} md={3} lg={3} xl={2}>
                    <ClubFilterActions
                        selected={listData.selected}

                        onExportAll={() => setExportWhich('all')}
                        onExportSelected={() => setExportWhich('selected')}
                    />
                </Grid>

                <Grid item xs={9} md={9} lg={9} xl={10}>
                    {listData.renderList()}
                </Grid>

            </Grid>

            <ExportClubsPopup
                open={!!exportWhich}
                onClose={() => setExportWhich('')}
                selected={listData.selected.values()}
                exportType={exportWhich}
                filters={filters}
            />

            <Dialog
                open={addOpen}
                onClose={() => setAddOpen(false)}
            >
                <Box width={300} p={3}>
                    <CreateClubForm
                        onClubCreated={club => openClub(club!)}
                    />
                </Box>
            </Dialog>

        </Box>
    )

    //endregion UI
}

export default ClubList
