import UserModel from "../models/user.model";
import {timeout} from "../util/util";

export default class IntercomService {

    static readonly appId = 'pet1qs21';

    static initialized = false;

    static async init() {
        await timeout(3 * 1000);

        if (this.initialized) return;
        this.initialized = true;

        // @ts-ignore
        window.Intercom('boot', {
            // app_id: 'ky44s8t6',
            app_id: IntercomService.appId,
        });
    }

    static async shutdown() {
        await timeout(3 * 1000);
        if (!this.initialized) return;
        this.initialized = false

        // @ts-ignore
        window.Intercom('shutdown');
    }

    static async updateIntercomUser(user?: UserModel) {
        if (!this.initialized) {
            await this.init();
        }

        if (!user) return

        // @ts-ignore
        window.Intercom('update', {
            app_id: IntercomService.appId,
            email: user.email,
            created_at: new Date().getTime(),
            name: `${user.profile?.firstName} ${user.profile?.lastName}`,
            user_id: `${user.id}`
        });
    }


}