import React, {useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";
import {UserTypeId} from "../../../../models/user.model";

const PlayerParentTypeFilter = () => {

    //region State

    const [paramValue] = useUrlParam({param: 'parentUserTypeId', dataType: 'numeric', defaultValue: 0})
    const [expanded, setExpanded] = useState(paramValue > 0)
    const history = useHistory()

    //endregion State

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="playerParentType-content"
                id="playerParentType-header"
            >
                <Typography>Player type</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="playerParentType-content"
                        name="playerParentType-content"
                        value={paramValue}
                        onChange={e => {
                            setMultipleParams([
                                {name: 'parentUserTypeId', value: e.target.value || 0, dataType: 'numeric'},
                                {name: 'listPage', value: 1, dataType: 'numeric'}
                            ], history)
                        }}
                    >
                        <FormControlLabel value={0} control={<Radio/>} label="All"/>
                        <FormControlLabel value={UserTypeId.REGULAR_TYPE_ID} control={<Radio/>}
                                          label="Regular (real user)"/>
                        <FormControlLabel value={UserTypeId.AGENT_TYPE_ID} control={<Radio/>} label="Agent's player"/>
                        <FormControlLabel value={UserTypeId.STAFF_TYPE_ID} control={<Radio/>} label="Club's player"/>
                    </RadioGroup>
                </FormControl>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default PlayerParentTypeFilter