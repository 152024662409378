import React, {useState} from 'react'
import {Box, Card, CircularProgress, Grid, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import Job from "../../../../models/job.model";
import {
    addCountries,
    eeaChCountryIds,
    euCountryIds,
    getCountriesByIds,
    removeCountries
} from "../../../../services/countries";
import {LocationType} from "../../../../models/locationType.enum";
import EButton from "../../../common/EButton";
import OpportunityService from "../../../../services/opportunity.service";
import _ from "lodash";
import ClubBadge from "../../../common/ClubBadge/ClubBadge";
import PickClubPopup from "../../../common/PickClubPopup/PickClubPopup";
import BasicInfo from "./BasicInfo.component";
import StatisticSection from "./StatisticSection.component";
import ImportantToKnow from "./ImportantToKnow.component";
import PublishJob from "./PublishJob.component";
import {useUser} from "../../../../store/store.utils";
import {UserTypeId} from "../../../../models/user.model";
import AdminSection from "./AdminSection.component";
import {isPhAdmin} from "../../../../util/profile.util";

interface Props {
    job?: Job,
    setJob: (job: Job) => void,
    saving?: boolean
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
    input: {
        width: '100%'
    },
    timeline: {
        // transform: "rotate(90deg)",
        // width: 100,
        height: '100%'
    },
    timelineContentContainer: {
        // textAlign: "left",
        flex: 1
    },
    timelineContent: {
        // display: "inline-block",
        // transform: "rotate(-90deg)",
        // textAlign: "center",
        minWidth: 100
    },
    timelineIcon: {
        // transform: "rotate(-90deg)",
        width: 20,
        height: 20
    },
    team: {
        background: '#FAFAFA',
        borderRadius: 16,
        marginTop: 5,
        marginBottom: 5,
        marginRight: 10,
        border: '2px solid transparent',
        cursor: 'pointer',
        padding: 10,
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.57)'
    },
    teamSelected: {
        background: theme.palette.primary.main,
        color: 'white'
    },
    slider: {
        marginLeft: 2,
        width: '100%',
        maxWidth: '100%'
    },
    sliderMark: {
        width: 24,
        textAlign: 'center',
        fontSize: 12
    },
    sliderMarkLeft: {
        marginRight: 20
    },
    sliderMarkRight: {
        marginLeft: 20
    },
    sliderTop: {
        width: '100% important!'
    },
    option: {
        width: 95,
        height: 50,
        cursor: 'pointer',
        borderBottom: '2px solid #FBFBFB',
        color: 'rgba(0, 0, 0, 0.61)',
        fontSize: '14px',
        background: '#FBFBFB',
        textAlign: 'center'
    },
    genderImg: {
        width: 14,
        height: 14
    },
    selected: {
        //background: linear-gradient(169deg, #e40015 0%, #750075 100%);
        borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    sliderTeamMark: {
        backgroundColor: theme.palette.primary.main,
        width: 3,
        height: 13,
        marginTop: -5,
        marginLeft: -1,
        borderRadius: 2
    },
    radioLabel: {
        fontSize: '14px !important'
    },
    selectLocationCTA: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginTop: 20,
        zIndex: 1
    }
}))

const CreateJob = ({job, setJob}: Props) => {

    const classes = useStyle()
    const history = useHistory()
    const {t, i18n} = useTranslation()
    const [saved, setSaved] = useState(false);
    const [saving, setSaving] = useState(false);
    const user = useUser()
    const isAdmin = user?.userType?.id === UserTypeId.ADMIN_TYPE_ID
    const [pickClubPopupOpen, setPickClubPopupOpen] = useState(false)


    const isTeamSelected = !!job?.teams?.length;
    const isPositionSelected = (job?.positionMode === 'positions' && job?.positions?.length) ||
        (job?.positionMode === 'section' && job?.section);
    const hasClub = job?.club?.id !== 0

    const isBasicSectionOk = isTeamSelected && isPositionSelected && hasClub;

    let isPublishSectionOk
    if (job?.locationType === LocationType.Local) {
        isPublishSectionOk = !!job?.location?.googleGeolocation || !!job?.location?.location;
    }

    if (job?.locationType === LocationType.Country) {
        isPublishSectionOk = !!job?.location?.country?.length;
    }

    if (job?.locationType === LocationType.International) {
        isPublishSectionOk = true;
    }

    const canPublish = isPublishSectionOk && isBasicSectionOk


    const updateFields = (data: { field: string, value: any }[]) => {
        const newJob: any = _.cloneDeep(job)
        data.forEach(d => newJob[d.field] = d.value)
        setJob(newJob)
    }

    const updateField = (field: string, value: any) => {
        updateFields([{field, value}])
    }


    // ***** Handlers ***** //


    const handlePublish = async () => {
        try {
            // setError('');
            setSaving(true);
            const response = await OpportunityService.createOpportunity(job!);
            if (response) {
                setSaved(true);
                history.goBack()
            }
        } catch (e) {
            // setError('generic');
            console.log(e);
        } finally {
            setSaving(false);
        }
    };


    const handleAddNationality = (country: any) => {
        let countriesToAdd;
        if (country.countryCode === 'eu' || country.countryCode === 'eeach') {
            countriesToAdd = country.countryCode === 'eu' ? getCountriesByIds(euCountryIds) : getCountriesByIds(eeaChCountryIds);
        } else {
            countriesToAdd = [country];
        }

        const updatedCountries = addCountries(job?.nationalities || [], countriesToAdd);
        updateField('nationalities', updatedCountries)
    };

    const handleRemoveNationality = (country: any) => {
        let countriesToRemove;
        if (country.countryCode === 'eu') {
            countriesToRemove = getCountriesByIds(euCountryIds);
        } else if (country.countryCode === 'eeach') {
            countriesToRemove = getCountriesByIds(eeaChCountryIds);
        } else {
            countriesToRemove = [country]
        }

        const removedCountries = removeCountries(job?.nationalities || [], countriesToRemove);
        updateField('nationalities', removedCountries)
    };


    //endregion Handlers

    //region UI

    const team = job?.teams?.[0];
    const teamLevel = team?.level?.value || 5;
    const teamName = team ? t(team.teamType?.name || '-') : '';

    const marks = [];
    if (team) {
        marks.push({
            value: teamLevel,
            label: teamName
        })
    }


    const jobUI = (
        <Grid item xs={12} style={{maxWidth: 1300}}>

            <Box
                width={'100%'}
                marginBottom={3}
                marginTop={2}
                display={'flex'}
                justifyContent={'center'}
            >
                <Typography variant={'h4'}>
                    What are you looking for?
                </Typography>
            </Box>

            <Card style={{padding: 30}}>
                <BasicInfo setJob={setJob} job={job}/>
            </Card>

            {
                isPhAdmin(user) &&
                    <React.Fragment>
                        <Box height={50}/>

                        <Box
                            width={'100%'}
                            marginBottom={3}
                            marginTop={2}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            <Typography variant={'h4'}>
                                Playerhunter admin specific
                            </Typography>
                        </Box>

                        <Card style={{padding: 30}}>
                            <AdminSection setJob={setJob} job={job}/>
                        </Card>
                    </React.Fragment>
            }

            <Box height={50}/>

            <Box
                width={'100%'}
                marginBottom={3}
                marginTop={2}
                display={'flex'}
                justifyContent={'center'}
            >
                <Typography variant={'h4'}>
                    Additional requirements?
                </Typography>
            </Box>

            <Card style={{padding: 30}}>
                <StatisticSection setJob={setJob} job={job}/>
            </Card>

            <Box height={50}/>

            <Box
                width={'100%'}
                marginBottom={3}
                marginTop={2}
                display={'flex'}
                justifyContent={'center'}
            >
                <Typography variant={'h4'}>
                    Important to know
                </Typography>
            </Box>

            <Card style={{padding: 30}}>
                <ImportantToKnow setJob={setJob} job={job}/>
            </Card>

            <Box height={50}/>

            <Box
                width={'100%'}
                marginBottom={3}
                marginTop={2}
                display={'flex'}
                justifyContent={'center'}
            >
                <Typography variant={'h4'}>
                    Where to publish?
                </Typography>
            </Box>

            <Grid container spacing={3}>
                <PublishJob setJob={setJob} job={job}/>
            </Grid>

            <Box
                display={'flex'}
                justifyContent={'center'}
                margin={5}
            >
                {
                    saving ? <CircularProgress/> :
                        <EButton
                            variant={'contained'}
                            color={'primary'}
                            size={'large'}
                            disabled={!canPublish}
                            onClick={handlePublish}
                        >
                            Publish
                        </EButton>
                }

            </Box>


        </Grid>
    )


    return (
        <Grid container justify={'center'}>

            {
                isAdmin &&
                <Box
                    width={'100%'}
                    marginBottom={3}
                    marginTop={2}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                >
                    <Typography variant={'h4'}>
                        Select club (admin only)
                    </Typography>

                    <Box
                        height={200}
                        width={400}
                    >
                        <ClubBadge
                            club={job?.club || job?.clubRequest}
                            clicked={() => setPickClubPopupOpen(true)}
                            clubNameEmptyText={'Select a club'}
                            rowDisplay
                            style={{width: 'calc(100% - 100px)', justifyContent: 'flex-start'}}
                        />
                    </Box>


                </Box>
            }

            {!!job?.club && jobUI}

            <PickClubPopup
                open={pickClubPopupOpen}
                onPick={(club) => {
                    updateField('club', club)
                    setPickClubPopupOpen(false)
                }}
                onClose={() => setPickClubPopupOpen(false)}
            />

        </Grid>
    )

    //endregion UI
}

export default CreateJob
