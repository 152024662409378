export const SET_FEED = 'SET_FEED';
export const SET_FEED_LOADING = 'SET_FEED_LOADING';

export const SET_LOGIN_OPEN = 'SET_LOGIN_OPEN';
export const SET_AUTH_EMAIL = 'SET_AUTH_EMAIL';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const SET_AUTH_PASSWORD = 'SET_AUTH_PASSWORD';
export const AUTH_IN_PROGRESS = 'AUTH_IN_PROGRESS';
export const SET_USER = 'SET_USER';
export const SET_AUTH_CLUB = 'SET_AUTH_CLUB';
export const SET_ADMIN_CLUB = 'SET_ADMIN_CLUB';
export const SET_AUTH_FIRST_NAME = 'SET_AUTH_FIRST_NAME';
export const SET_AUTH_LAST_NAME = 'SET_AUTH_LAST_NAME';
export const SET_AUTH_AGENCY_POSITION = 'SET_AUTH_AGENCY_POSITION';
export const SET_AUTH_PHONE = 'SET_AUTH_PHONE';
export const ADD_SKILL_MEDIA = 'ADD_SKILL_MEDIA';
export const LOGOUT = 'LOGOUT';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_PROFILE_UPDATE_IN_PROGRESS = 'SET_PROFILE_UPDATE_IN_PROGRESS';
export const SET_CLUB_UPDATE_IN_PROGRESS = 'SET_CLUB_UPDATE_IN_PROGRESS';
export const SET_LOGIN_TYPE = 'SET_LOGIN_TYPE';
export const SET_TOKEN_VALIDATION_DONE = 'SET_TOKEN_VALIDATION_DONE';

export const SET_ONBOARDING_PHASE = 'SET_ONBOARDING_PHASE';
export const SET_ONBOARDING_FIRST_NAME = 'SET_ONBOARDING_FIRST_NAME';
export const SET_ONBOARDING_LAST_NAME = 'SET_ONBOARDING_LAST_NAME';
export const SET_ONBOARDING_GENDER = 'SET_ONBOARDING_GENDER';
export const SET_ONBOARDING_POSITIONS = 'SET_ONBOARDING_POSITIONS';
export const SET_ONBOARDING_PHONE = 'SET_ONBOARDING_PHONE';
export const SET_ONBOARDING_CLUB = 'SET_ONBOARDING_CLUB';
export const SET_ONBOARDING_CLUBS_RESULT = 'SET_ONBOARDING_CLUBS_RESULT';
export const SET_ONBOARDING_CLUBS_LOADING = 'SET_ONBOARDING_CLUBS_LOADING';
export const SET_SHOW_AWESOME_DIALOG = 'SET_SHOW_AWESOME_DIALOG';

export const SET_APPLY_OPPORTUNITY_IN_PROGRESS = 'SET_APPLY_OPPORTUNITY_IN_PROGRESS';
export const SET_APPLY_OPPORTUNITY_DONE = 'SET_APPLY_OPPORTUNITY_DONE';

export const SET_SKIP_OPPORTUNITY_IN_PROGRESS = 'SET_SKIP_OPPORTUNITY_IN_PROGRESS';
export const SET_SKIP_OPPORTUNITY_DONE = 'SET_SKIP_OPPORTUNITY_DONE';

export const SET_REMOVE_OPPORTUNITY_APPLICATION = 'SET_REMOVE_OPPORTUNITY_APPLICATION';

export const SET_OPPORTUNITY_TO_APPLY_FOR_AFTER_ONBOARDING = 'SET_OPPORTUNITY_TO_APPLY_FOR_AFTER_ONBOARDING';

export const SET_APP_STATE = 'SET_APP_STATE';
export const SET_CURRENT_STACK = 'SET_CURRENT_STACK';
export const SET_SAFE_AREA_INSETS = 'SET_SAFE_AREA_INSETS';
export const SET_TUTORIAL_ACTIVE = 'SET_TUTORIAL_ACTIVE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_CURRENT_MODAL = 'SET_CURRENT_MODAL';
export const ADD_NOTIFICATION_TO_SHOW = 'ADD_NOTIFICATION_TO_SHOW';
export const REMOVE_NOTIFICATION_TO_SHOW = 'REMOVE_NOTIFICATION_TO_SHOW';
export const CLEAR_NOTIFICATIONS_TO_SHOW = 'CLEAR_NOTIFICATIONS_TO_SHOW';
export const SET_LATEST_DEEPLINK_NOTIFICATION = 'SET_LATEST_DEEPLINK_NOTIFICATION';

export const LAST_SESSION_IDS = 'LAST_SESSION_IDS';

export const SET_AVAILABLE_SUBSCRIPTIONS = 'SET_AVAILABLE_SUBSCRIPTIONS';
export const SET_SUBSCRIPTION_CONFIG = 'SET_SUBSCRIPTION_CONFIG';
export const SET_APPLICATIONS_IN_LAST_N_DAYS = 'SET_APPLICATIONS_IN_LAST_N_DAYS';
export const SET_PURCHASE_STATE = 'SET_PURCHASE_STATE';
export const SET_SUBSCRIPTION_MODAL_OPEN = 'SET_SUBSCRIPTION_MODAL_OPEN';
export const SET_SUBSCRIPTION_MODAL_OPEN_PROFILE = 'SET_SUBSCRIPTION_MODAL_OPEN_PROFILE';
export const SET_PREMIUM_OPPORTUNITY_MODAL_OPEN = 'SET_PREMIUM_OPPORTUNITY_MODAL_OPEN';
export const SET_REJECT_OPPORTUNITY_MODAL_OPEN = 'SET_REJECT_OPPORTUNITY_MODAL_OPEN';
export const SET_CONFIRM_CLOSE_PREMIUM_OPEN = 'SET_CONFIRM_CLOSE_PREMIUM_OPEN';
export const SET_CONFIRM_CLOSE_PREMIUM_OPEN_PROFILE = 'SET_CONFIRM_CLOSE_PREMIUM_OPEN_PROFILE';

export const SET_FIRST_APPLICATION_MODAL_OPEN = 'SET_FIRST_APPLICATION_MODAL_OPEN';
export const SET_PREMIUM_MEMBER_MODAL_OPEN = 'SET_PREMIUM_MEMBER_MODAL_OPEN';
export const SET_PREMIUM_MEMBER_MODAL_OPEN_PROFILE = 'SET_PREMIUM_MEMBER_MODAL_OPEN_PROFILE';

export const ADD_AGENT_PLAYER = 'ADD_AGENT_PLAYER'
export const REMOVE_AGENT_PLAYER = 'REMOVE_AGENT_PLAYER'
export const SET_AGENT_PLAYERS = 'SET_AGENT_PLAYERS'
export const SET_AGENT_PROFILE = 'SET_AGENT_PROFILE'

export const SET_JOBS = 'SET_JOBS'
export const ADD_JOB = 'ADD_JOB'
export const REMOVE_JOB = 'REMOVE_JOB'

// Applicants
export const SET_APPLICATIONS = 'SET_APPLICATIONS';
export const SET_APPLICATIONS_LOADING = 'SET_APPLICATIONS_LOADING';
export const SET_APPLICATIONS_HAS_MORE = 'SET_APPLICATIONS_HAS_MORE';
export const SET_APPLICATIONS_PAGE = 'SET_APPLICATIONS_PAGE';
export const ADD_TO_APPLICATIONS_IN_PROGRESS = 'ADD_TO_APPLICATIONS_IN_PROGRESS';
export const REMOVE_FROM_APPLICATIONS_IN_PROGRESS = 'REMOVE_FROM_APPLICATIONS_IN_PROGRESS';
export const MOVE_TO_REJECTED = 'MOVE_TO_REJECTED';
export const MOVE_TO_INVITED = 'MOVE_TO_INVITED';
export const MOVE_TO_DELETED = 'MOVE_TO_DELETED';
export const MOVE_FROM_TOP_PICKS_TO_OTHER = 'MOVE_FROM_TOP_PICKS_TO_OTHER';
export const MOVE_FROM_OTHER_TO_TOP_PICKS = 'MOVE_FROM_OTHER_TO_TOP_PICKS';
export const APPLICATION_DATA_UPDATE = 'APPLICATION_DATA_UPDATE';
export const SET_APPLICATION = 'SET_APPLICATION';
export const SET_APPLICATION_LOADING = 'SET_APPLICATION_LOADING';

export const SET_SELECTED_FOR_EXPORT = 'SET_SELECTED_FOR_EXPORT';
export const ADD_TO_SELECTED_FOR_EXPORT = 'ADD_TO_SELECTED_FOR_EXPORT';
export const REMOVE_FROM_SELECTED_FOR_EXPORT = 'REMOVE_FROM_SELECTED_FOR_EXPORT';

export const SET_PROFILE_USER = 'SET_PROFILE_USER';
export const SET_REGISTRATION_BASIC_INFO = 'SET_REGISTRATION_BASIC_INFO';
export const SET_REGISTRATION_CLUB = 'SET_REGISTRATION_CLUB';

// Billing
export const SET_SUBSCRIBING_TO_PRO_TRIAL_IN_PROGRESS = 'SET_SUBSCRIBING_TO_PRO_TRIAL_IN_PROGRESS'
export const SET_SUBSCRIBING_TO_AMATEUR = 'SET_SUBSCRIBING_TO_AMATEUR'
export const SET_SUBSCRIPTION_MESSAGE = 'SET_SUBSCRIPTION_MESSAGE'
export const SET_PRODUCT_PRICE = 'SET_PRODUCT_PRICE'
export const SET_ADD_PAYMENT_OPEN = 'SET_ADD_PAYMENT_OPEN'
export const SET_SUBSCRIBE_OPEN = 'SET_SUBSCRIBE_OPEN'
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION'
export const SET_SUBSCRIPTION_STATE = 'SET_SUBSCRIPTION_STATE'
export const SET_SUBSCRIPTION_SERVER = 'SET_SUBSCRIPTION_SERVER'
export const SET_INVOICES = 'SET_INVOICES'
export const SET_INVOICES_STATE = 'SET_INVOICES_STATE'
export const SET_STRIPE_CUSTOMER = 'SET_STRIPE_CUSTOMER'
export const SET_STRIPE_CUSTOMER_STATE = 'SET_STRIPE_CUSTOMER_STATE'
export const SET_UPGRADE_POPUP_OPEN = 'SET_UPGRADE_POPUP_OPEN';

// Algobug
export const SET_ALGOBUG_FEED_USER = 'SET_ALGOBUG_FEED_USER'
export const SET_ALGOBUG_NOTIFICATIONS_JOB = 'SET_ALGOBUG_NOTIFICATIONS_JOB'
