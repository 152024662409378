import React, {FunctionComponent} from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from './ApplicantContactInfo.module.scss';
import {useTranslation} from "react-i18next";
import {Box, CircularProgress} from "@material-ui/core";
import OpportunityApplicationModel from "../../../models/opportunityApplication.model";
import {getEmail, getPhone} from "../../../util/profile.util";
import MixpanelService from "../../../services/analytics/mixpanel.service";
import {EventName} from "../../../services/analytics/eventName";
import {EventProperty} from "../../../services/analytics/eventProperty";
import EButton from "../../common/EButton";
import clsx from "clsx";

interface Props {
    open: boolean,
    application?: OpportunityApplicationModel,
    actionInProgress?: boolean;
    onUpdateContactStatus: (contacted: boolean) => void;
    onClose: () => void
}

const ApplicantContactInfo: FunctionComponent<Props> = (props: Props) => {
    const phone = getPhone(props.application?.createdBy);
    const email = getEmail(props.application?.createdBy);
    const t = useTranslation().t;

    return (
        <Dialog {...props} aria-labelledby="simple-dialog-title" maxWidth={"xs"} fullWidth={true}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                className={styles.alertContainer}
            >
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    className={styles.alertText}
                >
                    <img src={require('../../../assets/images/close-black.svg').default} className={styles.closeIcon}
                         onClick={props.onClose}/>
                    <div>{t("contact_applicant")}</div>
                </Box>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={styles.contactInfoContainer}
                >
                    <div className={styles.line}/>

                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                    >
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            className={styles.contactHolder}
                        >

                            <img src={require('../../../assets/images/phone.svg').default}
                                 className={styles.phoneIcon}/>

                            <a className={styles.link} onClick={() => {
                                MixpanelService.track(EventName.APPLICANT_GET_IN_TOUCH, {
                                    [EventProperty.PLAYER_ID]: props.application?.createdBy?.id,
                                    [EventProperty.CONTACT_CHANNEL]: 'phone'
                                })
                            }}
                               href={`tel:${phone}`}>{phone || t('no_phone_provided')}</a>
                            {/*<div className={styles.linkSubtitle}>{lcl("monday")} - {lcl("friday")} | 9:00 - 16:00</div>*/}

                        </Box>

                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            className={styles.contactHolder}
                        >

                            <img src={require('../../../assets/images/mail.svg').default} className={styles.mailIcon}/>

                            <a className={styles.link} onClick={() => {
                                MixpanelService.track(EventName.APPLICANT_GET_IN_TOUCH, {
                                    [EventProperty.PLAYER_ID]: props.application?.createdBy?.id,
                                    [EventProperty.CONTACT_CHANNEL]: 'email'
                                })
                            }}
                               href={`mailto:${email}`}>{email || t('no_email_provided')}</a>
                            {/*<div className={styles.linkSubtitle}>{lcl("we_will_get_to_you_in_24h")}</div>*/}

                        </Box>
                    </Box>

                    <div className={styles.line}/>

                </Box>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                >

                    <div className={styles.text}>{t('Where you able to contact the player?')}</div>

                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        className={clsx(styles.buttonHolder, props.actionInProgress && styles.buttonHolderLoaded)}>

                        {
                            props.actionInProgress ?
                                <CircularProgress/>
                                :
                                <React.Fragment>
                                    <EButton
                                        variant={props.application?.contacted === true ? "contained" : "outlined"}
                                        color="primary"
                                        className={styles.button}
                                        onClick={() => props.onUpdateContactStatus(true)}
                                    >
                                        {t("yes")}
                                    </EButton>

                                    <EButton
                                        variant={props.application?.contacted === false ? "contained" : "outlined"}
                                        color="primary"
                                        className={styles.button}
                                        onClick={() => props.onUpdateContactStatus(false)}
                                    >
                                        {t("no")}
                                    </EButton>
                                </React.Fragment>
                        }
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
};

export default ApplicantContactInfo;
