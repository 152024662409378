import {removeMultipleParams, useUrlParam} from "../../util/data/url.util";
import CountryModel from "../../models/country.model";
import {useHistory} from "react-router";
import {useEffect, useState} from "react";
import ClubModel from "../../models/club.model";

export const useClubFilters = () => {
    const filters: any = {}
    const history = useHistory()

    // Country
    const [countries] = useUrlParam<CountryModel[]>({
        param: 'countries',
        dataType: 'complex',
        defaultValue: []
    })
    if (countries.length) {
        filters.countries = countries.map(c => c.id)
    }

    // Club name
    const [clubName] = useUrlParam({param: 'clubName', dataType: 'string', defaultValue: ''})
    clubName && (filters.name = clubName)

    // Unresolved admins
    const [unresolvedAdmins] = useUrlParam({param: 'unresolvedAdmins', dataType: 'string', defaultValue: ''})
    unresolvedAdmins === 'true' && (filters.onlyWithUnresolvedAdmins = true)

    // Subscription type
    const [subscriptionType] = useUrlParam({param: 'subscriptionType', dataType: 'string', defaultValue: ''})
    subscriptionType && (filters.paymentPackages = [subscriptionType])

    const [latestFilters, setLatestFilters] = useState(filters)

    useEffect(() => {
        setLatestFilters(filters)
    }, [countries, clubName, unresolvedAdmins, subscriptionType])

    const handleClearAllFilters = () => {
        removeMultipleParams(['countries', 'clubName', 'unresolvedAdmins', 'subscriptionType'], history)
    }

    return [latestFilters, handleClearAllFilters]
}