import React, {useState} from 'react'
import {Avatar, Box, Grid, IconButton, Typography} from "@material-ui/core"
import {useTranslation} from "react-i18next";
import {useListUtilStyle} from "../common/List/listUtil.style";
import {useHistory} from "react-router";
import {ListColumn} from "../common/List/List";
import Job, {JobStatus} from "../../models/job.model";
import {getJobClub, getJobClubLogo, getJobLocation} from "../../util/opportunity.util";
import {useListData} from "../../util/data/useListData";
import OpportunityService from "../../services/opportunity.service";
import {useBreadcrumbs} from "../../util/data/useBreadcrumbs";
import EButton from "../common/EButton";
import JobFilterActions from "./JobFilterActions.component";
import EditIcon from '@material-ui/icons/Edit';
import {GeneralSet} from "../common/generalSet";
import ExportJobsPopup from "./ExportOpportunitiesPopup/ExportJobsPopup";
import {useJobFilters} from "./useJobFilters";
import {SET_ALGOBUG_NOTIFICATIONS_JOB, SET_SELECTED_FOR_EXPORT} from "../../store/actions/actionTypes";
import {useDispatch} from "react-redux";
import Applicants from "./details/Applicants.component";
import {ApplicationStatus} from "../../models/opportunityApplication.model";
import {isPhAdmin} from "../../util/profile.util";
import {useUser} from "../../store/store.utils";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {useUrlParam} from "../../util/data/url.util";
import {encode} from "js-base64";

const JobsList = () => {

    //region State

    const classes = useListUtilStyle()
    const history = useHistory()
    const {t} = useTranslation()
    const [exportWhich, setExportWhich] = useState('')
    const [filters] = useJobFilters()
    const dispatch = useDispatch()
    const [quickOpenJob, setQuickOpenJob] = useState<GeneralSet<number>>(new GeneralSet())
    const user = useUser()

    const [applicationStatuses] = useUrlParam<ApplicationStatus[]>({
        param: 'applicationStatus',
        dataType: 'complex',
        defaultValue: []
    })

    const columns: ListColumn[] = [
        {name: t('Id'), id: 'id', width: 'm'},
        {name: t('Name'), id: 'name', width: 'grow'},
        {name: t('Club id'), id: 'clubId', width: 's'},
        {name: t('Positions'), id: 'positions', width: 'm'},
        {name: t('Age'), id: 'age', width: 's'},
        {name: t('Applicants'), id: 'applicants', width: 's'},
        {name: t('Location'), id: 'location', width: 'm'},
    ]

    const getPositions = (job: Job) => {
        if (job.positionMode === 'section') {
            return job.section
        }

        if (job.positions && job.positions.length) {
            return job.positions.map((position: any) => t(`position_${position}_short`)).join(', ')
        }

        return '-'
    }

    const getClubName = (opportunity: Job) => {
        if (!opportunity.club?.isRequest) {
            return opportunity.club?.name || '-'
        }

        if (opportunity.club.isRequest) {
            return (opportunity.club?.name || '-') + ' (req)'
        }

        return '-'
    }

    const renderCell = (job: Job, column: ListColumn) => {

        let dynamicStyle;
        switch (job.opportunityStatus) {
            case JobStatus.ACTIVE:
                dynamicStyle = {
                    background: '#57e82b'
                }
                break;
            case JobStatus.INACTIVE:
                dynamicStyle = {
                    background: '#f85151'
                }
                break;
            case JobStatus.NOT_APPROVED:
            default:
                dynamicStyle = {
                    background: '#f6de3d'
                }
        }

        switch (column.id) {
            case 'id':
                return (
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                    >
                        <Typography variant={'body1'}>{job.id}</Typography>
                        <IconButton
                            onClick={e => {
                                e.stopPropagation()
                                if (!job.id) return
                                history.push(`/admin/job/${job.id}`)
                            }}
                        >
                            <VisibilityIcon/>
                        </IconButton>
                    </Box>
                )
            case 'name':
                return (
                    <React.Fragment>
                        <Avatar className={classes.avatar}
                                src={getJobClubLogo(job)}>
                            {getClubName(job)?.substring(0, 2)}
                        </Avatar>

                        <Box style={{
                            width: 7,
                            height: 40,
                            ...dynamicStyle,
                            marginRight: 10
                        }}/>
                        <Typography variant={'body1'}>
                            {getClubName(job)}
                        </Typography>

                    </React.Fragment>
                )
            case 'clubId':
                return (
                    <Typography variant={'body1'}>{getJobClub(job)?.id}</Typography>
                )
            case 'positions':
                return (
                    <Typography variant={'body1'}>{getPositions(job)}</Typography>
                )
            case 'age':
                return (
                    <Typography variant={'body1'}>{`${job.fromAge} - ${job.toAge}`}</Typography>
                )
            case 'applicants':
                return (
                    <Typography variant={'body1'}>{job.applicants?.totalElements}</Typography>
                )
            case 'location':
                return (
                    <Typography variant={'body1'}>{getJobLocation(t, job)}</Typography>
                )
            case 'editJob':
                return (
                    <EButton
                        color={'primary'}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            history.push(`/admin/job/${job.id}`)
                        }}
                    >
                        <EditIcon/>
                    </EButton>
                )
            default:
                return null
        }
    }

    const shouldShowSection = (status: ApplicationStatus) => {
        return !applicationStatuses.length || !!applicationStatuses.find(s => s === status)
    }

    const renderRowAdditionalComponent = (job: Job) => {
        if (quickOpenJob.has(job.id!)) {
            return (
                <Box
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    minHeight={'fit-content'}
                    key={'applicants' + job.id}
                >
                    {
                        shouldShowSection(ApplicationStatus.SHORTLISTED) &&
                        <Applicants
                            title={t('Top pick applicants')}
                            job={job}
                            status={ApplicationStatus.SHORTLISTED}
                            noApplicantsText={'No Top Picks... Go to "Other applications" and make some players top picks...'}
                            backgroundColor={'#217748'}
                            titleColor={'white'}
                            multiselectable
                        />
                    }

                    {
                        shouldShowSection(ApplicationStatus.INVITED) &&
                        <Applicants
                            title={t('Invited applicants')}
                            job={job}
                            status={ApplicationStatus.INVITED}
                            noApplicantsText={'No Top Picks... Go to "Other applications" and make some players top picks...'}
                            backgroundColor={'#4a4a4a'}
                            titleColor={'white'}
                            multiselectable
                        />
                    }

                    {
                        shouldShowSection(ApplicationStatus.CREATED) &&
                        <Applicants
                            title={t('Other applicants')}
                            job={job}
                            status={ApplicationStatus.CREATED}
                            noApplicantsText={'No Invited applicants... You can invite applicants from either "Top picks" or "Other applicants" section...'}
                            multiselectable
                        />
                    }

                    {
                        isPhAdmin(user) && shouldShowSection(ApplicationStatus.REJECTED) &&
                        <React.Fragment>
                            <Box marginLeft={3}>
                                <Typography variant={'h3'}>{t('Rejected applicants')}</Typography>
                            </Box>
                            <Applicants
                                job={job}
                                status={ApplicationStatus.REJECTED}
                                noApplicantsText={'No applicants here...'}
                                multiselectable
                            />
                        </React.Fragment>
                    }

                </Box>
            )
        }

        return null
    }

    const listData = useListData<Job>({
        list: (page, size, search) => OpportunityService.getAllJobsAdmin({
            page: page,
            size: size,
            sort: {
                orders: [{prop: 'id', dir: 'desc'}]
            }
        }, filters),
        renderCell,
        renderRowAdditionalComponent,
        columns,
        onItemClick: job => setQuickOpenJob(quickOpenJob.has(job.id!) ? quickOpenJob.deleteImmutable(job.id!) : quickOpenJob.addImmutable(job.id!)),
        onItemMiddleClick: job => window.open(`${process.env.REACT_APP_WEB_URL}/admin/job/${job.id}`, '_blank'),
        onAdd: () => history.push(`/admin/job/new`),
        listId: 'jobsList',
        filters: filters,
        onNewLoadTriggered: () => dispatch({type: SET_SELECTED_FOR_EXPORT, payload: new GeneralSet()})
    })

    // useEffect(() => {
    //     listData.fetchItems()
    // }, [filters])

    const breadcrumbs = useBreadcrumbs({
        path: [
            {label: t('Jobs')}
        ]
    })

    const changeStatus = async (job: Job, status: JobStatus) => {
        try {
            const opportunityWithStatus = {
                ...job,
                opportunityStatus: status
            }
            const updatedOpportunity = await OpportunityService.updateOpportunity(opportunityWithStatus)

            return {
                ...updatedOpportunity,
                location: {
                    ...job.location
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const deleteOpportunity = async (opportunity: Job) => {
        try {
            const opportunityWithDelete = {
                ...opportunity,
                deleted: true
            }
            return await OpportunityService.updateOpportunity(opportunityWithDelete)
        } catch (e) {
            console.log(e)
        }
    }

    const onActivateSelected = async () => {
        let updated = []
        for (let opportunity of listData.selected.values()) {
            if (opportunity.club?.isRequest) continue
            const result = await changeStatus(opportunity, JobStatus.ACTIVE)
            result && updated.push(result)
        }

        const newOpportunities = [...(listData.items?.content || [])]
        updated.forEach(opportunity => {
            const index = newOpportunities.findIndex(o => o.id === opportunity.id)
            newOpportunities[index] = opportunity
        })
        listData.setItems({
            ...listData.items,
            content: newOpportunities
        })

        const newSelectedOpportunities = [...listData.selected.values()]
        updated.forEach(opportunity => {
            const index = newSelectedOpportunities.findIndex(o => o.id === opportunity.id)
            index >= 0 && (newSelectedOpportunities[index] = opportunity)
        })
        listData.setSelected(new GeneralSet(newSelectedOpportunities))
    }

    const onDeactivateSelected = async () => {
        let updated = []
        for (let opportunity of listData.selected.values()) {
            if (opportunity.club?.isRequest) continue
            const result = await changeStatus(opportunity, JobStatus.INACTIVE)
            result && updated.push(result)
        }

        const newOpportunities = [...(listData.items?.content || [])]
        updated.forEach(opportunity => {
            const index = newOpportunities.findIndex(o => o.id === opportunity.id)
            newOpportunities[index] = opportunity
        })
        listData.setItems({
            ...listData.items,
            content: newOpportunities
        })

        const newSelectedOpportunities = [...listData.selected.values()]
        updated.forEach(opportunity => {
            const index = newSelectedOpportunities.findIndex(o => o.id === opportunity.id)
            index >= 0 && (newSelectedOpportunities[index] = opportunity)
        })
        listData.setSelected(new GeneralSet(newSelectedOpportunities))
    }

    const onDeleteSelected = async () => {
        let deleted: Job[] = []
        for (let opportunity of listData.selected.values()) {
            const result = await deleteOpportunity(opportunity)
            result && deleted.push(result)
        }

        const newOpportunities = listData.items?.content?.filter(opportunity => !deleted.find(o => o.id === opportunity.id))
        listData.setItems({
            ...listData.items,
            content: newOpportunities
        })

        const newSelectedOpportunties = listData.selected.values().filter(opportunity => !deleted.find(o => o.id === opportunity.id))
        listData.setSelected(new GeneralSet(newSelectedOpportunties))
    }

    //endregion

    //region UI

    return (
        <Box>

            {breadcrumbs}

            <Grid container spacing={3} style={{flex: 1}}>
                <Grid item xs={3} md={3} lg={3} xl={2}>
                    <JobFilterActions
                        selected={listData.selected}

                        onExportAll={() => setExportWhich('all')}
                        onExportSelected={() => setExportWhich('selected')}

                        onActivateSelected={onActivateSelected}
                        onDeactivateSelected={onDeactivateSelected}
                        onDeleteSelected={onDeleteSelected}

                        onAnalyzeNotificationRecipients={job => {
                            dispatch({
                                type: SET_ALGOBUG_NOTIFICATIONS_JOB,
                                payload: job
                            })

                            history.push('/admin/algobug?tab=1')
                        }}

                        onPrintApplicants={() => {
                            const ids = encode(JSON.stringify(listData.selected.values().map(j => j.id)))
                            const statuses = encode(JSON.stringify(applicationStatuses.length ? applicationStatuses : [ApplicationStatus.INVITED, ApplicationStatus.CREATED, ApplicationStatus.SHORTLISTED, ApplicationStatus.REJECTED]))
                            // history.push(`/admin/printJobs?jobId=${ids}&applicationStatus=${statuses}`)
                            history.push(`/admin/printJobs`)
                        }}
                    />
                </Grid>

                <Grid item xs={9} md={9} lg={9} xl={10}>
                    {listData.renderList()}
                </Grid>

            </Grid>

            <ExportJobsPopup
                open={!!exportWhich}
                onClose={() => setExportWhich('')}
                selected={listData.selected.values()}
                exportType={exportWhich}
                filters={filters}
            />

        </Box>
    )

    //endregion UI
}

export default JobsList
