import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, TextField, Typography} from "@material-ui/core";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import {emptyNumberValidator, isFormValid, validateForm} from "../../util/form.util";
import AlgorithmService from "../../services/algorithm.service";
import PositionMatrix from "./PositionMatrix.component";

const useStyles = makeStyles(theme => ({
    container: {
        width: 'calc(100% - 20px)',
        maxWidth: '1000px',
        padding: '16px',
        marginBottom: '100px',
        //min-height: 100px;
    },
    header: {
        marginBottom: '30px',
        marginTop: '30px',
        justifyContent: 'space-between',
        width: '100%',
    },
    row: {
        width: '100%',
        justifyContent: 'space-between',
    },
    info: {
        fontSize: '10px'
    },
    containerTitle: {
        width: '100%',
        textAlign: 'left',
        fontSize: '20px',
        letterSpacing: '0.15px',
        color: '#000000'

    },
    input: {
        width: 'calc(100% - 100px)'
    },
    done: {
        marginTop: '20px !important'
    },
    shareInput: {
        background: '#f6f6f6',
        fontWeight: 'bold'
    },
    sectionSeparator: {
        width: '100%',
        height: 20,
        marginTop: '30px',
        marginBottom: '30px',
        background: '#d7d7d7'
    }
}))

const ApplicantsRanking = () => {

    const classes = useStyles()

    // ***** State ***** //

    const sections = [
        {
            id: 'basicProfileShare',
            placeholder: 'Basic Profile Share (0.0 - 1.0)',
            fields: [
                {
                    id: 'mainPosition',
                    placeholder: 'Main Position multiplier'
                },
                {
                    id: 'secondaryPositions',
                    placeholder: 'Secondary Positions multiplier',
                },

                {
                    id: 'levelDiffPointsCaseThree',
                    placeholder: 'Applicant two or more levels better than team',
                },
                {
                    id: 'levelDiffPointsCaseTwo',
                    placeholder: 'Applicant one level better than team',
                },
                {
                    id: 'levelDiffPointsCaseOne',
                    placeholder: 'Applicant same level as team',
                },
                {
                    id: 'levelDiffPointsCaseFour',
                    placeholder: 'Applicant one level worse than team',
                },
                {
                    id: 'levelDiffPointsCaseFive',
                    placeholder: 'Applicant two levels worse than team',
                },
                {
                    id: 'levelDiffPointsCaseSix',
                    placeholder: 'Applicant three or more levels worse than team',
                },


                {
                    id: 'foot',
                    placeholder: 'Foot',
                },
                {
                    id: 'height',
                    placeholder: 'Height',
                },
                {
                    id: 'language',
                    placeholder: 'Language',
                },
                {
                    id: 'nationality',
                    placeholder: 'Nationality',
                },
            ]
        },
        {
            id: 'statsShare',
            placeholder: 'Stats Share (0.0 - 1.0)',
            fields: []
        },
        {
            id: 'videoSkillShare',
            placeholder: 'Video Skill Share (0.0 - 1.0)',
            fields: [
                {
                    id: 'skillVideoNumber',
                    placeholder: 'Number of videos needed to max out',
                },
            ]
        }
    ];

    const createFormField = (placeholder: string, value: number) => {
        return {
            value: value,
            placeholder: placeholder,
            type: 'number',
            validation: {
                validators: [emptyNumberValidator]
            }
        }
    }

    const [form, setForm] = useState<any>();
    const [savingInProgress, setSavingInProgress] = useState(false);
    const [error, setError] = useState(false);

    // ***** Effects ***** //

    const loadForm = async () => {
        const form: any = {};

        const weights = await AlgorithmService.getApplicantsRankingWeights();

        sections.forEach(section => {
            form[section.id] = createFormField(section.placeholder, weights[section.id]);
            section.fields.forEach(field => form[field.id] = createFormField(field.placeholder, weights[field.id]));
        });

        setForm(form);
    }

    useEffect(() => {
        loadForm();
    }, [])

    // ***** Handlers ***** //

    const inputHandler = (key: string, value: string) => {
        const formElement = {
            ...form[key],
            validation: {
                ...form[key].validation,
                error: ''
            }
        };
        formElement.value = +value;
        setForm({
            ...form,
            [key]: formElement
        });
    };

    const saveHandler = async () => {
        const newForm = validateForm(form);
        setError(newForm);

        if (isFormValid(newForm)) {
            const weights: any = {};

            sections.forEach(section => {
                weights[section.id] = form[section.id].value;
                section.fields.forEach(field => weights[field.id] = form[field.id].value);
            });

            try {
                setSavingInProgress(true);
                setError(false);
                await AlgorithmService.updateApplicantsRankingWeights(weights)
            } catch (e) {
                setError(true);
            } finally {
                setSavingInProgress(false);
            }
        }
    };

    // ***** Render ***** //

    const renderInputField = (key: string) => {
        const formElement = form[key];
        return (
            <TextField
                onChange={(event) => inputHandler(key, event.target.value)}
                value={formElement.value}
                className={classes.input}
                type={formElement.type}
                label={formElement.placeholder}
                margin="normal"
                variant={'outlined'}
                error={!!formElement.validation.error}
                helperText={formElement.validation.error}
            />
        )
    };

    const getValueById = (id: string) => form?.[id]?.value;

    const totalForBasicSection = () => {
        return getValueById('mainPosition') * 50 +
            getValueById('secondaryPositions') * 50 +
            getValueById('levelDiffPointsCaseThree') +
            getValueById('foot') +
            getValueById('height') +
            getValueById('language') +
            getValueById('nationality');
    }

    const renderPercentForKey = (field: any) => {
        let percent = 0;
        switch (field.id) {
            case 'mainPosition':
                percent = getValueById('mainPosition') * 50 / totalForBasicSection() * getValueById('basicProfileShare');
                break;
            case 'secondaryPositions':
                percent = getValueById('secondaryPositions') * 50 / totalForBasicSection() * getValueById('basicProfileShare');
                break;
            case 'levelDiffPointsCaseThree':
                percent = getValueById('levelDiffPointsCaseThree') / totalForBasicSection() * getValueById('basicProfileShare');
                break;
            case 'foot':
                percent = getValueById('foot') / totalForBasicSection() * getValueById('basicProfileShare');
                break;
            case 'height':
                percent = getValueById('height') / totalForBasicSection() * getValueById('basicProfileShare');
                break;
            case 'language':
                percent = getValueById('language') / totalForBasicSection() * getValueById('basicProfileShare');
                break;
            case 'nationality':
                percent = getValueById('nationality') / totalForBasicSection() * getValueById('basicProfileShare');
                break;
            default:
                return null;
        }

        return (
            <div>
                {(percent * 100).toFixed(2)}%
            </div>
        );
    };

    const renderSectionPercent = (sectionId: string) => {
        return (
            <div>
                {(getValueById(sectionId) * 100).toFixed(2)}%
            </div>
        )
    }

    const renderForm = () => {
        if (!form) {
            return null;
        }

        return sections.map(section => (
            <React.Fragment>
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    className={clsx(classes.row, classes.shareInput)}
                >
                    {renderInputField(section.id)}
                    {renderSectionPercent(section.id)}
                </Box>

                {
                    section.fields.map(field => (
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            className={classes.row}
                        >
                            {renderInputField(field.id)}
                            {renderPercentForKey(field)}
                        </Box>
                    ))
                }

                <div className={classes.sectionSeparator}></div>

            </React.Fragment>
        ));

    }


    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} className={clsx('ph-container', classes.container)}>

            <PositionMatrix algorithm={'applicant'}/>

            <p className={classes.containerTitle}>{'Applicant ranking'}</p>

            <Box display={'flex'} alignItems={'center'} className={classes.header}>
                <Typography>Weight</Typography>
                <Typography>Effect on calculation (%)</Typography>
            </Box>

            {renderForm()}

            {error && <div>Something went wrong.Please try again later</div>}

            {
                savingInProgress ?

                    <CircularProgress/> :

                    <Button variant="contained" color="primary" className={[classes.done].join(' ')}
                            onClick={saveHandler}>
                        Save
                    </Button>
            }

        </Box>

    );
}

export default ApplicantsRanking;
