import React, {useCallback, useEffect, useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@material-ui/core"
import debounce from "lodash/debounce"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";
import ClubModel from "../../../../models/club.model";
import {getClubName} from "../../../../util/profile.util";
import ClubBadge from "../../ClubBadge/ClubBadge";
import EButton from "../../EButton";
import PickClubPopup from "../../PickClubPopup/PickClubPopup";

const ClubFilter = () => {

    //region State

    const [pickClubPopupOpen, setPickClubPopupOpen] = useState(false)
    const [club] = useUrlParam<ClubModel | null>({param: 'club', dataType: 'complex', defaultValue: null})
    const [expanded, setExpanded] = useState(!!club?.id)
    const history = useHistory()

    //endregion State

    //region Handlers

    const executeSet = useCallback(debounce((club: any) => {
        setMultipleParams([
            {name: 'club', value: club, dataType: 'complex'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }, 1000), [])

    // useEffect(() => {
    //     executeSet(club)
    // }, [user])

    //endregion Handlers


    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="club-content"
                id="club-header"
            >
                <Typography>Club
                    {!!club?.id && `(${getClubName(club)})`}
                </Typography>
            </AccordionSummary>

            <AccordionDetails>

                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} style={{marginTop: 1, width: '100%'}}>
                    <ClubBadge
                        club={club || undefined}
                        clicked={() => setPickClubPopupOpen(true)}
                        clubNameEmptyText={'Select a club'}
                        // rowDisplay
                        style={{width: 'calc(100% - 100px)', justifyContent: 'flex-start'}}
                    />

                    {
                        <EButton
                            style={{marginLeft: 'auto'}}
                            onClick={() => {
                                executeSet(null)
                            }}
                        >
                            Remove
                        </EButton>
                    }

                    <PickClubPopup
                        open={pickClubPopupOpen}
                        onPick={(club) => {
                            executeSet(club)
                            setPickClubPopupOpen(false)
                        }}
                        onClose={() => setPickClubPopupOpen(false)}
                    />
                </Box>

            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default ClubFilter
