import React from 'react'
import {Box, Card} from "@material-ui/core"
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {useTranslation} from "react-i18next";
import GroupIcon from '@material-ui/icons/Group';
import {useTabs} from "../../util/data/useTabs";
import Billing from "../billing/Billing/Billing.component";
import Account from "../auth/Account/Account";

const ClubAccount = () => {

    //region State

    const {t} = useTranslation()

    const tabs = [
        {icon: <InfoOutlinedIcon/>, label: t('My profile')},
        {icon: <GroupIcon/>, label: t('Billing')},
    ]


    const tabsData = useTabs({
        tabs: tabs,
        showDelete: false,
        // showSave: itemData.isDirty,
        // canSave: itemData.isValid,
        // onSave: handleSave,
        // onDelete: itemData.remove,
        // showButtonLoaders: itemData.saving,
        canDelete: false
    })

    //endregion

    //region UI

    return (
        <Box marginTop={4}>

            <Card>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                >
                    {tabsData.renderTabs()}

                </Box>

                {
                    tabsData.tab === 0 &&
                    <Account/>
                }

                {
                    tabsData.tab === 1 &&
                    <Billing/>
                }

            </Card>

            {/*{itemData.renderSnackbars()}*/}

        </Box>
    )

    //endregion UI
}

export default ClubAccount
