import React, {useCallback, useEffect, useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@material-ui/core"
import debounce from "lodash/debounce"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";
import ETextField from "../../ETextField.component";

const SalaryFilter = () => {

    //region State

    const [fromSalaryParam] = useUrlParam({param: 'fromSalary', dataType: 'numeric', defaultValue: 0})
    const [toSalaryParam] = useUrlParam({param: 'toSalary', dataType: 'numeric', defaultValue: 0})

    const [fromSalaryLocal, setFromSalaryLocal] = useState(fromSalaryParam)
    const [toSalaryLocal, setToSalaryLocal] = useState(toSalaryParam)
    const [manualInput, setManualInput] = useState(false)

    const [expanded, setExpanded] = useState(!!fromSalaryLocal && !!toSalaryLocal)
    const history = useHistory()

    //endregion State

    //region Handlers

    const executeSet = useCallback(debounce((fromSalary: any, toSalary: any) => {
        setMultipleParams([
            {name: 'fromSalary', value: fromSalary, dataType: 'numeric'},
            {name: 'toSalary', value: toSalary, dataType: 'numeric'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }, 1000), [])

    useEffect(() => {
        if (!manualInput) return
        executeSet(fromSalaryLocal, toSalaryLocal)
    }, [fromSalaryLocal, toSalaryLocal, manualInput])

    //endregion Handlers

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="salary-content"
                id="salary-header"
            >
                <Typography>Salary {!!fromSalaryLocal && !!toSalaryLocal && `(${fromSalaryLocal} - ${toSalaryLocal})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container spacing={1}>
                    <ETextField
                        label={'From'}
                        grid={'1/2'}
                        value={fromSalaryLocal}
                        onChange={e => {
                            setManualInput(true)
                            setFromSalaryLocal(+e)
                        }}
                    />
                    <ETextField
                        label={'To'}
                        grid={'1/2'}
                        value={toSalaryLocal}
                        onChange={e => {
                            setManualInput(true)
                            setToSalaryLocal(+e)
                        }}
                    />
                </Grid>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default SalaryFilter
