import React, {useState} from 'react'
import {Box, Card, CircularProgress} from "@material-ui/core"
import {useHistory, useParams} from "react-router"
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {useItemData} from "../../../util/data/useItemData"
import {useBreadcrumbs} from "../../../util/data/useBreadcrumbs"
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import {useTranslation} from "react-i18next";
import ClubModel from "../../../models/club.model";
import ClubService from "../../../services/club.service";
import {getClubName} from "../../../util/profile.util";
import {useTabs} from "../../../util/data/useTabs";
import ClubInfo from "./ClubInfo.component";
import _ from "lodash";
import GroupIcon from '@material-ui/icons/Group';
import ClubAdmins from "./ClubAdmins.component";
import AuthService from "../../../services/auth.service";

interface Params {
    id: string
}

const ClubDetails = () => {

    //region State

    const params = useParams<Params>()
    const id = params.id
    const history = useHistory()
    const {t} = useTranslation()
    const [loginAsClubInProgress, setLoginAsClubInProgress] = useState(false)

    const itemData = useItemData<ClubModel>({
        get: () => ClubService.getClub(+id, false, false),
        // isValid: item => !!item?.profile,
        deps: [id]
    })


    const handleSave = async () => {
        try {
            itemData.setSaving(true)

            let newClub = itemData?.item
            if (!_.isEqual(itemData.originalItem, itemData?.item)) {
                newClub = await ClubService.updateClubInfo(itemData?.item!)
            }

            const updatedClub: ClubModel = {
                ...itemData?.item,
            }
            itemData.setOriginalItem(updatedClub)
            itemData.setItem(updatedClub)
        } catch (e) {
            console.log(e)
        } finally {
            itemData.setSaving(false)
        }
    }

    const tabs = [
        {icon: <InfoOutlinedIcon/>, label: t('Club info')},
        {icon: <GroupIcon/>, label: t('Admins')},
        {icon: <CropOriginalIcon/>, label: t('Gallery')},
    ]


    const tabsData = useTabs({
        tabs: tabs,
        showDelete: false,
        showSave: itemData.isDirty,
        canSave: itemData.isValid,
        onSave: handleSave,
        onDelete: itemData.remove,
        showButtonLoaders: itemData.saving,
        canDelete: false
    })

    const breadcrumbs = useBreadcrumbs({
        path: [
            {label: t('Clubs'), url: '/admin/club'},
            {label: getClubName(itemData.item, '-')}
        ]
    })

    const handleLoginAsClub = async () => {
        try {
            setLoginAsClubInProgress(true);
            const response = await AuthService.loginAsClub(itemData.item);
            if (!response) {
                // TODO - display error?
            } else {
                window.open(process.env.webUrl, "_self");
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoginAsClubInProgress(false);
        }
    };

    //endregion

    //region UI

    return (
        <Box>

            {breadcrumbs}

            <Card>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                >
                    {tabsData.renderTabs()}

                    {loginAsClubInProgress && <CircularProgress/>}

                    {/*{*/}
                    {/*    !loginAsClubInProgress && !itemData.isDirty &&*/}
                    {/*    <Box*/}
                    {/*        marginRight={3}*/}
                    {/*    >*/}
                    {/*        <EButton*/}
                    {/*            variant={'contained'}*/}
                    {/*            onClick={handleLoginAsClub}*/}
                    {/*        >*/}
                    {/*            Login as club*/}
                    {/*        </EButton>*/}
                    {/*    </Box>*/}
                    {/*}*/}

                </Box>

                {itemData.loading && <CircularProgress/>}

                {
                    !itemData.loading && tabsData.tab === 0 &&
                    <ClubInfo club={itemData.item} setClub={itemData.setItem}/>
                }

                {
                    !itemData.loading && tabsData.tab === 1 &&
                    <ClubAdmins club={itemData.item} setClub={club => {
                        itemData.setOriginalItem(club)
                        itemData.setItem(club)
                    }}/>
                }

                {/*    {*/}
                {/*        !itemData.loading && isPlayer && tabsData.tab === 1 &&*/}
                {/*        <PlayerProfile user={itemData.item} setUser={itemData.setItem}/>*/}
                {/*    }*/}

            </Card>

            {itemData.renderSnackbars()}

        </Box>
    )

    //endregion UI
}

export default ClubDetails
