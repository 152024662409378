import React, {FunctionComponent} from 'react';
import Dialog from "@material-ui/core/Dialog";
import clsx from 'clsx';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button} from "@material-ui/core";

const useStyles = makeStyles(_theme => ({
    container: {
        padding: 20
    },
    image: {
        width: 50,
        height: 50,
        marginTop: 5,
    },
    alertText: {
        textAlign: 'center',
        fontSize: 16,
        lineHeight: '24px',
        letterSpacing: '0.15px',
        margin: 20
    },
    contentInfoContainer: {
        background: 'rgba(244, 245, 249, 0.51)',
        borderRadius: 8,
        width: '100%',
        padding: 16,
        marginBottom: 25
    },
    section: {
        color: _theme.palette.primary.main,
        fontSize: 16,
        marginBottom: 6
    },
    secondSection: {
        marginTop: 20
    },
    link: {
        fontSize: 14,
        color: '#000',
        marginBottom: 10
    },
    linkSubtitle: {
        fontSize: 14,
        color: '#000'
    }
}))

interface Props {
    open: boolean,
    onClose: () => void
}

const ClubAlreadyClaimed: FunctionComponent<Props> = (props: Props) => {

    const classes = useStyles()
    const t = useTranslation().t;

    return (
        <Dialog {...props} aria-labelledby="simple-dialog-title" maxWidth={"xs"} fullWidth={true}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                className={classes.container}
            >
                <img alt={'Warning'} src={require('../../assets/images/icons/warning-red.svg').default}
                     className={classes.image}/>
                <Box display={'flex'} className={classes.alertText}>
                    <div>{t("this_club_already_has_a_ph_profile")}</div>
                </Box>
                <Box
                    className={classes.contentInfoContainer}
                    display={'flex'}
                    flexDirection={'column'}
                >

                    <div className={classes.section}>{t("call_us")}</div>
                    <a className={classes.link} href="tel:+436645154196">+43 664 5154196 (Austria)</a>
                    <a className={classes.link} href="tel:+38163449091">+381 63 449 091 (Srbija)</a>
                    <div className={classes.linkSubtitle}>{t("monday")} - {t("friday")} | 9:00 - 16:00</div>

                    <div className={clsx(classes.section, classes.secondSection)}>{t("write_us")}</div>
                    <a className={classes.link} href="mailto:support@playerhunter.com">support@playerhunter.com</a>
                    <div className={classes.linkSubtitle}>{t("we_will_get_to_you_in_24h")}</div>

                </Box>
                <Button variant="contained" color="primary" onClick={props.onClose}>
                    {t("done")}
                </Button>
            </Box>
        </Dialog>
    )
}

export default ClubAlreadyClaimed;
