import React, {FunctionComponent, useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from './EditNamePopup.module.scss'
import {Box, Button, DialogActions, TextField} from '@material-ui/core';
import ClubModel from "../../../../../models/club.model";
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean,
    history?: any,
    onClose: () => void,
    onSave: (about: string) => void,
    club?: ClubModel,
}


const EditNamePopup: FunctionComponent<Props> = (props: Props) => {
    const [text, setText] = useState('');
    const t = useTranslation().t;

    useEffect(() => {
        setText(props.club?.name || '')
    }, [props.club]);

    return (
        <Dialog aria-labelledby="simple-dialog-title" {...props} maxWidth={"xs"} fullWidth={true}>

            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                className={styles.container}>

                <TextField
                    id="filled-multiline-flexible"
                    label={t("club_name")}
                    placeholder={t('type_club_name')}
                    value={text}
                    className={styles.input}
                    onChange={(e) => setText(e.target.value)}
                    margin="normal"
                />

            </Box>

            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Close
                </Button>
                <Button onClick={() => props.onSave(text)} color="primary" autoFocus>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default EditNamePopup;
