import React from 'react';
import {useTranslation} from "react-i18next";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

interface Props {
    preferredFoot?: string;
    onPreferredFootSelected: (preferredFoot: string | null) => void,
}

const useStyle = makeStyles(theme => ({
    option: {
        width: 95,
        height: 50,
        cursor: 'pointer',
        borderBottom: '2px solid #FBFBFB',
        color: 'rgba(0, 0, 0, 0.61)',
        fontSize: '14px',
        background: '#FBFBFB',
        textAlign: 'center'
    },
    genderImg: {
        width: 14,
        height: 14
    },
    selected: {
        //background: linear-gradient(169deg, #e40015 0%, #750075 100%);
        borderBottom: `2px solid ${theme.palette.primary.main}`
    }

}))

const PreferredFootSelector = (props: Props) => {
    const t = useTranslation().t;
    const classes = useStyle()

    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
        >
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                className={[classes.option, 'filledHover', props.preferredFoot === 'LEFT' ? classes.selected : ''].join(' ')}
                onClick={() => props.onPreferredFootSelected('LEFT')}><p>{t("left")}</p></Box>
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                className={[classes.option, 'filledHover', !props.preferredFoot || props.preferredFoot === 'BOTH' ? classes.selected : ''].join(' ')}
                onClick={() => props.onPreferredFootSelected(null)}><p>{t("both")}</p></Box>
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                className={[classes.option, 'filledHover', props.preferredFoot === 'RIGHT' ? classes.selected : ''].join(' ')}
                onClick={() => props.onPreferredFootSelected('RIGHT')}><p>{t("right")}</p>
            </Box>
        </Box>
    )
};

export default PreferredFootSelector;
