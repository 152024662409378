import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    Checkbox,
    CircularProgress,
    DialogActions,
    DialogContent, LinearProgress,
    TextField,
    Typography
} from "@material-ui/core";
import {useListStyle} from "./list.style";
import BaseModel from "../../../models/base.model";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Pagination} from "@material-ui/lab";
import {debounce} from 'lodash'
import {GeneralSet} from "../generalSet";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import ETextField from "../ETextField.component";

export interface ListColumn {
    name: string,
    id: string,
    width?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl' | 'grow'
}

interface Props<T extends BaseModel> {
    loading?: boolean
    items?: T[]
    columns: ListColumn[]
    renderCell?: (item: T, column: ListColumn) => JSX.Element | null
    renderRowAdditionalComponent?: (item: T) => JSX.Element | null
    customRowStyle?: (item: T) => string | null

    hideTopSection?: boolean,
    size?: number,
    onSizeChange?: (size: number) => void

    page?: number,
    onPageChange?: (page: number) => void
    totalPages?: number
    totalElements?: number

    onItemClick?: (item: T, index: number) => void
    onItemMiddleClick?: (item: T, index: number) => void,

    onAdd?: () => void
    onSearch?: (query: string) => void

    multiselect?: boolean
    selected?: GeneralSet<T>
    onSelect?: (item: T) => void
    onSelectAll?: () => void,

    classes?: {
        root?: string
    }
}

const EList = <T extends BaseModel>(props: Props<T>) => {

    const classes = useListStyle()

    const [searchQuery, setSearchQuery] = useState('')
    const {t} = useTranslation()

    const from = props.page && props.size ? (props.page - 1) * props.size + 1 : 1
    const to = from + (props.items?.length || 0) - 1
    const showingText = props.totalElements ? `Showing ${from} to ${to} of ${props.totalElements} results` : t('No items found')
    const [manualPageOpen, setManualPageOpen] = useState(false)
    const [manualPage, setManualPage] = useState<any>(props.page)

    const notifySearch = useCallback(debounce((query: string) => props.onSearch?.(query), 1000), []);

    useEffect(() => {
        notifySearch(searchQuery);
    }, [searchQuery]);

    const getColumnStyle = (column: ListColumn) => {
        switch (column.width) {
            case "xs":
                return classes.cellXS
            case "s":
                return classes.cellS
            case "l":
                return classes.cellL
            case "xl":
                return classes.cellXL
            case 'xxl':
                return classes.cellXXL
            case 'xxxl':
                return classes.cellXXXL
            case 'grow':
                return classes.cellGrow
        }
    }

    const search = (
        <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            className={classes.topBar}
        >
            <Typography variant={'body1'}>
                {showingText}
            </Typography>

            <Box display={'flex'} alignItems={'center'}>

                {
                    props.onSearch &&
                    <TextField
                        placeholder={t('Search')}
                        variant={'outlined'}
                        size={'small'}
                        className={classes.search}
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                }
                {props.onAdd && <Button color={'primary'} variant={'contained'} onClick={props.onAdd}>Add new</Button>}
            </Box>

        </Box>
    )

    const header = (
        <Box display={'flex'} style={{position: 'relative'}} className={classes.header}>

            {
                props.loading &&
                <LinearProgress
                    style={{
                        position:'absolute',
                        top: 0,
                        left: 0,
                        height: 3,
                        width: '100%'
                    }}
                />
            }

            {props.multiselect && <Checkbox
                checked={!!props.items?.length && props.selected?.size() === props.items?.length}
                onChange={props.onSelectAll}
            />}

            {
                props.columns.map(column => {
                    return (
                        <Box key={column.id} className={clsx(classes.cell, getColumnStyle(column))} display={'flex'}
                             alignItems={'center'}>
                            <div className={classes.headerText}>{column.name}</div>
                        </Box>
                    )
                })
            }

        </Box>
    )

    const loadingUI = (
        <Box className={classes.loadingBox} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <CircularProgress/>
        </Box>
    )

    const itemsList = props.items?.map((item, index) => {

        return (
            <React.Fragment>
                <Box
                    key={item.id}
                    display={'flex'}
                    className={clsx(classes.row, props.customRowStyle?.(item))}
                    onClick={() => props.onItemClick?.(item, index)}
                    onMouseDown={e => {
                        if (e.button === 1) {
                            props.onItemMiddleClick?.(item, index)
                        }
                    }}
                >

                    {
                        props.multiselect &&
                        <Checkbox
                            checked={props.selected?.has(item)}
                            onClick={e => e.stopPropagation()}
                            onChange={() => props.onSelect?.(item)}
                        />
                    }

                    {
                        props.columns.map(column => {
                            return (
                                <Box className={clsx(classes.cell, getColumnStyle(column))} display={'flex'}
                                     alignItems={'center'}>
                                    {props.renderCell?.(item, column)}
                                </Box>
                            )
                        })
                    }

                </Box>

                {props.renderRowAdditionalComponent?.(item)}

            </React.Fragment>
        )
    })

    const footer = (
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} className={classes.footer}>

            {
                props.size &&
                <Box display={'flex'} alignItems={'center'}>
                    <Typography variant={"body1"}>{t('Show')}</Typography>
                    <Select
                        variant={'standard'}
                        labelId="page-size-label"
                        value={props.size}
                        onChange={event => props.onSizeChange?.(event.target.value as number)}
                        className={classes.pageSize}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                    </Select>
                </Box>
            }

            {
                !!props.page && !!props.totalPages &&
                <Box
                    display={'flex'}
                    alignItems={'center'}
                >
                    <Pagination
                        page={props.page}
                        count={props.totalPages}
                        onChange={(_e, page) => props.onPageChange?.(page || 1)}
                        color="primary"
                    />

                    <Button
                        onClick={() => {
                            setManualPage(props.page)
                            setManualPageOpen(true)
                        }}
                    >
                        <EditIcon/>
                    </Button>
                </Box>
            }

        </Box>
    )

    return (
        <Card className={props.classes?.root}>
            {!props.hideTopSection && search}
            {header}
            {itemsList}
            {footer}

            <Dialog
                open={manualPageOpen}
                onBackdropClick={() => setManualPageOpen(false)}
            >
                <DialogContent>
                    <ETextField
                        label={'Page number'}
                        grid={'1/1'}
                        value={manualPage}
                        onChange={setManualPage}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setManualPageOpen(false)} color="primary">
                        {t('close')}
                    </Button>
                    <Button
                        disabled={!manualPage || isNaN(manualPage) || manualPage < 1}
                        onClick={() => {
                            setManualPageOpen(false)
                            props.onPageChange?.(manualPage!)
                        }} color="primary" autoFocus>
                        {t('save')}
                    </Button>
                </DialogActions>

            </Dialog>
        </Card>
    )
}

export default EList
