import React, {useEffect, useState} from "react";
import BaseModel from "../../models/base.model";
import _ from "lodash";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import {useQuery, useQueryClient} from "@tanstack/react-query";

interface Props<T extends BaseModel> {
    get?: () => Promise<T | undefined>
    update?: (id: number, item: T) => Promise<T>
    create?: (item: T) => Promise<T>
    delete?: (id: number) => Promise<void>
    isValid?: (item?: T) => boolean,
    onCreated?: (item?: T) => void,
    onDeleted?: (item?: T) => void,
    deps?: any,
    // key?: any
}

export const useItemData = <T extends BaseModel>(props: Props<T>) => {

    const [loading, setLoading] = useState(true)
    const [errorLoading, setErrorLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [errorSaving, setErrorSaving] = useState(false)
    const [originalItem, setOriginalItem] = useState<T>()
    const [item, setItem] = useState<T>()
    const isDirty = !_.isEqual(originalItem, item)
    const isValid = props.isValid ? props.isValid?.(item) : true
    // const queryClient = useQueryClient()

    const fetch = async () => {
        try {
            // if (props.key) {
            //     const result = await props.get?.()
            //     setItem(result)
            //     setOriginalItem(result)
            //     return result
            // } else {
                setLoading(true)
                const result = await props.get?.()
                setOriginalItem(result)
                setItem(result)
            // }
        } catch (e) {
            console.log(e)
            setErrorLoading(true)
        } finally {
            setLoading(false)
        }
    }

    // const keyClean = props.key ? props.key : 'dummy';
    // const query = useQuery(keyClean, async () => {
    //     if (!props.key) return
    //     return fetch()
    // })

    const loadClean = /* props.key ? query.isLoading : */ loading

    const itemClean = /* props.key ? query.data : */ item

    const setItemClean = (item?: T) => {
        setItem(item)
        // if (props.key) {
        //     queryClient.setQueryData(props.key, item)
        // }
    }

    const fetchClean = () => {
        // if (props.key) {
        //     query.refetch()
        // } else {
            fetch()
        // }
    }

    const save = async () => {
        if (!isValid) return

        try {
            if (!item?.id) {
                setSaving(true)
                const result = await props.create?.(item!)
                props.onCreated?.(result)
                setOriginalItem(result)
                setItem(result)
                setSaving(false)
            } else {
                setSaving(true)
                const result = await props.update?.(item?.id!, item)
                setOriginalItem(result)
                setItem(result)
                setSaving(false)
            }
        } catch (e) {
            console.log(e)
            setErrorSaving(true)
        } finally {
            setSaving(false)
        }
    }

    const saveThenSet = async (item: T) => {
        if (props.isValid && !props.isValid?.(item)) return

        try {
            setSaving(true)
            const result = await props.update?.(item?.id!, item)
            setOriginalItem(result)
            setItem(result)
            setSaving(false)
        } catch (e) {
            console.log(e)
            setErrorSaving(true)
        } finally {
            setSaving(false)
        }
    }

    const remove = async () => {
        if (!item?.id) return;
        setSaving(true)
        const result = await props.delete?.(item.id)
        setSaving(false)
        props.onDeleted?.(item)
    }

    const {t} = useTranslation()

    useEffect(() => {
        fetch()
    }, ...props.deps)

    const renderSnackbars = () => {
        return (
            <React.Fragment>
                <Snackbar open={errorLoading} autoHideDuration={5000} onClose={() => setErrorLoading(false)}>
                    <Alert onClose={() => setErrorLoading(false)} severity="error">
                        {t("Data could not be loaded. Try to refresh the page!")}
                    </Alert>
                </Snackbar>

                <Snackbar open={errorSaving} autoHideDuration={5000} onClose={() => setErrorSaving(false)}>
                    <Alert onClose={() => setErrorSaving(false)} severity="error">
                        {t("Changes could not be saved. Check all fields!")}
                    </Alert>
                </Snackbar>
            </React.Fragment>
        )
    }

    return {
        loading: loadClean,
        // setLoading,
        // errorLoading,
        // setErrorLoading,
        saving,
        setSaving,
        errorSaving,
        setErrorSaving,
        originalItem,
        setOriginalItem,
        item: itemClean,
        setItem: setItemClean,
        fetch: fetchClean,
        save,
        remove,
        renderSnackbars,
        isValid,
        isDirty,
        // saveThenSet
    }
}
