import React from 'react';
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

interface Props {
    gender?: string;
    onGenderSelect: (gender: string) => void
}

const useStyle = makeStyles(theme => ({
    gender: {
        width: 95,
        height: 50,
        cursor: 'pointer',
        borderBottom: '2px solid #FBFBFB',
        color: 'rgba(0, 0, 0, 0.61)',
        fontSize: '14px',
        background: '#FBFBFB',
        textAlign: 'center'
    },
    genderImg: {
      width: 14,
      height: 14
    },
    selected: {
        //background: linear-gradient(169deg, #e40015 0%, #750075 100%);
        borderBottom: `2px solid ${theme.palette.primary.main}`
    }

}))

const GenderSelector = (props: Props) => {

    const classes = useStyle()

    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
        >
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}

                className={[classes.gender, 'genderHover', props.gender === 'MALE' ? classes.selected : ''].join(' ')}
                onClick={() => props.onGenderSelect('MALE')}
            >
                <img className={classes.genderImg} alt={'Male'} src={props.gender === 'MALE' ? require('../../../assets/images/male.svg').default : require('../../../assets/images/male-white.svg').default}/>
            </Box>

            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}

                className={[classes.gender, 'genderHover', props.gender === 'FEMALE' ? classes.selected : ''].join(' ')}
                onClick={() => props.onGenderSelect('FEMALE')}
            >
                <img className={classes.genderImg} alt={'Female'} src={props.gender === 'FEMALE' ? require('../../../assets/images/female.svg').default : require('../../../assets/images/female-white.svg').default}/>
            </Box>
        </Box>
    )
};

export default GenderSelector;
