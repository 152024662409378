import React, {useEffect, useState} from 'react';
import ClubModel from "../../../../models/club.model";
import styles from './ClubAlbums.module.scss';
import clsx from 'clsx';
import EditAlbumsPopup from "./EditAlbumsPopup/EditAlbumsPopup";
import AddAlbumsPopup from "./AddAlbumsPopup/AddAlbumsPopup";
import OpenAlbumPopup from "./OpenAlbumPopup/OpenAlbumPopup";
import AlbumModel from "../../../../models/album.model";
import EditClubNotPossiblePopup from "../EditClubHeaderAndLocationPopup/EditClubNotPossiblePopup";
import UserModel from "../../../../models/user.model";
import {useTranslation} from "react-i18next";
import ClubService from "../../../../services/club.service";
import MixpanelService from '../../../../services/analytics/mixpanel.service';
import {EventName} from "../../../../services/analytics/eventName";
import {PeopleProperty} from "../../../../services/analytics/peopleProperty";
import MediaModel from "../../../../models/media.model";
import {isUserApprovedAndClubApproved, isUserClubAdmin} from "../../../../util/profile.util";
import {Box, Card} from "@material-ui/core";
import PreviewImagePopup from "../../../common/PreviewImagePopup/PreviewImagePopup";

interface Props {
    club?: ClubModel,
    user?: UserModel;
    onUpdate?: (club?: ClubModel) => void;
}

const ClubAlbums = (props: Props) => {

    // ***** State ***** //

    const [editAlbumPopupOpen, setEditAlbumPopupOpen] = useState(false);
    const [addAlbumPopupOpen, setAddAlbumPopupOpen] = useState(false);
    const [notAllowedPopupOpen, setNotAllowedPopupOpen] = useState(false);
    const [galleryPopupOpen, setGalleryPopupOpen] = useState(false);
    const [thumbnailPopupOpen, setPopupThumbnail] = useState(false);
    const [selectedAlbum, setSelectedAlbum] = useState<AlbumModel>();
    const [photoIndex, setPhotoIndex] = useState<number>(0);

    const t = useTranslation().t;

    // ***** Effects ***** //

    useEffect(() => {
        if (selectedAlbum) {
            const selectedAlbumNew = props.club?.albums?.content?.find(a => a.id === selectedAlbum.id);
            if (selectedAlbumNew) {
                setSelectedAlbum(selectedAlbumNew);
            }
        }
    }, [props.club]);

    // ***** Handlers ***** //

    const editHandler = () => {
        if (!isUserApprovedAndClubApproved(props.user, props.club)) {
            setNotAllowedPopupOpen(true);
        } else {
            setAddAlbumPopupOpen(true);
        }
    };

    const closeHandler = () => {
        setEditAlbumPopupOpen(false);
    };

    const addAlbumHandler = () => {
        setAddAlbumPopupOpen(true);
    };

    const cancelAddHandler = () => {
        setAddAlbumPopupOpen(false);
    };

    const confirmHandler = async (name: string) => {
        setAddAlbumPopupOpen(false);
        try {
            const album = await ClubService.addAlbum(props.club!, name);
            MixpanelService.track(EventName.CLUB_EDIT);
            MixpanelService.setUserProperties({
                [PeopleProperty.LAST_CLUB_EDIT]: MixpanelService.getCurrentTimeIso()
            });
            if (album) {
                props.onUpdate?.({
                    ...props.club,
                    albums: {
                        ...(props.club?.albums || []),
                        content: [album, ...(props.club?.albums?.content || [])]
                    }
                });
                openAlbumHandler(album);
            }
        } catch (err) {
        }

    };

    const openAlbumHandler = (album: AlbumModel) => {
        setPopupThumbnail(true);
        setSelectedAlbum(album);
    };

    const quitHandler = () => {
        setPopupThumbnail(false);
    };

    const deleteHandler = async () => {
        if (!selectedAlbum) return;

        try {
            const response = await ClubService.deleteAlbum(selectedAlbum);
            MixpanelService.track(EventName.CLUB_EDIT);
            MixpanelService.setUserProperties({
                [PeopleProperty.LAST_CLUB_EDIT]: MixpanelService.getCurrentTimeIso()
            });
            if (response) {
                let albums = props.club?.albums?.content?.filter(a => a.id !== selectedAlbum.id);
                props.onUpdate?.({
                    ...props.club,
                    albums: {
                        ...props.club?.albums,
                        content: albums
                    }
                });
            }
        } catch (err) {
        }

        setPopupThumbnail(false);
    };

    const addImage = async (cloudinaryResponse: any) => {
        if (!selectedAlbum) return;

        try {
            const media = await ClubService.addMedia(selectedAlbum, cloudinaryResponse);
            MixpanelService.track(EventName.CLUB_EDIT);
            MixpanelService.setUserProperties({
                [PeopleProperty.LAST_CLUB_EDIT]: MixpanelService.getCurrentTimeIso()
            });

            const album = {
                ...selectedAlbum,
                media: {
                    ...selectedAlbum.media,
                    content: [media, ...selectedAlbum.media?.content || []]
                }
            };

            const index = props.club?.albums?.content?.findIndex(a => a.id === selectedAlbum.id);
            const addImageAlbums = [...(props.club?.albums?.content || [])];
            index !== undefined && index > -1 && (addImageAlbums[index] = album);

            props.onUpdate?.({
                ...props.club,
                albums: {
                    ...(props.club?.albums || []),
                    content: addImageAlbums
                }
            })

        } catch (err) {
            console.log(err);
        }
    };

    const deleteImageHandler = async (image: MediaModel) => {
        if (!selectedAlbum) return;

        try {
            const deletedMedia = await ClubService.deleteMedia(image);

            const indexAlbum = props.club?.albums?.content?.findIndex(album => album.id === selectedAlbum.id) || 0;
            const updatedAlbum = {
                ...(props.club?.albums?.content?.[indexAlbum] || []),
                media: {
                    ...(props.club?.albums?.content?.[indexAlbum]?.media || []),
                    content: props.club?.albums?.content?.[indexAlbum]?.media?.content?.filter(img => img.id !== deletedMedia.id)
                }
            };

            const updatedAlbums = [...(props.club?.albums?.content || [])];
            updatedAlbums[indexAlbum] = updatedAlbum;

            props.onUpdate?.({
                ...props.club,
                albums: {
                    ...props?.club?.albums,
                    content: updatedAlbums
                }
            });
        } catch (err) {

        }
    }

    const handleImageOpen = (index: number) => {
        setPhotoIndex(index);
        setGalleryPopupOpen(true);
    };

    // ***** Render ***** //

    const getImageOnIndex = (index: number) => {
        if (!selectedAlbum ||
            !selectedAlbum.media ||
            !selectedAlbum.media.content ||
            index < 0 ||
            index > selectedAlbum.media.content.length - 1
        ) {
            return;
        }

        return selectedAlbum.media.content[index].url;
    };


    const albumsData = props.club?.albums?.content || [];

    const albums = albumsData.slice(0, 6).map(album => {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                className={styles.album}
                key={album.id}
                onClick={() => openAlbumHandler(album)}
            >
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={styles.albumImageHolder}
                >

                    {
                        album?.media?.content?.length
                            ?
                            <img className={styles.albumImage} src={album.media.content[0]?.url} alt=""/>
                            :
                            <img className={styles.albumNoImage}
                                 src={require('../../../../assets/images/gallery.svg').default} alt=""/>

                    }
                </Box>

                <p>{album.name}</p>
            </Box>
        )
    });

    const imagesCount = selectedAlbum?.media?.content?.length || 0;
    const nextImageIndex = (photoIndex + 1) % imagesCount;
    const prevImageIndex = (photoIndex + imagesCount - 1) % imagesCount;

    return (
        <Card
            className={styles.component}
        >
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}
            >
                <Box
                    alignItems={'center'}
                    display={'flex'}
                    width={'100%'}
                    justifyContent={'space-between'}
                >
                    <p className={styles.sectionTitle}>{t('albums')}</p>

                    {
                        isUserClubAdmin(props.user, props.club) &&
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            className={styles.editHolder}
                            onClick={editHandler}
                        >
                            <img alt={'Add'} src={require('../../../../assets/images/add-orange.svg').default}/>
                        </Box>
                    }

                </Box>
                <Box display={'flex'} flexWrap={'wrap'} className={styles.albums}>{albums}</Box>

                <EditAlbumsPopup open={editAlbumPopupOpen} club={props.club} onClose={closeHandler}
                                 onAdd={addAlbumHandler} selectedAlbumHandler={openAlbumHandler}/>

                <AddAlbumsPopup open={addAlbumPopupOpen} club={props.club} onCancel={cancelAddHandler}
                                onConfirm={confirmHandler} onThumbnail={openAlbumHandler}
                />

                <OpenAlbumPopup open={thumbnailPopupOpen}
                                club={props.club}
                                onQuit={quitHandler}
                                onDelete={deleteHandler}
                                onAddImage={addImage}
                                onImageOpen={handleImageOpen}
                                selectedAlbum={selectedAlbum}
                                onDeleteImage={deleteImageHandler}
                />

                <EditClubNotPossiblePopup
                    open={notAllowedPopupOpen}
                    onClose={() => setNotAllowedPopupOpen(false)}
                />

                <PreviewImagePopup
                    image={getImageOnIndex(photoIndex)}
                    open={galleryPopupOpen}
                    onClose={() => setGalleryPopupOpen(false)}
                    onMoveToNext={() => setPhotoIndex(nextImageIndex)}
                    onMoveToPrev={() => setPhotoIndex(prevImageIndex)}
                />

            </Box>
        </Card>
    );
}

// **** Redux **** //

export default ClubAlbums;


