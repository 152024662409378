import React, {Fragment, FunctionComponent, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from './OpenAlbumPopup.module.scss'
import {Box, Button, CircularProgress, DialogActions} from '@material-ui/core';
import ClubModel from "../../../../../models/club.model";
import AlbumModel from "../../../../../models/album.model";
import MediaModel from "../../../../../models/media.model";
import {useTranslation} from "react-i18next";
import CloudinaryService from "../../../../../services/cloudinary.service";
import Popup from "../../../../common/Popup/Popup";

let readAndCompressImage: any;
if (typeof window !== 'undefined') {
    readAndCompressImage = require('browser-image-resizer').readAndCompressImage;
}

interface Props {
    open: boolean,
    history?: any,
    club?: ClubModel,
    onAddImage: (cloudinaryResponse: any) => void;
    onQuit: () => void;
    onDelete: () => void;
    selectedAlbum?: AlbumModel,
    onImageOpen: (index: number) => void;
    onDeleteImage: (media: MediaModel) => void;
}


const OpenAlbumPopup: FunctionComponent<Props> = (props: Props) => {

    //***** State ***** //

    const [uploading, setUploading] = useState(false);
    const [selectedImage, setSelectedImage] = useState<MediaModel>();
    const [deleteAlbumPopupOpen, setDeleteAlbumPopupOpen] = useState<boolean>(false);
    const [deleteImagePopupOpen, setDeleteImagePopupOpen] = useState<boolean>(false);

    const t = useTranslation().t;

    //***** Handlers ***** //

    const deleteImageHandler = (image: MediaModel, event: any) => {
        event.stopPropagation();
        setSelectedImage(image);
        setDeleteImagePopupOpen(true);
    };

    const deleteImageConfirmHandler = () => {
        setDeleteImagePopupOpen(false);
        selectedImage && props.onDeleteImage(selectedImage)
    };

    const deleteAlbumHandler = () => {
        setDeleteAlbumPopupOpen(false);
        props.onDelete();
    };

    //***** Render ***** //

    const pictures = () => {
        if (!props.selectedAlbum?.media?.content) {
            return null;
        }

        return props.selectedAlbum.media.content.map((image, index) => {
            return (
                <Box
                    display={'flex'}
                    className={styles.thumbnailWrapper} onClick={() => props.onImageOpen(index)}>
                    <img alt={'Delete'} src={require('../../../../../assets/images/x-primary.svg').default}
                         className={styles.deleteTeamIcon}
                         onClick={(event) => deleteImageHandler(image, event)}/>
                    <img alt={'Album thumbnail'} src={image.url} className={styles.thumbnailImage}/>
                </Box>
            );
        });
    };

    const onChange = async (e: any) => {
        const files: File[] = Array.from(e.target.files);
        if (!files || !files.length) return;
        setUploading(true);

        let resizedImage = await readAndCompressImage(files[0], {
            quality: 0.8,
            maxWidth: 1600,
            maxHeight: 1600,
            mimeType: files[0].type
        });

        const cloudinaryResponse = await CloudinaryService.upload(resizedImage);
        props.onAddImage(cloudinaryResponse);
        setUploading(false);
    };

    const onPopupOpen = () => {
        setDeleteAlbumPopupOpen(true);
    };

    return (
        <Dialog aria-labelledby="simple-dialog-title" {...props} maxWidth={"xl"} fullWidth={true}>

            <div className={styles.albumName}>{props.selectedAlbum ? props.selectedAlbum.name : ''}</div>

            <Box
                display={'flex'}
                flexWrap={'wrap'}
                className={styles.container}
            >

                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    className={styles.addImageWrapper}
                >

                    {
                        uploading
                            ?
                            <CircularProgress/>
                            :
                            <Fragment>
                                <label htmlFor='single' className={styles.uploadInputArea}>
                                    <img alt={'Upload'}
                                         src={require('../../../../../assets/images/upload.svg').default}/>
                                    <div>Add image</div>
                                </label>
                                <input type='file' id='single' className={styles.uploadInputField} onChange={onChange}
                                       accept="image/bmp, image/jpeg, image/jpg, image/png"/>
                            </Fragment>
                    }
                </Box>

                {pictures()}
            </Box>

            <DialogActions>
                <Button color="primary" onClick={props.onQuit}>
                    {t('confirm_and_close')}
                </Button>
                <Button color="primary" onClick={onPopupOpen}>
                    {t('delete')}
                </Button>
            </DialogActions>

            <Popup
                open={deleteAlbumPopupOpen}
                onClose={() => setDeleteAlbumPopupOpen(false)}
                onConfirm={deleteAlbumHandler}
                text={t('do_you_want_to_delete_this_album')}
                confirmText={t('yes')}
                cancelText={t('no')}
            />

            <Popup
                open={deleteImagePopupOpen}
                onClose={() => setDeleteImagePopupOpen(false)}
                onConfirm={deleteImageConfirmHandler}
                text={t('do_you_want_to_delete_this_image')}
                confirmText={t('yes')}
                cancelText={t('no')}
            />

        </Dialog>
    );
};


export default OpenAlbumPopup;
