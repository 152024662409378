import React, {useEffect, useState} from 'react'
import Dialog from "@material-ui/core/Dialog";
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    FormControlLabel,
    Switch,
    TextField,
    Typography
} from "@material-ui/core";
import styles from "../club/preview/ClubHeader/EditNamePopup/EditNamePopup.module.scss";
import {useTranslation} from "react-i18next";
import UserModel from "../../models/user.model";
import UserService from "../../services/user.service";

interface Props {
    open: boolean
    onClose: () => void
    user?: UserModel
}

const ChangePassword = (props: Props) => {

    const t = useTranslation().t
    const [password, setPassword] = useState('')
    const [changePasswordOnNextLogin, setChangePasswordOnNextLogin] = useState(false)
    const [state, setState] = useState<'input' | 'saving' | 'error'>('input')

    useEffect(() => {
        if (props.open) {
            setPassword('')
            setChangePasswordOnNextLogin(false)
            setState('input')
        }
    }, [props.open])

    const handleChangePassword = async () => {
        setState('saving')
        try {
            const response = await UserService.adminUpdatePassword(props.user!, password, changePasswordOnNextLogin)
            setState('input')
            props.onClose()
        } catch (e) {
            setState('error')
        }
    }

    return (
        <Dialog {...props} maxWidth={"xs"} fullWidth={true}>

            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                className={styles.container}>

                <TextField
                    id="filled-multiline-flexible"
                    label={'New password'}
                    placeholder={'New password'}
                    value={password}
                    className={styles.input}
                    onChange={(e) => setPassword(e.target.value)}
                    margin="normal"
                    autoFocus
                />

                <FormControlLabel
                    control={<Switch
                        color={'primary'}
                        checked={changePasswordOnNextLogin}
                        onChange={e => setChangePasswordOnNextLogin(e.target.checked)}/>
                    }
                    label={'Change password on next login'}/>

                {
                    state === 'error' &&
                    <Typography
                        style={{color: 'red'}}
                    >
                        Error while changing password
                    </Typography>
                }

            </Box>

            <DialogActions>
                {
                    state === 'saving' ?
                        <CircularProgress/> :
                        <React.Fragment>
                            <Button onClick={props.onClose} color="primary">
                                Close
                            </Button>
                            <Button
                                disabled={password.length < 6}
                                onClick={handleChangePassword} color="primary">
                                Save
                            </Button>
                        </React.Fragment>
                }


            </DialogActions>
        </Dialog>
    )
}

export default ChangePassword