import React, {useEffect, useState} from 'react'
import {Box, Button, Card, CircularProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {useStore} from "react-redux";
import {loadInvoices, loadStripeCustomer, loadStripeSubscription} from "../../store/actions/billing.actions";
import SubscriptionService from "../../services/subscription.service";
import {useStripe} from "@stripe/react-stripe-js";
import {StripeInvoiceModel} from "../../models/StripeModels/stripeInvoice.model";
import {useBillingState, useUser} from "../../store/store.utils";
import AuthService from "../../services/auth.service";
import Popup from "../common/Popup/Popup";
import {useHistory} from "react-router";
import AddPaymentMethod from "../billing/AddPaymentMethod.component";

const useStyle = makeStyles({
    renewMessageBox: {
        width: 'calc(100% - 16px)',
        maxWidth: 600,
        marginBottom: 30
    },
    renewMessageText: {
        marginRight: 10
    }
})

const SubscriptionWarning = () => {

    const t = useTranslation().t
    const classes = useStyle()
    const {isCanceled, invoices} = useBillingState()
    const store = useStore()
    const user = useUser()
    const club = AuthService.getAdminClub(user)
    const [paymentStatus, setPaymentStatus] = useState<'' | 'loadMethods' | 'addingMethod' | 'paying'>('')
    const [paymentTryManual, setPaymentTryManual] = useState(false)
    const stripe = useStripe()
    const history = useHistory()

    const openInvoice = invoices.find(i => i.status === 'open')

    useEffect(() => {
        if (club) {
            store.dispatch(loadStripeCustomer(club) as any)
            store.dispatch(loadStripeSubscription(club) as any)
            store.dispatch(loadInvoices(club) as any)
        }
    }, [user])

    if (!isCanceled && !openInvoice) return null

    const handlePaymentFix = async () => {
        setPaymentStatus('loadMethods')
        const response = await SubscriptionService.getStripePaymentMethods(club)
        if (!response?.length) {
            setPaymentStatus('addingMethod')
        } else {
            handlePayInvoice()
        }
    }

    const handleAdditionalAuth = async (invoice?: StripeInvoiceModel) => {
        if (invoice?.paymentIntent?.clientSecret) {
            const response = await stripe?.confirmCardPayment(invoice?.paymentIntent?.clientSecret!, {
                return_url: window.location.href
            })
            if (response?.paymentIntent?.status === 'succeeded') {
                store.dispatch(loadStripeCustomer(club) as any)
                store.dispatch(loadStripeSubscription(club) as any)
                store.dispatch(loadInvoices(club) as any)
            } else {
                setPaymentTryManual(true)
            }
        } else {
            setPaymentTryManual(true)
        }
    }

    const handlePayInvoice = async () => {
        if (!openInvoice) {
            setPaymentStatus('')
            return
        }

        setPaymentStatus('paying')
        const invoice = await SubscriptionService.getStripeInvoice(club, openInvoice?.id)
        try {
            const response = await SubscriptionService.payStripeInvoice(club, invoice?.id)
            if (response?.status === 'paid') {
                store.dispatch(loadStripeCustomer(club) as any)
                store.dispatch(loadStripeSubscription(club) as any)
                store.dispatch(loadInvoices(club) as any)
            } else {
                await handleAdditionalAuth(invoice)
            }
        } catch (e) {
            await handleAdditionalAuth(invoice)
        } finally {
            setPaymentStatus('')
        }
    }

    const pastDueState = (
        <Box p={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <div className={classes.renewMessageText}>
                {t('Payment issues')}
            </div>

            {
                paymentStatus !== '' ?
                    <CircularProgress/>
                    :
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePaymentFix}
                    >
                        {t('Fix payment')}
                    </Button>
            }
        </Box>
    )

    const canceledState = (
        <Box p={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <div className={classes.renewMessageText}>
                {t('Subscription canceled')}
            </div>
            <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/billing/overview")}
            >
                {t('Renew')}
            </Button>
        </Box>
    )

    let ui
    if (!!openInvoice) {
        ui = pastDueState
    } else if (isCanceled) {
        ui = canceledState
    }

    return (
        <Card className={classes.renewMessageBox}>
            {ui}

            <AddPaymentMethod
                open={paymentStatus === 'addingMethod'}
                onDone={added => {
                    if (added) {
                        handlePayInvoice()
                    } else {
                        setPaymentStatus('')
                    }
                }}
            />

            <Popup
                open={paymentTryManual}
                text={t('Please update your payment method and try again')}
                onClose={() => setPaymentTryManual(false)}
                onConfirm={() => history.push(`/billing/overview`)}
                confirmText={t('Update payment methods')}
            />

        </Card>
    )
}

export default SubscriptionWarning