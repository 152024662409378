export enum ScreenName {

    // ***** Auth ***** //

    SIGN_UP = 'sign_up',
    SIGN_IN = 'sign_in',
    FORGOT_PASSWORD = 'forgot_password',

    DOWNLOAD_APP = 'download-app',
    CHECK_MAIL = 'check_mail',
    TERMS_AND_CONDITIONS = 'terms_and_conditions',
    EMAIL_SIGNUP = 'email_signup',

    // ***** Opportunities ***** //

    LANDING = 'landing',
    HOME_OPPORTUNITY_FEED = 'home_opportunity_feed',
    OPPORTUNITY_DETAIL = 'opportunity_detail',
    OPPORTUNITY_DETAIL_INFO = 'opportunity_detail_info',
    OPPORTUNITY_DETAIL_CLUB = 'opportunity_detail_club',
    OPPORTUNITY_SUCCESS_APPLIED = 'opportunity_success_applied',
    // OPPORTUNITY_SKIP_CONFORMATION = 'opportunity_skip_confirmation',

    // ***** Onboarding and boost ***** //

    ONBOARDING_HELLO = 'onboarding_hello',
    ONBOARDING_NAME = 'onboarding_name',
    ONBOARDING_NATIONALITY = 'onboarding_nationality',
    ONBOARDING_AGE_CONFIRMATION = 'onboarding_age_confirmation',
    // ONBOARDING_NO_PARENT_PERMISSION = 'onboarding_no_parent_permission',
    ONBOARDING_POSITION = 'onboarding_position',
    // ONBOARDING_LOCATION_SERVICE = 'onboarding_location_service',
    ONBOARDING_LOCATION = 'onboarding_location',
    ONBOARDING_PHONE = 'onboarding_phone',
    ONBOARDING_VERIFICATION = 'onboarding_verification',
    ONBOARDING_CURRENT_CLUB = 'onboarding_current_club',
    ONBOARDING_CURRENT_CLUB_TEAM = 'onboarding_current_club_team',
    ONBOARDING_CURRENT_CLUB_CONTRACT = 'onboarding_current_club_contract',
    ONBOARDING_SCRAPPED_LIST = 'onboarding_scrapped_list',
    ONBOARDING_AGENCY_NAME_ROLE = 'onboarding_agency_name_role',
    ONBOARDING_FIND_YOUR_CLUB = 'onboarding_find_your_club',
    ONBOARDING_CREATE_CLUB_REQUEST = 'onboarding_create_club_request',
    ONBOARDING_CLUB_NAME_ROLE = 'onboarding_club_name_role',

    BOOST_STATS = 'boost_stats',
    BOOST_PROFILE_PICTURE = 'boost_profile_picture',
    BOOST_PREVIOUS_CLUB = 'boost_previous_club',
    BOOST_PAST_CLUB = 'boost_past_club',
    BOOST_PAST_CLUB_TEAM = 'boost_past_club_team',
    BOOST_PAST_CLUB_CONTRACT = 'boost_past_club_contract',
    BOOST_SKILL_VIDEO = 'boost_skill_video',
    BOOST_SKILL_RECORDING_TUTORIAL = 'boost_skill_recording_tutorial',
    BOOST_SKILL_VIDEO_SUCCESS_UPLOAD = 'boost_skill_video_success_upload',

    // BOOST_CURRENT_CLUB = 'boost_current_club',
    // BOOST_CURRENT_CLUB_TEAM = 'boost_current_club_team',
    // BOOST_CURRENT_CLUB_CONTRACT = 'boost_current_club_contract',
    // BOOST_HEIGHT_AND_PREFERRED_FOOT = 'boost_height_and_preferred_foot',

    // ***** Profile ***** //

    PROFILE = 'profile',
    PROFILE_VIDEO_PLAYER = 'profile_video_player',
    PROFILE_STATS = 'profile_stats',
    PROFILE_SAVE_SKILL_VIDEO = 'profile_save_skill_video',
    PROFILE_SAVE_PROFILE_PICTURE = 'profile_save_profile_picture',
    PROFILE_PAST_CLUBS = 'profile_past_clubs',
    PROFILE_LOCATION_AND_BIRTHDAY = 'profile_location_and_birthday',
    PROFILE_NATIONALITY_AND_BIRTHDAY = 'profile_nationality_and_birthday',
    // PROFILE_GENDER = 'profile_gender',
    PROFILE_FULL_NAME = 'profile_full_name',
    PROFILE_DELETE_SKILL_VIDEO_CONFIRMATION = 'profile_delete_skill_video_confirmation',
    PROFILE_DELETE_HIGHLIGHT_VIDEO_CONFIRMATION = 'profile_delete_highlight_video_confirmation',
    PROFILE_CURRENT_CLUB = 'profile_current_club',
    PROFILE_PAST_CLUB = 'profile_past_club',
    PROFILE_CURRENT_CLUB_TEAMS = 'profile_current_club_teams',
    PROFILE_PAST_CLUB_TEAMS = 'profile_past_club_teams',
    PROFILE_CURRENT_CLUB_CONTRACT = 'profile_current_club_contract',
    PROFILE_PAST_CLUB_CONTRACT = 'profile_past_club_contract',
    PROFILE_ADDITIONAL_INFO = 'profile_additional_info',
    PROFILE_EXTERNAL_URLS = 'profile_external_urls',
    PROFILE_PHONE = 'profile_phone',
    PROFILE_GENDER = 'profile_gender',
    PLAYER_PROFILE = 'player profile',
    CLUB_REGISTRATION_PERSONAL = 'club registration personal',
    CLUB_REGISTRATION_CLUBS = 'club registration clubs',
    LOG_IN = 'log in',
    PRICING_POPUP = 'pricing popup',

    // ***** B2C ***** //

    B2C_MODAL = 'b2c_modal',                                            // B2C main modal open
    B2C_VIDEO_AFTER_APPLY = 'b2c_video_after_apply',                    // Video popup that opens after first apply
    B2C_TRY_PREMIUM_POPUP = 'b2c_try_premium_popup',                    // Popup that opens when user clicks on "X"
    B2C_ONLY_FOR_PREMIUM_POPUP = 'b2c_only_for_premium_popup',          // Premium job popup (after free user click on apply for premium)
    B2C_PACKAGE_BUY_SUCCESS = 'b2c_package_buy_success',                // User successfully subscribed
    B2C_CONFIRM_PREMIUM_POPUP_CLOSE_CONFIRM = 'b2c_premium_popup_close_confirm',                // User successfully subscribed
    B2C_PAYMENT_METHOD_MODAL = 'b2c_payment_method_modal',
    B2C_SMS_PAYMENT_MODAL = 'b2c_sms_payment_modal',
    B2C_PAYMENT_SLIP_MODAL = 'b2c_slip_payment_modal',

    // ***** Misc ***** //

    SETTINGS = 'settings',
    PICK_VIDEO = 'pick_video',
    PICK_PHOTO = 'pick_photo',
    APPLICATIONS = 'applications',
    CONTACTED_AGENT_APPLICATIONS = 'contacted_agent_applications',
    CROP_IMAGE = 'crop_image',
    TRIM_VIDEO = 'trim_video',
    ERROR = 'error',

    // ***** Club ***** //
    CLUB_PROFILE = 'club profile',
    CLUB_LANDING = 'club_landing',
    JOBS_LIST = 'jobs_list',
    CREATE_JOB = 'create_opportunity',
    EDIT_JOB = 'edit_opportunity',
    VIEW_APPLICANTS = 'view_applicants',
    VIEW_APPLICANT_DETAILS = 'view_applicant_details'

}
