import {v4 as uuidv4} from 'uuid';
import UserModel, {UserTypeId} from "../models/user.model";
import {useSelector} from "react-redux";
import {AuthState} from "./reducers/auth.reducer";
import {CommonState} from "./reducers/common.reducer";
import {JobsState} from "./reducers/jobs.reducer";
import {ApplicationsState} from "./reducers/applications.reducer";
import {ProfileState} from "./reducers/profile.reducer";
import {differenceInDays, isAfter} from "date-fns";
import {StripeSubscriptionStatus} from "../models/StripeModels/stripeSubscription.model";
import {BillingState} from "./reducers/billing.reducer";
import {AlgobugState} from "./reducers/algobug.reducer";

export const useAuthState = () => {
    return useSelector<any, AuthState>(store => store.auth)
}

export const useJobsState = () => {
    return useSelector<any, JobsState>(store => store.jobs)
}

export const useUserHelper = () => {
    const authState = useAuthState()

    return {
        isLoggedInUserRegularPlayer: authState.user?.userType?.id === UserTypeId.REGULAR_TYPE_ID && authState.user.loginType !== 'SUBUSER',
        isLoggedInUserAgent: authState.user?.userType?.id === UserTypeId.AGENT_TYPE_ID,
        isLoggedInUserAgentsPlayer: authState.user?.userType?.id === UserTypeId.REGULAR_TYPE_ID && authState.user.loginType === 'SUBUSER',
        isLoggedInUserClub: authState.user?.userType?.id === UserTypeId.STAFF_TYPE_ID,
    }
}

export const useCommonState = () => {
    return useSelector<any, CommonState>(store => store.common)
}

export const useUser = () => {
    const {user} = useSelector<any, AuthState>(store => store.auth)
    return user
}

export const mapDefaultProps = (state: any) => ({
    user: state.auth.user,
    authInProgress: state.auth.loading,
    tokenValidationDone: state.auth.tokenValidationDone,
    availableSubscriptions: state.subscription.availableSubscriptions,
    subscription: state.subscription.subscription,
    subscriptionConfig: state.subscription.subscriptionConfig,
    applicationsInLastNDays: state.subscription.applicationsInLastNDays,
    latestDeeplinkNotification: state.common.latestDeeplinkNotification
});

export interface DefaultProps {
    user?: UserModel;
    authInProgress?: boolean;
    tokenValidationDone?: boolean;
    applicationsInLastNDays?: number;
}

export const defaultActions = () => ({});

const timestamps: { [key: string]: string } = {};

export const activateCall = (callName: string): string => {
    const token = uuidv4();
    timestamps[callName] = token;
    return token;
};

export const isMyCallActive = (callName: string, token: string) => {
    return timestamps[callName] === token;
};

export const useApplicationsState = () => {
    return useSelector<any, ApplicationsState>(store => store.applications)
}

export const useProfileState = () => {
    return useSelector<any, ProfileState>(store => store.profile)
}

export const useBillingState = () => {
    const billingState = useSelector<any, BillingState>(store => store.billing)

    const isActive = billingState.subscription?.status === StripeSubscriptionStatus.ACTIVE
    const isTrialing = billingState.subscription?.status === StripeSubscriptionStatus.TRIALING
    const isCanceled = billingState.subscription?.status === StripeSubscriptionStatus.CANCELED
    const isTryingToCharge = billingState.subscription?.status === StripeSubscriptionStatus.INCOMPLETE
    const isPastDue = billingState.subscription?.status === StripeSubscriptionStatus.PAST_DUE ||
        billingState.subscription?.status === StripeSubscriptionStatus.INCOMPLETE_EXPIRED
    const hasAnySubscription = !!billingState?.subscription
    const daysUntilTrialEnds = billingState.subscription?.trialEnd &&
    isAfter(new Date(billingState.subscription.trialEnd * 1000), new Date()) ?
        differenceInDays(new Date(billingState.subscription.trialEnd * 1000), new Date()) : 0

    return {
        ...billingState,
        isActive,
        isTrialing,
        isCanceled,
        isPastDue,
        isTryingToCharge,
        hasAnySubscription,
        daysUntilTrialEnds
    }
}

export const useAlgobugState = () => {
    return useSelector<any, AlgobugState>(store => store.algobug)
}
