import React, {useState} from 'react';
import styles from './HighlightVideos.module.scss';
import UserModel from "../../../../models/user.model";
import MediaModel from "../../../../models/media.model";
import CloudinaryService from "../../../../services/cloudinary.service";
import {useTranslation} from "react-i18next";
import {getHighlightVideos} from "../../../../util/profile.util";
import {Box} from "@material-ui/core";
import PreviewVideoPopup from "../../../common/PreviewVideoPopup/PreviewVideoPopup";

interface Props {
    player?: UserModel,
    onVideoPreviewOpen: (video: MediaModel) => void;
}

const HighlightVideos = (props: Props) => {

    const [videoPreviewOpen, setVideoPreviewOpen] = useState(false);
    const [currentVideo, setCurrentVideo] = useState<MediaModel>();

    const t = useTranslation().t;

    const handleThumbnailClick = (video: MediaModel) => {
        setVideoPreviewOpen(true);
        setCurrentVideo(video);
    };

    const renderHighlights = () => {
        const highlights = getHighlightVideos(props.player);

        if (highlights.length === 0) {
            return (
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    className={styles.noHighlights}
                >
                    <div>{t('no_highlight_videos')}</div>
                </Box>
            )
        }

        return highlights.map((video) => {
            return (
                <div key={video.url} className={styles.thumbnailHolder} onClick={_ => handleThumbnailClick(video)}>
                    <img alt={'Video thumbnail'} className={styles.thumbnail}
                         src={CloudinaryService.getVideoPreviewUrl(video?.url)}/>
                    <img alt={'Play video'} className={styles.playVideo} src={require('../../../../assets/images/play-video.svg').default}/>
                </div>
            )
        })
    }

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={styles.container}
        >
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                className={styles.sectionTitle}
            >
                <p>{t('highlight_videos')}</p>
                {/*<p className={styles.edit}>Edit</p>*/}
            </Box>

            <Box
                display={'flex'}
                flexDirection={'row'}
                flexWrap={'wrap'}
                className={styles.videosContainer}
            >
                {renderHighlights()}
            </Box>

            <PreviewVideoPopup
                open={videoPreviewOpen}
                video={currentVideo?.url}
                onClose={() => setVideoPreviewOpen(false)}
            />
        </Box>
    );
}

export default HighlightVideos;
