export enum Position {
    Forward = 'Forward',
    LeftWing = 'LeftWing',
    RightWing = 'RightWing',
    AttackingMidfield = 'AttackingMidfield',
    LeftMidfield = 'LeftMidfield',
    CentralMidfield = 'CentralMidfield',
    RightMidfield = 'RightMidfield',
    DefensiveMidfield = 'DefensiveMidfield',
    LeftFullBack = 'LeftFullBack',
    CentralDefender = 'CentralDefender',
    RightFullBack = 'RightFullBack',
    Goalkeeper = 'Goalkeeper'
}
