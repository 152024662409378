import React from 'react'
import {Box, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AnimatedBox from "./Animated.component";
import {DIMENSIONS, LANDING_COMMON_COLORS} from "./common";
import {useTranslation} from "react-i18next";
import clsx from "clsx";

const useStyle = makeStyles(_theme => ({
    component: {
        marginTop: 200,
        width: '100%',
        position: 'relative',
        [_theme.breakpoints.down('md')]: {
            marginTop: 100
        }
    },
    title: {
        fontSize: DIMENSIONS.LARGE.TITLE_SIZE,
        color: LANDING_COMMON_COLORS.TITLE,
        marginBottom: 105,
        textAlign: "center",
        paddingLeft: 25,
        paddingRight: 25,
        [_theme.breakpoints.down('md')]: {
            fontSize: DIMENSIONS.SMALL.TITLE_SIZE
        }
    },
    partnerLogos: {
        maxWidth: 1200
    },
    partnerLogoHolder: {
        width: 120,
        height: 120,
        overflow: 'hidden'
    },
    partnerLogoWrapper: {
        width: '100%',
        height: '100%',
    },
    partnerLogo: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    }
}))

interface Props {
    classes?: any
}

const Partners = (props: Props) => {

    const classes = useStyle()
    const t = useTranslation().t

    const partners = [
        {name: 'Dresden', urlJpg: require('../../assets/images/landing/partner-dresden.jpg').default,  urlWebp: require('../../assets/images/landing/partner-dresden.webp').default},
        {name: 'Austria Wien', urlJpg: require('../../assets/images/landing/partner-austria-wien.jpg').default, urlWebp: require('../../assets/images/landing/partner-austria-wien.webp').default},
        {name: 'Red star belgrade', urlJpg: require('../../assets/images/landing/partner-red-star.jpg').default, urlWebp: require('../../assets/images/landing/partner-red-star.webp').default},
        {name: 'WSG Swarowski', urlJpg: require('../../assets/images/landing/partner-wsg-swarovski.jpg').default, urlWebp: require('../../assets/images/landing/partner-wsg-swarovski.webp').default},
        {name: 'Partizan', urlJpg: require('../../assets/images/landing/partner-partizan.jpg').default, urlWebp: require('../../assets/images/landing/partner-partizan.webp').default},
        {name: 'OFB', urlJpg: require('../../assets/images/landing/partner-ofb.jpg').default, urlWebp: require('../../assets/images/landing/partner-ofb.webp').default},
        {name: 'FC LIEFERING', urlJpg: require('../../assets/images/landing/partner-liefering.jpg').default, urlWebp: require('../../assets/images/landing/partner-liefering.webp').default},
        {name: 'FC Wacker', urlJpg: require('../../assets/images/landing/partner-fcw.jpg').default, urlWebp: require('../../assets/images/landing/partner-fcw.webp').default},
        {name: 'Red Star Penzing', urlJpg: require('../../assets/images/landing/partner-rsp.jpg').default, urlWebp: require('../../assets/images/landing/partner-rsp.webp').default},
        {name: 'Rapid Wien', urlJpg: require('../../assets/images/landing/partner-rapid.jpg').default, urlWebp: require('../../assets/images/landing/partner-rapid.webp').default},
        {name: 'Cukaricki', urlJpg: require('../../assets/images/landing/partner-cukaricki.jpg').default, urlWebp: require('../../assets/images/landing/partner-cukaricki.webp').default},
        {name: 'WDF', urlJpg: require('../../assets/images/landing/partner-wdf.jpg').default, urlWebp: require('../../assets/images/landing/partner-wdf.webp').default},
        {name: 'DW Sport Consulting', urlJpg: require('../../assets/images/landing/partner-dw-consulting.jpg').default, urlWebp: require('../../assets/images/landing/partner-dw-consulting.webp').default},
    ]

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={clsx(classes.component, props.classes)}
        >
            <div className={classes.title}>
                {t("Partners")}
            </div>

            <Grid
                container
                justify={'center'}
                spacing={6}
                className={classes.partnerLogos}
            >
                {
                    partners.map(partner => (
                        <Grid
                            item
                            key={partner.name}
                        >
                            <AnimatedBox
                                hover
                                fadeIn
                                fadeInThreshold={30}
                                className={classes.partnerLogoHolder}
                            >
                                <picture className={classes.partnerLogoWrapper}>
                                    <source
                                        className={classes.partnerLogo}
                                        srcSet={partner.urlWebp}
                                    />
                                    <img
                                        alt={partner.name}
                                        className={classes.partnerLogo}
                                        src={partner.urlJpg}
                                    />
                                </picture>
                            </AnimatedBox>
                        </Grid>
                    ))
                }

            </Grid>

        </Box>
    )
}

export default Partners
