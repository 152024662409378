import React, {useCallback, useEffect, useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@material-ui/core"
import debounce from "lodash/debounce"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";
import ETextField from "../../ETextField.component";

const UserNameFilter = () => {

    //region State

    const [firstNameParam] = useUrlParam({param: 'firstName', dataType: 'string', defaultValue: ''})
    const [lastNameParam] = useUrlParam({param: 'lastName', dataType: 'string', defaultValue: ''})

    const [firstNameLocal, setFirstNameLocal] = useState(firstNameParam)
    const [lastNameLocal, setLastNameLocal] = useState(lastNameParam)
    const [manualInput, setManualInput] = useState(false)

    const [expanded, setExpanded] = useState(!!firstNameLocal && !!lastNameLocal)
    const history = useHistory()

    //endregion State

    //region Handlers

    const executeSet = useCallback(debounce((firstName: any, lastName: any) => {
        setMultipleParams([
            {name: 'firstName', value: firstName, dataType: 'string'},
            {name: 'lastName', value: lastName, dataType: 'string'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }, 1000), [])

    useEffect(() => {
        if (!manualInput) return
        if (firstNameLocal === '' && lastNameLocal === '') {
            executeSet('', '')
        } else {
            executeSet(firstNameLocal || '', lastNameLocal || '')
        }
    }, [firstNameLocal, lastNameLocal, manualInput])

    //endregion Handlers

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="userName-content"
                id="userName-header"
            >
                <Typography>Name {!!firstNameLocal && !!lastNameLocal && `(${firstNameLocal} - ${lastNameLocal})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container spacing={1}>
                    <ETextField
                        label={'First name'}
                        grid={'1/2'}
                        value={firstNameLocal}
                        onChange={e => {
                            setManualInput(true)
                            setFirstNameLocal(e)
                        }}
                    />
                    <ETextField
                        label={'Last name'}
                        grid={'1/2'}
                        value={lastNameLocal}
                        onChange={e => {
                            setManualInput(true)
                            setLastNameLocal(e)
                        }}
                    />
                </Grid>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default UserNameFilter