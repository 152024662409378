import {Grid} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {DatePicker} from "@material-ui/pickers";
import {EGridSizes, getGridSizes} from "./grid.util";

interface Props {
    grid: EGridSizes
    value?: Date,
    onChange?: (date?: Date | null) => void,
    className?: any,
    style?: React.CSSProperties,
    label?: string,
    mandatory?: boolean,
    multiline?: boolean
    rows?: number,
    maxDate?: Date
}

const useStyle = makeStyles(theme => ({
    input: {
        width: '100%'
    }
}))

const EDateField = ({value, onChange, maxDate, className, label, mandatory, multiline, rows, style, grid}: Props) => {

    const classes = useStyle()

    return (
        <Grid item {...getGridSizes(grid)}>
            <DatePicker
                className={classes.input}
                label={label}
                maxDate={maxDate}
                value={value || null}
                inputVariant={'outlined'}
                margin={'normal'}
                format={'dd.MM.yyyy.'}
                onChange={date => onChange?.(date || null)}
            />
        </Grid>

    )
}

export default EDateField