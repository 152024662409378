import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInSeconds,
    subDays,
    subHours,
    subMinutes
} from "date-fns";

export const fitText = (text: string, lineWidth: number, lines: number): string => {
    let currentLines = 0;
    let currentChars = 0;
    let result = '';
    for (let i = 0; i < text.length; i++) {
        if (text[i] === '\n') {
            currentLines++;
            currentChars = 0;
        } else {
            currentChars++;
            if (currentChars > lineWidth) {
                currentLines++;
                currentChars -= lineWidth;
            }
        }

        if (currentLines > lines) {
            return result;
        }

        result += text[i];
    }
    return result;
};

export const formatTimeDiff = (date1: Date, date2: Date) => {
    const parts = [];
    let d, h, m, s;

    d = differenceInDays(date1, date2);
    if (d > 0) {
        date1 = subDays(date1, d);
    }
    parts.push(`${d.toString().padStart(2, '0')}d`);

    h = differenceInHours(date1, date2);
    if (h > 0) {
        date1 = subHours(date1, h);
    }
    parts.push(`${h.toString().padStart(2, '0')}h`);

    m = differenceInMinutes(date1, date2);
    if (m > 0) {
        date1 = subMinutes(date1, m);
    }
    parts.push(`${m.toString().padStart(2, '0')}m`);

    s = differenceInSeconds(date1, date2);
    parts.push(`${s.toString().padStart(2, '0')}s`);

    return parts.join(' ');
}