import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {useHistory} from "react-router"
import {useTranslation} from "react-i18next"
import {getEmail, getFirstName, getFullName, getPhone, getRole} from "../../../util/profile.util"
import ClubModel from "../../../models/club.model"
import EButton from "../../common/EButton"
import Popup from "../../common/Popup/Popup";
import ClubService from "../../../services/club.service";
import ClubAdminModel from "../../../models/clubAdmin.model";
import UserModel from "../../../models/user.model";
import clsx from "clsx";
import _ from "lodash";
import {Box} from "@material-ui/core";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {useUser} from "../../../store/store.utils";
import PickUserPopup from "../../common/PickUserPopup";

interface Props {
    club?: ClubModel,
    setClub: (club: ClubModel) => void,
}

const useStyle = makeStyles(theme => ({
    contactInfoContainer: {
        background: 'rgba(244, 245, 249, 0.51)',
        borderRadius: 8,
        width: '100%',
        padding: 16,
        marginBottom: 25,
        overflow: 'auto'
    },
    section: {
        color: '#46d98a',
        fontSize: 16,
        marginBottom: 6,
    },
    secondSection: {
        marginTop: 20
    },
    link: {
        fontSize: 14,
        color: '#000',
        marginBottom: 4
    },
    linkSubtitle: {
        fontSize: 14,
        color: '#0000008a',
    },
    adminHolder: {
        minHeight: 'fit-content !important'
    },
    addMeAsAdmin: {
        width: 'fit-content'
    },
    actionButton: {
        marginTop: 20,
        width: 'fit-content'
    }
}))


const ClubAdmins = ({club, setClub}: Props) => {

    const classes = useStyle()
    const history = useHistory()
    const {t, i18n} = useTranslation()
    const [pickUserVisible, setPickUserVisible] = useState(false)

    const admins = club?.clubAdmins || [];
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [currentAdmin, setCurrentAdmin] = useState<ClubAdminModel>();
    const user = useUser()

    const addClubAdmin = async (user?: UserModel) => {
        if (!user) return;

        try {
            const response = await ClubService.addClubAdmin(club!, user);
            const newAdmins = [...(club?.clubAdmins || [])];
            if (!newAdmins.find(admin => admin.user && admin.user.id === user.id)) {
                newAdmins.push(response);
            }
            setClub({
                ...club,
                clubAdmins: newAdmins
            });
        } catch (err) {
            console.log(err);
        }
    };

    const removeClubAdmin = async (clubAdmin: ClubAdminModel) => {
        try {
            await ClubService.removeClubAdmin(clubAdmin);
            const newAdmins = (club?.clubAdmins || []).filter(admin => admin.id !== clubAdmin.id);
            setClub({
                ...club,
                clubAdmins: newAdmins
            });
        } catch (err) {
            console.log(err);
        }
    };


    const handleDeleteAdmin = (admin: ClubAdminModel) => {
        setCurrentAdmin(admin);
        setConfirmDeleteOpen(true);
    };

    const approveClubAdmin = async (clubAdmin: ClubAdminModel) => {
        try {
            await ClubService.approveClubAdmin(clubAdmin);
            const newAdmins = _.cloneDeep(club?.clubAdmins || []);
            const admin = newAdmins?.find(admin => admin.id === clubAdmin.id);
            if (admin) admin.approved = true;
            setClub({
                ...club,
                clubAdmins: newAdmins
            });
        } catch (err) {
            console.log(err);
        }
    };


    //endregion Handlers

    //region UI

    const contactInfo = admins.map(admin => {
        const name = getFullName(admin?.user);
        const phone = getPhone(admin?.user);
        const email = getEmail(admin?.user);
        const role = getRole(admin?.user);
        const approved = admin?.approved;

        return (
            <Box
                className={classes.adminHolder}
                marginBottom={5}
                display={'flex'}
                flexDirection={'column'}
            >

                <div className={classes.section}>{t("Name")}</div>
                <div className={classes.link}>{name || t('no_name_provided')}</div>

                <div className={clsx(classes.section, classes.secondSection)}>{t("Phone")}</div>
                <a className={classes.link}
                   href={`tel:${phone}`}>{phone || t('no_phone_provided')}</a>

                <div className={clsx(classes.section, classes.secondSection)}>{t("write_an_email")}</div>
                <a className={classes.link}
                   href={`mailto:${email}`}>{email || t('no_email_provided')}</a>

                <div className={clsx(classes.section, classes.secondSection)}>{t("Approved")}</div>
                <div className={classes.link}>{approved ? 'Yes' : 'No'}</div>

                <div className={clsx(classes.section, classes.secondSection)}>{t("Role")}</div>
                <div className={classes.link}>{role}</div>

                {!approved &&
                <EButton
                    variant="contained"
                    color="primary"
                    onClick={() => approveClubAdmin(admin)}
                    className={classes.actionButton}
                >
                    <ThumbUpIcon/>
                    Approve "{getFirstName(admin && admin.user, '')}" as admin
                </EButton>
                }


                <EButton
                    variant="text" color="error"
                    onClick={() => handleDeleteAdmin(admin)}
                    className={classes.actionButton}
                >
                    <DeleteForeverIcon/>
                    Remove "{getFirstName(admin?.user, '')}" from admins
                </EButton>

            </Box>
        )
    });

    return (
        <Box
            p={3}
            display={'flex'}
            flexDirection={'column'}
        >

            {contactInfo}

            {
                !admins.find(a => a.user?.id === user?.id) &&
                <EButton
                    variant="text"
                    color="primary"
                    onClick={() => setPickUserVisible(true)}
                    className={classes.addMeAsAdmin}
                >
                    {t("Add admin manually")}
                </EButton>
            }


            <Popup
                open={confirmDeleteOpen}
                onClose={() => setConfirmDeleteOpen(false)}
                onConfirm={() => {
                    currentAdmin && removeClubAdmin(currentAdmin);
                    setConfirmDeleteOpen(false);
                }}
                text={t('Remove admin?')}
            />

            <PickUserPopup
                open={pickUserVisible}
                onClose={() => setPickUserVisible(false)}
                onPick={user => {
                    addClubAdmin(user)
                    setPickUserVisible(false)
                }}
                userRoles={['STAFF']}
            />

        </Box>
    )

    //endregion UI
}

export default ClubAdmins
