import React, {FunctionComponent, useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from './EditTeamsPopup.module.scss'
import {Box, Button, DialogActions, FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
import Team from "../../../../../models/team.model";
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean;
    history?: any;
    onClose: () => void;
    onSave: (gender: string, levelId: number, teamId: number) => void;
    existingTeams: Team[];
}


const EditTeamsPopup: FunctionComponent<Props> = (props: Props) => {

    // ***** State ***** //

    const [gender, setGender] = useState<string>();
    const [level, setLevel] = useState<number>();
    const [team, setTeam] = useState<number>();

    const sameTeam = props.existingTeams.find(t => t.gender === gender && t.teamType?.id === team);

    const t = useTranslation().t;

    // ***** Effects ***** //

    useEffect(() => {
        setGender(undefined);
        setLevel(undefined);
        setTeam(undefined);
    }, [props.open]);

    // ***** Render ***** //

    const levels = [];

    for (let i = 1; i <= 15; i++) {
        levels.push(i);
    }

    return (
        <Dialog aria-labelledby="simple-dialog-title" {...props} maxWidth={"xs"} fullWidth={true}>

            <Box display={'flex'} flexDirection={'column'} className={styles.container}>

                <FormControl className={styles.formControl} margin={'normal'}>
                    <InputLabel htmlFor="gender">{t('gender')}</InputLabel>
                    <Select
                        value={gender}
                        onChange={(event) => setGender(event.target.value as string)}
                        // inputProps={{
                        //     name: 'age',
                        //     id: 'age-simple',
                        // }}
                    >
                        <MenuItem value={"MALE"}>{t('male')}</MenuItem>
                        <MenuItem value={"FEMALE"}>{t('female')}</MenuItem>
                    </Select>
                </FormControl>

                <FormControl className={styles.formControl} margin={'normal'}>
                    <InputLabel htmlFor="level">{t('level')}</InputLabel>
                    <Select
                        value={level}
                        onChange={(event) => setLevel(event.target.value as number)}
                        // inputProps={{
                        //     name: 'age',
                        //     id: 'age-simple',
                        // }}
                    >
                        {levels.map(level => <MenuItem value={level}>{level}</MenuItem>)}
                    </Select>
                </FormControl>

                <FormControl className={styles.formControl} margin={'normal'}>
                    <InputLabel htmlFor="team">{t('team')}</InputLabel>
                    <Select
                        value={team}
                        onChange={(event) => setTeam(event.target.value as number)}
                        // inputProps={{
                        //     name: 'age',
                        //     id: 'age-simple',
                        // }}
                    >
                        <MenuItem value={1}>{t('First Team')}</MenuItem>
                        <MenuItem value={2}>{t('Second Team')}</MenuItem>
                        <MenuItem value={3}>{t('Youth')}</MenuItem>
                        <MenuItem value={8}>{t('Third Team')}</MenuItem>
                        <MenuItem value={9}>{t('Fourth Team')}</MenuItem>
                        <MenuItem value={10}>{t('Fifth Team')}</MenuItem>
                        <MenuItem value={11}>{t('Sixth Team')}</MenuItem>
                        <MenuItem value={12}>{t('Seventh Team')}</MenuItem>
                        <MenuItem value={13}>{t('Eight Team')}</MenuItem>
                        <MenuItem value={14}>{t('Ninth Team')}</MenuItem>
                        <MenuItem value={15}>{t('Tenth team')}</MenuItem>
                    </Select>
                </FormControl>

                {sameTeam && <div className={styles.teamAlreadyExists}>{t('team_already_exists')}</div>}

            </Box>

            <DialogActions>
                <Button color="primary" onClick={props.onClose}>
                    {t('close')}
                </Button>
                <Button color="primary" onClick={() => gender && level && team && props.onSave(gender, level, team)}
                        autoFocus
                        disabled={!!sameTeam}>
                    {t('save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default EditTeamsPopup;
