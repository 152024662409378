import {Box, Button, Card, CircularProgress} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import SubscriptionService from "../../../services/subscription.service";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {PaymentMethodModel} from "../../../models/StripeModels/paymentMethod.model";
import clsx from "clsx";
import AddPaymentMethod from "../AddPaymentMethod.component";
import {useStore} from "react-redux";
import {loadStripeCustomer} from "../../../store/actions/billing.actions";
import {useBillingState, useUser} from "../../../store/store.utils";
import AuthService from "../../../services/auth.service";

const useStyles = makeStyles({
    container: {
        width: `100%`,
        minHeight: 100
    },
    cardHolder: {
        width: '100%',
        marginBottom: 10
    },
    card: {
        width: '100%',
        maxWidth: 300,
        height: 120,
        borderRadius: 10,
        marginBottom: 10,
        boxShadow: '#bdbdbd 0 0 3px'
    },
    iconCardHolder: {
        position: 'relative',
        width: '30%',
        borderRight: '1px solid #bdbdbd'
    },
    iconCard: {
        width: 50,
        height: 50
    },
    paymentDetails: {
        width: '70%',
        marginLeft: 15,
        position: 'relative'
    },
    paymentInfo: {
        marginBottom: 15,
    },
    defaultPayment: {
        position: "absolute",
        bottom: 5,
        right: 10,
        background: '#cecece',
        borderRadius: 5,
        padding: '5px 10px',
        fontSize: 12
    },
    setDefaultCard: {
        marginLeft: 40,
        cursor: "pointer",
        marginBottom: 10,
        fontSize: 12
    },
    deleteCard: {
        marginLeft: 40,
        color: 'red',
        cursor: 'pointer',
        fontSize: 12
    },
    addNewPayment: {
        marginTop: 10,
        width: 'fit-content'
    }
})

const PaymentMethods = () => {

    //region State

    const user = useUser()
    const club = AuthService.getAdminClub(user)
    const t = useTranslation().t
    const classes = useStyles()
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodModel[]>([])
    const [paymentMethodsStatus, setPaymentMethodsStatus] = useState<'loading' | 'error' | ''>('loading')
    const [addPaymentMethodOpen, setAddPaymentMethodOpen] = useState(false)
    const [paymentMethodsWithActionInProgress, setPaymetMethodsWithActionInProgress] = useState<PaymentMethodModel[]>([])
    const {stripeCustomer, stripeCustomerState} = useBillingState()
    const store = useStore()

    //endregion State

    //region Handlers

    const getPaymentMethods = async () => {
        setPaymentMethodsStatus('loading')
        const response = await SubscriptionService.getStripePaymentMethods(club)
        setPaymentMethods(response || [])
        setPaymentMethodsStatus('')
    }

    useEffect(() => {
        if (club && stripeCustomer) {
            getPaymentMethods()
        }
    }, [user, stripeCustomer])

    const handleAddNewPayment = () => {
        setAddPaymentMethodOpen(true)
    }

    const handleDeletePaymentMethod = async (paymentMethod: PaymentMethodModel) => {
        if (!club?.id || !paymentMethod) return

        try {
            setPaymetMethodsWithActionInProgress([...paymentMethodsWithActionInProgress, paymentMethod])
            const response = await SubscriptionService.removeStripePaymentMethod(club, paymentMethod)
            if (response) {
                setPaymentMethods(paymentMethods.filter(p => p.id !== response.id))
            }
        } catch (e) {
            console.log(e)
        } finally {
            setPaymetMethodsWithActionInProgress(paymentMethodsWithActionInProgress.filter(p => p.id === paymentMethod.id))
        }
    }

    const handleDefaultPaymentMethod = async (paymentMethod: PaymentMethodModel) => {
        if (!club?.id || !paymentMethod) return

        try {
            setPaymetMethodsWithActionInProgress([...paymentMethodsWithActionInProgress, paymentMethod])
            const response = await SubscriptionService.setDefaultPaymentMethod(club, paymentMethod.id)
            if (response) {
                store.dispatch(loadStripeCustomer(club) as any)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setPaymetMethodsWithActionInProgress(paymentMethodsWithActionInProgress.filter(p => p.id === paymentMethod.id))
        }
    }

    //endregion Handlers

    //region UI

    const generateCardIcon = (method: PaymentMethodModel) => {
        switch (method.card?.brand) {
            case 'visa':
                return require('../../../assets/images/visa_card.svg').default
            case 'mastercard':
                return require('../../../assets/images/mastercard_card.svg').default
            default:
                return require('../../../assets/images/credit_card.svg').default
        }
    }

    const renderPaymentMethods = () => {
        return paymentMethods.map(method => {
            return (
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    // flexWrap={'wrap'}
                    className={classes.cardHolder}
                >
                    <Box className={clsx(classes.card)} display={'flex'}>
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            className={classes.iconCardHolder}
                        >
                            <img src={generateCardIcon(method) as any} className={classes.iconCard}/>
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'flex-start'}
                            className={classes.paymentDetails}
                        >
                            <div className={classes.paymentInfo}>{'**** **** **** ' + method.card?.last4}</div>
                            {/*<div className={styles.paymentInfo}>{method.card?.brand.toUpperCase()}</div>*/}
                            <div className={classes.paymentInfo}>{method.billingDetails?.name || '-'}</div>
                            <div
                                className={classes.paymentInfo}>{'exp. ' + method.card?.expMonth + '/' + method.card?.expYear}</div>

                            {
                                stripeCustomerState !== 'loading' && stripeCustomer?.invoiceSettings?.defaultPaymentMethod === method.id &&
                                <div className={classes.defaultPayment}>
                                    {t('Default')}
                                </div>
                            }

                        </Box>
                    </Box>

                    {
                        paymentMethodsWithActionInProgress.find(p => p.id === method.id) ?
                            <Box>
                                <CircularProgress/>
                            </Box> :
                            (
                                stripeCustomer?.invoiceSettings?.defaultPaymentMethod !== method.id &&
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                >
                                    <div className={classes.setDefaultCard}
                                         onClick={() => handleDefaultPaymentMethod(method)}>
                                        {t('Make default')}
                                    </div>
                                    <div className={classes.deleteCard}
                                         onClick={() => handleDeletePaymentMethod(method)}>
                                        {t('Delete')}
                                    </div>
                                </Box>
                            )
                    }
                </Box>
            )
        })
    }

    return (
        <Card className={classes.container}>
            <Box
                p={2}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                boxSizing={'border-box'}
            >
                {
                    paymentMethodsStatus === 'loading' ? <CircularProgress/> : renderPaymentMethods()
                }

                {
                    paymentMethodsStatus !== 'loading' &&
                    <Button
                        className={classes.addNewPayment}
                        // variant="contained"
                        color="primary"
                        onClick={handleAddNewPayment}>
                        {t('Add new payment method')}
                    </Button>
                }
            </Box>

            <AddPaymentMethod
                open={addPaymentMethodOpen}
                onDone={added => {
                    setAddPaymentMethodOpen(false)
                    added && getPaymentMethods()
                }}
            />

        </Card>
    )

    //endregion UI
}

export default PaymentMethods