export const languages = [
    {
        "id": 12,
        "isoCode": "de",
        "name": "German (Deutsch)"
    },
    {
        "id": 14,
        "isoCode": "en",
        "name": "English"
    },
    {
        "id": 55,
        "isoCode": "sr",
        "name": "Serbian (Srpski)"
    },
    {
        "id": 7,
        "isoCode": "bs",
        "name": "Bosnian (Bosanski)"
    },
    {
        "id": 25,
        "isoCode": "hr",
        "name": "Croatian (Hrvatski)"
    },
    {
        "id": 81,
        "isoCode": "ab",
        "name": "Abkhaz (\tаҧсуа бызшәа)"
    },
    {
        "id": 82,
        "isoCode": "aa",
        "name": "Afar (ʿAfár af)"
    },
    {
        "id": 1,
        "isoCode": "af",
        "name": "Afrikaans"
    },
    {
        "id": 83,
        "isoCode": "ak",
        "name": "Akan"
    },
    {
        "id": 42,
        "isoCode": "sq",
        "name": "Albanian (Shqip)"
    },
    {
        "id": 84,
        "isoCode": "am",
        "name": "Amharic (ኣማርኛ)"
    },
    {
        "id": 85,
        "isoCode": "ar",
        "name": "Arabic (العربية)"
    },
    {
        "id": 86,
        "isoCode": "an",
        "name": "Aragonese (Fabla)"
    },
    {
        "id": 87,
        "isoCode": "hy",
        "name": "Armenian (Հայերէն)"
    },
    {
        "id": 88,
        "isoCode": "as",
        "name": "Assamese (অসমীয়া)"
    },
    {
        "id": 89,
        "isoCode": "av",
        "name": "Avaric (магIарул мацI)"
    },
    {
        "id": 90,
        "isoCode": "ae",
        "name": "Avestan"
    },
    {
        "id": 91,
        "isoCode": "ay",
        "name": "Aymara (Aymar aru)"
    },
    {
        "id": 2,
        "isoCode": "az",
        "name": "Azerbaijani (Азәрбајҹан / Azərbaycan)"
    },
    {
        "id": 92,
        "isoCode": "bm",
        "name": "Bambara (Bamanankan)"
    },
    {
        "id": 93,
        "isoCode": "ba",
        "name": "Bashkir (башҡорт теле)"
    },
    {
        "id": 18,
        "isoCode": "eu",
        "name": "Basque (euskara)"
    },
    {
        "id": 94,
        "isoCode": "be",
        "name": "Belarusian (Беларуская мова)"
    },
    {
        "id": 95,
        "isoCode": "bn",
        "name": "Bengali (বাংলা)"
    },
    {
        "id": 96,
        "isoCode": "bh",
        "name": "Bihari"
    },
    {
        "id": 6,
        "isoCode": "",
        "name": "Bisaya"
    },
    {
        "id": 97,
        "isoCode": "bi",
        "name": "Bislama"
    },
    {
        "id": 98,
        "isoCode": "br",
        "name": "Breton (ar brezhoneg)"
    },
    {
        "id": 50,
        "isoCode": "bg",
        "name": "Bulgarian (български)"
    },
    {
        "id": 99,
        "isoCode": "my",
        "name": "Burmese (ဗမာစကား)"
    },
    {
        "id": 8,
        "isoCode": "ca",
        "name": "Catalan (Català)"
    },
    {
        "id": 101,
        "isoCode": "ch",
        "name": "Chamorro (Chamoru)"
    },
    {
        "id": 9,
        "isoCode": "ce",
        "name": "Chechen (Нохчийн мотт)"
    },
    {
        "id": 102,
        "isoCode": "ny",
        "name": "Chichewa; Chewa; Nyanja"
    },
    {
        "id": 103,
        "isoCode": "zh",
        "name": "Chinese (中文)"
    },
    {
        "id": 104,
        "isoCode": "cv",
        "name": "Chuvash"
    },
    {
        "id": 105,
        "isoCode": "kw",
        "name": "Cornish (Kernewek)"
    },
    {
        "id": 106,
        "isoCode": "co",
        "name": "Corsican"
    },
    {
        "id": 107,
        "isoCode": "cr",
        "name": "Cree (ᓀᐦᐃᔭᐍᐏᐣ)"
    },
    {
        "id": 108,
        "isoCode": "cs",
        "name": "Czech (čeština)"
    },
    {
        "id": 11,
        "isoCode": "da",
        "name": "Danish (dansk)"
    },
    {
        "id": 109,
        "isoCode": "dv",
        "name": "Divehi; Dhivehi; Maldivian;"
    },
    {
        "id": 33,
        "isoCode": "nl",
        "name": "Dutch (Nederlands)"
    },
    {
        "id": 110,
        "isoCode": "eo",
        "name": "Esperanto"
    },
    {
        "id": 13,
        "isoCode": "et",
        "name": "Estonian (eesti keel)"
    },
    {
        "id": 111,
        "isoCode": "ee",
        "name": "Ewe (Eʋegbe)"
    },
    {
        "id": 112,
        "isoCode": "fo",
        "name": "Faroese (Føroyskt)"
    },
    {
        "id": 113,
        "isoCode": "fj",
        "name": "Fijian (Vakaviti)"
    },
    {
        "id": 19,
        "isoCode": "",
        "name": "Filipino"
    },
    {
        "id": 45,
        "isoCode": "fi",
        "name": "Finnish (suomi)"
    },
    {
        "id": 21,
        "isoCode": "fr",
        "name": "French (français)"
    },
    {
        "id": 114,
        "isoCode": "ff",
        "name": "Fula; Fulah; Pulaar; Pular"
    },
    {
        "id": 23,
        "isoCode": "gl",
        "name": "Galician (Galego)"
    },
    {
        "id": 115,
        "isoCode": "ka",
        "name": "Georgian (ქართული)"
    },
    {
        "id": 116,
        "isoCode": "el",
        "name": "Greek (ελληνικά)"
    },
    {
        "id": 24,
        "isoCode": "gn",
        "name": "Guarani (Avañe’ẽ)"
    },
    {
        "id": 118,
        "isoCode": "gu",
        "name": "Gujarati (ગુજરાતી)"
    },
    {
        "id": 119,
        "isoCode": "ht",
        "name": "Haitian; Haitian Creole (Kreyòl ayisyen)"
    },
    {
        "id": 120,
        "isoCode": "ha",
        "name": "Hausa (حَوْسَ)"
    },
    {
        "id": 121,
        "isoCode": "he",
        "name": "Hebrew (עִבְרִית)"
    },
    {
        "id": 122,
        "isoCode": "hz",
        "name": "Herero (Otjiherero)"
    },
    {
        "id": 123,
        "isoCode": "hi",
        "name": "Hindi (हिन्दी)"
    },
    {
        "id": 124,
        "isoCode": "ho",
        "name": "Hiri Motu"
    },
    {
        "id": 32,
        "isoCode": "hu",
        "name": "Hungarian (Magyar)"
    },
    {
        "id": 26,
        "isoCode": "is",
        "name": "Icelandic (Íslenska)"
    },
    {
        "id": 130,
        "isoCode": "io",
        "name": "Ido"
    },
    {
        "id": 128,
        "isoCode": "ig",
        "name": "Igbo"
    },
    {
        "id": 3,
        "isoCode": "id",
        "name": "Indonesian (Bahasa Indonesia)"
    },
    {
        "id": 125,
        "isoCode": "ia",
        "name": "Interlingua"
    },
    {
        "id": 126,
        "isoCode": "ie",
        "name": "Interlingue"
    },
    {
        "id": 131,
        "isoCode": "iu",
        "name": "Inuktitut"
    },
    {
        "id": 129,
        "isoCode": "ik",
        "name": "Inupiaq"
    },
    {
        "id": 127,
        "isoCode": "ga",
        "name": "Irish"
    },
    {
        "id": 27,
        "isoCode": "it",
        "name": "Italian (italiano)"
    },
    {
        "id": 132,
        "isoCode": "ja",
        "name": "Japanese (日本語)"
    },
    {
        "id": 5,
        "isoCode": "jv",
        "name": "Javanese (Jawa)"
    },
    {
        "id": 133,
        "isoCode": "kl",
        "name": "Kalaallisut, Greenlandic"
    },
    {
        "id": 134,
        "isoCode": "kn",
        "name": "Kannada (ಕನ್ನಡ)"
    },
    {
        "id": 135,
        "isoCode": "kr",
        "name": "Kanuri"
    },
    {
        "id": 136,
        "isoCode": "ks",
        "name": "Kashmiri (कॉशुर)"
    },
    {
        "id": 137,
        "isoCode": "kk",
        "name": "Kazakh (Қазақ тілі)"
    },
    {
        "id": 138,
        "isoCode": "km",
        "name": "Khmer (ភាសាខ្មែរ)"
    },
    {
        "id": 139,
        "isoCode": "ki",
        "name": "Kikuyu (Gĩkũyũ)"
    },
    {
        "id": 140,
        "isoCode": "rw",
        "name": "Kinyarwanda (Ikinyarwanda)"
    },
    {
        "id": 141,
        "isoCode": "ky",
        "name": "Kirghiz, Kyrgyz"
    },
    {
        "id": 181,
        "isoCode": "rn",
        "name": "Kirundi (íkiRǔndi)"
    },
    {
        "id": 142,
        "isoCode": "kv",
        "name": "Komi (коми кыв)"
    },
    {
        "id": 143,
        "isoCode": "kg",
        "name": "Kongo (kikongo)"
    },
    {
        "id": 144,
        "isoCode": "ko",
        "name": "Korean (한국어)"
    },
    {
        "id": 29,
        "isoCode": "ku",
        "name": "Kurdish (Kurdí)"
    },
    {
        "id": 145,
        "isoCode": "kj",
        "name": "Kwanyama (kuanyama)"
    },
    {
        "id": 151,
        "isoCode": "lo",
        "name": "Lao (ພາສາລາວ)"
    },
    {
        "id": 146,
        "isoCode": "la",
        "name": "Latin"
    },
    {
        "id": 30,
        "isoCode": "lv",
        "name": "Latvian (latviešu valoda)"
    },
    {
        "id": 149,
        "isoCode": "li",
        "name": "Limburgish, Limburgan, Limburger"
    },
    {
        "id": 150,
        "isoCode": "ln",
        "name": "Lingala"
    },
    {
        "id": 31,
        "isoCode": "lt",
        "name": "Lithuanian (lietuvių kalba)"
    },
    {
        "id": 152,
        "isoCode": "lu",
        "name": "Luba-Katanga"
    },
    {
        "id": 148,
        "isoCode": "lg",
        "name": "Luganda"
    },
    {
        "id": 147,
        "isoCode": "lb",
        "name": "Luxembourgish (Lëtzebuergesch)"
    },
    {
        "id": 51,
        "isoCode": "mk",
        "name": "Macedonian (македонски)"
    },
    {
        "id": 154,
        "isoCode": "mg",
        "name": "Malagasy"
    },
    {
        "id": 4,
        "isoCode": "ms",
        "name": "Malay (Bahasa melayu)"
    },
    {
        "id": 155,
        "isoCode": "ml",
        "name": "Malayalam (മലയാളം)"
    },
    {
        "id": 156,
        "isoCode": "mt",
        "name": "Maltese (Malti)"
    },
    {
        "id": 153,
        "isoCode": "gv",
        "name": "Manx (Gaelg)"
    },
    {
        "id": 157,
        "isoCode": "mi",
        "name": "Māori"
    },
    {
        "id": 158,
        "isoCode": "mr",
        "name": "Marathi (मराठी)"
    },
    {
        "id": 159,
        "isoCode": "mh",
        "name": "Marshallese (Kajin M̧aje)"
    },
    {
        "id": 52,
        "isoCode": "mn",
        "name": "Mongolian (монгол)"
    },
    {
        "id": 160,
        "isoCode": "na",
        "name": "Nauru (Ekakairũ Naoero)"
    },
    {
        "id": 161,
        "isoCode": "nv",
        "name": "Navajo, Navaho"
    },
    {
        "id": 165,
        "isoCode": "ng",
        "name": "Ndonga"
    },
    {
        "id": 34,
        "isoCode": "",
        "name": "Nederlands (België)"
    },
    {
        "id": 164,
        "isoCode": "ne",
        "name": "Nepali (नेपाली)"
    },
    {
        "id": 36,
        "isoCode": "nn",
        "name": "Norsk (nynorsk)"
    },
    {
        "id": 163,
        "isoCode": "nd",
        "name": "North Ndebele"
    },
    {
        "id": 186,
        "isoCode": "se",
        "name": "Northern Sami"
    },
    {
        "id": 35,
        "isoCode": "no",
        "name": "Norwegian (Norsk)"
    },
    {
        "id": 162,
        "isoCode": "nb",
        "name": "Norwegian Bokmål"
    },
    {
        "id": 166,
        "isoCode": "nb",
        "name": "Norwegian Nynorsk"
    },
    {
        "id": 167,
        "isoCode": "ii",
        "name": "Nuosu"
    },
    {
        "id": 169,
        "isoCode": "oc",
        "name": "Occitan"
    },
    {
        "id": 170,
        "isoCode": "oj",
        "name": "Ojibwe, Ojibwa (ᐊᓂᔑᓇᐯᒧᐏᐣ)"
    },
    {
        "id": 173,
        "isoCode": "or",
        "name": "Oriya (ଓଡ଼ିଆ)"
    },
    {
        "id": 172,
        "isoCode": "om",
        "name": "Oromo (Afaan Oromo)"
    },
    {
        "id": 174,
        "isoCode": "os",
        "name": "Ossetian, Ossetic (ирон ӕвзаг)"
    },
    {
        "id": 176,
        "isoCode": "pi",
        "name": "Pāli (पालि)"
    },
    {
        "id": 175,
        "isoCode": "pa",
        "name": "Panjabi, Punjabi (ਪੰਜਾਬੀ)"
    },
    {
        "id": 178,
        "isoCode": "ps",
        "name": "Pashto, Pushto (پښتو)"
    },
    {
        "id": 177,
        "isoCode": "fa",
        "name": "Persian (فارسى)"
    },
    {
        "id": 38,
        "isoCode": "pl",
        "name": "Polish (polski)"
    },
    {
        "id": 39,
        "isoCode": "pt",
        "name": "Portuguese (português)"
    },
    {
        "id": 221,
        "isoCode": "pt-BR",
        "name": "Portuguese Brazilian (português)"
    },
    {
        "id": 179,
        "isoCode": "qu",
        "name": "Quechua (Qhichwa)"
    },
    {
        "id": 41,
        "isoCode": "ro",
        "name": "Romanian (română)"
    },
    {
        "id": 182,
        "isoCode": "ro",
        "name": "Moldavian, Moldovan"
    },
    {
        "id": 180,
        "isoCode": "rm",
        "name": "Romansh (rumantsch)"
    },
    {
        "id": 53,
        "isoCode": "ru",
        "name": "Russian (Русский)"
    },
    {
        "id": 187,
        "isoCode": "sm",
        "name": "Samoan (Gagana Samoa)"
    },
    {
        "id": 188,
        "isoCode": "sg",
        "name": "Sango (Yângâ tî Sängö)"
    },
    {
        "id": 183,
        "isoCode": "sa",
        "name": "Sanskrit (Saṁskṛta)"
    },
    {
        "id": 184,
        "isoCode": "sc",
        "name": "Sardinian (Sardu)"
    },
    {
        "id": 189,
        "isoCode": "gd",
        "name": "Scottish Gaelic; Gaelic"
    },
    {
        "id": 190,
        "isoCode": "sn",
        "name": "Shona"
    },
    {
        "id": 185,
        "isoCode": "sd",
        "name": "Sindhi"
    },
    {
        "id": 191,
        "isoCode": "si",
        "name": "Sinhala, Sinhalese (සිංහල)"
    },
    {
        "id": 43,
        "isoCode": "sk",
        "name": "Slovak (slovenčina)"
    },
    {
        "id": 44,
        "isoCode": "sl",
        "name": "Slovenian (slovenščina)"
    },
    {
        "id": 192,
        "isoCode": "so",
        "name": "Somali (af Soomaali)"
    },
    {
        "id": 168,
        "isoCode": "nr",
        "name": "South Ndebele"
    },
    {
        "id": 193,
        "isoCode": "st",
        "name": "Southern Sotho"
    },
    {
        "id": 16,
        "isoCode": "es",
        "name": "Spanish (español)"
    },
    {
        "id": 194,
        "isoCode": "su",
        "name": "Sundanese (Basa Sunda)"
    },
    {
        "id": 28,
        "isoCode": "sw",
        "name": "Swahili (Kiswahili)"
    },
    {
        "id": 195,
        "isoCode": "ss",
        "name": "Swati (siSwati)"
    },
    {
        "id": 46,
        "isoCode": "sv",
        "name": "Swedish (Svenska)"
    },
    {
        "id": 203,
        "isoCode": "tl",
        "name": "Tagalog"
    },
    {
        "id": 209,
        "isoCode": "ty",
        "name": "Tahitian"
    },
    {
        "id": 198,
        "isoCode": "tg",
        "name": "Tajik (тоҷики)"
    },
    {
        "id": 196,
        "isoCode": "ta",
        "name": "Tamil (தமிழ்)"
    },
    {
        "id": 207,
        "isoCode": "tt",
        "name": "Tatar (татарча)"
    },
    {
        "id": 197,
        "isoCode": "te",
        "name": "Telugu (తెలుగు)"
    },
    {
        "id": 199,
        "isoCode": "th",
        "name": "Thai (ภาษาไทย)"
    },
    {
        "id": 201,
        "isoCode": "bo",
        "name": "Tibetan Standard, Tibetan (བོད་སྐད་)"
    },
    {
        "id": 47,
        "isoCode": "vi",
        "name": "Tiếng Việt"
    },
    {
        "id": 200,
        "isoCode": "ti",
        "name": "Tigrinya (ትግርኛ)"
    },
    {
        "id": 205,
        "isoCode": "to",
        "name": "Tonga (Tonga Islands)"
    },
    {
        "id": 206,
        "isoCode": "ts",
        "name": "Tsonga (xiTsonga)"
    },
    {
        "id": 204,
        "isoCode": "tn",
        "name": "Tswana (Setswana)"
    },
    {
        "id": 48,
        "isoCode": "tr",
        "name": "Turkish (Türkçe)"
    },
    {
        "id": 202,
        "isoCode": "tk",
        "name": "Turkmen (түркmенче)"
    },
    {
        "id": 208,
        "isoCode": "tw",
        "name": "Twi"
    },
    {
        "id": 210,
        "isoCode": "ug",
        "name": "Uighur, Uyghur"
    },
    {
        "id": 56,
        "isoCode": "uk",
        "name": "Ukrainian (Українська)"
    },
    {
        "id": 211,
        "isoCode": "ur",
        "name": "Urdu (urdū, اردو)"
    },
    {
        "id": 37,
        "isoCode": "uz",
        "name": "Uzbek (ўзбек тили)"
    },
    {
        "id": 212,
        "isoCode": "ve",
        "name": "Venda (tshiVenḓa)"
    },
    {
        "id": 213,
        "isoCode": "vi",
        "name": "Vietnamese (tiếng việt, 㗂越)"
    },
    {
        "id": 214,
        "isoCode": "vo",
        "name": "Volapük"
    },
    {
        "id": 215,
        "isoCode": "wa",
        "name": "Walloon (walon)"
    },
    {
        "id": 10,
        "isoCode": "cy",
        "name": "Welsh (Cymraeg)"
    },
    {
        "id": 22,
        "isoCode": "fy",
        "name": "Western Frisian"
    },
    {
        "id": 216,
        "isoCode": "wo",
        "name": "Wolof"
    },
    {
        "id": 217,
        "isoCode": "xh",
        "name": "Xhosa (isiXhosa)"
    },
    {
        "id": 218,
        "isoCode": "yi",
        "name": "Yiddish (Yidish, ײִדיש)"
    },
    {
        "id": 219,
        "isoCode": "yo",
        "name": "Yoruba (Yorùbá)"
    },
    {
        "id": 220,
        "isoCode": "za",
        "name": "Zhuang, Chuang (Vaƅcueŋƅ)"
    }
];
