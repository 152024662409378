import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {getClubLocationGeo, getClubName} from "../../../util/profile.util";
import ETextField from "../../common/ETextField.component";
import GoogleMapReact from "google-map-react"
import {mapStyleGrey} from "../../../util/map.style";
import ClubModel from "../../../models/club.model";

interface Props {
    club?: ClubModel,
    setClub: (club: ClubModel) => void,
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
    input: {
        width: '100%'
    }
}))


const ClubRequestInfo = ({club, setClub}: Props) => {

    const classes = useStyle()
    const history = useHistory()
    const {t, i18n} = useTranslation()

    const [map, setMap] = useState()
    const [marker, setMarker] = useState<any>()

    const updateClubFields = (data: { field: string, value: any }[]) => {
        const newClub: any = {
            ...club,
        }
        data.forEach(d => newClub[d.field] = d.value)
        setClub(newClub)
    }

    const updateClubField = (field: string, value: any) => {
        updateClubFields([{field, value}])
    }

    const drawCircle = () => {
        marker?.setMap(null)

        if (!getClubLocationGeo(club)) return

        try {
            setMarker(new google.maps.Marker({
                map,
                position: getClubLocationGeo(club)
            }))
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        drawCircle()
    }, [club, map])

    //endregion Handlers

    //region UI

    const commonInfoUI = (
        <Grid
            container
            spacing={2}
            className={classes.sectionData}
        >

            <ETextField
                grid={'2/3'}
                label={t('Club name')}
                value={getClubName(club)}
            />

        </Grid>
    )

    const contactInfoUI = (
        <Grid
            container
            spacing={2}
            className={classes.sectionData}
        >
            <ETextField
                grid={'1/3'}
                label={t('Admin name')}
                value={`${club?.createdBy?.profile?.firstName} ${club?.createdBy?.profile?.lastName}`}
            />

            <ETextField
                grid={'1/3'}
                label={t('Phone')}
                value={club?.createdBy?.profile?.contactInfo?.phone || '-'}
            />

            <ETextField
                grid={'1/3'}
                label={t('Role')}
                value={club?.createdBy?.userType?.name || '-'}
            />

        </Grid>
    )


    const locationUI = (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <div style={{
                    width: '100%',
                    height: 300,
                    marginTop: 20,
                    marginBottom: 30
                }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: process.env.REACT_APP_MAPS_KEY!}}
                        defaultCenter={getClubLocationGeo(club)}
                        center={getClubLocationGeo(club)}
                        defaultZoom={7}
                        options={{styles: mapStyleGrey}}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({map}) => setMap(map)}
                    />
                </div>
            </Grid>

        </Grid>
    )

    return (
        <Box
            p={3}
            display={'flex'}
            flexDirection={'column'}
        >
            {commonInfoUI}

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Contact info")}
            </Typography>
            {contactInfoUI}


            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Location")}
            </Typography>
            {locationUI}

        </Box>
    )

    //endregion UI
}

export default ClubRequestInfo
