export const windowWidth = () => {
    if (typeof window !== 'undefined') {
        return window.innerWidth
    }
    return 1200
}

export const windowHeight = () => {
    if (typeof window !== 'undefined') {
        return window.innerHeight
    }
    return 900
}