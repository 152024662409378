const Privacy = () => {
    return (
        <div style={{marginTop: '100px', padding: '20px'}}>

            <h1>PRIVACY POLICY</h1>
            <p>This privacy policy will help you understand how we collect and use all the personal data you decide to share with us.</p>
            <h1>THE DATA WE COLLECT AUTOMATICALLY</h1>
            <p>Like most of the websites, we do capture limited information every time you visit our website. This includes your IP address, type of your browser, the operating system, etc. None of this information is personal and is only stored for audit purposes.</p>
            <p>playerhunter uses the information that is collected automatically: (1) to administer the Website for internal operations including troubleshooting purposes; (2) to ensure that content from the Website is presented in the most effective manner for you and your devices; and (3) as part of playerhunter’s effort to keep our Website safe and secure. (4) playerhunter shares this information with certain third party tools, more information on these tools and how they use this data, go to the third party section of this document.</p>
            <h1>THE PERSONAL DATA</h1>
            <p>playerhunter uses all user information with the explicit consent of the user. </p>
            <p>The purpose of playerhunter is to match players who are looking for a new club with the right club. In order to guarantee the best experience for our users, this involves storing and processing the users information (first name, last name, birthdate, nationality, email, phone number, gender, current club, past club, position, user location, chosen location, photo and video media files) through our internal backend and algorithms and various third-party tools that are explained in detail below.</p>
            <h2>Playerhunter internal use of data</h2>
            <p>By explicitly applying to an opportunity your information is shared with clubs and their employees.</p>
            <p>By allowing the mobile device to send the users location, playerhunter may store and use that information for internal analytics and marketing purposes and for matching you with corresponding clubs.</p>
            <p>If a user wishes to obtain a copy of personal information that playerhunter holds about you or a user wants to delete his or her account and all associated data, he or she needs to write a written request to support@playerhunter.com. Before responding to your request, playerhunter may ask you to verify your identity and to provide further details about your request. playerhunter will endeavor to respond within an appropriate timeframe and, in any event, within any timescales required by law.</p>
            <h2>Playerhunter - Sharing of information</h2>
            <p>Playerhunter shares personal information with the users consent or as necessary to complete transactions or provide the products the user requested or authorized. We also share personal information as follows:</p>
            <ul><li>Affiliates and Subsidiaries. Our subsidiaries and affiliates share standard information systems and process information as needed to provide our products and operate our business.</li>
            <li>Third-Party Service Providers. With third parties who work on our behalf for the purposes described in this Privacy Statement, provided that such third parties agree to provide at least the same level of privacy protection as required by this Privacy Statement. These services may include providing cloud computing infrastructure, website crash reporting, and providing customer support or account administration. </li>
            <li>Lawful Requests. To comply with laws or to respond to lawful requests and legal process, provided that playerhunter will endeavor to notify you, where practical and permitted by applicable law or regulation, if playerhunter has received a lawful request for your information.</li>
            <li>Protection of Rights and Property. To protect the rights and property of playerhunter, our agents, customers, and others, including to enforce our agreements and policies.</li>
            <li>Emergency. In an emergency, including to protect the personal safety of any person.</li>
            <li>Corporate Restructuring. For a corporate transaction (or negotiation of a corporate transaction) involving sale or transfer of all or a part of our business or assets (corporate transaction may include, for example, any merger, financing, acquisition, divestiture or dissolution transaction or proceeding). We may transfer your personal information in the event of an insolvency, bankruptcy, or receivership. If another company acquires our company, business, or assets, that company will possess the personal information collected by us and will assume the rights and obligations regarding your personal information as described in this Statement</li></ul>
        <h2>Opt out / Do not track</h2>
    <p>To opt-out of tracking for individual third party tools used by playerhunter in its Applications or Websites, please refer to the section third party tools. </p>
<p>playerhunter users may have the right to access or submit requests to review, correct, update, or delete personal information. Users can ask to review their personal information that playerhunter maintains about the user by sending a written request to support@playerhunter.com. We may limit or deny access to personal information where providing such access to unreasonably burdensome, expensive under the circumstances.</p>
<h2>Third Party Tools</h2>
    <h3>Mixpanel</h3>
    <p>We use a service provided by Mixpanel, Inc. (“Mixpanel”) to provide us with analytics data regarding Website Users’ interactions with our Website and Services. You may opt-out of Mixpanel’s automatic retention of data that is collected while using the Services by visiting <u>https://mixpanel.com/optout/</u>. To track opt-outs, Mixpanel uses a persistent opt-out cookie placed on your device. Please note that if you get a new computer, install a new browser, erase or otherwise alter the browser’s cookie file (including upgrading certain browsers), you may delete the Mixpanel opt-out cookie.  </p>
    <p>playerhunter uses Mixpanel to report individualized and aggregated analytics metrics about the performance of the Applications and Websites and for marketing purposes. </p>
    <p>If the user decides to create a playerhunter account to use our Application or Website Services, the user must enter his/her first and last name, email, phone number, birth date, nationality, gender, current or past club they are playing for, position, location and password or otherwise enter similar information into forms or surveys on our Site or Applications. This information is stored and processed by Mixpanel. </p>
<p>Furthermore playerhunter tracks through Mixpanel all user interactions with the playerhunter app and websites, this includes all screen views, uploads and form entries.</p>
<p>By allowing the mobile device to send the user’s location, playerhunter may store and use that information for internal analytics and marketing purposes.</p>
<p>In addition, playerhunter may also collect information from the mobile device including, but not limited to, the first time the Application is opened, the mobile carrier, the device identifier, the type of device that is being used, if the user has downloaded our Applications or visited our websites, and the usage activity while using the Application through Mixpanel.</p>
<h3>Facebook</h3>
    <p>We may use certain tools offered by Facebook, Inc. (“Facebook”) that enable it to collect or receive information about actions Website Users take on our Website through use of cookies, web beacons and other storage technologies or in order to provide measurement services, targeted ads and other services. For more information regarding the collection and use of such information by Facebook, please see the Facebook Data Policy, available at <u>https://www.facebook.com/policy.php</u>.  </p>
    <p>playerhunter uses facebook services for acquiring, tracking and retargeting visitors of its Applications and Websites and to report aggregated analytics metrics about the performance of the Applications and Websites and for marketing purposes. </p>
    <p>In addition users can sign up and log in to playerhunter Applications and Websites using the facebook login.</p>
<h3>Google OAuth</h3>
    <p>playerhunter users of its Applications and Websites can use Googles OAuth Services to sign up and login to use the Playerhunter Services. For more information on the data used and the general services please visit <u>https://developers.google.com/terms/api-services-user-data-policy</u> and <u>https://developers.google.com/terms/</u></p>
    <h3>Google Firebase</h3>
    <p>playerhunter uses Google Firebase for its Authentication, Cloud Messaging and Crash Reporting functionalities. For these purposes, Google Firebase uses IP Addresses, Login Information, Phone numbers, user agents, instances IDs and crash traces. For more information on Google Firebase and how the user data is saved and processed, please visit: <u>https://firebase.google.com/support/privacy</u> </p>
    <h3>Branch.io</h3>
    <p>Branch.io is used to attribute user when downloading the playerhunter Applications or visiting the websites and to report individualized and aggregated analytics metrics about the performance of the Applications and Websites and for marketing purposes. </p>
<p>For this purpose, users are identified when clicking on a link and then matched when signing up for one of the playerhunter services. </p>
<h3>Intercom</h3>
    <p>We use Intercom (www.intercom.com) to facilitate communications with, maintain information about, and collect publicly available information about, our Website and Applications Users. For more information on Intercom’s services, please visit <u>www.intercom.com</u>.  </p>
<h3>Google Analytics</h3>
    <p>We may use Google Analytics and Google Analytics Demographics and Interest Reporting to collect information regarding visitor behavior and visitor demographics on our Website and to develop website content. This analytics data is not tied to any Personal Data. For more information about Google Analytics, please visit <u>www.google.com/policies/privacy/partners/</u>. You can opt-out of Google’s collection and processing of data generated by your use of the Services by going to <u><a href="http://tools.google.com/dlpage/gaoptout" rel="nofollow">http://tools.google.com/dlpage/gaoptout</a></u>. Branch has implemented Google’s reCAPTCHA technology on our Website to protect it from abusive automated crawling and spam. This service is provided by Google Inc., 1600 Amphitheater Parkway, Mountain View, CA 94043, USA. Use of the reCAPTCHA technology is subject to the Google <u>Privacy Policy</u> and <u>Terms of Service</u>.  </p>
    <h3>Cloudinary</h3>
    <p>Playerhunter uses Cloudinary services to manage, optimize and deliver user images and videos. For this purpose, Cloudinary accesses this user information by Playerhunter users. For more information, please visit: <u>https://cloudinary.com/privacy</u></p>
    <h3>Applozic</h3>
    <p>Playerhunter users Applozic services to provide a chat tool to its users. When using this particular feature of the playerhunter Applications or Websites, Applozic will get access to certain user data, this includes the user’s name, device id, phone number, email. </p>
<p>For more information about Applozic and its data privacy, please visit https://www.applozic.com/privacy.html</p>
        <h3>Amazon Web Services</h3>
        <p>In order to provide the playerhunter services to our users on all Applications and Websites, we use Amazon Web Services to store and process all user data, including first name, last name, birthdate, nationality, email, phone number, gender, current club, past club, position, user location, chosen location, photo and video media files, Interactions with our Applications and Websites.</p>
<p>For more information on the data & privacy policy by Amazon Web Services please visit  <u>https://aws.amazon.com/de/compliance/data-privacy/</u></p>
    <h2>COOKIES</h2>
    <p>We use cookies and third party cookies that place information directly onto your local machine. In addition we use a type of cookie called a session variable that stores information on the server, while you are on our website. This is used solely to make sure you have the most enjoyable visit through our website.</p>
<p>For more information on the third party services and cookies playerhunter is using, please refer to the third party services section.</p>
<h2>Access to services</h2>
    <p>Everyone can access playerhunter´s website and applications and it’s public information. All ‘guests‘ or unregistered members have limited options. Personal details of each subscriber are protected, so they are not visible to unregistered persons. Selected information is visible only to registered members.</p>
<h2>Direct marketing</h2>
    <p>playerhunter may use the information you provide on its Applications or Website for direct marketing purposes to provide updates; newsletters; events; or other communications that may interest you. Where required by law, your prior consent will be obtained before sending direct marketing. In any event, playerhunter will offer you the option to unsubscribe in every communication sent.</p>
<h2>Links to other website and social media</h2>
    <p>Where playerhunter provides links to other websites, this is done for information purposes only. The other websites are outside playerhunter’s control and are not covered by this Policy. If you access other websites using the links provided, the operators of these websites may collect information from you which will be used by them in accordance with their privacy policy, which may differ from playerhunter’s.</p>
<h2>Contact</h2>
    <p>If you require any further information on our policies, please email us at support@playerhunter.com and we will contact you shortly.</p>
<h2>Security</h2>
    <p>playerhunter uses security measures in order to protect itself against the loss, misuse and alteration of data. playerhunter applies appropriate security measures to protect your personal information that is under playerhunter’s control from unauthorised access, collection, use, disclosure, copying, modification or disposal.</p>
<p>All information you provide to playerhunter is stored on secure servers. Where you have a password, which enables you to access playerhunter’s website or its Applications, you are responsible for keeping this password secure and confidential.</p>
<h2>Data retention</h2>
    <p>playerhunter will not retain your information for longer than is necessary for the business purposes or for legal requirements. However, please be advised that playerhunter may retain some information after you have closed your account with playerhunter, for instance if doing so is necessary to meet legal obligations, such as retaining the information for tax and accounting purposes.</p>
<h2>Amendments</h2>
    <p>We reserve the right to change and revise our Privacy Policy and our Terms of Use at any time. We encourage you to refer to this policy on an ongoing basis, so that you always understand our Privacy Policy. Unless stated otherwise, our current Privacy Policy applies to all the information we have on all our members and their accounts.</p>

        </div>
    )
};

export default Privacy;