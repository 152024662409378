import React, {useEffect, useState} from 'react'
import {
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem, Radio,
    RadioGroup,
    Select,
    Slider,
    Typography
} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import UserModel, {TransferAvailabilityStatus, UserProfile, UserTypeId} from "../../../models/user.model";
import {
    getEmail,
    getFirstName,
    getLastName,
    getPhone,
    getProfileMainPosition, getProfileSalaryLocalized,
    getProfileSecondaryPositions, getProfileTransferFeeLocalized,
    getUserHeight, getUserTransfermarktValue
} from "../../../util/profile.util";
import ETextField from "../../common/ETextField.component";
import EDateField from "../../common/EDateField.component";
import CountrySelector from "../../common/CountrySelector.component";
import {
    addCountries,
    eeaChCountryIds,
    euCountryIds,
    getCountriesByIds,
    removeCountries,
    worldIds
} from "../../../services/countries";
import Position from "../../common/Position/Position";
import {getGridSizes} from "../../common/grid.util";
import styles from "../preview/PlayerCard/PlayerCard.module.scss";
import EButton from "../../common/EButton";
import LanguageSelector from "../../common/LanguageSelector.component";
import {LoginType} from "../../../models/loginType.model";
import {getOpportunitySalaryLocalized, getOpportunityTransferFeeLocalized} from "../../../util/opportunity.util";

interface Props {
    user?: UserModel,
    setUser: (user: UserModel) => void,
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
    input: {
        width: '100%'
    },
    radioLabel: {
        fontSize: '14px !important'
    },
}))


const PlayerProfile = ({user, setUser}: Props) => {

    const classes = useStyle()
    const history = useHistory()
    const {t, i18n} = useTranslation()

    const [map, setMap] = useState()
    const [marker, setMarker] = useState<any>()

    const profile = user?.profile || {}
    const isRegularUser = user?.userType?.id === UserTypeId.REGULAR_TYPE_ID
    const isAgent = user?.userType?.id === UserTypeId.AGENT_TYPE_ID
    const isClubAdmin = user?.userType?.id === UserTypeId.STAFF_TYPE_ID
    const isAgentsPlayer = user?.loginType === LoginType.SUBUSER

    const parts = []
    if (getFirstName(user)) parts.push(getFirstName(user))
    if (getLastName(user)) parts.push(getLastName(user))
    const googleQuery = [...parts, 'transfermarkt'].join('+')
    const youtubeQuery = [...parts, 'highlights'].join('+')

    const [customInputState, setCustomInputState] = useState(false)
    const [customTransferFeeInputState, setCustomTransferFeeInputState] = useState(false)

    useEffect(() => {
        if (profile) {
            setCustomInputState(getProfileSalaryLocalized(profile) === 'Custom')
            setCustomTransferFeeInputState(getProfileTransferFeeLocalized(profile) === 'Custom')
        }
    }, [profile?.id])

    const updateProfileFields = (data: { field: keyof UserProfile, value: any }[]) => {
        const newUser: any = {
            ...user,
            profile: {
                ...user?.profile
            }
        }
        data.forEach(d => newUser.profile[d.field] = d.value)
        setUser(newUser)
    }

    const salaryHandler = (newFrom?: number, newTo?: number) => {
        updateProfileFields([
            {field: 'fromSalary', value: newFrom},
            {field: 'toSalary', value: newTo},
        ])
    };

    const updateProfileField = (field: keyof UserProfile, value: any) => {
        updateProfileFields([{field, value}])
    }

    const handleAddNationality = (country: any) => {
        let nationalitiesToAdd
        if (country.countryCode === 'eu' || country.countryCode === 'eeach' || country.countryCode === 'world') {
            switch (country.countryCode) {
                case 'eu':
                    nationalitiesToAdd = getCountriesByIds(euCountryIds)
                    break
                case 'eeach':
                    nationalitiesToAdd = getCountriesByIds(eeaChCountryIds)
                    break
                case 'world':
                    nationalitiesToAdd = getCountriesByIds(worldIds)
                    break
            }
        } else {
            nationalitiesToAdd = [country]
        }
        const updatedCountries = addCountries(user?.profile?.nationalities || [], nationalitiesToAdd || [])

        updateProfileField('nationalities', updatedCountries)
    }

    const handleRemoveNationality = (country: any) => {
        let nationalitiesToRemove
        if (country.countryCode === 'eu') {
            nationalitiesToRemove = getCountriesByIds(euCountryIds)
        } else if (country.countryCode === 'eeach') {
            nationalitiesToRemove = getCountriesByIds(eeaChCountryIds)
        } else if (country.countryCode === 'world') {
            nationalitiesToRemove = getCountriesByIds(worldIds)
        } else {
            nationalitiesToRemove = [country]
        }

        const removedCountries = removeCountries(user?.profile?.nationalities || [], nationalitiesToRemove)

        updateProfileField('nationalities', removedCountries)
    }

    const onPositionClick = (position: string) => {

        let main = getProfileMainPosition(profile)
        let secondary1 = getProfileSecondaryPositions(profile)?.[0]
        let secondary2 = getProfileSecondaryPositions(profile)?.[1]

        if (position === main) {
            updateProfileFields([{field: 'mainPosition', value: ''}])
            return
        }

        if (main === 'Goalkeeper') {
            main = position
            secondary1 = ''
            secondary2 = ''
        } else {
            if (position === 'Goalkeeper') {
                main = position
                secondary1 = ''
                secondary2 = ''
            } else {
                let unselect = false
                if (position === secondary1) {
                    secondary1 = ''
                    unselect = true
                }

                if (position === secondary2) {
                    secondary2 = ''
                    unselect = true
                }

                if (!unselect) {
                    if (!main) {
                        main = position
                    } else if (!secondary1) {
                        secondary1 = position
                    } else if (!secondary2) {
                        secondary2 = position
                    } else {
                        main = position
                    }
                }
            }
        }

        const positions = [secondary1, secondary2].filter(position => !!position) as string[]
        updateProfileFields([
            {field: 'mainPosition', value: main},
            {field: 'secondaryPositions', value: positions}
        ])
    }

    //endregion Handlers

    //region UI

    let phIndexFactor = 1
    if (user?.profile?.instatIndex) {
        phIndexFactor = (user?.profile?.phIndex || 0) / user?.profile?.instatIndex
        phIndexFactor = Math.max(0, Math.min(phIndexFactor, 2))
    }

    const generalUI = (
        <Grid
            container
            spacing={2}
            className={classes.sectionData}
            alignItems={'center'}
        >
            <Grid item {...getGridSizes('1/3')}>
                <FormControl
                    variant="outlined"
                    className={classes.input}
                    margin={'normal'}
                >
                    <InputLabel>{t("Transfer availability")}</InputLabel>
                    <Select
                        label={'Transfer availability'}
                        value={user?.profile?.transferAvailabilityStatus || TransferAvailabilityStatus.TRANSFER_AND_LOAN}
                        className={classes.input}
                        onChange={event => updateProfileField('transferAvailabilityStatus', event.target.value)}
                    >
                        <MenuItem value={TransferAvailabilityStatus.TRANSFER_AND_LOAN}>{t("Transfer and loan")}</MenuItem>
                        <MenuItem value={TransferAvailabilityStatus.TRANSFER_ONLY}>{t('Transfer only')}</MenuItem>
                        <MenuItem value={TransferAvailabilityStatus.LOAN_ONLY}>{t('Loan only')}</MenuItem>
                        <MenuItem value={TransferAvailabilityStatus.NOT_AVAILABLE}>{t('Not availabile')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

        </Grid>
    )

    const qualityIndexUI = (
        <Grid
            container
            spacing={2}
            className={classes.sectionData}
            alignItems={'center'}
        >

            <ETextField
                grid={'1/3'}
                label={t('League level')}
                value={user?.profile?.leagueLevel}
                onChange={event => updateProfileField('leagueLevel', +event)}
            />

            <ETextField
                grid={'1/3'}
                label={t('Instat index')}
                value={user?.profile?.instatIndex}
                onChange={event => updateProfileField('instatIndex', +event)}
            />

            <Grid item {...getGridSizes('1/3')}>
                <Box>
                    <Typography>Ph Index (factor: {phIndexFactor}) (calculated: {user?.profile?.phIndex})</Typography>
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                    >
                        <Box marginRight={3}>0</Box>
                        <Slider
                            disabled={!user?.profile?.instatIndex}
                            onChange={(_: any, newValue: any) => {
                                updateProfileField('phIndex', Math.floor(newValue * (user?.profile?.instatIndex || 0)))
                            }}
                            value={phIndexFactor}
                            valueLabelDisplay="auto"
                            min={0}
                            max={2}
                            step={0.1}
                        />
                        <Box marginLeft={3}>2</Box>
                    </Box>
                </Box>
            </Grid>

            {/*<ETextField*/}
            {/*    grid={'1/3'}*/}
            {/*    label={t('Playerhunter index')}*/}
            {/*    value={user?.profile?.phIndex}*/}
            {/*    onChange={event => updateProfileField('phIndex', +event)}*/}
            {/*/>*/}

        </Grid>
    )

    const contactUI = (
        <Grid
            container
            spacing={2}
            className={classes.sectionData}
        >

            <ETextField
                grid={'1/3'}
                label={t('Phone')}
                value={getPhone(user)}
                onChange={event => updateProfileField('contactInfo', {
                    ...user?.profile?.contactInfo,
                    phone: event
                })}
                disabled={isAgentsPlayer}
            />

            <ETextField
                grid={'1/3'}
                label={getEmail(user)}
                value={user?.profile?.contactInfo?.email || ''}
                onChange={event => updateProfileField('contactInfo', {
                    ...user?.profile?.contactInfo,
                    email: event
                })}
                disabled
            />


        </Grid>
    )

    const contractUI = (
        <Grid
            container
            spacing={2}
            className={classes.sectionData}
            alignItems={'center'}
        >

            <Grid item xs={12} sm={4}>
                <FormControl
                    variant="outlined"
                    className={classes.input}
                    margin={'normal'}
                >
                    <InputLabel>{t("Agent")}</InputLabel>
                    <Select
                        disabled={isAgentsPlayer}
                        label={'Agent'}
                        value={isAgentsPlayer || user?.profile?.hasAgent ? 'true' : 'false'}
                        className={classes.input}
                        onChange={e => updateProfileField('hasAgent', e.target.value === 'true')}
                    >
                        <MenuItem value={"true"}>Has agent</MenuItem>
                        <MenuItem value={"false"}>No agent</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <EDateField
                grid={'1/3'}
                label={'Contract from'}
                value={user?.profile?.currentContractFrom}
                onChange={d => updateProfileField('currentContractFrom', d as Date)}
            />

            <EDateField
                grid={'1/3'}
                label={'Contract from'}
                value={user?.profile?.currentContractTo}
                onChange={d => updateProfileField('currentContractTo', d as Date)}
            />

        </Grid>
    )

    const salaryUI = (
        <Grid item xs={6}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>Salary range</Typography>
                </Grid>
                <Grid item xs={8}>
                    <RadioGroup
                        aria-label="salary" name="gender1"
                        value={getProfileSalaryLocalized(user?.profile)}
                        onChange={e => {
                            const newValue = e.target.value
                            switch (newValue) {
                                case '-':
                                    setCustomInputState(false)
                                    salaryHandler(0, 0)
                                    break
                                case '0 - 2.000€':
                                    setCustomInputState(false)
                                    salaryHandler(0, 2_000)
                                    break
                                case '2.000 - 5.000€':
                                    setCustomInputState(false)
                                    salaryHandler(2_000, 5_000)
                                    break
                                case '5.000 - 10.000€':
                                    setCustomInputState(false)
                                    salaryHandler(5_000, 10_000)
                                    break
                                case '10.000 - 25.000€':
                                    setCustomInputState(false)
                                    salaryHandler(10_000, 25_000)
                                    break
                                case '25.000 - 50.000€':
                                    setCustomInputState(false)
                                    salaryHandler(25_000, 50_000)
                                    break
                                case '50.000 - 100.000€':
                                    setCustomInputState(false)
                                    salaryHandler(50_000, 100_000)
                                    break
                                case '100.000€+':
                                    setCustomInputState(false)
                                    salaryHandler(100_000, 0)
                                    break
                                case 'Custom':
                                    setCustomInputState(true)
                                    salaryHandler(undefined, undefined)
                                    break
                            }
                        }}
                    >
                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'-'}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="Salary is not public"
                        />

                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'0 - 2.000€'}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="0 - 2.000€"
                        />

                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'2.000 - 5.000€'}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="2.000€ - 5.000€"
                        />

                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'5.000 - 10.000€'}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="5.000€ - 10.000€"
                        />

                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'10.000 - 25.000€'}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="10.000€ - 25.000€"
                        />

                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'25.000 - 50.000€'}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="20.000€ - 50.000€"
                        />

                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'50.000 - 100.000€'}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="50.000€ - 100.000€"
                        />

                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'100.000€+'}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="100.000€ or more"
                        />

                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'Custom'}
                            checked={customInputState}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="Custom"
                        />

                        {
                            customInputState &&
                            <Grid container spacing={2}>
                                <ETextField
                                    grid={'1/2'}
                                    value={user?.profile?.fromSalary || ''}
                                    onChange={e => updateProfileField('fromSalary', e !== '' ? parseInt(e.replace(/[^0-9]/g, '')) : undefined)}
                                />
                                <ETextField
                                    grid={'1/2'}
                                    value={user?.profile?.toSalary || ''}
                                    onChange={e => updateProfileField('toSalary', e !== '' ? parseInt(e.replace(/[^0-9]/g, '')) : undefined)}
                                />
                            </Grid>
                        }

                    </RadioGroup>
                </Grid>
            </Grid>
        </Grid>
    )

    const transferFee = (
        <Grid item xs={6}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>Transfer fee</Typography>
                </Grid>
                <Grid item xs={8}>
                    <RadioGroup
                        aria-label="salary" name="gender1"
                        value={user?.profile?.transferFee || 0}
                        onChange={e => {
                            if (e.target.value === 'custom') {
                                setCustomTransferFeeInputState(true)
                            } else {
                                setCustomTransferFeeInputState(false)
                                const newValue = e.target.value !== undefined ? +e.target.value : undefined
                                updateProfileField('transferFee', newValue)
                            }
                        }}
                    >
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={0}
                            control={<Radio color={'primary'} size={'small'}/>} label="Don't disclose"/>
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={-1} control={<Radio color={'primary'} size={'small'}/>}
                            label="Free agent"/>
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={100_000} control={<Radio color={'primary'} size={'small'}/>}
                            label="up to 100.000€"/>
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={200_000} control={<Radio color={'primary'} size={'small'}/>}
                            label="up to 200.000€"/>
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={300_000} control={<Radio color={'primary'} size={'small'}/>}
                            label="up to 300.000€"/>
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={400_000} control={<Radio color={'primary'} size={'small'}/>}
                            label="up to 400.000€"/>
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={500_000} control={<Radio color={'primary'} size={'small'}/>}
                            label="up to 500.000€"/>
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={750_000} control={<Radio color={'primary'} size={'small'}/>}
                            label="up to 750.000€"/>
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={1_000_000} control={<Radio color={'primary'} size={'small'}/>}
                            label="up to 1.000.000€"/>

                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={'custom'} control={<Radio color={'primary'} checked={customTransferFeeInputState} size={'small'}/>}
                            label="Custom"/>

                        {
                            customTransferFeeInputState &&
                            <Grid container spacing={2}>
                                <ETextField
                                    grid={'1/1'}
                                    value={profile?.transferFee || ''}
                                    onChange={e => updateProfileField('transferFee', e !== '' ? parseInt(e.replace(/[^0-9]/g, '')) : undefined)}
                                />
                            </Grid>
                        }


                    </RadioGroup>
                </Grid>
            </Grid>
        </Grid>
    )

    const miscUI = (
        <Grid
            container
            spacing={2}
            className={classes.sectionData}
        >

            <ETextField
                grid={'1/3'}
                label={t('Height (cm)')}
                value={getUserHeight(user)}
                onChange={e => {
                    let newValue
                    if (e !== '') {
                        newValue = +e
                    }

                    updateProfileField('height', newValue)
                }}
            />

            <Grid item {...getGridSizes('1/3')}>
                <FormControl
                    variant="outlined"
                    className={classes.input}
                    margin={'normal'}
                >
                    <InputLabel>{t("Preferred foot")}</InputLabel>
                    <Select
                        label={'Preferred foot'}
                        value={user?.profile?.preferredFoot || 'BOTH'}
                        className={classes.input}
                        onChange={e => updateProfileField('preferredFoot', e.target.value as string)}
                    >
                        <MenuItem value={"BOTH"}>Both</MenuItem>
                        <MenuItem value={"LEFT"}>Left</MenuItem>
                        <MenuItem value={"RIGHT"}>Right</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item {...getGridSizes('1/3')} />

            <EDateField
                grid={'1/3'}
                label={'Date of birth'}
                value={profile.dateOfBirth}
                onChange={d => updateProfileField('dateOfBirth', d as Date)}
            />

            <Grid item {...getGridSizes('1/3')}>
                <FormControl
                    style={{width: '100%'}}
                    variant="outlined"
                    margin="normal"
                >
                    <InputLabel id="gender-select-label">Gender</InputLabel>
                    <Select
                        value={profile.gender}
                        onChange={e => updateProfileField('gender', e.target.value)}
                        labelId={'gender-select-label'}
                    >
                        <MenuItem value={"MALE"}>{t('male')}</MenuItem>
                        <MenuItem value={"FEMALE"}>{t('female')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item {...getGridSizes('1/3')} />

            <ETextField
                grid={'1/3'}
                label={t('Transfermarkt value (€)')}
                value={getUserTransfermarktValue(user)}
                onChange={e => {
                    let newValue
                    if (e !== '') {
                        newValue = +e
                    }

                    updateProfileField('transfermarktValue', newValue)
                }}
            />

            <Grid item {...getGridSizes('2/3')} />

            {salaryUI}
            {transferFee}

        </Grid>
    )

    const languageAndNationalityUI = (
        <Grid container spacing={5}>

            <Grid item xs={12} sm={4}>
                <LanguageSelector
                    languages={user?.profile?.languages}
                    onRemoveLanguage={language => {
                        updateProfileField('languages', user?.profile?.languages?.filter(l => l.id !== language.id))
                    }}
                    onAddLanguage={language => {
                        updateProfileField('languages', [...(user?.profile?.languages || []), language])
                    }}
                    addTitle={'Add language'}/>
            </Grid>

            <Grid item xs={12} sm={4}>
                <CountrySelector
                    countries={user?.profile?.nationalities}
                    onRemoveCountry={handleRemoveNationality}
                    onAddCountry={handleAddNationality}
                    addTitle={'Add nationality'}/>
            </Grid>

        </Grid>
    )

    const externalUriUI = (
        <Box>
            {
                user?.profile?.externalUrls?.map((url, index) => {
                    return (
                        <Grid container spacing={5} alignItems={'center'}>
                            <ETextField
                                grid={'2/3'}
                                style={{width: 'calc(100% - 200px)'}}
                                value={url}
                                onChange={e => {
                                    const newUrls = [...user?.profile?.externalUrls!]
                                    newUrls[index] = e
                                    updateProfileField('externalUrls', newUrls)
                                }}
                            />

                            <Grid item {...getGridSizes('1/3')}>
                                <EButton
                                    variant={'contained'}
                                    color={'error'}
                                    onClick={() => {
                                        const newUrls = [...user?.profile?.externalUrls!]
                                        newUrls.splice(index, 1)
                                        updateProfileField('externalUrls', newUrls)
                                    }}
                                >
                                    Remove
                                </EButton>
                            </Grid>

                        </Grid>
                    )
                }) || []
            }

            <EButton
                color={'primary'}
                variant={'contained'}
                onClick={() => {
                    const newUrls = [...(user?.profile?.externalUrls || []), '']
                    updateProfileField('externalUrls', newUrls)
                }}
            >
                Add url
            </EButton>

        </Box>
    )

    const positionUI = (
        <Box display={'flex'} flex={1} marginTop={3} marginBottom={5}>
            {
                isRegularUser &&
                <Box style={{width: 320, height: 350}}>
                    <Position
                        selectedPrimary={[getProfileMainPosition(profile)]}
                        selectedSecondary={getProfileSecondaryPositions(profile)}
                        // classes={styles.blurred}
                        positionClickHandler={onPositionClick}/>
                </Box>
            }
        </Box>
    )

    return (
        <Box
            p={3}
            display={'flex'}
            flexDirection={'column'}
        >
            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("General")}
            </Typography>
            {generalUI}

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Quality index")}
            </Typography>
            {qualityIndexUI}

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Position")}
            </Typography>
            {positionUI}

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Contact")}
            </Typography>
            {contactUI}

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Contract")}
            </Typography>
            {contractUI}

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Misc")}
            </Typography>
            {miscUI}

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Language and nationality")}
            </Typography>
            {languageAndNationalityUI}

            <Box
                display={'flex'}
                marginTop={5}
            >

                <Typography variant={'h4'} className={classes.sectionTitle}>
                    {t("External URLS")}
                </Typography>

                <img
                    alt={'Google'}
                    src={require('../../../assets/images/google.svg').default}
                    className={styles.adminActionIcon}
                    onClick={() => {
                        window.open(`https://www.google.com/search?q=${encodeURI(googleQuery)}`, '_blank')
                    }}
                />

                <img
                    alt={'Youtube'}
                    src={require('../../../assets/images/youtube.svg').default}
                    className={styles.adminActionIcon}
                    onClick={() => {
                        window.open(`https://www.youtube.com/results?search_query=${encodeURI(youtubeQuery)}`, '_blank')
                    }}
                />

            </Box>

            {externalUriUI}

        </Box>
    )

    //endregion UI
}

export default PlayerProfile
