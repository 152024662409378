export const LANDING_COMMON_COLORS = {
    TITLE: '#343434',
    BOX_TEXT: '#414141'
}

export const DIMENSIONS = {
    LARGE: {
        TITLE_SIZE: 48,
        INFO_BOX_PADDING: 30,
        INFO_ICON_SIZE: 70,
        INFO_TITLE_SIZE: 20,
        INFO_TITLE_LINE_HEIGHT: '24px',
        INFO_MESSAGE_SIZE: 16,
        INFO_MESSAGE_LINE_HEIGHT: '27px'
    },
    SMALL: {
        TITLE_SIZE: 36,
        INFO_BOX_PADDING: 20,
        INFO_ICON_SIZE: 55,
        INFO_TITLE_SIZE: 18,
        INFO_TITLE_LINE_HEIGHT: '22px',
        INFO_MESSAGE_SIZE: 14,
        INFO_MESSAGE_LINE_HEIGHT: '24px'
    }
}