import React, {FunctionComponent} from 'react';
import styles from './ClubBadge.module.scss';
import ClubModel from "../../../models/club.model";
import {Box} from "@material-ui/core";

interface Props {
    club?: ClubModel
    clicked: () => void
    clubNameEmptyText?: string
    rowDisplay?: boolean
    style?: React.CSSProperties
}


const ClubBadge: FunctionComponent<Props> = (props: Props) => {
    return (
        <Box
            display={'flex'}
            flexDirection={props.rowDisplay ? 'row' : 'column'}
            alignItems={'center'}

            onClick={props.clicked}

            // vAlignContent={props.rowDisplay ? 'center' : 'top'}
            className={styles.component}
            style={props.style}
        >
            <img alt={'Club logo'} className={styles.logo} src={props.club?.logo || require('../../../assets/images/fake-club.svg').default}/>
            <div className={styles.clubName}
                 style={{marginLeft: (props.rowDisplay && '10px') || ''}}>{props.club?.name?.substring(0, 30) || props.clubNameEmptyText}</div>
            <div className={styles.clubLocation}>
                {props.club?.city?.name},
                {props.club?.country?.name}
            </div>
        </Box>
    )
};

export default ClubBadge
