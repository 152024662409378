export type FormFieldValidator = (value: any, formFields?: FormField[]) => string;

export interface FormField {
    name: string;
    element: 'input' | 'checkbox' | 'button' | 'none';
    placeholder?: string;
    label?: string;
    autocomplete?: string;
    type?: string;
    value?: any;
    validators?: FormFieldValidator[];
    error?: string;
    custom?: any;
}

export const validateFormFields = (formFields?: FormField[]): FormField[] => {
    const fields: FormField[] = [];

    formFields?.forEach(field => {
        let error = '';
        field.validators?.forEach(validator => {
            error = error || validator(field.value, formFields);
        });

        fields.push({
            ...field,
            error
        })
    });

    return fields;
}

export const validateForm = (form: any) => {
    let newForm: any = {};

    Object.keys(form).forEach(key => {
        newForm[key] = {
            ...form[key],
            validation: {
                ...form[key].validation
            }
        };
    });

    Object.keys(newForm).forEach(key => {
        let formElement = newForm[key];
        if (formElement.validation && formElement.validation.validators) {
            let error = '';
            formElement.validation.validators.forEach((validator: any) => {
                error = error || validator(formElement.value);
            });
            formElement.validation.error = error;
        }
    });

    return newForm;
};

export const isFormValid = (form: any) => {
    let error: any = '';
    Object.keys(form).forEach(key => {
        let formElement = form[key];
        if (formElement.validation) {
            error = error || formElement.validation.error;
        }
    });
    return !error;
};


export const isFormFieldValid = (formFields?: FormField[]): boolean => {
    let valid = true;
    formFields?.forEach(field => {
        valid = valid && !field.error;
    });
    return valid;
}

export const noValueValidator = (value: string): string => {
    return !value ? 'no_value' : '';
};

export const emailValidator = (email: string): string => {
    const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !regex.test(email) ? 'invalid_email' : '';
};

export const lengthValidator = (length: number) =>
    (value: string) => {
        return !value || value.length < length ? 'too_short' : '';
    };

export const fieldConfirmValidator = (otherFieldName: string) =>
    (value: string, formFields?: FormField[]) => {
        const otherFieldValue: any = formFields?.find(field => field.name == otherFieldName)?.value;
        return otherFieldValue !== value ? 'fields_not_same' : '';
    };

export const checkedValidator = (checked: boolean) => {
    return !checked ? 'not_checked' : '';
}

export const emptyNumberValidator = (value: number): string => {
    return value === null || value === undefined ? 'empty' : '';
};
