import React, {useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";

const PreferredFootFilter = () => {

    //region State

    const [paramValue] = useUrlParam({param: 'preferredFoot', dataType: 'string', defaultValue: ''})
    const [expanded, setExpanded] = useState(paramValue !== '')
    const history = useHistory()

    //endregion State

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="preferredFoot-content"
                id="preferredFoot-header"
            >
                <Typography>Preferred foot {paramValue !== '' && `(${paramValue?.toLowerCase()})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="preferredFoot-content"
                        name="verification_foot"
                        value={paramValue}
                        onChange={e => {
                            setMultipleParams([
                                {name: 'preferredFoot', value: e.target.value || '', dataType: 'string'},
                                {name: 'listPage', value: 1, dataType: 'numeric'}
                            ], history)
                        }}
                    >
                        <FormControlLabel value={"LEFT"} control={<Radio/>} label="Left"/>
                        <FormControlLabel value={"RIGHT"} control={<Radio/>} label="Right"/>
                        <FormControlLabel value={"BOTH"} control={<Radio/>}
                                          label="Both"/>
                    </RadioGroup>
                </FormControl>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default PreferredFootFilter