import React from 'react';
import styles from './CurrentClub.module.scss';
import clsx from 'clsx';
import UserModel from "../../../../models/user.model";
import {useTranslation} from "react-i18next";
import {
    getCurrentClub,
    getCurrentClubLocation,
    getCurrentClubLogo,
    getCurrentClubName,
    getCurrentClubTeams,
    getCurrentContractFormatted
} from "../../../../util/profile.util";
import {Box} from "@material-ui/core";

interface Props {
    player?: UserModel
}

const CurrentClub = (props: Props) => {

    const t = useTranslation().t;

    const hasCurrentClub = !!getCurrentClub(props.player) && !props.player?.profile?.skipCurrentClub;

    return (
        <Box className={styles.container}>

            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                className={styles.currentClub}
            >
                <p>{t("current_club")}</p>
                {/*<p className={styles.edit}>Edit</p>*/}
            </Box>

            <Box className={clsx(styles.greyBox, styles.currentClubInfo)}
                 justifyContent={hasCurrentClub ? 'flex-start' : 'center'}>
                <div className={styles.clubBadge}>
                    <img alt={''} src={require('../../../../assets/images/club-logo-hexagon.svg').default} className={styles.clubImgHolder}/>
                    <img alt={'Club logo'}
                         src={hasCurrentClub ? getCurrentClubLogo(props.player,  require('../../../../assets/images/fake-club.svg').default) : require('../../../../assets/images/fake-club.svg').default}
                         className={styles.clubImg}/>
                </div>
                <Box className={styles.clubName}>
                    <p className={styles.valueLarge}>{hasCurrentClub ? getCurrentClubName(props.player) : t('no_current_club')}</p>
                    {hasCurrentClub && <p className={styles.title}>{getCurrentClubLocation(props.player, '-')}</p>}
                    {hasCurrentClub && <p className={styles.title}>{getCurrentContractFormatted(t, props.player)}</p>}
                </Box>

                {
                    hasCurrentClub ? getCurrentClubTeams(props.player).map(team => {
                        return (
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    className={styles.teamInfo}
                                >
                                    <img alt={'Team'} src={require('../../../../assets/images/player-team.svg').default} className={styles.teamIcon}/>
                                    <Box className={styles.teamInfoValues}>
                                        {/*<p className={styles.value}>{lcl("senior_team_men")}</p>*/}
                                        <p className={styles.value}>{t(team.teamType?.name || '-')}</p>
                                        <p className={styles.title}>{t("team")}</p>
                                    </Box>
                                </Box>

                                <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    className={styles.teamInfo}
                                >
                                    <img alt={'Team level'} src={require('../../../../assets/images/player-team-level.svg').default}
                                         className={styles.teamIcon}/>
                                    <Box className={styles.teamInfoValues}>
                                        <p className={styles.value}>{team.level?.id}</p>
                                        <p className={styles.title}>{t("level", {level: ''})}</p>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    }) : null
                }
            </Box>
        </Box>
    );
}

export default CurrentClub;
