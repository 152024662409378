import gql from "graphql-tag";
import {
    ALBUM,
    CITY_FULL,
    CITY_LOCATION,
    CITY_SIMPLE,
    CLUB_FULL,
    CLUB_FULL_PRIVATE,
    CLUB_PUBLIC,
    CLUB_SIMPLE,
    COUNTRY,
    MEDIA_SIMPLE,
    PAGINATION,
    PAYMENT,
    USER_FULL,
    USER_PUBLIC
} from "./queryFragments";

export const GET_CLUBS_QUERY_WITH_LOCATION = gql`
    query clubs($latitude: Float, $longitude: Float, $name: String, $paging: PageableInput) {
        clubs(latitude: $latitude, longitude: $longitude, name: $name, paging: $paging) {
            content {
                ${CLUB_SIMPLE}
            }
            ${PAGINATION}
        }
    }    
`;

export const UPDATE_CLUB_INFO = gql`
    mutation club($club: ClubInput!) {
        club(club: $club) {
            ${CLUB_FULL_PRIVATE}
        }
    }
`;

export const CREATE_ALBUM = gql`
    mutation album($album: MediaAlbumInput!) {
        album(album: $album) {
            ${ALBUM}
        }
    }
`;

export const DELETE_ALBUM = gql`
    mutation deleteAlbum($mediaAlbumId: Long!) {
        deleteAlbum(mediaAlbumId: $mediaAlbumId) {
            id
        }
    }
`;

export const CREATE_MEDIA = gql`
    mutation media($media: MediaInput!) {
        media(media: $media) {
            ${MEDIA_SIMPLE}
        }
    }
`;

export const DELETE_MEDIA = gql`
    mutation deleteMedia($id: Long!) {
        deleteMedia(id: $id) {
            album {
                id
            }
            id
        }
    }
`;


export const GET_CLUB_QUERY = gql`
    query club($id: Long!) {
        club(id: $id) {
           ${CLUB_FULL}
        }
    }
`;

export const GET_CLUB_PUBLIC_QUERY = gql`
    query club($id: Long!) {
        club(id: $id) {
           ${CLUB_PUBLIC}
        }
    }
`;

export const GET_CLUB_ADMIN = gql`
    query club($id: Long!) {
        club(id: $id) {
           ${CLUB_FULL_PRIVATE}
        }
    }
`;

export const GET_CLUB_REQUEST_ADMIN = gql`
    query clubRequest($clubRequestId: Long!) {
        clubRequest(clubRequestId: $clubRequestId) {
            id
            name
            country {
              ${COUNTRY}
            }
            city {
              ${CITY_FULL}
            }
            location {
              ${CITY_LOCATION}
            }
            createdBy {
                ${USER_FULL}
            }
            updatedAt
            createdAt
        }
    }
`;

export const GET_CLUBS = gql`
    query clubs($name: String, $paging: PageableInput) {
        clubs(name: $name, paging: $paging) {
            content{ 
                ${CLUB_FULL}
            }
            ${PAGINATION}
        }
    }
`;

export const GET_CLUBS_ADMIN = gql`
    query adminClubs($paging: PageableInput, $filter: ClubSpecificationInput) {
        adminClubs(paging: $paging, filter: $filter) {
            content{ 
                ${CLUB_FULL_PRIVATE}
            }
            ${PAGINATION}
        }
    }
`;

export const GET_CLUB_REQUESTS = gql`
    query clubRequests($filter: ClubRequestSpecificationInput, $paging: PageableInput) {
        clubRequests(filter: $filter, paging: $paging) {
            content{ 
                id
                name
                deleted
                createdAt
                createdBy {
                    id
                    email
                    profile {
                        country {
                            ${COUNTRY}
                        }       
                        city {
                            ${CITY_SIMPLE}
                        }
                        firstName
                        lastName
                        contactInfo {
                            phone
                            email
                        }
                    }
                }
                country {
                    ${COUNTRY}
                }
                city {
                    ${CITY_SIMPLE}
                }
            }
            ${PAGINATION}
        }
    }
`;

export const GET_CLUB_REQUESTS_ADMIN = gql`
    query clubRequests($filter: ClubRequestSpecificationInput, $paging: PageableInput) {
        clubRequests(filter: $filter, paging: $paging) {
            content{ 
                id
                name
                deleted
                createdAt
                createdBy {
                    id
                    email
                    profile {
                        country {
                            ${COUNTRY}
                        }       
                        city {
                            ${CITY_SIMPLE}
                        }
                        firstName
                        lastName
                        contactInfo {
                            phone
                            email
                        }
                    }
                    userType {
                        id
                        name
                    }
                }
                country {
                    ${COUNTRY}
                }
                city {
                    ${CITY_SIMPLE}
                }
                payments {
                    ${PAYMENT}
                }
            }
            ${PAGINATION}
        }
    }
`;


export const RESOLVE_CLUB_REQUEST = gql`
    mutation resolveClubRequest($clubId: Long!, $clubRequestId: Long!) {
        resolveClubRequest(clubId: $clubId, clubRequestId: $clubRequestId) {
            id   
        }
    }
`;

export const DELETE_CLUB_REQUEST = gql`
    mutation deleteClubRequest($id: Long!) {
        deleteClubRequest(id: $id) {
            id   
        }
    }
`;

export const CREATE_CLUB = gql`
    mutation club($club: ClubInput!) {
        club(club: $club) {
            ${CLUB_FULL}
        }
    }
`;

export const CREATE_CLUB_REQUEST = gql`
    mutation clubRequest($clubRequest: ClubRequestInput) {
        clubRequest(clubRequest: $clubRequest) {
            id
            name
            location {
                ${CITY_LOCATION}
            }
        }
    }
`;

export const UPGRADE_SUBSCRIPTION_CLUB = gql`
    mutation upgrade($payment: PaymentInput!) {
        upgrade(payment: $payment){
            ${PAYMENT}
        }
    }    
`;

export const UPGRADE_SUBSCRIPTION_ADMIN = gql`
    mutation updatePayment($payment: PaymentInput!) {
        updatePayment(payment: $payment){
            ${PAYMENT}
        }
    }    
`;

export const REQUEST_HOF = gql`
    mutation requestHOF($club: String, $request: Boolean!, $clubId: Long!, $email: String, $message: String, $name: String, $phone: String) {
        requestHOF(club: $club, request: $request, clubId: $clubId, email: $email, message: $message, name: $name, phone: $phone) {
            ${PAYMENT}
        }
    }    
`;

export const ADD_CLUB_ADMIN = gql`
    mutation addClubAdmin($clubId: Long!, $userId: Long!) {
        addClubAdmin(clubId: $clubId, userId: $userId) {
           id
           approved
           club {
                id
           }
           club_request {
                id
           }
           user {
                ${USER_PUBLIC}
           }
        }
    }
`;

export const APPROVE_CLUB_ADMIN = gql`
    mutation approveClubAdmin($clubAdminId: Long!) {
        approveClubAdmin(clubAdminId: $clubAdminId) {
           id
           approved
           club {
                id
           }
           club_request {
                id
           }
            user {
                id
           }
        }
    }
`;

export const REMOVE_CLUB_ADMIN = gql`
    mutation removeClubAdmin($clubAdminId: Long!) {
        removeClubAdmin(clubAdminId: $clubAdminId) {
           id
            user {
                id
           }
        }
    }
`;
