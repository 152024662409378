import apollo from "./api/apollo";
import {
    GET_APPLICANTS_FILTER,
    GET_APPLICANTS_NUMBER,
    GET_APPLICANTS_VIA_OPPORTUNITY,
    GET_SINGLE_APPLICATION,
    INVITE_APPLICANT,
    REJECT_APPLICANT,
    REMOVE_APPLICANT_FROM_SHORTLIST,
    REMOVE_APPLICATION_FOR_OPPORTUNITY,
    SHORTLIST_APPLICANT,
    UPDATE_CONTACTED_STATE,
    UPDATE_RELEVANCE
} from "./api/opportunityQueries";
import OpportunityApplicationModel, {
    ApplicationRelevance,
    ApplicationStatus
} from "../models/opportunityApplication.model";
import Job from "../models/job.model";
import {ApplicantFeedModel} from "../models/applicantFeed.model";
import {GET_ADMIN_APPLICANT_FEED} from "./api/algorithmsQueries";
import MixpanelService from "./analytics/mixpanel.service";
import {EventName} from "./analytics/eventName";
import {PeopleProperty} from "./analytics/peopleProperty";
import {EventProperty} from "./analytics/eventProperty";
import Page from "../models/page.model";

export default class ApplicationsService {

    static covertToWebFormat = (application: OpportunityApplicationModel): OpportunityApplicationModel => {
        if (!application) return {};

        return {
            ...application,
            createdAt: application.createdAt && new Date(application.createdAt),
            createdBy: {
                ...application.createdBy,
                subscription: {
                    ...application.createdBy?.subscription,
                    expirationDate: application.createdBy?.subscription?.expirationDate && new Date(application.createdBy?.subscription?.expirationDate),
                    // expirationDate: addMonths(new Date(), 4),
                    // type: ((application.id || 0) % 5 === 0) ? UserSubscriptionType.PREMIUM : UserSubscriptionType.BASIC
                }
            }
        }
    }

    static convertToApiFormat = (application: OpportunityApplicationModel) => {
        return application;
    }

    static async fetchApplicants(opportunityId: number, status: string, page: number, size: number): Promise<Page<OpportunityApplicationModel> | undefined> {
        try {
            const response = await apollo.query({
                query: GET_APPLICANTS_FILTER,
                variables: {
                    opportunityId: opportunityId,
                    paging: {
                        page: page,
                        size: size
                    },
                    status: status
                },
                errorPolicy: 'ignore'
            });

            let applications = response?.data?.applications?.content || [];
            applications = applications
                .filter((applicant: OpportunityApplicationModel) => !applicant.deleted)
                .map(ApplicationsService.covertToWebFormat);

            return {
                ...response.data.applications,
                content: applications
            }
        } catch (e) {
            console.error(e);
            return undefined
        }
    }

    static async fetchApplicantsViaOpportunity(opportunityId: number, page: number, size: number) {
        try {
            const response = await apollo.query({
                query: GET_APPLICANTS_VIA_OPPORTUNITY,
                variables: {
                    id: opportunityId,
                    paging: {
                        page: page,
                        size: size
                    },
                },
                errorPolicy: 'ignore'
            });

            const applications = response?.data?.opportunity?.applicants?.content || [];
            return applications
                .filter((applicant: OpportunityApplicationModel) => !applicant.deleted)
                .map(ApplicationsService.covertToWebFormat);
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    static async fetchApplicantNumber(opportunityId: number) {
        try {
            const response = await apollo.query({
                query: GET_APPLICANTS_NUMBER,
                variables: {
                    opportunityId: opportunityId,
                    paging: {
                        page: 0,
                        size: 1000
                    }
                },
            });

            return response?.data?.applications?.totalElements;
        } catch (e) {
            console.error(e);
            return 0;
        }
    }

    static getAllApplicantsAdminFeed = async (opportunity: Job, showDisqualified: boolean, paging: any, userIds: number[]): Promise<ApplicantFeedModel> => {
        const response = await apollo.query({
            query: GET_ADMIN_APPLICANT_FEED,
            variables: {
                areDisqualifiedFiltered: !showDisqualified,
                opportunityId: opportunity.id,
                paging: paging,
                userIds: userIds
            }
        });

        return response?.data?.adminOpportunityApplicationFeed;
    };

    static async fetchTopPicks(opportunityId: number, page: number, size: number) {
        return await ApplicationsService.fetchApplicants(opportunityId, 'SHORTLISTED', page, size);
    }

    static async fetchInvited(opportunityId: number, page: number, size: number) {
        return await ApplicationsService.fetchApplicants(opportunityId, 'INVITED', page, size);
    }

    static async fetchOther(opportunityId: number, page: number, size: number) {
        return await ApplicationsService.fetchApplicants(opportunityId, 'CREATED', page, size);
    }

    static async fetchRejected(opportunityId: number, page: number, size: number) {
        return await ApplicationsService.fetchApplicants(opportunityId, 'REJECTED', page, size);
    }

    static async removeApplicantFromTopPicks(application: OpportunityApplicationModel | undefined): Promise<OpportunityApplicationModel | undefined> {
        try {
            const response = await apollo.query({
                query: REMOVE_APPLICANT_FROM_SHORTLIST,
                variables: {
                    opportunityApplicationId: application?.id
                },
            });

            return this.covertToWebFormat(response?.data?.removeApplicantFromShortList);
        } catch (e) {
            console.error(e);
        }
    };

    static async moveApplicantToTopPicks(application: OpportunityApplicationModel | undefined): Promise<OpportunityApplicationModel | undefined> {
        try {
            const response = await apollo.query({
                query: SHORTLIST_APPLICANT,
                variables: {
                    opportunityApplicationId: application?.id
                },
            });

            return this.covertToWebFormat(response?.data?.shortlistApplicant);
        } catch (e) {
            console.error(e);
        }
    };

    static async moveApplicantToRejected(application: OpportunityApplicationModel): Promise<OpportunityApplicationModel | undefined> {
        try {
            if (application.status === ApplicationStatus.SHORTLISTED) {
                await this.removeApplicantFromTopPicks(application);
            }

            const response = await apollo.query({
                query: REJECT_APPLICANT,
                variables: {
                    opportunityApplicationId: application?.id
                },
            });

            try {
                const user = application.createdBy;
                MixpanelService.track(EventName.APPLICANT_REJECTED, {
                    [EventProperty.APPLICANT_USER_ID]: user?.id
                })
                MixpanelService.incrementProperty(PeopleProperty.TOTAL_APPLICANTS_REJECTED);
                MixpanelService.setUserProperties({
                    [PeopleProperty.LAST_APPLICANT_REJECT]: MixpanelService.getCurrentTimeIso()
                });
            } catch (e) {
                console.log(e);
            }

            return this.covertToWebFormat(response?.data?.rejectApplicant);
        } catch (e) {
            console.error(e);
        }
    };

    static async moveApplicantToInvited(application: OpportunityApplicationModel): Promise<OpportunityApplicationModel | undefined> {
        try {
            if (application.status === 'CREATED') {
                await apollo.query({
                    query: SHORTLIST_APPLICANT,
                    variables: {
                        opportunityApplicationId: application.id
                    },
                });
            }

            const response = await apollo.query({
                query: INVITE_APPLICANT,
                variables: {
                    opportunityApplicationId: application.id
                },
            });

            try {
                const user = application.createdBy;
                MixpanelService.track(EventName.APPLICANT_INVITED, {
                    [EventProperty.APPLICANT_USER_ID]: user?.id
                });
                MixpanelService.incrementProperty(PeopleProperty.TOTAL_APPLICANTS_INVITED);
                MixpanelService.setUserProperties({
                    [PeopleProperty.LAST_APPLICANT_INVITE]: MixpanelService.getCurrentTimeIso(),
                });
            } catch (e) {
                console.log(e);
            }

            return this.covertToWebFormat(response?.data?.inviteApplicant);
        } catch (e) {
            console.error(e);
        }
    };

    static async setApplicantRelevance(application: OpportunityApplicationModel, relevance: ApplicationRelevance): Promise<OpportunityApplicationModel | undefined> {
        try {
            const response = await apollo.mutate({
                mutation: UPDATE_RELEVANCE,
                variables: {
                    opportunityApplicationId: application.id,
                    relevance: relevance
                },
            });

            return this.covertToWebFormat(response?.data?.relevance);
        } catch (e) {
            console.error(e);
        }
    };

    static async changeApplicantContactedState(application: OpportunityApplicationModel, contacted: boolean): Promise<OpportunityApplicationModel | undefined> {
        try {
            const response = await apollo.mutate({
                mutation: UPDATE_CONTACTED_STATE,
                variables: {
                    opportunityApplicationId: application.id,
                    contacted: contacted
                },
            });

            return this.covertToWebFormat(response?.data?.contacted);
        } catch (e) {
            console.error(e);
        }
    };

    static async getApplication(id: number): Promise<OpportunityApplicationModel | undefined> {
        try {
            const response = await apollo.query({
                query: GET_SINGLE_APPLICATION,
                variables: {
                    id
                },
            });

            return this.covertToWebFormat(response?.data?.application);
        } catch (e) {
            console.error(e);
        }
    };

    static async fullyDeleteApplication(application: OpportunityApplicationModel | undefined): Promise<OpportunityApplicationModel | undefined> {
        try {
            const response = await apollo.mutate({
                mutation: REMOVE_APPLICATION_FOR_OPPORTUNITY,
                variables: {
                    id: application?.id
                },
            });

            try {
                const user = application?.createdBy;
                MixpanelService.track(EventName.APPLICANT_DELETED, {
                    [EventProperty.APPLICANT_USER_ID]: user?.id
                })
                MixpanelService.incrementProperty(PeopleProperty.TOTAL_APPLICANTS_DELETED);
                MixpanelService.setUserProperties({
                    [PeopleProperty.LAST_APPLICANT_DELETE]: MixpanelService.getCurrentTimeIso()
                });
            } catch (e) {
                console.log(e);
            }

            return this.covertToWebFormat(response?.data?.removeApplicationForOpportunity);
        } catch (e) {
            console.error(e);
        }
    };
}



