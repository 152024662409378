import apollo from "./api/apollo";
import {
    CREATE_OPPORTUNITY_QUERY,
    GET_ADMIN_OPPORTUNITIES,
    GET_OPPORTUNITIES,
    GET_OPPORTUNITIES_FOR_CLUB_REQUEST,
    GET_OPPORTUNITIES_TOTAL,
    GET_OPPORTUNITY,
    GET_OPPORTUNITY_NOTIFIABLE_USERS,
    GET_TOTAL_OPPORTUNITIES_FOR_CLUB_REQUEST
} from "./api/opportunityQueries";
import Job from "../models/job.model";
import ClubModel from "../models/club.model";
import MixpanelService from "./analytics/mixpanel.service";
import UserModel from "../models/user.model";
import OpportunityFeedModel from "../models/opportunityFeed.model";
import {LocationType} from "../models/locationType.enum";
import {GET_ADMIN_OPPORTUNITY_FEED} from "./api/algorithmsQueries";
import {getPositionsForSection, getSectionForPositions} from "../util/position.util";
import {generateTeam} from "../util/team.util";
import {EventProperty} from "./analytics/eventProperty";
import {EventName} from "./analytics/eventName";
import {PeopleProperty} from "./analytics/peopleProperty";
import Page from "../models/page.model";
import {getAnalyticsGender, getAnalyticsPositions} from "../util/analytics.util";
import {convertGeocodeResultsToGoogleGeolocationInput} from "../util/util";
import {getOpportunitySalaryLocalized, getOpportunityTransferFeeLocalized} from "../util/opportunity.util";

export default class OpportunityService {

    static convertToWebOpportunity(apiOpportunity: any): Job {
        const section = getSectionForPositions(apiOpportunity.positions);

        let club = apiOpportunity.club;
        if (!club && apiOpportunity.clubRequest) {
            club = {
                ...apiOpportunity.clubRequest,
                isRequest: true
            }
        }

        let locationType = null;
        if (apiOpportunity.location) {
            if (apiOpportunity.location?.location?.name || apiOpportunity.location?.city?.name) {
                locationType = LocationType.Local;
            } else if (apiOpportunity.location?.country?.length) {
                locationType = LocationType.Country;
            } else {
                locationType = LocationType.International;
            }
        }

        return {
            ...apiOpportunity,
            teams: apiOpportunity.teams || [],
            section: section || null,
            positionMode: section ? 'section' : 'positions',
            positions: apiOpportunity.positions || [],
            fromLevel: apiOpportunity.fromLevel?.value || 0,
            toLevel: apiOpportunity.toLevel?.value || 15,
            nationalities: apiOpportunity.nationalities || [],
            languages: apiOpportunity.languages || [],
            club: club,
            locationType: locationType,
        };
    }

    static convertToApiOpportunity(webOpportunity: Job): any {
        let positions;
        if (webOpportunity.positionMode === 'positions') {
            positions = webOpportunity.positions;
        } else {
            const section = webOpportunity.section;
            positions = getPositionsForSection(section);
        }

        // let teams: Team[] = [...(webOpportunity.teams || [])].map(team => {
        //     return {
        //         id: team.id
        //     }
        // });
        let teams = webOpportunity.teams;

        if (!teams?.length) {
            teams = [generateTeam(webOpportunity.gender || 'MALE', 5, 1)]
        }

        let country = undefined;
        // let city = null;
        let radius = 0;
        let googleGeolocation = undefined;
        let innerLocation = undefined;
        if (webOpportunity.location) {
            if (webOpportunity.locationType === LocationType.Local) {
                if (webOpportunity.location.googleGeolocation) {
                    googleGeolocation = convertGeocodeResultsToGoogleGeolocationInput(webOpportunity.location.googleGeolocation);
                } else {
                    innerLocation = webOpportunity.location.location;
                }
            }

            if (webOpportunity.locationType === LocationType.Country) {
                country = webOpportunity?.location?.country
            }

            radius = webOpportunity.location.radius || 0;
        }

        return {
            id: webOpportunity.id,
            deleted: webOpportunity.deleted,
            gender: webOpportunity.gender,
            fromLevel: {
                id: webOpportunity.fromLevel,
                value: webOpportunity.fromLevel,
            },
            toLevel: {
                id: webOpportunity.toLevel,
                value: webOpportunity.toLevel,
            },
            fromAge: webOpportunity.fromAge,
            toAge: webOpportunity.toAge,
            teams: teams,
            // date: "2987-06-05T00:00:00Z",
            salary: 'FREE',
            positions: positions,
            anonymous: webOpportunity.anonymous,
            preferredFoot: webOpportunity.preferredFoot,
            minHeight: webOpportunity.minHeight,
            skillNeeded: webOpportunity.skillNeeded,
            transferPeriod: webOpportunity.transferPeriod,
            location: {
                id: webOpportunity.location?.id,
                radius: radius,
                country: country?.map(c => {
                    return {
                        id: c.id
                    }
                }),
                location: innerLocation,
                googleGeolocation: googleGeolocation || undefined
            },
            club: webOpportunity.club && !webOpportunity.club.isRequest && {
                id: webOpportunity.club.id
            } || undefined,
            clubRequest: webOpportunity.club && webOpportunity.club.isRequest && {
                id: webOpportunity.club.id
            } || undefined,
            opportunityStatus: webOpportunity.opportunityStatus,
            nationalities: webOpportunity?.nationalities?.map(({id, name}) => {
                return {id, name}
            }),
            languages: webOpportunity.languages,
            premium: webOpportunity.premium,
            fromSalary: webOpportunity.fromSalary,
            toSalary: webOpportunity.toSalary,
            requirements: webOpportunity.requirements,
            minInstatIndex: webOpportunity.minInstatIndex,
            minPhIndex: webOpportunity.minPhIndex,
            minMinutesPercentage: webOpportunity.minMinutesPercentage,
            minGoals: webOpportunity.minGoals,
            minAssists: webOpportunity.minAssists,
            transferFee: webOpportunity.transferFee,
            titleOverride: webOpportunity.titleOverride,
            jobCreatedByAgent: webOpportunity.jobCreatedByAgent,
            logoOverride: webOpportunity.logoOverride,
            agent: webOpportunity.agent ? {id: webOpportunity.agent.id} : undefined,
            minTmValue: webOpportunity.minTmValue || undefined
        };
    }

    static getOpportunities = async (club: ClubModel) => {
        let response;
        if (club.isRequest) {
            response = await apollo.query({
                query: GET_OPPORTUNITIES_FOR_CLUB_REQUEST,
                variables: {
                    clubRequestId: club.id
                }
            });

            return {
                ...response.data.clubRequest.opportunities,
                content: response.data.clubRequest.opportunities.content.map(OpportunityService.convertToWebOpportunity)
            };
        } else {
            response = await apollo.query({
                query: GET_OPPORTUNITIES,
                variables: {
                    id: club.id
                }
            });

            return {
                ...response.data.club.opportunities,
                content: response.data.club.opportunities.content.map(OpportunityService.convertToWebOpportunity)
            };
        }

    };

    static getNumberOfCreatedOpportunities = async (club: ClubModel) => {
        let response;
        if (club.isRequest) {
            response = await apollo.query({
                query: GET_TOTAL_OPPORTUNITIES_FOR_CLUB_REQUEST,
                variables: {
                    clubRequestId: club.id
                }
            });

            return response.data.clubRequest.opportunities.totalElements;
        } else {
            response = await apollo.query({
                query: GET_OPPORTUNITIES_TOTAL,
                variables: {
                    id: club.id
                }
            });

            return response.data.club.opportunities.totalElements;
        }

    };

    static getAllJobsAdmin = async (paging: any, filters: any): Promise<Page<Job>> => {
        let response;
        response = await apollo.query({
            query: GET_ADMIN_OPPORTUNITIES,
            variables: {
                paging: paging,
                filter: filters
            }
        });

        return {
            ...response.data.opportunities,
            content: response.data.opportunities.content.map(OpportunityService.convertToWebOpportunity)
        };
    };

    static async fetchNotifiableUsers(opportunityId: number, page: number, size: number): Promise<Page<UserModel> | undefined> {
        try {
            const response = await apollo.query({
                query: GET_OPPORTUNITY_NOTIFIABLE_USERS,
                variables: {
                    opportunityId: opportunityId,
                    paging: {
                        page: page,
                        size: size
                    },
                    // userIds: []
                },
                errorPolicy: 'ignore'
            });

            return response?.data?.opportunityNotifiableUsers
        } catch (e) {
            console.error(e);
        }
    }

    static getAllOpportunitiesAdminFeed = async (user: UserModel, opportunityIds: number[], showDisqualified: boolean, paging: any): Promise<OpportunityFeedModel> => {
        let response;
        response = await apollo.query({
            query: GET_ADMIN_OPPORTUNITY_FEED,
            variables: {
                areDisqualifiedFiltered: !showDisqualified,
                opportunityIds: opportunityIds,
                userId: user.id,
                paging: paging,
            }
        });

        const entriesMapped = response.data.adminOpportunityFeed.entries?.map((entry: any) => {
            return {
                ...entry,
                opportunity: OpportunityService.convertToWebOpportunity(entry.opportunity)
            }
        })

        return {
            ...response.data.adminOpportunityFeed,
            entries: entriesMapped
        };
    };

    static getOpportunity = async (opportunityId: number) => {
        const response = await apollo.query({
            query: GET_OPPORTUNITY,
            variables: {
                id: opportunityId
            }
        });

        return OpportunityService.convertToWebOpportunity(response.data.opportunity);
    };


    static createJobMixpanelEventData = (job: Job) => {
        return {
            [EventProperty.OPPORTUNITY_POSITION]: getAnalyticsPositions(job),
            [EventProperty.OPPORTUNITY_GENDER]: getAnalyticsGender(job),
            [EventProperty.OPPORTUNITY_LEVEL_MIN]: job.fromLevel,
            [EventProperty.OPPORTUNITY_LEVEL_MAX]: job.toLevel,
            [EventProperty.AGE_MIN]: job.fromAge,
            [EventProperty.AGE_MAX]: job.toAge,
            [EventProperty.TEAM]: (job.teams || []).map(t => t.teamType?.name).join(', '),
            // [EventProperty.SALARY]: opportunity?.salary?.toLowerCase().replace("_", " "),
            [EventProperty.ANONYMOUS]: job.anonymous ? 'yes' : 'no',
            [EventProperty.NATIONALITY]: job.nationalities?.map(nationality => nationality.name).join(', '),
            [EventProperty.LANGUAGE]: job.languages?.map(language => language.name).join(', '),
            [EventProperty.PREFERRED_FOOT]: job.preferredFoot?.toLowerCase() || 'indifferent',
            [EventProperty.MIN_HEIGHT]: job.minHeight,
            [EventProperty.TRANSFER_PERIOD]: job.transferPeriod && job.transferPeriod.period && `${job.transferPeriod.period.toLowerCase()} ${job.transferPeriod.year}`,
            [EventProperty.SKILL_VIDEO_REQUIRED]: job.skillNeeded ? 'yes' : 'no',
            [EventProperty.CLUB_ID]: job.club?.id,
            [EventProperty.CLUB_NAME]: job.club?.name,
            [EventProperty.CLUB_IS_REQUEST]: job.club?.isRequest || false,
            [EventProperty.OPPORTUNITY_CITY]: job.location?.city?.name,
            [EventProperty.OPPORTUNITY_COUNTRY]: job.location?.country?.map(c => c.name),
            [EventProperty.RADIUS]: job.location?.radius,
            [EventProperty.TEAM_LEVEL]: job.teams?.[0]?.level?.value,
            [EventProperty.TYPE]: job.jobType || 'job',
            [EventProperty.MIN_GOALS]: job.minGoals || 0,
            [EventProperty.MIN_ASSISTS]: job.minAssists || 0,
            [EventProperty.MIN_MINUTES_PERCENTAGE]: job.minMinutesPercentage || 0,
            [EventProperty.TRANSFER_FEE]: getOpportunityTransferFeeLocalized(job),
            [EventProperty.SALARY]: getOpportunitySalaryLocalized(job),
            [EventProperty.MUST_BE_AVAILABLE_FOR_TRIAL]: !!job.requirements?.find(r => r.id === 1),
            [EventProperty.CLUB_OPEN_FOR_LOAN]: !!job.requirements?.find(r => r.id === 2),
            [EventProperty.PH_INDEX]: job.minPhIndex || 0,
            [EventProperty.INSTAT_INDEX]: job.minInstatIndex || 0
        }
    }

    static async createOpportunity(opportunity: Job) {
        const response = await apollo.mutate({
            mutation: CREATE_OPPORTUNITY_QUERY,
            variables: {
                opportunity: OpportunityService.convertToApiOpportunity(opportunity)
            }
        });

        try {
            MixpanelService.track(EventName.OPPORTUNITY_CREATED, OpportunityService.createJobMixpanelEventData(opportunity));
            MixpanelService.incrementProperty(PeopleProperty.TOTAL_OPPORTUNITIES_CREATED);
            MixpanelService.setUserProperties({
                [PeopleProperty.LAST_OPPORTUNITY_CREATED]: MixpanelService.getCurrentTimeIso(),
                [PeopleProperty.LAST_ACTIVITY]: MixpanelService.getCurrentTimeIso()
            });
        } catch (e) {
            console.log(e);
        }

        return await this.getOpportunity(response.data.createOpportunity.id);
    }

    static async updateOpportunity(opportunity: Job): Promise<Job> {
        const response = await apollo.mutate({
            mutation: CREATE_OPPORTUNITY_QUERY,
            variables: {
                opportunity: OpportunityService.convertToApiOpportunity(opportunity),
            }
        });

        const updatedOpportunity = this.convertToWebOpportunity(response.data.createOpportunity);
        updatedOpportunity.createdAt = opportunity.createdAt;

        return updatedOpportunity;
    }
}
