import React, {useEffect, useState} from 'react'
import {Box, Card, Grid, Slider, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from "react-i18next";
import Job from "../../../../models/job.model";
import {LocationType} from "../../../../models/locationType.enum";
import _ from "lodash";
import {
    addCountries,
    eeaChCountryIds,
    euCountryIds,
    getCountriesByIds,
    removeCountries,
    worldIds
} from "../../../../services/countries";
import GooglePlacesAutocomplete, {geocodeByPlaceId} from "react-google-places-autocomplete";
import {getOpportunityLocationGeo} from "../../../../util/opportunity.util";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RoomIcon from "@material-ui/icons/Room";
import GoogleMapReact from "google-map-react";
import {mapStyleGrey} from "../../../../util/map.style";
import FlagIcon from "@material-ui/icons/Flag";
import CountrySelector from "../../../common/CountrySelector.component";
import Chart from "react-google-charts";
import PublicIcon from "@material-ui/icons/Public";

interface Props {
    job?: Job,
    setJob: (job: Job) => void,
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
    input: {
        width: '100%'
    },
    timeline: {
        // transform: "rotate(90deg)",
        // width: 100,
        height: '100%'
    },
    timelineContentContainer: {
        // textAlign: "left",
        flex: 1
    },
    timelineContent: {
        // display: "inline-block",
        // transform: "rotate(-90deg)",
        // textAlign: "center",
        minWidth: 100
    },
    timelineIcon: {
        // transform: "rotate(-90deg)",
        width: 20,
        height: 20
    },
    team: {
        background: '#FAFAFA',
        borderRadius: 16,
        marginTop: 5,
        marginBottom: 5,
        marginRight: 10,
        border: '2px solid transparent',
        cursor: 'pointer',
        padding: 10,
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.57)'
    },
    teamSelected: {
        background: theme.palette.primary.main,
        color: 'white'
    },
    slider: {
        marginLeft: 2,
        width: '100%',
        maxWidth: '100%'
    },
    sliderMark: {
        width: 24,
        textAlign: 'center',
        fontSize: 12
    },
    sliderMarkLeft: {
        marginRight: 20
    },
    sliderMarkRight: {
        marginLeft: 20
    },
    sliderTop: {
        width: '100% important!'
    },
    option: {
        width: 95,
        height: 50,
        cursor: 'pointer',
        borderBottom: '2px solid #FBFBFB',
        color: 'rgba(0, 0, 0, 0.61)',
        fontSize: '14px',
        background: '#FBFBFB',
        textAlign: 'center'
    },
    genderImg: {
        width: 14,
        height: 14
    },
    selected: {
        //background: linear-gradient(169deg, #e40015 0%, #750075 100%);
        borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    sliderTeamMark: {
        backgroundColor: theme.palette.primary.main,
        width: 3,
        height: 13,
        marginTop: -5,
        marginLeft: -1,
        borderRadius: 2
    },
    radioLabel: {
        fontSize: '14px !important'
    },
    selectLocationCTA: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginTop: 20,
        zIndex: 1
    }
}))

const PublishJob = ({job, setJob}: Props) => {

    const classes = useStyle()
    const {t, i18n} = useTranslation()
    const [circle, setCircle] = useState<any>();
    const [map, setMap] = useState()
    const [marker, setMarker] = useState<any>()
    const [zoom, setZoom] = useState(7);

    const updateFields = (data: { field: string, value: any }[]) => {
        const newJob: any = _.cloneDeep(job)
        data.forEach(d => newJob[d.field] = d.value)
        setJob(newJob)
    }

    const updateField = (field: string, value: any) => {
        updateFields([{field, value}])
    }

    // ***** Handlers ***** //

    const drawCircle = () => {
        circle?.setMap(null);
        marker?.setMap(null);

        if (!selectedCityGeo()) return;

        try {
            setCircle(new google.maps.Circle({
                strokeColor: '#46d98a',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#46d98a',
                fillOpacity: 0.3,
                map,
                center: selectedCityGeo(),
                radius: (job?.location?.radius || 1) * 1000,
            }));

            setMarker(new google.maps.Marker({
                map,
                position: selectedCityGeo()
            }));

            setZoom(7 - (job?.location?.radius || 0) / 130);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        drawCircle();
    }, [job?.location, map])

    const handleAddCountry = (country: any) => {
        let countriesToAdd;
        if (country.countryCode === 'eu' || country.countryCode === 'eeach' || country.countryCode === 'world') {
            switch (country.countryCode) {
                case 'eu':
                    countriesToAdd = getCountriesByIds(euCountryIds)
                    break;
                case 'eeach':
                    countriesToAdd = getCountriesByIds(eeaChCountryIds)
                    break;
                case 'world':
                    countriesToAdd = getCountriesByIds(worldIds)
                    break;
                default:
                    countriesToAdd = [country]
            }
        } else {
            countriesToAdd = [country];
        }

        const updatedCountries = addCountries(job?.location?.country || [], countriesToAdd);

        updateField('location', {
            ...job?.location,
            country: updatedCountries
        })
    };

    const handleRemoveCountry = (country: any) => {
        let countriesToRemove;
        if (country.countryCode === 'eu' || country.countryCode === 'eeach' || country.countryCode === 'world') {
            switch (country.countryCode) {
                case 'eu':
                    countriesToRemove = getCountriesByIds(euCountryIds)
                    break;
                case 'eeach':
                    countriesToRemove = getCountriesByIds(eeaChCountryIds)
                    break;
                case 'world':
                    countriesToRemove = getCountriesByIds(worldIds)
                    break;
                default:
                    countriesToRemove = [country]
            }
        } else {
            countriesToRemove = [country];
        }

        const removedCountries = removeCountries(job?.location?.country || [], countriesToRemove);

        updateField('location', {
            ...job?.location,
            country: removedCountries
        })
    };

    //endregion Handlers

    //region UI

    const renderSelectedLocation = () => {
        if (job?.location?.googleGeolocation) {
            return (
                <Typography
                    className={classes.selectLocationCTA}
                    onClick={() => updateField('location', {
                        ...job?.location,
                        googleGeolocation: undefined
                    })}
                >{job?.location?.googleGeolocation?.[0]?.formatted_address}, {job?.location?.radius || 0}km</Typography>
            );
        }

        if (job?.location?.location) {
            return (
                <Typography
                    className={classes.selectLocationCTA}
                    onClick={() => updateField('location', {
                        ...job?.location,
                        googleGeolocation: undefined
                    })}
                >{job?.location?.location?.name}, {job?.location?.location?.country?.name}, {job?.location.radius}km</Typography>
            );
        }

        return (
            <Box width={'100%'} marginTop={4}>
                <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_MAPS_KEY!}
                    autocompletionRequest={{
                        types: ['(cities)']
                    }}
                    debounce={1000}
                    selectProps={{
                        styles: {width: '100%'},
                        onChange: async (res: any) => {
                            const results = await geocodeByPlaceId(res.value.place_id)
                            updateField('location', {
                                ...job?.location,
                                googleGeolocation: results,
                                location: null,
                                city: null,
                                country: undefined,
                                id: undefined
                            })
                        }
                    }}
                />
            </Box>


            // <GooglePlacesAutocomplete
            //     autocompletionRequest={{
            //         types: ['(cities)'],
            //         // componentRestrictions: {
            //         //     country: COUNTRY_LIMIT
            //         // }
            //     }}
            //     placeholder={t('Enter city name')}
            //     onSelect={async res => {
            //         const result = await geocodeByPlaceId(res.place_id);
            //         // @ts-ignore
            //         props.setCitySearchResults(result);
            //     }}
            //     // apiKey={process.env.mapsKey}
            // />
        )
    }

    const selectedCityGeo = () => {
        if (job?.location?.googleGeolocation?.length) {
            return {
                lat: job?.location?.googleGeolocation?.[0]?.geometry?.location.lat() || 0,
                lng: job?.location?.googleGeolocation?.[0]?.geometry?.location.lng() || 0
            };
        }

        return getOpportunityLocationGeo(job);
    }

    console.log(job?.location)


    return (
        <React.Fragment>
            <Grid item xs={4}>
                <Card
                    style={{cursor: 'pointer'}}
                    onClick={() => job?.locationType !== LocationType.Local && updateField('locationType', LocationType.Local)}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        p={3}
                    >
                        {
                            job?.locationType === LocationType.Local ?
                                <CheckCircleIcon style={{fontSize: 30}} color={'primary'}/> :
                                <RoomIcon style={{fontSize: 30}} color={'secondary'}/>
                        }

                        <Typography style={{marginTop: 20, marginBottom: 5}} variant={'h4'}>Locally</Typography>
                        <Typography variant={'h6'}>{t('search_players_around_specific_city')}</Typography>

                        {
                            job?.locationType === LocationType.Local &&
                            <React.Fragment>
                                {renderSelectedLocation()}

                                {
                                    !!selectedCityGeo() &&
                                    <React.Fragment>
                                        <Box
                                            display={'flex'}
                                            alignItems={'center'}
                                            width={'100%'}
                                            maxWidth={300}
                                            marginTop={3}
                                        >
                                            <div>0km</div>

                                            <Slider
                                                style={{
                                                    flex: 1,
                                                    marginLeft: 10,
                                                    marginRight: 10
                                                }}
                                                value={job?.location?.radius || 0}
                                                min={0}
                                                step={1}
                                                max={450}
                                                valueLabelDisplay="auto"
                                                aria-labelledby="range-slider"
                                                onChange={(_, newValue) => updateField('location', {
                                                    ...job?.location,
                                                    radius: newValue as number
                                                })}
                                            />
                                            <div>450km</div>
                                        </Box>

                                    </React.Fragment>
                                }

                                {
                                    !!selectedCityGeo() &&
                                    <Box style={{
                                        width: '100%',
                                        height: 200,
                                        marginTop: '30px',
                                        borderRadius: 8,
                                        overflow: 'hidden'
                                    }}>
                                        <GoogleMapReact
                                            options={{styles: mapStyleGrey}}
                                            bootstrapURLKeys={{key: process.env.mapsKey!}}
                                            defaultCenter={selectedCityGeo()}
                                            center={selectedCityGeo()}
                                            defaultZoom={7}
                                            zoom={zoom}
                                            yesIWantToUseGoogleMapApiInternals
                                            onGoogleApiLoaded={({map}) => setMap(map)}
                                        />
                                    </Box>
                                }

                                {!selectedCityGeo() && <Box m={5}/>}


                            </React.Fragment>
                        }

                    </Box>
                </Card>
            </Grid>

            <Grid item xs={4}>
                <Card
                    style={{cursor: 'pointer'}}
                    onClick={() => job?.locationType !== LocationType.Country && updateField('locationType', LocationType.Country)}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        p={3}
                    >
                        {
                            job?.locationType === LocationType.Country ?
                                <CheckCircleIcon style={{fontSize: 30}} color={'primary'}/> :
                                <FlagIcon style={{fontSize: 30}} color={'secondary'}/>
                        }

                        <Typography style={{marginTop: 20, marginBottom: 5}} variant={'h4'}>Country</Typography>
                        <Typography variant={'h6'}>{t('search_players_from_selected_countries')}</Typography>

                        {
                            job?.locationType === LocationType.Country &&
                            <React.Fragment>
                                <CountrySelector
                                    countries={job?.location?.country || []}
                                    onRemoveCountry={handleRemoveCountry}
                                    onAddCountry={handleAddCountry}
                                    addTitle={'Add new country'}
                                />

                                {
                                    !!job?.location?.country?.length &&
                                    <Chart
                                        width={328}
                                        height={300}
                                        chartType="GeoChart"
                                        loader={<div>Loading Chart</div>}
                                        data={[
                                            ['Country'],
                                            ...job?.location?.country?.map(c => [c.name])
                                        ]}
                                        options={{
                                            defaultColor: '#46d98a',
                                            // region: mapCountryCodeToChartRegionCode(props.opportunity.countries?.[0]?.countryCode || ''),
                                            magnifyingGlass: {
                                                enable: true,
                                                zoomFactor: 5
                                            }
                                        }}
                                    />
                                }
                            </React.Fragment>
                        }

                    </Box>
                </Card>
            </Grid>

            <Grid item xs={4}>
                <Card
                    style={{cursor: 'pointer'}}
                    onClick={() => job?.locationType !== LocationType.International && updateField('locationType', LocationType.International)}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        p={3}
                    >
                        {
                            job?.locationType === LocationType.International ?
                                <CheckCircleIcon style={{fontSize: 30}} color={'primary'}/> :
                                <PublicIcon style={{fontSize: 30}} color={'secondary'}/>
                        }

                        <Typography style={{marginTop: 20, marginBottom: 5}}
                                    variant={'h4'}>International</Typography>
                        <Typography variant={'h6'}>{t('serach_players_from_all_over_the_world')}</Typography>
                    </Box>
                </Card>
            </Grid>
        </React.Fragment>
    )

    //endregion UI
}

export default PublishJob
