import {Box, Button, Card, CircularProgress} from "@material-ui/core";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import SubscriptionService from "../../../services/subscription.service";
import {format} from "date-fns";
import {useDispatch, useStore} from "react-redux";
import AddPaymentMethod from "../AddPaymentMethod.component";
import {
    loadInvoices,
    loadStripeCustomer,
    loadStripeSubscription,
    setUpgradePopupOpen
} from "../../../store/actions/billing.actions";
import {StripeInvoiceModel} from "../../../models/StripeModels/stripeInvoice.model";
import {useStripe} from "@stripe/react-stripe-js";
import {useBillingState, useUser} from "../../../store/store.utils";
import AuthService from "../../../services/auth.service";
import {PremiumPopupTrigger} from "../../../store/reducers/billing.reducer";
import Popup from "../../common/Popup/Popup";

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        minHeight: 100
    },
    content: {
        width: '100%',
        minHeight: 100
    },
    currentPackageName: {
        fontWeight: 'bold',
        fontSize: 20,
        marginTop: 10,
        marginBottom: 10
    },
    resumeAutorenewal: {
        fontSize: 12,
        color: theme.palette.primary.main,
        marginTop: 10,
        cursor: 'pointer'
    },
    cancelAutorenewal: {
        fontSize: 12,
        color: theme.palette.error.main,
        marginTop: 10,
        cursor: 'pointer'
    }
}))

const CurrentSubscription = () => {

    //region State

    const t = useTranslation().t
    const classes = useStyles()
    const store = useStore()
    const {
        subscription,
        subscriptionState,
        isActive,
        isPastDue,
        isCanceled,
        isTrialing,
        daysUntilTrialEnds,
        invoices,
    } = useBillingState()
    const stripe = useStripe()
    const user = useUser()
    const club = AuthService.getAdminClub(user)
    const dispatch = useDispatch()

    const openInvoice = invoices.find(i => i.status === 'open')
    const [paymentStatus, setPaymentStatus] = useState<'' | 'loadMethods' | 'addingMethod' | 'paying'>('')
    const [paymentTryManual, setPaymentTryManual] = useState(false)

    //endregion State

    //region Handlers

    // const handleRenewalChange = async (shouldAutorenew: boolean) => {
    //     try {
    //         setCurrentSubscriptionState('loading')
    //         const response = await SubscriptionService.changeSubscriptionRenewal(club, shouldAutorenew)
    //         if (response) {
    //             store.dispatch({type: SET_SUBSCRIPTION, payload: response})
    //         }
    //     } catch (e) {
    //         console.log(e)
    //     } finally {
    //         setCurrentSubscriptionState('')
    //     }
    // }

    const handleAdditionalAuth = async (invoice?: StripeInvoiceModel) => {
        if (invoice?.paymentIntent?.clientSecret) {
            const response = await stripe?.confirmCardPayment(invoice?.paymentIntent?.clientSecret!, {
                return_url: window.location.href
            })
            if (response?.paymentIntent?.status === 'succeeded') {
                store.dispatch(loadStripeCustomer(club) as any)
                store.dispatch(loadStripeSubscription(club) as any)
                store.dispatch(loadInvoices(club) as any)
            } else {
                setPaymentTryManual(true)
            }
        } else {
            setPaymentTryManual(true)
        }
    }

    const handlePaymentFix = async () => {
        setPaymentStatus('loadMethods')
        const response = await SubscriptionService.getStripePaymentMethods(club)
        if (!response?.length) {
            setPaymentStatus('addingMethod')
        } else {
            handlePayInvoice()
        }
    }

    const handlePayInvoice = async () => {
        if (!openInvoice) {
            setPaymentStatus('')
            return
        }

        setPaymentStatus('paying')
        const invoice = await SubscriptionService.getStripeInvoice(club, openInvoice?.id)
        try {
            const response = await SubscriptionService.payStripeInvoice(club, invoice?.id)
            if (response?.status === 'paid') {
                store.dispatch(loadStripeCustomer(club) as any)
                store.dispatch(loadStripeSubscription(club) as any)
                store.dispatch(loadInvoices(club) as any)
            } else {
                await handleAdditionalAuth(invoice)
            }
        } catch (e) {
            await handleAdditionalAuth(invoice)
        } finally {
            setPaymentStatus('')
        }
    }

    //endregion Handlers

    //region UI

    // const getTitle = (): string => {
    //     switch (getCurrentSubscriptionType(club)) {
    //         case 'PREMIUM':
    //         case 'AMATEUR':
    //             return t('Amateur')
    //         case 'PRO':
    //         case 'TOP_TIER':
    //             return t('Professional')
    //         default:
    //             return t('Not subscribed')
    //     }
    // }

    const getTitle = (): string => {
        if (isActive || isTrialing) {
            return t('Your subscription is active')
        }

        if (isCanceled) {
            return t('Subscription canceled')
        }

        return t('Not subscribed')
    }

    const packageName = (
        <div className={classes.currentPackageName}>
            {getTitle()}
        </div>
    )

    const subscriptionStart = subscription?.currentPeriodStart ? (
        <div>
            {t('subscribed_on', {start: format(new Date(subscription.currentPeriodStart * 1000), 'dd.MM.yyyy')})}
        </div>
    ) : null

    const expiration = subscription?.currentPeriodEnd ? (
        <div>
            {
                subscription?.cancelAtPeriodEnd
                    ?
                    t('subscription_expires_on', {expiration: format(new Date(subscription?.currentPeriodEnd * 1000), 'dd.MM.yyyy')})
                    :
                    t('Subscription renews on', {date: format(new Date(subscription?.currentPeriodEnd * 1000), 'dd.MM.yyyy')})
            }
        </div>
    ) : null

    const canceledAt = subscription?.canceledAt ? (
        <div>
            {t('Canceled at', {date: format(new Date(subscription?.canceledAt * 1000), 'dd.MM.yyyy')})}
        </div>
    ) : null

    const renderLatestInvoiceState = () => {
        if (paymentStatus !== '') {
            return <CircularProgress/>
        }

        if (!subscription) {
            return (
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => dispatch(setUpgradePopupOpen(true, PremiumPopupTrigger.BILLING_PAGE))}
                >
                    {t('Subscribe')}
                </Button>
            )
        }

        if (openInvoice) {
            return (
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={handlePaymentFix}
                >
                    {t('Retry payment')}
                </Button>
            )
        }

        if (isCanceled) {
            return (
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => store.dispatch(setUpgradePopupOpen(true, PremiumPopupTrigger.BILLING_PAGE))}
                >
                    {t('Renew')}
                </Button>
            )
        }

        return null
    }

    const trialInfo = subscription?.trialEnd ? (
        <div>
            {daysUntilTrialEnds > 0 ? t('Trial ends in', {days: daysUntilTrialEnds}) : t('Trial ends today')}
        </div>
    ) : null

    // let resume: JSX.Element
    // if (subscription && isActive) {
    //     if (subscription.cancelAtPeriodEnd) {
    //         resume = (
    //             <div
    //                 onClick={() => handleRenewalChange(true)}
    //                 className={classes.resumeAutorenewal}
    //             >
    //                 {t('Resume auto-renew')}
    //             </div>
    //         )
    //     } else {
    //         resume = (
    //             <div
    //                 onClick={() => handleRenewalChange(false)}
    //                 className={classes.cancelAutorenewal}
    //             >
    //                 {t('Stop auto-renew')}
    //             </div>
    //         )
    //     }
    // }

    const renderInfo = () => {
        if (subscriptionState === 'loading') {
            return (
                <Box
                    className={classes.content}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <CircularProgress/>
                </Box>
            )
        }

        return (
            <Box
                className={classes.content}
                p={2}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-start'}
                    justifyContent={'center'}
                >
                    {packageName}
                    {(isActive || isPastDue) && subscriptionStart}
                    {(isActive || isPastDue) && expiration}
                    {isCanceled && canceledAt}
                    {isTrialing && trialInfo}
                    {/*{resume}*/}
                </Box>
                {renderLatestInvoiceState()}

            </Box>
        )
    }

    return (
        <Card className={classes.container}>
            {renderInfo()}

            <AddPaymentMethod
                open={paymentStatus === 'addingMethod'}
                onDone={added => {
                    if (added) {
                        handlePayInvoice()
                    } else {
                        setPaymentStatus('')
                    }
                }}
            />

            <Popup
                open={paymentTryManual}
                text={t('Payment attempt failed. Please try manual payment or contact our support')}
                onClose={() => setPaymentTryManual(false)}
                onConfirm={() => window.open(openInvoice?.hostedInvoiceUrl, '_blank')}
                confirmText={t('Pay manually')}
            />

        </Card>
    )

    //endregion UI
}

export default CurrentSubscription