import React, {useCallback, useEffect, useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@material-ui/core"
import debounce from "lodash/debounce"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";
import ETextField from "../../ETextField.component";

const TransfermarktValueFilter = () => {

    //region State

    const [fromTransfermarktValueParam] = useUrlParam({param: 'fromTransfermarktValue', dataType: 'numeric', defaultValue: 0})
    const [toTransfermarktValueParam] = useUrlParam({param: 'toTransfermarktValue', dataType: 'numeric', defaultValue: 0})

    const [fromTransfermarktValueLocal, setFromTransfermarktValueLocal] = useState(fromTransfermarktValueParam)
    const [toTransfermarktValueLocal, setToTransfermarktValueLocal] = useState(toTransfermarktValueParam)
    const [manualInput, setManualInput] = useState(false)

    const [expanded, setExpanded] = useState(!!fromTransfermarktValueLocal && !!toTransfermarktValueLocal)
    const history = useHistory()

    //endregion State

    //region Handlers

    const executeSet = useCallback(debounce((fromTransfermarktValue: any, toTransfermarktValue: any) => {
        setMultipleParams([
            {name: 'fromTransfermarktValue', value: fromTransfermarktValue, dataType: 'numeric'},
            {name: 'toTransfermarktValue', value: toTransfermarktValue, dataType: 'numeric'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }, 1000), [])

    useEffect(() => {
        if (!manualInput) return
        executeSet(fromTransfermarktValueLocal, toTransfermarktValueLocal)
    }, [fromTransfermarktValueLocal, toTransfermarktValueLocal, manualInput])

    //endregion Handlers

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="userName-content"
                id="userName-header"
            >
                <Typography>Transfermarkt value {!!fromTransfermarktValueLocal && !!toTransfermarktValueLocal && `(${fromTransfermarktValueLocal} - ${toTransfermarktValueLocal})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container spacing={1}>
                    <ETextField
                        label={'From'}
                        grid={'1/2'}
                        value={fromTransfermarktValueLocal}
                        onChange={e => {
                            setManualInput(true)
                            setFromTransfermarktValueLocal(+e)
                        }}
                    />
                    <ETextField
                        label={'To'}
                        grid={'1/2'}
                        value={toTransfermarktValueLocal}
                        onChange={e => {
                            setManualInput(true)
                            setToTransfermarktValueLocal(+e)
                        }}
                    />
                </Grid>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default TransfermarktValueFilter