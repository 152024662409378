import {applyMiddleware, combineReducers, createStore} from "redux";
import authReducer from "./reducers/auth.reducer";
import commonReducer from "./reducers/common.reducer";
import thunk from "redux-thunk";
import jobsReducer from "./reducers/jobs.reducer";
import applicationsReducer from "./reducers/applications.reducer";
import profileReducer from "./reducers/profile.reducer";
import billingReducer from "./reducers/billing.reducer";
import algobugReducer from "./reducers/algobug.reducer";

const rootReducer = combineReducers({
    auth: authReducer,
    common: commonReducer,
    jobs: jobsReducer,
    applications: applicationsReducer,
    profile: profileReducer,
    billing: billingReducer,
    algobug: algobugReducer,
});

const middlewares = [thunk];

export const store = createStore(rootReducer, applyMiddleware(...middlewares));
