import {parsePhoneNumberFromString} from "libphonenumber-js/max";
import {getCountryByCountryCode, getCountryByPhoneCode} from "../services/countries";

export const emailValidator = (email: string): string => {
    const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regex.test(email) ? '' : 'email_not_valid';
};

export const emptyValidator = (value: string): string => {
    return value ? '' : 'empty';
};

export const emptyPhoneNumberValidator = (value: string): string => {
    value = value.trim();

    if (!value || value === '+' || getCountryByPhoneCode(value)) {
        return 'empty';
    }

    const parsed = parsePhoneNumberFromString(value);
    let phoneCodeLength = 0;

    if (parsed) {
        const newCountry = getCountryByCountryCode(parsed.country as string);
        if (newCountry) phoneCodeLength = newCountry?.phoneCode?.length || 0;
    }

    return value.length - phoneCodeLength < 7 ? 'empty' : '';
};

export const emptyNumberValidator = (value: number): string => {
    return value === null || value === undefined ? 'empty' : '';
};

export const minLengthValidator = (value: string, minLength: number) => {
    return value && value.length >= minLength ? '' : 'min_length';
};

export const validateForm = (form: any) => {
    let newForm: any = {};

    Object.keys(form).forEach(key => {
        newForm[key] = {
            ...form[key],
            validation: {
                ...form[key].validation
            }
        };
    });

    Object.keys(newForm).forEach(key => {
        let formElement = newForm[key];
        if (formElement.validation && formElement.validation.validators) {
            let error = '';
            formElement.validation.validators.forEach((validator: any) => {
                error = error || validator(formElement.value);
            });
            formElement.validation.error = error;
        }
    });

    return newForm;
};

export const isFormValid = (form: any) => {
    let error: any = '';
    Object.keys(form).forEach(key => {
        let formElement = form[key];
        if (formElement.validation) {
            error = error || formElement.validation.error;
        }
    });
    return !error;
};
