import React, {useCallback, useEffect, useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Box, Slider, Typography} from "@material-ui/core"
import debounce from "lodash/debounce"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";

const TeamLevelFilter = () => {

    //region State

    const [fromLevelParam] = useUrlParam({param: 'fromLevel', dataType: 'numeric', defaultValue: 0})
    const [toLevelParam] = useUrlParam({param: 'toLevel', dataType: 'numeric', defaultValue: 0})

    const [fromLevelLocal, setFromLevelLocal] = useState(fromLevelParam)
    const [toLevelLocal, setToLevelLocal] = useState(toLevelParam)

    const [expanded, setExpanded] = useState(!!fromLevelLocal && !!toLevelLocal)
    const history = useHistory()

    //endregion State

    //region Handlers

    const executeSet = useCallback(debounce((from: any, to: any) => {
        setMultipleParams([
            {name: 'fromLevel', value: from, dataType: 'numeric'},
            {name: 'toLevel', value: to, dataType: 'numeric'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }, 1000), [])

    useEffect(() => {
        if (fromLevelLocal && toLevelLocal) {
            executeSet(fromLevelLocal, toLevelLocal)
        }
    }, [fromLevelLocal, toLevelLocal])

    //endregion Handlers

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="team-level-content"
                id="team-level-header"
            >
                <Typography>Level {!!fromLevelLocal && !!toLevelLocal && `(${fromLevelLocal} - ${toLevelLocal})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Box display={'flex'} alignItems={'center'} style={{width: '100%'}}>
                    <div>{fromLevelLocal || 1}</div>
                    <Slider
                        style={{width: '100% !important', margin: '0 20px'}}
                        onChange={(_, value) => {
                            setFromLevelLocal((value as number[])[0])
                            setToLevelLocal((value as number[])[1])
                        }}
                        value={[fromLevelLocal || 1, toLevelLocal || 15]}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        min={1}
                        max={15}
                        step={1}
                    />
                    <div>{toLevelLocal || 15}</div>
                </Box>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default TeamLevelFilter