import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {Box, CircularProgress} from "@material-ui/core";
import PlayerProfilePreview from "./preview/PlayerProfilePreview";
import UserModel from "../../models/user.model";
import UserService from "../../services/user.service";

const UserPublicPreview = () => {

    const [user, setUser] = useState<UserModel>()
    const [loading, setLoading] = useState(false)
    const params = useParams<{ id?: string }>()

    const fetch = async () => {
        setLoading(true)
        const response = await UserService.getUser(+params.id!, true)
        setUser(response)
        setLoading(false)
    }

    useEffect(() => {
        fetch()
    }, [params.id])

    if (loading) {
        return <CircularProgress/>
    }

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
        >
            <PlayerProfilePreview
                user={user}
            />
        </Box>
    )
}

export default UserPublicPreview