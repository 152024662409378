import React from 'react'
import {Box, Grid, Slider, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import Job from "../../../../models/job.model";
import clsx from "clsx";
import _ from "lodash";

interface Props {
    job?: Job,
    setJob: (job: Job) => void,
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
    input: {
        width: '100%'
    },
    timeline: {
        // transform: "rotate(90deg)",
        // width: 100,
        height: '100%'
    },
    timelineContentContainer: {
        // textAlign: "left",
        flex: 1
    },
    timelineContent: {
        // display: "inline-block",
        // transform: "rotate(-90deg)",
        // textAlign: "center",
        minWidth: 100
    },
    timelineIcon: {
        // transform: "rotate(-90deg)",
        width: 20,
        height: 20
    },
    team: {
        background: '#FAFAFA',
        borderRadius: 16,
        marginTop: 5,
        marginBottom: 5,
        marginRight: 10,
        border: '2px solid transparent',
        cursor: 'pointer',
        padding: 10,
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.57)'
    },
    teamSelected: {
        background: theme.palette.primary.main,
        color: 'white'
    },
    slider: {
        marginLeft: 2,
        width: '100%',
        maxWidth: '100%'
    },
    sliderMark: {
        width: 24,
        textAlign: 'center',
        fontSize: 12
    },
    sliderMarkLeft: {
        marginRight: 20
    },
    sliderMarkRight: {
        marginLeft: 20
    },
    sliderTop: {
        width: '100% important!'
    },
    option: {
        width: 95,
        height: 50,
        cursor: 'pointer',
        borderBottom: '2px solid #FBFBFB',
        color: 'rgba(0, 0, 0, 0.61)',
        fontSize: '14px',
        background: '#FBFBFB',
        textAlign: 'center'
    },
    genderImg: {
        width: 14,
        height: 14
    },
    selected: {
        //background: linear-gradient(169deg, #e40015 0%, #750075 100%);
        borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    sliderTeamMark: {
        backgroundColor: theme.palette.primary.main,
        width: 3,
        height: 13,
        marginTop: -5,
        marginLeft: -1,
        borderRadius: 2
    },
    radioLabel: {
        fontSize: '14px !important'
    },
    selectLocationCTA: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginTop: 20,
        zIndex: 1
    }
}))

const StatisticSection = ({job, setJob}: Props) => {

    const classes = useStyle()

    const updateFields = (data: { field: string, value: any }[]) => {
        const newJob: any = _.cloneDeep(job)
        data.forEach(d => newJob[d.field] = d.value)
        setJob(newJob)
    }

    const updateField = (field: string, value: any) => {
        updateFields([{field, value}])
    }

    // ***** Handlers ***** //

    //endregion Handlers

    //region UI

    const minutesPlayerUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>Minutes played % (last season)</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        className={classes.slider}
                    >
                        <p style={{fontSize: 12}} className={clsx(classes.sliderMarkLeft)}>Not important</p>
                        <Slider
                            className={classes.sliderTop}
                            onChange={(_: any, newValue: any) => {
                                updateField('minMinutesPercentage', newValue as number)
                            }}
                            value={job?.minMinutesPercentage || 0}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            min={0}
                            max={100}
                            step={10}
                        />
                        <p className={clsx(classes.sliderMark, classes.sliderMarkRight)}>100%</p>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )

    const goalsUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>Goals (last season)</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        className={classes.slider}
                    >
                        <p style={{fontSize: 12}} className={clsx(classes.sliderMarkLeft)}>Not important</p>
                        <Slider
                            className={classes.sliderTop}
                            onChange={(_: any, newValue: any) => {
                                updateField('minGoals', newValue as number)
                            }}
                            value={job?.minGoals || 0}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            min={0}
                            max={50}
                            step={5}
                        />
                        <p className={clsx(classes.sliderMark, classes.sliderMarkRight)}>50+</p>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )

    const assistsUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>Assists (last season)</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        className={classes.slider}
                    >
                        <p style={{fontSize: 12}} className={clsx(classes.sliderMarkLeft)}>Not important</p>
                        <Slider
                            className={classes.sliderTop}
                            onChange={(_: any, newValue: any) => {
                                updateField('minAssists', newValue as number)
                            }}
                            value={job?.minAssists || 0}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            min={0}
                            max={50}
                            step={5}
                        />
                        <p className={clsx(classes.sliderMark, classes.sliderMarkRight)}>50+</p>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )

    const additionalLeftUI = (
        <Grid item xs={6}>

            <Grid container spacing={4}>
                {goalsUI}
                {assistsUI}
                {minutesPlayerUI}
            </Grid>

        </Grid>
    )

    const additionalRightUI = (
        <Grid item xs={6}>

        </Grid>
    )


    return (
        <Grid container spacing={10}>
            {additionalLeftUI}
            {additionalRightUI}
        </Grid>
    )

    //endregion UI
}

export default StatisticSection
