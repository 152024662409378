import apollo from "./api/apollo"
import ProfileService from "./profile.service"
import UserModel, {AgencyPosition, UserProfile, UserTypeId} from "../models/user.model"
import MixpanelService from "./analytics/mixpanel.service"
import {EventName} from "./analytics/eventName"
import {EventProperty} from "./analytics/eventProperty"
import {PeopleProperty} from "./analytics/peopleProperty"
import {
    generateProfileURL,
    getAgencyPosition,
    getCityName,
    getClubLogo,
    getCountryName,
    getPhone,
    getProfilePicture,
    isPhAdmin
} from "../util/profile.util"
import IntercomService from "./intercom.service"
import {LoginType} from "../models/loginType.model"
import ClubModel from "../models/club.model";
import {
    CHECK_EMAIL,
    LOGIN,
    LOGIN_AS_CLUB,
    LOGIN_AS_CLUB_REQUEST,
    REGISTER_CLUB_ADMIN,
    REGISTER_USER_VIA_EMAIL,
    RESET_PASSWORD,
    SOCIAL_LOGIN,
    UPDATE_PASSWORD,
    UPDATE_USER_PROFILE
} from "./api/usersQueries";
import {gapi, loadAuth2} from "gapi-script";
import {getClubCityName, getClubCountryName} from "./club.util";

declare global {
    interface Window {
        FB: any
    }
}

const SCOPES = "https://www.googleapis.com/auth/userinfo.email";
const API_KEY = process.env.REACT_APP_GAPI_API_KEY;
const CLIENT_ID = process.env.REACT_APP_GAPI_CLIENT_ID;

export class AuthError extends Error {
    code: string

    constructor(code: string) {
        super()
        this.code = code
    }
}

export default class AuthService {

    static instance = new AuthService()

    token: string = ''
    userId?: number = 0

    static getInstance = () => {
        return AuthService.instance
    }

    //region Util

    static cleanupTokens = async () => {
        await localStorage.removeItem('token')
        await localStorage.removeItem('userId')
    }

    static setTokens = async (token: string, id: number) => {
        await localStorage.setItem('token', token)
        await localStorage.setItem('userId', `${id}`)
    }

    static handleCommonPostAuth = async (response: any) => {
        AuthService.getInstance().userId = response.id
        await localStorage.setItem('token', response.token)

        const me = await ProfileService.getMe()
        isPhAdmin(me) ? IntercomService.shutdown() : IntercomService.updateIntercomUser(me)

        return me
    }

    static handlePostLoginViaLoginResponse = async (response: any) => {
        const user = await AuthService.handleCommonPostAuth(response)
        MixpanelService.identifyUser(`${user.id}`, false)
        AuthService.trackCommonPostAuth(user)
        return user
    }

    static handlePostLoginViaUser = async (user: UserModel) => {
        MixpanelService.identifyUser(`${user.id}`, false)
        AuthService.trackCommonPostAuth(user)

        return user
    }

    static trackCommonPostAuth = (user?: UserModel) => {
        MixpanelService.track(EventName.SIGN_IN, {
            [EventProperty.SIGN_IN_TYPE]: user?.loginType?.toLowerCase(),
            [EventProperty.SIGN_IN_DATE]: MixpanelService.getCurrentTimeIso(),
        })
        MixpanelService.incrementProperty(PeopleProperty.TOTAL_NUMBER_OF_SIGN_IN)
        MixpanelService.setUserProperties({
            [PeopleProperty.EMAIL]: user?.email,
            [PeopleProperty.FIRST_NAME]: user?.profile?.firstName,
            [PeopleProperty.LAST_NAME]: user?.profile?.lastName,
            [PeopleProperty.NAME]: [user?.profile?.firstName, user?.profile?.lastName].filter(x => !!x).join(' '),
            [PeopleProperty.CREATED]: MixpanelService.getCurrentTimeIso(),
            [PeopleProperty.USER_TYPE]: 'club',
            [PeopleProperty.USER_ID]: user?.id,
            [PeopleProperty.SIGN_IN_DATE]: MixpanelService.getCurrentTimeIso(),
            [PeopleProperty.PROFILE_URL]: generateProfileURL(user),
            [PeopleProperty.PHONE]: getPhone(user),
            [PeopleProperty.AVATAR]: getProfilePicture(user),
            [PeopleProperty.SELECTED_CITY]: getCityName(user),
            [PeopleProperty.SELECTED_COUNTRY]: getCountryName(user),
            [PeopleProperty.LAST_SIGN_IN_PLATFORM]: 'web'
        })

        const club = AuthService.getAdminClub(user)
        MixpanelService.setUserProperties({
            [PeopleProperty.CLUB_NAME]: club?.name,
            [PeopleProperty.CLUB_POSITION]: getAgencyPosition(user)?.toLowerCase(),
            [PeopleProperty.CLUB_CITY]: getClubCityName(club),
            [PeopleProperty.CLUB_COUNTRY]: getClubCountryName(club),
            [PeopleProperty.CLUB_LOGO]: getClubLogo(club)
        })
    }

    // static handlePostLogin = async (response: any) => {
    //     const me = await AuthService.handleCommonPostAuth(response)
    //
    //     MixpanelService.identifyUser(`${response.id}`, false)
    //     MixpanelService.track(EventName.SIGN_IN, {
    //         [EventProperty.SIGN_IN_TYPE]: 'email',
    //         [EventProperty.SIGN_IN_DATE]: MixpanelService.getCurrentTimeIso(),
    //     })
    //     MixpanelService.incrementProperty(PeopleProperty.TOTAL_NUMBER_OF_SIGN_IN)
    //     MixpanelService.setUserProperties({
    //         [PeopleProperty.SIGN_IN_DATE]: MixpanelService.getCurrentTimeIso(),
    //         [PeopleProperty.PROFILE_URL]: generateProfileURL(me),
    //         [PeopleProperty.USER_ID]: me.id,
    //     })
    //
    //     return me
    // }

    static handlePostRegistration = async (response: any, signUpType: string, firstName?: string, lastName?: string, phone?: string, email?: string, agencyPosition?: AgencyPosition) => {
        let me = await AuthService.handleCommonPostAuth(response)

        try {
            await apollo.mutate({
                mutation: UPDATE_USER_PROFILE,
                variables: {
                    profile: {
                        firstName: firstName,
                        lastName: lastName,
                        contactInfo: {
                            phone: phone,
                            email: email
                        },
                        agencyPosition: agencyPosition
                    } as UserProfile
                }
            })

            me = await ProfileService.getMe()
        } catch (e) {
            console.log(e)
        }

        MixpanelService.identifyUser(`${response.id}`, true)
        MixpanelService.track(EventName.SIGN_UP, {
            [EventProperty.SIGN_UP_TYPE]: signUpType,
            [EventProperty.SIGN_UP_DATE]: MixpanelService.getCurrentTimeIso(),
        })
        MixpanelService.setUserProperties({
            [PeopleProperty.SIGN_UP_TYPE]: signUpType,
            [PeopleProperty.SIGN_UP_DATE]: MixpanelService.getCurrentTimeIso(),
        })

        AuthService.trackCommonPostAuth(me)

        return me
    }

    //endregion Util

    //region Email auth

    static emailUserLogin = async (email: string, password: string) => {
        await AuthService.cleanupTokens()

        const response = await apollo.mutate({
            mutation: LOGIN,
            variables: {
                email: email,
                password: password
            }
        })

        return AuthService.handlePostLoginViaLoginResponse(response.data.login)
    }

    static emailUserRegistration = async (
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        userTypeId: UserTypeId
    ) => {
        await AuthService.cleanupTokens()

        const response = await apollo.mutate({
            mutation: REGISTER_USER_VIA_EMAIL,
            variables: {
                email: email,
                password: password,
                firstName: firstName,
                lastName: lastName,
                type: userTypeId ? {
                    id: userTypeId
                } : undefined
            }
        })

        return AuthService.handlePostRegistration(response.data.register, 'email', firstName, lastName, '', email)
    }

    static emailClubRegistration = async (
        firstName: string,
        lastName: string,
        postal: string,
        phone: string,
        email: string,
        password: string,
        role: string,
        club: ClubModel,
        agencyPosition?: AgencyPosition
    ) => {
        await AuthService.cleanupTokens()

        const response = await apollo.mutate({
            mutation: REGISTER_CLUB_ADMIN,
            variables: {
                clubId: club.isRequest ? null : club.id,
                clubRequest: club.isRequest ? {name: club.name} : null,
                email: email,
                password: password,
                role: role
            }
        })
        await localStorage.setItem('token', response.data.registerAsClubAdmin.token)

        return AuthService.handlePostRegistration(response.data.registerAsClubAdmin, 'email', firstName, lastName, phone, email, agencyPosition)
    }

    static async checkEmail(email: string): Promise<boolean> {
        const response = await apollo.query({
            query: CHECK_EMAIL,
            variables: {
                email: email
            }
        })
        return response?.data?.checkEmail?.exist || false
    }

    //endregion Email auth

    //region Social auth

    static facebookLogin = async () => {
        let onResolve: (value: any) => void
        let onReject: (reason: any) => void
        const promise = new Promise((resolve, reject) => {
            onResolve = resolve
            onReject = reject
        })

        //Create response callback.
        const responseInfoCallback = (error?: Object, result?: any) => {
            if (error) {
                onResolve(null)
            } else {
                console.log('Success fetching data: ', result)
                onResolve({
                    email: result.email,
                    externalId: result.id,
                    firstName: result.first_name,
                    lastName: result.last_name,
                    loginType: LoginType.FACEBOOK,
                    token: result.accessToken
                })
            }
        }

        // Create a graph request asking for user information with a callback to handle the response.
        window.FB.login((authResponse: any) => {
            if (authResponse.authResponse) {
                console.log('Welcome!  Fetching your information.... ', authResponse.authResponse);
                window.FB.api('/me',
                    'GET',
                    {fields: 'id,email,first_name,last_name'},
                    (response: any) => {
                        if (response.id) {
                            console.log('Good to see you, ' + response.first_name + '.', response);
                            responseInfoCallback('', {
                                ...response,
                                accessToken: authResponse.authResponse.accessToken
                            })
                        } else {
                            responseInfoCallback('Cancel or not full auth')
                        }
                    });
            } else {
                console.log('User cancelled login or did not fully authorize.');
                responseInfoCallback('Cancel or not full auth')
            }
        }, {scope: 'email,public_profile', return_scopes: true});

        return promise
    }

    static googleLogin = async () => {
        try {
            let auth2 = await loadAuth2(gapi, '787768798290-4j9qj949vmi26fdseo53co0jtoi847s3.apps.googleusercontent.com', SCOPES);
            const authResponse = await auth2.signIn()
            const externalId = authResponse.getId()
            const email = authResponse.getBasicProfile().getEmail()
            const firstName = authResponse.getBasicProfile().getGivenName()
            const lastName = authResponse.getBasicProfile().getFamilyName()
            const token = authResponse.getAuthResponse().access_token
            return {
                email,
                externalId,
                firstName,
                lastName,
                loginType: LoginType.GOOGLE,
                token
            }
        } catch (e) {
            console.log(e)
        }
    }

    static appleLogin = async () => {
        // console.warn('Beginning Apple Authentication')
        //
        // try {
        //     const appleAuthRequestResponse = await appleAuth.performRequest({
        //         requestedOperation: appleAuth.Operation.LOGIN,
        //         requestedScopes: [appleAuth.Scope.EMAIL, appleAuth.Scope.FULL_NAME],
        //     })
        //
        //     console.log('appleAuthRequestResponse', appleAuthRequestResponse)
        //
        //     const {
        //         user,
        //         email,
        //         nonce,
        //         identityToken,
        //         realUserStatus,
        //         fullName
        //     } = appleAuthRequestResponse
        //
        //     if (identityToken) {
        //         console.warn(`Apple Authentication Completed, ${user}, ${email}`)
        //
        //         return {
        //             email: email,
        //             externalId: user,
        //             firstName: fullName?.givenName,
        //             lastName: fullName?.familyName,
        //             loginType: LoginType.APPLE,
        //             token: identityToken
        //         }
        //     } else {
        //         return null
        //     }
        // } catch (error: any) {
        //     if (error.code === appleAuth.Error.CANCELED) {
        //         console.warn('User canceled Apple Sign in.')
        //     } else {
        //         console.log(error)
        //     }
        //     return null
        // }
    }

    static socialLogin = async (loginType: LoginType, userTypeId?: UserTypeId, clubId?: number, clubRequest?: ClubModel, firstName?: string, lastName?: string, phone?: string, agencyPosition?: AgencyPosition, createGenericAccount?: boolean) => {
        await localStorage.removeItem('token')
        await localStorage.removeItem('userId')

        const id = Math.floor(Math.random() * 100000000)
        // const id = 72532052;

        let socialLoginDTO: any
        switch (loginType) {
            case LoginType.FACEBOOK:
                socialLoginDTO = await AuthService.facebookLogin()
                break
            case LoginType.GOOGLE:
                socialLoginDTO = await AuthService.googleLogin()
                break
            case LoginType.APPLE:
                socialLoginDTO = await AuthService.appleLogin()
                break
        }
        console.log('Social login info', socialLoginDTO)

        if (!socialLoginDTO) {
            throw new Error('no user info loaded')
        }

        if (process.env.REACT_APP_ALLOW_GENERIC_ACCOUNTS === 'true') {
            if (createGenericAccount) {
                socialLoginDTO.externalId = id + socialLoginDTO.externalId
                socialLoginDTO.email = socialLoginDTO.email && id + socialLoginDTO.email
            }
        }

        const response = await apollo.mutate({
            mutation: SOCIAL_LOGIN,
            variables: {
                clubId,
                clubRequest,
                socialLoginDTO: socialLoginDTO,
                type: userTypeId ? {
                    id: userTypeId
                } : undefined
            }
        })

        const userLoginResponse = response.data.socialLogin
        if (userLoginResponse.registered) {
            return AuthService.handlePostRegistration(userLoginResponse, loginType.toLowerCase(), firstName, lastName, phone, userLoginResponse.email, agencyPosition)
        } else {
            return AuthService.handlePostLoginViaLoginResponse(userLoginResponse)
        }
    }

    //endregion Social auth

    //region Token auth

    static tokenLogin = async () => {
        await localStorage.removeItem('subuser_token')

        let token: string = ''

        try {
            token = (await localStorage.getItem('token')) || ''
        } catch (e) {
            console.log(e)
        }

        if (token) {
            const me = await ProfileService.getMe()
            AuthService.getInstance().userId = me.id
            AuthService.handlePostLoginViaUser(me)

            isPhAdmin(me) ? IntercomService.shutdown() : IntercomService.updateIntercomUser(me)

            return me
        } else {
            return null
        }
    }

    //endregion Token auth

    //region Password handling

    static resetPassword = async (email: string): Promise<UserModel> => {
        const response = await apollo.mutate({
            mutation: RESET_PASSWORD,
            variables: {
                email: email
            }
        })

        return response.data.resetPassword
    }

    public static async updatePassword(resetToken: string, newPassword: string) {
        try {
            localStorage.removeItem("token")
            const response = await apollo.mutate({
                mutation: UPDATE_PASSWORD,
                variables: {
                    login: {
                        password: newPassword
                    },
                    token: resetToken
                }
            })

            return AuthService.handlePostLoginViaLoginResponse(response.data.updatePassword)
        } catch (error) {
            console.log(error)
        }
    }

    //endregion Password handling

    //region Util

    static logout = async () => {
        try {
            await AuthService.cleanupTokens()
            IntercomService.init()
            // TODO
            // await IntercomService.logout()
            // await IntercomService.registerUnidentifiedUser()
            AuthService.getInstance().userId = undefined
            MixpanelService.reset()
        } catch (e) {
            console.log(e)
        }
    }

    public static getAdminClub = (user?: UserModel): ClubModel | undefined => {
        if (user?.adminOf?.[0]) return user?.adminOf?.[0]
        if (user?.adminOfRequests?.[0]) {
            return {
                ...user?.adminOfRequests?.[0],
                isRequest: true
            }
        }

        return undefined
    }

    static async executeLoginAsClub(clubForLogin?: ClubModel) {
        let response
        if (clubForLogin?.isRequest) {
            response = await apollo.mutate({
                mutation: LOGIN_AS_CLUB_REQUEST,
                variables: {
                    clubRequestId: clubForLogin?.id
                }
            })
        } else {
            response = await apollo.mutate({
                mutation: LOGIN_AS_CLUB,
                variables: {
                    clubId: clubForLogin?.id
                }
            })
        }

        return clubForLogin?.isRequest ? response.data.adminLoginAsClubRequest : response.data.adminLoginAsClub
    }

    public static async loginAsClub(clubForLogin?: ClubModel) {
        try {
            const response = await AuthService.executeLoginAsClub(clubForLogin)
            return await AuthService.handlePostLoginViaLoginResponse(response)
        } catch (error) {
            console.log(error)
        }
    }

    //endregion Util
}

