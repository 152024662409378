import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from './Share.module.scss';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    OKIcon,
    OKShareButton,
    PinterestIcon,
    PinterestShareButton,
    PocketIcon,
    PocketShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TumblrShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    VKIcon,
    VKShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';
import {useTranslation} from "react-i18next";
import {Box} from "@material-ui/core";
import EButton from "../EButton";

interface Props {
    open: boolean;
    onClose: () => void;
    title: string;
    text: string;
    url: string;
    shareTo: string[];
    media: string;
}


const Share = (props: Props) => {

    const [copied, setCopied] = useState(false);
    const t = useTranslation().t;

    useEffect(() => {
        if (props.open) {
            setCopied(false);
        }
    }, [props.open]);

    // @ts-ignore
    const renderShareButton = (network: string) => {
        switch (network) {
            case 'facebook':
                return (
                    <FacebookShareButton
                        url={props.url}
                        quote={props.text}
                        className={styles.shareButton}
                    >
                        <FacebookIcon size={48} round/>
                    </FacebookShareButton>
                );
            case 'twitter':
                return (
                    <TwitterShareButton
                        url={props.url}
                        title={props.text}
                        className={styles.shareButton}
                    >
                        <TwitterIcon size={48} round/>
                    </TwitterShareButton>
                );
            case 'email':
                return (
                    <EmailShareButton
                        url={props.url}
                        subject={props.title}
                        body={props.text}
                        className={styles.shareButton}
                    >
                        <EmailIcon size={48} round/>
                    </EmailShareButton>
                );
            case 'whatsapp':
                return (
                    <WhatsappShareButton
                        url={props.url}
                        title={props.text}
                        separator=":: "
                        className={styles.shareButton}
                    >
                        <WhatsappIcon size={48} round/>
                    </WhatsappShareButton>
                );
            case 'linkedin':
                return (
                    <LinkedinShareButton
                        url={props.url}
                        className={styles.shareButton}
                    >
                        <LinkedinIcon size={48} round/>
                    </LinkedinShareButton>
                );
            case 'pinterest':
                return (
                    <PinterestShareButton
                        url={props.url}
                        media={props.media}
                        className={styles.shareButton}
                    >
                        <PinterestIcon size={48} round/>
                    </PinterestShareButton>
                );
            case 'viber':
                return (
                    <ViberShareButton
                        url={props.url}
                        title={props.text}
                        className={styles.shareButton}
                    >
                        <ViberIcon size={48} round/>
                    </ViberShareButton>
                );
            case 'telegram':
                return (
                    <TelegramShareButton
                        url={props.url}
                        title={props.text}
                        className={styles.shareButton}
                    >
                        <TelegramIcon size={48} round/>
                    </TelegramShareButton>
                );
            case 'vk':
                return (
                    <VKShareButton
                        url={props.url}
                        image={props.media}
                        className={styles.shareButton}
                    >
                        <VKIcon size={48} round/>
                    </VKShareButton>
                );
            case 'ok':
                return (
                    <OKShareButton
                        url={props.url}
                        image={props.media}
                        className={styles.shareButton}
                    >
                        <OKIcon size={48} round/>
                    </OKShareButton>
                );
            case 'reddit':
                return (
                    <RedditShareButton
                        url={props.url}
                        title={props.text}
                        windowWidth={660}
                        windowHeight={460}
                        className={styles.shareButton}
                    >
                        <RedditIcon size={48} round/>
                    </RedditShareButton>
                );
            case 'thumblr':
                return (
                    <TumblrShareButton
                        url={props.url}
                        title={props.text}
                        className={styles.shareButton}
                    >
                        <TumblrIcon size={48} round/>
                    </TumblrShareButton>
                );
            case 'pocket':
                return (
                    <PocketShareButton
                        url={props.url}
                        title={props.text}
                        className={styles.shareButton}
                    >
                        <PocketIcon size={48} round/>
                    </PocketShareButton>
                );

        }
        return null;
    };

    return (
        <Dialog aria-labelledby="simple-dialog-title" {...props} maxWidth={"sm"} fullWidth={true}
                PaperProps={{
                    style: {
                        margin: 8,
                        width: 'calc(100% - 16px)'
                    },
                }}
        >
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                flexWrap={'wrap'}
                className={styles.container}
            >
                <div className={styles.title}>{t('share_profile')}</div>

                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    flexWrap={'wrap'}
                    className={styles.buttons}
                >
                    {props.shareTo.map(renderShareButton)}
                </Box>

                <EButton variant="outlined" color="primary" className={styles.actionButton}
                         onClick={() => {
                             navigator.clipboard.writeText(props.url);
                             setCopied(true)
                         }}>
                    {copied ? t('profile_link_copied') : t('copy_profile_link_to_clipboard')}
                </EButton>

            </Box>
        </Dialog>
    )
};

export default Share;
