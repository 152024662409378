export type EGridSizes = '1/1' | '1/2' | '1/3' | '2/3' | '1/4' | '1/6'

export const getGridSizes = (grid: EGridSizes) => {
    let xs;
    let sm;
    let md;

    switch (grid) {
        case "1/1":
            xs = 12
            sm = 12
            md = 12
            break;
        case "1/2":
            xs = 12
            sm = 6
            md = 6
            break;
        case "1/3":
            xs = 12
            sm = 4
            md = 4
            break;
        case "2/3":
            xs = 12
            sm = 8
            md = 8
            break;
        case "1/4":
            xs = 12
            sm = 3
            md = 3
            break;
        case "1/6":
            xs = 6
            sm = 2
            md = 2
            break;
    }

    return {
        xs: xs as any,
        sm: sm as any,
        md: md as any
    }
}