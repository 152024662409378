import React, {useEffect, useState} from 'react'
import styles from './JobsClub.module.scss'
import {useTranslation} from "react-i18next"
import Job from "../../models/job.model";
import {useBillingState, useUser} from "../../store/store.utils";
import OpportunityService from "../../services/opportunity.service";
import {useHistory} from "react-router";
import AuthService from "../../services/auth.service";
import {REGISTRATION_CHOSEN_SUBSCRIPTION_TYPE} from "../../util/constants";
import {SET_SUBSCRIBING_TO_AMATEUR, SET_SUBSCRIBING_TO_PRO_TRIAL_IN_PROGRESS} from "../../store/actions/actionTypes";
import MixpanelService from "../../services/analytics/mixpanel.service";
import {ScreenName} from "../../services/analytics/screenName";
import {setUpgradePopupOpen} from "../../store/actions/billing.actions";
import {useDispatch} from "react-redux";
import {EventName} from "../../services/analytics/eventName";
import {PeopleProperty} from "../../services/analytics/peopleProperty";
import {isUserApprovedAndClubApproved} from "../../util/profile.util";
import {Box, Button, Grid, Typography} from "@material-ui/core";
import {getCurrentSubscriptionMaxJobs} from "../../util/payment.util";
import {PremiumPopupTrigger} from "../../store/reducers/billing.reducer";
import JobPreview from "./JobPreview/JobPreview";
import JobPreviewLoader from "./JobPreview/JobPreviewLoader";
import SubscriptionWarning from "./SubscriptionWarning.component";
import EditClubNotPossiblePopup from "./EditClubHeaderAndLocationPopup/EditClubNotPossiblePopup";
import Countdown from "./Countdown.component";

const JobsClub = () => {

    //region State

    const [notAllowedPopupOpen, setNotAllowedPopupOpen] = useState(false)
    const [opportunities, setOpportunities] = useState<Job[]>([])
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const t = useTranslation().t
    const history = useHistory()
    const user = useUser()
    const club = AuthService.getAdminClub(user)
    const {isActive, isTrialing, isTryingToCharge, hasAnySubscription} = useBillingState()
    const canCreateOpportunity = isTrialing || isActive || isTryingToCharge

    //endregion State

    //region Handlers

    const getOpportunities = async () => {
        if (!club) return

        try {
            setLoading(true)
            const response = await OpportunityService.getOpportunities(club)
            setOpportunities(response.content)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (club && user) {
            getOpportunities()

            const productId = localStorage.getItem(REGISTRATION_CHOSEN_SUBSCRIPTION_TYPE) || ''
            localStorage.removeItem(REGISTRATION_CHOSEN_SUBSCRIPTION_TYPE)
            if (productId === 'PRO') {
                dispatch({type: SET_SUBSCRIBING_TO_PRO_TRIAL_IN_PROGRESS, payload: true})
            } else if (productId === 'AMATEUR') {
                dispatch({type: SET_SUBSCRIBING_TO_AMATEUR, payload: true})
            }
        }
    }, [user])

    useEffect(() => {
        MixpanelService.trackScreenVisit(ScreenName.JOBS_LIST)
        MixpanelService.trackScreenVisit(ScreenName.CLUB_LANDING)
        // dispatch(loadInvoices(club) as any)
        // dispatch(loadStripeCustomer(club) as any)
        // dispatch(loadStripeSubscription(club) as any)
    }, [])

    const handleOpportunityCreate = () => {
        if (!canCreateOpportunity) {
            if (!hasAnySubscription) {
                dispatch(setUpgradePopupOpen?.(true, PremiumPopupTrigger.JOB_LIMIT))
            } else {
                history.push('/club/billing/overview')
            }
            return
        }

        MixpanelService.track(EventName.OPPORTUNITY_CREATION_STARTED)
        MixpanelService.incrementProperty(PeopleProperty.OPPORTUNITY_CREATION_STARTED)
        MixpanelService.setUserProperties({
            [PeopleProperty.LAST_OPPORTUNITY_CREATION_STARTED]: MixpanelService.getCurrentTimeIso()
        })

        history.push(`/club/job/new`)
    }

    const handleOpportunityClick = (opportunity: Job) => {
        if (!isUserApprovedAndClubApproved(user, club)) {
            setNotAllowedPopupOpen(true)
            return
        }

        MixpanelService.track(EventName.APPLICANT_REVIEW)
        MixpanelService.incrementProperty(PeopleProperty.TOTAL_APPLICANT_REVIEWS)
        MixpanelService.setUserProperties({
            [PeopleProperty.LAST_APPLICANT_REVIEW]: MixpanelService.getCurrentTimeIso()
        })

        history.push(`/club/job/${opportunity.id}`)
    }

    const handleDelete = async (opportunity: Job) => {
        try {
            const opportunityWithDelete = {
                ...opportunity,
                deleted: true
            }
            const deletedOpportunity = await OpportunityService.updateOpportunity(opportunityWithDelete)
            setOpportunities(opportunities.filter(o => o.id !== deletedOpportunity.id))
        } catch (e) {
            console.error(e)
        }
    }

    // TODO
    const onChangeOpportunityStatus = async (opportunity: Job, newStatus: string) => {
        //     try {
        //         const opportunityWithStatus = {
        //             ...opportunity,
        //             opportunityStatus: newStatus
        //         }
        //         const updatedOpportunity = await OpportunityService.updateOpportunity(opportunityWithStatus)
        //
        //         const result = {
        //             ...updatedOpportunity,
        //             location: {
        //                 ...opportunity.location
        //             }
        //         }
        //
        //         const updatedOpportunities = [...opportunities]
        //         const index = updatedOpportunities.findIndex(opportunity => opportunity.id === result.id)
        //         updatedOpportunities[index] = result
        //         setOpportunities(updatedOpportunities)
        //     } catch (e) {
        //         console.error(e)
        //     }
        //
        //     if (newStatus === 'INACTIVE') {
        //         MixpanelService.track(EventName.OPPORTUNITY_DEACTIVATED, {
        //             [EventProperty.OPPORTUNITY_ID]: opportunity?.id
        //         })
        //     }
    }

    //endregion Handlers

    //region UI

    const showNoOpportunities = () => {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                className={styles.container}
            >
                <Typography>{t("you_haven't_created_an_opportunity")}</Typography>
                <Typography>{t("start_finding_player")}</Typography>

                <Button variant="contained"
                        color="primary"
                        className={styles.createEmpty}
                        onClick={handleOpportunityCreate}
                >
                    {t('create_an_opportunity')}
                </Button>
            </Box>
        )
    }

    const showOpportunities = () => {
        let opportunityStatus = ''
        const totalOpportunities = opportunities.length
        const allowedOpportunities = getCurrentSubscriptionMaxJobs(club)
        if (allowedOpportunities !== -1) {
            opportunityStatus = t('created_out_of_total', {
                numberOfCreated: totalOpportunities,
                numberOfTotal: allowedOpportunities
            })
        }

        return (
            <React.Fragment>
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={styles.manageTitle}
                    flexWrap={'wrap'}
                >
                    <div className={styles.manageTitleText}>{t("manage_opportunities")}</div>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        className={styles.createTop}
                    >
                        <Button variant="contained"
                                color="primary"
                                disabled={!canCreateOpportunity}
                                onClick={handleOpportunityCreate}
                        >
                            {canCreateOpportunity ? t('create_an_opportunity') : t('upgrade_package')}
                        </Button>
                        {!!opportunityStatus && <div className={styles.opportunityCounter}>{opportunityStatus}</div>}
                    </Box>
                </Box>

                <Box
                    width={'100%'}
                    maxWidth={'1200px'}
                    marginTop={5}
                >
                    <Grid
                        container
                        spacing={6}
                    >
                        {opportunities.map(opportunity => {
                            return (
                                <Grid item xs={12} md={6} lg={6}>
                                    <JobPreview
                                        opportunity={opportunity}
                                        club={club}
                                        onDelete={() => handleDelete(opportunity)}
                                        onClick={() => handleOpportunityClick(opportunity)}
                                        onChangeStatus={(newStatus: string) => onChangeOpportunityStatus(opportunity, newStatus)}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>

            </React.Fragment>
        )
    }

    const showLoaders = () => {
        let loaders = [...Array(3)]
        return (
            <React.Fragment>
                <Box marginTop={2} marginBottom={4}>
                    <Typography className={styles.manageTitleText}>{t("manage_opportunities")}</Typography>
                </Box>
                <Grid
                    container
                    spacing={6}
                >
                    {loaders.map(_ => {
                        return (
                            <Grid item xs={12} md={6} lg={6}>
                                <JobPreviewLoader/>
                            </Grid>
                        )

                    })}
                </Grid>
            </React.Fragment>
        )
    }

    const renderContent = () => {
        if (loading) {
            return showLoaders()
        } else {
            if (opportunities?.length) {
                return showOpportunities()
            } else {
                return showNoOpportunities()
            }
        }
    }

    const renderPopups = () => {
        return (
            <React.Fragment>
                <EditClubNotPossiblePopup open={notAllowedPopupOpen} onClose={() => setNotAllowedPopupOpen(false)}/>
            </React.Fragment>
        )
    }

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={styles.component}
        >
            <SubscriptionWarning/>
            <Countdown/>
            {renderContent()}
            {renderPopups()}

        </Box>
    )

    //endregion UI
}

export default JobsClub
