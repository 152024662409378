import React, {useEffect} from 'react'
import {Box, Card, CircularProgress, makeStyles, Typography} from "@material-ui/core"
import {useHistory, useParams} from "react-router"
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {useItemData} from "../../../util/data/useItemData";
import Job from "../../../models/job.model";
import OpportunityService from "../../../services/opportunity.service";
import {useTabs} from "../../../util/data/useTabs";
import {useBreadcrumbs} from "../../../util/data/useBreadcrumbs";
import {getOpportunityClubName} from "../../../util/opportunity.util";
import Overview from "./Overview.component";
import Applicants from "./Applicants.component";
import {ApplicationStatus} from "../../../models/opportunityApplication.model";
import CreateJob from "./createJob/CreateJob.component";
import {LocationType} from "../../../models/locationType.enum";
import {isPhAdmin} from "../../../util/profile.util";
import AuthService from "../../../services/auth.service";
import {useUser} from "../../../store/store.utils";
import PeopleIcon from '@material-ui/icons/People';
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import GradeIcon from '@material-ui/icons/Grade';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
// import {initFetchApplications} from "../../../store/actions/applicationsActions";
import {useDispatch} from "react-redux";
import MixpanelService from "../../../services/analytics/mixpanel.service";
import {ScreenName} from "../../../services/analytics/screenName";
import {EventProperty} from "../../../services/analytics/eventProperty";
import {PeopleProperty} from "../../../services/analytics/peopleProperty";

const useStyles = makeStyles({});


interface Params {
    id: string
}

const JobDetails = () => {

    //region State

    const params = useParams<Params>()
    const id = params.id
    const history = useHistory()
    const {t} = useTranslation()
    const isNew = id === 'new'
    const classes = useStyles();
    const user = useUser()
    let jobClub = isPhAdmin(user) ? undefined : AuthService.getAdminClub(user)
    const dispatch = useDispatch()

    const itemData = useItemData<Job>({
        get: async () => isNew ? {
            club: jobClub,
            jobType: '',
            gender: 'MALE',
            fromLevel: 3,
            toLevel: 5,
            fromAge: 13,
            toAge: 20,
            positionMode: 'positions',
            positions: [],
            teams: [],
            fromSalary: 0,
            toSalary: 0,
            anonymous: false,
            preferredFoot: 'BOTH',
            skillNeeded: false,
            minHeight: 0,
            locationType: LocationType.Local,
            location: {
                radius: 0,
            },
            nationalities: [],
            languages: [],
            opportunityStatus: 'NOT_APPROVED',
        } as Job : OpportunityService.getOpportunity(+id),
        // isValid: item => !!item?.profile,
        deps: [id],
        update: async (id1, item) => {
            const response = await OpportunityService.updateOpportunity(item)
            return response
        },
        // key: ['job', id]
    })

    useEffect(() => {
        if (!id) return;

        // dispatch(initFetchApplications(ApplicationStatus.CREATED, +id!, 0))
        // dispatch(initFetchApplications(ApplicationStatus.INVITED, +id!, 0))
        // dispatch(initFetchApplications(ApplicationStatus.SHORTLISTED, +id!, 0))
        // isPhAdmin(user) && dispatch(initFetchApplications(ApplicationStatus.REJECTED, +id!, 0))

        if (!isPhAdmin(user)) {
            if (isNew) {
                MixpanelService.trackScreenVisit(ScreenName.CREATE_JOB)
            } else {
                MixpanelService.trackScreenVisit(ScreenName.VIEW_APPLICANTS, {
                    [EventProperty.OPPORTUNITY_ID]: id
                })
                MixpanelService.setUserProperties({
                    [PeopleProperty.LAST_APPLICANTS_REVIEW]: MixpanelService.getCurrentTimeIso()
                })
                MixpanelService.incrementProperty(PeopleProperty.TOTAL_APPLICANTS_REVIEWED, 1)
            }
        }

    }, [id]);


    const handleSave = async () => {
        try {
            itemData.setSaving(true)

            if (!_.isEqual(itemData.originalItem, itemData?.item)) {
                await OpportunityService.updateOpportunity(itemData?.item!)
            }

            const updatedJob: Job = {
                ...itemData?.item,
            }
            itemData.setOriginalItem(updatedJob)
            itemData.setItem(updatedJob)
        } catch (e) {
            console.log(e)
        } finally {
            itemData.setSaving(false)
        }
    }

    const tabs = [
        {icon: <PeopleIcon/>, label: t('All applicants')},
        {icon: <GradeIcon/>, label: t('Top pick applicants')},
        {icon: <PhoneCallbackIcon/>, label: t('Invited applicants')},
        {icon: <NotListedLocationIcon/>, label: t('Other applicants')},
    ]

    if (isPhAdmin(user)) {
        tabs.push({icon: <ThumbDownIcon/>, label: t('Rejected applicants')})
        tabs.push({icon: <InfoOutlinedIcon/>, label: t('Job info')})
    }

    const tabsData = useTabs({
        tabs: tabs,
        showDelete: false,
        showSave: itemData.isDirty,
        canSave: itemData.isValid,
        onSave: handleSave,
        onDelete: itemData.remove,
        showButtonLoaders: itemData.saving,
        canDelete: false
    })

    const breadcrumbs = useBreadcrumbs({
        path: [
            {label: t('Job'), url: '/admin/job'},
            {label: isNew ? 'New' : getOpportunityClubName(itemData.item, '-')}
        ]
    })

    //endregion

    //region UI

    const jobUI = (
        <Card>
            {tabsData.renderTabs()}

            {itemData.loading && <CircularProgress/>}

            {
                !itemData.loading && tabsData.tab === 0 &&
                <Box>
                    <Applicants
                        title={t('Top pick applicants')}
                        job={itemData.item}
                        status={ApplicationStatus.SHORTLISTED}
                        noApplicantsText={'No Top Picks... Go to "Other applications" and make some players top picks...'}
                        backgroundColor={'#217748'}
                        titleColor={'white'}
                    />

                    <Applicants
                        title={t('Invited applicants')}
                        job={itemData.item}
                        status={ApplicationStatus.INVITED}
                        noApplicantsText={'No Top Picks... Go to "Other applications" and make some players top picks...'}
                        backgroundColor={'#4a4a4a'}
                        titleColor={'white'}
                    />

                    <Applicants
                        title={t('Other applicants')}
                        job={itemData.item}
                        status={ApplicationStatus.CREATED}
                        noApplicantsText={'No Invited applicants... You can invite applicants from either "Top picks" or "Other applicants" section...'}
                    />

                    {
                        isPhAdmin(user) &&
                        <React.Fragment>
                            <Box marginLeft={3}>
                                <Typography variant={'h3'}>{t('Rejected applicants')}</Typography>
                            </Box>
                            <Applicants
                                job={itemData.item}
                                status={ApplicationStatus.REJECTED}
                                noApplicantsText={'No applicants here...'}
                            />
                        </React.Fragment>
                    }

                </Box>

            }

            {
                !itemData.loading && tabsData.tab === 1 &&
                <Applicants
                    job={itemData.item}
                    status={ApplicationStatus.SHORTLISTED}
                    noApplicantsText={'No Top Picks... Go to "Other applications" and make some players top picks...'}
                />
            }

            {
                !itemData.loading && tabsData.tab === 2 &&
                <Applicants
                    job={itemData.item}
                    status={ApplicationStatus.INVITED}
                    noApplicantsText={'No Invited applicants... You can invite applicants from either "Top picks" or "Other applicants" section...'}
                />
            }

            {
                !itemData.loading && tabsData.tab === 3 &&
                <Applicants
                    job={itemData.item}
                    status={ApplicationStatus.CREATED}
                    noApplicantsText={'No applicants here...'}
                />
            }

            {
                !itemData.loading && tabsData.tab === 4 &&
                <Applicants
                    job={itemData.item}
                    status={ApplicationStatus.REJECTED}
                    noApplicantsText={'No applicants here...'}
                />
            }

            {
                !itemData.loading && tabsData.tab === 5 &&
                <Overview job={itemData.item} setJob={itemData.setItem}/>
            }


        </Card>
    )

    return (
        <Box>

            {isPhAdmin(user) ? breadcrumbs : <Box marginTop={4}/>}
            {!isNew && jobUI}
            {isNew && <CreateJob setJob={itemData.setItem} job={itemData.item}/>}
            {itemData.renderSnackbars()}

        </Box>
    )

    //endregion UI
}

export default JobDetails
