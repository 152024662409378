import React, {useEffect, useState} from "react";
// import styles from './PositionMatrix.module.scss';
import AlgorithmService from "../../services/algorithm.service";
import PositionMapModel from "../../models/positionMap.model";
import {Box, CircularProgress} from "@material-ui/core";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import ETextField from "../common/ETextField.component";
import EButton from "../common/EButton";
import {Position} from "../../models/position.model";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";


const useStyles = makeStyles(theme => ({
    valueContainer: {
        flex: 1,
        border: '1px solid rgba(0, 0, 0, 0.05)',
        width: 'calc(100% / 13)',
        height: '40px',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    container: {
        width: 'calc(100% - 20px)',
        maxWidth: '1000px',
        padding: '16px',
        marginBottom: '100px',
        // minHeight: '100px',
    },
    valueInput: {
        width: '100%',
        border: 'none',
    },
    table: {
        width: '100%'
    },
    row: {
        width: '100%'
    },
    // phContainer: {
    //     // backgroundColor: '#FFFFFF',
    //     // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.02)',
    //     // borderRadius: '9px',
    //     //overflow: hidden;
    // },
    userPositionName: {
        minWidth: '100px'
    },
    positionName: {
        fontWeight: 'bold'
    },
    opportunityTitle: {
        marginBottom: '20px',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    playerTitle: {
        marginRight: '20px',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    updateButton: {
        marginTop: '30px !important',
}

}))

interface Props {
    algorithm: 'feed' | 'applicant'
}

const PositionMatrix = (props: Props) => {

    // ***** State ***** //

    const PositionsList = [
        Position.Goalkeeper,
        Position.LeftFullBack,
        Position.CentralDefender,
        Position.RightFullBack,
        Position.DefensiveMidfield,
        Position.LeftMidfield,
        Position.CentralMidfield,
        Position.RightMidfield,
        Position.AttackingMidfield,
        Position.LeftWing,
        Position.Forward,
        Position.RightWing
    ];

    const [matrix, setMatrix] = useState<PositionMapModel[]>([]);
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const [mapDirty, setMapDirty] = useState(false);

    const t = useTranslation().t;
    const classes = useStyles()


    // ***** Effects ***** //

    const fetchMatrix = async () => {
        let matrix = [];
        switch (props.algorithm) {
            case "feed":
                matrix = await AlgorithmService.getPositionMatrixForOpportunityFeed();
                break;
            case "applicant":
                matrix = await AlgorithmService.getPositionMatrixApplicants();
                break;

        }

        setMatrix(matrix);
    };

    useEffect(() => {
        fetchMatrix();
    }, []);

    // ***** Handlers ***** //

    const getValueOnPosition = (matrix: PositionMapModel[], opportunityPosition: Position, userPosition: Position) => {
        return matrix.find(value => value.opportunityPosition === opportunityPosition && value.userPosition === userPosition)
    }

    const onUpdateMatrix = async () => {
        setUpdateInProgress(true);

        const newMatrix = _.cloneDeep(matrix);

        for (let i = 0; i < PositionsList.length; i++) {
            for (let j = 0; j < PositionsList.length; j++) {
                const userPosition = PositionsList[i];
                const opportunityPosition = PositionsList[j];

                const newPositionObject = getValueOnPosition(newMatrix, opportunityPosition, userPosition) || {};
                let newValue = parseInt(`${newPositionObject?.value}`);
                if (isNaN(newValue)) {
                    newValue = 0;
                }
                newPositionObject.value = newValue;

                if (newPositionObject.value !== getValueOnPosition(matrix, opportunityPosition, userPosition)?.value) {
                    switch (props.algorithm) {
                        case "feed":
                            await AlgorithmService.updatePositionMatrixForOpportunityFeed(newPositionObject);
                            break;
                        case "applicant":
                            await AlgorithmService.updatePositionMatrixApplicant(newPositionObject);
                            break;

                    }
                }
            }
        }

        setMatrix(newMatrix);
        setMapDirty(false);
        setUpdateInProgress(false);
    };

    const updatePositionValue = (userPosition: Position, opportunityPosition: Position, value: any) => {
        const newMatrix = _.cloneDeep(matrix);
        const valueObject = getValueOnPosition(newMatrix, opportunityPosition, userPosition) || {};
        valueObject.value = value;
        setMatrix(newMatrix);
        setMapDirty(true);
    };

    // ***** Render ***** //

    const renderValue = (userPosition: Position, opportunityPosition: Position) => {
        return (
            <Box
                className={classes.valueContainer}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <input
                    className={classes.valueInput}
                    value={getValueOnPosition(matrix, opportunityPosition, userPosition)?.value || ''}
                    onChange={(event) => updatePositionValue(userPosition, opportunityPosition, event.target.value)}
                />
            </Box>
        )
    };

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            className={classes.container}
        >
            <div
                className={classes.opportunityTitle}
            >Opportunity
            </div>

            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
            >

                <div className={classes.playerTitle}>Player</div>

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    className={classes.table}
                >
                    <Box
                        display={'flex'}
                        className={classes.row}
                    >
                        <div
                            className={classes.valueContainer}
                        />
                        {
                            PositionsList.map(position =>
                                <Box
                                    display={'flex'}
                                    className={clsx(classes.valueContainer, classes.positionName)}
                                    alignItems={'center'}
                                >
                                    <div>{t(`position_${position}_short`)}</div>
                                </Box>)
                        }
                    </Box>

                    {
                        PositionsList.map(userPosition => {
                            return (
                                <Box
                                    display={'flex'}
                                    className={classes.row}
                                >
                                    <Box
                                        display={'flex'}
                                        className={clsx(classes.valueContainer, classes.positionName)}
                                        alignItems={'center'}

                                    >
                                        {t(`position_${userPosition}_short`)}
                                    </Box>

                                    {
                                        PositionsList.map(opportunityPosition => renderValue(userPosition, opportunityPosition))
                                    }
                                </Box>
                            )
                        })
                    }
                </Box>
            </Box>

            {
                updateInProgress ?
                    <CircularProgress/> :
                    <EButton variant="contained" color="primary"
                        className={classes.updateButton}
                             onClick={onUpdateMatrix} disabled={!mapDirty}>
                        Update matrix
                    </EButton>
            }

        </Box>
    );
};

export default PositionMatrix;
