import {Box, Typography} from "@material-ui/core";
import ETextField from "../../common/ETextField.component";
import React from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import SubscriptionConfigAdmin from "../../../models/subscriptionConfigAdmin.model";
import ESwitchField from "../../common/ESwitchField.component";
import CountrySelector from "../../common/CountrySelector.component";
import {
    addCountries,
    eeaChCountryIds,
    euCountryIds,
    getCountriesByIds,
    removeCountries
} from "../../../services/countries";
import {Alert} from "@material-ui/lab";


interface Props {
    config?: SubscriptionConfigAdmin,
    setConfig: (config: SubscriptionConfigAdmin) => void,
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
    input: {
        width: '100%'
    }
}))

const PlayerConfiguration = ({config, setConfig}: Props) => {


    const classes = useStyle()
    const {t, i18n} = useTranslation()

    const appleReviewVersionUI = (
        <React.Fragment>
            <Alert
                severity={'warning'}
            >
                iOS - App build version for which B2C is always active. This should be always set to a version that
                is on Apple review
                and removed when it's not anymore. This way, Apple reviewers always see B2C no matter where they are
                from...
                Do not touch this unless you are dev!
            </Alert>

            <ETextField
                grid={'1/3'}
                onChange={event => {
                    setConfig({
                        ...config,
                        allowedAppleVersion: event
                    });
                }}
                value={config?.allowedAppleVersion}
                // className={styles.textInput}
            />
        </React.Fragment>
    )

    const androidReviewVersionUI = (

        <React.Fragment>
            <Alert
                severity={'warning'}
            >
                Android - App build version for which B2C is always active. This should be always set to a version
                that is on Google review
                and removed when it's not anymore. This way, Google reviewers always see B2C no matter where they
                are from...
                Do not touch this unless you are dev!
            </Alert>

            <ETextField
                grid={'1/3'}
                onChange={event => {
                    setConfig({
                        ...config,
                        allowedGoogleVersion: event
                    });
                }}
                value={config?.allowedGoogleVersion}
                // className={styles.textInput}
            />

        </React.Fragment>
    );

    const handleAddCountry = (country: any) => {
        let countriesToAdd;
        if (country.countryCode === 'eu' || country.countryCode === 'eeach') {
            countriesToAdd = country.countryCode === 'eu' ? getCountriesByIds(euCountryIds) : getCountriesByIds(eeaChCountryIds);
        } else {
            countriesToAdd = [country];
        }

        const updatedCountries = addCountries(config?.activeIn || [], countriesToAdd);
        setConfig({
            ...config,
            activeIn: updatedCountries
        })
    };

    const handleRemoveCountry = (country: any) => {
        let countriesToRemove;
        if (country.countryCode === 'eu') {
            countriesToRemove = getCountriesByIds(euCountryIds);
        } else if (country.countryCode === 'eeach') {
            countriesToRemove = getCountriesByIds(eeaChCountryIds);
        } else {
            countriesToRemove = [country]
        }

        const updatedCountries = removeCountries(config?.activeIn || [], countriesToRemove);

        setConfig({
            ...config,
            activeIn: updatedCountries
        })
    };

    const countrySelector = (
        <React.Fragment>
            <p>Countries where B2C is active. In countries where B2C is not active, users
                can do everything, as before</p>
            <CountrySelector
                countries={config?.activeIn}
                onAddCountry={handleAddCountry}
                onRemoveCountry={handleRemoveCountry}
                addTitle={'Add country'}
            />
        </React.Fragment>
    );


    const textField = (
        field: 'maxApplicationsOnFree' | 'freeApplicationsRenewalDays' | 'blurEveryNth' | 'trialPeriod',
        title: string,
        type: 'text' | 'numeric'
    ) => {

        const update = (value: number | string) => {
            setConfig({
                ...config,
                [field]: value
            })
        }

        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
            >

                <Box
                    display={'flex'}
                    flexDirection={'column'}>
                    <p
                        // className={styles.leftConfig}
                    >
                        {title}
                    </p>

                    <ETextField
                        onChange={event => {
                            switch (type) {
                                case "text":
                                    update(event)
                                    break;
                                case "numeric":
                                    if (!event) {
                                        update(0);
                                    } else {
                                        const value = parseInt(event);
                                        if (!isNaN(value)) {
                                            update(value);
                                        } else {
                                            update(0);
                                        }
                                    }
                                    break;

                            }
                        }}
                        value={config?.[field]}
                        label={''}
                        // className={styles.textInput}
                        grid={'1/3'}/>

                </Box>
            </Box>
        )
    }

    const booleanField = (
        field: 'tryToConvertAfterOnboarding' | 'freeUsersCanApplyForPremiumJobs',
        title: string,
    ) => {
        const update = (value: boolean) => {
            setConfig({
                ...config,
                [field]: value
            })
        }

        return (
            <Box
                // column
                // className={clsx('ph-container', styles.container)}
                // hAlignContent='left'
                display={'flex'}
                flexDirection={'column'}
            >

                <Box
                    // vAlignContent='center'
                    // wrap
                    // className={styles.row}
                >
                    <p
                        // className={styles.leftConfig}
                    >{title}</p>

                    <ESwitchField
                        value={config?.[field]}
                        onChange={event => update(event)}
                        grid={'1/3'}/>
                </Box>
            </Box>
        )
    }


    const maxApplicationsOnFree = () => {
        return textField('maxApplicationsOnFree', 'How many times can a FREE user apply before getting locked?', "numeric");
    };

    const renewalDays = () => {
        return textField('freeApplicationsRenewalDays', 'What is the time period to "reset" lock state and let FREE user apply again? In days. Example -> Reset perod is 10 days, user gets 3 applications per period, user registers on 1.9 => 1.9 - 10.9 can apply 3 times, 11.9 - 20.9. can apply 3 times, etc...', "numeric");
    };

    const blurUI = () => {
        return textField('blurEveryNth', 'Blue one in N jobs for FREE users. For example, if number is set to 7, one in 7 jobs will be blurred', "numeric");
    };

    const tryToConvertAfterOnboarding = () => {
        return booleanField('tryToConvertAfterOnboarding', 'Are we showing the premium offer popup immediately after basic onboarding is done?');
    }

    const freeUsersCanApplyForPremiumJobs = () => {
        return booleanField('freeUsersCanApplyForPremiumJobs', 'Can FREE users apply for premium jobs?');
    }


    return (
        <Box
            p={3}
            display={'flex'}
            flexDirection={'column'}
        >
            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("General config")}
            </Typography>

            <Box>
                {countrySelector}
                <Box height={30} />
                {appleReviewVersionUI}
                {androidReviewVersionUI}
            </Box>

            <Box height={50} />

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("App config")}
            </Typography>

            <Box>

                {maxApplicationsOnFree()}
                {renewalDays()}
                {blurUI()}
                {tryToConvertAfterOnboarding()}
                {freeUsersCanApplyForPremiumJobs()}
            </Box>

        </Box>
    )
}


export default PlayerConfiguration
