import React, {useCallback, useEffect, useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@material-ui/core"
import debounce from "lodash/debounce"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";
import ETextField from "../../ETextField.component";

const ClubNameFilter = () => {

    //region State

    const [clubNameParam] = useUrlParam({param: 'clubName', dataType: 'string', defaultValue: ''})

    const [clubNameLocal, setClubNameLocal] = useState(clubNameParam)
    const [manualInput, setManualInput] = useState(false)

    const [expanded, setExpanded] = useState(!!clubNameLocal)
    const history = useHistory()

    //endregion State

    //region Handlers

    const executeSet = useCallback(debounce((clubName: any) => {
        setMultipleParams([
            {name: 'clubName', value: clubName, dataType: 'string'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }, 1000), [])

    useEffect(() => {
        if (!manualInput) return
        executeSet(clubNameLocal || '')
    }, [clubNameLocal, manualInput])

    //endregion Handlers

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="clubName-content"
                id="clubName-header"
            >
                <Typography>Club name {!!clubNameLocal && `(${clubNameLocal})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container spacing={1}>
                    <ETextField
                        label={'Club name'}
                        grid={'1/1'}
                        value={clubNameLocal}
                        onChange={e => {
                            setManualInput(true)
                            setClubNameLocal(e)
                        }}
                    />
                </Grid>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default ClubNameFilter
