import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Box, Card, Grid} from "@material-ui/core";
import {AuthCommon, useAuthCommonStyles} from "./auth.common.";
import React, {useEffect} from "react";
import AnimatedBox from "../landing/Animated.component";
import MixpanelService from "../../services/analytics/mixpanel.service";
import {ScreenName} from "../../services/analytics/screenName";

const useStyle = makeStyles(_theme => ({
    component: {
        marginTop: 200,
        width: '100%',
        position: 'relative',
        [_theme.breakpoints.down('md')]: {
            marginTop: 100
        }
    },
    containerHolder: {
       // zIndex: 1,
        width: 'calc(100% - 40px)',
        maxWidth: 650,
        marginBottom: 'auto'
    },
    container: {
        width: '100%',
        padding: 15,
        [_theme.breakpoints.up('md')]: {
            padding: 20,
        }
    },
    done: {
        marginTop: 20
    },

}))

const DownloadApp = () => {
    //region State

    const classes = useStyle()
    const commonClasses = useAuthCommonStyles()
    const t = useTranslation().t


    useEffect(() => {
        MixpanelService.trackScreenVisit(ScreenName.DOWNLOAD_APP)
    }, [])


    const formUI = (
        <Card className={classes.containerHolder}>
            <Box
                className={classes.container}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                boxSizing={'border-box'}
            >
                <Grid container justify={'center'} spacing={7}>

                    <Grid item>
                        <a href={'https://apps.apple.com/us/app/playerhunter/id1151813385'} target={'_blank'}>
                            <AnimatedBox
                                fadeIn
                                fadeInThreshold={0}
                            >
                                <img src={require('../../assets/images/landing/app-store.svg').default}/>
                            </AnimatedBox>
                        </a>
                    </Grid>

                    <Grid item>
                        <a href={'https://play.google.com/store/apps/details?id=com.playerhunter&hl=en'} target={'_blank'}>
                            <AnimatedBox
                                fadeIn
                            >
                                <img src={require('../../assets/images/landing/google-play.svg').default}/>
                            </AnimatedBox>
                        </a>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    )

    return (
        <Box
            className={commonClasses.component}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
        >
            <AuthCommon title={t("download_app")}/>
            {formUI}
        </Box>
    )


}

export default DownloadApp
