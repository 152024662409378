import UserModel from "./user.model";
import Page from "./page.model";
import Team from "./team.model";
import {SeasonModel} from "./season.model";
import {LocationType} from "./locationType.enum";
import CountryModel from "./country.model";
import CityModel from "./city.model";
import ClubModel from "./club.model";
import LanguageModel from "./language.model";
import {GeocodeResultsRaw} from "./geocodeResult.model";
import {CityLocation} from "./cityLocation.model";

export const YOUTH_MIN_AGE = 13
export const YOUTH_MAX_AGE = 22
export const SENIOR_MIN_AGE = 17
export const SENIOR_MAX_AGE = 40

export enum JobStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    NOT_APPROVED = "NOT_APPROVED"
}

export default interface Job {
    id?: number;
    deleted?: boolean;

    // Basic

    gender?: string;

    fromAge?: number;
    toAge?: number;

    fromLevel?: number;
    toLevel?: number;

    teams?: Team[];

    // salary?: string;
    fromSalary?: number
    toSalary?: number

    anonymous?: boolean;

    positionMode?: string;
    positions?: string[];
    section?: string;

    opportunityStatus?: JobStatus;

    // More

    nationalities?: CountryModel[];
    languages?: LanguageModel[];

    minHeight?: number;

    preferredFoot?: string;

    skillNeeded?: boolean;

    transferPeriod?: SeasonModel;

    // Location

    locationType?: LocationType;

    location?: {
        id?: number;
        radius?: number;
        city?: CityModel | null;
        country?: CountryModel[];
        googleGeolocation?: GeocodeResultsRaw[] | null;
        location?: CityLocation | null;
    }

    date?: string;

    applicants?: Page<UserModel>;
    jobType?: string;

    club?: ClubModel;
    clubRequest?: ClubModel;
    agent?: UserModel

    createdAt?: Date;
    updatedAt?: Date;

    premium?: boolean;

    requirements?: {
        id?: number,
        name?: string
    }[]

    minInstatIndex?: number
    minPhIndex?: number
    minMinutesPercentage?: number,
    minGoals?: number,
    minAssists?: number,
    transferFee?: number,
    titleOverride?: string
    jobCreatedByAgent?: boolean
    logoOverride?: string
    minTmValue?: number
}
