import React, {useEffect, useState} from 'react'
import styles from './Billing.module.scss'
import {useStore} from "react-redux"
import {Box, Button, Card, CircularProgress, Dialog, TextField} from "@material-ui/core"
import {useTranslation} from "react-i18next"
import SubscriptionService from "../../../services/subscription.service"
import {StripeProduct, StripeProductPrice} from "../../../models/StripeModels/stripeProduct.model"
import {useStripe} from "@stripe/react-stripe-js"
import {StripePromoCodeModel} from "../../../models/StripeModels/stripeCustomer.model"
import {makeStyles} from "@material-ui/styles"
import {StripeSubscriptionStatus} from "../../../models/StripeModels/stripeSubscription.model";
import {SET_SUBSCRIPTION} from "../../../store/actions/actionTypes";
import {useUser} from "../../../store/store.utils";
import AuthService from "../../../services/auth.service";

interface Props {
    open: boolean,
    onDone: (subscribed: boolean) => void,
    product?: StripeProduct,
    price?: StripeProductPrice
}

const useStyle = makeStyles({
    container: {
        width: '100%'
    },
    title: {
        margin: '20px 20px 0 20px',
        textAlign: 'center',
        fontSize: 20
    },
})

const SubscribeTo = (props: Props) => {

    //region State

    const [subscribeInProgress, setSubscribeInProgress] = useState(false)
    const [promoCodeCode, setPromoCodeCode] = useState('')
    const [promoCode, setPromoCode] = useState<StripePromoCodeModel>()
    const [promoCodeState, setPromoCodeState] = useState<'' | 'loading' | 'error'>('')
    const store = useStore()
    const stripe = useStripe()
    const t = useTranslation().t
    const classes = useStyle()
    const user = useUser()
    const club = AuthService.getAdminClub(user)

    const trialDays = props?.product?.metadata?.trialLengthDays || 0
    const hasTrial = trialDays > 0

    //endregion State

    //region Handlers

    useEffect(() => {
        if (club && props.open) {
            setSubscribeInProgress(false)
        }
    }, [user, props.open])

    const handleAddPromoCode = async () => {
        setPromoCodeState('loading')
        const promoCode = await SubscriptionService.getStripePromoCodes(club, promoCodeCode)
        if (promoCode?.length) {
            setPromoCode(promoCode[0])
            setPromoCodeState('')
            setPromoCodeCode('')
        } else {
            setPromoCodeState('error')
        }
    }

    const onPurchase = async () => {
        if (!props?.product || !props?.price) return

        setSubscribeInProgress(true)
        let subscribed = false
        let stripeSubscirption
        stripeSubscirption = await SubscriptionService.purchaseStripeSubscription(club!, props?.price, promoCode)

        if (stripeSubscirption?.status === StripeSubscriptionStatus.INCOMPLETE) {
            const invoice = await SubscriptionService.getStripeInvoice(club, stripeSubscirption.latestInvoice?.id!)
            const response = await stripe?.confirmCardPayment(invoice?.paymentIntent?.clientSecret!, {
                return_url: window.location.href
            })
            if (response?.paymentIntent?.status === 'succeeded') {
                subscribed = true
            }
        } else {
            subscribed = true
        }

        if (subscribed && stripeSubscirption && stripeSubscirption.currentPeriodStart && stripeSubscirption.currentPeriodEnd) {
            props.onDone(true)
        } else {
            props.onDone(false)
        }
    }

    // const onClose = () => {
    //     props.onDone(false)
    // }

    //endregion Handlers

    //region UI

    const renderPromoCode = () => {
        if (promoCode) {
            return (
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    className={styles.packageHolder}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                    >
                        <div className={styles.promoCodeTitle}>{t('Promo code')}</div>
                        <div className={styles.promoCodeCode}>{promoCode.code}</div>
                        {
                            promoCode.coupon?.amountOff ?
                                <div
                                    className={styles.promoCodeAmount}>{promoCode.coupon?.amountOff / 100} {promoCode.coupon.currency?.toUpperCase()} off</div> :
                                <div className={styles.promoCodeAmount}>{promoCode.coupon?.percentOff}% off</div>
                        }
                    </Box>

                    <Button
                        color={'primary'}
                        className={styles.promoCodeAddButton}
                        onClick={() => setPromoCode(undefined)}
                    >
                        {t('Delete')}
                    </Button>

                </Box>
            )
        } else {
            return (
                <React.Fragment>
                    <Box
                        display={'flex'}
                        alignItems={'flex-end'}
                        className={styles.packageHolder}
                    >
                        <TextField
                            onChange={e => setPromoCodeCode(e.target.value)}
                            value={promoCodeCode}
                            className={styles.promoCodeInput}
                            label={t("Add promo code")}
                            margin="normal"
                        />

                        {
                            promoCodeState === 'loading' ? <CircularProgress/> :
                                <Button
                                    disabled={!promoCodeCode}
                                    color={'primary'}
                                    variant={'contained'}
                                    className={styles.promoCodeAddButton}
                                    onClick={handleAddPromoCode}
                                >
                                    {t('Add')}
                                </Button>
                        }

                    </Box>

                    {
                        promoCodeState === 'error' &&
                        <div className={styles.promoCodeError}>{t('Promo code not found')}</div>
                    }

                </React.Fragment>
            )
        }
    }

    const renderPackage = () => {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                className={styles.packageInfoHolder}>

                {
                    hasTrial &&
                    <Box className={styles.packageHolder}>
                        <div className={styles.packageInfo}>{t('Trial')}</div>
                        <div className={styles.packageCurrency}>
                            {t('Trial days', {days: trialDays})}
                        </div>
                    </Box>
                }

                <Box className={styles.packageHolder}>
                    <div className={styles.packageInfo}>{hasTrial ? t('Price (after trial ends)') : t('Price')}</div>
                    <div className={styles.packageCurrency}>
                        {((props?.price?.unitAmount || 0) / 100).toFixed(2)} {props?.price?.currency?.toUpperCase()}
                    </div>
                </Box>

                <Box className={styles.packageHolder}>
                    <div className={styles.packageInfo}>{t('Period')}</div>
                    <div className={styles.packageCurrency}>
                        {t(`Subscription period ${props?.price?.recurring?.interval}`)}
                    </div>
                </Box>
                <div className={styles.autorenewMessage}>{t('Package auto-renews')}</div>
                <div className={styles.autorenewMessage}>{t('Min contract period 12 months')}</div>
            </Box>
        )
    }

    const renderTotalPrice = () => {
        let fullPrice = (props?.price?.unitAmount || 0) / 100

        if (promoCode) {
            if (promoCode.coupon?.amountOff) {
                fullPrice = fullPrice - (promoCode.coupon.amountOff / 100)
            } else if (promoCode.coupon?.percentOff) {
                fullPrice = fullPrice * (1 - promoCode.coupon.percentOff / 100)
            }
        }

        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                className={styles.packageInfoHolder}
            >
                {/*<FlexView className={styles.packageHolder} hAlignContent={'center'}>*/}
                {/*    <div className={styles.packageTitle}>{product?.name} {t('package')}</div>*/}
                {/*</FlexView>*/}

                {
                    hasTrial ?
                        <Box className={styles.packageHolder}>
                            <div className={styles.packageInfo}>{t('Total now')}</div>
                            <div className={styles.packageCurrency}>0</div>
                        </Box>
                        :
                        <Box className={styles.packageHolder}>
                            <div className={styles.packageInfo}>{t('Total now')}</div>
                            <div className={styles.packageCurrency}>
                                {fullPrice.toFixed(2)} {props?.price?.currency?.toUpperCase()}
                            </div>
                        </Box>
                }

                {/*{*/}
                {/*    hasTrial &&*/}
                {/*    <FlexView className={styles.packageHolder}>*/}
                {/*        <div className={styles.packageInfo}>{t('Due in trial days', {days: trialDays})}</div>*/}
                {/*        <div className={styles.packageCurrency}>*/}
                {/*            {fullPrice.toFixed(2)} {props?.price?.currency?.toUpperCase()}*/}
                {/*        </div>*/}
                {/*    </FlexView>*/}
                {/*}*/}

            </Box>
        )
    }

    return (
        <Dialog open={props.open}>
            <Card>
                <Box p={2}>

                    <div className={classes.title}>
                        {t('Subscribe')} {props?.product ? ` - ${props?.product.name}` : ''}
                    </div>

                    {renderPackage()}

                    {renderPromoCode()}

                    {renderTotalPrice()}

                    {
                        subscribeInProgress ?
                            <CircularProgress/>
                            :
                            <Box display={'flex'} justifyContent={'space-between'}>
                                <Button onClick={() => props.onDone(false)}>
                                    {t('Cancel')}
                                </Button>
                                <Button color="primary" variant='contained' onClick={() => onPurchase()}>
                                    {hasTrial ? t('Start now') : t('Subscribe')}
                                </Button>
                            </Box>

                    }
                </Box>
            </Card>
        </Dialog>
    )

    //endregion UI
}

export default SubscribeTo
