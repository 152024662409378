import React from "react";
// import FlexView from "react-flexview/lib/FlexView";
// import styles from './AlgoBug.module.scss';
// import AlgoBugOpportunityFeed from "./opportunityFeed/OpportunityFeed";
// import AlgoBugApplicants from "./applicants/Applicants";
// import {useRouter} from "next/router";
import {Box, Card} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import AssignmentIcon from "@material-ui/icons/Assignment";
import {useTabs} from "../../util/data/useTabs";
import {useBreadcrumbs} from "../../util/data/useBreadcrumbs";
import OpportunityFeedAlgoBug from "./OpportunityFeedAlgoBug.component";
import NotificationsIcon from '@material-ui/icons/Notifications';
import JobNotificationChecker from "./JobNotificationChecker.component";

const AlgoBug = () => {

    // region state

    const {t} = useTranslation()

    // endregion state

    const tabs = [
        {icon: <AssignmentIcon/>, label: t('Opportunity feed')},
        {icon: <NotificationsIcon/>, label: t('Notification checker')},
    ]

    const tabsData = useTabs({
        tabs: tabs,
        showDelete: false,
        canDelete: false,
        showSave: false,
        canSave: false,
    })

    const breadcrumbs = useBreadcrumbs({
        path: [
            {label: t('AlgoBug')}
        ]
    })

    return (
        <Box>

            {breadcrumbs}

            <Card>
                {tabsData.renderTabs()}


                {tabsData.tab === 0 && <OpportunityFeedAlgoBug/>}

                {tabsData.tab === 1 && <JobNotificationChecker />}

            </Card>


        </Box>
    );
}

export default AlgoBug;
