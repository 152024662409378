import React, {useCallback, useEffect, useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@material-ui/core"
import debounce from "lodash/debounce"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";
import ETextField from "../../ETextField.component";

const AgencyNameFilter = () => {

    //region State

    const [agencyNameParam] = useUrlParam({param: 'agencyName', dataType: 'string', defaultValue: ''})

    const [agencyNameLocal, setAgencyNameLocal] = useState(agencyNameParam)
    const [manualInput, setManualInput] = useState(false)

    const [expanded, setExpanded] = useState(!!agencyNameLocal)
    const history = useHistory()

    //endregion State

    //region Handlers

    const executeSet = useCallback(debounce((agencyName: any) => {
        setMultipleParams([
            {name: 'agencyName', value: agencyName, dataType: 'string'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }, 1000), [])

    useEffect(() => {
        if (!manualInput) return
        executeSet(agencyNameLocal || '')
    }, [agencyNameLocal, manualInput])

    //endregion Handlers

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="agencyName-content"
                id="agencyName-header"
            >
                <Typography>Agency name {!!agencyNameLocal && `(${agencyNameLocal})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container spacing={1}>
                    <ETextField
                        label={'Agency name'}
                        grid={'1/1'}
                        value={agencyNameLocal}
                        onChange={e => {
                            setManualInput(true)
                            setAgencyNameLocal(e)
                        }}
                    />
                </Grid>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default AgencyNameFilter