import {
    ADD_TO_APPLICATIONS_IN_PROGRESS,
    ADD_TO_SELECTED_FOR_EXPORT,
    LOGOUT,
    REMOVE_FROM_APPLICATIONS_IN_PROGRESS,
    REMOVE_FROM_SELECTED_FOR_EXPORT,
    SET_APPLICATION,
    SET_APPLICATION_LOADING,
    SET_SELECTED_FOR_EXPORT
} from "../actions/actionTypes";
import OpportunityApplicationModel, {ApplicationStatus} from "../../models/opportunityApplication.model";
import {GeneralSet} from "../../components/common/generalSet";

export interface ApplicationsState {

    // [ApplicationStatus.SHORTLISTED]: {
    //     applications: OpportunityApplicationModel[],
    //     applicationsLoading: false,
    //     applicationsHasMore: false,
    //     applicationsPage: number,
    // },
    //
    // [ApplicationStatus.INVITED]: {
    //     applications: OpportunityApplicationModel[],
    //     applicationsLoading: false,
    //     applicationsHasMore: false,
    //     applicationsPage: number,
    // },
    //
    // [ApplicationStatus.CREATED]: {
    //     applications: OpportunityApplicationModel[],
    //     applicationsLoading: false,
    //     applicationsHasMore: false,
    //     applicationsPage: number,
    // },
    //
    // [ApplicationStatus.REJECTED]: {
    //     applications: OpportunityApplicationModel[],
    //     applicationsLoading: false,
    //     applicationsHasMore: false,
    //     applicationsPage: number,
    // }
    //
    // [ApplicationStatus.SKIPPED]: {
    //     applications: OpportunityApplicationModel[],
    //     applicationsLoading: false,
    //     applicationsHasMore: false,
    //     applicationsPage: number,
    // }

    applicationsWithActionInProgress: OpportunityApplicationModel[],
    selectedForExport: GeneralSet<OpportunityApplicationModel>,

    application?: OpportunityApplicationModel,
    applicationLoading?: boolean
}

const initialState: ApplicationsState = {
    // [ApplicationStatus.SHORTLISTED]: {
    //     applications: [],
    //     applicationsHasMore: false,
    //     applicationsLoading: false,
    //     applicationsPage: 0
    // },
    //
    // [ApplicationStatus.INVITED]: {
    //     applications: [],
    //     applicationsHasMore: false,
    //     applicationsLoading: false,
    //     applicationsPage: 0
    // },
    //
    // [ApplicationStatus.CREATED]: {
    //     applications: [],
    //     applicationsHasMore: false,
    //     applicationsLoading: false,
    //     applicationsPage: 0
    // },
    //
    // [ApplicationStatus.REJECTED]: {
    //     applications: [],
    //     applicationsHasMore: false,
    //     applicationsLoading: false,
    //     applicationsPage: 0
    // },
    //
    // [ApplicationStatus.SKIPPED]: {
    //     applications: [],
    //     applicationsHasMore: false,
    //     applicationsLoading: false,
    //     applicationsPage: 0
    // },

    applicationsWithActionInProgress: [],
    selectedForExport: new GeneralSet<OpportunityApplicationModel>()
};

const applicationsReducer = (state = initialState, action: any): ApplicationsState => {
    const status = action?.payload?.status ? action.payload?.status as ApplicationStatus : undefined

    switch (action.type) {

        // case SET_APPLICATIONS:
        //     return status ? {
        //         ...state,
        //         [status]: {
        //             ...state[status],
        //             applications: action.payload?.append ?
        //                 [...state[status].applications, ...(action.payload?.applications || [])] :
        //                 action.payload?.applications || []
        //         }
        //     } : state
        //
        // case SET_APPLICATIONS_LOADING:
        //     return status ? {
        //         ...state,
        //         [status]: {
        //             ...state[status],
        //             applicationsLoading: action.payload?.loading
        //         }
        //     } : state
        //
        // case SET_APPLICATIONS_HAS_MORE:
        //     return status ? {
        //         ...state,
        //         [status]: {
        //             ...state[status],
        //             applicationsHasMore: action.payload?.hasMore
        //         }
        //     } : state
        //
        // case SET_APPLICATIONS_PAGE:
        //     return status ? {
        //         ...state,
        //         [status]: {
        //             ...state[status],
        //             applicationsPage: action.payload?.page
        //         }
        //     } : state

        case ADD_TO_APPLICATIONS_IN_PROGRESS:
            let newApplicationsWithActionInProgress = [...state.applicationsWithActionInProgress];
            if (!newApplicationsWithActionInProgress.find(a => a.id === action.payload?.id)) {
                newApplicationsWithActionInProgress.push(action.payload);
            }
            return {
                ...state,
                applicationsWithActionInProgress: newApplicationsWithActionInProgress
            }

        case REMOVE_FROM_APPLICATIONS_IN_PROGRESS:
            return {
                ...state,
                applicationsWithActionInProgress: state.applicationsWithActionInProgress.filter(a => a.id !== action.payload?.id)
            }

        case ADD_TO_SELECTED_FOR_EXPORT:
            return {
                ...state,
                selectedForExport: state.selectedForExport.addImmutable(action.payload)
            }

        case REMOVE_FROM_SELECTED_FOR_EXPORT:
            return {
                ...state,
                selectedForExport: state.selectedForExport.deleteImmutable(action.payload)
            }

        case SET_SELECTED_FOR_EXPORT:
            return {
                ...state,
                selectedForExport: action.payload || new GeneralSet<OpportunityApplicationModel>()
            }

        // case MOVE_TO_REJECTED:
        //     return status ? {
        //         ...state,
        //         [status]: {
        //             ...state[status],
        //             applications: state[status].applications.filter(a => a.id !== action.payload?.application.id)
        //         },
        //         [ApplicationStatus.REJECTED]: {
        //             ...state[ApplicationStatus.REJECTED],
        //             applications: [action.payload?.application, ...state[ApplicationStatus.REJECTED].applications]
        //         }
        //     } : {
        //         ...state,
        //         application: action.payload?.application
        //     }
        //
        // case MOVE_TO_INVITED:
        //     return status ? {
        //         ...state,
        //         [status]: {
        //             ...state[status],
        //             applications: state[status].applications.filter(a => a.id !== action.payload?.application.id)
        //         },
        //         [ApplicationStatus.INVITED]: {
        //             ...state[ApplicationStatus.INVITED],
        //             applications: [action.payload?.application, ...state[ApplicationStatus.INVITED].applications]
        //         }
        //     } : {
        //         ...state,
        //         application: action.payload?.application
        //     }
        //
        // case MOVE_TO_DELETED:
        //     return status ? {
        //         ...state,
        //         [status]: {
        //             ...state[status],
        //             applications: state[status].applications.filter(a => a.id !== action.payload?.application.id)
        //         }
        //     } : {
        //         ...state,
        //         application: action.payload?.application
        //     }
        //
        // case MOVE_FROM_OTHER_TO_TOP_PICKS:
        //     return status ? {
        //         ...state,
        //         [status]: {
        //             ...state[status],
        //             applications: state[status].applications.filter(a => a.id !== action.payload?.application.id)
        //         },
        //         [ApplicationStatus.SHORTLISTED]: {
        //             ...state[ApplicationStatus.SHORTLISTED],
        //             applications: [action.payload?.application, ...state[ApplicationStatus.SHORTLISTED].applications]
        //         }
        //     } : {
        //         ...state,
        //         application: action.payload?.application
        //     }
        //
        // case MOVE_FROM_TOP_PICKS_TO_OTHER:
        //     return status ? {
        //         ...state,
        //         [status]: {
        //             ...state[status],
        //             applications: state[status].applications.filter(a => a.id !== action.payload?.application.id)
        //         },
        //         [ApplicationStatus.CREATED]: {
        //             ...state[ApplicationStatus.CREATED],
        //             applications: [action.payload?.application, ...state[ApplicationStatus.CREATED].applications]
        //         }
        //     } : {
        //         ...state,
        //         application: action.payload?.application
        //     }
        //
        // case APPLICATION_DATA_UPDATE:
        //     if (status) {
        //         let newApplications = [...state[status].applications]
        //         const applicationsIndex = newApplications.findIndex(a => a.id === action.payload?.application.id)
        //         newApplications.splice(applicationsIndex, 1, action.payload?.application)
        //         return {
        //             ...state,
        //             [status]: {
        //                 ...state[status],
        //                 applications: newApplications
        //             },
        //         }
        //     } else {
        //         return {
        //             ...state,
        //             application: action.payload?.application
        //         }
        //     }


        case SET_APPLICATION:
            return {
                ...state,
                application: action.payload
            }

        case SET_APPLICATION_LOADING:
            return {
                ...state,
                applicationLoading: action.payload
            }

        case LOGOUT:
            return {
                ...initialState,
            };
    }
    return state;
};

export default applicationsReducer;

