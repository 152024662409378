import React, {useState} from "react";
import {Box, Chip} from "@material-ui/core";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import {getLanguageFlag} from "../../util/flag.util";
import EButton from "./EButton";
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from "@material-ui/core/styles";
import LanguageModel from "../../models/language.model";
import LanguageSearch from "./LanguageSearch/LanguageSearch";

interface Props {
    languages?: LanguageModel[]
    onRemoveLanguage: (language: LanguageModel) => void
    onAddLanguage: (language: LanguageModel) => void
    addTitle: string;
}

const useStyle = makeStyles(theme => ({
    flag: {
        borderRadius: '50%',
        backgroundSize: 'cover !important'
    },
    multiselectChip: {
        margin: 5
    }
}))

const LanguageSelector = (props: Props) => {

    const [searchOpen, setSearchOpen] = useState(false)
    const classes = useStyle()

    const t = useTranslation().t

    const renderLanguages = () => {
        let languages = (props.languages && [...props.languages]) || []
        return languages.map((language: any) => (
            <Chip
                key={language.id}
                avatar={<div className={clsx(classes.flag, 'flag-icon', getLanguageFlag(language))}/>}
                label={language.name}
                size="small"
                onDelete={() => props.onRemoveLanguage(language)}
                className={classes.multiselectChip}
            />
        ))
    };

    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            flexWrap={'wrap'}
        >

            {renderLanguages()}

            <EButton
                onClick={() => setSearchOpen(true)}
            >
                <AddIcon/>
                {props.addTitle}
            </EButton>

            <LanguageSearch
                open={searchOpen}
                onLanguagePicked={(language) => {
                    props.onAddLanguage(language);
                    setSearchOpen(false);
                }}
                onClose={() => setSearchOpen(false)}
                title={t('select_country')}
            />

        </Box>
    )
}

export default LanguageSelector
