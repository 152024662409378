import {
    LOGOUT,
    SET_ADD_PAYMENT_OPEN,
    SET_INVOICES,
    SET_INVOICES_STATE,
    SET_PRODUCT_PRICE,
    SET_STRIPE_CUSTOMER,
    SET_STRIPE_CUSTOMER_STATE,
    SET_SUBSCRIBE_OPEN,
    SET_SUBSCRIBING_TO_AMATEUR,
    SET_SUBSCRIBING_TO_PRO_TRIAL_IN_PROGRESS,
    SET_SUBSCRIPTION,
    SET_SUBSCRIPTION_MESSAGE,
    SET_SUBSCRIPTION_STATE, SET_UPGRADE_POPUP_OPEN,
} from "../actions/actionTypes";
import {StripeProduct, StripeProductPrice} from "../../models/StripeModels/stripeProduct.model";
import {StripeSubscription} from "../../models/StripeModels/stripeSubscription.model";
import {StripeInvoiceModel} from "../../models/StripeModels/stripeInvoice.model";
import {StripeCustomerModel} from "../../models/StripeModels/stripeCustomer.model";

export enum PremiumPopupTrigger {
    // GENERIC = 'GENERIC',
    JOB_LIMIT = 'job limit hit',
    LOCAL_PUBLISH_NOT_AVAILABLE = 'local publish',
    HEADER = 'header',
    DROPDOWN = 'dropdown',
    BILLING_PAGE = 'billing upgrade',
    SPECIFIC_POSITION = 'specific position'
}


export interface BillingState {
    subscribingToProTrialInProgress: boolean
    subscribingToAmateurInProgress: boolean
    subscriptionMessage: string,
    product?: StripeProduct,
    price?: StripeProductPrice,
    addPaymentMethodOpen: boolean,
    subscribeToPackageOpen: boolean,

    subscription?: StripeSubscription,
    subscriptionServer?: StripeSubscription,
    subscriptionState: 'loading' | ''

    invoices: StripeInvoiceModel[],
    invoicesState: 'loading' | '',

    stripeCustomer?: StripeCustomerModel,
    stripeCustomerState: 'loading' | '',

    upgradePopupOpen: boolean
    upgradePopupTrigger: PremiumPopupTrigger,
}

const initialState: BillingState = {
    subscribingToProTrialInProgress: false,
    subscribingToAmateurInProgress: false,
    subscriptionMessage: '',
    addPaymentMethodOpen: false,
    subscribeToPackageOpen: false,
    invoices: [],
    subscriptionState: '',
    invoicesState: '',
    stripeCustomerState: '',
    upgradePopupOpen: false,
    upgradePopupTrigger: PremiumPopupTrigger.HEADER,
}

const billingReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_SUBSCRIBING_TO_PRO_TRIAL_IN_PROGRESS:
            return {
                ...state,
                subscribingToProTrialInProgress: action.payload || false
            }
        case SET_SUBSCRIBING_TO_AMATEUR:
            return {
                ...state,
                subscribingToAmateurInProgress: action.payload || false
            }
        case SET_SUBSCRIPTION_MESSAGE:
            return {
                ...state,
                subscriptionMessage: action.payload || ''
            }
        case SET_PRODUCT_PRICE:
            return {
                ...state,
                product: action.payload.product,
                price: action.payload.price
            }
        case SET_ADD_PAYMENT_OPEN:
            return {
                ...state,
                addPaymentMethodOpen: action.payload
            }
        case SET_SUBSCRIBE_OPEN:
            return {
                ...state,
                subscribeToPackageOpen: action.payload
            }
        case SET_SUBSCRIPTION_STATE:
            return {
                ...state,
                subscriptionState: action.payload
            }
        case SET_SUBSCRIPTION:
            return {
                ...state,
                subscription: action.payload
            }
        case SET_INVOICES_STATE:
            return {
                ...state,
                invoicesState: action.payload
            }
        case SET_INVOICES:
            return {
                ...state,
                invoices: action.payload
            }
        case SET_STRIPE_CUSTOMER_STATE:
            return {
                ...state,
                stripeCustomerState: action.payload
            }
        case SET_STRIPE_CUSTOMER:
            return {
                ...state,
                stripeCustomer: action.payload
            }
        case SET_UPGRADE_POPUP_OPEN:
            return {
                ...state,
                upgradePopupOpen: action.payload.open,
                upgradePopupTrigger: action.payload.trigger
            }
        case LOGOUT:
            return {
                ...initialState
            }
        default:
            return state
    }
}

export default billingReducer
