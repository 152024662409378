import React, {FunctionComponent} from 'react';
import styles from './Position.module.scss';
import clsx from 'clsx';
import {useTranslation} from "react-i18next";
import {Box} from "@material-ui/core";

interface Props {
    classes?: string;
    selectedPrimary?: string[];
    selectedSecondary?: string[];
    selectedSection?: string;
    readOnly?: boolean;
    positionClickHandler?: (position: string) => void;
    sectionClickHandler?: (section: string) => void;
    mode?: string;
}

const Position: FunctionComponent<Props> = (props: Props) => {

    const t = useTranslation().t;

    const positions = [
        {id: 'Forward', x: '50%', y: '15%'},
        {id: 'LeftWing', x: '15%', y: '20%'},
        {id: 'RightWing', x: '85%', y: '20%'},
        {id: 'AttackingMidfield', x: '50%', y: '32%'},
        {id: 'LeftMidfield', x: '15%', y: '50%'},
        {id: 'CentralMidfield', x: '50%', y: '50%'},
        {id: 'RightMidfield', x: '85%', y: '50%'},
        {id: 'DefensiveMidfield', x: '50%', y: '68%'},
        {id: 'LeftFullBack', x: '15%', y: '82%'},
        {id: 'CentralDefender', x: '50%', y: '82%'},
        {id: 'RightFullBack', x: '85%', y: '82%'},
        {id: 'Goalkeeper', x: '50%', y: '97%'},
    ];

    const sections = [
        {
            name: 'forward',
            y: -5,
            height: 109,
            value: t('field_section_forward'),
            valueY: '14%',
        },
        {
            name: 'midfield',
            y: 107,
            height: 96,
            value: t('field_section_midfield'),
            valueY: '43%',
        },
        {
            name: 'defense',
            y: 207,
            height: 103,
            value: t('field_section_defense'),
            valueY: '72%',
        },
        {
            name: 'goalkeeper',
            y: 313,
            height: 35,
            value: t('field_section_goalkeeper'),
            valueY: '94%',
        }
    ];

    const positionClickHandler = (position: string) => {
        if (props.positionClickHandler && !props.readOnly) {
            props.positionClickHandler(position);
        }
    };

    const sectionClickHandler = (section: string) => {
        if (props.sectionClickHandler && !props.readOnly) {
            props.sectionClickHandler(section);
        }
    };

    const positionsUI = positions.map(position => {
        const style: any = {
            left: `calc(${position.x} - 20px)`,
            top: `calc(${position.y} - 20px)`,
        };
        if (props.selectedPrimary && props.selectedPrimary.find(p => position.id === p)) {
            style.color = 'white';
            style.background = '#4858E1';
        }
        if (props.selectedSecondary && props.selectedSecondary.find(p => position.id === p)) {
            style.color = 'white';
            style.background = '#46d98a';
        }
        return (
            <Box key={position.id}
                 display={'flex'}
                 justifyContent='center'
                 alignItems='center'
                 className={clsx(styles.position, props.readOnly ? ' ' : clsx(styles.editable, 'editablePositionHover'))}
                 style={style}
                 onClick={() => positionClickHandler(position.id)}>
                <p>{t(`position_${position.id}_short`)}</p>
            </Box>
        )
    });

    const sectionsUI = sections.map(section => {
        const style: any = {
            top: section.valueY
        };

        const catcherStyle = {
            top: `${section.y}px`,
            height: `${section.height}px`
        };

        return (
            <React.Fragment>
                <div className={styles.section} key={section.name}>

                    <div className={styles.sectionValue} style={style}>{section.value}</div>

                    {props.selectedSection === section.name &&
                        <svg width="100%" height="100%" viewBox="0 0 292 345" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="292"
                                  height="345">
                                <path
                                    d="M9.28306 9.75206C9.41799 4.60407 13.63 0.500977 18.7798 0.500977H273.22C278.37 0.500977 282.582 4.60409 282.717 9.75208L291.231 334.61C291.371 339.953 287.079 344.359 281.734 344.359H10.2655C4.92135 344.359 0.628769 339.953 0.768787 334.61L9.28306 9.75206Z"
                                    fill="white" stroke="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0)">
                                <g opacity="0.2">
                                    <rect x="-9" y={`${section.y}`} width="311" height={`${section.height}`}
                                          fill="#46d98a"/>
                                </g>
                            </g>
                        </svg>
                    }
                </div>


                <div className={styles.sectionClickCatcher} style={catcherStyle}
                     onClick={() => sectionClickHandler(section.name)}></div>
            </React.Fragment>

        )
    });

    return (
        <div className={[styles.pitch, props.classes].join(' ')}>
            <div className={styles.backgroundRectangle}/>
            <img src={require('../../../assets/images/pitch.svg').default} className={styles.pitchImage}/>
            {props.mode === 'section' ? sectionsUI : positionsUI}
        </div>
    )
};

export default Position;
