import React from "react";
import {Box, Card} from "@material-ui/core";
import AssignmentIcon from '@material-ui/icons/Assignment';
import {useTranslation} from "react-i18next";
import {useTabs} from "../../util/data/useTabs";
import {useBreadcrumbs} from "../../util/data/useBreadcrumbs";
import Opportunity from "./OpportunityFeed.component";
import OpportunityFeed from "./OpportunityFeed.component";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ApplicantRanking from "./ApplicantRanking";

const Algorithms = () => {

    // region state

    const {t} = useTranslation()

    // endregion state

    const tabs = [
        {icon: <AssignmentIcon/>, label: t('Opportunity')},
        {icon: <NotificationsActiveIcon/>, label: t('Applicants & Notifications')},
    ]

    const tabsData = useTabs({
        tabs: tabs,
        showDelete: false,
        canDelete: false,
        showSave: false,
        canSave: false,
    })

    const breadcrumbs = useBreadcrumbs({
        path: [
            {label: t('Algorithms')}
        ]
    })

    return (
        <Box>

            {breadcrumbs}

            <Card>
                {tabsData.renderTabs()}
                {tabsData.tab === 0 && <OpportunityFeed/>}
                {tabsData.tab === 1 && <ApplicantRanking/>}
            </Card>


        </Box>
    );
}

export default Algorithms;
