import React from 'react';
import {Box, Theme, Tooltip, withStyles} from "@material-ui/core";
import clsx from 'clsx';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({

    userInfoBox: {
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.11)',
        width: '150px',
        height: '100px',
        background: 'white',
        margin: '10px',
        cursor: 'default'
    },
    userInfoValue: {
        textAlign: 'center'
    },
    userInfoLabel: {
        fontSize: '12px',
        color: 'grey',
        marginTop: '5px',
        marginBottom: '27px',
    },
}));

interface Props {
    title: string;
    value: string | number;
    tooltip?: string;
    classes?: any;

}

const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        whiteSpace: 'pre-wrap'
    },
}))(Tooltip);

const InfoBox = (props: Props) => {

    const classes = useStyles()


    return (
        <LightTooltip title={props.tooltip || ''}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                className={clsx(classes.userInfoBox, props.classes)}
                alignItems={'center'}
                justifyContent={'flex-end'}
            >
                <div className={classes.userInfoValue}>{props.value}</div>
                <div className={classes.userInfoLabel}>{props.title}</div>
            </Box>
        </LightTooltip>
    )
};

export default InfoBox;
