import {Grid, TextField} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {EGridSizes, getGridSizes} from "./grid.util";

interface Props {
    grid: EGridSizes
    value?: string | number,
    onChange?: (value: string) => void,
    className?: any,
    style?: React.CSSProperties,
    label?: string,
    mandatory?: boolean,
    multiline?: boolean
    rows?: number,
    disabled?: boolean
}

const useStyle = makeStyles(theme => ({
    input: {
        width: '100%'
    }
}))

const ETextField = ({value, onChange, className, label, mandatory, multiline, rows, style, grid, disabled}: Props) => {

    const classes = useStyle()

    return (
        <Grid item {...getGridSizes(grid)}>
            <TextField
                label={label}
                variant={'outlined'}
                margin={'normal'}
                value={value || ''}
                style={style}
                className={classes.input}
                onChange={event => onChange?.(event.target.value)}
                error={mandatory ? !value : false}
                helperText={mandatory ? !value && 'Field is mandatory' : ''}
                multiline={multiline}
                rows={rows}
                disabled={disabled}
            />
        </Grid>

    )
}

export default ETextField