import React, {FunctionComponent} from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from './ShowAllTeamsPopup.module.scss'
import {Box, Button, DialogActions} from '@material-ui/core';
import Team from "../../../../../models/team.model";
import clsx from 'clsx';


interface Props {
    open: boolean;
    onClose: () => void;
    teams: Team[];
    onAddNew: () => void;
    onDelete: (team: Team) => void;
}


const ShowAllTeamsPopup: FunctionComponent<Props> = (props: Props) => {

    const teams = props.teams.map(team => {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                className={styles.team}
            >

                <img alt={'Delete'} src={require('../../../../../assets/images/x-primary.svg').defalu}
                     className={styles.deleteTeamIcon}
                     onClick={() => props.onDelete(team)}/>

                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    className={styles.teamGenderHolder}
                >
                    <img alt={''} src={require('../../../../../assets/images/hexagon-white.svg').default}
                         className={styles.teamGenderHex}/>
                    <img alt={'Gender'} className={styles.teamGender}
                         src={team.gender === 'MALE' ? require('../../../../../assets/images/male.svg').default : require('../../../../../assets/images/female.svg').default}/>
                </Box>
                <p className={styles.teamName}>{team.teamType?.name}</p>
                <p className={styles.leagueLevel}>League {team.level?.id}</p>
            </Box>
        )
    });

    return (
        <Dialog aria-labelledby="simple-dialog-title" {...props} maxWidth={"sm"} fullWidth={true}>

            <Box display={'flex'} className={styles.container}>
                {teams}

                <Box
                    display={'flex'}
                    onClick={props.onAddNew}
                    className={clsx(styles.team, styles.addNewTeam)}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <img alt={'Add'} src={require('../../../../../assets/images/add-orange.svg').default}/>
                </Box>

            </Box>

            <DialogActions>
                <Button color="primary" onClick={props.onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default ShowAllTeamsPopup;
