const UserData = () => {
    return (
        <div style={{marginTop: '100px', padding: '20px'}}>

            <h1>USER DATA POLICIES</h1>

            <h3>ACCOUNT DELETION</h3>

            <p>If you want to delete your account and data, please email us at <a href={"mailto:support@playerhunter.com"}>support@playerhunter.com</a> and we will
                contact you shortly.</p>

        </div>
    )
};

export default UserData;
