import React, {useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {VerifiedStatus} from "../../../../models/user.model";
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";

const VerificationStatusFilter = () => {

    //region State

    const [verified] = useUrlParam({param: 'verified', dataType: 'string', defaultValue: ''})
    const [expanded, setExpanded] = useState(verified !== '')
    const history = useHistory()

    //endregion State

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="verification-content"
                id="verification-header"
            >
                <Typography>Verification status {verified !== '' && `(${verified?.toLowerCase()})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="verification_foot"
                        name="verification_foot"
                        value={verified}
                        onChange={e => {
                            setMultipleParams([
                                {name: 'verified', value: e.target.value || '', dataType: 'string'},
                                {name: 'listPage', value: 1, dataType: 'numeric'}
                            ], history)
                        }}
                    >
                        <FormControlLabel value={VerifiedStatus.NOT_VERIFIED} control={<Radio/>} label="Not verified"/>
                        <FormControlLabel value={VerifiedStatus.VERIFIED} control={<Radio/>} label="Verified"/>
                        <FormControlLabel value={VerifiedStatus.PENDING} control={<Radio/>}
                                          label="Waiting verification"/>
                        <FormControlLabel value="" control={<Radio/>} label="All"/>
                    </RadioGroup>
                </FormControl>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default VerificationStatusFilter