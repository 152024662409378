import React from 'react'
import {Box} from "@material-ui/core"
import AgeFilter from "./FilterTypes/AgeFilter.component"
import VerificationStatusFilter from "./FilterTypes/VerificationStatusFilter.component";
import TeamLevelFilter from "./FilterTypes/TeamLevelFilter.component";
import CountryFilter from "./FilterTypes/CountryFilter.component";
import GenderFilter from "./FilterTypes/GenderFilter.component";
import PositionsFilter from "./FilterTypes/PositionsFilter.component";
import UserNameFilter from "./FilterTypes/UserNameFilter.component";
import AgencyNameFilter from "./FilterTypes/AgencyNameFilter.component";
import HeightFilter from "./FilterTypes/HeightFilter.component";
import SkillVideoCountFilter from "./FilterTypes/VideoCountFilter.component";
import PreferredFootFilter from "./FilterTypes/PreferredFootFilter.component";
import NationalityFilter from "./FilterTypes/NationalityFilter.component";
import RoleFilter from "./FilterTypes/RoleFilter.component";
import AppliedToFilter from "./FilterTypes/AppliedToFilter.component";
import JobStatusFilter from "./FilterTypes/JobStatusFilter.component";
import ApplicantsCountFilter from "./FilterTypes/ApplicantsCountFilter.component";
import JobLocationTypeFilter from "./FilterTypes/JobLocationTypeFilter.component";
import ClubNameFilter from "./FilterTypes/ClubNameFilter.component";
import UnresolvedAdminsFilter from "./FilterTypes/UnresolvedAdminsFilter.component";
import SubscriptionTypeFilter from "./FilterTypes/SubscriptionTypeFilter.component";
import ClubFilter from "./FilterTypes/ClubFilter.component";
import TransferAvailabilityStatusFilter from "./FilterTypes/TransferAvailabilityStatusFilter.component";
import TransfermarktValueFilter from "./FilterTypes/TransfermarktValueFilter.component";
import InstatIndexFilter from "./FilterTypes/InstatIndexFilter.component";
import PlayerParentTypeFilter from "./FilterTypes/PlayerParentTypeFilter.component";
import EmailFilter from "./FilterTypes/EmailFilter.component";
import AgentFilter from "./FilterTypes/AgentFilter.component";
import ApplicationStatusFilter from "./FilterTypes/ApplicationStatusFilter.component";
import SalaryFilter from "./FilterTypes/SalaryFilter.component";
import TransferFeeFilter from "./FilterTypes/TransferFeeFilter.component";

export enum FILTERS {
    CLUB = 'filter.club',
    COUNTRIES = 'filter.countries',
    CITY = 'filter.city',
    AGE = 'filter.age',
    APPLICANTS = 'filter.applicants',
    STATUS = 'filter.status',
    GENDER = 'filter.gender',
    POSITIONS = 'filter.position',
    CREATED_AT = 'filter.createdAt',
    END_DATE = 'filter.paymentEndDate',
    FIRST_LAST_NAME = 'filter.firstLastName',
    NAME = 'filter.name',
    AGENCY_NAME = 'filter.agencyName',
    LANGUAGES = 'filter.languages',
    NATIONALITIES = 'filter.nationalities',
    HEIGHT = 'filter.height',
    PREFERRED_FOOT = 'filter.preferredFoot',
    SKILL_VIDEOS_COUNT = 'filter.skillVideosCount',
    SUBSCRIPTION_TYPES = 'filter.subscriptionTypes',
    ROLES = 'filter.userRoles',
    APPLIED_TO = 'filter.appliedTo',
    ONLY_WITH_UNRESOLVED_ADMINS = 'filter.onlyWithUnresolvedAdmins',
    TRANSFER_AVAILABILITY_STATUS = 'filter.transferAvailabilityStatus',
    VERIFICATION_STATUS = 'filter.verified',
    TEAM_LEVEL = 'filter.teamLevel',
    INTERNATIONAL_ONLY = 'filter.internationalOnly',
    TRANSFERMARKT_VALUE = 'filter.transfermarktValue',
    INSTAT_INDEX_VALUE = 'filter.instatIndexValue',
    PARENT_USER_TYPE_ID = 'filter.parentUserTypeId',
    EMAIL = 'filter.email',
    AGENT = 'filter.agent',
    APPLICATION_STATUS = 'filter.applicationStatus',
    SALARY = 'filter.salary',
    TRANSFER_FEE = 'filter.transferFee',
}

interface Props {
    filters: FILTERS[]
}

const DashboardFilters2 = (props: Props) => {

    const renderFilterInput = (filter: FILTERS) => {
        switch (filter) {

            case FILTERS.AGE:
                return <AgeFilter/>
            case FILTERS.TEAM_LEVEL:
                return <TeamLevelFilter/>
            case FILTERS.COUNTRIES:
                return <CountryFilter/>
            case FILTERS.VERIFICATION_STATUS:
                return <VerificationStatusFilter/>
            case FILTERS.GENDER:
                return <GenderFilter/>
            case FILTERS.POSITIONS:
                return <PositionsFilter/>
            case FILTERS.FIRST_LAST_NAME:
                return <UserNameFilter/>
            case FILTERS.AGENCY_NAME:
                return <AgencyNameFilter/>
            case FILTERS.HEIGHT:
                return <HeightFilter/>
            case FILTERS.SKILL_VIDEOS_COUNT:
                return <SkillVideoCountFilter/>
            case FILTERS.PREFERRED_FOOT:
                return <PreferredFootFilter/>
            case FILTERS.NATIONALITIES:
                return <NationalityFilter/>
            case FILTERS.ROLES:
                return <RoleFilter/>
            case FILTERS.APPLIED_TO:
                return <AppliedToFilter/>
            case FILTERS.STATUS:
                return <JobStatusFilter/>
            case FILTERS.APPLICANTS:
                return <ApplicantsCountFilter/>
            case FILTERS.INTERNATIONAL_ONLY:
                return <JobLocationTypeFilter/>
            case FILTERS.NAME:
                return <ClubNameFilter/>
            case FILTERS.ONLY_WITH_UNRESOLVED_ADMINS:
                return <UnresolvedAdminsFilter/>
            case FILTERS.SUBSCRIPTION_TYPES:
                return <SubscriptionTypeFilter/>
            case FILTERS.CLUB:
                return <ClubFilter/>
            case FILTERS.TRANSFER_AVAILABILITY_STATUS:
                return <TransferAvailabilityStatusFilter/>
            case FILTERS.TRANSFERMARKT_VALUE:
                return <TransfermarktValueFilter/>
            case FILTERS.INSTAT_INDEX_VALUE:
                return <InstatIndexFilter/>
            case FILTERS.PARENT_USER_TYPE_ID:
                return <PlayerParentTypeFilter/>
            case FILTERS.EMAIL:
                return <EmailFilter/>
            case FILTERS.AGENT:
                return <AgentFilter/>
            case FILTERS.APPLICATION_STATUS:
                return <ApplicationStatusFilter/>
            case FILTERS.SALARY:
                return <SalaryFilter/>
            case FILTERS.TRANSFER_FEE:
                return <TransferFeeFilter/>
        }

        return null
    }

    return (
        <Box>
            {props.filters.map(renderFilterInput)}
        </Box>
    )

}

export default DashboardFilters2
