import {FormControlLabel, Grid, Switch} from "@material-ui/core";
import React from "react";
import {EGridSizes, getGridSizes} from "./grid.util";

interface Props {
    grid: EGridSizes
    value?: boolean
    onChange?: (value: boolean) => void
    label?: string
}

// const useStyle = makeStyles(theme => ({
//     input: {
//         width: '100%'
//     }
// }))

const ESwitchField = ({value, onChange, label, grid}: Props) => {

    // const classes = useStyle()

    return (
        <Grid item {...getGridSizes(grid)}>
            <FormControlLabel
                control={
                    <Switch
                        color={'primary'}
                        checked={value || false}
                        onChange={e => onChange?.(e.target.checked)}
                    />
                }
                label={label}
            />
        </Grid>

    )
}

export default ESwitchField