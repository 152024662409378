import {
    SET_INVOICES,
    SET_INVOICES_STATE,
    SET_STRIPE_CUSTOMER,
    SET_STRIPE_CUSTOMER_STATE,
    SET_SUBSCRIPTION,
    SET_SUBSCRIPTION_STATE, SET_UPGRADE_POPUP_OPEN
} from "./actionTypes";
import SubscriptionService from "../../services/subscription.service";
import ClubModel from "../../models/club.model";
import {PremiumPopupTrigger} from "../reducers/billing.reducer";

export const loadInvoices = (club?: ClubModel) => {
    return async (dispatch: any) => {
        if (!club) return;

        dispatch({type: SET_INVOICES_STATE, payload: 'loading'})
        const invoices = await SubscriptionService.getStripeInvoices(club)
        dispatch({type: SET_INVOICES, payload: invoices || []})
        dispatch({type: SET_INVOICES_STATE, payload: ''})

        return invoices || []
    }
}

export const loadStripeCustomer = (club?: ClubModel) => {
    return async (dispatch: any) => {
        if (!club) return;

        dispatch({type: SET_STRIPE_CUSTOMER_STATE, payload: 'loading'})
        const customer = await SubscriptionService.getStripeCustomer(club)
        dispatch({type: SET_STRIPE_CUSTOMER, payload: customer})
        dispatch({type: SET_STRIPE_CUSTOMER_STATE, payload: ''})

        return customer
    }
}

export const loadStripeSubscription = (club?: ClubModel) => {
    return async (dispatch: any) => {
        if (!club) return;

        dispatch({type: SET_SUBSCRIPTION_STATE, payload: 'loading'})
        const subscription = await SubscriptionService.getStripeSubscription(club)
        dispatch({type: SET_SUBSCRIPTION, payload: subscription})
        dispatch({type: SET_SUBSCRIPTION_STATE, payload: ''})

        return subscription
    }
}

export const setUpgradePopupOpen = (open: boolean, trigger?: PremiumPopupTrigger) => {
    return {
        type: SET_UPGRADE_POPUP_OPEN,
        payload: {open, trigger}
    }
}
