import React, {useEffect, useState} from 'react'
import {Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import UserModel, {UserAdminInfo, UserTypeId, VerifiedStatus} from "../../../models/user.model";
import GenericNameAvatar from "./GenericNameAvatar.component";
import {
    getEmail,
    getFullName,
    getProfileCityCountryName,
    getProfileLocationGeo,
    getUserProfilePicture
} from "../../../util/profile.util";
import ETextField from "../../common/ETextField.component";
import ESwitchField from "../../common/ESwitchField.component";
import GoogleMapReact from "google-map-react"
import GooglePlacesAutocomplete, {geocodeByPlaceId} from "react-google-places-autocomplete"
import {mapStyleGrey} from "../../../util/map.style";
import EDateField from "../../common/EDateField.component";
import {getGridSizes} from "../../common/grid.util";
import {isAfter} from "date-fns";
import {Alert} from "@material-ui/lab";
import EButton from "../../common/EButton";
import {LoginType} from "../../../models/loginType.model";
import {convertGeocodeResultsToGoogleGeolocationInput} from "../../../util/util";
import ChangePassword from "../ChangePassword";
import ChangeEmail from "../ChangeEmail";

interface Props {
    user?: UserModel,
    setUser: (user: UserModel) => void,
    isDirty?: boolean,
    onRefresh: () => void,
    userAdminInfo?: UserAdminInfo,
    setUserAdminInfo: (userAdminInfo?: UserAdminInfo) => void
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
    input: {
        width: '100%'
    }
}))

const defaultNotes = `
1. Wants to change club or browsing jobs?


2. Favorite countries?


3. Salary range?


---- Other note ----
`

const Overview = ({user, setUser, isDirty, onRefresh, userAdminInfo, setUserAdminInfo}: Props) => {

    const classes = useStyle()
    const history = useHistory()
    const {t, i18n} = useTranslation()

    const [map, setMap] = useState()
    const [marker, setMarker] = useState<any>()
    const [changePasswordOpen, setChangePasswordOpen] = useState(false)
    const [changeEmailOpen, setChangeEmailOpen] = useState(false)

    const profile = user?.profile || {}
    const isRegularUser = user?.userType?.id === UserTypeId.REGULAR_TYPE_ID && user?.loginType !== LoginType.SUBUSER
    const isAgent = user?.userType?.id === UserTypeId.AGENT_TYPE_ID
    const isClubAdmin = user?.userType?.id === UserTypeId.STAFF_TYPE_ID
    const isAgentsPlayer = user?.userType?.id === UserTypeId.REGULAR_TYPE_ID && user?.loginType === LoginType.SUBUSER

    let typeText = 'Regular Player'
    if (isAgent) {
        typeText = 'Agent'
    } else if (isAgentsPlayer) {
        typeText = "Agent's Player"
    } else if (isClubAdmin) {
        typeText = "Club Admin"
    }

    const updateProfileFields = (data: { field: string, value: any }[]) => {
        const newUser: any = {
            ...user,
            profile: {
                ...user?.profile
            }
        }
        data.forEach(d => newUser.profile[d.field] = d.value)
        setUser(newUser)
    }

    const updateProfileField = (field: string, value: any) => {
        updateProfileFields([{field, value}])
    }

    const drawCircle = () => {
        marker?.setMap(null)

        if (!getProfileLocationGeo(profile)) return

        try {
            setMarker(new google.maps.Marker({
                map,
                position: getProfileLocationGeo(profile)
            }))
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        drawCircle()
    }, [profile, map])

    //endregion Handlers

    //region UI

    const commonInfoUI = (
        <Grid
            container
            spacing={2}
            className={classes.sectionData}
        >

            <ETextField
                grid={'1/3'}
                label={t('First name')}
                value={user?.profile?.firstName}
                onChange={event => updateProfileField('firstName', event)}
            />

            <ETextField
                grid={'1/3'}
                label={t('Last name')}
                value={user?.profile?.lastName}
                onChange={event => updateProfileField('lastName', event)}
            />

            <Grid item {...getGridSizes('1/3')}>
                <FormControl
                    variant="outlined"
                    className={classes.input}
                    margin={'normal'}
                    disabled
                >
                    <InputLabel>{t("Account type")}</InputLabel>
                    <Select
                        label={'Account type'}
                        value={user?.userType?.id || 0}
                        className={classes.input}
                        // onChange={event => updateField('status', event.target.value)}
                    >
                        <MenuItem value={UserTypeId.AGENT_TYPE_ID}>{t("Agent")}</MenuItem>
                        <MenuItem
                            value={UserTypeId.REGULAR_TYPE_ID}>{isAgentsPlayer ? t("Agent's player") : t("Regular player")}</MenuItem>
                        <MenuItem value={UserTypeId.STAFF_TYPE_ID}>{t("Club admin")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                {
                    isAgentsPlayer &&
                    <Alert
                        severity={'info'}
                    >

                        <Typography>Agency: {user?.parent?.userProfile?.agencyName || '-'}</Typography>
                        <Typography>Agent: {user?.parent?.userProfile?.firstName} {user?.parent?.userProfile?.lastName}</Typography>
                        <Typography>Phone: {user?.parent?.userProfile?.contactInfo?.phone}</Typography>
                        <Typography>Email: {user?.parent?.userProfile?.contactInfo?.email}</Typography>
                        <EButton
                            color={'secondary'}
                            variant={'contained'}
                            style={{marginTop: 20}}
                            onClick={() => history.push(`/admin/user/${user?.parent?.id}`)}
                        >
                            View agent's profile
                        </EButton>

                    </Alert>
                }
            </Grid>

            <Grid item xs={12}>
                <Alert
                    severity={'success'}
                >
                    <Typography>Registered via: {user?.loginType}</Typography>
                    <Typography>Registration email: {getEmail(user, '-')}</Typography>

                    {
                        user?.loginType === LoginType.EMAIL &&
                        <Box display={'flex'}>
                            <Box m={1}>
                                <Button
                                    disabled={isDirty}
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => setChangePasswordOpen(true)}
                                >
                                    Change password
                                </Button>
                            </Box>
                            <Box m={1}>
                                <Button
                                    disabled={isDirty}
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => setChangeEmailOpen(true)}
                                >
                                    Change email
                                </Button>
                            </Box>
                        </Box>
                    }

                    {
                        user?.loginType === LoginType.EMAIL && isDirty &&
                        <Typography>Please save changes before editing password or email</Typography>
                    }

                </Alert>
            </Grid>

        </Grid>
    )

    const accountStatusUI = (
        <Grid
            container
            spacing={2}
            className={classes.sectionData}
            alignItems={'center'}
        >

            <Grid item {...getGridSizes('1/3')}>
                <FormControl
                    variant="outlined"
                    className={classes.input}
                    margin={'normal'}
                >
                    <InputLabel>{t("Verification status")}</InputLabel>
                    <Select
                        label={'Verification status'}
                        value={user?.profile?.verified ?? VerifiedStatus.PENDING}
                        className={classes.input}
                        onChange={event => updateProfileField('verified', event.target.value)}
                    >
                        <MenuItem value={VerifiedStatus.PENDING}>Waiting verification</MenuItem>
                        <MenuItem value={VerifiedStatus.NOT_VERIFIED}>Not verified</MenuItem>
                        <MenuItem value={VerifiedStatus.VERIFIED}>Verified</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <ESwitchField
                grid={"1/3"}
                label={t('Profile is public')}
                value={!!user?.profile?.isPublic}
                onChange={checked => {
                    updateProfileField('isPublic', checked)
                }}
            />

        </Grid>
    )

    const subscriptionUI = (
        <Grid
            container
            spacing={2}
            className={classes.sectionData}
            alignItems={'center'}
        >
            <EDateField
                grid={"1/3"}
                label={'Subscription start'}
                value={user?.subscription?.subscriptionDate || undefined}
                onChange={d => {
                    setUser({
                        ...user,
                        subscription: {
                            ...user?.subscription,
                            subscriptionDate: d as Date
                        }
                    })
                }}
            />

            <EDateField
                grid={"1/3"}
                label={'Subscription end'}
                value={user?.subscription?.expirationDate || undefined}
                onChange={d => {
                    setUser({
                        ...user,
                        subscription: {
                            ...user?.subscription,
                            expirationDate: d as Date
                        }
                    })
                }}
            />

        </Grid>
    )

    const locationUI = (
        <Grid container spacing={4}>

            <Grid item xs={12}>
                <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_MAPS_KEY!}
                    debounce={1000}
                    selectProps={{
                        value: getProfileCityCountryName(profile),
                        onChange: async (res: any) => {
                            const results = await geocodeByPlaceId(res.value.place_id)
                            updateProfileField('googleGeolocation', convertGeocodeResultsToGoogleGeolocationInput(results as any))
                        }
                    }}
                />
                <div style={{
                    width: '100%',
                    height: 300,
                    marginTop: 20,
                    marginBottom: 30
                }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: process.env.REACT_APP_MAPS_KEY!}}
                        defaultCenter={getProfileLocationGeo(profile)}
                        center={getProfileLocationGeo(profile)}
                        defaultZoom={7}
                        options={{styles: mapStyleGrey}}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({map}) => setMap(map)}
                    />
                </div>
            </Grid>

        </Grid>
    )

    return (
        <Box
            p={3}
            display={'flex'}
            flexDirection={'column'}
        >

            <GenericNameAvatar
                name={`${getFullName(user)} (${typeText})`}
                image={getUserProfilePicture(user)}
                setImage={media => updateProfileField('profilePicture', media?.url)}
            />

            <Box m={1}/>

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Common info")}
            </Typography>
            {commonInfoUI}

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Login info")}
            </Typography>

            {
                !isClubAdmin &&
                <React.Fragment>
                    <Typography variant={'h4'} className={classes.sectionTitle}>
                        {t("Account status")}
                    </Typography>
                    {accountStatusUI}
                </React.Fragment>
            }


            {
                (isAgent || isRegularUser) &&
                <React.Fragment>
                    <Typography variant={'h4'} className={classes.sectionTitle}>
                        {t("Subscription info")}
                    </Typography>

                    {
                        user?.subscription?.expirationDate && isAfter(user.subscription.expirationDate, new Date()) ?
                            <Alert severity="success">User has an active subscription</Alert>
                            :
                            <Alert severity="warning">User does not have an active subscription</Alert>
                    }

                    {subscriptionUI}
                </React.Fragment>
            }


            {
                !isClubAdmin &&
                <React.Fragment>
                    <Typography variant={'h4'} className={classes.sectionTitle}>
                        {t("Location")}
                    </Typography>
                    {locationUI}
                </React.Fragment>
            }

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Internal notes")}
            </Typography>

            <Grid
                container
                spacing={2}
                className={classes.sectionData}
            >

                <ETextField
                    grid={'1/1'}
                    multiline
                    rows={30}
                    label={t('Internal note')}
                    value={userAdminInfo?.adminNote || defaultNotes}
                    onChange={event => setUserAdminInfo({
                        ...userAdminInfo,
                        adminNote: event
                    })}
                />

            </Grid>

            <ChangePassword
                user={user}
                open={changePasswordOpen}
                onClose={() => setChangePasswordOpen(false)}
            />

            <ChangeEmail
                user={user}
                open={changeEmailOpen}
                onClose={() => setChangeEmailOpen(false)}
                onChanged={onRefresh}
            />

        </Box>
    )

    //endregion UI
}

export default Overview
