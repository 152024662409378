import React, {useEffect, useRef, useState} from 'react'
import {Box, BoxProps} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyle = makeStyles(({
    component: {
        transitionDuration: '1s',
    },
    hoverable: {
        '&:hover': {
            transition: 'transform 0.2s cubic-bezier(.02,.54,.58,1)',
            boxShadow: '0px 10px 55px 5px rgba(65, 196, 126, 0.35)',
            borderRadius: 15,
            transform: 'translate3d(0,-20px,0)',
            background: 'white'
        }
    },
    staticHover: {
        '&:hover': {
            transform: 'translate3d(0,0,0)',
        }
    },
    fade: {
        transition: 'transform 1s cubic-bezier(.02,.54,.58,1), opacity 1s',
        opacity: 0,
        transform: 'translate3d(0,100px,0)'
    },
    staticFadeIn: {
        transform: 'translate3d(0,0,0)'
    },
    fadeInOn: {
        opacity: 1,
        transform: 'translate3d(0,0,0)'
    }
}))

interface Props extends BoxProps {
    hover?: boolean,
    hoverType?: 'move' | 'static',
    hoverBackgroundOverride?: string,
    fadeIn?: boolean,
    fadeInType?: 'move' | 'static',
    fadeInThreshold?: number,
    onAnimate?: () => void
}

const AnimatedBox = React.forwardRef((props: Props, _ref) => {

    const classes = useStyle()
    const boxRef = useRef<any>()

    const animationDone = useRef(false)
    const [animate, setAnimate] = useState(false)

    const fadeInThreshold = props.fadeInThreshold || 0

    const handleScroll = () => {
        if (animationDone.current) return;
        const rect: DOMRect | undefined = boxRef?.current?.getBoundingClientRect()
        if (!rect) return

        const windowHeight = window.innerHeight
        if (rect.y + fadeInThreshold < windowHeight) {
            animationDone.current = true
            setAnimate(true)
            props.onAnimate?.()
        }
    }

    useEffect(() => {
        handleScroll()

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    const animationClasses = []
    const dynamicStyle: React.CSSProperties = {}

    if (props.hover) {
        animationClasses.push(classes.hoverable)

        if (props.hoverType === 'static') {
            animationClasses.push(classes.staticHover)
        }
    }

    if (props.fadeIn) {
        animationClasses.push(classes.fade)
        if (props.fadeInType === 'static') {
            animationClasses.push(classes.staticFadeIn)
        }

        animate && animationClasses.push(classes.fadeInOn)
    }

    const passProps = {
        ...props
    }

    delete passProps.hover
    delete passProps.hoverType
    delete passProps.hoverBackgroundOverride
    delete passProps.fadeIn
    delete passProps.fadeInType
    delete passProps.fadeInThreshold
    delete passProps.onAnimate

    return (
        <Box
            {...passProps}
            className={clsx(props.className, classes.component, ...animationClasses)}
            style={dynamicStyle}
            // @ts-ignore
            ref={boxRef}
        >
            {props.children}
        </Box>
    )
})

export default AnimatedBox