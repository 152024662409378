import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Link,
    Snackbar,
    Switch,
    TextField,
    Typography
} from "@material-ui/core";
import {useHistory} from "react-router";
import {emailValidator, validateForm} from "../../util/form.util";
import {Alert} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {AuthCommon, useAuthCommonStyles} from "./auth.common.";
import {useUrlParam} from "../../util/data/url.util";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {useAuthState} from "../../store/store.utils";
import {initEmailAuth, initRegisterClub, initSocialAuth, setAuthError} from "../../store/actions/authActions";
import {AuthError} from "../../store/reducers/auth.reducer";
import clsx from "clsx";
import {emptyValidator, isFormValid, minLengthValidator} from "../../util/validators";
import {SET_AUTH_EMAIL, SET_AUTH_PASSWORD} from "../../store/actions/actionTypes";
import {LoginType} from "../../models/loginType.model";
import {UserTypeId} from "../../models/user.model";
import ClubModel from "../../models/club.model";
import MixpanelService from "../../services/analytics/mixpanel.service";
import {ScreenName} from "../../services/analytics/screenName";
import {EventProperty} from "../../services/analytics/eventProperty";

const useStyles = makeStyles(theme => ({
    containerHolder: {
        zIndex: 1,
        width: 'calc(100% - 40px)',
        maxWidth: 450,
        marginBottom: 'auto'
    },
    container: {
        width: '100%',
        padding: 15,
        [theme.breakpoints.up('md')]: {
            padding: 20,
        }
    },
    logo: {
        width: 200,
        height: 100,
        objectFit: 'contain',
        marginBottom: theme.spacing(5)
    },
    legalRow: {
        width: '100%',
        marginLeft: -10,
        '& a': {
            color: theme.palette.primary.main,
            textTransform: 'none',
            textDecoration: 'none'
        }
    },
    legalText: {
        fontSize: 12
    },
    loginButton: {
        width: 225,
        height: 39,
        cursor: 'pointer',
        marginBottom: 10
    },
    done: {
        marginTop: 20
    },
}))

const Login = () => {

    //region State

    const classes = useStyles()
    const commonClasses = useAuthCommonStyles()
    const t = useTranslation().t
    const [registration] = useUrlParam({param: 'registration', dataType: 'boolean', defaultValue: false})
    const [acceptsToS, setAcceptsToS] = useState(false)
    const [acceptsPrivacy, setAcceptsPrivacy] = useState(false)
    const [loginWithEmailShown, setLoginWithEmailShown] = useState(false)
    const [tosError, setTosError] = useState(false)
    const showTosError = tosError && (!acceptsToS || !acceptsPrivacy)
    const {
        loading,
        club,
        firstName,
        lastName,
        agencyPosition,
        phone,
        email,
        password,
        authError
    } = useAuthState()
    const [createGenericAccount, setCreateGenericAccount] = useState(false)

    let errorMessage = ''
    switch (authError) {
        case AuthError.GENERIC:
            errorMessage = t('An error occurred. Please try again or contact our support')
            break;
        case AuthError.USER_NOT_CLUB:
            errorMessage = t('Only CLUBS can use web platform. If you are a Player or an Agent, please download the app from the app store to continue')
            break;

    }

    const [form, setForm] = useState({
        email: {
            value: '',
            placeholder: t("email"),
            type: 'email',
            validation: {
                validators: [emptyValidator, emailValidator],
                error: '',
            }
        },
        password: {
            value: '',
            placeholder: t('password'),
            type: 'password',
            validation: {
                validators: [
                    emptyValidator,
                    (value: string) => minLengthValidator(value, 8)
                ],
                error: '',
            }
        },
    })

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        MixpanelService.trackScreenVisit(registration ? ScreenName.SIGN_UP : ScreenName.SIGN_IN, {
            [EventProperty.USER_TYPE]: 'club'
        })
    }, [])

    if (registration) {
        if (!club) {
            history.replace('/auth/club')
        } else if (!firstName || !lastName || !phone || !agencyPosition) {
            history.replace('/auth/details')
        }
    }

    const login = async (loginType: LoginType) => {
        if (registration) {
            if (!acceptsToS || !acceptsPrivacy) {
                setTosError(true)
                return
            }

            if (loginType === LoginType.EMAIL) {
                dispatch(initRegisterClub(
                    club!,
                    firstName!,
                    lastName!,
                    phone!,
                    form.email.value,
                    form.password.value,
                    agencyPosition,
                    history,
                ))
            } else {
                const clubId = !club?.isRequest ? club?.id : undefined
                const clubRequest: ClubModel | undefined = club?.isRequest ? {
                    name: club.name,
                    googleGeolocation: club.googleGeolocation
                } : undefined

                dispatch(initSocialAuth(
                    loginType,
                    UserTypeId.STAFF_TYPE_ID,
                    clubId,
                    clubRequest,
                    firstName,
                    lastName,
                    phone,
                    agencyPosition,
                    history,
                    createGenericAccount
                ))
            }
        } else {
            if (loginType === LoginType.EMAIL) {
                dispatch(initEmailAuth(
                    form.email.value,
                    form.password.value,
                    false,
                    '',
                    '',
                    undefined,
                    history
                ))
            } else {
                dispatch(initSocialAuth(
                    loginType,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    history,
                    createGenericAccount
                ))
            }
        }
    }

    const validate = async (): Promise<boolean> => {
        let newForm: any = validateForm(form)
        // if (newForm.email.value &&
        //     newForm.repeatEmail.value &&
        //     !newForm.repeatEmail.validation.error &&
        //     newForm.email.value !== newForm.repeatEmail.value
        // ) {
        //     newForm.repeatEmail.validation.error = 'emails_does_not_match'
        // }
        //
        // if (newForm.email.value && !newForm.email.validation.error && isFormValid(newForm)) {
        //     const emailExists = await AuthService.checkEmail(form.email.value)
        //     newForm.email.validation.error = emailExists ? 'email_already_in_use' : ''
        // }

        setForm(newForm)
        return isFormValid(newForm)
    }

    const onContinue = async () => {
        if (await validate()) {
            await dispatch({type: SET_AUTH_EMAIL, payload: form.email.value})
            await dispatch({type: SET_AUTH_PASSWORD, payload: form.password.value})
            login(LoginType.EMAIL)
        }
    }

    const inputHandler = (key: 'email' | 'password', value: string) => {
        const formElement = {
            ...form[key],
            validation: {
                ...form[key].validation,
                error: ''
            }
        }
        formElement.value = value
        setForm({
            ...form,
            [key]: formElement
        })
    }

    const tos = (
        <Box
            display={'flex'}
            alignItems={'center'}
            className={classes.legalRow}
        >
            <Checkbox
                color={'primary'}
                checked={acceptsToS}
                onChange={_ => setAcceptsToS(!acceptsToS)}
                onClick={(e) => e.stopPropagation()}
            />
            <div className={classes.legalText}>{t('I accept the')} <Link
                href={'/terms-of-services'}><a>{t('Terms & Conditions')}</a></Link>
            </div>
        </Box>
    )

    const privacy = (
        <Box
            display={'flex'}
            alignItems={'center'}
            className={classes.legalRow}
        >
            <Checkbox
                color={'primary'}
                checked={acceptsPrivacy}
                onChange={_ => setAcceptsPrivacy(!acceptsPrivacy)}
                onClick={(e) => e.stopPropagation()}
            />
            <div className={classes.legalText}>{t('I accept the')} <Link
                href={'/privacy-policy'}><a>{t('Data Privacy')}</a></Link></div>
        </Box>
    )


    const loginButtons = (
        <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            marginBottom={3}
        >
            <img
                onClick={() => login(LoginType.FACEBOOK)}
                className={classes.loginButton}
                src={require('../../assets/images/login-option-facebook.svg').default}
            />

            <img
                onClick={() => login(LoginType.GOOGLE)}
                className={classes.loginButton}
                src={require('../../assets/images/login-option-google.svg').default}
            />

            <img
                onClick={() => setLoginWithEmailShown(true)}
                className={classes.loginButton}
                src={require('../../assets/images/login-option-email.svg').default}
            />

            {
                process.env.REACT_APP_ALLOW_GENERIC_ACCOUNTS === 'true' &&
                <FormControlLabel
                    label={t('Create generic account')}
                    control={<Switch
                        checked={createGenericAccount}
                        onChange={e => setCreateGenericAccount(e.target.checked)}
                    />}
                />
            }

        </Box>
    )

    const mapErrorToMessage = (error: string): string => {
        switch (error) {
            case 'empty':
                return t('error_empty_field')
            case 'email_not_valid':
                return t('error_bad_email')
            case 'min_length':
                return t('password_must_be_at_least_8_characters_long')
            case 'passwords_must_match':
                return t('passwords_must_match')
            case 'emails_does_not_match':
                return t('Emails do not match')
            case 'email_already_in_use':
                return t('email_already_in_use')
        }
        return ''
    }

    const renderInputField = (key: 'email' | 'password', extraClass?: any) => {
        if (!form) return null

        const formElement = form[key]

        return (
            <TextField
                onChange={(event) => inputHandler(key, event.target.value)}
                value={formElement.value}
                className={clsx(commonClasses.input, extraClass)}
                required
                type={formElement.type}
                label={formElement.placeholder}
                variant="outlined"
                margin="normal"
                // size={'medium'}
                error={!!formElement.validation.error}
                helperText={mapErrorToMessage(formElement.validation.error)}
            />
        )
    }

    return (
        <Box
            className={commonClasses.component}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            boxSizing={'border-box'}
        >
            <AuthCommon title={t(registration ? "Sign up" : "Sign in")}/>

            <Card
                className={classes.containerHolder}
            >

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    boxSizing={'border-box'}
                    p={3}
                    className={classes.container}
                >

                    {/*<img className={classes.logo} src={LogoFull as any}/>*/}

                    {!loginWithEmailShown && loginButtons}

                    {
                        loginWithEmailShown &&
                        <Button
                            onClick={() => setLoginWithEmailShown(false)}
                            style={{marginBottom: 10}}
                        >
                            <ArrowBackIcon/>
                            <Typography>
                                {t('Login with social network')}
                            </Typography>
                        </Button>
                    }

                    {
                        loginWithEmailShown &&
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            width={'100%'}
                        >
                            {renderInputField('email')}
                            {renderInputField('password')}

                            {
                                loading ? <CircularProgress className={classes.done}/> :
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.done}
                                        onClick={onContinue}
                                    >
                                        {/*{t("Sign up free")}*/}
                                        {t("Continue")}
                                    </Button>
                            }
                        </Box>
                    }

                    {
                        loginWithEmailShown && !registration &&
                        <Button
                            style={{marginTop: 20}}
                            onClick={() => history.push('/auth/forgot-password')}
                        >
                            {t('Forgot password?')}
                        </Button>
                    }


                    {
                        registration &&
                        <Box width={loginWithEmailShown ? '100%' : 225}>
                            {tos}
                            {privacy}

                            {
                                showTosError && <Typography style={{color: 'red', fontSize: 12, width: 220}}>
                                    {t('You must accept Terms and Privacy to continue')}
                                </Typography>
                            }

                        </Box>
                    }

                </Box>

            </Card>

            <Snackbar
                open={!!errorMessage}
                autoHideDuration={5000}
                onClose={() => dispatch(setAuthError())}
            >
                <Alert onClose={() => dispatch(setAuthError())} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>

        </Box>
    )
}

export default Login
