import {Box, Card, CircularProgress} from "@material-ui/core";
import React from "react";
import {format} from "date-fns";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {useBillingState} from "../../../store/store.utils";

const useStyles = makeStyles({
    container: {
        width: `100%`,
        minHeight: 100
    },
    invoice: {
        width: '100%',
        borderBottom: '1px solid #e0e0e0',
        marginTop: 10,
        paddingBottom: 10
    },
    invoiceStatus: {
        marginLeft: 'auto',
        textTransform: 'capitalize'
    },
    dateAndTime: {
        fontSize: 14,
        opacity: 0.7,
        marginTop: 5,
        marginBottom: 10
    },
    downloadIcon: {
        width: 20,
        height: 20,
        marginLeft: 15,
        cursor: 'pointer'
    },
    priceText: {
        fontWeight: 500
    },
    retry: {
        marginLeft: 'auto',
    }
})

const Invoices = () => {

    //region State

    const t = useTranslation().t
    const classes = useStyles()
    const {invoices, invoicesState} = useBillingState()

    //endregion State

    //region Handlers

    //endregion Handlers

    //region UI

    const renderStripeInvoices = () => {
        if (invoicesState === 'loading') {
            return <CircularProgress/>
        }

        return invoices.map(invoice => {
            const downloadUI = (
                <>
                    <div
                        className={classes.invoiceStatus}>{invoice?.status ? t(`invoice status ${invoice?.status}`) : '-'}</div>
                    <a target="_blank" href={invoice.hostedInvoiceUrl}>
                        <img
                            src={require('../../../assets/images/download.svg').default}
                            className={classes.downloadIcon}
                        />
                    </a>
                </>
            )

            // const retryUI = (
            //     <Button
            //         variant={'contained'}
            //         color={'primary'}
            //         className={classes.retry}
            //         onClick={() => window.open(invoice.hostedInvoiceUrl, '_blank')}
            //     >
            //         {t('Pay manually')}
            //     </Button>
            // )

            return (
                <Box
                    key={invoice.id}
                    display={'flex'}
                    alignItems={'center'}
                    className={classes.invoice}
                >
                    <Box display={'flex'} flexDirection={'column'}>
                        <div className={classes.dateAndTime}>
                            {format(new Date(invoice.created! * 1000), 'dd.MM.yyyy HH:mm')}
                        </div>
                        <div className={classes.priceText}>
                            {((invoice.total || 0) / 100).toFixed(2)} {invoice.currency?.toUpperCase()}
                        </div>
                    </Box>

                    {downloadUI}

                </Box>
            )
        })
    }

    return (
        <Card className={classes.container}>
            <Box
                p={2}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                {renderStripeInvoices()}
            </Box>
        </Card>
    )

    //endregion UI
}

export default Invoices