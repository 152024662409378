import React, {useState} from 'react';
import {Box, CircularProgress, Grid} from "@material-ui/core";
import InfoBox from "./InfoBox.component";
import {useTranslation} from "react-i18next";
import UserModel from "../../models/user.model";
import UserService from "../../services/user.service";
import {
    getCityCountryName,
    getFullName,
    getGender,
    getPreferredFoot,
    getUserAge,
    getUserHeight,
    getUserLanguages,
    getUserMainPositionLocalizedLong,
    getUserNationalities,
    getUserSecondaryPositionsLocalizedLong
} from "../../util/profile.util";
import EButton from "../common/EButton";
import ETextField from "../common/ETextField.component";
import {makeStyles} from "@material-ui/core/styles";
import {getGridSizes} from "../common/grid.util";

const useStyles = makeStyles(theme => ({

    userInput: {
        width: '100%',
    },
    userIdInput: {
        width: '200px',
        marginBottom: '0 !important',
        marginTop: '0 !important',
    },
    loadUserButton: {
        width: '150px',
        marginLeft: '20px !important',
        height: 'fit-content !important',
    },
    userInfoBoxesHolder: {
        width: 'calc(100% + 20px)',
        marginLeft: '-10px',
    },
    visibilityCTA: {
        // marginLeft: 'auto !important',
    },
    currentUserName: {
        marginRight: '10px',
        cursor: 'pointer',
    },
}));

interface Props {
    user: UserModel;
    onUserLoaded: (user?: UserModel) => void;
}

const UserAnalyzer = (props: Props) => {

    const [userId, setUserId] = useState<string>();
    const [fetchingUser, setFetchingUser] = useState(false);
    const [expanded, setExpanded] = useState(true);

    const t = useTranslation().t;
    const classes = useStyles()

    const handleUserFetch = async () => {
        props.onUserLoaded(undefined);
        const id = userId && parseInt(userId);
        if (id && !isNaN(id)) {
            try {
                setFetchingUser(true);
                const response = await UserService.getUser(id, false);
                props.onUserLoaded(response);
                setExpanded(true);
            } finally {
                setFetchingUser(false);
            }
        }
    };

    const openUserHandler = () => {
        if (props.user) {
            window.open(`${process.env.REACT_APP_WEB_URL}/player/${props.user.id}`, '_blank')
        }
    };

    const renderUserInfo = (user: UserModel) => {
        if (fetchingUser) {
            return <CircularProgress/>;
        }

        if (!user || !expanded) return null;

        return (
            <Box
                display={'flex'}
                flexWrap={'wrap'}
                justifyContent={'left'}
                className={classes.userInfoBoxesHolder}>

                <InfoBox
                    title='gender'
                    value={getGender(user, '-')}
                />

                <InfoBox
                    title='age'
                    value={getUserAge(user, '-')}
                />

                <InfoBox
                    title='level'
                    value={user.profile?.leagueLevel || 0}
                    // tooltip={getLevelData(user).tooltip}
                />

                <InfoBox
                    title='location'
                    value={getCityCountryName(user) || 0}
                    // tooltip={getLocationData(user).tooltip}
                />

                <InfoBox
                    title='main position'
                    value={getUserMainPositionLocalizedLong(t, user)}
                />

                <InfoBox
                    title='secondary positions'
                    value={getUserSecondaryPositionsLocalizedLong(t, user).join('\n')}
                />

                <InfoBox
                    title='preferred foot'
                    value={getPreferredFoot(user)}
                />

                <InfoBox
                    title='height'
                    value={getUserHeight(user, '-')}
                />

                <InfoBox
                    title='nationalities'
                    value={getUserNationalities(user).map(n => n.name).join(', ') || '-'}
                />

                <InfoBox
                    title='languages'
                    value={getUserLanguages(user).map(l => l.name).join(', ') || '-'}
                />

            </Box>
        )
    };

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
        >

            <Grid
                container
                alignItems={'center'}
            >

                <ETextField
                    className={classes.userIdInput}
                    onChange={e => setUserId(e)}
                    value={userId}
                    label={'User id'}
                    // margin="normal"
                    // variant="outlined"
                    grid={'1/3'}
                />

                <Grid item {...getGridSizes('1/3')}>
                    <EButton variant="contained" color="primary"
                             className={classes.loadUserButton}
                             onClick={handleUserFetch}>
                        Load user
                    </EButton>
                </Grid>

                <Grid item  {...getGridSizes('1/3')}>
                    <Box display={'flex'}
                         justifyContent={'center'}
                         className={classes.visibilityCTA}
                         alignItems={'center'}
                    >

                        <div className={classes.currentUserName} onClick={openUserHandler}>
                            {props.user ? getFullName(props.user) : 'No user selected'}
                        </div>

                        {
                            props.user && <EButton
                                variant="contained"
                                color="primary"
                                onClick={() => setExpanded(!expanded)}>
                                {expanded ? 'Hide details' : 'Show details'}
                            </EButton>
                        }

                    </Box>
                </Grid>

            </Grid>

            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                {renderUserInfo(props.user)}
            </Box>

        </Box>
    )
};

export default UserAnalyzer;
