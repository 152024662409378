import React, {Component} from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from './LanguageSearch.module.scss';
import debounce from "lodash/debounce";
import clsx from 'clsx';
import CommonService from "../../../services/common.service";
import {Box, TextField} from "@material-ui/core";
import {getLanguageFlag} from "../../../util/flag.util";

interface Props {
    open: boolean;
    onLanguagePicked: (language: any) => void;
    onClose: () => void;
    title?: string;
}

interface State {
    query: string;
    languages: OptionType[];
}

export interface OptionType {
    label: string;
    value: any;
    icon?: string;
}

class LanguageSearch extends Component<Props, State> {

    state: State = {
        query: '',
        languages: []
    };

    componentDidMount(): void {
        this.getLanguages('');
        this.executeSearch = debounce(this.executeSearch, 500);
    }

    queryHandler = (event: any) => {
        this.setState({query: event.target.value});
        this.executeSearch(event.target.value)
    };

    executeSearch = (query: string) => {
        this.getLanguages(query);
    };

    getLanguages = async (query: string) => {
        let languages = await CommonService.getLanguages(query);

        const suggestions = languages.map((language: any) => {
            return {
                label: `${language.name}`,
                value: language,
            }
        });
        this.setState({
            languages: suggestions
        })

    };

    renderLanguages() {
        return this.state.languages.map((language) => {
            return (
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    key={language.value.isoCode}
                    className={styles.language}
                    onClick={() => this.props.onLanguagePicked(language.value)}
                >
                    <div className={clsx(styles.flag, 'flag-icon', getLanguageFlag(language.value))}/>
                    <div>{language.label}</div>
                </Box>
            )
        })
    }

    render() {
        return (
            <Dialog aria-labelledby="simple-dialog-title" {...this.props} maxWidth={"sm"} fullWidth={true}
                    PaperProps={{
                        style: {
                            margin: 8,
                            width: 'calc(100% - 16px)'
                        },
                    }}
            >
                <Box className={styles.container}>

                    {this.props.title ? <div className={styles.title}>{this.props.title}</div> : null}

                    <TextField
                        onChange={this.queryHandler}
                        value={this.state.query}
                        className={styles.searchField}
                        id="outlined-search"
                        label={("search")}
                        type="search"
                        margin="normal"
                    />

                    <Box className={styles.languages}>
                        {this.renderLanguages()}
                    </Box>

                </Box>
            </Dialog>
        )
    }
}

export default LanguageSearch;
