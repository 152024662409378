import React, {useState} from 'react';
import ClubModel from "../../../../models/club.model";
import styles from './ClubAboutUs.module.scss';
import EditAboutUsPopup from "./EditAboutUsPopup/EditAboutUsPopup";
import clsx from 'clsx';
import EditClubNotPossiblePopup from "../EditClubHeaderAndLocationPopup/EditClubNotPossiblePopup";
import UserModel from "../../../../models/user.model";
import {useTranslation} from "react-i18next";
import {isUserApprovedAndClubApproved, isUserClubAdmin} from "../../../../util/profile.util";
import {fitText} from "../../../../util/text.util";
import Popup from "../../../common/Popup/Popup";
import {Box, Card} from "@material-ui/core";

interface Props {
    club?: ClubModel,
    user?: UserModel,
    onUpdate?: (club: ClubModel) => void;
}

const ClubAboutUs = (props: Props) => {

    // ***** State ***** //

    const [popupOpen, setPopupOpen] = useState(false);
    const [notAllowedPopupOpen, setNotAllowedPopupOpen] = useState(false);
    const [showMorePopupOpen, setShowMorePopupOpen] = useState(false);

    const t = useTranslation().t;

    // ***** Handler ***** //

    const editHandler = () => {
        if (!isUserApprovedAndClubApproved(props.user, props.club)) {
            setNotAllowedPopupOpen(true);
        } else {
            setPopupOpen(true);
        }
    };

    const saveHandler = (text: string) => {
        setPopupOpen(false);
        const club = {
            ...props.club,
            about: text
        };
        props.onUpdate?.(club);
    };

    // ***** Render ***** //

    let text = props.club?.about || 'Please add info about your club';
    const fittedText = fitText(text, 90, 15);
    const shouldShowReadMore = fittedText.length < text.length;

    return (
        <Card className={styles.component}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}
            >
                <Box
                    alignItems={'center'}
                    display={'flex'}
                    width={'100%'}
                    justifyContent={'space-between'}
                >
                    <p className={styles.sectionTitle}>{t('about_us')}</p>

                    {
                        isUserClubAdmin(props.user, props.club) &&
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            className={styles.editHolder}
                            onClick={editHandler}
                        >
                            <img alt={'Edit'} src={require('../../../../assets/images/edit-orange.svg').default}/>
                        </Box>
                    }

                </Box>
                <p className={styles.content}>
                    {fittedText}{shouldShowReadMore ?
                    <span className={styles.readMore}
                          onClick={() => setShowMorePopupOpen(false)}>{t('read_more')}</span> : null}
                </p>

                <EditAboutUsPopup
                    open={popupOpen}
                    onClose={() => setPopupOpen(false)}
                    onSave={saveHandler}
                    club={props.club}
                />

                <EditClubNotPossiblePopup
                    open={notAllowedPopupOpen}
                    onClose={() => setNotAllowedPopupOpen(false)}
                />

                <Popup
                    maxWidth={'sm'}
                    open={showMorePopupOpen}
                    onClose={() => setShowMorePopupOpen(false)}
                    text={props.club?.about || ''}
                />

            </Box>
        </Card>
    );
}

export default ClubAboutUs;
