import React, {useCallback, useEffect, useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@material-ui/core"
import debounce from "lodash/debounce"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";
import ETextField from "../../ETextField.component";

const InstatIndexFilter = () => {

    //region State

    const [fromInstatIndexParam] = useUrlParam({param: 'fromInstatIndex', dataType: 'numeric', defaultValue: 0})
    const [toInstatIndexParam] = useUrlParam({param: 'toInstatIndex', dataType: 'numeric', defaultValue: 0})

    const [fromInstatIndexLocal, setFromInstatIndexLocal] = useState(fromInstatIndexParam)
    const [toInstatIndexLocal, setToInstatIndexLocal] = useState(toInstatIndexParam)
    const [manualInput, setManualInput] = useState(false)

    const [expanded, setExpanded] = useState(!!fromInstatIndexLocal && !!toInstatIndexLocal)
    const history = useHistory()

    //endregion State

    //region Handlers

    const executeSet = useCallback(debounce((fromInstatIndex: any, toInstatIndex: any) => {
        setMultipleParams([
            {name: 'fromInstatIndex', value: fromInstatIndex, dataType: 'numeric'},
            {name: 'toInstatIndex', value: toInstatIndex, dataType: 'numeric'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }, 1000), [])

    useEffect(() => {
        if (!manualInput) return
        executeSet(fromInstatIndexLocal, toInstatIndexLocal)
    }, [fromInstatIndexLocal, toInstatIndexLocal, manualInput])

    //endregion Handlers

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="userName-content"
                id="userName-header"
            >
                <Typography>Instat index value {!!fromInstatIndexLocal && !!toInstatIndexLocal && `(${fromInstatIndexLocal} - ${toInstatIndexLocal})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container spacing={1}>
                    <ETextField
                        label={'From'}
                        grid={'1/2'}
                        value={fromInstatIndexLocal}
                        onChange={e => {
                            setManualInput(true)
                            setFromInstatIndexLocal(+e)
                        }}
                    />
                    <ETextField
                        label={'To'}
                        grid={'1/2'}
                        value={toInstatIndexLocal}
                        onChange={e => {
                            setManualInput(true)
                            setToInstatIndexLocal(+e)
                        }}
                    />
                </Grid>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default InstatIndexFilter