import React from 'react'
import {Box, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AnimatedBox from "./Animated.component";
import {DIMENSIONS, LANDING_COMMON_COLORS} from "./common";
import {useTranslation} from "react-i18next";
import {windowWidth} from "../../util/dimension.util";

const useStyle = makeStyles(_theme => ({
    component: {
        marginTop: 100,
        width: '100%',
    },
    title: {
        fontSize: DIMENSIONS.LARGE.TITLE_SIZE,
        color: LANDING_COMMON_COLORS.TITLE,
        marginBottom: 45,
        [_theme.breakpoints.down('md')]: {
            fontSize: DIMENSIONS.SMALL.TITLE_SIZE
        }
    },
    infoContainer: {
        width: '100%',
        maxWidth: 1500
    },
    infoBox: {
        padding: DIMENSIONS.LARGE.INFO_BOX_PADDING,
        cursor: 'default',
        '&:hover': {
            '& $infoIcon': {
                filter: 'invert(42%) sepia(0%) saturate(564%) hue-rotate(181deg) brightness(91%) contrast(87%)',
            }
        },
        [_theme.breakpoints.down('md')]: {
            padding: DIMENSIONS.SMALL.INFO_BOX_PADDING
        }
    },
    infoIcon: {
        width: DIMENSIONS.LARGE.INFO_ICON_SIZE,
        height: DIMENSIONS.LARGE.INFO_ICON_SIZE,
        filter: 'invert(60%) sepia(67%) saturate(379%) hue-rotate(95deg) brightness(95%) contrast(92%)',
        [_theme.breakpoints.down('md')]: {
            width: DIMENSIONS.SMALL.INFO_ICON_SIZE,
            height: DIMENSIONS.SMALL.INFO_ICON_SIZE,
        }
    },
    infoTitle: {
        marginTop: 37,
        marginBottom: 6,
        fontSize: DIMENSIONS.LARGE.INFO_TITLE_SIZE,
        lineHeight: DIMENSIONS.LARGE.INFO_TITLE_LINE_HEIGHT,
        textAlign: 'center',
        color: LANDING_COMMON_COLORS.TITLE,
        [_theme.breakpoints.down('md')]: {
            fontSize: DIMENSIONS.SMALL.INFO_TITLE_SIZE,
            lineHeight: DIMENSIONS.SMALL.INFO_TITLE_LINE_HEIGHT,
        }
    },
    infoMessage: {
        fontWeight: 300,
        fontSize: DIMENSIONS.LARGE.INFO_MESSAGE_SIZE,
        lineHeight: DIMENSIONS.LARGE.INFO_MESSAGE_LINE_HEIGHT,
        textAlign: 'center',
        color: LANDING_COMMON_COLORS.BOX_TEXT,
        whiteSpace: 'pre-wrap',
        [_theme.breakpoints.down('md')]: {
            fontSize: DIMENSIONS.SMALL.INFO_MESSAGE_SIZE,
            lineHeight: DIMENSIONS.SMALL.INFO_MESSAGE_LINE_HEIGHT,
        },
        '& b': {
            fontWeight: 500
        }
    }
}))

const AboutUs = () => {

    const classes = useStyle()
    const t = useTranslation().t

    const renderBox = (icon: any, title: string, message: string) => (

        <AnimatedBox
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={classes.infoBox}
            hover
            fadeIn
            fadeInThreshold={30}
        >
            <img
                className={classes.infoIcon}
                src={icon}
            />
            <div
                className={classes.infoTitle}
            >
                {title}
            </div>
            <div
                className={classes.infoMessage}
                dangerouslySetInnerHTML={{__html: message}}
            >
                {/*{message}*/}
            </div>
        </AnimatedBox>
    )



    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={classes.component}
        >
            <div className={classes.title}>
                {t("About Us")}
            </div>

            <Grid container spacing={windowWidth() < 600 ? 4 : 10} className={classes.infoContainer}>

                <Grid item xs={12} sm={6} md={4}>
                    {renderBox(
                        require('../../assets/images/icons/connect.svg').default,
                        t('Smart Matching Of Clubs & Players'),
                        t('Playerhunter easily connects clubs and players through a smart algorithm. Club requirements are matched with relevant player profiles which ensures an easy and transparent way to get quickly in contact with each other.')
                    )}
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    {renderBox(
                        require('../../assets/images/icons/support.svg').default,
                        t('Full service'),
                        t('Offer players to clubs in countries where it does not have its own network developed.')
                    )}
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    {renderBox(
                        require('../../assets/images/icons/like.svg').default,
                        t('What We Deliver'),
                        t('There are no monthly overpayments or any upfront payments.')
                    )}
                </Grid>

            </Grid>

        </Box>
    )
}

export default AboutUs
