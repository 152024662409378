import React, {useState} from 'react';
import ClubModel from "../../../../models/club.model";
import styles from './ClubTeams.module.scss';
import EditTeamsPopup from "./EditTeamsPopup/EditTeamsPopup";
import clsx from 'clsx';
import Team from "../../../../models/team.model";
import EditClubNotPossiblePopup from "../EditClubHeaderAndLocationPopup/EditClubNotPossiblePopup";
import ShowAllTeamsPopup from "./ShowAllTeamsPopup/ShowAllTeamsPopup";
import UserModel from "../../../../models/user.model";
import {useTranslation} from "react-i18next";
import {isUserApprovedAndClubApproved, isUserClubAdmin} from "../../../../util/profile.util";
import {generateTeam} from "../../../../util/team.util";
import {Box, Card} from "@material-ui/core";

interface Props {
    club?: ClubModel;
    user?: UserModel;
    onUpdate?: (club: ClubModel) => void;
}

const ClubTeams = (props: Props) => {

    // ***** State ***** //

    const [popupOpen, setPopupOpen] = useState(false);
    const [notAllowedPopupOpen, setNotAllowedPopupOpen] = useState(false);
    const [allTeamsPopupOpen, setAllTeamsPopupOpen] = useState(false);

    const t = useTranslation().t;

    // ***** Handlers ***** //

    const editHandler = () => {
        if (!isUserApprovedAndClubApproved(props.user, props.club)) {
            setNotAllowedPopupOpen(true);
        } else {
            setPopupOpen(true);
        }
    };

    const closeHandler = () => {
        setPopupOpen(false);
    };

    const saveHandler = (gender: string, levelId: number, teamId: number) => {
        setPopupOpen(false);
        const newTeams = [...(props.club?.teams || []), generateTeam(gender, levelId, teamId)];
        const club = {
            ...props.club,
            teams: newTeams
        };
        props.onUpdate?.(club);
    };

    const deleteHandler = (team: Team) => {
        const newTeams = props.club?.teams?.filter(t => t !== team);
        const club = {
            ...props.club,
            teams: newTeams
        };
        props.onUpdate?.(club);
    };

    // ***** Render ***** //

    const getTeams = () => {
        return props.club?.teams || [];
    }

    const teams = getTeams().slice(0, 6).map(team => {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                className={styles.team}
            >

                {
                    isUserClubAdmin(props.user, props.club) &&
                    <img alt={'Delete'} src={require('../../../../assets/images/x-primary.svg').default}
                         className={styles.deleteTeamIcon}
                         onClick={() => deleteHandler(team)}/>
                }

                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    className={styles.teamGenderHolder}
                >
                    <img alt={''} src={require('../../../../assets/images/hexagon-white.svg').default}
                         className={styles.teamGenderHex}/>
                    <img alt={'Gender'} className={styles.teamGender}
                         src={team.gender === 'MALE' ? require('../../../../assets/images/male.svg').default : require('../../../../assets/images/female.svg').default}/>
                </Box>
                <p className={styles.teamName}>{t(team.teamType?.name || '-')}</p>
                <p className={styles.leagueLevel}>League {team.level?.id}</p>
            </Box>
        )
    });

    return (
        <Card className={styles.component}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}
            >
                <Box
                    alignItems={'center'}
                    display={'flex'}
                    width={'100%'}
                    justifyContent={'space-between'}
                >
                    <p className={styles.sectionTitle}>{t('teams')}</p>

                    {
                        isUserClubAdmin(props.user, props.club) &&
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            className={styles.editHolder}
                            onClick={editHandler}
                        >
                            <img alt={'Add'} src={require('../../../../assets/images/add-orange.svg').default}/>
                        </Box>
                    }

                </Box>
                <Box
                    display={'flex'}
                    flexWrap={'wrap'}
                    className={styles.teams}
                >
                    {teams}
                </Box>

                {
                    getTeams().length > 6 &&
                    <div onClick={() => setAllTeamsPopupOpen(true)}
                         className={styles.showAllTeams}>{t("show_all_teams", {teamsCount: getTeams().length})}</div>
                }

                <EditTeamsPopup
                    open={popupOpen}
                    onClose={closeHandler}
                    onSave={saveHandler}
                    existingTeams={getTeams()}
                />

                <EditClubNotPossiblePopup
                    open={notAllowedPopupOpen}
                    onClose={() => setNotAllowedPopupOpen(false)}
                />

                <ShowAllTeamsPopup
                    open={allTeamsPopupOpen}
                    onClose={() => setAllTeamsPopupOpen(false)}
                    teams={getTeams()}
                    onAddNew={() => setPopupOpen(true)}
                    onDelete={deleteHandler}
                />

            </Box>
        </Card>
    );
}

export default ClubTeams;
