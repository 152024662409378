import React from 'react'
import {Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from "react-i18next";
import UserModel, {AgencyPosition} from "../../../models/user.model";
import {getEmail, getPhone} from "../../../util/profile.util";
import ETextField from "../../common/ETextField.component";
import {getGridSizes} from "../../common/grid.util";
import ESwitchField from "../../common/ESwitchField.component";

interface Props {
    user?: UserModel,
    setUser: (user: UserModel) => void,
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
    input: {
        width: '100%'
    }
}))


const AgentProfileTab = ({user, setUser}: Props) => {

    const classes = useStyle()
    const {t, i18n} = useTranslation()
    const profile = user?.profile || {}

    const updateProfileFields = (data: { field: string, value: any }[]) => {
        const newUser: any = {
            ...user,
            profile: {
                ...user?.profile
            }
        }
        data.forEach(d => newUser.profile[d.field] = d.value)
        setUser(newUser)
    }

    const updateProfileField = (field: string, value: any) => {
        updateProfileFields([{field, value}])
    }

    //endregion Handlers

    //region UI

    const contactUI = (
        <Grid
            container
            spacing={2}
            className={classes.sectionData}
        >

            <ETextField
                grid={'1/3'}
                label={t('Phone')}
                value={getPhone(user)}
                onChange={event => updateProfileField('contactInfo', {
                    ...user?.profile?.contactInfo,
                    phone: event
                })}
            />

            <ETextField
                grid={'1/3'}
                label={getEmail(user)}
                value={user?.profile?.contactInfo?.email || ''}
                onChange={event => updateProfileField('contactInfo', {
                    ...user?.profile?.contactInfo,
                    email: event
                })}
                disabled
            />


        </Grid>
    )

    const agencyInfoUI = (
        <Grid
            container
            spacing={2}
            className={classes.sectionData}
        >

            <ETextField
                grid={'1/3'}
                label={t('Agency name')}
                value={user?.profile?.agencyName || ''}
                onChange={event => updateProfileField('agencyName', event)}
            />

            <Grid item {...getGridSizes('1/3')}>
                <Box display={'flex'} alignItems={'center'}>
                    <FormControl style={{width: '100%'}} margin="normal" variant={'outlined'}>
                        <InputLabel id="agency-position-select-label">Agency position</InputLabel>
                        <Select
                            value={user?.profile?.agencyPosition ?? AgencyPosition.AGENT}
                            onChange={e => updateProfileField('agencyPosition', e.target.value)}
                            labelId={'agency-position-select-label'}
                        >
                            <MenuItem value={AgencyPosition.AGENT}>Agent</MenuItem>
                            <MenuItem value={AgencyPosition.OTHER}>Other</MenuItem>
                            <MenuItem value={AgencyPosition.SCOUT}>Scout</MenuItem>
                            <MenuItem value={AgencyPosition.OWNER_CEO}>Owner/CEO</MenuItem>

                        </Select>
                    </FormControl>

                </Box>
            </Grid>


        </Grid>
    )

    const miscUI = (
        <Grid
            container
            spacing={2}
            className={classes.sectionData}
        >

            <ESwitchField
                grid={'1/3'}
                label={t('Can create jobs')}
                value={user?.canCreateOpportunities || false}
                onChange={event => setUser({
                  ...user,
                  canCreateOpportunities: event
                })}
            />

            <ESwitchField
                grid={'1/3'}
                label={t('Can search players')}
                value={user?.canSearchPlayers || false}
                onChange={event => setUser({
                    ...user,
                    canSearchPlayers: event
                })}
            />

        </Grid>
    )

    return (
        <Box
            p={3}
            display={'flex'}
            flexDirection={'column'}
        >
            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Contact")}
            </Typography>
            {contactUI}

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Agency info")}
            </Typography>
            {agencyInfoUI}

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Misc")}
            </Typography>
            {miscUI}

        </Box>
    )

    //endregion UI
}

export default AgentProfileTab
