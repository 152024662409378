import React, {useEffect, useState} from "react";
import {Box, CircularProgress} from "@material-ui/core";
import {
    emptyNumberValidator,
    isFormFieldValid,
    isFormValid,
    validateForm,
    validateFormFields
} from "../../util/form.util";
import AlgorithmService from "../../services/algorithm.service";
import ETextField from "../common/ETextField.component";
import PositionMatrix from "./PositionMatrix.component";
import EButton from "../common/EButton";
import {makeStyles} from "@material-ui/core/styles";
// import FlexView from "react-flexview/lib/FlexView";
// import styles from './Algorithms.module.scss';
// import OpportunityFeed from "./opportunityFeed/OpportunityFeed";
// import ApplicantsRanking from "./applicantRanking/ApplicantRanking";
// import {useRouter} from "next/router";


const useStyles = makeStyles(theme => ({
    container: {
        width: 'calc(100% - 20px)',
            maxWidth: '1000px',
        padding: '16px',
        marginBottom: '100px',
        //min-height: 100px;
    },
    header: {
        marginBottom: '30px',
        marginTop: '30px',
        justifyContent: 'space-between',
        width: '100%',
    },
    row: {
        width: '100%',
        justifyContent: 'space-between',
    },
    info: {
        fontSize: '10px'
    },
    containerTitle: {
        width: '100%',
        textAlign: 'left',
        fontSize: '20px',
        letterSpacing: '0.15px',
        color: '#000000'

    },
    input: {
        width: 'calc(100% - 100px)'
    },
    done: {
    marginTop: '20px !important'
}

}))

const OpportunityFeed = () => {

    // region state

    const [form, setForm] = useState<any>({
        primaryPosition: {
            value: '',
            placeholder: 'Primary position factor',
            type: 'number',
            validation: {
                validators: [emptyNumberValidator]
            }
        },
        secondaryPosition: {
            value: '',
            placeholder: 'Secondary position factor',
            type: 'number',
            validation: {
                validators: [emptyNumberValidator]
            }
        },
        maxLevelPoints: {
            value: '',
            placeholder: 'Max level points',
            type: 'number',
            validation: {
                validators: [emptyNumberValidator]
            }
        },
        absoluteLevelMultiplier: {
            value: '',
            placeholder: 'Absolute level multiplier',
            type: 'number',
            validation: {
                validators: [emptyNumberValidator]
            }
        },
        alternatingLevelMultiplier: {
            value: '',
            placeholder: 'Alternating level multiplier',
            type: 'number',
            validation: {
                validators: [emptyNumberValidator]
            }
        },
        height: {
            value: '',
            placeholder: 'Height',
            type: 'number',
            validation: {
                validators: [emptyNumberValidator]
            }
        },
        foot: {
            value: '',
            placeholder: 'Foot',
            type: 'number',
            validation: {
                validators: [emptyNumberValidator]
            }
        },
        language: {
            value: '',
            placeholder: 'Language',
            type: 'number',
            validation: {
                validators: [emptyNumberValidator]
            }
        },
        nationality: {
            value: '',
            placeholder: 'Nationality',
            type: 'number',
            validation: {
                validators: [emptyNumberValidator]
            }
        },
    });

    const [savingInProgress, setSavingInProgress] = useState(false);
    const [error, setError] = useState(false);
    const [id, setId] = useState(false);
    const classes = useStyles()

    // endregion state

    // region handlers

    const loadWeights = async () => {
        const weight = await AlgorithmService.getOpportunityFeedWeights();
        setId(weight.id);
        setForm({
            ...form,
            primaryPosition: {
                ...form.primaryPosition,
                value: weight.primaryPosition
            },
            secondaryPosition: {
                ...form.secondaryPosition,
                value: weight.secondaryPosition
            },
            maxLevelPoints: {
                ...form.maxLevelPoints,
                value: weight.maxLevelPoints
            },
            absoluteLevelMultiplier: {
                ...form.absoluteLevelMultiplier,
                value: weight.absoluteLevelMultiplier
            },
            alternatingLevelMultiplier: {
                ...form.alternatingLevelMultiplier,
                value: weight.alternatingLevelMultiplier
            },
            height: {
                ...form.height,
                value: weight.height
            },
            foot: {
                ...form.foot,
                value: weight.foot
            },
            language: {
                ...form.language,
                value: weight.language
            },
            nationality: {
                ...form.nationality,
                value: weight.nationality
            }
        });
    }

    useEffect(() => {
        loadWeights();
    }, []);

    const inputHandler = (key: string, value: string) => {
        const formElement = {
            ...form[key],
            validation: {
                ...form[key].validation,
                error: ''
            }
        };
        formElement.value = value === '' ? '' : +value;
        setForm({
            ...form,
            [key]: formElement
        });
    };

    const saveHandler = async () => {
        const newForm = validateForm(form);
        setForm(newForm);

        if (isFormValid(newForm)) {
            const weights = {
                id: id,
                primaryPosition: form.primaryPosition.value,
                secondaryPosition: form.secondaryPosition.value,
                maxLevelPoints: form.maxLevelPoints.value,
                absoluteLevelMultiplier: form.absoluteLevelMultiplier.value,
                alternatingLevelMultiplier: form.alternatingLevelMultiplier.value,
                height: form.height.value,
                foot: form.foot.value,
                language: form.language.value,
                nationality: form.nationality.value,
                leagueLevelOneAboveOrBelow: 0,
                location: 0,
                locationInverse: 0,
                positions: 0,
                sameLeagueLevel: 0
            };
            try {
                setSavingInProgress(true);
                setError(false);
                await AlgorithmService.updateOpportunityFeedWeights(weights)
            } catch (e) {
                setError(true);
            } finally {
                setSavingInProgress(false);
            }
        }
    };

    //endregion handlers


    // region UI

    const renderInputField = (key: string) => {
        const formElement = form[key];
        return (
            <ETextField
                onChange={(event) => inputHandler(key, event)}
                value={formElement.value}
                className={classes.input}
                label={formElement.placeholder}
                mandatory
                grid={'1/2'}
            />
        )
    }

    const totalPoints = (): number => {
        let total = 0;
        Object.keys(form).forEach(key => {
            total += getValueForKey(key);
        });
        return total + 50;
    };

    const getValueForKey = (key: string): number => {
        return form[key].value;
    };

    const renderPercentForKey = (key: string, multiplier: number = 1) => {
        return (
            <div>
                {(multiplier * getValueForKey(key) / totalPoints() * 100).toFixed(2)}%
            </div>
        );
    };

    return (

        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            className={classes.container}
        >

            <PositionMatrix algorithm={'feed'}/>

            <p className={classes.containerTitle}>{'Opportunity feed'}</p>

            <Box
                alignItems={'center'}
                className={classes.header}>
                <Box>Weight</Box>
                <Box>Effect on calculation (%)</Box>
            </Box>

            <Box
                alignItems={'center'}
                display={'flex'}
                className={classes.row}
            >
                {renderInputField('primaryPosition')}
                {renderPercentForKey('primaryPosition', 50)}
            </Box>

            <Box
                alignItems={'center'}
                display={'flex'}
                className={classes.row}
            >
                {renderInputField('secondaryPosition')}
                {renderPercentForKey('secondaryPosition', 50)}
            </Box>

            <div
                className={classes.info}
            >
                Max level points = {form?.maxLevelPoints?.value}
            </div>
            <div
                className={classes.info}
            >
                1 level better
                = {form?.maxLevelPoints?.value - form?.absoluteLevelMultiplier?.value + form?.alternatingLevelMultiplier?.value},
                2 level better
                = {form?.maxLevelPoints?.value - 2 * form?.absoluteLevelMultiplier?.value + form?.alternatingLevelMultiplier?.value},
            </div>
            <div
                className={classes.info}
            >
                1 level worse
                = {form?.maxLevelPoints?.value - form?.absoluteLevelMultiplier?.value},
                2 level worse
                = {form?.maxLevelPoints?.value - 2 * form?.absoluteLevelMultiplier?.value},
            </div>

            <Box
                alignItems={'center'}
                display={'flex'}
                className={classes.row}
            >
                {renderInputField('maxLevelPoints')}
                {renderPercentForKey('maxLevelPoints')}
            </Box>

            <Box
                display={'flex'}
                alignItems={'center'}
                className={classes.row}
            >
                {renderInputField('absoluteLevelMultiplier')}
            </Box>

            <Box
                display={'flex'}
                alignItems={'center'}
                className={classes.row}
            >
                {renderInputField('alternatingLevelMultiplier')}
            </Box>

            <Box
                display={'flex'}
                alignItems={'center'}
                className={classes.row}
            >
                {renderInputField('height')}
                {renderPercentForKey('height')}
            </Box>

            <Box
                display={'flex'}
                alignItems={'center'}
                className={classes.row}
            >
                {renderInputField('foot')}
                {renderPercentForKey('foot')}
            </Box>

            <Box
                display={'flex'}
                alignItems={'center'}
                className={classes.row}
            >
                {renderInputField('language')}
                {renderPercentForKey('language')}
            </Box>

            <Box
                display={'flex'}
                className={classes.row}
                alignItems={'center'}

            >
                {renderInputField('nationality')}
                {renderPercentForKey('nationality')}
            </Box>

            {error && <div
                // className={styles.errorMsg}
            >Something went wrong.Please try again later</div>}

            {
                savingInProgress ?

                    <CircularProgress/> :

                    <EButton variant="contained" color="primary"
                        className={[classes.done].join(' ')}
                             onClick={saveHandler}>
                        Save
                    </EButton>
            }

        </Box>
    );

    // endregion UI
}

export default OpportunityFeed;
