import React, {useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";
import {SubscriptionType} from "../../../../models/subscription.model";

const SubscriptionTypeFilter = () => {

    //region State

    const [subscriptionType] = useUrlParam({param: 'subscriptionType', dataType: 'string', defaultValue: ''})
    const [expanded, setExpanded] = useState(subscriptionType !== '')
    const history = useHistory()

    //endregion State

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="subscription-type-content"
                id="subscription-type-header"
            >
                <Typography>Subscription
                    status {subscriptionType !== '' && `(${subscriptionType?.toLowerCase()})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="verification_foot"
                        name="verification_foot"
                        value={subscriptionType}
                        onChange={e => {
                            setMultipleParams([
                                {name: 'subscriptionType', value: e.target.value || '', dataType: 'string'},
                                {name: 'listPage', value: 1, dataType: 'numeric'}
                            ], history)
                        }}
                    >
                        <FormControlLabel value={SubscriptionType.PREMIUM} control={<Radio/>} label="Premium"/>
                        <FormControlLabel value={SubscriptionType.PRO} control={<Radio/>} label="Pro"/>
                        <FormControlLabel value="" control={<Radio/>} label="All"/>
                    </RadioGroup>
                </FormControl>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default SubscriptionTypeFilter