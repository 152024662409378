import React, {FunctionComponent} from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from './EditClubNotPossiblePopup.module.scss'
import clsx from 'clsx';
import {useTranslation} from "react-i18next";
import {Box, Button} from "@material-ui/core";

interface Props {
    open: boolean,
    history?: any,
    onClose: () => void,
}


const EditClubNotPossiblePopup: FunctionComponent<Props> = (props: Props) => {

    const t = useTranslation().t;

    return (
        <Dialog aria-labelledby="simple-dialog-title" {...props} maxWidth={"xs"} fullWidth={true}>

            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                className={styles.alertContainer}
            >
                <img alt={'Warning'} src={require('../../../assets/images/icons/warning-red.svg').default}
                     className={styles.alertImg}/>
                <div className={styles.alertText}>{t('profile_verification_in_progress')}</div>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    className={styles.contactInfoContainer}
                >

                    <div className={styles.section}>{t("call_us")}</div>
                    <a className={styles.link} href="tel:+436645154196">+43 664 5154196 (Austria)</a>
                    <a className={styles.link} href="tel:+38163449091">+38163449091 (Srbija)</a>
                    <div className={styles.linkSubtitle}>{t("monday")} - {t("friday")} | 9:00 - 16:00</div>

                    <div className={clsx(styles.section, styles.secondSection)}>{t("write_us")}</div>
                    <a className={styles.link} href="mailto:support@playerhunter.com">support@playerhunter.com</a>
                    <div className={styles.linkSubtitle}>{t("we_will_get_to_you_in_24h")}</div>

                </Box>
                <Button variant="contained" color="primary" onClick={props.onClose}>
                    {t("done")}
                </Button>
            </Box>
        </Dialog>
    );
};


export default EditClubNotPossiblePopup;
