import React, {useEffect, useState} from 'react'
import classes from './Carousel.module.scss'
import clsx from "clsx";

interface Props {
    items: { id: string, element: JSX.Element }[],
    active: number,
    direction: string,
    variant: 3 | 5
}

const Carousel = (props: Props) => {

    const [active, setActive] = useState(0)
    // const [direction, setDirection] = useState<'left' | 'right'>('right')

    const getItemClass = (index: number) => {
        for (let j = -Math.floor(props.variant / 2); j <= Math.floor(props.variant / 2); j++) {

            let itemIndex = active + j
            if (itemIndex < 0) {
                itemIndex = props.items.length + itemIndex
            } else if (itemIndex >= props.items.length) {
                itemIndex = itemIndex % props.items.length
            }

            let levelClass = classes.level0;
            switch (j) {
                case -2:
                    levelClass = classes.levelMinus2
                    break
                case -1:
                    levelClass = classes.levelMinus1
                    break
                case 0:
                    levelClass = classes.level0
                    break
                case 1:
                    levelClass = classes.level1
                    break
                case 2:
                    levelClass = classes.level2
                    break
            }

            if (index === itemIndex) return levelClass
        }
        return classes.hidden
    }


    useEffect(() => {
        setActive(props.active)
    }, [props.active])

    useEffect(() => {
        const clear = setTimeout(moveRight, 3000)
        return () => clearTimeout(clear)
    }, [active])

    // const moveLeft = () => {
    //     const newActive = active - 1
    //     setActive(newActive < 0 ? props.items.length - 1 : newActive)
    //     setDirection('left')
    // }

    const moveRight = () => {
        const newActive = active + 1
        setActive(newActive % props.items.length)
        // setDirection('right')
    }

    return (
        <div className={clsx(classes.carousel, classes.noselect)}>
            {
                props.items.map((item, index) => {
                    return (
                        <Item
                            key={item.id}
                            id={item.id}
                            element={item.element}
                            levelClass={getItemClass(index)}
                        />
                    )
                })
            }
        </div>
    )
}

const Item = ({id, element, levelClass}: { id: string, element: JSX.Element, levelClass: string }) => {

    useEffect(() => {
        console.log('Item created', id)
    }, [])

    return (
        <div key={id} id={id} className={clsx(classes.item, levelClass)}>
            {element}
        </div>
    )
}

export default Carousel