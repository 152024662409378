import apollo from "./api/apollo";
import {
    CREATE_CLUB, CREATE_CLUB_REQUEST,
    DELETE_CLUB_REQUEST,
    GET_CLUB_REQUESTS,
    GET_CLUB_REQUESTS_ADMIN,
    GET_CLUBS,
    GET_CLUBS_ADMIN,
    GET_CLUBS_QUERY_WITH_LOCATION,
    RESOLVE_CLUB_REQUEST
} from "./api/clubQueries";
import ClubModel from "../models/club.model";
import SubscriptionService from "./subscription.service";
import Page from "../models/page.model";

export default class ClubsService {

    static getClubs = async (paging: any, name?: string): Promise<Page<ClubModel>> => {
        let response;
        response = await apollo.query({
            query: GET_CLUBS,
            variables: {
                paging: paging,
                name: name,
                latitude: null,
                longitude: null
            }
        });

        const clubsContent = response?.data?.clubs?.content?.map((club: ClubModel) => {
            return {
                ...club,
                payments: club?.payments?.map(SubscriptionService.convertToWeb)
            }
        });

        return {
            ...response.data.clubs,
            content: clubsContent
        }
    };

    static getAdminClubs = async (paging: any, filters: any): Promise<Page<ClubModel>> => {
        let response;
        response = await apollo.query({
            query: GET_CLUBS_ADMIN,
            variables: {
                paging: paging,
                filter: filters
            }
        });

        const clubsContent = response?.data?.adminClubs?.content?.map((club: ClubModel) => {
            return {
                ...club,
                payments: club?.payments?.map(SubscriptionService.convertToWeb)
            }
        });

        return {
            ...response.data.adminClubs,
            content: clubsContent
        }
    };

    static getClubRequests = async (paging: any, admin = false): Promise<Page<ClubModel>> => {
        let response;
        response = await apollo.query({
            query: admin ? GET_CLUB_REQUESTS_ADMIN : GET_CLUB_REQUESTS,
            variables: {
                paging: paging,
                filter: {
                    deleted: false
                }
            },
            errorPolicy: 'ignore'
        });

        const clubRequestsContent = response?.data?.clubRequests?.content?.map((club: ClubModel) => {
            return {
                ...club,
                payments: club?.payments?.map(SubscriptionService.convertToWeb)
            }
        });

        return {
            ...response.data.clubRequests,
            content: clubRequestsContent
        }
    };

    static getClubsByLocation = async (name: string, latitude?: number, longitude?: number, paging?: any) => {
        try {
            const response = await apollo.query({
                query: GET_CLUBS_QUERY_WITH_LOCATION,
                variables: {
                    name,
                    latitude,
                    longitude,
                    paging
                }
            });

            const clubsContent: ClubModel[] = response?.data?.clubs?.content?.map((club: ClubModel) => {
                return {
                    ...club,
                    payments: club?.payments?.map(SubscriptionService.convertToWeb)
                }
            });

            return {
                ...response.data.clubs,
                content: clubsContent
            }
        } catch (error) {
            return [];
        }
    };

    static resolveClubRequest = async (clubId: number, clubRequestId: number): Promise<ClubModel | undefined> => {
        try {
            const response = await apollo.mutate({
                mutation: RESOLVE_CLUB_REQUEST,
                variables: {
                    clubId: clubId,
                    clubRequestId: clubRequestId
                }
            });

            return response?.data?.resolveClubRequest;
        } catch (error) {
            console.error(error);
        }
    };

    static deleteClubRequest = async (clubRequestId: number): Promise<ClubModel | undefined> => {
        try {
            const response = await apollo.mutate({
                mutation: DELETE_CLUB_REQUEST,
                variables: {
                    id: clubRequestId
                }
            });

            return response?.data?.deleteClubRequest;
        } catch (error) {
            console.error(error);
        }
    };

    static createClub = async (club: ClubModel): Promise<ClubModel | undefined> => {
        try {
            const response = await apollo.mutate({
                mutation: CREATE_CLUB,
                variables: {
                    club: club
                }
            });

            return response?.data?.club;
        } catch (error) {
            console.error(error);
        }
    };

    static createClubRequest = async (clubRequest: ClubModel): Promise<ClubModel | undefined> => {
        try {
            const response = await apollo.mutate({
                mutation: CREATE_CLUB_REQUEST,
                variables: {
                    clubRequest: clubRequest
                }
            });

            return response?.data?.clubRequest;
        } catch (error) {
            console.error(error);
        }
    };
}
