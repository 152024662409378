import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from './ExportClubsPopup.module.scss';
import * as Excel from 'exceljs';
import {saveAs} from "file-saver";
import {Box, CircularProgress} from "@material-ui/core";
import ClubModel from "../../../models/club.model";
import {getEmail, getFullName, getPhone} from "../../../util/profile.util";
import ClubsService from "../../../services/clubs.service";
import EButton from "../../common/EButton";

interface Props {
    open: boolean,
    onClose: () => void,
    selected: ClubModel[],
    exportType: string,
    filters: any,
}

const ExportClubsPopup: FunctionComponent<Props> = (props: Props) => {

    // ***** State ***** //

    const [status, setStatus] = useState<string>();
    const [downloading, setDownloading] = useState(false);

    const shouldBeCanceled = useRef(false);

    useEffect(() => {
        if (props.open) {
            setStatus('');
        }
    }, [props.open]);

    // ***** Handlers ***** //

    const addWorksheetColumns = (worksheet: Excel.Worksheet) => {
        const columns = [
            {header: 'Id', key: 'id', width: 32},
            {header: 'Name', key: 'name', width: 32},
            {header: 'City', key: 'city', width: 32},
            {header: 'Country', key: 'country', width: 32},
            {header: 'Logo', key: 'logo', width: 32},
            {header: 'Admin 1 name', key: 'admin1name', width: 32},
            {header: 'Admin 1 email', key: 'admin1email', width: 32},
            {header: 'Admin 1 phone', key: 'admin1phone', width: 32},
            {header: 'Admin 2 name', key: 'admin2name', width: 32},
            {header: 'Admin 2 email', key: 'admin2email', width: 32},
            {header: 'Admin 2 phone', key: 'admin2phone', width: 32},
            {header: 'Admin 3 name', key: 'admin3name', width: 32},
            {header: 'Admin 3 email', key: 'admin3email', width: 32},
            {header: 'Admin 3 phone', key: 'admin3phone', width: 32},
            {header: 'About', key: 'about', width: 32},
            {header: 'Teams', key: 'teams', width: 32},
            {header: 'Albums', key: 'albums', width: 32},
            {header: 'Created', key: 'created', width: 32},
            {header: 'Subscription start', key: 'subscriptionStart', width: 32},
            {header: 'Subscription end', key: 'subscriptionEnd', width: 32},
            {header: 'Subscription type', key: 'subscriptionType', width: 32},
            {header: 'Subscription duration', key: 'subscriptionDuration', width: 32},
            {header: 'Subscription jobs limit', key: 'subscriptionJobs', width: 32},
        ];

        worksheet.columns = columns;
    };

    const generateClubData = (club: ClubModel) => {
        const payment = club?.payments?.[0];

        const teams = club?.teams?.map(team => {
            return `${team?.teamType?.name} ${team?.gender} ${team?.level?.value}`;
        }).join(',\n');

        return {
            id: club?.id,
            name: club?.name,
            city: club?.city?.name,
            country: club?.country?.name,
            logo: club?.logo,
            admin1name: getFullName(club?.admins?.[0]),
            admin1email: getEmail(club?.admins?.[0]),
            admin1phone: getPhone(club?.admins?.[0]),
            admin2name: getFullName(club?.admins?.[1]),
            admin2email: getEmail(club?.admins?.[1]),
            admin2phone: getPhone(club?.admins?.[1]),
            admin3name: getFullName(club?.admins?.[2]),
            admin3email: getEmail(club?.admins?.[2]),
            admin3phone: getPhone(club?.admins?.[2]),
            about: club?.about,
            teams: teams,
            albums: club?.albums?.totalElements,
            created: club?.createdAt,
            subscriptionStart: payment && new Date(payment.paymentFrom!),
            subscriptionEnd: payment && new Date(payment.paymentTo!),
            subscriptionType: payment?.paymentType,
            subscriptionDuration: payment?.paymentDuration,
            subscriptionJobs: payment?.jobLimit === -1 ? 'UNLIMITED' : payment?.jobLimit
        }
    }

    const fillRows = async (worksheet: Excel.Worksheet, clubs: ClubModel[]) => {
        for (let i = 0; i < clubs.length; i++) {
            const club = clubs[i];
            const clubData = generateClubData(club);
            worksheet.addRow(clubData);
        }
    };

    const exportToFile = async (clubs: ClubModel[]) => {
        let workbook = new Excel.Workbook();

        const worksheet = workbook.addWorksheet('Clubs');
        await addWorksheetColumns(worksheet);
        await fillRows(worksheet, clubs);

        const buf = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buf]), 'export.xlsx');
    };

    const startExport = async () => {
        let clubs: ClubModel[] = [];

        setDownloading(true);
        shouldBeCanceled.current = false;

        if (props.exportType === 'all') {
            let allClubsDownloaded = false;
            let page = 0;
            let totalElements;
            setStatus('Downloading clubs...');
            while (!allClubsDownloaded) {
                if (shouldBeCanceled.current) {
                    setStatus('');
                    return;
                }

                let downloadedPage = await ClubsService.getAdminClubs({page, size: 20}, props.filters);

                totalElements = downloadedPage.totalElements;
                clubs.push(...(downloadedPage.content || []));
                page++;

                if ((downloadedPage?.content?.length || 0) < 20) {
                    allClubsDownloaded = true;
                }

                setStatus(`Downloading clubs... Downloaded ${clubs.length} clubs (of total ${totalElements})`);
            }
        } else {
            clubs = props.selected;
        }

        setStatus(`Total clubs for export: ${clubs.length}`);

        await exportToFile(clubs);
        setDownloading(false);
    };

    const cancelExport = async () => {
        setDownloading(false);
        shouldBeCanceled.current = true;
    };

    // ***** UI ***** //

    const titleContent = props.exportType === 'all' ?
        <div className={styles.title}>You want to export data for ALL filtered clubs</div> :
        <div className={styles.title}>You want to export data for {props.selected.length} clubs</div>;

    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            {...props}
            maxWidth={"xs"}
            fullWidth={true}
            disableBackdropClick={downloading}
        >

            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                className={styles.container}>
                {titleContent}

                {!!status && <div className={styles.progressText}>{status}</div>}

                {downloading && <CircularProgress className={styles.progressIndicator}/>}

                {downloading ?
                    <EButton variant="contained"
                             className={styles.ctaButton}
                             color="primary"
                             onClick={cancelExport}>
                        Cancel
                    </EButton>
                    :
                    <EButton variant="contained"
                             className={styles.ctaButton}
                             color="primary"
                             onClick={startExport}>
                        Start export
                    </EButton>
                }
            </Box>
        </Dialog>
    )
}

export default ExportClubsPopup;
