import React, {useEffect, useState} from 'react';
import styles from './PhoneNumberInput.module.scss';
import clsx from 'clsx';
import {Box, TextField, withStyles} from "@material-ui/core";
import CountrySearch from "../CountrySearch/CountrySearch";
import {parsePhoneNumberFromString} from 'libphonenumber-js/max'
import {TextFieldProps} from "@material-ui/core/TextField/TextField";
import CountryModel from "../../../models/country.model";
import {countries, getCountryByCountryCode} from "../../../services/countries";
import {getCountryFlag} from "../../../util/flag.util";


const Input = withStyles({
    root: {
        '& .MuiInputBase-root': {
            paddingLeft: 35
        },
    },
})(TextField);

interface Props {
    onPhoneNumberChange: (phone: string) => void;
    phoneNumber: string;
    ipCountry?: CountryModel
}

export type PhoneNumberInputProps = Props & TextFieldProps;

const PhoneNumberInput = (props: PhoneNumberInputProps) => {

    const [country, setCountry] = useState(countries[0]);

    const prefixWithPhoneCode = `+${country.phoneCode}`;

    const [value, setValue] = useState('+43');
    const [countrySearchOpen, setCountrySearchOpen] = useState(false);

    useEffect(() => {
        if (props.phoneNumber) {
            let newValue = props.phoneNumber;
            if (!newValue.startsWith('+')) {
                newValue = '+' + newValue;
            }

            const parsed = parsePhoneNumberFromString(newValue);
            if (parsed) {
                const newCountry = getCountryByCountryCode(parsed.country as string);
                if (newCountry && newCountry.countryCode !== country.countryCode) setCountry(newCountry);
                newValue = parsed.formatInternational();
            }

            setValue(newValue);
        }
    }, [props.phoneNumber]);

    useEffect(() => {
        if (props.ipCountry?.phoneCode) {
            let newValue = props.ipCountry.phoneCode;
            if (!newValue.startsWith('+')) {
                newValue = '+' + newValue;
            }
            setCountry(props.ipCountry)
            setValue(newValue)
        }
    }, [props.ipCountry])

    const onValueChange = (e: any) => {
        let newValue = e.target.value;

        if (!newValue.startsWith(prefixWithPhoneCode)) {
            newValue = prefixWithPhoneCode;
        }

        const parsed = parsePhoneNumberFromString(newValue);
        if (parsed) {
            // const newCountry = getCountryByCountryCode(parsed.country as string);
            // if (newCountry) setCountry(newCountry);
            newValue = parsed.formatInternational();
        }

        newValue = '+' + newValue.replace(/[^0-9^\s]/gi, '');

        setValue(newValue);

        props.onPhoneNumberChange(newValue);
    };

    const onChangeCountry = (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        setCountrySearchOpen(true);
    };

    const onCountryPick = (country: any) => {
        setCountry(country);
        setCountrySearchOpen(false);

        setValue(`+${country.phoneCode}`);
    };

    let bottom;
    if (props.variant === 'outlined') {
        bottom = props.error ? '50px' : '25px';
    } else {
        bottom = props.error ? '36px' : '16px';
    }

    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            className={props.className}
            style={{position: 'relative'}}
        >
            <div
                className={clsx(styles.flag, 'flag-icon', getCountryFlag(country))}
                style={{
                    bottom: bottom,
                    position: 'absolute',
                    left: props.variant === 'outlined' ? '16px' : '0px'
                }}
                onClick={onChangeCountry}
            />
            <Input
                {...props}
                value={value}
                onChange={onValueChange}
            />

            <CountrySearch
                open={countrySearchOpen}
                onCountryPicked={onCountryPick}
                onClose={() => setCountrySearchOpen(false)}
                showCountryPhoneCode
            />

        </Box>
    )
};

export default PhoneNumberInput;
