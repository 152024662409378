import React, {useEffect, useState} from 'react'
import {Box, CircularProgress, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {StripeProduct, StripeProductPrice} from "../../models/StripeModels/stripeProduct.model";
import SubscriptionService from "../../services/subscription.service";
import AnimatedBox from "./Animated.component";
import {DIMENSIONS, LANDING_COMMON_COLORS} from "./common";
import {useTranslation} from "react-i18next";

const useStyle = makeStyles(_theme => ({
    component: {
        marginTop: 100,
        width: '100%',
        position: 'relative',
    },
    title: {
        fontSize: DIMENSIONS.LARGE.TITLE_SIZE,
        color: LANDING_COMMON_COLORS.TITLE,
        marginBottom: 45,
        textAlign: 'center',
        [_theme.breakpoints.down('md')]: {
            fontSize: DIMENSIONS.SMALL.TITLE_SIZE
        }
    },
    package: {
        background: '#FFF',
        width: '100%',
        maxWidth: 400,
        borderRadius: 16,
        overflow: 'hidden',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12)'
    },
    packagePro: {
        boxShadow: '0px 10px 55px 5px rgba(65, 196, 126, 0.35)',
    },
    headerHolder: {
        width: '100%',
        height: 100,
        borderBottom: '1px solid #FFF'
    },
    amateur: {
        background: '#FF966A',
    },
    pro: {
        background: _theme.palette.primary.main
    },
    headerPackageName: {
        fontWeight: 'bold',
        fontSize: 30,
        lineHeight: '35px',
        textAlign: 'center',
        color: '#FFF'
    },
    headerPackageSubtitle: {
        fontWeight: 'bold',
        fontSize: 18,
        lineHeight: '21px',
        textAlign: 'center',
        color: '#FFF',
        marginTop: 7
    },
    priceHolder: {
        width: '100%',
        height: 100,
    },
    priceCurrency: {
        fontSize: 18,
        fontWeight: 'bold',
        alignSelf: 'flex-start',
        color: '#FFF',
        marginRight: 2
    },
    priceText: {
        fontWeight: 'bold',
        fontSize: 48,
        lineHeight: '48px',
        textAlign: 'center',
        color: '#FFF'
    },
    pricePeriod: {
        fontSize: 18,
        fontWeight: 'bold',
        alignSelf: 'flex-end',
        color: '#FFF',
        marginLeft: 2,
        marginBottom: 5
    },
    tickText: {
        marginLeft: 10,
        color: LANDING_COMMON_COLORS.TITLE,
        fontSize: 14,
        lineHeight: '16px'
    },
    ctaButton: {
        marginTop: 40,
        padding: '0 37px',
        height: 48,
        borderRadius: 24,
        cursor: 'pointer'
    },
    ctaText: {
        color: '#FFF',
        fontSize: 18
    },
    ctaSubtitle: {
        color: '#979797',
        fontSize: 14,
        textAlign: 'center'
    },
    anchor: {
        position: 'absolute',
        top: -100
    }
}))

const TICK_ORANGE = require('../../assets/images/landing/tick-orange.svg').default
const TICK_GREEN = require('../../assets/images/landing/tick-green.svg').default

interface Props {
    showTitle?: boolean,
    extraClass?: string,
    onPackageSelected: (stripeProduct: StripeProduct, price?: StripeProductPrice) => void
}

const Pricing = (props: Props) => {

    const [products, setProducts] = useState<StripeProduct[]>([]);
    const [productsState, setProductsState] = useState<'' | 'loading' | 'error'>("loading");
    const classes = useStyle()
    const t = useTranslation().t

    // ***** Effects ***** //

    useEffect(() => {
        const fetchPackages = async () => {
            try {
                setProductsState("loading");
                const products = await SubscriptionService.getStripeProducts();
                setProducts(products)
                setProductsState('');
            } catch (e) {
                console.log(e);
                setProducts([]);
                setProductsState("error");
            }
        }

        fetchPackages();
    }, []);


    const renderTick = (icon: any, text: string) => {
        return (
            <Box
                display={'flex'}
                alignItems={'center'}
                width={'100%'}
                marginTop={'10px'}
            >
                <img src={icon}/>
                <div className={classes.tickText} dangerouslySetInnerHTML={{__html: text}}/>
            </Box>
        )
    }

    const renderAmateur = () => (
        <AnimatedBox
            className={classes.package}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}

            fadeInThreshold={30}
            fadeIn
            hover
            hoverType={'static'}
        >
            <Box
                className={clsx(classes.headerHolder, classes.amateur)}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <div className={classes.headerPackageName}>
                    {t("AMATEUR LEAGUE")}
                </div>
                <div className={classes.headerPackageSubtitle}>
                    {t("CLUBS")}
                </div>
            </Box>

            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                className={clsx(classes.priceHolder, classes.amateur)}
            >
                {
                    productsState === 'loading' ? <CircularProgress/> :
                        <Box
                            display={'flex'}
                        >
                            <div className={classes.priceCurrency}> €</div>
                            <div className={classes.priceText}>
                                {
                                    ((products.find(p => p.metadata.package === 'AMATEUR')?.prices?.[0]?.unitAmount || 0) / 100).toFixed(2)
                                }
                            </div>
                            <div className={classes.pricePeriod}>/{t('Pricing period month')}</div>
                        </Box>
                }

            </Box>

            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'flex-start'}
                width={'100%'}
                paddingLeft={'40px'}
                paddingRight={'40px'}
                height={350}
            >
                {renderTick(TICK_ORANGE, t(`Unlimited job posts`))}
                {renderTick(TICK_ORANGE, t(`Non-verified amateur players`))}
                {renderTick(TICK_ORANGE, t(`Easily create and manage multiple jobs`))}
                {renderTick(TICK_ORANGE, t(`Simple management of your applicants`))}
                {renderTick(TICK_ORANGE, t(`Score based ranking of applicants`))}
                {renderTick(TICK_ORANGE, t(`Email support`))}
            </Box>

            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                className={clsx(classes.ctaButton, classes.amateur)}
                onClick={() => props.onPackageSelected(
                    products.find(p => p.metadata.package === 'AMATEUR')!,
                    products.find(p => p.metadata.package === 'AMATEUR')?.prices?.[0]!
                )}
            >
                <div className={classes.ctaText}>{t("START NOW")}</div>
            </Box>

            <Box height={50}/>

        </AnimatedBox>
    )

    const renderPro = () => (
        <AnimatedBox
            className={clsx(classes.package, classes.packagePro)}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}

            fadeInThreshold={30}
            fadeIn
        >
            <Box
                className={clsx(classes.headerHolder, classes.pro)}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <div className={classes.headerPackageName}>
                    {t("PRO LEAGUE")}
                </div>
                <div className={classes.headerPackageSubtitle}>
                    {t("CLUBS")}
                </div>
            </Box>

            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                className={clsx(classes.priceHolder, classes.pro)}
            >
                {
                    productsState === 'loading' ? <CircularProgress color={'secondary'}/> :
                        <Box
                            display={'flex'}
                        >
                            <div className={classes.priceCurrency}> €</div>
                            <div className={classes.priceText}>
                                {
                                    ((products.find(p => p.metadata.package === 'PRO')?.prices?.[0]?.unitAmount || 0) / 100).toFixed(2)
                                }
                            </div>
                            <div className={classes.pricePeriod}>/{t('Pricing period month')}</div>
                        </Box>
                }
            </Box>

            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'flex-start'}
                width={'100%'}
                paddingLeft={'40px'}
                paddingRight={'40px'}
                height={350}
            >
                {renderTick(TICK_GREEN, t(`Unlimited job posts`))}
                {renderTick(TICK_GREEN, t(`Full player verification`))}
                {renderTick(TICK_GREEN, t(`Direct contact with relevant TOP candidates`))}
                {renderTick(TICK_GREEN, t(`Job review by Playerhunter`))}
                {renderTick(TICK_GREEN, t(`Dedicated personal Customer Scout Manager`))}
                {renderTick(TICK_GREEN, t(`Score based ranking of applicants`))}
                {renderTick(TICK_GREEN, t(`Easily create and manage multiple jobs`))}
                {renderTick(TICK_GREEN, t(`Simple management of your applicants`))}
                {renderTick(TICK_GREEN, t(`Advanced search filters`))}
            </Box>

            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                className={clsx(classes.ctaButton, classes.pro)}
                onClick={() => props.onPackageSelected(
                    products.find(p => p.metadata.package === 'PRO')!,
                    products.find(p => p.metadata.package === 'PRO')?.prices?.[0]!
                )}
            >
                <div className={classes.ctaText}>{t("FREE TRIAL")}</div>
            </Box>

            <Box
                height={50}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <div className={classes.ctaSubtitle}>{t("No credit card required")}</div>
            </Box>

        </AnimatedBox>
    )

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={clsx(classes.component, props.extraClass)}
        >
            {
                props.showTitle &&
                <div className={classes.title}>
                    {t("Get Access To Top Players!")}
                </div>
            }

            <a className={classes.anchor} id="pricing"/>

            <Box
                display={'flex'}
                justifyContent={'center'}
                flexWrap={'wrap'}
                width={'100%'}
                p={3}
            >
                <Grid
                    container
                    spacing={6}
                    alignItems={"center"}
                    justify={'center'}
                >
                    <Grid item>
                        {renderAmateur()}
                    </Grid>
                    <Grid item>
                        {renderPro()}
                    </Grid>
                </Grid>
            </Box>

        </Box>
    )
}

export default Pricing
