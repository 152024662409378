import ClubModel from "./club.model"
import CityModel from "./city.model"
import CountryModel from "./country.model"
import UserTypeModel from "./userType.model"
import SkillMediaModel from "./skillMedia.model"
import PastClubModel from "./pastClub.model"
import Team from "./team.model"
import MediaModel from "./media.model"
import LanguageModel from "./language.model"
import {UserSubscriptionModel} from "./userSubscription.model"
import {CityLocation} from "./cityLocation.model"
import {LoginType} from "./loginType.model";

export enum VerifiedStatus {
    NOT_VERIFIED = 'NOT_VERIFIED',
    PENDING = 'PENDING',
    VERIFIED = 'VERIFIED'
}

export interface UserAdminInfo {
    id?: number
    adminNote?: string
}

export interface UserProfile {
    adminNote?: string
    agencyName?: string
    agencyPosition?: AgencyPosition
    city?: CityModel
    completion?: number
    contactInfo?: {
        id?: number
        email?: string
        phone?: string
    }
    country?: CountryModel
    createdAt?: Date
    currentClub?: ClubModel
    currentClubRequest?: ClubModel
    currentClubTeams?: Team[]
    currentContractFrom?: Date
    currentContractTo?: Date
    dateOfBirth?: Date
    externalUrls?: string[]
    firstName?: string
    fullName?: string
    gender?: string
    googleGeolocation?: {
        results: {
            address_components?: {
                long_name?: string,
                short_name?: string,
                types?: string[]
            }[]
            geometry?: {
                location?: { lat?: number | null, lng?: number | null },
                location_type?: string,
                viewport?: {
                    northeast?: any,
                    southwest?: any
                }
            }
        }[]
    }
    hasAgent?: boolean
    height?: number
    highlightVideos?: MediaModel[]
    id?: number
    languages?: LanguageModel[]
    lastName?: string
    location?: CityLocation
    mainPosition?: string
    nationalities?: CountryModel[]
    preferredFoot?: string
    previousClubs?: PastClubModel[]
    profilePicture?: string
    secondaryPositions?: string[]
    skillMedia?: SkillMediaModel[]
    skipCurrentClub?: boolean
    skipPastClub?: boolean
    stats?: {
        appearances?: number
        assists?: number
        cleanSheets?: number
        goals?: number
        goalsReceived?: number
        redCards?: number
        yellowCards?: number
        minutesPercentage?: number
    }
    previousSeasonStats?: {
        appearances?: number
        assists?: number
        cleanSheets?: number
        goals?: number
        goalsReceived?: number
        redCards?: number
        yellowCards?: number
        minutesPercentage?: number
    }
    transfermarktValue?: number
    verified?: VerifiedStatus
    isPublic?: boolean

    instatIndex?: number
    phIndex?: number
    leagueLevel?: number
    transferAvailabilityStatus?: TransferAvailabilityStatus

    fromSalary?: number
    toSalary?: number
    transferFee?: number,
}

export default interface UserModel {
    id?: number
    email?: string
    password?: string
    profile?: UserProfile
    userType?: UserTypeModel
    loginType?: LoginType
    subscription?: UserSubscriptionModel
    adminOf?: ClubModel[];
    adminOfRequests?: ClubModel[]
    parent?: {
        id?: number,
        userProfile?: {
            firstName?: string,
            lastName?: string,
            agencyName?: string,
            contactInfo?: {
                email?: string
                phone?: string
            }
        },
        userType?: UserTypeModel;
    }
    canCreateOpportunities?: boolean
    canSearchPlayers?: boolean
    userProfile?: any
}

export enum UserTypeId {
    ADMIN_TYPE_ID = 1,
    REGULAR_TYPE_ID = 2,
    STAFF_TYPE_ID = 5,
    AGENT_TYPE_ID = 6
}

export enum AgencyPosition {
    AGENT = 'AGENT',
    OTHER = 'OTHER',
    OWNER_CEO = 'OWNER_CEO',
    SCOUT = 'SCOUT',
    HEAD_OF_SCOUTING = 'HEAD_OF_SCOUTING',
    SPORTS_DIRECTOR = 'SPORTS_DIRECTOR'
}

export enum TransferAvailabilityStatus {
    TRANSFER_AND_LOAN = 'TRANSFER_AND_LOAN',
    TRANSFER_ONLY = 'TRANSFER_ONLY',
    LOAN_ONLY = 'LOAN_ONLY',
    NOT_AVAILABLE = 'NOT_AVAILABLE'
}
