import gql from "graphql-tag";
import {
    STRIPE_CUSTOMER,
    STRIPE_DISCOUNT,
    STRIPE_INVOICE,
    STRIPE_PAYMENT_METHOD,
    STRIPE_PRODUCT,
    STRIPE_PROMO_CODE,
    STRIPE_SUBSCRIPTION
} from "./queryFragments";

export const GET_STRIPE_PRODUCTS = gql`
    query stripeProducts {
        stripeProducts {
            ${STRIPE_PRODUCT}
        }
    }    
`;

export const PURCHASE_SUBSCRIPTION = gql`
mutation stripePurchaseSubscription ($id: Long!, $isClub:Boolean!, $priceId: String!, $promotionCodeId: String) {
    stripePurchaseSubscription (id: $id, isClub: $isClub, priceId: $priceId, promotionCodeId: $promotionCodeId) {
       ${STRIPE_SUBSCRIPTION}
    }
}
`;

export const UPGRADE_SUBSCRIPTION = gql`
mutation stripeUpdateSubscription ($id: Long!, $isClub:Boolean!, $priceId: String, $autorenew: Boolean, $promotionCodeId: String) {
    stripeUpdateSubscription (id: $id, isClub: $isClub, priceId: $priceId, autorenew: $autorenew, promotionCodeId: $promotionCodeId) {
       ${STRIPE_SUBSCRIPTION}
    }
}
`;

export const STRIPE_SETUP_INTENT = gql`
    mutation  stripeSetupIntent ($id: Long!, $isClub:Boolean!) {
            stripeSetupIntent (id: $id, isClub: $isClub) {
                    cancellationReason
                    clientSecret
                    created
                    description
                    id
                    livemode
                    metadata
                    object
                    paymentMethodTypes
                    status
                    usage
            }
        }
    
`;

export const GET_STRIPE_PAYMENT_METHODS = gql`
    query stripePaymentMethods ($id: Long!, $isClub:Boolean!) {
        stripePaymentMethods (id: $id, isClub: $isClub) {       
            ${STRIPE_PAYMENT_METHOD}
        }    
    }
`;

export const REMOVE_STRIPE_PAYMENT_METHOD = gql`
    mutation stripeDetachPaymentMethod ($id: Long!, $isClub:Boolean!, $paymentMethodId: String!) {
        stripeDetachPaymentMethod (id: $id, isClub: $isClub, paymentMethodId: $paymentMethodId) {       
            ${STRIPE_PAYMENT_METHOD}
        }    
    }
`;

export const GET_STRIPE_INVOICES = gql`
    query stripeInvoices ($id: Long!, $isClub:Boolean!) {
        stripeInvoices (id: $id, isClub: $isClub){
            ${STRIPE_INVOICE}
        }
      }
`;

export const GET_STRIPE_SUBSCRIPTION = gql`
    query stripeSubscription ($id: Long!, $isClub:Boolean!) {
        stripeSubscription (id: $id, isClub: $isClub){
            ${STRIPE_SUBSCRIPTION}
        }
      }
`;


export const GET_STRIPE_INVOICE = gql`
    query stripeInvoice ($id: Long!, $invoiceId: String!, $isClub:Boolean!) {
        stripeInvoice (id: $id, invoiceId: $invoiceId, isClub: $isClub){
            ${STRIPE_INVOICE}
        }
      }
`;

export const PAY_STRIPE_INVOICE = gql`
    mutation stripePayInvoice ($id: Long!, $invoiceId: String!, $isClub:Boolean!) {
        stripePayInvoice (id: $id, invoiceId: $invoiceId, isClub: $isClub){
            ${STRIPE_INVOICE}
        }
      }
`;

export const GET_STRIPE_CUSTOMER = gql`
 query stripeCustomer($id:Long!, $isClub:Boolean!){
    stripeCustomer (id: $id, isClub: $isClub) {
            ${STRIPE_CUSTOMER}
  }
}
`;

export const SET_DEFAULT_PAYMENT_METHOD = gql`
    mutation stripeDefaultPaymentMethod ($id: Long!, $isClub:Boolean!, $paymentMethodId: String!) {
            stripeDefaultPaymentMethod (id: $id, isClub: $isClub, paymentMethodId: $paymentMethodId)  {
                      ${STRIPE_CUSTOMER}
        }  
    }
 `;

export const SET_COUPON = gql`
mutation stripeCoupon ($id: Long!, $isClub:Boolean!, $coupon: String!) {
        stripeCoupon (id: $id, isClub: $isClub, coupon: $coupon)  {
              ${STRIPE_DISCOUNT}
    }  
}
 `;

export const GET_STRIPE_PROMO_CODES = gql`
 query stripePromotionCodes($id:Long!, $isClub:Boolean!, $code: String!){
    stripePromotionCodes (id: $id, isClub: $isClub, code: $code) {
            ${STRIPE_PROMO_CODE}
  }
}
`;
