import React, {useEffect, useState} from 'react'
import Dialog from "@material-ui/core/Dialog"
import {Box, Button, Card, CircularProgress, Typography} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"
import UserService from "../../../services/user.service";
import {NotificationStatus} from "../../../models/notificationStatus.model";
import UserModel from "../../../models/user.model";

const useStyles = makeStyles(_theme => ({
    container: {
        minHeight: 250,
        width: 450
    },
    actionButton: {
        marginBottom: 20
    },
    title: {
        marginBottom: 20
    }
}))

interface Props {
    open: boolean,
    onClose: () => void,
    user: UserModel
}

const SendNotificationPopup = (props: Props) => {

    const classes = useStyles()
    const [sending, setSending] = useState(false)
    const [status, setStatus] = useState<'' | 'sent' | 'error'>('')

    useEffect(() => {
        setStatus('')
        setSending(false)
    }, [props.open])

    const sendNotification = async (status: NotificationStatus) => {
        setSending(true)
        setStatus('')
        try {
            const response = await UserService.sendNotification(status, {id: props.user.id})
            if (response === 'Ok') {
                setStatus('sent')
            } else {
                setStatus('error')
            }
        } catch (e) {
            console.log(e)
            setStatus('error')
        } finally {
            setSending(false)
        }
    }

    const renderOptions = () => {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
            >
                <Button
                    variant={'contained'}
                    color={'primary'}
                    className={classes.actionButton}
                    onClick={() => sendNotification(NotificationStatus.VERIFIED)}
                >
                    Send "YOUR ACCOUNT IS VERIFIED"
                </Button>

                <Button
                    variant={'contained'}
                    color={'primary'}
                    className={classes.actionButton}
                    onClick={() => sendNotification(NotificationStatus.PROFILE_CHANGED_BY_PH)}
                >
                    Send "YOUR PROFILE IS UPDATED BY PH TEAM"
                </Button>
            </Box>
        )
    }

    return (
        <Dialog
            open={props.open}
            onBackdropClick={props.onClose}
        >
            <Card>
                <Box
                    className={classes.container}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    p={3}
                >
                    <Typography
                        className={classes.title}
                    >
                        Send notification
                    </Typography>

                    {
                        sending ? <CircularProgress/> : renderOptions()
                    }

                    {status && <Typography>
                        Status: {status === 'sent' ? 'Notification sent' : 'Error while sending notification'}
                    </Typography>}

                </Box>
            </Card>

        </Dialog>
    )
}

export default SendNotificationPopup
