import React, {useState} from 'react'
import {Box, Button, Card, CircularProgress} from "@material-ui/core"
import User from "../../../models/user.model"
import UserModel, {UserAdminInfo, UserTypeId} from "../../../models/user.model"
import UserService from "../../../services/user.service"
import {useHistory, useParams} from "react-router"
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {useItemData} from "../../../util/data/useItemData"
import {useTabs} from "../../../util/data/useTabs"
import {useBreadcrumbs} from "../../../util/data/useBreadcrumbs"
import PersonIcon from '@material-ui/icons/Person';
import Overview from "./Overview.component";
import {useTranslation} from "react-i18next";
import {getFullName} from "../../../util/profile.util";
import PlayerProfile from "./PlayerProfile.component";
import PlayerPreview from "./PlayerPreview.component";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VideoTab from "./VideoTab";
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import TimelineIcon from '@material-ui/icons/Timeline';
import ClubsStatsTab from "./ClubsStatsTab.component";
import _ from 'lodash'
import AgentProfileTab from "./AgentProfileTab.component";
import AgentPlayersTab from "./AgentPlayersTab.component";
import ClubAdminProfileTab from "./ClubAdminProfileTab.component";
import SendNotificationPopup from "./SendNotificationPopup";
import {LoginType} from "../../../models/loginType.model";

interface Params {
    id: string
}

const UserDetails = () => {

    //region State

    const params = useParams<Params>()
    const id = params.id
    const {t} = useTranslation()
    const [notifyPopupOpen, setNotifyPopupOpen] = useState(false)
    const history = useHistory()

    const userData = useItemData<User>({
        get: () => UserService.getUser(+id, false),
        isValid: item => !!item?.profile,
        deps: [id],
        delete: async () => {
            await UserService.removeSubuser(userData.item?.id!)
        },
        onDeleted: () => history.goBack()
    })

    const userAdminInfoData = useItemData<UserAdminInfo>({
        get: async () => {
            try {
                const response = await UserService.getUserAdminInfo(+id)
                return response || {}
            } catch (e) {
                return {}
            }
        },
        create: item => UserService.updateUserAdminInfo(+id, item),
        update: (_, item) => UserService.updateUserAdminInfo(+id, item),
        isValid: item => true,
        deps: [id]
    })

    const isPlayer = userData.item?.userType?.id === UserTypeId.REGULAR_TYPE_ID
    const isAgent = userData.item?.userType?.id === UserTypeId.AGENT_TYPE_ID
    const isClubAdmin = userData.item?.userType?.id === UserTypeId.STAFF_TYPE_ID
    const isSubuser = userData.item?.loginType === LoginType.SUBUSER

    const handleSave = async () => {
        try {
            userData.setSaving(true)

            const userWithoutProfile = {...userData.item}
            delete userWithoutProfile.profile
            delete userWithoutProfile.adminOf
            delete userWithoutProfile.adminOfRequests
            delete userWithoutProfile.subscription
            delete userWithoutProfile.userProfile
            if (userData.item?.parent) {
                userWithoutProfile.parent = {id: userData.item.parent.id}
            } else {
                delete userWithoutProfile.parent
            }
            let userUpdateResponse = await UserService.updateUser(userWithoutProfile)

            if (userAdminInfoData.isDirty) {
                await userAdminInfoData.save()
            }

            let updatedProfile = userData?.item?.profile
            if (!_.isEqual(userData.originalItem?.profile, userData?.item?.profile)) {
                updatedProfile = await UserService.updateProfile(+id, userData?.item?.profile!)
            }

            let updatedSubscription = userData?.item?.subscription
            if (userData?.item?.subscription && !_.isEqual(userData.originalItem?.subscription, userData?.item?.subscription)) {
                updatedSubscription = await UserService.updateSubscription(userData?.item!, userData?.item?.subscription)
            }

            const updatedUser: UserModel = {
                ...userData?.item,
                ...userUpdateResponse,
                profile: updatedProfile,
                subscription: updatedSubscription
            }
            userData.setOriginalItem(updatedUser)
            userData.setItem(updatedUser)
        } catch (e) {
            console.log(e)
        } finally {
            userData.setSaving(false)
        }
    }

    const playerTabs = [
        {icon: <InfoOutlinedIcon/>, label: t('Overview')},
        {icon: <PersonIcon/>, label: t('Player Profile')},
        {icon: <TimelineIcon/>, label: t('Club and Stats')},
        {icon: <VideoLibraryIcon/>, label: t('Videos')},
        {icon: <VisibilityIcon/>, label: t('Preview')},
    ]

    const agentTabs = [
        {icon: <InfoOutlinedIcon/>, label: t('Overview')},
        {icon: <PersonIcon/>, label: t('Agent Profile')},
        {icon: <VisibilityIcon/>, label: t('Agent Players')},
    ]

    const clubAdminTabs = [
        {icon: <InfoOutlinedIcon/>, label: t('Overview')},
        {icon: <PersonIcon/>, label: t('Admin Profile')},
        {icon: <VisibilityIcon/>, label: isAgent ? t('Agent Players') : t('Club Players')},
    ]

    let tabs: any[] = []
    if (isPlayer) {
        tabs = playerTabs
    } else if (isAgent) {
        tabs = agentTabs
    } else if (isClubAdmin) {
        tabs = clubAdminTabs
    }

    const tabsData = useTabs({
        tabs: tabs,
        showDelete: isSubuser,
        showSave: userData.isDirty || userAdminInfoData.isDirty,
        canSave: userData.isValid && userAdminInfoData.isValid,
        onSave: handleSave,
        onDelete: userData.remove,
        showButtonLoaders: userData.saving,
        canDelete: isSubuser
    })

    const breadcrumbs = useBreadcrumbs({
        path: [
            {label: t('Users'), url: '/user'},
            {label: getFullName(userData.item, '-')}
        ]
    })

    //endregion

    //region UI

    return (
        <Box>

            {breadcrumbs}

            <Card>
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    paddingRight={3}
                >
                    {tabsData.renderTabs()}

                    <Button
                        variant={'contained'}
                        color={'primary'}
                        style={{marginLeft: 10}}
                        onClick={() => setNotifyPopupOpen(true)}
                    >
                        Notify user
                    </Button>
                </Box>

                {userData.loading && <CircularProgress/>}

                {
                    !userData.loading && tabsData.tab === 0 &&
                    <Overview
                        user={userData.item}
                        setUser={userData.setItem}
                        isDirty={userData.isDirty}
                        onRefresh={userData.fetch}
                        userAdminInfo={userAdminInfoData.item}
                        setUserAdminInfo={userAdminInfoData.setItem}
                    />
                }

                {
                    !userData.loading && isPlayer && tabsData.tab === 1 &&
                    <PlayerProfile user={userData.item} setUser={userData.setItem}/>
                }

                {
                    !userData.loading && isAgent && tabsData.tab === 1 &&
                    <AgentProfileTab user={userData.item} setUser={userData.setItem}/>
                }

                {
                    !userData.loading && isClubAdmin && tabsData.tab === 1 &&
                    <ClubAdminProfileTab user={userData.item} setUser={userData.setItem}/>
                }


                {
                    !userData.loading && isPlayer && tabsData.tab === 2 &&
                    <ClubsStatsTab user={userData.item} setUser={userData.setItem}/>
                }

                {
                    !userData.loading && (isAgent || isClubAdmin) && tabsData.tab === 2 &&
                    <AgentPlayersTab user={userData.item} setUser={userData.setItem}/>
                }

                {
                    !userData.loading && isPlayer && tabsData.tab === 3 &&
                    <VideoTab user={userData.item} setUser={userData.setItem}/>
                }

                {
                    !userData.loading && isPlayer && tabsData.tab === 4 &&
                    <PlayerPreview user={userData.item}/>
                }


            </Card>

            {userData.renderSnackbars()}

            <SendNotificationPopup
                open={notifyPopupOpen}
                onClose={() => setNotifyPopupOpen(false)}
                user={userData.item!}
            />

        </Box>
    )

    //endregion UI
}

export default UserDetails
