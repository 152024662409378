import React, {FunctionComponent} from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from './EditAlbumsPopup.module.scss'
import {Box, Button, DialogActions} from '@material-ui/core';
import ClubModel from "../../../../../models/club.model";
import AlbumModel from "../../../../../models/album.model";
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean,
    history?: any,
    onClose: () => void,
    club?: ClubModel,
    onAdd: () => void,
    selectedAlbumHandler: (album: AlbumModel) => void
}


const EditAlbumsPopup: FunctionComponent<Props> = (props: Props) => {

    const t = useTranslation().t;

    const albumThumbnail = () => {
        const albums = props.club?.albums?.content || [];
        const thumbnails = albums.map(album => {
            return (
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    className={styles.thumbnailWrapper}
                >
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        className={styles.albumImageHolder}
                        onClick={() => props.selectedAlbumHandler(album)}
                    >
                        {
                            album?.media?.content?.length
                                ?
                                <img className={styles.albumImage} src={album?.media?.content?.[0]?.url || ''} alt=""/>
                                :
                                <img className={styles.albumNoImage}
                                     src={require('../../../../../assets/images/gallery.svg').default} alt=""/>

                        }
                    </Box>

                    <div className={styles.albumName}>{album.name}</div>
                </Box>
            );
        });
        return thumbnails;
    };

    return (
        <Dialog aria-labelledby="simple-dialog-title" {...props} maxWidth={"xs"} fullWidth={true}>

            <Box
                display={'flex'}
                flexDirection={'column'}
                className={styles.container}
            >
                <div>Albums</div>
                <Box
                    display={'flex'}
                    flexWrap={'wrap'}
                    className={styles.thumbnailContainer}
                >
                    {props.club?.albums?.content?.length ? albumThumbnail() :
                        <div>{t('no_albums_yet')}</div>}
                </Box>
            </Box>

            <DialogActions>
                <Button color="primary" onClick={props.onClose}>
                    {t('close')}
                </Button>
                <Button color="primary" onClick={props.onAdd}>
                    {t('add_new_album')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default EditAlbumsPopup;
