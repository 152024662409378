import React, {useEffect} from 'react'
import {useTranslation} from "react-i18next"
import Invoices from "./Invoices.component";
import PaymentMethods from "./PaymentMethods.component";
import CurrentSubscription from "./CurrentSubscription.component";
import {makeStyles} from "@material-ui/core/styles";
import {useStore} from "react-redux";
import {loadInvoices, loadStripeCustomer} from "../../../store/actions/billing.actions";
import {useUser} from "../../../store/store.utils";
import AuthService from "../../../services/auth.service";
import {Box, Grid} from "@material-ui/core";

const useStyles = makeStyles({
    component: {
        // minHeight: '100vh !important',
        // background: `#F5F6FA url('/images/world-dots-original.png') center center no-repeat`
    },
    content: {
        width: '100%',
        maxWidth: 600,
        marginBottom: 50
    },
    title: {
        fontSize: 24,
        margin: 20,
        textAlign: 'center'
    },
    subtitle: {
        textAlign: 'center',
        fontSize: 16,
        marginLeft: 10,
        alignSelf: 'flex-start',
        marginTop: 30,
        marginBottom: 20
    }
})

const Billing = () => {

    //region State

    const translation = useTranslation()
    const t = translation.t
    const user = useUser()
    const club = AuthService.getAdminClub(user)
    const classes = useStyles()
    const store = useStore()

    //endregion State

    //region Handlers

    useEffect(() => {
        if (club) {
            store.dispatch(loadInvoices(club) as any)
            store.dispatch(loadStripeCustomer(club) as any)
        }
    }, [user])

    //endregion Handlers

    //region UI

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            className={classes.component}
            padding={3}
        >
            <Grid container spacing={3}>

                <Grid item xs={12} md={6}>
                    <p className={classes.subtitle}>{t('subscription')}</p>
                    <CurrentSubscription/>

                    <div className={classes.subtitle}>{t('Payment methods')}</div>
                    <PaymentMethods/>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className={classes.subtitle}>{t('Invoices')}</div>
                    <Invoices/>
                </Grid>

            </Grid>

        </Box>
    )

    //endregion UI
}


export default Billing
