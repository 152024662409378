import React, {useState} from 'react'
import {Box, Button, Card, CircularProgress, Snackbar, TextField} from "@material-ui/core"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router";
import {emptyValidator, isFormValid, minLengthValidator, validateForm} from "../../../util/validators";
import AuthService from "../../../services/auth.service";
import {useUrlParam} from "../../../util/data/url.util";
import {AuthCommon, useAuthCommonStyles} from "../auth.common.";
import {makeStyles} from "@material-ui/core/styles";
import {useAuthState} from "../../../store/store.utils";
import {initUpdatePassword, setAuthError} from "../../../store/actions/authActions";
import {useDispatch} from "react-redux";
import {Alert} from "@material-ui/lab";
import {AuthError} from "../../../store/reducers/auth.reducer";

const useStyle = makeStyles(_theme => ({
    containerHolder: {
        zIndex: 1,
        width: 'calc(100% - 40px)',
        maxWidth: 450,
        marginBottom: 'auto'
    },
    container: {
        width: '100%',
        padding: 15,
        [_theme.breakpoints.up('md')]: {
            padding: 20,
        }
    },
    passwordSentMessage: {
        marginTop: 25,
        textAlign: 'center'
    },
    input: {
        width: '100%'
    },
    error: {
        color: 'red',
        textAlign: 'center',
        marginTop: 15
    },
    done: {
        width: '60%',
        marginTop: 20
    }
}))


const ResetPassword = () => {

    //region State

    const t = useTranslation().t
    const classes = useStyle()
    const history = useHistory()
    const [token] = useUrlParam({defaultValue: '', param: 'token', dataType: "string"})
    const commonClasses = useAuthCommonStyles()
    const dispatch = useDispatch()

    // const [latestUrlParams] = useState<any>(router.query)

    const [form, setForm] = useState({
        password: {
            value: '',
            placeholder: t("password"),
            type: 'password',
            validation: {
                error: '',
                validators: [
                    emptyValidator,
                    (value: string) => minLengthValidator(value, 8)
                ]
            }
        },
    })
    const {loading, authError} = useAuthState()

    let errorMessage = ''
    switch (authError) {
        case AuthError.PASSWORD_RESET_GENERIC:
            errorMessage = t('An error occurred. Please try again or contact our support')
            break;
    }

    //endregion State

    //region Handlers

    const validate = (): boolean => {
        let newForm: any = validateForm(form)
        setForm(newForm)
        return isFormValid(newForm)
    }

    const resetHandler = async () => {
        if (validate()) {
            dispatch(initUpdatePassword(history, token, form.password.value))
        }
    }

    const inputHandler = (key: 'password', value: string) => {
        const formElement = {
            ...form[key],
            validation: {
                ...form[key].validation,
                error: ''
            }
        }
        formElement.value = value
        setForm({
            ...form,
            [key]: formElement
        })
    }

    //endregion Handlers

    //region UI

    const mapErrorToMessage = (error: string): string => {
        switch (error) {
            case 'empty':
                return t('error_empty_field')
            case 'min_length':
                return t('password_must_be_at_least_8_characters_long')
        }
        return ''
    }

    const renderInputField = (key: 'password') => {
        const formElement = form[key]
        return (
            <TextField
                disabled={loading}
                onChange={(event) => inputHandler(key, event.target.value)}
                value={formElement.value}
                className={classes.input}
                required
                type={formElement.type}
                label={formElement.placeholder}
                margin="normal"
                error={!!formElement.validation.error}
                helperText={mapErrorToMessage(formElement.validation.error)}
            />
        )
    }

    const formUI = () => {
        return (
            <Card className={classes.containerHolder}>
                <Box
                    className={classes.container}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    boxSizing={'border-box'}
                >

                    {renderInputField('password')}

                    {
                        loading ?
                            <CircularProgress/> :
                            <Button variant="contained" color="primary" className={classes.done}
                                    onClick={resetHandler}>
                                {t("done")}
                            </Button>
                    }
                </Box>
            </Card>
        )
    }

    return (
        <Box
            className={commonClasses.component}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
        >
            <AuthCommon title={t("reset_your_password")}/>
            {formUI()}

            <Snackbar
                open={!!errorMessage}
                autoHideDuration={5000}
                onClose={() => dispatch(setAuthError())}
            >
                <Alert onClose={() => dispatch(setAuthError())} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>

        </Box>
    )

    //endregion UI
}

export default ResetPassword
