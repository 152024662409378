import React, {useEffect, useState} from 'react'
import {Box, CircularProgress, Grid, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {getClubLocationGeo} from "../../../util/profile.util";
import ClubModel from "../../../models/club.model";
import ClubsService from "../../../services/clubs.service";
import ClubBadge from "../../common/ClubBadge/ClubBadge";
import ReactPaginate from "react-paginate";
import Popup from "../../common/Popup/Popup";
import CreateClubForm from "../../common/CreateClubForm.component";
import ETextField from "../../common/ETextField.component";

interface Props {
    club?: ClubModel,
    setClub: (club: ClubModel) => void,
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
    input: {
        width: '100%'
    },
    pagination: {
        display: 'inline-block',
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 0,
        marginBottom: 10,

        '& li': {
            display: 'inline-block',
            margin: 5,
            cursor: 'pointer',
            outline: 'none'
        }
    },
    paginationActive: {
        fontWeight: 'bold'
    },
    paginationSubContainer: {
        marginLeft: 15,
        marginRight: 15
    }
}))

let executeSearch = (query: string, getAllClubs: any) => {
    console.log("will execute search " + query);
    getAllClubs({page: 0, size: 6}, query);
};

const ClubRequestResolve = ({club, setClub}: Props) => {

    const classes = useStyle()
    const history = useHistory()
    const {t, i18n} = useTranslation()
    const [clubs, setClubs] = useState<ClubModel[]>([]);
    const [clubsFetchingInProgress, setClubsFetchingInProgress] = useState(false);
    // const [clubsPageNumber, setClubsPageNumber] = useState(0);
    const [clubsTotalPages, setClubsTotalPages] = useState(0);
    // const [resolvingInProgress, setResolvingInProgress] = useState(false);
    const [resolvedRequest, setResolvedRequest] = useState<ClubModel>();
    const [deleteRequestOpen, setDeleteRequestOpen] = useState(false);

    const [query, setQuery] = useState('');
    const [resolvingWith, setResolvingWith] = useState<ClubModel>();
    const [deletingInProgress, setDeletingInProgress] = useState(false);
    const [loginAsClubInProgress, setLoginAsClubInProgress] = useState(false);

    const [map, setMap] = useState()
    const [marker, setMarker] = useState<any>()

    const updateClubFields = (data: { field: string, value: any }[]) => {
        const newClub: any = {
            ...club,
        }
        data.forEach(d => newClub[d.field] = d.value)
        setClub(newClub)
    }

    const updateClubField = (field: string, value: any) => {
        updateClubFields([{field, value}])
    }

    const drawCircle = () => {
        marker?.setMap(null)

        if (!getClubLocationGeo(club)) return

        try {
            setMarker(new google.maps.Marker({
                map,
                position: getClubLocationGeo(club)
            }))
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        drawCircle()
    }, [club, map])

    const getAllClubs = async (paging: any, name?: string) => {
        setClubs([]);
        setClubsFetchingInProgress(true);
        try {
            const result = await ClubsService.getClubs(paging, name);
            setClubs(result?.content || []);
            setClubsTotalPages(result.totalPages || 0)
        } catch (e) {
            console.log(e);
        } finally {
            setClubsFetchingInProgress(false);
        }
    }

    useEffect(() => {
        setQuery('');
        setResolvedRequest(undefined);
        getAllClubs({page: 0, size: 6}, '');
    }, [club]);

    if (!club) {
        return null;
    }

    const onDeleteClubRequest = async () => {
        if (!club) return;
        setDeletingInProgress(true);
        const result = await ClubsService.deleteClubRequest(club?.id!);
        setDeletingInProgress(false);
        if (result) {
            history.goBack()
        }
    };

    const resolveClubRequest = async (clubId?: number) => {
        if (clubId && club?.id) {
            const result = await ClubsService.resolveClubRequest(clubId, club?.id);
            setResolvedRequest(result);
        }
    };

    const clubPickHandler = async (club: ClubModel) => {
        setResolvingWith(club);
    };

    const renderClubs = () => {
        return clubs.slice(0, 6).map(club => {
            return (
                <Box
                    width={150}
                    height={120}
                >
                    <ClubBadge club={club} clicked={() => clubPickHandler(club)}/>
                </Box>
            )
        })
    };

    const queryHandler = (query: string) => {
        setQuery(query);
        executeSearch(query, getAllClubs)
    };

    const handlePageChange = (data: any) => {
        getAllClubs({page: data.selected, size: 6}, query);
    };

    //endregion Handlers

    //region UI


    return (
        <Box
            p={3}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
        >

            <Grid container spacing={10}>

                <Grid item xs={6}>

                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <Typography>Select an existing club</Typography>

                        <Grid container>
                            <ETextField
                                grid={'1/1'}
                                onChange={e => queryHandler(e)}
                                value={query}
                                className={classes.input}
                                label={t("type_club_name")}
                                // variant="outlined"
                            />
                        </Grid>

                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            flexWrap={'wrap'}
                            marginBottom={4}
                            marginTop={4}
                        >
                            {
                                clubsFetchingInProgress
                                    ?
                                    <CircularProgress/>
                                    :
                                    renderClubs()
                            }
                        </Box>

                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={clubsTotalPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName={classes.pagination}
                            // subContainerClassName={styles.paginationSubContainer}
                            activeClassName={classes.paginationActive}
                        />

                    </Box>

                </Grid>

                <Grid item xs={6}>
                    <CreateClubForm
                        onClubCreated={setResolvingWith}
                    />
                </Grid>

                <Popup
                    open={!!resolvingWith}
                    onClose={() => setResolvingWith(undefined)}
                    text={`Do you want to resolve ${club && club.name} (request) using ${resolvingWith && resolvingWith.name} (club)?`}
                    onConfirm={async () => {
                        resolvingWith && await resolveClubRequest(resolvingWith?.id);
                        setResolvingWith(undefined);
                    }}
                />

                <Popup
                    open={!!resolvedRequest}
                    text={`Club request successfully resolved!`}
                    onClose={async () => {
                        if (resolvedRequest) {
                            history.goBack()
                        }
                    }}
                />

                <Popup
                    open={deleteRequestOpen}
                    text={`Delete club request?`}
                    onConfirm={() => {
                        setDeleteRequestOpen(false);
                        onDeleteClubRequest();
                    }}
                    onClose={() => setDeleteRequestOpen(false)}
                />

            </Grid>


        </Box>
    )

    //endregion UI
}

export default ClubRequestResolve
