import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translations_en from "./assets/translations/en.json"
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: translations_en
    }
};

const initLocalization = async () => {
    await i18n
        .use(initReactI18next)
        .use(LanguageDetector)
        .init({
            resources,
            fallbackLng: "en",
            supportedLngs: ['en'],


            keySeparator: false,

            interpolation: {
                escapeValue: false
            }
        });
}

export default initLocalization;
