import React, {useCallback, useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Typography} from "@material-ui/core"
import debounce from "lodash/debounce"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";
import EButton from "../../EButton";
import UserModel from "../../../../models/user.model";
import {getFullName, getUserProfilePicture, getUserTypeIcon} from "../../../../util/profile.util";
import PickUserPopupAdvanced from "../../PickUserPopupAdvanced";

const AgentFilter = () => {

    //region State

    const [pickAgentPopupOpen, setPickAgentPopupOpen] = useState(false)
    const [agent] = useUrlParam<UserModel | null>({param: 'agent', dataType: 'complex', defaultValue: null})
    const [expanded, setExpanded] = useState(!!agent?.id)
    const history = useHistory()

    //endregion State

    //region Handlers

    const executeSet = useCallback(debounce((agent: any) => {
        setMultipleParams([
            {name: 'agent', value: agent, dataType: 'complex'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }, 1000), [])

    // useEffect(() => {
    //     executeSet(agent)
    // }, [user])

    //endregion Handlers


    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="agent-content"
                id="agent-header"
            >
                <Typography>Agent
                    {!!agent?.id && `(${getFullName(agent)})`}
                </Typography>
            </AccordionSummary>

            <AccordionDetails>

                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} style={{marginTop: 1, width: '100%'}}>

                    {
                        !!agent &&
                        <Box display={'flex'}>
                            <Avatar
                                style={{
                                    width: 32,
                                    height: 32,
                                    marginRight: 10,
                                    fontSize: 14
                                }}
                                src={getUserProfilePicture(agent)}>
                                {agent?.profile?.firstName?.substring(0, 2)}
                            </Avatar>
                            <Typography>
                                {getFullName(agent, '-')}
                            </Typography>
                            <img
                                style={{
                                    marginLeft: 10,
                                    marginRight: 10,
                                    width: 15,
                                    height: 18
                                }}
                                src={getUserTypeIcon(agent)}
                            />
                        </Box>
                    }

                    {
                        !agent &&
                        <EButton
                            onClick={() => {
                                setPickAgentPopupOpen(true)
                            }}
                        >
                            Select agent
                        </EButton>
                    }

                    {
                        agent &&
                        <EButton
                            style={{marginLeft: 'auto'}}
                            onClick={() => {
                                executeSet(null)
                            }}
                        >
                            Remove
                        </EButton>
                    }

                    <PickUserPopupAdvanced
                        open={pickAgentPopupOpen}
                        onPick={(agent) => {
                            executeSet(agent)
                            setPickAgentPopupOpen(false)
                        }}
                        onClose={() => setPickAgentPopupOpen(false)}
                        userRoles={['AGENT']}
                    />
                </Box>

            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default AgentFilter
