import {GeocodeResultsRaw, GoogleGeolocationInput} from "../models/geocodeResult.model";

export const flatten = (arr: any[]) => {
    return Array.prototype.concat(...arr);
};

export const timeout = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const sleep = async (ms: number, fn: any, ...args: any[]) => {
    await timeout(ms);
    return fn(...args);
};

export const shuffle = (array: any) => {
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

        // swap elements array[i] and array[j]
        // let t = array[i]; array[i] = array[j]; array[j] = t
        [array[i], array[j]] = [array[j], array[i]];
    }
};

export const convertGeocodeResultsToGoogleGeolocationInput = (geocodeResult?: GeocodeResultsRaw[]): GoogleGeolocationInput | undefined => {
    if (!geocodeResult) return;

    const mapped = geocodeResult.map(res => {
        const addressInput: any = {};
        if (res.address_components) {
            addressInput.address_components = res.address_components?.map((addressComponent: any) => {
                return {
                    long_name: addressComponent.long_name,
                    short_name: addressComponent.short_name,
                    types: addressComponent.types
                }
            });
        }

        if (res.geometry) {
            addressInput.geometry = {
                location: res.geometry.location,
                location_type: (res.geometry as any)?.location_type,
                // viewport: res.geometry.viewport
            }
        }

        return addressInput;
    })

    return {
        results: mapped
    }
}

export function toDate(date: any) {
    if (typeof date === 'string' || typeof date === 'number') {
        return new Date(date)
    }

    if (date instanceof Date) {
        return date
    }
}
