import React from 'react'
import {Box} from "@material-ui/core"
import UserModel from "../../../models/user.model";
import PlayerProfilePreview from "../preview/PlayerProfilePreview";

interface Props {
    user?: UserModel,
}

const PlayerPreview = ({user}: Props) => {

    return (
        <Box
            p={1}
            display={'flex'}
            flexDirection={'column'}
        >

            <PlayerProfilePreview
                user={user}
            />

        </Box>
    )

    //endregion UI
}

export default PlayerPreview
