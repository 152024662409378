import React from 'react';
import MuiButton, {ButtonProps as MuiButtonProps} from "@material-ui/core/Button";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import capitalize from "lodash/capitalize";
import clsx from "clsx";

export type ColorTypes =
    | "primary"
    | "secondary"
    | "error"
    | "success"
    | "warning"
    | "default"
    | "inherit"
    | "info";

type ButtonProps = { color?: ColorTypes } & Omit<MuiButtonProps, "color">;

const useStyles = makeStyles<Theme>(theme =>
    createStyles({
        textSuccess: {
            color: theme.palette.success.main
        },
        textError: {
            color: theme.palette.error.main
        },
        textWarning: {
            color: theme.palette.warning.main
        },
        textInfo: {
            color: theme.palette.info.main
        },

        outlinedSuccess: {
            borderColor: theme.palette.success.main,
            color: theme.palette.success.main
        },
        outlinedError: {
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main
        },
        outlinedWarning: {
            borderColor: theme.palette.warning.main,
            color: theme.palette.warning.main
        },
        outlinedInfo: {
            borderColor: theme.palette.info.main,
            color: theme.palette.info.main
        },

        containedSuccess: {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.success.dark
            }
        },
        containedError: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.error.dark
            }
        },
        containedWarning: {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.warning.dark
            }
        },
        containedInfo: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })
);

const EButton: React.FC<ButtonProps> = ({children, color, ...props}) => {
    const classes = useStyles();
    const customVariant = props.variant || 'text';
    const className = color ? classes?.[`${customVariant}${capitalize(color)}`] : undefined;
    const colorProp =
        color && ["default", "inherit", "primary", "secondary"].indexOf(color) > -1
            ? (color as "default" | "inherit" | "primary" | "secondary")
            : 'primary';

    return (
        <MuiButton {...props} variant={customVariant} color={colorProp} className={clsx(className, props.className)}>
            {children}
        </MuiButton>
    );
};

EButton.displayName = "Button";

export default EButton;