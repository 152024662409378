import gql from "graphql-tag";
import {
    APPLICANTS_WEIGHTS, CITY_FULL, CITY_LOCATION, CLUB_FULL,
    COMPLETION_WEIGHTS, COUNTRY,
    FEED_WEIGHTS,
    opportunityBuilder,
    POSITION_FIELD, TEAM, USER_FULL
} from "./queryFragments";

export const GET_OPPORTUNITY_FEED_WEIGHTS = gql`
    query feedWeights {
        feedWeights {
            ${FEED_WEIGHTS}  
        }
    }
`;

export const UPDATE_OPPORTUNITY_FEED_WEIGHTS = gql`
    mutation feedWeights($weights: OpportunityFeedWeightsInput) {
         feedWeights(weights: $weights){
             ${FEED_WEIGHTS}  
          } 
    }
`;

export const GET_PROFILE_COMPLETION_WEIGHTS = gql`
    query completionWeights{
        completionWeights{
            ${COMPLETION_WEIGHTS}
        }
    }  
`;

export const UPDATE_PROFILE_COMPLETION_WEIGHTS = gql`
    mutation completionWeights($weights: ProfileCompletionWeightsInput) {
        completionWeights(weights: $weights) {
            ${COMPLETION_WEIGHTS}
        }
    }
`;

export const GET_APPLICANT_RANKING_WEIGHTS = gql`  
    query applicantsWeights{
        applicantsWeights{
            ${APPLICANTS_WEIGHTS}
        }
    }
`;

export const UPDATE_APPLICANTS_RANKING_WEIGHTS = gql`
    mutation applicantsWeights($weights: OpportunityApplicantsWeightsInput) {
        applicantsWeights(weights: $weights) {
            ${APPLICANTS_WEIGHTS}
        }
    }
`;

export const GET_POSITION_MATRIX_FOR_OPPORTUNITY_FEED = gql`  
    query positionMaps {
        positionMaps {
            ${POSITION_FIELD}
        }
    }
`;

export const GET_POSITION_MATRIX_FOR_APPLICANTS = gql`  
    query positionMapsApplicant {
        positionMapsApplicant {
            ${POSITION_FIELD}
        }
    }
`;

export const UPDATE_POSITION_MATRIX_FOR_OPPORTUNITY_FEED = gql`  
    mutation positionMap($positionMap: PositionMapInput) {
        positionMap(positionMap: $positionMap) {
            ${POSITION_FIELD}
        }
    }
`;

export const UPDATE_POSITION_MATRIX_FOR_APPLICANTS = gql`  
    mutation positionMapApplicant($positionMapApplicant: PositionMapApplicantInput) {
        positionMapApplicant(positionMapApplicant: $positionMapApplicant) {
            ${POSITION_FIELD}
        }
    }
`;

export const GET_ADMIN_OPPORTUNITY_FEED = gql`
    query adminOpportunityFeed ($areDisqualifiedFiltered: Boolean, $opportunityIds: [Long], $paging: PageableInput, $userId: Long!) {
        adminOpportunityFeed (areDisqualifiedFiltered: $areDisqualifiedFiltered, opportunityIds: $opportunityIds, paging: $paging, userId: $userId) {
            entries {
                ageDisqualification
                appliedOrDismissedDisqualification
                currentClubDisqualification
                footPoints
                genderDisqualification
                heightPoints
                languagePoints
                levelDisqualification
                verifiedDisqualification
                levelPoints
                locationDisqualification
                nationalityPoints
                positionDisqualification
                positionPoints
                opportunity {
                    ${opportunityBuilder(CLUB_FULL, CITY_FULL, COUNTRY, TEAM)}
                }
            }
            userCalculatedData {
                age
                location {
                    ${CITY_LOCATION}
                }
                currentClubId
                foot
                gender
                height
                languages
                level
                mainPosition
                nationalities
                secondaryPositions
                userId
                verified
            }      
        }
    }
`;

export const GET_ADMIN_APPLICANT_FEED = gql`
    query adminOpportunityApplicationFeed ($areDisqualifiedFiltered: Boolean, $opportunityId: Long!, $paging: PageableInput, $userIds: [Long]) {
        adminOpportunityApplicationFeed (areDisqualifiedFiltered: $areDisqualifiedFiltered, opportunityId: $opportunityId, paging: $paging, userIds: $userIds) {
            entries {
                ageDisqualification
                application {
                    id
                    status
                    createdBy {
                        ${USER_FULL}
                    }
                    deleted
                    score
                    relevance
                }
                currentClubDisqualification
                footPoints
                genderDisqualification
                heightPoints
                languagePoints
                levelDisqualification
                levelPoints
                locationDisqualification
                nationalityPoints
                opportunityMatchPoints
                positionDisqualification
                positionPoints
                score
                skillVideoPoints
                statsAppearancesPoints
                statsAssistsPoints
                statsCleanSheetsPoints
                statsGoalsPoints
                statsGoalsReceivedPoints
                statsPoints
                statsRedCardsPoints
                statsYellowCardsPoints
                verifiedDisqualification
            }
            opportunityCalculatedData {
                clubId
                countryId
                gender
                global
                isGlobal
                isLocal
                isNational
                languages
                latitude
                local
                longitude
                maxAge
                maxLevel
                minAge
                minHeight
                minLevel
                national
                nationalities
                opportunityId
                opportunityLevel
                positions
                preferredFoot
                radius
            }      
        }
    }
`;