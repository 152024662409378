import React, {useEffect, useState} from 'react'
import GooglePlacesAutocomplete, {geocodeByPlaceId} from "react-google-places-autocomplete"
import {makeStyles} from "@material-ui/core/styles"
import {useTranslation} from "react-i18next"
import ClubModel from "../../models/club.model"
import {GeocodeResultsRaw} from "../../models/geocodeResult.model"
import {Box, Button, CircularProgress, Dialog, TextField} from "@material-ui/core"
import GoogleMapReact from "google-map-react"
import {convertGeocodeResultsToGoogleGeolocationInput} from "../../util/util";
import {mapStyleGrey} from "../../util/map.style";

interface Props {
    nameInitialValue?: string
    open: boolean
    onClose: () => void
    onClubRequestCreated: (clubRequest?: ClubModel) => Promise<void>
}

const useStyle = makeStyles(_theme => ({
    component: {
        width: 320,
        padding: 20
    },
    title: {
        fontWeight: 300,
        fontSize: 24,
        textAlign: 'center',
        color: '#656565'
    },
    nameField: {
        width: '100%'
    },
    selectLocationRow: {
        width: '100%'
    },
    selectLocationLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1,
        marginTop: 16,
        marginBottom: 5
    },
    cta: {
        marginTop: 16
    },
    map: {
        marginTop: 16,
        height: 150,
        width: '100%',
        borderRadius: 6,
        border: '1px solid #DDDDDD'
    }
}))

const CreateClubRequestPopup = (props: Props) => {

    // ***** State ***** //

    const classes = useStyle()
    const [geocodeResults, setGeocodeResults] = useState<GeocodeResultsRaw[]>()
    const [name, setName] = useState('')
    const [adding, setAdding] = useState(false)
    const t = useTranslation().t
    const [map, setMap] = useState<any>()
    const [marker, setMarker] = useState<any>()

    // ***** Effects ***** //

    useEffect(() => {
        if (props.open) {
            setName('')
            setGeocodeResults([])
            setAdding(false)
            setName(props.nameInitialValue || '')
        }
    }, [props.open])

    const drawMarker = () => {
        marker?.setMap(null)

        if (!selectedCityGeo()) return

        try {

            setMarker(new google.maps.Marker({
                map,
                position: selectedCityGeo()
            }))
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        drawMarker()
    }, [geocodeResults, map])

    // ***** Handlers ***** //

    const createNewClubRequest = async () => {
        try {
            setAdding(true)
            const clubRequest: ClubModel = {
                name,
                googleGeolocation: convertGeocodeResultsToGoogleGeolocationInput(geocodeResults),
                isRequest: true
            }
            // const createdClubRequest = await ClubsService.createClubRequest(clubRequest)
            clubRequest && await props.onClubRequestCreated(clubRequest)
        } catch (e) {
            console.log(e)
        } finally {
            setAdding(false)
        }
    }

    // ***** Render ***** //

    const nameUI = (
        <TextField
            className={classes.nameField}
            onChange={(event: any) => setName(event.target.value)}
            value={name}
            required
            variant={'outlined'}
            label={t("Club name")}
            margin="normal"
        />
    )

    const locationUI = (
        <Box
            display={'flex'}
            flexDirection={'column'}
            className={classes.selectLocationRow}>

            <div className={classes.selectLocationLabel}>{t('Location')}</div>

            <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_MAPS_KEY!}
                debounce={1000}
                selectProps={{
                    onChange: async (res: any) => {
                        const result = await geocodeByPlaceId(res.value.place_id)
                        setGeocodeResults(result as any)
                    }
                }}
            />

        </Box>
    )

    const selectedCityGeo = () => {
        if (geocodeResults?.length) {
            return {
                lat: geocodeResults?.[0]?.geometry?.location.lat() || 0,
                lng: geocodeResults?.[0]?.geometry?.location.lng() || 0
            }
        }
    }

    return (
        <Dialog {...props}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                className={classes.component}
            >

                <div className={classes.title}>{t('Add your club')}</div>

                {nameUI}
                {locationUI}

                <div className={classes.map}>
                    <GoogleMapReact
                        options={{styles: mapStyleGrey}}
                        bootstrapURLKeys={{key: process.env.mapsKey!}}
                        defaultCenter={selectedCityGeo()}
                        center={selectedCityGeo()}
                        defaultZoom={7}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({map}) => setMap(map)}
                    />
                </div>

                {
                    adding ? <CircularProgress className={classes.cta}/> :
                        <Button
                            onClick={createNewClubRequest}
                            variant="contained"
                            disabled={!name || !geocodeResults?.length}
                            color="primary"
                            className={classes.cta}
                        >
                            {t('Continue')}
                        </Button>
                }
            </Box>
        </Dialog>
    )
}

export default CreateClubRequestPopup
