import gql from "graphql-tag";
import {CITY_FULL, CITY_SIMPLE, COUNTRY} from "./queryFragments";

export const GET_CITIES = gql`
    query countries($countryId: Long, $name: String, $paging: PageableInput) {
        cities(countryId: $countryId, name: $name, paging: $paging) {
            content {
                city {
                    ${CITY_FULL}
                }
                ${CITY_SIMPLE}
            }
        }
    }
`;

export const GET_CURRENT_COUNTRY = gql`
    query currentCountry {
        currentCountry {
            ${COUNTRY}
        }
    }
`;
