import {Box, Card, Tab, Tabs} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {useFilterStyles} from "../common/List/list.style"
import DeviceHubOutlinedIcon from '@material-ui/icons/DeviceHubOutlined'
import FilterTiltShiftOutlinedIcon from '@material-ui/icons/FilterTiltShiftOutlined'
import User from "../../models/user.model"
import UserModel from "../../models/user.model"
import {GeneralSet} from "../common/generalSet"
import EButton from "../common/EButton"
import {useTranslation} from "react-i18next";
import DashboardFilters2, {FILTERS} from "../common/DashboardFilters/DashboardFilters.component";
import {useHistory} from "react-router";
import {useUserFilters} from "./useUserFilters";

interface Props {
    selectedUsers?: GeneralSet<User>,

    onExportAll?: () => void,
    onExportSelected?: () => void,
    onAnalyzeJobFeedForUser?: (user?: UserModel) => void,

    onFiltersChange?: (filters: any) => void
}

const UserFilterActions = (props: Props) => {

    const filterClasses = useFilterStyles()
    const [tab, setTab] = useState(0)
    const {t} = useTranslation()
    const history = useHistory()
    const [filters, clearAllFilters] = useUserFilters()


    useEffect(() => {
        if (props.selectedUsers?.size()) {
            setTab(1)
        }
    }, [props.selectedUsers])

    // region Filters

    const renderFilters = () => (
        <Box display={'flex'} flexDirection={'column'}>

            <DashboardFilters2 filters={[
                FILTERS.VERIFICATION_STATUS,
                FILTERS.TEAM_LEVEL,
                FILTERS.COUNTRIES,
                FILTERS.AGE,
                FILTERS.GENDER,
                FILTERS.POSITIONS,
                FILTERS.FIRST_LAST_NAME,
                FILTERS.EMAIL,
                FILTERS.AGENCY_NAME,
                FILTERS.HEIGHT,
                FILTERS.SKILL_VIDEOS_COUNT,
                FILTERS.PREFERRED_FOOT,
                FILTERS.NATIONALITIES,
                FILTERS.ROLES,
                FILTERS.PARENT_USER_TYPE_ID,
                FILTERS.APPLIED_TO,
                FILTERS.TRANSFER_AVAILABILITY_STATUS,
                FILTERS.TRANSFERMARKT_VALUE,
                FILTERS.INSTAT_INDEX_VALUE,
                FILTERS.TRANSFER_FEE,
                FILTERS.SALARY
            ]}/>

            <EButton
                color={'error'}
                style={{alignSelf: 'center', width: '100%', marginTop: 5}}
                onClick={clearAllFilters}
            >
                Clear all filters
            </EButton>

        </Box>
    )

    const renderActions = () => (
        <Box display={'flex'} flexDirection={'column'} p={2}>

            <EButton
                variant={'contained'}
                color={'primary'}
                onClick={props.onExportAll}
            >
                Export all
            </EButton>

            <Box m={1}/>

            {
                (props.selectedUsers?.size() || 0) > 0 &&
                <EButton
                    variant={'contained'}
                    color={'primary'}
                    onClick={props.onExportSelected}
                >
                    Export selected
                </EButton>
            }


            <Box m={1}/>

            {
                (props.selectedUsers?.size() || 0) === 1 &&
                <EButton
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => props.onAnalyzeJobFeedForUser?.(props.selectedUsers?.values()?.[0])}
                >
                    AlgoBug - Analyse job feed for user
                </EButton>
            }

        </Box>
    )

    return (
        <Card className={filterClasses.container}>
            <Box p={2}>
                <Tabs
                    textColor={'primary'}
                    indicatorColor={'primary'}
                    value={tab}
                    onChange={(_e, selected) => setTab(selected)}
                >
                    <Tab icon={<FilterTiltShiftOutlinedIcon/>} label={t('Filters')} tabIndex={0}/>
                    <Tab icon={<DeviceHubOutlinedIcon/>} label={t('Actions')} tabIndex={1}/>
                </Tabs>
            </Box>

            {tab === 0 && renderFilters()}
            {tab === 1 && renderActions()}

        </Card>
    )
}

export default UserFilterActions
