import React from 'react';
import styles from './PreferredFoot.module.scss';
import clsx from 'clsx';
import UserModel from "../../../../models/user.model";
import {useTranslation} from "react-i18next";
import {getPreferredFoot} from "../../../../util/profile.util";
import {Box} from "@material-ui/core";

interface Props {
    player?: UserModel
}

const PreferredFoot = (props: Props) => {

    const LeftFootSelected = require('../../../../assets/images/left-foot-selected.svg').default;
    const LeftFootNotSelected = require('../../../../assets/images/left-foot-not-selected.svg').default;
    const RightFootSelected = require('../../../../assets/images/right-foot-selected.svg').default;
    const RightFootNotSelected = require('../../../../assets/images/right-foot-not-selected.svg').default

    const t = useTranslation().t;

    const preferredFoot = getPreferredFoot(props.player);
    const leftFootPreferred = preferredFoot === 'LEFT' || preferredFoot === 'BOTH';
    const rightFootPreferred = preferredFoot === 'RIGHT' || preferredFoot === 'BOTH';

    return (
        <Box className={styles.container}>
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                className={styles.currentStats}
            >
                <p>{t('preferred_foot')}</p>
                {/*<p className={styles.edit}>Edit</p>*/}
            </Box>

            <Box
                display={'flex'}
                flexDirection={'row'}
                className={styles.footOptions}
            >
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    className={clsx(styles.backgroundBox, leftFootPreferred && styles.greyBox)}
                >
                    <img alt={'Left foot'} src={leftFootPreferred ? LeftFootSelected : LeftFootNotSelected}/>
                    <p>{t("left")}</p>
                </Box>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    className={clsx(styles.backgroundBox, rightFootPreferred && styles.greyBox)}
                >
                    <img alt={'Right foot'} src={rightFootPreferred ? RightFootSelected : RightFootNotSelected}/>
                    <p>{t("right")}</p>
                </Box>
            </Box>
        </Box>
    );
}

export default PreferredFoot;
