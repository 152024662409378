import {
    ADD_TO_APPLICATIONS_IN_PROGRESS,
    REMOVE_FROM_APPLICATIONS_IN_PROGRESS,
    SET_APPLICATION,
    SET_APPLICATION_LOADING,
} from "./actionTypes";
import OpportunityApplicationModel, {ApplicationStatus} from "../../models/opportunityApplication.model";
import ApplicationsService from "../../services/applications.service";
import {QueryClient} from "@tanstack/react-query";
import Job from "../../models/job.model";
import {getQueryKey} from "../../components/job/utils";
import Page from "../../models/page.model";

// export const setApplicationsLoading = (status: ApplicationStatus, loading: boolean) => {
//     return {
//         type: SET_APPLICATIONS_LOADING,
//         payload: {
//             status,
//             loading
//         }
//     }
// };
//
// export const setApplications = (status: ApplicationStatus, applications: OpportunityApplicationModel[], append: boolean) => {
//     return {
//         type: SET_APPLICATIONS,
//         payload: {
//             status,
//             applications,
//             append
//         }
//     }
// };
//
// export const setApplicationsHasMore = (status: ApplicationStatus, hasMore: boolean) => {
//     return {
//         type: SET_APPLICATIONS_HAS_MORE,
//         payload: {
//             status,
//             hasMore
//         }
//     }
// }
//
// export const setApplicationsPage = (status: ApplicationStatus, page: number) => {
//     return {
//         type: SET_APPLICATIONS_PAGE,
//         payload: {
//             status,
//             page
//         }
//     }
// }


// export const initFetchApplications = (status: ApplicationStatus, jobId: number, page: number) => {
//     return async (dispatch: any) => {
//         dispatch(setApplicationsLoading(status, true));
//         let fetchFunction: any;
//         switch (status) {
//             case ApplicationStatus.CREATED:
//                 fetchFunction = ApplicationsService.fetchOther
//                 break;
//             case ApplicationStatus.INVITED:
//                 fetchFunction = ApplicationsService.fetchInvited
//                 break;
//             case ApplicationStatus.REJECTED:
//                 fetchFunction = ApplicationsService.fetchRejected
//                 break;
//             case ApplicationStatus.SHORTLISTED:
//                 fetchFunction = ApplicationsService.fetchTopPicks
//                 break;
//
//         }
//         const result = await fetchFunction?.(jobId, page, 6);
//         dispatch(setApplications(status, result || [], page > 0))
//         dispatch(setApplicationsHasMore(status, result.length >= 6))
//         dispatch(setApplicationsLoading(status, false))
//         dispatch(setApplicationsPage(status, page))
//     }
// };

export const initAddToApplicationsWithActionsInProgress = (application: OpportunityApplicationModel) => {
    return {
        type: ADD_TO_APPLICATIONS_IN_PROGRESS,
        payload: application
    }
}

export const initRemoveFromApplicationsWithActionsInProgress = (application: OpportunityApplicationModel) => {
    return {
        type: REMOVE_FROM_APPLICATIONS_IN_PROGRESS,
        payload: application
    }
}

const removeFromOriginalQuery = (application: OpportunityApplicationModel, job: Job, queryClient: QueryClient) => {
    const originalKey = getQueryKey(application.status!, job)
    queryClient.setQueryData(originalKey, (data: any) => {
        console.log(data.pages, application)
        const newPagesArray: Page<OpportunityApplicationModel>[] = data?.pages.map((page: Page<OpportunityApplicationModel>) => {
                return {
                    ...page,
                    content: page.content?.filter((val: OpportunityApplicationModel) => val.id !== application.id)
                }
            }
        ) ?? []

        return {
            pages: newPagesArray,
            pageParams: data.pageParams,
        }
    })
}

const addToQuery = (application: OpportunityApplicationModel, job: Job, queryClient: QueryClient) => {
    const rejectedKey = getQueryKey(application.status!, job)
    queryClient.setQueryData(rejectedKey, (data: any) => {
        let newPagesArray: Page<OpportunityApplicationModel>[] = [...(data.pages || [])]
        if (newPagesArray.length > 0) {
            newPagesArray[0] = {
                ...newPagesArray[0],
                content: [application, ...(newPagesArray[0].content || [])]
            }
        } else {
            newPagesArray = [
                {
                    content: [application]
                }
            ]
        }

        return {
            pages: newPagesArray,
            pageParams: data.pageParams,
        }
    })
}

export const initRejectApplication = (application: OpportunityApplicationModel, job: Job, queryClient: QueryClient) => {
    return async (dispatch: any) => {
        dispatch(initAddToApplicationsWithActionsInProgress(application))
        const response = await ApplicationsService.moveApplicantToRejected(application);
        dispatch(initRemoveFromApplicationsWithActionsInProgress(application))

        if (!response) return;
        removeFromOriginalQuery(application, job, queryClient)
        addToQuery(response, job, queryClient)

        // dispatch({
        //     type: MOVE_TO_REJECTED,
        //     payload: {
        //         status,
        //         application: response
        //     }
        // })
        return response
    }
}

export const initInviteApplication = (application: OpportunityApplicationModel, job: Job, queryClient: QueryClient) => {
    return async (dispatch: any) => {
        dispatch(initAddToApplicationsWithActionsInProgress(application))
        const response = await ApplicationsService.moveApplicantToInvited(application);
        dispatch(initRemoveFromApplicationsWithActionsInProgress(application))

        if (!response) return;
        removeFromOriginalQuery(application, job, queryClient)
        addToQuery(response, job, queryClient)

        // dispatch({
        //     type: MOVE_TO_INVITED,
        //     payload: {
        //         status,
        //         application: response
        //     }
        // })
        return response
    }
}

export const initFullyDeleteApplication = (application: OpportunityApplicationModel, job: Job, queryClient: QueryClient) => {
    return async (dispatch: any) => {
        dispatch(initAddToApplicationsWithActionsInProgress(application))
        const response = await ApplicationsService.fullyDeleteApplication(application);
        dispatch(initRemoveFromApplicationsWithActionsInProgress(application))

        if (!response) return;
        removeFromOriginalQuery(application, job, queryClient)

        // dispatch({
        //     type: MOVE_TO_DELETED,
        //     payload: {
        //         status,
        //         application: response
        //     }
        // })
        return response
    }
}

export const initAddToTopPicks = (application: OpportunityApplicationModel, job: Job, queryClient: QueryClient) => {
    return async (dispatch: any) => {
        dispatch(initAddToApplicationsWithActionsInProgress(application))
        const response = await ApplicationsService.moveApplicantToTopPicks(application);
        dispatch(initRemoveFromApplicationsWithActionsInProgress(application))

        if (!response) return;
        removeFromOriginalQuery(application, job, queryClient)
        addToQuery(response, job, queryClient)

        // dispatch({
        //     type: MOVE_FROM_OTHER_TO_TOP_PICKS,
        //     payload: {
        //         status,
        //         application: response
        //     }
        // })
        return response
    }
}

export const initRemoveFromTopPicks = (application: OpportunityApplicationModel, job: Job, queryClient: QueryClient) => {
    return async (dispatch: any) => {
        dispatch(initAddToApplicationsWithActionsInProgress(application))
        const response = await ApplicationsService.removeApplicantFromTopPicks(application);
        dispatch(initRemoveFromApplicationsWithActionsInProgress(application))

        if (!response) return;
        removeFromOriginalQuery(application, job, queryClient)
        addToQuery(response, job, queryClient)

        // dispatch({
        //     type: MOVE_FROM_TOP_PICKS_TO_OTHER,
        //     payload: {
        //         status,
        //         application: response
        //     }
        // })
        return response
    }
}

export const initUpdateApplicationContactState = (application: OpportunityApplicationModel, contacted: boolean, status?: ApplicationStatus) => {
    return async (dispatch: any) => {
        dispatch(initAddToApplicationsWithActionsInProgress(application))
        const response = await ApplicationsService.changeApplicantContactedState(application, contacted);
        dispatch(initRemoveFromApplicationsWithActionsInProgress(application))

        // if (!response) return;
        // dispatch({
        //     type: APPLICATION_DATA_UPDATE,
        //     payload: {
        //         status,
        //         application: response
        //     }
        // })
        return response
    }
}

export const initSetApplication = (application?: OpportunityApplicationModel) => {
    return {type: SET_APPLICATION, payload: application}
}

export const initLoadApplication = (id: number) => {
    return async (dispatch: any) => {
        dispatch({type: SET_APPLICATION_LOADING, payload: true})
        dispatch(initSetApplication(undefined))
        const response = await ApplicationsService.getApplication(id);
        dispatch(initSetApplication(response))
        dispatch({type: SET_APPLICATION_LOADING, payload: false})
        return response
    }
}
