import React, {useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControl,
    FormControlLabel,
    Typography
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";

const AppliedToFilter = () => {

    //region State

    const [paramValue] = useUrlParam<string[]>({param: 'appliedTo', dataType: 'complex', defaultValue: []})
    const [expanded, setExpanded] = useState(!!paramValue.length)
    const history = useHistory()

    //endregion State

    const handleChangeAppliedTo = (appliedTo: string) => {
        let updatedAppliedTo = [...paramValue]
        if (!updatedAppliedTo.find(r => r === appliedTo)) {
            updatedAppliedTo = [...paramValue, appliedTo]
        } else {
            updatedAppliedTo = paramValue.filter(r => r !== appliedTo)
        }

        setMultipleParams([
            {name: 'appliedTo', value: updatedAppliedTo, dataType: 'complex'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="appliedTo-content"
                id="appliedTo-header"
            >
                <Typography>Applied to {!!paramValue.length && `(${paramValue.join(', ')})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <FormControl component="fieldset">

                    <FormControlLabel
                        checked={!!paramValue.find(f => f === 'COUNTRY_WIDE')}
                        onChange={_ => handleChangeAppliedTo('COUNTRY_WIDE')}
                        value="COUNTRY_WIDE" control={<Checkbox/>}
                        label="Country wide"
                    />

                    <FormControlLabel
                        checked={!!paramValue.find(f => f === 'INTERNATIONAL')}
                        onChange={_ => handleChangeAppliedTo('INTERNATIONAL')}
                        value="INTERNATIONAL" control={<Checkbox/>}
                        label="International"
                    />

                    <FormControlLabel
                        checked={!!paramValue.find(f => f === 'LOCAL')}
                        onChange={_ => handleChangeAppliedTo('LOCAL')}
                        value="LOCAL" control={<Checkbox/>}
                        label="Local"
                    />

                </FormControl>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default AppliedToFilter