import {removeMultipleParams, useUrlParam} from "../../util/data/url.util";
import CountryModel from "../../models/country.model";
import {useHistory} from "react-router";
import {useEffect, useState} from "react";
import {UserTypeId} from "../../models/user.model";

export const useUserFilters = () => {
    const filters: any = {}
    const history = useHistory()

    // Verified
    const [verified] = useUrlParam({param: 'verified', dataType: 'string', defaultValue: ''})
    verified && (filters.verified = verified)

    // Level
    const [fromLevel] = useUrlParam({param: 'fromLevel', dataType: 'numeric', defaultValue: 0})
    const [toLevel] = useUrlParam({param: 'toLevel', dataType: 'numeric', defaultValue: 0})
    if (fromLevel && toLevel) {
        filters.fromLevel = fromLevel
        filters.toLevel = toLevel
    }

    // Age
    const [fromAge] = useUrlParam({param: 'fromAge', dataType: 'numeric', defaultValue: 0})
    const [toAge] = useUrlParam({param: 'toAge', dataType: 'numeric', defaultValue: 0})
    if (fromAge && toAge) {
        filters.fromAge = fromAge
        filters.toAge = toAge
    }

    // Country
    const [countries] = useUrlParam<CountryModel[]>({
        param: 'countries',
        dataType: 'complex',
        defaultValue: []
    })
    if (countries.length) {
        filters.countries = countries.map(c => c.id)
    }

    // Gender
    const [gender] = useUrlParam({param: 'gender', dataType: 'string', defaultValue: ''})
    gender && (filters.gender = gender)

    // Positions
    const [positions] = useUrlParam<string[]>({
        param: 'positions',
        dataType: 'complex',
        defaultValue: []
    })
    if (positions.length) {
        filters.positions = positions
    }

    // First name
    const [firstName] = useUrlParam({param: 'firstName', dataType: 'string', defaultValue: ''})
    firstName && (filters.firstName = firstName)

    // Last name
    const [lastName] = useUrlParam({param: 'lastName', dataType: 'string', defaultValue: ''})
    lastName && (filters.lastName = lastName)

    // Agency name
    const [agencyName] = useUrlParam({param: 'agencyName', dataType: 'string', defaultValue: ''})
    agencyName && (filters.agencyName = agencyName)

    // Email
    const [email] = useUrlParam({param: 'email', dataType: 'string', defaultValue: ''})
    email && (filters.email = email)

    // Height
    const [fromHeight] = useUrlParam({param: 'fromHeight', dataType: 'numeric', defaultValue: 0})
    const [toHeight] = useUrlParam({param: 'toHeight', dataType: 'numeric', defaultValue: 0})
    if (fromHeight && toHeight) {
        filters.fromHeight = fromHeight
        filters.toHeight = toHeight
    }

    // Skill video count
    const [fromSkillVideoCount] = useUrlParam({param: 'fromSkillVideoCount', dataType: 'numeric', defaultValue: 0})
    const [toSkillVideoCount] = useUrlParam({param: 'toSkillVideoCount', dataType: 'numeric', defaultValue: 0})
    if (fromSkillVideoCount && toSkillVideoCount) {
        filters.fromSkillVideoCount = fromSkillVideoCount
        filters.toSkillVideoCount = toSkillVideoCount
    }

    // Preferred Foot
    const [preferredFoot] = useUrlParam({param: 'preferredFoot', dataType: 'string', defaultValue: ''})
    preferredFoot && preferredFoot !== 'BOTH' && (filters.preferredFoot = preferredFoot)

    // Nationalities
    const [nationalities] = useUrlParam<CountryModel[]>({
        param: 'nationalities',
        dataType: 'complex',
        defaultValue: []
    })
    if (nationalities.length) {
        filters.nationalities = nationalities.map(c => c.id)
    }

    // Roles
    const [userRoles] = useUrlParam<string[]>({
        param: 'userRoles',
        dataType: 'complex',
        defaultValue: []
    })
    if (userRoles.length) {
        filters.userRoles = userRoles
    }

    // Applied to
    const [appliedTo] = useUrlParam<string[]>({
        param: 'appliedTo',
        dataType: 'complex',
        defaultValue: []
    })
    if (appliedTo.length) {
        filters.appliedTo = appliedTo
    }

    // Transfer availability status
    const [transferAvailabilityStatus] = useUrlParam<string[]>({
        param: 'transferAvailabilityStatus',
        dataType: 'complex',
        defaultValue: []
    })
    transferAvailabilityStatus?.length && (filters.transferAvailabilityStatus = transferAvailabilityStatus)

    // Transfermarkt value
    const [fromTransfermarktValue] = useUrlParam({
        param: 'fromTransfermarktValue',
        dataType: 'numeric',
        defaultValue: 0
    })
    const [toTransfermarktValue] = useUrlParam({param: 'toTransfermarktValue', dataType: 'numeric', defaultValue: 0})
    if (fromTransfermarktValue && toTransfermarktValue) {
        filters.fromTransfermarktValue = fromTransfermarktValue
        filters.toTransfermarktValue = toTransfermarktValue
    }

    // Instat index value
    const [fromInstatIndex] = useUrlParam({param: 'fromInstatIndex', dataType: 'numeric', defaultValue: 0})
    const [toInstatIndex] = useUrlParam({param: 'toInstatIndex', dataType: 'numeric', defaultValue: 0})
    if (fromInstatIndex && toInstatIndex) {
        filters.fromInstatIndex = fromInstatIndex
        filters.toInstatIndex = toInstatIndex
    }

    // Player parent
    const [parentUserTypeId] = useUrlParam({param: 'parentUserTypeId', dataType: 'numeric', defaultValue: 0})
    if (parentUserTypeId) {
        filters.parentUserTypeId = parentUserTypeId
    }

    // Salary
    const [fromSalary] = useUrlParam({param: 'fromSalary', dataType: 'numeric', defaultValue: 0})
    const [toSalary] = useUrlParam({param: 'toSalary', dataType: 'numeric', defaultValue: 0})
    if (fromSalary && toSalary) {
        filters.fromSalary = fromSalary
        filters.toSalary = toSalary
    }

    // Transfer fee
    const [fromTransferFee] = useUrlParam({param: 'fromTransferFee', dataType: 'numeric', defaultValue: 0})
    const [toTransferFee] = useUrlParam({param: 'toTransferFee', dataType: 'numeric', defaultValue: 0})
    if (fromTransferFee && toTransferFee) {
        filters.fromTransferFee = fromTransferFee
        filters.toTransferFee = toTransferFee
    }

    const [latestFilters, setLatestFilters] = useState(filters)

    useEffect(() => {
        setLatestFilters(filters)
    }, [verified, fromLevel, toLevel, fromAge, toAge, countries, gender, positions,
        firstName, lastName, agencyName, fromHeight, toHeight, fromSkillVideoCount, toSkillVideoCount,
        preferredFoot, nationalities, userRoles, appliedTo, transferAvailabilityStatus,
        fromTransfermarktValue, toTransfermarktValue, fromInstatIndex, toInstatIndex, parentUserTypeId,
        email, fromSalary, toSalary, fromTransferFee, toTransferFee
    ])

    const handleClearAllFilters = () => {
        removeMultipleParams(['verified', 'fromLevel', 'toLevel', 'fromAge', 'toAge', 'countries', 'gender', 'positions', 'firstName', 'lastName', 'agencyName', 'fromHeight', 'toHeight',
            'fromSkillVideoCount', 'toSkillVideoCount', 'preferredFoot', 'nationalities', 'userRoles', 'appliedTo', 'transferAvailabilityStatus',
            'fromTransfermarktValue', 'toTransfermarktValue', 'fromInstatIndex', 'toInstatIndex', 'parentUserTypeId', 'email',
            'fromSalary', 'toSalary', 'fromTransferFee', 'toTransferFee'
        ], history)
    }

    return [latestFilters, handleClearAllFilters]
}
