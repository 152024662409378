import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from './ExportUsersPopup.module.scss';
import * as Excel from 'exceljs';
import {saveAs} from "file-saver";
import {Box, CircularProgress, FormControlLabel, Switch} from "@material-ui/core";
import {
    getAllSkillVideosForUser,
    getCityCountryName,
    getCurrentClubName,
    getCurrentClubTeams,
    getCurrentContractFormatted,
    getEmail,
    getFullName,
    getGender,
    getHighlightVideos,
    getPhone,
    getPreferredFoot,
    getPreviousClubName,
    getPreviousClubs,
    getRole,
    getUserAge,
    getUserHeight,
    getUserLanguages,
    getUserMainPosition,
    getUserNationalities,
    getUserProfilePicture,
    getUserSecondaryPositions,
    getUserStat
} from "../../../util/profile.util";
import {useTranslation} from "react-i18next";
import UserModel from "../../../models/user.model";
import UserService from "../../../services/user.service";
import EButton from "../../common/EButton";

interface Props {
    open: boolean,
    onClose: () => void,
    selected: UserModel[],
    exportType: string,
    filters: any,
}

const ExportUsersPopup: FunctionComponent<Props> = (props: Props) => {

    // ***** State ***** //

    const [status, setStatus] = useState('');
    const [downloading, setDownloading] = useState(false);

    const [exportId, setExportId] = useState(true)
    const [exportName, setExportName] = useState(true)
    const [exportEmail, setExportEmail] = useState(true)
    const [exportPhone, setExportPhone] = useState(true)
    const [exportAge, setExportAge] = useState(true)
    const [exportCurrentClub, setExportCurrentClub] = useState(true)
    const [exportCurrentClubTeams, setExportCurrentClubTeams] = useState(true)
    const [exportContract, setExportContract] = useState(true)
    const [exportLevel, setExportLevel] = useState(true)
    const [exportPositions, setExportPositions] = useState(true)
    const [exportStats, setExportStats] = useState(true)
    const [exportVideos, setExportVideos] = useState(true)
    const [exportProfilePicture, setExportProfilePicture] = useState(true)
    const [exportGender, setExportGender] = useState(true)
    const [exportLocation, setExportLocation] = useState(true)
    const [exportPreferredFoot, setExportPreferredFoot] = useState(true)
    const [exportPreviousClubs, setExportPreviousClubs] = useState(true)
    const [exportHeight, setExportHeight] = useState(true)
    const [exportLanguages, setExportLanguages] = useState(true)
    const [exportNationalities, setExportNationalities] = useState(true)
    const [exportRole, setExportRole] = useState(false)
    const [exportNote, setExportNote] = useState(true)
    const [exportExternal, setExportExternal] = useState(true)
    const [exportHasAgent, setExportHasAgent] = useState(true)
    const [exportVerificationStatus, setExportVerificationStatus] = useState(true)

    const t = useTranslation().t;

    const shouldBeCanceled = useRef(false);

    useEffect(() => {
        if (props.open) {
            setStatus('');
        }
    }, [props.open]);

    // ***** Handlers ***** //

    const addWorksheetColumns = (worksheet: Excel.Worksheet) => {
        const columns = []
        exportId && columns.push({header: 'Id', key: 'id', width: 32})
        exportName && columns.push({header: 'Name', key: 'name', width: 32})
        exportEmail && columns.push({header: 'Email', key: 'email', width: 32})
        exportPhone && columns.push({header: 'Phone', key: 'phone', width: 32})
        exportAge && columns.push({header: 'Age', key: 'age', width: 32})
        exportCurrentClub && columns.push({header: 'Current club', key: 'currentClub', width: 32})
        exportCurrentClubTeams && columns.push({header: 'Current teams', key: 'currentTeams', width: 32})
        exportContract && columns.push({header: 'Current contract', key: 'currentContract', width: 32})
        exportLevel && columns.push({header: 'Level', key: 'level', width: 32})
        exportPositions && columns.push({header: 'Main position', key: 'mainPosition', width: 32})
        exportPositions && columns.push({header: 'Secondary positions', key: 'secondaryPositions', width: 32})
        exportStats && columns.push({header: 'Appearances', key: 'appearances', width: 32})
        exportStats && columns.push({header: 'Assists', key: 'assists', width: 32})
        exportStats && columns.push({header: 'Clean sheets', key: 'cleanSheets', width: 32})
        exportStats && columns.push({header: 'Goals', key: 'goals', width: 32})
        exportStats && columns.push({header: 'Goals received', key: 'goalsReceived', width: 32})
        exportStats && columns.push({header: 'Red cards', key: 'redCards', width: 32})
        exportStats && columns.push({header: 'Yellow cards', key: 'yellowCards', width: 32})
        exportVideos && columns.push({header: 'Highlight videos', key: 'highlightVideos', width: 32})
        exportProfilePicture && columns.push({header: 'Profile picture', key: 'profilePicture', width: 32})
        exportGender && columns.push({header: 'Gender', key: 'gender', width: 32})
        exportLocation && columns.push({header: 'Location', key: 'location', width: 32})
        exportPreferredFoot && columns.push({header: 'Preferred foot', key: 'preferredFoot', width: 32})
        exportPreviousClubs && columns.push({header: 'Previous clubs', key: 'previousClubs', width: 32})
        exportHeight && columns.push({header: 'Height', key: 'height', width: 32})
        exportLanguages && columns.push({header: 'Languages', key: 'languages', width: 32})
        exportNationalities && columns.push({header: 'Nationalities', key: 'nationalities', width: 32})
        exportRole && columns.push({header: 'Role', key: 'role', width: 32})
        exportNote && columns.push({header: 'Note', key: 'note', width: 32})
        exportExternal && columns.push({header: 'External', key: 'external', width: 32})
        exportHasAgent && columns.push({header: 'Has agent', key: 'hasAgent', width: 32})
        exportVerificationStatus && columns.push({header: 'Verification status', key: 'verification', width: 32})

        worksheet.columns = columns;
    };

    const generateUserData = (user: UserModel) => {
        return {
            id: user.id,
            name: getFullName(user),
            email: getEmail(user),
            phone: getPhone(user),
            age: getUserAge(user),
            currentClub: getCurrentClubName(user),
            currentTeams: getCurrentClubTeams(user).map(t => t.teamType?.name).join(', '),
            currentContract: getCurrentContractFormatted(t, user),
            level: 'TODO', // TODO
            mainPosition: getUserMainPosition(user),
            secondaryPositions: getUserSecondaryPositions(user).join(', '),
            appearances: getUserStat(user, 'appearances'),
            assists: getUserStat(user, 'assists'),
            cleanSheets: getUserStat(user, 'cleanSheets'),
            goals: getUserStat(user, 'goals'),
            goalsReceived: getUserStat(user, 'goalsReceived'),
            redCards: getUserStat(user, 'redCards'),
            yellowCards: getUserStat(user, 'yellowCards'),
            skillVideos: getAllSkillVideosForUser(user).length,
            highlightVideos: getHighlightVideos(user).length,
            profilePicture: getUserProfilePicture(user),
            gender: getGender(user),
            location: getCityCountryName(user),
            preferredFoot: getPreferredFoot(user),
            previousClubs: getPreviousClubs(user).map(getPreviousClubName).join(', '),
            height: getUserHeight(user),
            languages: getUserLanguages(user).map(l => l.name).join(', '),
            nationalities: getUserNationalities(user).map(n => n.name).join(', '),
            role: getRole(user),
            note: user.profile?.adminNote,
            external: user.profile?.externalUrls?.join('\n'),
            hasAgent: user.profile?.hasAgent ? 'yes' : 'no',
            verification: user.profile?.verified
        }
    }

    const fillRows = async (worksheet: Excel.Worksheet, users: UserModel[]) => {
        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            const opportunityData = generateUserData(user);
            worksheet.addRow(opportunityData);
        }
    };

    const exportToFile = async (users: UserModel[]) => {
        let workbook = new Excel.Workbook();

        const worksheet = workbook.addWorksheet('Users');
        await addWorksheetColumns(worksheet);
        await fillRows(worksheet, users);

        const buf = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buf]), 'export.xlsx');
    };

    const startExport = async () => {
        let users = [];

        setDownloading(true);
        shouldBeCanceled.current = false;

        if (props.exportType === 'all') {
            let allUsersDownloaded = false;
            let page = 0;
            let totalElements;
            setStatus('Downloading users...');
            while (!allUsersDownloaded) {
                if (shouldBeCanceled.current) {
                    setStatus('');
                    return;
                }

                let downloadedPage = await UserService.getUsers({page, size: 20}, props.filters);

                totalElements = downloadedPage.totalElements;
                users.push(...(downloadedPage.content || []));
                page++;

                if ((downloadedPage.content?.length || 0) < 20) {
                    allUsersDownloaded = true;
                }

                setStatus(`Downloading users... Downloaded ${users.length} users (of total ${totalElements})`);
            }
        } else {
            users = props.selected;
        }

        setStatus(`Total users for export: ${users.length}`);

        await exportToFile(users);
        setDownloading(false);
    };

    const cancelExport = async () => {
        setDownloading(false);
        shouldBeCanceled.current = true;
    };

    // ***** UI ***** //

    const titleContent = props.exportType === 'all' ?
        <div className={styles.title}>You want to export data for ALL filtered users</div> :
        <div className={styles.title}>You want to export data for {props.selected.length} users</div>;

    const exportOptionsUI = (
        <Box display={'flex'} flexWrap={'wrap'} style={{width: 850}}>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportId}
                                                                    onChange={e => setExportId(e.target.checked)}/>}
                              label={'Export ID'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportName}
                                                                    onChange={e => setExportName(e.target.checked)}/>}
                              label={'Export name'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportEmail}
                                                                    onChange={e => setExportEmail(e.target.checked)}/>}
                              label={'Export email'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportPhone}
                                                                    onChange={e => setExportPhone(e.target.checked)}/>}
                              label={'Export phone'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportAge}
                                                                    onChange={e => setExportAge(e.target.checked)}/>}
                              label={'Export age'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportCurrentClub}
                                                                    onChange={e => setExportCurrentClub(e.target.checked)}/>}
                              label={'Export current club'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportCurrentClubTeams}
                                                                    onChange={e => setExportCurrentClubTeams(e.target.checked)}/>}
                              label={'Export current club teams'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportContract}
                                                                    onChange={e => setExportContract(e.target.checked)}/>}
                              label={'Export contract'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportLevel}
                                                                    onChange={e => setExportLevel(e.target.checked)}/>}
                              label={'Export level'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportPositions}
                                                                    onChange={e => setExportPositions(e.target.checked)}/>}
                              label={'Export positions'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportStats}
                                                                    onChange={e => setExportStats(e.target.checked)}/>}
                              label={'Export stats'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportVideos}
                                                                    onChange={e => setExportVideos(e.target.checked)}/>}
                              label={'Export videos'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportProfilePicture}
                                                                    onChange={e => setExportProfilePicture(e.target.checked)}/>}
                              label={'Export profile picture'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportGender}
                                                                    onChange={e => setExportGender(e.target.checked)}/>}
                              label={'Export gender'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportLocation}
                                                                    onChange={e => setExportLocation(e.target.checked)}/>}
                              label={'Export location'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportPreferredFoot}
                                                                    onChange={e => setExportPreferredFoot(e.target.checked)}/>}
                              label={'Export preferred foot'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportPreviousClubs}
                                                                    onChange={e => setExportPreviousClubs(e.target.checked)}/>}
                              label={'Export previous foods'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportHeight}
                                                                    onChange={e => setExportHeight(e.target.checked)}/>}
                              label={'Export height'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportLanguages}
                                                                    onChange={e => setExportLanguages(e.target.checked)}/>}
                              label={'Export languages'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportNationalities}
                                                                    onChange={e => setExportNationalities(e.target.checked)}/>}
                              label={'Export nationalities'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportRole}
                                                                    onChange={e => setExportRole(e.target.checked)}/>}
                              label={'Export role'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportNote}
                                                                    onChange={e => setExportNote(e.target.checked)}/>}
                              label={'Export note'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportExternal}
                                                                    onChange={e => setExportExternal(e.target.checked)}/>}
                              label={'Export external links'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportHasAgent}
                                                                    onChange={e => setExportHasAgent(e.target.checked)}/>}
                              label={'Export agent status'}/>
            <FormControlLabel style={{width: 250}} control={<Switch color={'primary'} checked={exportVerificationStatus}
                                                                    onChange={e => setExportVerificationStatus(e.target.checked)}/>}
                              label={'Export verification status'}/>
        </Box>
    )

    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            {...props}
            maxWidth={"md"}
            fullWidth={true}
            disableBackdropClick={downloading}
        >

            <Box display={'flex'} flexDirection={'column'} className={styles.container}>
                {titleContent}

                {!!status && <div className={styles.progressText}>{status}</div>}

                {downloading && <CircularProgress className={styles.progressIndicator}/>}

                {!downloading && exportOptionsUI}

                {downloading ?
                    <EButton variant="contained"
                             className={styles.ctaButton}
                             color="primary"
                             onClick={cancelExport}>
                        Cancel
                    </EButton>
                    :
                    <EButton variant="contained"
                             className={styles.ctaButton}
                             color="primary"
                             onClick={startExport}>
                        Start export
                    </EButton>
                }
            </Box>
        </Dialog>
    )
}

export default ExportUsersPopup;
