import React from 'react';
import styles from './ExternalLinks.module.scss';
import UserModel from "../../../../models/user.model";
import {useTranslation} from "react-i18next";
import {Box} from "@material-ui/core";

interface Props {
    player?: UserModel,
}

const ExternalLinks = (props: Props) => {

    const t = useTranslation().t;

    const links = props.player?.profile?.externalUrls?.map(url => {
        let logo = ''
        if (url?.indexOf('transfermarkt.com') >= 0) {
            logo = require('../../../../assets/images/logo/transfermarkt.png').default
        } else if (url?.indexOf('wyscout.com') >= 0) {
            logo = require('../../../../assets/images/logo/wyscout.png').default
        } else if (url?.indexOf('youtube.com') >= 0 || url?.indexOf('youtu.be') >= 0) {
            logo = require('../../../../assets/images/logo/youtube.png').default
        } else if (url?.indexOf('instatscout.com') >= 0) {
            logo = require('../../../../assets/images/logo/instat.svg').default
        } else {
            logo = require('../../../../assets/images/logo/web-general.svg').default
        }

        return (
            <a
                className={styles.link}
                key={url}
                href={url}
                target={'_blank'}
            >
                {logo && <img className={styles.logo} src={logo}/>}
                <div className={styles.linkValue}>{url}</div>
            </a>
        )
    })

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            className={styles.container}
        >
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                className={styles.sectionTitle}
            >
                <p>{t('External links')}</p>
            </Box>

            <Box
                display={'flex'}
                flexDirection={'row'}
                className={styles.linksContainer}
                flexWrap={'wrap'}
            >
                {links}
            </Box>

        </Box>
    );
}

export default ExternalLinks;
