import React, {useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControl,
    FormControlLabel,
    Typography
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";
import {TransferAvailabilityStatus} from "../../../../models/user.model";
import {ApplicationStatus} from "../../../../models/opportunityApplication.model";

const ApplicationStatusFilter = () => {

    //region State

    const [paramValue] = useUrlParam<string[]>({
        param: 'applicationStatus',
        dataType: 'complex',
        defaultValue: []
    })
    const [expanded, setExpanded] = useState(!!paramValue.length)
    const history = useHistory()

    //endregion State

    const handleChangeStatus = (role: string) => {
        let updatedStatuses = [...paramValue]
        if (!updatedStatuses.find(r => r === role)) {
            updatedStatuses = [...paramValue, role]
        } else {
            updatedStatuses = paramValue.filter(r => r !== role)
        }

        setMultipleParams([
            {name: 'applicationStatus', value: updatedStatuses, dataType: 'complex'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="role-content"
                id="role-header"
            >
                <Typography>Show applicants: {!!paramValue.length && `(${paramValue.join(', ')})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <FormControl component="fieldset">

                    <FormControlLabel
                        checked={!!paramValue.find(f => f === ApplicationStatus.SHORTLISTED)}
                        onChange={_ => handleChangeStatus(ApplicationStatus.SHORTLISTED)}
                        value={ApplicationStatus.SHORTLISTED} control={<Checkbox/>}
                        label="Top picks"
                    />

                    <FormControlLabel
                        checked={!!paramValue.find(f => f === ApplicationStatus.INVITED)}
                        onChange={_ => handleChangeStatus(ApplicationStatus.INVITED)}
                        value={ApplicationStatus.INVITED} control={<Checkbox/>}
                        label="Invited"
                    />

                    <FormControlLabel
                        checked={!!paramValue.find(f => f === ApplicationStatus.CREATED)}
                        onChange={_ => handleChangeStatus(ApplicationStatus.CREATED)}
                        value={ApplicationStatus.CREATED} control={<Checkbox/>}
                        label="Other"
                    />

                    <FormControlLabel
                        checked={!!paramValue.find(f => f === ApplicationStatus.REJECTED)}
                        onChange={_ => handleChangeStatus(ApplicationStatus.REJECTED)}
                        value={ApplicationStatus.REJECTED} control={<Checkbox/>}
                        label="Rejected"
                    />

                </FormControl>
            </AccordionDetails>

        </Accordion>
    )

}

export default ApplicationStatusFilter
