import React, {useEffect, useState} from 'react';
import styles from './PlayerProfile.module.scss';
import PlayerCard from "./PlayerCard/PlayerCard";
import CurrentSeasonOverview from "./CurrentSeasonOverview/CurrentSeasonOverview";
import PastClubs from "./PastClubs/PastClubs";
import PreferredFoot from "./PreferredFoot/PreferredFoot";
import UserModel from "../../../models/user.model";
import PlayerCardLoader from "./PlayerCard/PlayerCardLoader";
import CurrentSeasonOverviewLoader from "./CurrentSeasonOverview/CurrentSeasonOverviewLoader";
import PreferredFootLoader from "./PreferredFoot/PreferredFootLoader";
import clsx from 'clsx';
import PastClubsLoader from "./PastClubs/PastClubsLoader";
import CurrentClub from "./CurrentClub/CurrentClub";
import HighlightVideos from "./HighlightVideos/HighlightVideos";
import AdditionalInfo from "./AdditionalInfo/AdditionalInfo";
import {useTranslation} from "react-i18next";
import MediaModel from "../../../models/media.model";
import {Box, Typography} from "@material-ui/core";
import MixpanelService from "../../../services/analytics/mixpanel.service";
import {ScreenName} from "../../../services/analytics/screenName";
import ExternalLinks from "./ExternalLinks/ExternalLinks";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
    generateProfileURL,
    getUserMainPosition,
    getUserProfilePicture,
    getUserSecondaryPositions
} from "../../../util/profile.util";
import EButton from "../../common/EButton";
import Position from "../../common/Position/Position";
import Share from "../../common/Share/Share";
import PositionLoader from "../../common/Position/PositionLoader";
import {useHistory} from "react-router";
import {useUser} from "../../../store/store.utils";
import {useDispatch} from "react-redux";

interface Props {
    user?: UserModel,
    editable?: boolean
}

const PlayerProfilePreview = (props: Props) => {

    // ***** State ***** //

    const [sharePopupOpen, setSharePopupOpen] = useState(false);
    const loggedInUser = useUser()

    const loading = false;
    const user = props.user
    const history = useHistory()
    const dispatch = useDispatch()

    const t = useTranslation().t;

    // ***** Lifecycle ***** //

    useEffect(() => {
        MixpanelService.trackScreenVisit(ScreenName.PLAYER_PROFILE);
    }, [])

    // ***** UI ***** //

    const handleSkillVideoPlay = (video: MediaModel) => {
        console.log(video);
        // if (video.url && props.currentVideoUrl !== video.url) {
        //     MixpanelService.track(EventName.VIDEOS_WATCHED, {
        // [EventProperty.OPPORTUNITY_GENDER]: getAnalyticsGender(props.opportunity),
        // [EventProperty.OPPORTUNITY_POSITION]: getAnalyticsPositions(props.opportunity),
        //     });
        //     MixpanelService.incrementProperty(PeopleProperty.TOTAL_SKILL_VIDEOS_WATCHED);
        //     MixpanelService.setUserProperties({
        //         [PeopleProperty.LAST_APPLICANT_REVIEW]: MixpanelService.getCurrentTimeIso()
        //     });
        // }
        // props.setCurrentVideoUrl(video.url);
    };

    const renderNotPublic = () => {
        return (
            <Box
                width={'100%'}
                height={'80vh'}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                style={{position: 'relative', overflow: 'hidden'}}
            >
                <LockOutlinedIcon style={{fontSize: 100}}/>
                <Typography variant={'h6'}>{t('Sorry, this profile is private')}</Typography>
            </Box>
        )
    }

    const renderProfile = () => {
        if (!user) {
            return renderNotPublic()
        }

        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                flexWrap={'wrap'}
                className={styles.playerProfile}
            >

                {!loggedInUser &&
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    className={styles.publicCtaHolder}
                >

                    <div className={styles.additionalInfoTitle}>{t('are_you_looking_for_players')}</div>

                    <EButton
                        className={styles.publicCta}
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => history.push('/auth/club')}
                    >
                        {t('start_now')}
                    </EButton>

                </Box>
                }

                <div style={{marginTop: '40px'}}/>

                {/*{renderAdmin()}*/}

                <Box className={styles.profileWrapper}>

                    {/* AdminHeader container with Invite/Info button */}
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        className={styles.profileContainer}
                    >
                        <PlayerCard
                            player={user}
                            onShare={() => {
                                setSharePopupOpen(true)
                            }}
                            shareButton
                            canLeadToEdit={props.editable}
                        />

                    </Box>
                    {/* End AdminHeader container with Invite/Info button */}

                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        flexWrap={'wrap'}
                    >

                        {/* Positions and preferred foot */}
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            className={styles.columnContainer}
                        >

                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                                className={styles.positionContainer}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    className={styles.positions}
                                >
                                    <div>{t("positions")}</div>
                                    {/*<p className={styles.edit}>Edit</p>*/}
                                </Box>
                                <Box className={styles.pitch}>
                                    <Position
                                        selectedPrimary={(getUserMainPosition(user) && [getUserMainPosition(user)]) || []}
                                        selectedSecondary={getUserSecondaryPositions(user, [])}
                                        readOnly/>
                                </Box>
                            </Box>

                            <Box className={styles.statsContainer}>
                                <PreferredFoot player={user}/>
                            </Box>

                            <Box className={styles.statsContainer}>
                                <AdditionalInfo player={user}/>
                            </Box>

                        </Box>
                        {/* End Positions and preferred foot */}

                        {/* Stats and current club */}
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            className={styles.columnContainer}
                        >

                            <Box className={styles.statsContainer}>
                                <CurrentClub player={user}/>
                            </Box>

                            <Box className={styles.statsContainer}>
                                <CurrentSeasonOverview player={user}/>
                            </Box>

                            <Box className={styles.pastClubsContainer}>
                                <PastClubs player={user}/>
                            </Box>

                        </Box>
                        {/* End Stats and current club */}

                        {/* Skills */}
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            className={styles.columnContainer}
                        >

                            {/*<FlexView className={styles.additionalInfoContainer}>*/}
                            {/*<AdditionalInfo/>*/}
                            {/*</FlexView>*/}

                            <Box className={styles.skillContainer}>
                                <HighlightVideos
                                    player={user}
                                    onVideoPreviewOpen={handleSkillVideoPlay}
                                />
                            </Box>

                            <Box className={styles.skillContainer}>
                                <ExternalLinks
                                    player={user}
                                />
                            </Box>

                            {/*<FlexView className={styles.skillContainer}>*/}
                            {/*    <Skills*/}
                            {/*        player={getUserObject()}*/}
                            {/*        onVideoPreviewOpen={handleSkillVideoPlay}*/}
                            {/*    />*/}
                            {/*</FlexView>*/}

                        </Box>

                    </Box>
                </Box>

                <Share
                    open={sharePopupOpen}
                    onClose={() => setSharePopupOpen(false)}
                    title={loggedInUser ? t('share_profile_title_club') : t('share_profile_title')}
                    text={loggedInUser ? t('share_profile_message_club') : t('share_profile_message')}
                    url={generateProfileURL(user)}
                    shareTo={['facebook', 'twitter', 'email', 'whatsapp', 'linkedin',
                        'pinterest', 'viber', 'telegram', 'vk', 'ok', 'reddit', 'pocket']}
                    media={getUserProfilePicture(user)}
                />

            </Box>
        );
    }

    const renderLoaders = () => {
        return (
            <Box display={'flex'}
                 flexDirection={'column'}
                 alignItems={'center'}
                 className={styles.playerProfile}
            >

                <Box className={styles.profileContainer}>
                    <PlayerCardLoader/>
                </Box>

                <Box className={styles.statsContainer}>
                    <CurrentSeasonOverviewLoader/>
                </Box>

                <Box className={styles.statsContainer}>
                    <PreferredFootLoader/>
                </Box>

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    className={styles.positionContainer}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        className={styles.positions}
                    >
                        <div className={clsx(styles.textInfo, 'boxLoaderMedium', 'boxLoaderNormal')}/>
                        {/*<p className={styles.edit}>Edit</p>*/}
                    </Box>
                    <Box className={styles.pitch}>
                        <PositionLoader/>
                    </Box>
                </Box>

                <Box className={styles.pastClubsContainer}>
                    <PastClubsLoader/>
                </Box>

                {/*<FlexView className={styles.additionalInfoContainer}>*/}
                {/*<AdditionalInfo/>*/}
                {/*</FlexView>*/}

            </Box>
        );
    }


    return loading ? renderLoaders() : renderProfile();

}

export default PlayerProfilePreview