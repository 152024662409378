import CountryModel from "../models/country.model";

export const countries: CountryModel[] = [
    {
        "id": 2782113,
        "name": "Austria",
        "countryCode": "AT",
        "phoneCode": "43",
        "nativeName": "Österreich"
    },
    {
        "id": 2921044,
        "name": "Germany",
        "countryCode": "DE",
        "phoneCode": "49",
        "nativeName": "Deutschland"
    },
    {
        "id": 2658434,
        "name": "Switzerland",
        "countryCode": "CH",
        "phoneCode": "41",
        "nativeName": "Schweiz"
    },
    {
        "id": 6290252,
        "name": "Serbia",
        "countryCode": "RS",
        "phoneCode": "381",
        "nativeName": "Србија"
    },
    {
        "id": 3202326,
        "name": "Croatia",
        "countryCode": "HR",
        "phoneCode": "385",
        "nativeName": "Hrvatska"
    },
    {
        "id": 3277605,
        "name": "Bosnia and Herzegovina",
        "countryCode": "BA",
        "phoneCode": "387",
        "nativeName": "Bosna i Hercegovina"
    },
    {
        "id": 3194884,
        "name": "Montenegro",
        "countryCode": "ME",
        "phoneCode": "382",
        "nativeName": "Црна Гора"
    },
    {
        "id": 1149361,
        "name": "Afghanistan",
        "countryCode": "AF",
        "phoneCode": "93",
        "nativeName": "افغانستان"
    },
    {
        "id": 661882,
        "name": "Åland",
        "countryCode": "AX",
        "phoneCode": "358"
    },
    {
        "id": 783754,
        "name": "Albania",
        "countryCode": "AL",
        "phoneCode": "355",
        "nativeName": "Shqipëria"
    },
    {
        "id": 2589581,
        "name": "Algeria",
        "countryCode": "DZ",
        "phoneCode": "213",
        "nativeName": "الجزائر"
    },
    {
        "id": 5880801,
        "name": "American Samoa",
        "countryCode": "AS",
        "phoneCode": "1684",
        "nativeName": "American Samoa"
    },
    {
        "id": 3041565,
        "name": "Andorra",
        "countryCode": "AD",
        "phoneCode": "376",
        "nativeName": "Andorra"
    },
    {
        "id": 3351879,
        "name": "Angola",
        "countryCode": "AO",
        "phoneCode": "244",
        "nativeName": "Angola"
    },
    {
        "id": 3573511,
        "name": "Anguilla",
        "countryCode": "AI",
        "phoneCode": "1264",
        "nativeName": "Anguilla"
    },
    {
        "id": 6697173,
        "name": "Antarctica",
        "countryCode": "AQ",
        "phoneCode": "672",
        "nativeName": "Antarctica"
    },
    {
        "id": 3576396,
        "name": "Antigua and Barbuda",
        "countryCode": "AG",
        "phoneCode": "1268",
        "nativeName": "Antigua and Barbuda"
    },
    {
        "id": 3865483,
        "name": "Argentina",
        "countryCode": "AR",
        "phoneCode": "54",
        "nativeName": "Argentina"
    },
    {
        "id": 174982,
        "name": "Armenia",
        "countryCode": "AM",
        "phoneCode": "374",
        "nativeName": "Հայաստան"
    },
    {
        "id": 3577279,
        "name": "Aruba",
        "countryCode": "AW",
        "phoneCode": "297",
        "nativeName": "Aruba"
    },
    {
        "id": 2077456,
        "name": "Australia",
        "countryCode": "AU",
        "phoneCode": "61",
        "nativeName": "Australia"
    },
    {
        "id": 587116,
        "name": "Azerbaijan",
        "countryCode": "AZ",
        "phoneCode": "994",
        "nativeName": "Azərbaycan"
    },
    {
        "id": 3572887,
        "name": "Bahamas",
        "countryCode": "BS",
        "phoneCode": "1242",
        "nativeName": "Bahamas"
    },
    {
        "id": 290291,
        "name": "Bahrain",
        "countryCode": "BH",
        "phoneCode": "973",
        "nativeName": "‏البحرين"
    },
    {
        "id": 1210997,
        "name": "Bangladesh",
        "countryCode": "BD",
        "phoneCode": "880",
        "nativeName": "Bangladesh"
    },
    {
        "id": 3374084,
        "name": "Barbados",
        "countryCode": "BB",
        "phoneCode": "1246",
        "nativeName": "Barbados"
    },
    {
        "id": 630336,
        "name": "Belarus",
        "countryCode": "BY",
        "phoneCode": "375",
        "nativeName": "Белару́сь"
    },
    {
        "id": 2802361,
        "name": "Belgium",
        "countryCode": "BE",
        "phoneCode": "32",
        "nativeName": "België"
    },
    {
        "id": 3582678,
        "name": "Belize",
        "countryCode": "BZ",
        "phoneCode": "501",
        "nativeName": "Belize"
    },
    {
        "id": 2395170,
        "name": "Benin",
        "countryCode": "BJ",
        "phoneCode": "229",
        "nativeName": "Bénin"
    },
    {
        "id": 3573345,
        "name": "Bermuda",
        "countryCode": "BM",
        "phoneCode": "1441",
        "nativeName": "Bermuda"
    },
    {
        "id": 1252634,
        "name": "Bhutan",
        "countryCode": "BT",
        "phoneCode": "975",
        "nativeName": "ʼbrug-yul"
    },
    {
        "id": 3923057,
        "name": "Bolivia",
        "countryCode": "BO",
        "phoneCode": "591"
    },
    {
        "id": 7626844,
        "name": "Bonaire, Sint Eustatius, and Saba",
        "countryCode": "BQ",
        "phoneCode": "5997"
    },
    {
        "id": 933860,
        "name": "Botswana",
        "countryCode": "BW",
        "phoneCode": "267",
        "nativeName": "Botswana"
    },
    {
        "id": 7909813,
        "name": "Bouvet Island",
        "countryCode": "BV",
        "phoneCode": "55",
        "nativeName": "Bouvetøya"
    },
    {
        "id": 3469034,
        "name": "Brazil",
        "countryCode": "BR",
        "phoneCode": "55",
        "nativeName": "Brasil"
    },
    {
        "id": 1282588,
        "name": "British Indian Ocean Territory",
        "countryCode": "IO",
        "phoneCode": "246",
        "nativeName": "British Indian Ocean Territory"
    },
    {
        "id": 3577718,
        "name": "British Virgin Islands",
        "countryCode": "VG",
        "phoneCode": "1284"
    },
    {
        "id": 1820814,
        "name": "Brunei",
        "countryCode": "BN",
        "phoneCode": "673"
    },
    {
        "id": 732800,
        "name": "Bulgaria",
        "countryCode": "BG",
        "phoneCode": "359",
        "nativeName": "България"
    },
    {
        "id": 2361809,
        "name": "Burkina Faso",
        "countryCode": "BF",
        "phoneCode": "226",
        "nativeName": "Burkina Faso"
    },
    {
        "id": 433561,
        "name": "Burundi",
        "countryCode": "BI",
        "phoneCode": "257",
        "nativeName": "Burundi"
    },
    {
        "id": 1831722,
        "name": "Cambodia",
        "countryCode": "KH",
        "phoneCode": "855",
        "nativeName": "Kâmpŭchéa"
    },
    {
        "id": 2233387,
        "name": "Cameroon",
        "countryCode": "CM",
        "phoneCode": "237",
        "nativeName": "Cameroon"
    },
    {
        "id": 6251999,
        "name": "Canada",
        "countryCode": "CA",
        "phoneCode": "1",
        "nativeName": "Canada"
    },
    {
        "id": 3374766,
        "name": "Cape Verde",
        "countryCode": "CV",
        "phoneCode": "238"
    },
    {
        "id": 3580718,
        "name": "Cayman Islands",
        "countryCode": "KY",
        "phoneCode": "1345",
        "nativeName": "Cayman Islands"
    },
    {
        "id": 239880,
        "name": "Central African Republic",
        "countryCode": "CF",
        "phoneCode": "236",
        "nativeName": "Ködörösêse tî Bêafrîka"
    },
    {
        "id": 2434508,
        "name": "Chad",
        "countryCode": "TD",
        "phoneCode": "235",
        "nativeName": "Tchad"
    },
    {
        "id": 3895114,
        "name": "Chile",
        "countryCode": "CL",
        "phoneCode": "56",
        "nativeName": "Chile"
    },
    {
        "id": 1814991,
        "name": "China",
        "countryCode": "CN",
        "phoneCode": "86",
        "nativeName": "中国"
    },
    {
        "id": 2078138,
        "name": "Christmas Island",
        "countryCode": "CX",
        "phoneCode": "61",
        "nativeName": "Christmas Island"
    },
    {
        "id": 1547376,
        "name": "Cocos [Keeling] Islands",
        "countryCode": "CC",
        "phoneCode": "61"
    },
    {
        "id": 3686110,
        "name": "Colombia",
        "countryCode": "CO",
        "phoneCode": "57",
        "nativeName": "Colombia"
    },
    {
        "id": 921929,
        "name": "Comoros",
        "countryCode": "KM",
        "phoneCode": "269",
        "nativeName": "Komori"
    },
    {
        "id": 203312,
        "name": "Congo",
        "countryCode": "CD",
        "phoneCode": "243",
        "nativeName": "République du Congo"
    },
    {
        "id": 1899402,
        "name": "Cook Islands",
        "countryCode": "CK",
        "phoneCode": "682",
        "nativeName": "Cook Islands"
    },
    {
        "id": 3624060,
        "name": "Costa Rica",
        "countryCode": "CR",
        "phoneCode": "506",
        "nativeName": "Costa Rica"
    },
    {
        "id": 3562981,
        "name": "Cuba",
        "countryCode": "CU",
        "phoneCode": "53",
        "nativeName": "Cuba"
    },
    {
        "id": 7626836,
        "name": "Curaçao",
        "countryCode": "CW",
        "phoneCode": "599",
        "nativeName": "Curaçao"
    },
    {
        "id": 146669,
        "name": "Cyprus",
        "countryCode": "CY",
        "phoneCode": "357",
        "nativeName": "Κύπρος"
    },
    {
        "id": 3077311,
        "name": "Czech Republic",
        "countryCode": "CZ",
        "phoneCode": "420",
        "nativeName": "Česká republika"
    },
    {
        "id": 2623032,
        "name": "Denmark",
        "countryCode": "DK",
        "phoneCode": "45",
        "nativeName": "Danmark"
    },
    {
        "id": 223816,
        "name": "Djibouti",
        "countryCode": "DJ",
        "phoneCode": "253",
        "nativeName": "Djibouti"
    },
    {
        "id": 3575830,
        "name": "Dominica",
        "countryCode": "DM",
        "phoneCode": "1767",
        "nativeName": "Dominica"
    },
    {
        "id": 3508796,
        "name": "Dominican Republic",
        "countryCode": "_DO",
        "phoneCode": "1809",
        "nativeName": "República Dominicana"
    },
    {
        "id": 1966436,
        "name": "East Timor",
        "countryCode": "TL",
        "phoneCode": "670"
    },
    {
        "id": 3658394,
        "name": "Ecuador",
        "countryCode": "EC",
        "phoneCode": "593",
        "nativeName": "Ecuador"
    },
    {
        "id": 357994,
        "name": "Egypt",
        "countryCode": "EG",
        "phoneCode": "20",
        "nativeName": "مصر‎"
    },
    {
        "id": 3585968,
        "name": "El Salvador",
        "countryCode": "SV",
        "phoneCode": "503",
        "nativeName": "El Salvador"
    },
    {
        "id": 7909808,
        "name": "England",
        "countryCode": "GB",
        "phoneCode": "44"
    },
    {
        "id": 2309096,
        "name": "Equatorial Guinea",
        "countryCode": "GQ",
        "phoneCode": "240",
        "nativeName": "Guinea Ecuatorial"
    },
    {
        "id": 338010,
        "name": "Eritrea",
        "countryCode": "ER",
        "phoneCode": "291",
        "nativeName": "ኤርትራ"
    },
    {
        "id": 453733,
        "name": "Estonia",
        "countryCode": "EE",
        "phoneCode": "372",
        "nativeName": "Eesti"
    },
    {
        "id": 337996,
        "name": "Ethiopia",
        "countryCode": "ET",
        "phoneCode": "251",
        "nativeName": "ኢትዮጵያ"
    },
    {
        "id": 3474414,
        "name": "Falkland Islands",
        "countryCode": "FK",
        "phoneCode": "500"
    },
    {
        "id": 2622320,
        "name": "Faroe Islands",
        "countryCode": "FO",
        "phoneCode": "298",
        "nativeName": "Føroyar"
    },
    {
        "id": 2081918,
        "name": "Federated States of Micronesia",
        "countryCode": "FM",
        "phoneCode": "691"
    },
    {
        "id": 2205218,
        "name": "Fiji",
        "countryCode": "FJ",
        "phoneCode": "679",
        "nativeName": "Fiji"
    },
    {
        "id": 660013,
        "name": "Finland",
        "countryCode": "FI",
        "phoneCode": "358",
        "nativeName": "Suomi"
    },
    {
        "id": 3017382,
        "name": "France",
        "countryCode": "FR",
        "phoneCode": "33",
        "nativeName": "France"
    },
    {
        "id": 3381670,
        "name": "French Guiana",
        "countryCode": "GF",
        "phoneCode": "594",
        "nativeName": "Guyane française"
    },
    {
        "id": 4030656,
        "name": "French Polynesia",
        "countryCode": "PF",
        "phoneCode": "689",
        "nativeName": "Polynésie française"
    },
    {
        "id": 1546748,
        "name": "French Southern Territories",
        "countryCode": "TF",
        "phoneCode": "689",
        "nativeName": "Territoire des Terres australes et antarctiques françaises"
    },
    {
        "id": 2400553,
        "name": "Gabon",
        "countryCode": "GA",
        "phoneCode": "241",
        "nativeName": "Gabon"
    },
    {
        "id": 2413451,
        "name": "Gambia",
        "countryCode": "GM",
        "phoneCode": "220",
        "nativeName": "Gambia"
    },
    {
        "id": 614540,
        "name": "Georgia",
        "countryCode": "GE",
        "phoneCode": "995",
        "nativeName": "საქართველო"
    },
    {
        "id": 2300660,
        "name": "Ghana",
        "countryCode": "GH",
        "phoneCode": "233",
        "nativeName": "Ghana"
    },
    {
        "id": 2411586,
        "name": "Gibraltar",
        "countryCode": "GI",
        "phoneCode": "350",
        "nativeName": "Gibraltar"
    },
    {
        "id": 390903,
        "name": "Greece",
        "countryCode": "GR",
        "phoneCode": "30",
        "nativeName": "Ελλάδα"
    },
    {
        "id": 3425505,
        "name": "Greenland",
        "countryCode": "GL",
        "phoneCode": "299",
        "nativeName": "Kalaallit Nunaat"
    },
    {
        "id": 3580239,
        "name": "Grenada",
        "countryCode": "GD",
        "phoneCode": "1473",
        "nativeName": "Grenada"
    },
    {
        "id": 3579143,
        "name": "Guadeloupe",
        "countryCode": "GP",
        "phoneCode": "590",
        "nativeName": "Guadeloupe"
    },
    {
        "id": 4043988,
        "name": "Guam",
        "countryCode": "GU",
        "phoneCode": "1671",
        "nativeName": "Guam"
    },
    {
        "id": 3595528,
        "name": "Guatemala",
        "countryCode": "GT",
        "phoneCode": "502",
        "nativeName": "Guatemala"
    },
    {
        "id": 3042362,
        "name": "Guernsey",
        "countryCode": "GG",
        "phoneCode": "44",
        "nativeName": "Guernsey"
    },
    {
        "id": 2372248,
        "name": "Guinea-Bissau",
        "countryCode": "GW",
        "phoneCode": "245",
        "nativeName": "Guiné-Bissau"
    },
    {
        "id": 3378535,
        "name": "Guyana",
        "countryCode": "GY",
        "phoneCode": "592",
        "nativeName": "Guyana"
    },
    {
        "id": 3723988,
        "name": "Haiti",
        "countryCode": "HT",
        "phoneCode": "509",
        "nativeName": "Haïti"
    },
    {
        "id": 248816,
        "name": "Hashemite Kingdom of Jordan",
        "countryCode": "JO",
        "phoneCode": "962"
    },
    {
        "id": 7909814,
        "name": "Heard Island and McDonald Islands",
        "countryCode": "HM",
        "phoneCode": "6723",
        "nativeName": "Heard Island and McDonald Islands"
    },
    {
        "id": 3608932,
        "name": "Honduras",
        "countryCode": "HN",
        "phoneCode": "504",
        "nativeName": "Honduras"
    },
    {
        "id": 1819730,
        "name": "Hong Kong",
        "countryCode": "HK",
        "phoneCode": "852",
        "nativeName": "香港"
    },
    {
        "id": 719819,
        "name": "Hungary",
        "countryCode": "HU",
        "phoneCode": "36",
        "nativeName": "Magyarország"
    },
    {
        "id": 2629691,
        "name": "Iceland",
        "countryCode": "IS",
        "phoneCode": "354",
        "nativeName": "Ísland"
    },
    {
        "id": 1269750,
        "name": "India",
        "countryCode": "IN",
        "phoneCode": "91",
        "nativeName": "भारत"
    },
    {
        "id": 1643084,
        "name": "Indonesia",
        "countryCode": "ID",
        "phoneCode": "62",
        "nativeName": "Indonesia"
    },
    {
        "id": 130758,
        "name": "Iran",
        "countryCode": "IR",
        "phoneCode": "98"
    },
    {
        "id": 99237,
        "name": "Iraq",
        "countryCode": "IQ",
        "phoneCode": "964",
        "nativeName": "العراق"
    },
    {
        "id": 2963597,
        "name": "Ireland",
        "countryCode": "IE",
        "phoneCode": "353",
        "nativeName": "Éire"
    },
    {
        "id": 3042225,
        "name": "Isle of Man",
        "countryCode": "IM",
        "phoneCode": "44",
        "nativeName": "Isle of Man"
    },
    {
        "id": 294640,
        "name": "Israel",
        "countryCode": "IL",
        "phoneCode": "972",
        "nativeName": "יִשְׂרָאֵל"
    },
    {
        "id": 3175395,
        "name": "Italy",
        "countryCode": "IT",
        "phoneCode": "39",
        "nativeName": "Italia"
    },
    {
        "id": 2287781,
        "name": "Ivory Coast",
        "countryCode": "CI",
        "phoneCode": "225"
    },
    {
        "id": 3489940,
        "name": "Jamaica",
        "countryCode": "JM",
        "phoneCode": "1876",
        "nativeName": "Jamaica"
    },
    {
        "id": 1861060,
        "name": "Japan",
        "countryCode": "JP",
        "phoneCode": "81",
        "nativeName": "日本"
    },
    {
        "id": 3042142,
        "name": "Jersey",
        "countryCode": "JE",
        "phoneCode": "44",
        "nativeName": "Jersey"
    },
    {
        "id": 1522867,
        "name": "Kazakhstan",
        "countryCode": "KZ",
        "phoneCode": "76",
        "nativeName": "Қазақстан"
    },
    {
        "id": 192950,
        "name": "Kenya",
        "countryCode": "KE",
        "phoneCode": "254",
        "nativeName": "Kenya"
    },
    {
        "id": 4030945,
        "name": "Kiribati",
        "countryCode": "KI",
        "phoneCode": "686",
        "nativeName": "Kiribati"
    },
    {
        "id": 831053,
        "name": "Kosovo",
        "countryCode": "XK",
        "phoneCode": "383"
    },
    {
        "id": 285570,
        "name": "Kuwait",
        "countryCode": "KW",
        "phoneCode": "965",
        "nativeName": "الكويت"
    },
    {
        "id": 1527747,
        "name": "Kyrgyzstan",
        "countryCode": "KG",
        "phoneCode": "996",
        "nativeName": "Кыргызстан"
    },
    {
        "id": 1655842,
        "name": "Laos",
        "countryCode": "LA",
        "phoneCode": "856"
    },
    {
        "id": 458258,
        "name": "Latvia",
        "countryCode": "LV",
        "phoneCode": "371",
        "nativeName": "Latvija"
    },
    {
        "id": 272103,
        "name": "Lebanon",
        "countryCode": "LB",
        "phoneCode": "961",
        "nativeName": "لبنان"
    },
    {
        "id": 932692,
        "name": "Lesotho",
        "countryCode": "LS",
        "phoneCode": "266",
        "nativeName": "Lesotho"
    },
    {
        "id": 2275384,
        "name": "Liberia",
        "countryCode": "LR",
        "phoneCode": "231",
        "nativeName": "Liberia"
    },
    {
        "id": 2215636,
        "name": "Libya",
        "countryCode": "LY",
        "phoneCode": "218",
        "nativeName": "‏ليبيا"
    },
    {
        "id": 3042058,
        "name": "Liechtenstein",
        "countryCode": "LI",
        "phoneCode": "423",
        "nativeName": "Liechtenstein"
    },
    {
        "id": 2960313,
        "name": "Luxembourg",
        "countryCode": "LU",
        "phoneCode": "352",
        "nativeName": "Luxembourg"
    },
    {
        "id": 1821275,
        "name": "Macao",
        "countryCode": "MO",
        "phoneCode": "853",
        "nativeName": "澳門"
    },
    {
        "id": 718075,
        "name": "Macedonia",
        "countryCode": "MK",
        "phoneCode": "389"
    },
    {
        "id": 1062947,
        "name": "Madagascar",
        "countryCode": "MG",
        "phoneCode": "261",
        "nativeName": "Madagasikara"
    },
    {
        "id": 927384,
        "name": "Malawi",
        "countryCode": "MW",
        "phoneCode": "265",
        "nativeName": "Malawi"
    },
    {
        "id": 1733045,
        "name": "Malaysia",
        "countryCode": "MY",
        "phoneCode": "60",
        "nativeName": "Malaysia"
    },
    {
        "id": 1282028,
        "name": "Maldives",
        "countryCode": "MV",
        "phoneCode": "960",
        "nativeName": "Maldives"
    },
    {
        "id": 2453866,
        "name": "Mali",
        "countryCode": "ML",
        "phoneCode": "223",
        "nativeName": "Mali"
    },
    {
        "id": 2562770,
        "name": "Malta",
        "countryCode": "MT",
        "phoneCode": "356",
        "nativeName": "Malta"
    },
    {
        "id": 2080185,
        "name": "Marshall Islands",
        "countryCode": "MH",
        "phoneCode": "692",
        "nativeName": "M̧ajeļ"
    },
    {
        "id": 3570311,
        "name": "Martinique",
        "countryCode": "MQ",
        "phoneCode": "596",
        "nativeName": "Martinique"
    },
    {
        "id": 2378080,
        "name": "Mauritania",
        "countryCode": "MR",
        "phoneCode": "222",
        "nativeName": "موريتانيا"
    },
    {
        "id": 934292,
        "name": "Mauritius",
        "countryCode": "MU",
        "phoneCode": "230",
        "nativeName": "Maurice"
    },
    {
        "id": 1024031,
        "name": "Mayotte",
        "countryCode": "YT",
        "phoneCode": "262",
        "nativeName": "Mayotte"
    },
    {
        "id": 3996063,
        "name": "Mexico",
        "countryCode": "MX",
        "phoneCode": "52",
        "nativeName": "México"
    },
    {
        "id": 2993457,
        "name": "Monaco",
        "countryCode": "MC",
        "phoneCode": "377",
        "nativeName": "Monaco"
    },
    {
        "id": 2029969,
        "name": "Mongolia",
        "countryCode": "MN",
        "phoneCode": "976",
        "nativeName": "Монгол улс"
    },
    {
        "id": 3578097,
        "name": "Montserrat",
        "countryCode": "MS",
        "phoneCode": "1664",
        "nativeName": "Montserrat"
    },
    {
        "id": 2542007,
        "name": "Morocco",
        "countryCode": "MA",
        "phoneCode": "212",
        "nativeName": "المغرب"
    },
    {
        "id": 1036973,
        "name": "Mozambique",
        "countryCode": "MZ",
        "phoneCode": "258",
        "nativeName": "Moçambique"
    },
    {
        "id": 1327865,
        "name": "Myanmar [Burma]",
        "countryCode": "MM",
        "phoneCode": "95"
    },
    {
        "id": 3355338,
        "name": "Namibia",
        "countryCode": "NA",
        "phoneCode": "264",
        "nativeName": "Namibia"
    },
    {
        "id": 2110425,
        "name": "Nauru",
        "countryCode": "NR",
        "phoneCode": "674",
        "nativeName": "Nauru"
    },
    {
        "id": 1282988,
        "name": "Nepal",
        "countryCode": "NP",
        "phoneCode": "977",
        "nativeName": "नेपाल"
    },
    {
        "id": 2750405,
        "name": "Netherlands",
        "countryCode": "NL",
        "phoneCode": "31",
        "nativeName": "Nederland"
    },
    {
        "id": 2139685,
        "name": "New Caledonia",
        "countryCode": "NC",
        "phoneCode": "687",
        "nativeName": "Nouvelle-Calédonie"
    },
    {
        "id": 2186224,
        "name": "New Zealand",
        "countryCode": "NZ",
        "phoneCode": "64",
        "nativeName": "New Zealand"
    },
    {
        "id": 3617476,
        "name": "Nicaragua",
        "countryCode": "NI",
        "phoneCode": "505",
        "nativeName": "Nicaragua"
    },
    {
        "id": 2440476,
        "name": "Niger",
        "countryCode": "NE",
        "phoneCode": "227",
        "nativeName": "Niger"
    },
    {
        "id": 2328926,
        "name": "Nigeria",
        "countryCode": "NG",
        "phoneCode": "234",
        "nativeName": "Nigeria"
    },
    {
        "id": 4036232,
        "name": "Niue",
        "countryCode": "NU",
        "phoneCode": "683",
        "nativeName": "Niuē"
    },
    {
        "id": 2155115,
        "name": "Norfolk Island",
        "countryCode": "NF",
        "phoneCode": "672",
        "nativeName": "Norfolk Island"
    },
    {
        "id": 1873107,
        "name": "North Korea",
        "countryCode": "KP",
        "phoneCode": "850"
    },
    {
        "id": 7909809,
        "name": "Northern Ireland",
        "countryCode": "GB",
        "phoneCode": "44"
    },
    {
        "id": 4041468,
        "name": "Northern Mariana Islands",
        "countryCode": "MP",
        "phoneCode": "1670",
        "nativeName": "Northern Mariana Islands"
    },
    {
        "id": 3144096,
        "name": "Norway",
        "countryCode": "NO",
        "phoneCode": "47",
        "nativeName": "Norge"
    },
    {
        "id": 286963,
        "name": "Oman",
        "countryCode": "OM",
        "phoneCode": "968",
        "nativeName": "عمان"
    },
    {
        "id": 1168579,
        "name": "Pakistan",
        "countryCode": "PK",
        "phoneCode": "92",
        "nativeName": "Pakistan"
    },
    {
        "id": 1559582,
        "name": "Palau",
        "countryCode": "PW",
        "phoneCode": "680",
        "nativeName": "Palau"
    },
    {
        "id": 6254930,
        "name": "Palestine",
        "countryCode": "PS",
        "phoneCode": "970"
    },
    {
        "id": 3703430,
        "name": "Panama",
        "countryCode": "PA",
        "phoneCode": "507",
        "nativeName": "Panamá"
    },
    {
        "id": 2088628,
        "name": "Papua New Guinea",
        "countryCode": "PG",
        "phoneCode": "675",
        "nativeName": "Papua Niugini"
    },
    {
        "id": 3437598,
        "name": "Paraguay",
        "countryCode": "PY",
        "phoneCode": "595",
        "nativeName": "Paraguay"
    },
    {
        "id": 3932488,
        "name": "Peru",
        "countryCode": "PE",
        "phoneCode": "51",
        "nativeName": "Perú"
    },
    {
        "id": 1694008,
        "name": "Philippines",
        "countryCode": "PH",
        "phoneCode": "63",
        "nativeName": "Pilipinas"
    },
    {
        "id": 4030699,
        "name": "Pitcairn Islands",
        "countryCode": "PN",
        "phoneCode": "64"
    },
    {
        "id": 798544,
        "name": "Poland",
        "countryCode": "PL",
        "phoneCode": "48",
        "nativeName": "Polska"
    },
    {
        "id": 2264397,
        "name": "Portugal",
        "countryCode": "PT",
        "phoneCode": "351",
        "nativeName": "Portugal"
    },
    {
        "id": 4566966,
        "name": "Puerto Rico",
        "countryCode": "PR",
        "phoneCode": "1787",
        "nativeName": "Puerto Rico"
    },
    {
        "id": 289688,
        "name": "Qatar",
        "countryCode": "QA",
        "phoneCode": "974",
        "nativeName": "قطر"
    },
    {
        "id": 1835841,
        "name": "Republic of Korea",
        "countryCode": "KR",
        "phoneCode": "82"
    },
    {
        "id": 597427,
        "name": "Republic of Lithuania",
        "countryCode": "LT",
        "phoneCode": "370"
    },
    {
        "id": 617790,
        "name": "Republic of Moldova",
        "countryCode": "MD",
        "phoneCode": "373"
    },
    {
        "id": 935317,
        "name": "Réunion",
        "countryCode": "RE",
        "phoneCode": "262",
        "nativeName": "La Réunion"
    },
    {
        "id": 798549,
        "name": "Romania",
        "countryCode": "RO",
        "phoneCode": "40",
        "nativeName": "România"
    },
    {
        "id": 2017370,
        "name": "Russia",
        "countryCode": "RU",
        "phoneCode": "7"
    },
    {
        "id": 49518,
        "name": "Rwanda",
        "countryCode": "RW",
        "phoneCode": "250",
        "nativeName": "Rwanda"
    },
    {
        "id": 3370751,
        "name": "Saint Helena",
        "countryCode": "SH",
        "phoneCode": "290"
    },
    {
        "id": 3575174,
        "name": "Saint Kitts and Nevis",
        "countryCode": "KN",
        "phoneCode": "1869",
        "nativeName": "Saint Kitts and Nevis"
    },
    {
        "id": 3576468,
        "name": "Saint Lucia",
        "countryCode": "LC",
        "phoneCode": "1758",
        "nativeName": "Saint Lucia"
    },
    {
        "id": 3578421,
        "name": "Saint Martin",
        "countryCode": "MF",
        "phoneCode": "590"
    },
    {
        "id": 3424932,
        "name": "Saint Pierre and Miquelon",
        "countryCode": "PM",
        "phoneCode": "508",
        "nativeName": "Saint-Pierre-et-Miquelon"
    },
    {
        "id": 3577815,
        "name": "Saint Vincent and the Grenadines",
        "countryCode": "VC",
        "phoneCode": "1784",
        "nativeName": "Saint Vincent and the Grenadines"
    },
    {
        "id": 3578476,
        "name": "Saint-Barthélemy",
        "countryCode": "BL",
        "phoneCode": "590"
    },
    {
        "id": 4034894,
        "name": "Samoa",
        "countryCode": "WS",
        "phoneCode": "685",
        "nativeName": "Samoa"
    },
    {
        "id": 3168068,
        "name": "San Marino",
        "countryCode": "SM",
        "phoneCode": "378",
        "nativeName": "San Marino"
    },
    {
        "id": 2410758,
        "name": "São Tomé and Príncipe",
        "countryCode": "ST",
        "phoneCode": "239"
    },
    {
        "id": 102358,
        "name": "Saudi Arabia",
        "countryCode": "SA",
        "phoneCode": "966",
        "nativeName": "العربية السعودية"
    },
    {
        "id": 7909810,
        "name": "Scotland",
        "countryCode": "GB",
        "phoneCode": "44"
    },
    {
        "id": 2245662,
        "name": "Senegal",
        "countryCode": "SN",
        "phoneCode": "221",
        "nativeName": "Sénégal"
    },
    {
        "id": 241170,
        "name": "Seychelles",
        "countryCode": "SC",
        "phoneCode": "248",
        "nativeName": "Seychelles"
    },
    {
        "id": 2403846,
        "name": "Sierra Leone",
        "countryCode": "SL",
        "phoneCode": "232",
        "nativeName": "Sierra Leone"
    },
    {
        "id": 1880251,
        "name": "Singapore",
        "countryCode": "SG",
        "phoneCode": "65",
        "nativeName": "Singapore"
    },
    {
        "id": 7609695,
        "name": "Sint Maarten",
        "countryCode": "SX",
        "phoneCode": "1721"
    },
    {
        "id": 3057568,
        "name": "Slovakia",
        "countryCode": "SK",
        "phoneCode": "421",
        "nativeName": "Slovensko"
    },
    {
        "id": 3190538,
        "name": "Slovenia",
        "countryCode": "SI",
        "phoneCode": "386",
        "nativeName": "Slovenija"
    },
    {
        "id": 2103350,
        "name": "Solomon Islands",
        "countryCode": "SB",
        "phoneCode": "677",
        "nativeName": "Solomon Islands"
    },
    {
        "id": 51537,
        "name": "Somalia",
        "countryCode": "SO",
        "phoneCode": "252",
        "nativeName": "Soomaaliya"
    },
    {
        "id": 953987,
        "name": "South Africa",
        "countryCode": "ZA",
        "phoneCode": "27",
        "nativeName": "South Africa"
    },
    {
        "id": 3474415,
        "name": "South Georgia and the South Sandwich Islands",
        "countryCode": "GS",
        "phoneCode": "500",
        "nativeName": "South Georgia"
    },
    {
        "id": 7909807,
        "name": "South Sudan",
        "countryCode": "SS",
        "phoneCode": "211",
        "nativeName": "South Sudan"
    },
    {
        "id": 2510769,
        "name": "Spain",
        "countryCode": "ES",
        "phoneCode": "34",
        "nativeName": "España"
    },
    {
        "id": 1227603,
        "name": "Sri Lanka",
        "countryCode": "LK",
        "phoneCode": "94",
        "nativeName": "śrī laṃkāva"
    },
    {
        "id": 366755,
        "name": "Sudan",
        "countryCode": "SD",
        "phoneCode": "249",
        "nativeName": "السودان"
    },
    {
        "id": 3382998,
        "name": "Suriname",
        "countryCode": "SR",
        "phoneCode": "597",
        "nativeName": "Suriname"
    },
    {
        "id": 607072,
        "name": "Svalbard and Jan Mayen",
        "countryCode": "SJ",
        "phoneCode": "4779",
        "nativeName": "Svalbard og Jan Mayen"
    },
    {
        "id": 934841,
        "name": "Swaziland",
        "countryCode": "SZ",
        "phoneCode": "268",
        "nativeName": "Swaziland"
    },
    {
        "id": 2661886,
        "name": "Sweden",
        "countryCode": "SE",
        "phoneCode": "46",
        "nativeName": "Sverige"
    },
    {
        "id": 163843,
        "name": "Syria",
        "countryCode": "SY",
        "phoneCode": "963"
    },
    {
        "id": 1668284,
        "name": "Taiwan",
        "countryCode": "TW",
        "phoneCode": "886",
        "nativeName": "臺灣"
    },
    {
        "id": 1220409,
        "name": "Tajikistan",
        "countryCode": "TJ",
        "phoneCode": "992",
        "nativeName": "Тоҷикистон"
    },
    {
        "id": 149590,
        "name": "Tanzania",
        "countryCode": "TZ",
        "phoneCode": "255"
    },
    {
        "id": 1605651,
        "name": "Thailand",
        "countryCode": "TH",
        "phoneCode": "66",
        "nativeName": "ประเทศไทย"
    },
    {
        "id": 2363686,
        "name": "Togo",
        "countryCode": "TG",
        "phoneCode": "228",
        "nativeName": "Togo"
    },
    {
        "id": 4031074,
        "name": "Tokelau",
        "countryCode": "TK",
        "phoneCode": "690",
        "nativeName": "Tokelau"
    },
    {
        "id": 4032283,
        "name": "Tonga",
        "countryCode": "TO",
        "phoneCode": "676",
        "nativeName": "Tonga"
    },
    {
        "id": 3573591,
        "name": "Trinidad and Tobago",
        "countryCode": "TT",
        "phoneCode": "1868",
        "nativeName": "Trinidad and Tobago"
    },
    {
        "id": 2464461,
        "name": "Tunisia",
        "countryCode": "TN",
        "phoneCode": "216",
        "nativeName": "تونس"
    },
    {
        "id": 298795,
        "name": "Turkey",
        "countryCode": "TR",
        "phoneCode": "90",
        "nativeName": "Türkiye"
    },
    {
        "id": 1218197,
        "name": "Turkmenistan",
        "countryCode": "TM",
        "phoneCode": "993",
        "nativeName": "Türkmenistan"
    },
    {
        "id": 3576916,
        "name": "Turks and Caicos Islands",
        "countryCode": "TC",
        "phoneCode": "1649",
        "nativeName": "Turks and Caicos Islands"
    },
    {
        "id": 2110297,
        "name": "Tuvalu",
        "countryCode": "TV",
        "phoneCode": "688",
        "nativeName": "Tuvalu"
    },
    {
        "id": 5854968,
        "name": "U.S. Minor Outlying Islands",
        "countryCode": "UM",
        "phoneCode": "246"
    },
    {
        "id": 4796775,
        "name": "U.S. Virgin Islands",
        "countryCode": "VI",
        "phoneCode": "1340"
    },
    {
        "id": 226074,
        "name": "Uganda",
        "countryCode": "UG",
        "phoneCode": "256",
        "nativeName": "Uganda"
    },
    {
        "id": 690791,
        "name": "Ukraine",
        "countryCode": "UA",
        "phoneCode": "380",
        "nativeName": "Україна"
    },
    {
        "id": 290557,
        "name": "United Arab Emirates",
        "countryCode": "AE",
        "phoneCode": "971",
        "nativeName": "دولة الإمارات العربية المتحدة"
    },
    {
        "id": 2635167,
        "name": "United Kingdom",
        "countryCode": "GB",
        "phoneCode": "44"
    },
    {
        "id": 6252001,
        "name": "United States",
        "countryCode": "US",
        "phoneCode": "1"
    },
    {
        "id": 3439705,
        "name": "Uruguay",
        "countryCode": "UY",
        "phoneCode": "598",
        "nativeName": "Uruguay"
    },
    {
        "id": 1512440,
        "name": "Uzbekistan",
        "countryCode": "UZ",
        "phoneCode": "998",
        "nativeName": "O‘zbekiston"
    },
    {
        "id": 2134431,
        "name": "Vanuatu",
        "countryCode": "VU",
        "phoneCode": "678",
        "nativeName": "Vanuatu"
    },
    {
        "id": 3164670,
        "name": "Vatican City",
        "countryCode": "VA",
        "phoneCode": "379"
    },
    {
        "id": 3625428,
        "name": "Venezuela",
        "countryCode": "VE",
        "phoneCode": "58"
    },
    {
        "id": 1562822,
        "name": "Vietnam",
        "countryCode": "VN",
        "phoneCode": "84"
    },
    {
        "id": 7909811,
        "name": "Wales",
        "countryCode": "GB",
        "phoneCode": "44"
    },
    {
        "id": 4034749,
        "name": "Wallis and Futuna",
        "countryCode": "WF",
        "phoneCode": "681",
        "nativeName": "Wallis et Futuna"
    },
    {
        "id": 7909812,
        "name": "Western Sahara",
        "countryCode": "EH",
        "phoneCode": "212",
        "nativeName": "الصحراء الغربية"
    },
    {
        "id": 69543,
        "name": "Yemen",
        "countryCode": "YE",
        "phoneCode": "967",
        "nativeName": "اليَمَن"
    },
    {
        "id": 895949,
        "name": "Zambia",
        "countryCode": "ZM",
        "phoneCode": "260",
        "nativeName": "Zambia"
    },
    {
        "id": 878675,
        "name": "Zimbabwe",
        "countryCode": "ZW",
        "phoneCode": "263",
        "nativeName": "Zimbabwe"
    }
];

export const regions: CountryModel[] = [
    {
        "id": -1,
        "name": "EU",
        "countryCode": "eu",
        "phoneCode": ""
    },
    {
        "id": -2,
        "name": "EEA + Switzerland",
        "countryCode": "eeach",
        "phoneCode": ""
    },
    {
        "id": -3,
        "name": "World",
        "countryCode": "world",
        "phoneCode": ""
    },
];

export const getRegion = (regionId: string) => {
    return regions.find(region => region.countryCode === regionId);
};

export const mapCountryCodeToChartRegionCode = (countryCode: string) => {
    if (!countryCode) return 'world';

    // ***** Africa ***** //

    if (['DZ', 'EG', 'EH', 'LY', 'MA', 'SD', 'SS', 'TN'].find(c => c === countryCode.toUpperCase())) {
        return '015' // - Northern Africa
    }

    if (['BF', 'BJ', 'CI', 'CV', 'GH', 'GM', 'GN', 'GW', 'LR', 'ML', 'MR', 'NE', 'NG', 'SH', 'SL', 'SN', 'TG'].find(c => c === countryCode.toUpperCase())) {
        return '011'; // - Western Africa
    }

    if (['AO', 'CD', 'ZR', 'CF', 'CG', 'CM', 'GA', 'GQ', 'ST', 'TD'].find(c => c === countryCode.toUpperCase())) {
        return '017'; // - Middle Africa
    }

    if (['BI', 'DJ', 'ER', 'ET', 'KE', 'KM', 'MG', 'MU', 'MW', 'MZ', 'RE', 'RW', 'SC', 'SO', 'TZ', 'UG', 'YT', 'ZM', 'ZW'].find(c => c === countryCode.toUpperCase())) {
        return '014'; // - Eastern Africa
    }

    if (['BW', 'LS', 'NA', 'SZ', 'ZA'].find(c => c === countryCode.toUpperCase())) {
        return '018'; // - Southern Africa
    }

    // ***** Europe ***** //

    if (['GG', 'JE', 'AX', 'DK', 'EE', 'FI', 'FO', 'GB', 'IE', 'IM', 'IS', 'LT', 'LV', 'NO', 'SE', 'SJ'].find(c => c === countryCode.toUpperCase())) {
        return '154' // - Northern Europe
    }

    if (['AT', 'BE', 'CH', 'DE', 'DD', 'FR', 'FX', 'LI', 'LU', 'MC', 'NL'].find(c => c === countryCode.toUpperCase())) {
        return '155' // - Western Europe
    }

    if (['BG', 'BY', 'CZ', 'HU', 'MD', 'PL', 'RO', 'RU', 'SU', 'SK', 'UA'].find(c => c === countryCode.toUpperCase())) {
        return '151' // - Eastern Europe
    }

    if (['AD', 'AL', 'BA', 'ES', 'GI', 'GR', 'HR', 'IT', 'ME', 'MK', 'MT', 'RS', 'PT', 'SI', 'SM', 'VA', 'YU'].find(c => c === countryCode.toUpperCase())) {
        return '039' // - Southern Europe
    }

    // ***** Americas ***** //

    if (['BM', 'CA', 'GL', 'PM', 'US'].find(c => c === countryCode.toUpperCase())) {
        return '021' // - Northern America
    }

    if (['AG', 'AI', 'AN', 'AW', 'BB', 'BL', 'BS', 'CU', 'DM', 'DO', 'GD', 'GP', 'HT', 'JM', 'KN', 'KY', 'LC', 'MF', 'MQ', 'MS', 'PR', 'TC', 'TT', 'VC', 'VG', 'VI'].find(c => c === countryCode.toUpperCase())) {
        return '029' // - Caribbean
    }

    if (['BZ', 'CR', 'GT', 'HN', 'MX', 'NI', 'PA', 'SV'].find(c => c === countryCode.toUpperCase())) {
        return '013' // - Central America
    }

    if (['AR', 'BO', 'BR', 'CL', 'CO', 'EC', 'FK', 'GF', 'GY', 'PE', 'PY', 'SR', 'UY', 'VE'].find(c => c === countryCode.toUpperCase())) {
        return '005' // - South America
    }

    // ***** Asia ***** //

    if (['TM', 'TJ', 'KG', 'KZ', 'UZ'].find(c => c === countryCode.toUpperCase())) {
        return '143' // - Central Asia
    }

    if (['CN', 'HK', 'JP', 'KP', 'KR', 'MN', 'MO', 'TW'].find(c => c === countryCode.toUpperCase())) {
        return '030' // - Eastern Asia
    }

    if (['AF', 'BD', 'BT', 'IN', 'IR', 'LK', 'MV', 'NP', 'PK'].find(c => c === countryCode.toUpperCase())) {
        return '034' // - Southern Asia
    }

    if (['BN', 'ID', 'KH', 'LA', 'MM', 'BU', 'MY', 'PH', 'SG', 'TH', 'TL', 'TP', 'VN'].find(c => c === countryCode.toUpperCase())) {
        return '035' // - South-Eastern Asia
    }

    if (['AE', 'AM', 'AZ', 'BH', 'CY', 'GE', 'IL', 'IQ', 'JO', 'KW', 'LB', 'OM', 'PS', 'QA', 'SA', 'NT', 'SY', 'TR', 'YE', 'YD'].find(c => c === countryCode.toUpperCase())) {
        return '145' // - Western Asia
    }

    // ***** Oceania ***** //

    if (['AU', 'NF', 'NZ'].find(c => c === countryCode.toUpperCase())) {
        return '053' // - Australia and New Zealand
    }

    if (['FJ', 'NC', 'PG', 'SB', 'VU'].find(c => c === countryCode.toUpperCase())) {
        return '054' // - Melanesia
    }

    if (['FM', 'GU', 'KI', 'MH', 'MP', 'NR', 'PW'].find(c => c === countryCode.toUpperCase())) {
        return '057' // - Micronesia
    }

    if (['AS', 'CK', 'NU', 'PF', 'PN', 'TK', 'TO', 'TV', 'WF', 'WS'].find(c => c === countryCode.toUpperCase())) {
        return '061' // - Polynesia
    }

    return 'world';
}


export const euCountryIds = [
    2782113,        // Austria
    2802361,        // Belgium
    732800,         // Bulgaria
    3202326,        // Croatia
    146669,         // Cyprus
    3077311,        // Czech Republic,
    2623032,        // Denmark
    453733,         // Estonia
    660013,         // Finland,
    3017382,        // France
    2921044,        // Germany,
    390903,         // Greece
    719819,         // Hungary
    2963597,        // Ireland
    3175395,        // Italy
    458258,         // Latvia
    597427,         // Lithuania
    2960313,        // Luxembourg
    2562770,        // Malta
    2750405,        // Netherlands
    798544,         // Poland
    2264397,        // Portugal
    798549,         // Romania
    3057568,        // Slovakia
    3190538,        // Slovenia
    2510769,        // Spain
    2661886,        // Sweden
    2635167,        // United Kingdom,
    7909808,        // England
    7909810,        // Scotland
    7909811,        // Wales
    7909809,        // Northern Ireland
];

export const eeaChCountryIds = [
    ...euCountryIds,
    2629691,        // Iceland
    3042058,        // Liechtenstein
    3144096,        // Norway
    2658434,        // Switzerland
];

export const worldIds: number[] = countries.map(c => c.id!)

export const addCountries = (addTo: CountryModel[], countriesToAdd: CountryModel[]) => {
    const newCountries = [...addTo];

    countriesToAdd.forEach(countryToAdd => {
        if (!newCountries.find(c => c.id === countryToAdd.id)) {
            newCountries.push(countryToAdd);
        }
    });

    return newCountries;
};

export const removeCountries = (removeFrom: CountryModel[], countriesToRemove: CountryModel[]) => {
    return removeFrom.filter(c => !countriesToRemove.find(countryToRemove => countryToRemove.id === c.id));
};

export const getCountriesByIds = (ids: number[]): CountryModel[] => {
    // @ts-ignore
    return ids.map(id => countries.find(country => country.id === id)).filter(c => !!c);
};

export const countriesHaveAllIds = (countries: CountryModel[], ids: number[]) => {
    return ids.reduce((prev, current) => {
        return prev && !!countries.find(country => country.id === current);
    }, true)
};

export const getCountryByCountryCode = (code: string) => {
    if (!code) return null;

    return countries.find(c => c.countryCode && c.countryCode.toLowerCase() === code.toLowerCase());
};

export const getCountryByPhoneCode = (phoneCode: string) => {
    if (!phoneCode) return null;
    const phoneCodeClean = phoneCode.startsWith('+') ? phoneCode.substr(1) : phoneCode;

    return countries.find(c => c.phoneCode && c.phoneCode.toLowerCase() === phoneCodeClean.toLowerCase());
};
