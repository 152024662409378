import {CloudinaryResource, CloudinaryResponse} from "../models/cloudinary.model";

declare global {
    interface Window {
        cloudinary: any
    }
}

export interface UploadOptions {
    multiple?: boolean
    resourceType?: 'auto' | 'image' | 'video' | 'raw'
}

export default class CloudinaryService {

    public static async upload(file: any) {
        const formData = new FormData();
        formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_IMAGE_UPLOAD_PRESET!);
        formData.append('file', file);

        const response = await fetch(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_ID}/upload`, {
            method: 'POST',
            body: formData
        });

        return response.json();
    }

    static async uploadMedia(options?: UploadOptions): Promise<CloudinaryResource> {
        return new Promise<CloudinaryResource>((resolve, reject) => {
            window.cloudinary.openUploadWidget({
                cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_ID,
                uploadPreset: process.env.REACT_APP_CLOUDINARY_IMAGE_UPLOAD_PRESET,
                ...options
            }, (error: any, result: CloudinaryResponse) => {
                if (error) {
                    reject(error)
                }

                if (result.event === 'success' && result.info) {
                    resolve(result.info)
                }
            })
        })
    }

    public static getVideoPreviewUrl(videoUrl?: string) {
        return (videoUrl && `${videoUrl.substring(0, videoUrl.lastIndexOf('.'))}.jpg`) || '';
    }
}
