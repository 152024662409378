import React from 'react';
import styles from './PlayerCard.module.scss';
import UserModel, {VerifiedStatus} from "../../../../models/user.model";
import clsx from 'clsx';
import {useTranslation} from "react-i18next";
import {
    getCityCountryName,
    getFirstName,
    getFullName,
    getGenderLocalized,
    getLastName,
    getUserAgeLocalized,
    getUserMainPositionLocalizedLong,
    getUserNationalities,
    getUserProfilePicture,
    isPhAdmin,
    isAgentPlayer,
    isPlayerPremium
} from "../../../../util/profile.util";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import {Box, Grid} from "@material-ui/core";
import {useSelector} from "react-redux";
import {windowWidth} from "../../../../util/dimension.util";
import {getCountryFlag} from "../../../../util/flag.util";
import {useUser} from "../../../../store/store.utils";
import EuroIcon from '@material-ui/icons/Euro';
import TransfermarktValue from "../../../common/TransfermarktValue.component";

interface Props {
    player: UserModel | undefined,
    onShare?: () => void,
    shareButton?: boolean,
    canLeadToEdit?: boolean
}

const PlayerCard = (props: Props) => {

    const t = useTranslation().t;
    const premium = isPlayerPremium(props.player);
    const loggedInUser = useUser()
    const isAgentsPlayer = isAgentPlayer(props.player)
    const transfermarktValue = props.player?.profile?.transfermarktValue

    const parts = []
    if (getFirstName(props.player)) parts.push(getFirstName(props.player))
    if (getLastName(props.player)) parts.push(getLastName(props.player))
    const googleQuery = [...parts, 'transfermarkt'].join('+')
    const youtubeQuery = [...parts, 'highlights'].join('+')

    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            className={styles.container}
        >

            <div className={styles.hexagonThinHolder}>
                <img alt={''} src={require('../../../../assets/images/hexagon-thin.svg').default} className={styles.hexagonThinLeft}/>
                <img alt={''} src={require('../../../../assets/images/hexagon-thin.svg').default} className={styles.hexagonThinRight}/>
            </div>

            <div className={styles.hexagon}>
                <img alt={''}
                     src={premium ? require('../../../../assets/images/hexagon-gold.svg').default : require('../../../../assets/images/hexagon.svg').default}
                     className={styles.hexagonBackground}/>
                <img alt={'Profile'}
                     src={getUserProfilePicture(props.player, require('../../../../assets/images/avatar.svg').default)}
                     className={styles.profilePic}/>
            </div>

            <Box className={styles.cardInfoHolder}>

                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    className={clsx(styles.nameFlagSection, styles.cardInfoHolder)}
                >
                    <p>{getFullName(props.player)}</p>
                    {props.shareButton &&
                    <img alt={'Share'} src={require('../../../../assets/images/share.svg').default} className={styles.shareIcon} onClick={props.onShare}/>}

                    {
                        isPhAdmin(loggedInUser) &&
                        <img
                            alt={'Google'}
                            src={require('../../../../assets/images/google.svg').default}
                            className={styles.adminActionIcon}
                            onClick={() => {
                                window.open(`https://www.google.com/search?q=${encodeURI(googleQuery)}`, '_blank')
                            }}
                        />
                    }

                    {
                        isPhAdmin(loggedInUser) &&
                        <img
                            alt={'Youtube'}
                            src={require('../../../../assets/images/youtube.svg').default}
                            className={styles.adminActionIcon}
                            onClick={() => {
                                window.open(`https://www.youtube.com/results?search_query=${encodeURI(youtubeQuery)}`, '_blank')
                            }}
                        />
                    }

                    {
                        isPhAdmin(loggedInUser) && props.canLeadToEdit &&
                        <img
                            alt={'Edit profile'}
                            src={require('../../../../assets/images/edit-orange.svg').default}
                            className={styles.adminActionIcon}
                            onClick={() => {
                                window.open(`${process.env.webUrl}/admin/user/${props.player?.id}`, '_blank')
                            }}
                        />
                    }

                </Box>

                <Grid
                    style={{
                        marginTop: 10,
                        marginBottom: 10
                    }}
                    container
                    justify={windowWidth() < 1100 ? 'center' : 'flex-start'}
                    spacing={3}
                    alignItems={'center'}
                >
                    {
                        props.player?.profile?.verified === VerifiedStatus.VERIFIED &&
                        <Grid item>
                            <Box style={{fontSize: 14}} display={'flex'} alignItems={'center'}>
                                <VerifiedUserIcon style={{color: 'green', fontSize: 24, marginRight: '5px'}}/>
                                <p>{t('Verified player')}</p>
                            </Box>
                        </Grid>
                    }

                    {
                        isAgentsPlayer &&
                        <Grid item>
                            <Box style={{fontSize: 14}} display={'flex'} alignItems={'center'}>
                                <img
                                    src={require('../../../../assets/images/agent-gold.svg').default}
                                    style={{
                                        width: 24,
                                        height: 24,
                                        marginRight: 5
                                    }}
                                />
                                <p>{t("Agent's player")}</p>
                            </Box>
                        </Grid>
                    }

                    {
                        !!transfermarktValue &&
                        <Grid item>
                            <TransfermarktValue user={props.player} />
                        </Grid>
                    }

                </Grid>

                <Grid
                    container
                    spacing={3}
                    justify={windowWidth() < 1100 ? 'center' : 'flex-start'}
                    style={{marginBottom: 10}}
                >
                    <Grid item>
                        <div>{getCityCountryName(props.player, '-')}</div>
                    </Grid>
                    <Grid item>
                        {
                            getUserNationalities(props.player, [])
                                .slice(0, 3)
                                .map(nationality =>
                                    <div
                                        key={nationality.id}
                                        className={clsx(styles.flag, 'flag-icon', getCountryFlag(nationality))}
                                    />
                                )
                        }
                    </Grid>

                    <Grid item>
                        <div>{getUserAgeLocalized(t, props.player)}</div>
                    </Grid>
                </Grid>

                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    className={clsx(styles.mainDetailsSection, styles.cardInfoHolder)}
                >

                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        className={styles.item}
                    >
                        <img alt={'Gender'} src={require('../../../../assets/images/gender-male.svg').default} className={styles.icon}/>
                        <p className={styles.text}>{getGenderLocalized(t, props.player)}</p>
                    </Box>

                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        className={styles.item}
                    >
                        <img alt={'Position'} src={require('../../../../assets/images/striker.svg').default} className={styles.icon}/>
                        <p className={styles.text}>{getUserMainPositionLocalizedLong(t, props.player)}</p>
                    </Box>

                </Box>

            </Box>

        </Box>
    )
}

export default PlayerCard;
