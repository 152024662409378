import React, {useCallback, useEffect, useState} from 'react'
import {Avatar, Box, Button, Card, CircularProgress, Dialog, DialogActions, Grid, Typography} from "@material-ui/core";
import UserModel from "../../models/user.model";
import UserService from "../../services/user.service";
import debounce from "lodash/debounce";
import _ from "lodash";
import ETextField from "./ETextField.component";
import {getEmail, getFullName, getProfilePicture} from "../../util/profile.util";
import {makeStyles} from "@material-ui/core/styles";

const useStyle = makeStyles(theme => ({
    userBadge: {
        display: 'flex',
        flexDirection: 'column',
        // justifyItems: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            background: '#f8f8f8'
        }
    },
    userAvatar: {
        fontSize: 12
    },
    userName: {
        textAlign: 'center'
    },
    userEmail: {
        textAlign: 'center'
    }
}))

interface Props {
    open: boolean;
    onClose: () => void;
    onPick: (user: UserModel) => void;
    userRoles?: ('PLAYER' | 'AGENT' | 'STAFF' | 'ADMIN')[]
}

const PickUserPopup = (props: Props) => {

    const classes = useStyle()
    const [query, setQuery] = useState('');
    const [users, setUsers] = useState<UserModel[]>([]);
    const [loading, setLoading] = useState(false);
    const [firstNameQuery, setFirstNameQuery] = useState('')
    const [lastNameQuery, setLastNameQuery] = useState('')

    const getUsers = async (firstNameQuery: string, lastNameQuery: string) => {
        if (!props.open) return

        setUsers([]);
        setLoading(true);
        try {
            const filters: any = {}
            props.userRoles?.length && (filters.userRoles = props.userRoles)
            firstNameQuery && (filters.firstName = firstNameQuery)
            lastNameQuery && (filters.lastName = lastNameQuery)
            const result = await UserService.getUsers({page: 0, size: 12}, filters);
            setUsers(_.cloneDeep(result?.content || []));
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    const getUsersDebounced = useCallback(debounce((firstNameQuery, lastNameQuery) => getUsers(firstNameQuery, lastNameQuery), 1000), [props.open]);

    useEffect(() => {
        if (props.open) {
            setFirstNameQuery('')
            setLastNameQuery('')
            setUsers([])
        }
    }, [props.open])

    useEffect(() => {
        if (props.open) {
            getUsersDebounced(firstNameQuery, lastNameQuery)
        }
    }, [props.open, firstNameQuery, lastNameQuery]);

    const renderUsers = () => {
        if (loading) {
            return (
                <CircularProgress/>
            )
        }

        return (
            <Grid container spacing={3}>
                {
                    users?.map(user => {
                        return (
                            <Grid
                                item
                                xs={4}
                                className={classes.userBadge}
                                onClick={() => props.onPick(user)}
                            >
                                <Avatar
                                    className={classes.userAvatar}
                                    src={getProfilePicture(user)}
                                >
                                    {getFullName(user, '-').substring(0, 2)}
                                </Avatar>
                                <Typography
                                    variant={'body2'}
                                    className={classes.userName}
                                >
                                    {getFullName(user, '-')}
                                </Typography>
                                <Typography
                                    variant={'subtitle2'}
                                    className={classes.userEmail}
                                >
                                    {getEmail(user, '-')}
                                </Typography>

                            </Grid>
                        )
                    })
                }
            </Grid>
        )
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth={true}
            maxWidth={"md"}
        >

            <Card style={{width: '100%'}}>
                <Box p={2} width={'100%'}>
                    <Grid container spacing={3} style={{width: '100%'}}>
                        <ETextField
                            grid={'1/2'}
                            value={firstNameQuery}
                            onChange={setFirstNameQuery}
                            label={'First name'}
                        />
                        <ETextField
                            grid={'1/2'}
                            value={lastNameQuery}
                            onChange={setLastNameQuery}
                            label={'Second name'}
                        />
                    </Grid>

                    <Box marginTop={2}>
                    </Box>

                    <Box
                        width={'100%'}
                        minHeight={400}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        {renderUsers()}
                    </Box>
                </Box>
            </Card>

            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>

        </Dialog>
    )

}

export default PickUserPopup

