import React, {useEffect, useState} from 'react'
import {Box, Button, Card, CircularProgress, TextField} from "@material-ui/core"
import MixpanelService from "../../services/analytics/mixpanel.service"
import {useTranslation} from "react-i18next"
import {ScreenName} from "../../services/analytics/screenName"
import {AuthCommon, useAuthCommonStyles} from "./auth.common.";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router";
import {emptyValidator, isFormValid, validateForm} from "../../util/validators";
import {emailValidator} from "../../util/form.util";
import AuthService from "../../services/auth.service";

const useStyle = makeStyles(_theme => ({
    containerHolder: {
        zIndex: 1,
        width: 'calc(100% - 40px)',
        maxWidth: 450,
        marginBottom: 'auto'
    },
    container: {
        width: '100%',
        padding: 15,
        [_theme.breakpoints.up('md')]: {
            padding: 20,
        }
    },
    done: {
        marginTop: 20
    },
    passwordSentMessage: {
        marginTop: 25,
        textAlign: 'center'
    }
}))

const ForgotPassword = () => {

    //region State

    const t = useTranslation().t
    const history = useHistory()
    const commonClasses = useAuthCommonStyles()
    const classes = useStyle()
    const [passwordResetState, setPasswordResetState] = useState('')
    const [form, setForm] = useState({
        email: {
            value: '',
            placeholder: t("email"),
            type: 'email',
            validation: {
                error: '',
                validators: [emptyValidator, emailValidator]
            }
        },
    })

    //endregion State

    //region Handlers

    useEffect(() => {
        MixpanelService.trackScreenVisit(ScreenName.FORGOT_PASSWORD)
    }, [])

    const validate = (): boolean => {
        let newForm: any = validateForm(form)
        setForm(newForm)
        return isFormValid(newForm)
    }

    const resetHandler = async () => {
        if (validate()) {
            try {
                setPasswordResetState('loading')
                await AuthService.resetPassword(form.email.value)
                setPasswordResetState('sent')
            } catch (e) {
                setPasswordResetState('error')
            }
        }
    }

    const inputHandler = (key: 'email', value: string) => {
        const formElement = {
            ...form[key],
            validation: {
                ...form[key].validation,
                error: ''
            }
        }
        formElement.value = value
        setForm({
            ...form,
            [key]: formElement
        })
    }

    //endregion Handlers

    //region UI

    const mapErrorToMessage = (error: string): string => {
        switch (error) {
            case 'empty':
                return t('error_empty_field')
            case 'email_not_valid':
                return t('error_bad_email')
        }
        return ''
    }

    const renderInputField = (key: 'email') => {
        const formElement = form[key]
        return (
            <TextField
                disabled={passwordResetState === 'loading'}
                onChange={(event) => inputHandler(key, event.target.value)}
                value={formElement.value}
                className={commonClasses.input}
                required
                type={formElement.type}
                label={formElement.placeholder}
                margin="normal"
                variant={'outlined'}
                error={!!formElement.validation.error}
                helperText={mapErrorToMessage(formElement.validation.error)}
            />
        )
    }

    const renderBeforeResetState = () => {
        return (
            <React.Fragment>
                {renderInputField('email')}
                {
                    passwordResetState === 'loading' ?
                        <CircularProgress/> :
                        <Button variant="contained" color="primary" className={[classes.done].join(' ')}
                                onClick={resetHandler}>
                            {t("done")}
                        </Button>
                }
            </React.Fragment>
        )
    }

    const renderAfterResetState = () => {
        return (
            <React.Fragment>
                <div
                    className={classes.passwordSentMessage}>{t('password_instructions_sent_to', {email: form.email.value})}</div>
                {
                    <Button variant="contained" color="primary" className={[classes.done].join(' ')}
                            onClick={() => history.push('/auth/login')}>
                        {t('back_to_login')}
                    </Button>
                }
            </React.Fragment>
        )
    }

    const formUI = (
        <Card className={classes.containerHolder}>
            <Box
                className={classes.container}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                boxSizing={'border-box'}
            >
                {passwordResetState === 'sent' ? renderAfterResetState() : renderBeforeResetState()}
            </Box>
        </Card>
    )

    return (
        <Box
            className={commonClasses.component}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
        >
            <AuthCommon title={t("reset_your_password")}/>
            {formUI}
        </Box>
    )

    //endregion UI
}

export default ForgotPassword
