import React, {useEffect} from 'react';
import ClubHeader from "./ClubHeader/ClubHeader";
import ClubModel from "../../../models/club.model";
import styles from './ClubProfile.module.scss';
import ClubLocation from "./ClubLocation/ClubLocation";
import ClubAboutUs from "./ClubAboutUs/ClubAboutUs";
import ClubAlbums from "./ClubAlbums/ClubAlbums";
import ClubTeams from "./ClubTeams/ClubTeams";
import MixpanelService from "../../../services/analytics/mixpanel.service";
import {useTranslation} from "react-i18next";
import ClubService from "../../../services/club.service";
import {EventName} from "../../../services/analytics/eventName";
import {PeopleProperty} from "../../../services/analytics/peopleProperty";
import {Box, Button, Grid} from "@material-ui/core";
import {useHistory} from "react-router";
import {useUser} from "../../../store/store.utils";
import {getClubName, isPhAdmin} from "../../../util/profile.util";
import {ScreenName} from "../../../services/analytics/screenName";

interface Props {
    club?: ClubModel,
    setClub?: (club?: ClubModel) => void,
    canEdit?: boolean,
    showPublicCTA?: boolean
}

const ClubProfile = ({club, canEdit, setClub, showPublicCTA}: Props) => {

    // ***** State ***** //

    const t = useTranslation().t;
    const history = useHistory()
    const user = useUser()

    // const clubId = props.match.params.id

    // ***** Effects ***** //

    // const fetchClubInfo = async () => {
    //     if (id === props.userClub?.id && props.userClub?.isRequest) {
    //         setClub({
    //             ...props.userClub,
    //             clubAdmins: [{user: props.user}]
    //         });
    //         setPayment(undefined);
    //     } else {
    //         const response = await ClubService.getClub(id, !props.user, isPhAdmin(props.user))
    //         setClub(response);
    //         setPayment(response?.payments?.[0]);
    //     }
    // }
    //

    useEffect(() => {
        MixpanelService.trackScreenVisit(ScreenName.PROFILE);
    }, []);

    // ***** Handlers ***** //

    const updateClubInfo = async (info: ClubModel) => {
        try {
            await ClubService.updateClubInfo(info);
            const response = await ClubService.getClub(club?.id!, false, isPhAdmin(user));
            setClub?.(response);
            MixpanelService.track(EventName.CLUB_EDIT);
            MixpanelService.setUserProperties({
                [PeopleProperty.LAST_CLUB_EDIT]: MixpanelService.getCurrentTimeIso()
            });
        } catch (err) {
        }
    }

    // ***** Render ***** //

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={styles.component}
            marginTop={'50px'}
        >

            <Grid
                container
                spacing={2}
                className={styles.content}
            >

                <Grid
                    item xs={12}
                    className={styles.publicCtaHolder}
                >

                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                    >
                        <div className={styles.additionalInfoTitle}>{t('are_you_looking_for_players')}</div>

                        <Button
                            className={styles.publicCta}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => history.push('/auth/club')}
                        >
                            {t('start_now')}
                        </Button>
                    </Box>

                </Grid>

                <Grid item xs={12} lg={4}>
                    <ClubHeader club={club} user={user} onUpdate={updateClubInfo}/>
                </Grid>

                <Grid item xs={12} lg={8}>
                    <ClubAboutUs user={user} club={club} onUpdate={updateClubInfo}/>
                </Grid>

                <Grid item xs={12} lg={4}>
                    <ClubLocation user={user} club={club} onUpdate={updateClubInfo}/>
                </Grid>

                <Grid item xs={12} lg={4}>
                    <ClubAlbums user={user} club={club} onUpdate={setClub}/>
                </Grid>

                <Grid item xs={12} lg={4}>
                    <ClubTeams user={user} club={club} onUpdate={updateClubInfo}/>
                </Grid>

            </Grid>

            {
                showPublicCTA &&
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    className={styles.bottomCtaHolder}
                >

                    <div className={styles.appStoreTitle}>
                        {t('playing_for_club_create_card', {club: getClubName(club)})}
                    </div>

                    <Box
                        display={'flex'}
                        flexWrap={'wrap'}
                    >
                        <a className={styles.appStoreLink}
                           href="https://apps.apple.com/us/app/playerhunter/id1151813385"
                           target="_blank" rel={'noopener noreferrer'}>
                            <picture>
                                <source srcSet={require('../../../assets/images/app-store.webp').default}
                                        type="image/webp"/>
                                <img alt={'App store'} src={require('../../../assets/images/app-store.png').default}/>
                            </picture>
                        </a>
                        <a className={styles.appStoreLink}
                           href="https://play.google.com/store/apps/details?id=com.playerhunter&hl=en"
                           target="_blank" rel={'noopener noreferrer'}>
                            <picture>
                                <source srcSet={require('../../../assets/images/google-play.webp').default}
                                        type="image/webp"/>
                                <img alt={'Google play'}
                                     src={require('../../../assets/images/google-play.png').default}/>
                            </picture>
                        </a>
                    </Box>
                </Box>
            }

        </Box>
    )
}

// **** Redux **** //

export default ClubProfile
