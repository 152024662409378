import UserModel from "./user.model";

export enum UserSubscriptionType {
    BASIC = 'BASIC',
    PREMIUM = 'PREMIUM',
}

export interface UserSubscriptionModel {
    id?: number;
    expirationDate?: Date;
    subscriptionDate?: Date;
    type?: UserSubscriptionType;
    user?: UserModel;
}
