import {Breadcrumbs, Link, Typography} from "@material-ui/core";
import React from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import {useBreadcrumbsStyle} from "../../components/common/List/list.style";


interface Props {
    path: { url?: string, label?: string }[]
}

export const useBreadcrumbs = (props: Props) => {

    const breadcrumbsClasses = useBreadcrumbsStyle()

    return (
        <Breadcrumbs
            className={breadcrumbsClasses.breadcrumbs}
            separator={<NavigateNextIcon fontSize="small"/>}
        >
            <Link color="inherit" href="/">
                <HomeOutlinedIcon color={'primary'}/>
            </Link>

            {
                props.path.map(pathChunk => {
                    if (!!pathChunk.url) {
                        return (
                            <Link key={pathChunk.url} color="primary" href={pathChunk.url}>
                                {pathChunk.label}
                            </Link>
                        )
                    } else {
                        return (
                            <Typography color="textPrimary" key={pathChunk.label}>{pathChunk.label}</Typography>
                        )
                    }
                })
            }
        </Breadcrumbs>
    )
}