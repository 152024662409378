import {ADD_JOB, LOGOUT, REMOVE_JOB, SET_JOBS} from "../actions/actionTypes"
import Job from "../../models/job.model";

export interface JobsState {
    jobs?: Job[]
}

const initialState: JobsState = {}

const jobsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_JOBS:
            return {
                ...state,
                jobs: action.payload || []
            }
        case ADD_JOB:
            return {
                ...state,
                jobs: [action.payload, ...(state.jobs || [])]
            }
        case REMOVE_JOB:
            return {
                ...state,
                jobs: [...(state.jobs || [])].filter(p => p.id !== action.payload.id)
            }
        case LOGOUT:
            return {
                ...initialState
            }
    }
    return state
}

export default jobsReducer