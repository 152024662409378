import React, {useEffect, useState} from 'react'
import {Box, CircularProgress, Typography} from "@material-ui/core"
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import UserModel, {UserAdminInfo} from "../../models/user.model";
import {
    getCurrentClubName,
    getFullName,
    getProfileSalaryLocalized, getProfileTransferFeeLocalized,
    getUserMainPositionLocalizedShort
} from "../../util/profile.util";
import {format} from "date-fns";
import {numberFormat} from "../common/number.util";
import {useApplicationsState} from "../../store/store.utils";
import UserService from "../../services/user.service";
import Job from "../../models/job.model";
import {getOpportunityClubName} from "../../util/opportunity.util";
import PersonIcon from '@material-ui/icons/Person';

const PrintJobs = () => {

    //region State

    const history = useHistory()
    const {t} = useTranslation()
    const [players, setPlayers] = useState<{ user?: UserModel, admin?: UserAdminInfo, job?: Job }[]>([])
    const [downloading, setDownloading] = useState(false)
    const {selectedForExport} = useApplicationsState()
    console.log(selectedForExport.values())

    // const [applicationStatuses] = useUrlParam<ApplicationStatus[]>({
    //     param: 'applicationStatus',
    //     dataType: 'complex',
    //     defaultValue: []
    // })
    //
    // const [jobIds] = useUrlParam<number[]>({
    //     param: 'jobId',
    //     dataType: 'complex',
    //     defaultValue: []
    // })

    // const fetchJobs = async () => {
    //     setDownloading(true)
    //
    //     let playersTmp: Set<UserModel> = new Set<UserModel>()
    //
    //     for (let i = 0; i < jobIds.length; i++) {
    //         let applicantsPage = 0
    //         let download = true
    //
    //         if (applicationStatuses.find(s => s === ApplicationStatus.INVITED)) {
    //             applicantsPage = 0
    //             download = true
    //             while (download) {
    //                 const response = await ApplicationsService.fetchInvited(jobIds[i], applicantsPage, 20)
    //                 response?.content?.forEach(application => {
    //                     playersTmp.add(application.createdBy!)
    //                 })
    //                 if (!response?.last) {
    //                     applicantsPage++
    //                 } else {
    //                     download = false
    //                 }
    //             }
    //         }
    //
    //         if (applicationStatuses.find(s => s === ApplicationStatus.CREATED)) {
    //             applicantsPage = 0
    //             download = true
    //             while (download) {
    //                 const response = await ApplicationsService.fetchOther(jobIds[i], applicantsPage, 20)
    //                 response?.content?.forEach(application => {
    //                     playersTmp.add(application.createdBy!)
    //                 })
    //                 if (!response?.last) {
    //                     applicantsPage++
    //                 } else {
    //                     download = false
    //                 }
    //             }
    //         }
    //
    //         if (applicationStatuses.find(s => s === ApplicationStatus.SHORTLISTED)) {
    //             applicantsPage = 0
    //             download = true
    //             while (download) {
    //                 const response = await ApplicationsService.fetchTopPicks(jobIds[i], applicantsPage, 20)
    //                 response?.content?.forEach(application => {
    //                     playersTmp.add(application.createdBy!)
    //                 })
    //                 if (!response?.last) {
    //                     applicantsPage++
    //                 } else {
    //                     download = false
    //                 }
    //             }
    //         }
    //
    //         if (applicationStatuses.find(s => s === ApplicationStatus.REJECTED)) {
    //             applicantsPage = 0
    //             download = true
    //             while (download) {
    //                 const response = await ApplicationsService.fetchRejected(jobIds[i], applicantsPage, 20)
    //                 response?.content?.forEach(application => {
    //                     playersTmp.add(application.createdBy!)
    //                 })
    //                 if (!response?.last) {
    //                     applicantsPage++
    //                 } else {
    //                     download = false
    //                 }
    //             }
    //         }
    //     }
    //
    //     setPlayers(Array.from(playersTmp.values()) || [])
    //     setDownloading(false)
    // }

    // useEffect(() => {
    //     fetchJobs()
    // }, [])

    const fetchPlayers = async () => {
        setDownloading(true)

        const response = await Promise.all(selectedForExport.values().map(async application => {
            const user = await UserService.getUser(application.createdBy?.id!, false)
            const admin = await UserService.getUserAdminInfo(application.createdBy?.id!)

            return {
                user: user,
                admin: admin,
                job: application.opportunity
            }
        }))

        setPlayers(response || [])


        setDownloading(false)
    }

    useEffect(() => {
        fetchPlayers()
    }, [])

    //endregion

    //region UI

    if (downloading) {
        return (
            <Box>
                <CircularProgress/>
            </Box>
        )
    }

    const renderTmLink = (user?: UserModel) => {
        const tmLink = user?.profile?.externalUrls?.find(url => url.includes('transfermarkt'))
        if (tmLink) {
            return (
                <a
                    style={{color: '#fff'}}
                    href={tmLink}
                >
                    <Typography variant={'caption'} style={{color: '#fff'}}>
                        {tmLink}
                    </Typography>
                </a>
            )
        } else {
            return (
                <Typography variant={'caption'} style={{color: '#fff'}}>
                    -
                </Typography>
            )
        }


    }

    const logo = (
        <img
            src={require('../../assets/images/print/top-logo.png').default}
            style={{
                width: 130,
                height: 130,
                marginBottom: 20,
                objectFit: 'contain'
            }}
        />

    )

    const header = (
        <Box
            style={{
                width: 723,
                height: 48,
                position: 'relative',
                marginBottom: 30
            }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <img
                src={require('../../assets/images/print/title-strip.png').default}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    position: 'absolute',
                    left: 0,
                    top: 0
                }}
            />

            <Typography
                style={{
                    fontSize: 24,
                    color: '#fff',
                    fontWeight: 'bold',
                    zIndex: 100
                }}
            >
                TOP PICKS TRANSFER REPORT
            </Typography>

        </Box>
    )

    const playerBasicInfo = (user?: UserModel, job?: Job) => (
        <Box
            display={'flex'}
            alignItems={'center'}
            style={{
                background: '#1E1E1E',
                width: 723,
                padding: '20px',
                boxSizing: 'border-box'
            }}
        >
            <Box
                display={'flex'}
                flex={1}
                flexDirection={'column'}
                justifyContent={'space-between'}
                color={'#fff'}
                minHeight={240}
            >
                <Box>
                    <Typography style={{color: '#fff'}}>Date of
                        report: {format(new Date(), 'dd.MM.yyyy')}</Typography>
                    <Typography style={{color: '#fff'}}>Job
                        owner: {job?.club?.name || job?.clubRequest?.name || '-'}</Typography>
                </Box>

                <Box>
                    <Typography style={{color: '#fff'}}>Club
                        name: {getCurrentClubName(user, '-')}</Typography>
                    <Typography
                        style={{color: '#fff'}}>Position: {getUserMainPositionLocalizedShort(t, user) || '-'}</Typography>
                </Box>

                <Box>
                    <Typography style={{color: '#fff'}}>Player name: {getFullName(user)}</Typography>
                    <Typography style={{color: '#fff'}}>TM link:</Typography>
                    {renderTmLink(user)}
                </Box>

            </Box>

            <Box
                style={{
                    width: 176,
                    height: 200,
                    padding: 9,
                    background: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {
                    user?.profile?.profilePicture ?
                        <img
                            src={user?.profile?.profilePicture}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                        :
                        <PersonIcon style={{fontSize: 164, color: '#ccc'}}/>
                }

            </Box>

        </Box>
    )

    const agencyInfo = (user?: UserModel) => (
        <Box
            display={'flex'}
            alignItems={'center'}
            style={{
                border: '1px solid #46D989',
                width: 723,
                minHeight: 100,
                padding: '20px',
                boxSizing: 'border-box',
                marginTop: 30,
                marginBottom: 30
            }}
        >
            <Box>
                <Typography>
                    TM Value:
                    {user?.profile?.transfermarktValue ?
                        ' USD ' + numberFormat(user?.profile?.transfermarktValue || 0, 0, ",", ".") : ' -'}
                </Typography>

                <Typography>
                    Salary range: {getProfileSalaryLocalized(user?.profile, '-')}
                </Typography>

                <Typography>
                    Transfer fee: {getProfileTransferFeeLocalized(user?.profile, '-')}
                </Typography>

                <Typography>
                    Agent
                    name: {[user?.parent?.userProfile?.firstName, user?.parent?.userProfile?.lastName].filter(x => !!x).join(' ')}
                </Typography>

                <Typography>
                    Agency name: {user?.parent?.userProfile?.agencyName}
                </Typography>

            </Box>
        </Box>
    )

    const internalNotes = (admin?: UserAdminInfo) => (
        <Box
            display={'flex'}
            flexDirection={'column'}
            style={{
                border: '1px solid #46D989',
                width: 723,
                padding: '20px',
                boxSizing: 'border-box',
            }}

        >
            <Typography>
                Internal notes:
            </Typography>

            <Typography style={{whiteSpace: 'pre-wrap', fontSize: '12px', lineHeight: '14px'}}>
                {admin?.adminNote}
            </Typography>
        </Box>
    )


    return (
        <Box
            // display='flex'
            // flexDirection={'column'}
        >
            {players.map(info => {
                const user = info.user
                const admin = info.admin
                const job = info.job

                return (
                    <Box
                        // display={'flex'}
                        // flexDirection={'column'}
                        style={{
                            width: '210mm',
                            // height: '297mm',
                            minHeight: '297mm',
                            padding: '40px',
                            boxSizing: 'border-box'
                            // backgroundColor: 'red'
                        }}
                        className={'pagebreak'}
                    >
                        {logo}
                        {header}
                        {playerBasicInfo(user, job)}
                        {agencyInfo(user)}
                        {internalNotes(admin)}
                    </Box>


                )
            })}
        </Box>
    )

    //endregion UI
}

export default PrintJobs
