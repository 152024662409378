const TermsOfService = () => {
    return (
        <div style={{marginTop: '100px', padding: '20px'}}>
            <h3>TERMS OF USE</h3>
            <p>Your use of Playerhunter’s software, services and web site (referred to collectively as the “Services” in
                this document) is subject to the terms of a legal agreement between you and Playerhunter. In order to
                use
                the Services, you must first read and agree to the Terms of Use. You agree that by clicking “Join Now”,
                “Sign Up” or similar, registering, accessing or using our Services, you are agreeing to enter into a
                legally
                binding contract with Playhunter. If you do not agree to this Terms of Use, do not click “Join Now” (or
                similar) and do not access or otherwise use any of Playhunter’s Services.</p>
            <p>This agreement is subject to changes by Playerhunter.com at any time. If Playhunter makes material
                changes
                to it, Playhunter will provide you notice through the Services, or by other means, to provide you the
                opportunity to review the changes before they become effective. If you object to any changes, you may
                close
                your account. Your continued use of Playhunter’s Services after Playhunter publishes or sends a notice
                about
                the changes to these terms means that you are consenting to the updated Terms of use.
                Before continuing, we recommend you to print off or save a local copy of the Terms of Use for your
                record.</p>
            <h3>GENERAL</h3>
            <p>Customer data is for internal use only and will be treated confidential. Information about the method and
                scope of the utilisation of your personal data can be found in the Privacy Policy.
                All transactions are SSL encrypted.
                All questions will be answered within five working days.
                Should all or individual provisions of these Terms of Use be or become ineffective or invalid in whole
                or in
                part, the effectiveness of the remaining provisions of these Terms of Use shall not be affected.</p>
            <h3>THE SERVICES OFFERED BY PLAYERHUNTER</h3>
            <p>Playerhunter is constantly upgrading and you acknowledge and agree that the form and nature of the
                Services
                may change now and then, without informing you.
                You acknowledge and agree that Playerhunter may stop (permanently or temporarily) with the Services to
                you
                and all the users generally at Playerhunter’s sole discretion, without prior notice to you. You may stop
                using the Services at any time and you do not need to inform Playerhunter on stopping using its
                Services.</p>
            <p>You agree that Playhunter has no obligation to store, maintain or provide you a copy of any content or
                information that you or others provide, except to the extent required by applicable law and as noted in
                our
                Privacy Policy. You acknowledge and agree that if Playerhunter disables access to your account, you may
                be
                prevented from accessing all account details and your account content.</p>
            <h3>USE OF PLAYERHUNTER’S SERVICES</h3>
            <p>In order to use the Services, you may be required to enter some personal data (such as identification or
                contact details) as part of the registration or in order to access the Services. You acknowledge and
                agree
                that all the personal data you give to Playerhunter will always be accurate and correct. You agree that
                Playhunter will provide notices to you in the following ways: (1) a notice within the Service, or (2) a
                message sent to the contact information you provided to Playhunter (e.g., email, mobile number, physical
                address).</p>
            <p>Playerhunter provides subscribers with the possibility to interact and promote themselves via multimedia
                material and by connecting with each other. Playerhunter offers its members a chance to present their
                skills
                the best way possible, as long as this complies with the applicable statutory regulations and terms of
                use.
                In case of a breach of such provisions, Playerhunter has the right to immediately remove the infringing
                content.</p>
            <p>Subscribers can run the search engine database, look for interesting managers and players.
                Playerhunter is solely the service provider and has no influence on negotiations or contracts between
                scouts, managers and players. Playerhunter has no responsibility for false information or any other
                breach
                of the Terms of use. Playerhunter does not guarantee the correctness of player or manager/agent
                profiles.
                Further, Playerhunter cannot be held responsible for any third person influences on negotiations.
                You acknowledge and agree on using the Services only for purposes that are permitted by the Terms of
                Use,
                the law, regulation or generally accepted practices in the relevant jurisdictions.
                You agree that you will not be part of any activity that interferes with or disrupts Playerhunter.
                Playerhunter reserves the right to pre-screen, review, flag, filter, modify, refuse or remove any or all
                the
                content on the website.</p>
            <p>Unless you have signed a contract with Playerhunter, you don’t have the right to use any of
                Playerhunter’s
                trade names, trade marks, logos, service marks, domain namesbrand features or other copyrghtable works.
                Playhunter reserves all of its intellectual property rights in the Services. Using the Services does not
                give you any ownership in Playhunter’s Services or the content or information made available through
                Playhunter’s Services.</p>
            <h3>PASSWORDS AND ACCOUNT SECURITY</h3>
            <p>Upon registration, you create a username and a password from Playerhunter.com in order to access the
                site,
                its materials and obtain the benefits of the subscription.</p>
            <p>You agree to: (1) try to choose a strong and secure password; (2) keep your password secure and
                confidential; (3) not transfer any part of your account (e.g., connections) and (4) follow the law. You
                are
                responsible for anything that happens through your account unless you close it or report misuse.If any
                unauthorized use of your password and your account happens, you may notify Playerhunter immediately at:
                support@playerhunter.com and it reserves the right to assess the situation and react in an appropriate
                way.</p>
            <h3>ADVERTISEMENTS</h3>
            <p>Some of the Services are supported by advertising revenue and may display advertisements and promotions.
                These advertisements may be targeted to the content of information stored on the Services, queries made
                through the Services or other information.</p>
            <p>The manner, mode and extent of advertising by Playerhunter on the Services are subject to change without
                specific notice to you.</p>
            <p>In consideration for Playerhunter granting you access to and use of the Services, you agree that
                Playerhunter may place such advertising on the Services.</p>
            <p>The PH website may link to external websites and other external websites may link to the PH website. PH
                is
                not responsible for the content of any such external websites and disclaims any liability associated
                with
                them.</p>
            <h3>UPDATES</h3>
            <p>The Software may automatically download and install updates. From time to time, Playerhunter designs
                updates
                in order to improve and develop the Services even more, which may take the form of bug fixes, enhanced
                functions and completely new versions. You agree and permit to receive such updates during using the
                Services.</p>
            <h3>DISCLAIMER AND LIMITATION OF LIABILITY</h3>
            <p>TO THE EXTENT ALLOWED UNDER LAW PLAYHUNTER (A) DISCLAIMS ALL IMPLIED WARRANTIES AND REPRESENTATIONS (E.G.
                FITNESS FOR A PARTICULAR PURPOSE, ACCURACY OF DATA, AND NONINFRINGEMENT); (B) DOES NOT GUARANTEE THAT
                THE
                SERVICES WILL FUNCTION WITHOUT INTERRUPTION OR ERRORS, AND (C) PROVIDES THE SERVICE (INCLUDING CONTENT
                AND
                INFORMATION) ON AN “AS IS” AND “AS AVAILABLE” BASIS.</p>
            <p>Playhunter is not liable for any damages caused by minor negligence. To the extent permitted by law,
                Playhunter will furthermore only be liable for foreseeable damages typical for similar contracts.The
                aforementioned limitations of Playhunter’s liability do not apply for claims under the Product Liability
                Act
                as well as by injuries to life, body and health Termination.</p>
            <p>When you want to terminate the legal agreement with Playerhunter, you may do so by notifying Playerhunter
                at
                any time or by simply closing your account and stopping using all of the Playerhunter’s Services.
                Playerhunter may at any time, terminate its legal agreement with you.</p>
            <h3>GOVERNING LAW/JURISDICTION</h3>
            <p>You agree that the laws of Austria, excluding conflict of laws rules, shall exclusively govern any
                dispute
                relating to this Agreement and/or the Services. The exclusive venue shall be the Inner City of Vienna;
                in
                case of cross-border contracts, Playhunter reserves the right to address any other competent court. This
                does not apply for contracts with consumers from EU Member States; for those, the exclusive venue is the
                court competent for the domicile of the consumer.</p>

        </div>
    )
};

export default TermsOfService;
