import React from 'react'
import {Box, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import AnimatedBox from "./Animated.component";
import {DIMENSIONS, LANDING_COMMON_COLORS} from "./common";
import {useTranslation} from "react-i18next";

const useStyle = makeStyles(_theme => ({
    component: {
        marginTop: 200,
        width: '100%',
        [_theme.breakpoints.down('md')]: {
            marginTop: 100
        }
    },
    title: {
        fontSize: DIMENSIONS.LARGE.TITLE_SIZE,
        color: LANDING_COMMON_COLORS.TITLE,
        marginBottom: 45,
        textAlign: "center",
        paddingLeft: 25,
        paddingRight: 25,
        [_theme.breakpoints.down('md')]: {
            fontSize: DIMENSIONS.SMALL.TITLE_SIZE
        }
    },
    infoContainer: {
        width: '100%',
    },
    infoBox: {
        padding: DIMENSIONS.LARGE.INFO_BOX_PADDING,
        cursor: 'default',
        width: 350,
        // maxWidth: 400,
        [_theme.breakpoints.down('xl')]: {
            width: 'calc(20vw - 60px)',
        },
        [_theme.breakpoints.down('lg')]: {
            width: 'calc(33vw - 60px)',
        },
        [_theme.breakpoints.down('md')]: {
            padding: DIMENSIONS.SMALL.INFO_BOX_PADDING,
            width: 'calc(50vw - 60px)',
        },
        [_theme.breakpoints.down('sm')]: {
            width: 'calc(100vw - 60px)',
        },
    },
    infoBoxHovered: {
        boxShadow: '0px 10px 55px 5px rgba(65, 196, 126, 0.35)',
        borderRadius: 15,
        background: '#FFFFFF'
    },
    infoIcon: {
        width: 44,
        height: 44
    },
    infoTitle: {
        marginTop: 16,
        marginBottom: 6,
        fontSize: 20,
        lineHeight: DIMENSIONS.LARGE.INFO_TITLE_LINE_HEIGHT,
        textAlign: 'center',
        color: LANDING_COMMON_COLORS.TITLE,
        [_theme.breakpoints.down('md')]: {
            fontSize: 18,
            lineHeight: DIMENSIONS.SMALL.INFO_TITLE_LINE_HEIGHT,
        }
    },
    infoMessage: {
        fontWeight: 300,
        fontSize: DIMENSIONS.LARGE.INFO_MESSAGE_SIZE,
        lineHeight: DIMENSIONS.LARGE.INFO_MESSAGE_LINE_HEIGHT,
        textAlign: 'center',
        color: LANDING_COMMON_COLORS.BOX_TEXT,
        whiteSpace: 'pre-wrap',
        [_theme.breakpoints.down('md')]: {
            fontSize: DIMENSIONS.SMALL.INFO_MESSAGE_SIZE,
            lineHeight: DIMENSIONS.SMALL.INFO_MESSAGE_LINE_HEIGHT,
        },
        '& b': {
            fontWeight: 500
        }
    }
}))

const CoreValue = () => {

    const classes = useStyle()
    const t = useTranslation().t

    const renderBox = (icon: any, title: string, message: string, highlight: boolean) => (
        <AnimatedBox
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={clsx(classes.infoBox, highlight && classes.infoBoxHovered)}

            hover
            fadeIn
            fadeInThreshold={30}
        >
            <img
                className={classes.infoIcon}
                src={icon}
            />
            <div
                className={classes.infoTitle}
                style={highlight && {fontWeight: 'bold'} || undefined}
            >
                {title}
            </div>
            <div
                className={classes.infoMessage}
                dangerouslySetInnerHTML={{__html: message}}
            >
                {/*{message}*/}
            </div>
        </AnimatedBox>
    )

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={classes.component}
        >
            <div className={classes.title}>
                {t("What is our core value for clubs & agents (players)?")}
            </div>

            <Grid
                justify={'center'}
                container spacing={4}
                className={classes.infoContainer}
            >

                <Grid item>
                    {renderBox(
                        require('../../assets/images/landing/value-V.svg').default,
                        t('Publish Multiple Jobs'),
                        t('based on your clubs’ player requirements and needs.'),
                        false
                    )}
                </Grid>


                <Grid item>
                    {renderBox(
                        require('../../assets/images/landing/value-A.svg').default,
                        t('Contact Interested Players '),
                        t('Get direct contact from relevant top players who expressed interest to join your club - matched with your criteria.'),
                        false
                    )}
                </Grid>

                <Grid item>
                    {renderBox(
                        require('../../assets/images/landing/value-L.svg').default,
                        t('Skip the first step'),
                        t('Instead of performing massive player research you instantly get a list of shortlisted relevant candidates, willing to join your club based on your specified criteria'),
                        true
                    )}
                </Grid>

                <Grid item>
                    {renderBox(
                        require('../../assets/images/landing/value-U.svg').default,
                        t('Straight to analysis'),
                        t('Go straight to the second step, analyzing ONLY relevant players who MATCH your specific needs.'),
                        true
                    )}
                </Grid>

                <Grid item>
                    {renderBox(
                        require('../../assets/images/landing/value-E.svg').default,
                        t('Top Candidates'),
                        t('Communicate directly with the TOP candidates - and negotiate terms & conditions.'),
                        false
                    )}
                </Grid>

            </Grid>

        </Box>
    )
}

export default CoreValue
