import apollo from "./api/apollo";
import {
    GET_APPLICANT_RANKING_WEIGHTS,
    GET_OPPORTUNITY_FEED_WEIGHTS,
    GET_POSITION_MATRIX_FOR_APPLICANTS,
    GET_POSITION_MATRIX_FOR_OPPORTUNITY_FEED,
    GET_PROFILE_COMPLETION_WEIGHTS,
    UPDATE_APPLICANTS_RANKING_WEIGHTS,
    UPDATE_OPPORTUNITY_FEED_WEIGHTS,
    UPDATE_POSITION_MATRIX_FOR_APPLICANTS,
    UPDATE_POSITION_MATRIX_FOR_OPPORTUNITY_FEED,
    UPDATE_PROFILE_COMPLETION_WEIGHTS
} from "./api/algorithmsQueries";
import PositionMapModel from "../models/positionMap.model";

export default class AlgorithmService {

    static getOpportunityFeedWeights = async () => {
        let response = await apollo.query({
            query: GET_OPPORTUNITY_FEED_WEIGHTS
        });
        return response.data.feedWeights;
    };

    static updateOpportunityFeedWeights = async (weights: any) => {
        let response = await apollo.mutate({
            mutation: UPDATE_OPPORTUNITY_FEED_WEIGHTS,
            variables: {
                weights: weights
            }
        })
        return response.data.feedWeights;
    };

    static getProfileCompletionWeights = async () => {
        let response = await apollo.query({
            query: GET_PROFILE_COMPLETION_WEIGHTS,
        });
        return response.data.completionWeights;
    };

    static updateProfileCompletionWeights = async (weights: any) => {
        let response = await apollo.mutate({
            mutation: UPDATE_PROFILE_COMPLETION_WEIGHTS,
            variables: {
                weights: weights
            }
        });
        return response.data.completionWeights;
    };

    static getApplicantsRankingWeights = async () => {
        let response = await apollo.query({
            query: GET_APPLICANT_RANKING_WEIGHTS,
            variables: {}
        });
        return response.data.applicantsWeights;
    };

    static updateApplicantsRankingWeights = async (weights: any) => {
        let response = await apollo.mutate({
            mutation: UPDATE_APPLICANTS_RANKING_WEIGHTS,
            variables: {
                weights: weights
            }
        });
        return response.data.applicantsWeights;
    };

    static getPositionMatrixForOpportunityFeed = async (): Promise<PositionMapModel[]> => {
        let response = await apollo.query({
            query: GET_POSITION_MATRIX_FOR_OPPORTUNITY_FEED
        });
        return response.data.positionMaps;
    };

    static updatePositionMatrixForOpportunityFeed = async (positionMap: PositionMapModel) => {
        let response = await apollo.mutate({
            mutation: UPDATE_POSITION_MATRIX_FOR_OPPORTUNITY_FEED,
            variables: {
                positionMap
            }
        });
        return response.data.positionMap;
    };

    static getPositionMatrixApplicants = async (): Promise<PositionMapModel[]> => {
        let response = await apollo.query({
            query: GET_POSITION_MATRIX_FOR_APPLICANTS
        });
        return response.data.positionMapsApplicant;
    };

    static updatePositionMatrixApplicant = async (positionMapApplicant: PositionMapModel) => {
        let response = await apollo.mutate({
            mutation: UPDATE_POSITION_MATRIX_FOR_APPLICANTS,
            variables: {
                positionMapApplicant
            }
        });
        return response.data.positionMapApplicant;
    };
}
