import {removeMultipleParams, useUrlParam} from "../../util/data/url.util";
import CountryModel from "../../models/country.model";
import {useHistory} from "react-router";
import {useEffect, useState} from "react";
import ClubModel from "../../models/club.model";
import UserModel from "../../models/user.model";

export const useJobFilters = () => {
    const filters: any = {}
    const history = useHistory()

    // Level
    const [fromLevel] = useUrlParam({param: 'fromLevel', dataType: 'numeric', defaultValue: 0})
    const [toLevel] = useUrlParam({param: 'toLevel', dataType: 'numeric', defaultValue: 0})
    if (fromLevel && toLevel) {
        filters.fromLevel = fromLevel
        filters.toLevel = toLevel
    }

    // Age
    const [fromAge] = useUrlParam({param: 'fromAge', dataType: 'numeric', defaultValue: 0})
    const [toAge] = useUrlParam({param: 'toAge', dataType: 'numeric', defaultValue: 0})
    if (fromAge && toAge) {
        filters.fromAge = fromAge
        filters.toAge = toAge
    }

    // Country
    const [countries] = useUrlParam<CountryModel[]>({
        param: 'countries',
        dataType: 'complex',
        defaultValue: []
    })
    if (countries.length) {
        filters.countries = countries.map(c => c.id)
    }

    // Gender
    const [gender] = useUrlParam({param: 'gender', dataType: 'string', defaultValue: ''})
    gender && (filters.gender = gender)

    // Positions
    const [positions] = useUrlParam<string[]>({
        param: 'positions',
        dataType: 'complex',
        defaultValue: []
    })
    if (positions.length) {
        filters.positions = positions
    }

    // Job status
    const [jobStatus] = useUrlParam({param: 'jobStatus', dataType: 'string', defaultValue: ''})
    jobStatus && (filters.status = jobStatus)

    // Applicants
    const [fromApplicantsCount] = useUrlParam({param: 'fromApplicantsCount', dataType: 'numeric', defaultValue: 0})
    const [toApplicantsCount] = useUrlParam({param: 'toApplicantsCount', dataType: 'numeric', defaultValue: 0})
    if (fromApplicantsCount && toApplicantsCount) {
        filters.fromApplicantsCount = fromApplicantsCount
        filters.toApplicantsCount = toApplicantsCount
    }

    // Job location type
    const [jobLocationType] = useUrlParam({param: 'jobLocationType', dataType: 'string', defaultValue: ''})
    if (jobLocationType === "INTERNATIONAL") {
        filters.internationalOnly = true
    } else if (jobLocationType === "LOCAL") {
        filters.internationalOnly = false
    }

    // Club
    const [club] = useUrlParam<ClubModel | null>({param: 'club', dataType: 'complex', defaultValue: null})
    if (club) {
        filters.club = club.id
    }

    // Club
    const [agent] = useUrlParam<UserModel | null>({param: 'agent', dataType: 'complex', defaultValue: null})
    if (agent) {
        filters.agent = agent.id
    }

    // Salary
    const [fromSalary] = useUrlParam({param: 'fromSalary', dataType: 'numeric', defaultValue: 0})
    const [toSalary] = useUrlParam({param: 'toSalary', dataType: 'numeric', defaultValue: 0})
    if (fromSalary && toSalary) {
        filters.fromSalary = fromSalary
        filters.toSalary = toSalary
    }

    // Transfer fee
    const [fromTransferFee] = useUrlParam({param: 'fromTransferFee', dataType: 'numeric', defaultValue: 0})
    const [toTransferFee] = useUrlParam({param: 'toTransferFee', dataType: 'numeric', defaultValue: 0})
    if (fromTransferFee && toTransferFee) {
        filters.fromTransferFee = fromTransferFee
        filters.toTransferFee = toTransferFee
    }

    const [latestFilters, setLatestFilters] = useState(filters)

    useEffect(() => {
        setLatestFilters(filters)
    }, [fromAge, toAge, fromLevel, toLevel, countries, gender, positions, jobStatus,
        fromApplicantsCount, toApplicantsCount, jobLocationType, club, agent, fromSalary, toSalary, fromTransferFee, toTransferFee])

    const handleClearAllFilters = () => {
        removeMultipleParams(['fromLevel', 'toLevel', 'fromAge', 'toAge', 'countries', 'gender', 'positions',
            'jobStatus', 'fromApplicantsCount', 'toApplicantsCount', 'jobLocationType', 'club', 'agent', 'fromSalary', 'toSalary',
            'fromTransferFee', 'toTransferFee'
        ], history)
    }

    return [latestFilters, handleClearAllFilters]
}
