import {Box, Button, CircularProgress, makeStyles, Tab, Tabs} from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import {useUrlParam} from "./url.util";
import {useTranslation} from "react-i18next";
import EButton from "../../components/common/EButton";

const useStyle = makeStyles({
    tabsContainer: {
        flex: 1
    },
    secondaryTabsContainer: {
        paddingTop: 0,
        marginTop: -10
    }
})

interface Props {
    tabs: { icon?: any, label: string }[],
    showButtonLoaders?: boolean,
    showSave?: boolean,
    canSave?: boolean,
    showDelete?: boolean,
    canDelete?: boolean,
    onSave?: () => void,
    onDelete?: () => void,
    priority?: 'primary' | 'secondary'
    classes?: {
        container?: any
    }
}

export const useTabs = (props: Props) => {

    const classes = useStyle(props)
    const [tab, setTab] = useUrlParam({param: 'tab', dataType: 'numeric', defaultValue: 0})
    const {t} = useTranslation()

    const renderTabs = () => (
        <Box
            p={3}
            display={'flex'}
            className={clsx(classes.tabsContainer, props.priority === 'secondary' && classes.secondaryTabsContainer, props.classes?.container)}
            justifyContent={'space-between'}
        >
            <Tabs
                textColor={props.priority || 'primary'}
                indicatorColor={props.priority || 'primary'}
                value={tab}
                onChange={(_, newTab) => {
                    setTab(newTab)
                }}
            >
                {
                    props.tabs.map((tab, index) => (
                        <Tab icon={tab.icon} label={tab.label} tabIndex={index}/>
                    ))
                }
            </Tabs>

            {
                props.showButtonLoaders && <CircularProgress/>
            }

            {
                !props.showButtonLoaders &&
                <Box
                    display={'flex'}
                >

                    {
                        props.showSave &&
                        <Button
                            color={'primary'}
                            variant={'contained'}
                            onClick={props.onSave}
                            disabled={!props.canSave}
                        >
                            {t("Save")}
                        </Button>
                    }

                    {
                        props.showDelete &&
                        <EButton
                            color={'error'}
                            variant={'contained'}
                            onClick={props.onDelete}
                            disabled={!props.canDelete}
                        >
                            {t("Delete")}
                        </EButton>
                    }

                </Box>
            }

        </Box>
    )

    return {
        tab,
        // setTab,
        renderTabs
    }
}
