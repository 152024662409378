import React, {useState} from "react";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import UserModel from "../../models/user.model";
import {useTranslation} from "react-i18next";
import EButton from "../common/EButton";
import {useAlgobugState} from "../../store/store.utils";
import {SET_ALGOBUG_NOTIFICATIONS_JOB} from "../../store/actions/actionTypes";
import {useDispatch} from "react-redux";
import JobAnalyzer from "./JobAnalyzer.component";
import OpportunityService from "../../services/opportunity.service";
import {
    getCityCountryName,
    getFullName,
    getGender,
    getPreferredFoot,
    getUserAge,
    getUserMainPositionLocalizedShort,
    getUserSecondaryPositionsLocalizedShort
} from "../../util/profile.util";


const useStyles = makeStyles(theme => ({
    header: {
        width: '100%',
        padding: '10px',
        borderBottom: '1px solid #f6f6f6',
        fontSize: '12px',
    },
    logo: {
        width: '30px',
        minWidth: '30px',
        height: '30px',
        marginRight: '15px',
        objectFit: 'contain'
    },
    standardCol: {
        width: '120px',
        minWidth: '120px !important',
        cursor: 'pointer',
        fontSize: '12px',
    },
    name: {
        // flexGrow: '1 !important',
        minWidth: '150px !important',
        cursor: 'pointer',
    },
    rowOpportunityDetails: {
        width: '100%',
        minHeight: '100px',
        padding: '30px 10px'
    },
    userInfoBoxGreen: {
        boxShadow: '0px 0px 3px 2px rgba(38, 217, 24, 0.8)'
    },
    userInfoBoxRed: {
        boxShadow: '0px 0px 3px 2px rgba(191, 0, 16, 0.8)'
    },
    actionButton: {
        minWidth: 'fit-content',
        marginRight: '20px !important'
    },
    label: {
        marginRight: '10px',
    },
    option: {
        color: 'white',
        height: '100%',
        paddingLeft: '10px',
        paddingRight: '10px',
        minWidth: '100px !important',
        borderRadius: '10px',
        background: theme.palette.secondary.main,
        cursor: 'pointer'
    },
    selected: {
        background: theme.palette.primary.main,
        color: 'white'
    },
    options: {
        height: '35px',
        marginLeft: '10px',
    },
    optionsFirst: {
        marginLeft: 'auto'
    },
    row: {
        width: '100%'
    },
    rowOpportunityInfo: {
        width: '100%',
        padding: '10px',
        borderBottom: '1px solid #f6f6f6',
        cursor: 'pointer',
        fontSize: '14px',
        // hover: {
        //     background: '#fefefe'
        // }
    },
    opportunityStatus: {
        alignSelf: 'center',
        justifySelf: 'center',
        marginTop: '15px !important',
        marginBottom: '20px !important',
    },
    loadMore: {
        marginTop: '15px !important',
        marginBottom: '20px !important',
        maxWidth: 'fit-content !important',
        alignSelf: 'center'
    },
    table: {
        marginTop: '25px',
        background: 'white',
        borderRadius: '4px',
        width: '100%',
        // overflowX: 'auto',
        minHeight: '200px !important',
    }

}))

const JobNotificationChecker = () => {

    // region state
    const classes = useStyles()
    const {t} = useTranslation()


    const [status, setStatus] = useState('');
    const [users, setUsers] = useState<UserModel[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [canLoadMore, setCanLoadMore] = useState(false);

    const {algobugNotificationsJob} = useAlgobugState()
    const dispatch = useDispatch()

    // endregion state


    // region handlers

    const getNotifiableUsers = async (loadMore: boolean) => {
        setStatus('Downloading results... Please wait, this may take a while...');

        if (!loadMore) setUsers([]);

        const page = loadMore ? currentPage + 1 : 0;

        let newUsers = await OpportunityService.fetchNotifiableUsers(
            algobugNotificationsJob?.id!,
            page,
            20
        )
        setCurrentPage(page);
        setCanLoadMore((newUsers?.content?.length || 0) >= 20);

        if (loadMore) {
            setUsers([...users, ...(newUsers?.content || [])]);
        } else {
            setUsers(newUsers?.content || []);
        }

        setStatus('');
    };

    const handleRowClick = (user: UserModel) => {
        window.open(`${process.env.REACT_APP_WEB_URL}/admin/user/${user.id}`, '_blank')
    };

    //endregion handlers


    // region UI

    const renderHeader = () => {
        return (
            <Box
                className={classes.header}
                display={'flex'}
                alignItems={'center'}
            >
                <div className={classes.standardCol}>Id</div>
                <div className={classes.name}>Name</div>
                <div className={classes.standardCol}>Positions</div>
                <div className={classes.standardCol}>Age</div>
                <div className={classes.standardCol}>Level</div>
                <div className={classes.standardCol}>Gender</div>
                <div className={classes.standardCol}>Foot</div>
                <div className={classes.standardCol}>Verified</div>
                <div className={classes.standardCol}>Location</div>
            </Box>
        )
    };

    const renderUserBasicInfo = (user: UserModel) => {
        const position = [getUserMainPositionLocalizedShort(t, user)]
        if (getUserSecondaryPositionsLocalizedShort(t, user)?.length) {
            position.push(`(${getUserSecondaryPositionsLocalizedShort(t, user)})`)
        }

        return (
            <Box
                className={classes.rowOpportunityInfo}
                display={'flex'}
                alignItems={'center'}
            >
                <div className={classes.standardCol}>{user?.id}</div>
                <div className={classes.name}>{getFullName(user) || '-'}</div>
                <div className={classes.standardCol}>{position.join(' ')}</div>
                <div className={classes.standardCol}>{getUserAge(user, '-')}</div>
                <div className={classes.standardCol}>{user?.profile?.leagueLevel}</div>
                <div className={classes.standardCol}>{getGender(user, '-')}</div>
                <div className={classes.standardCol}>{getPreferredFoot(user, '-')}</div>
                <div className={classes.standardCol}>{user.profile?.verified}</div>
                <div className={classes.standardCol}>{getCityCountryName(user, '-')}</div>
            </Box>
        );
    };


    const renderUsers = () => {

        return users.map(user => {
            return (
                <Box
                    className={classes.row}
                    display={'flex'}
                    key={user?.id}
                    onClick={() => handleRowClick(user)}
                    flexDirection={'column'}
                    onMouseDown={e => {
                        if (e.button === 1) {
                            handleRowClick(user)
                        }
                    }}
                >
                    {renderUserBasicInfo(user)}
                </Box>
            )
        });
    };

    return (

        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            p={3}
        >

            <JobAnalyzer job={algobugNotificationsJob} onJobLoaded={job => {
                dispatch({
                    type: SET_ALGOBUG_NOTIFICATIONS_JOB,
                    payload: job
                })
            }}/>

            {algobugNotificationsJob && !status &&
            <Box
                width={'100%'}
                marginTop={'25px'}
                display={'flex'}
                alignItems={'center'}
            >

                <EButton variant="contained"
                         className={classes.actionButton}
                         onClick={() => getNotifiableUsers(false)}>
                    Analyze
                </EButton>

                {
                    // opportunityPool === 'bucket' &&
                    // <EButton variant="contained" color="primary"
                    //           className={classes.actionButton}
                    //           onClick={() => setBucketVisible(true)}>
                    //     View bucket ({props.opportunitiesBucket.length})
                    // </EButton>
                }

                {/*<Box*/}
                {/*    marginLeft={'auto'}*/}
                {/*    display={'flex'} alignItems={'center'}*/}
                {/*    className={clsx(classes.options, classes.optionsFirst)}>*/}
                {/*    <div className={classes.label}>Opportunities:</div>*/}
                {/*    <Box className={clsx(classes.option, opportunityPool === 'all' && classes.selected)}*/}
                {/*         display={'flex'} alignItems={'center'} justifyContent={'center'}*/}
                {/*         onClick={() => setOpportunityPool('all')}*/}
                {/*    >*/}
                {/*        <div>All</div>*/}
                {/*    </Box>*/}
                {/*    <Box className={clsx(classes.option, opportunityPool === 'bucket' && classes.selected)}*/}
                {/*         display={'flex'} alignItems={'center'} justifyContent={'center'}*/}
                {/*         onClick={() => setOpportunityPool('bucket')}*/}
                {/*    >*/}
                {/*        <div>Bucket</div>*/}
                {/*    </Box>*/}
                {/*</Box>*/}

                {/*<Box display={'flex'} alignItems={'center'} className={clsx(classes.options)}>*/}
                {/*    <div className={classes.label}>Show disqualified:</div>*/}
                {/*    <Box className={clsx(classes.option, showDisqualified && classes.selected)}*/}
                {/*         display={'flex'} alignItems={'center'} justifyContent={'center'}*/}
                {/*         onClick={() => setShowDisqualified(true)}*/}
                {/*    >*/}
                {/*        <div>Yes</div>*/}
                {/*    </Box>*/}
                {/*    <Box className={clsx(classes.option, showDisqualified === false && classes.selected)}*/}
                {/*         display={'flex'} alignItems={'center'} justifyContent={'center'}*/}
                {/*         onClick={() => setShowDisqualified(false)}*/}
                {/*    >*/}
                {/*        <div>No</div>*/}
                {/*    </Box>*/}
                {/*</Box>*/}

            </Box>
            }


            <Box
                className={classes.table}

                display={'flex'}
                flexDirection={'column'}
                alignSelf={'flex-start'}
            >
                {renderHeader()}
                {renderUsers()}

                {status && <div className={classes.opportunityStatus}>{status}</div>}

                {
                    canLoadMore && !status &&
                    <EButton variant="contained" color="primary"
                             className={classes.loadMore}
                             onClick={() => getNotifiableUsers(true)}>
                        Load More
                    </EButton>
                }

            </Box>

        </Box>
    );

    // endregion UI
}

export default JobNotificationChecker;
