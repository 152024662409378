import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import {useDispatch, useStore} from "react-redux"
import {useTranslation} from "react-i18next"
import {ScreenName} from "../../services/analytics/screenName"
import MixpanelService from "../../services/analytics/mixpanel.service"
import {Box, Button, Card, CircularProgress, Grid, TextField, Typography} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles";
import CommonService from "../../services/common.service";
import {AuthCommon, useAuthCommonStyles} from "./auth.common.";
import {useHistory} from "react-router";
import {emptyPhoneNumberValidator, emptyValidator, isFormValid} from "../../util/validators";
import CountryModel from "../../models/country.model";
import {validateForm} from "../../util/form.util";
import {setRegistrationBasicInfo} from "../../store/actions/authActions";
import PhoneNumberInput from "../common/PhoneNumberInput/PhoneNumberInput.component";
import {AgencyPosition} from "../../models/user.model";
import {useAuthState} from "../../store/store.utils";
import {SET_AUTH_AGENCY_POSITION} from "../../store/actions/actionTypes";

const useStyle = makeStyles(_theme => ({
    containerHolder: {
        zIndex: 1,
        width: 'calc(100% - 40px)',
        maxWidth: 450,
        marginBottom: 'auto'
    },
    container: {
        width: '100%',
        padding: 15,
        [_theme.breakpoints.up('md')]: {
            padding: 20,
        }
    },
    nameInputContainer: {
        width: '100%',
        justifyContent: 'space-between'
    },
    legalRow: {
        width: '100%',
        marginLeft: -23,
        '& a': {
            color: _theme.palette.primary.main,
            textTransform: 'none',
            textDecoration: 'none'
        }
    },
    legalText: {
        fontSize: 12
    },
    done: {
        marginTop: 20
    },
    infoRow: {
        marginTop: 15
    },
    infoIcon: {
        height: 15
    },
    infoText: {
        fontSize: 10,
        color: '#646363',
        marginLeft: 10,
        marginRight: 15
    },
    passwordHolder: {
        position: 'relative',
    },
    passwordInput: {
        width: '100%'
    },
    passwordReveal: {
        position: 'absolute',
        right: 7,
        cursor: 'pointer',
        color: '#717171',
        marginTop: 8
    },
    roleHolder: {
        cursor: "pointer"
    },
    role: {
        borderRadius: 10,
        borderWidth: 3,
        borderColor: _theme.palette.primary.main,
        justifyContent: 'center',
        alignItems: 'center',
        width: 55,
        height: 55,
        opacity: 0.4,
    },
    roleSelected: {
        borderColor: _theme.palette.primary.main,
        opacity: 1
    },
    roleIcon: {
        width: 26,
        height: 24,
        objectFit: 'contain'
    },
    roleText: {
        marginTop: 5,
        textAlign: "center"
    },
    roleTextSelected: {
        // fontWeight: 'bold',
        color: _theme.palette.primary.main,
    },
}))

const RegistrationClubDetails = () => {

    //region State

    const classes = useStyle()
    const commonClasses = useAuthCommonStyles()
    const [form, setForm] = useState<any>()
    // const [passwordVisible, setPasswordVisible] = useState(false)
    const t = useTranslation().t
    const history = useHistory()
    const store = useStore()
    const [phoneCodeFetched, setPhoneCodeFetched] = useState(false)
    const [ipCountry, setIpCountry] = useState<CountryModel>()
    const [processing, setProcessing] = useState(false)
    const dispatch = useDispatch()
    const {firstName, lastName, agencyPosition, phone} = useAuthState()

    //endregion State

    //region Handlers

    useEffect(() => {
        MixpanelService.trackScreenVisit(ScreenName.ONBOARDING_CLUB_NAME_ROLE)
    }, [])

    useEffect(() => {
        setForm({
            firstName: {
                value: firstName || '',
                placeholder: t("first_name"),
                type: 'text',
                validation: {
                    validators: [emptyValidator],
                    error: '',
                }
            },
            lastName: {
                value: lastName || '',
                placeholder: t("last_name"),
                type: 'text',
                validation: {
                    validators: [emptyValidator],
                    error: '',
                }
            },
            phone: {
                value: phone || '',
                placeholder: t("phone"),
                type: 'phone',
                validation: {
                    validators: [emptyPhoneNumberValidator],
                    error: '',
                }
            }
        })
    }, [])

    const getCountryPrefix = async () => {
        if (phoneCodeFetched) {
            return
        } else {
            setPhoneCodeFetched(true)
        }

        try {
            const country = await CommonService.getCurrentCountry()
            if (country.phoneCode) {
                setIpCountry(country)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (form?.phone) {
            getCountryPrefix()
        }
    }, [form])

    const isButtonDisabled = () => {
        return !form?.firstName?.value || !form?.lastName?.value || !form?.phone?.value || !agencyPosition
    }

    const validate = async (): Promise<boolean> => {
        let newForm: any = validateForm(form)
        // if (newForm.email.value &&
        //     newForm.repeatEmail.value &&
        //     !newForm.repeatEmail.validation.error &&
        //     newForm.email.value !== newForm.repeatEmail.value
        // ) {
        //     newForm.repeatEmail.validation.error = 'emails_does_not_match'
        // }
        //
        // if (newForm.email.value && !newForm.email.validation.error && isFormValid(newForm)) {
        //     const emailExists = await AuthService.checkEmail(form.email.value)
        //     newForm.email.validation.error = emailExists ? 'email_already_in_use' : ''
        // }

        setForm(newForm)
        return isFormValid(newForm)
    }

    const onContinue = async () => {
        if (await validate()) {
            await dispatch(setRegistrationBasicInfo(
                form.firstName.value,
                form.lastName.value,
                form.phone.value
            ))
            history.push('/auth/login?registration=true')
        }
    }

    const inputHandler = (key: string, value: string) => {
        const formElement = {
            ...form[key],
            validation: {
                ...form[key].validation,
                error: ''
            }
        }
        formElement.value = value
        setForm({
            ...form,
            [key]: formElement
        })
    }

    //endregion Handlers

    //region UI

    const renderInputField = (key: string, extraClass?: any) => {
        if (!form) return null

        const formElement = form[key]

        return (
            <TextField
                onChange={(event) => inputHandler(key, event.target.value)}
                value={formElement.value}
                className={clsx(commonClasses.input, extraClass)}
                required
                type={formElement.type}
                label={formElement.placeholder}
                variant="outlined"
                margin="normal"
                // size={'medium'}
                error={!!formElement.validation.error}
                helperText={mapErrorToMessage(formElement.validation.error)}
            />
        )
    }

    // const renderPasswordField = (key: string, extraClass?: any) => {
    //     if (!form) return null
    //
    //     const formElement = form[key]
    //
    //     return (
    //         <Box
    //             display={'flex'}
    //             flexDirection={'row'}
    //             alignItems={'center'}
    //             className={clsx(commonClasses.input, classes.passwordHolder, extraClass)}
    //         >
    //             <TextField
    //                 onChange={(event) => inputHandler(key, event.target.value)}
    //                 value={formElement.value}
    //                 required
    //                 className={classes.passwordInput}
    //                 type={passwordVisible ? 'text' : formElement.type}
    //                 label={formElement.placeholder}
    //                 variant="outlined"
    //                 margin="normal"
    //                 // size={'medium'}
    //                 error={!!formElement.validation.error}
    //                 helperText={mapErrorToMessage(formElement.validation.error)}
    //             />
    //
    //             {
    //                 passwordVisible ?
    //                     <VisibilityOffIcon
    //                         className={classes.passwordReveal}
    //                         onClick={() => setPasswordVisible(false)}
    //                     />
    //                     :
    //                     <VisibilityIcon
    //                         className={classes.passwordReveal}
    //                         onClick={() => setPasswordVisible(true)}
    //                     />
    //             }
    //
    //
    //         </Box>
    //     )
    // }

    const renderPhoneInputField = (key: string) => {
        if (!form) return null

        const formElement = form[key]

        return (
            <PhoneNumberInput
                onPhoneNumberChange={(phone) => inputHandler(key, phone)}
                phoneNumber={formElement.value}
                ipCountry={ipCountry}
                className={commonClasses.input}
                required
                variant={'outlined'}
                type={formElement.type}
                label={formElement.placeholder}
                margin="normal"
                error={!!formElement.validation.error}
                helperText={mapErrorToMessage(formElement.validation.error)}
            />
        )
    }

    const mapErrorToMessage = (error: string): string => {
        switch (error) {
            case 'empty':
                return t('error_empty_field')
            case 'email_not_valid':
                return t('error_bad_email')
            case 'min_length':
                return t('password_must_be_at_least_8_characters_long')
            case 'passwords_must_match':
                return t('passwords_must_match')
            case 'emails_does_not_match':
                return t('Emails do not match')
            case 'email_already_in_use':
                return t('email_already_in_use')
        }
        return ''
    }

    const formFooter = (
        <Box
            display={'flex'}
            alignItems={'center'}
            className={classes.infoRow}
        >
            <img alt={'SSL secure'} src={require('../../assets/images/icons/ssl.svg').default}
                 className={classes.infoIcon}/>
            <div className={classes.infoText}>{t('SSL secure')}</div>
            <img alt={'No credit card'} src={require('../../assets/images/icons/no-credit-card.svg').default}
                 className={classes.infoIcon}/>
            <div className={classes.infoText}>{t('No Credit Card')}</div>
        </Box>
    )

    const renderRole = (position: AgencyPosition, imageSelected: any, imageNotSelected: any) => (
        <Grid item xs={3}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                className={classes.roleHolder}
            >
                <Box
                    className={clsx(classes.role, agencyPosition === position && classes.roleSelected)}
                    onClick={() => dispatch({type: SET_AUTH_AGENCY_POSITION, payload: position})}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <img
                        src={agencyPosition === position ? imageSelected : imageNotSelected}
                        className={classes.roleIcon}
                    />
                </Box>
                <Typography
                    className={clsx(classes.roleText, agencyPosition === position && classes.roleTextSelected)}
                >
                    {t(`AGENT_ROLE_${position}`)}
                </Typography>
            </Box>
        </Grid>
    )

    const formUI = (
        <Card className={classes.containerHolder}>
            <Box
                className={classes.container}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                boxSizing={'border-box'}
            >
                <Box
                    display={'flex'}
                    flexWrap={'wrap'}
                    className={classes.nameInputContainer}
                >
                    {renderInputField('firstName', commonClasses.inputHalf)}
                    {renderInputField('lastName', commonClasses.inputHalf)}
                </Box>

                {renderPhoneInputField('phone')}

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    width={'100%'}
                    marginTop={4}
                    marginBottom={4}
                >
                    <Typography>
                        {t('Role')}
                    </Typography>

                    <Grid container spacing={4} style={{flexDirection: 'row'}}>
                        {renderRole(AgencyPosition.SPORTS_DIRECTOR, require('../../assets/images/agent-role-agent-green.svg').default, require('../../assets/images/agent-role-agent-grey.svg').default)}
                        {renderRole(AgencyPosition.HEAD_OF_SCOUTING, require('../../assets/images/agent-role-scout-green.svg').default, require('../../assets/images/agent-role-scout-grey.svg').default)}
                        {renderRole(AgencyPosition.OWNER_CEO, require('../../assets/images/agent-role-ceo-green.svg').default, require('../../assets/images/agent-role-ceo-grey.svg').default)}
                        {renderRole(AgencyPosition.OTHER, require('../../assets/images/agent-role-other-green.svg').default, require('../../assets/images/agent-role-other-grey.svg').default)}
                    </Grid>
                </Box>

                {
                    processing ? <CircularProgress className={classes.done}/> :
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.done}
                            disabled={isButtonDisabled()} onClick={onContinue}
                        >
                            {/*{t("Sign up free")}*/}
                            {t("Continue")}
                        </Button>
                }

                {formFooter}

            </Box>
        </Card>
    )

    return (
        <Box
            className={commonClasses.component}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
        >
            <AuthCommon title={t("Start your search")}/>
            {formUI}
        </Box>
    )

    //endregion UI
}


export default RegistrationClubDetails
