import React, {useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";
import {JobStatus} from "../../../../models/job.model";

const JobStatusFilter = () => {

    //region State

    const [jobStatus] = useUrlParam({param: 'jobStatus', dataType: 'string', defaultValue: ''})
    const [expanded, setExpanded] = useState(jobStatus !== '')
    const history = useHistory()

    //endregion State

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="jobStatus-content"
                id="jobStatus-header"
            >
                <Typography>Job
                    status {jobStatus !== '' && `(${jobStatus?.toLowerCase()})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="jobStatus_foot"
                        name="jobStatus_foot"
                        value={jobStatus}
                        onChange={e => {
                            setMultipleParams([
                                {name: 'jobStatus', value: e.target.value || '', dataType: 'string'},
                                {name: 'listPage', value: 1, dataType: 'numeric'}
                            ], history)
                        }}
                    >
                        <FormControlLabel value={JobStatus.ACTIVE} control={<Radio/>} label="Active"/>
                        <FormControlLabel value={JobStatus.INACTIVE} control={<Radio/>} label="Inactive"/>
                        <FormControlLabel value={JobStatus.NOT_APPROVED} control={<Radio/>}
                                          label="Not approved"/>
                        <FormControlLabel value="" control={<Radio/>} label="All"/>
                    </RadioGroup>
                </FormControl>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default JobStatusFilter