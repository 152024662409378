import React, {useState} from 'react'
import {
    Avatar,
    Box, Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from "react-i18next";
import UserModel from "../../../models/user.model";
import User, {VerifiedStatus} from "../../../models/user.model";
import {
    getCurrentClubLocation,
    getCurrentClubName,
    getFullName,
    getUserAge,
    getUserMainPositionLocalizedShort,
    getUserProfilePicture
} from "../../../util/profile.util";
import {ListColumn} from "../../common/List/List";
import {useListData} from "../../../util/data/useListData";
import UserService from "../../../services/user.service";
import {useHistory} from "react-router";
import {useListStyle} from "../../common/List/list.style";
import {format} from "date-fns";
import ETextField from "../../common/ETextField.component";

interface Props {
    user?: UserModel,
    setUser: (user: UserModel) => void,
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
    input: {
        width: '100%'
    },
    listRoot: {
        boxShadow: 'none !important',
        margin: 0
    }
}))


const AgentPlayersTab = ({user, setUser}: Props) => {

    const classes = useStyle()
    const {t, i18n} = useTranslation()
    const profile = user?.profile || {}
    const history = useHistory()
    const listClasses = useListStyle()
    const [addUserOpen, setAddUserOpen] = useState(false)
    const [tmpFirstName, setTmpFirstName] = useState('')
    const [tmpLastName, setTmpLastName] = useState('')
    const [addingPlayer, setAddingPlayer] = useState(false)

    const columns: ListColumn[] = [
        {name: t('Id'), id: 'id', width: 'xs'},
        {name: t('Name'), id: 'name', width: 'grow'},
        {name: t('Position'), id: 'position', width: 's'},
        {name: t('Age'), id: 'age', width: 's'},
        {name: t('Club'), id: 'currentClub', width: 'xl'},
        {name: t('Created at'), id: 'createdAt', width: 'm'},
    ]

    const renderCell = (user: User, column: ListColumn) => {
        let currentClubText = getCurrentClubName(user, '-');
        if (getCurrentClubLocation(user)) {
            currentClubText += ` (${getCurrentClubLocation(user)})`
        }

        let dynamicStyle;
        switch (user.profile?.verified) {
            case VerifiedStatus.VERIFIED:
                dynamicStyle = {
                    background: '#57e82b'
                }
                break;
            case VerifiedStatus.NOT_VERIFIED:
                dynamicStyle = {
                    background: '#f85151'
                }
                break;
            case VerifiedStatus.PENDING:
            default:
                dynamicStyle = {
                    background: '#f6de3d'
                }
        }

        switch (column.id) {
            case 'id':
                return (
                    <Typography variant={'body1'}>{user.id}</Typography>
                )
            case 'name':
                return (
                    <React.Fragment>
                        <Avatar className={listClasses.avatar}
                                src={getUserProfilePicture(user)}>
                            {user.profile?.firstName?.substring(0, 2)}
                        </Avatar>

                        <Box style={{
                            width: 7,
                            height: 40,
                            ...dynamicStyle,
                            marginRight: 10
                        }}/>
                        <Typography variant={'body1'}>
                            {getFullName(user) || '-'}
                        </Typography>

                    </React.Fragment>
                )
            case 'position':
                return (
                    <Typography variant={'body1'}>{getUserMainPositionLocalizedShort(t, user)}</Typography>
                )
            case 'age':
                return (
                    <Typography variant={'body1'}>{getUserAge(user, '-')}</Typography>
                )
            case 'currentClub':
                return (
                    <Typography variant={'body1'}>{currentClubText}</Typography>
                )
            case 'createdAt':
                return (
                    <Typography
                        variant={'body1'}>{user.profile?.createdAt ? format(user.profile.createdAt, "dd.MM.yyyy HH:mm") : '-'}</Typography>
                )
            default:
                return null
        }
    }

    const openUser = (user: User) => {
        history.push(`/admin/user/${user.id}`)
    }

    const listData = useListData<User>({
        list: (page, size, search) => UserService.getUsers({
            page: page,
            size: size,
            sort: {
                orders: [{prop: 'id', dir: 'desc'}]
            }
        }, {
            parent: user?.id
        }),
        renderCell,
        columns,
        onAdd: () => setAddUserOpen(true),
        onItemClick: openUser,
        onItemMiddleClick: user => window.open(`${process.env.REACT_APP_WEB_URL}/admin/user/${user.id}`, '_blank'),
        listId: 'agentPlayersList',
        classes: {
            root: classes.listRoot
        }
    })

    //endregion Handlers

    //region UI

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
        >
            {listData.renderList()}

            <Dialog
                open={addUserOpen}
                onClose={() => setAddUserOpen(false)}
            >
                <DialogTitle>
                    {t('Add player')}
                </DialogTitle>

                <DialogContent>
                    <Box
                        p={1}
                    >
                        <Grid container spacing={3}>
                            <ETextField
                                grid={'1/2'}
                                label={t('First name')}
                                value={tmpFirstName}
                                onChange={setTmpFirstName}
                                disabled={addingPlayer}
                            />
                            <ETextField
                                grid={'1/2'}
                                label={t('Last name')}
                                value={tmpLastName}
                                onChange={setTmpLastName}
                                disabled={addingPlayer}
                            />
                        </Grid>
                    </Box>
                </DialogContent>

                <DialogActions>
                    {
                        addingPlayer ? <CircularProgress/> :
                            <Button
                                onClick={async () => {
                                    setAddingPlayer(true)
                                    const response = await UserService.registerSubuser(user?.id!, tmpFirstName, tmpLastName)
                                    openUser(response)
                                    setTmpFirstName('')
                                    setTmpLastName('')
                                    setAddingPlayer(false)
                                    setAddUserOpen(false)
                                }}
                            >
                                {t('Add player')}
                            </Button>
                    }
                </DialogActions>

            </Dialog>

        </Box>
    )

    //endregion UI
}

export default AgentPlayersTab
