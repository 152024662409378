import Job from "../models/job.model";

export function getAnalyticsPositions(opportunity?: Job) {
    if (!opportunity || !opportunity.positions) {
        return '';
    }

    if (opportunity.positionMode === 'section') {
        return opportunity.section;
    } else {
        return opportunity.positions.join(', ');
    }
}

export function getAnalyticsGender(opportunity?: Job) {
    if (!opportunity || !opportunity.gender) {
        return null;
    }

    return opportunity.gender.toLowerCase();
}
