import React from 'react'
import {Box, Card, CircularProgress} from "@material-ui/core"
import {useHistory, useParams} from "react-router"
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {useItemData} from "../../../util/data/useItemData"
import {useBreadcrumbs} from "../../../util/data/useBreadcrumbs"
import {useTranslation} from "react-i18next";
import ClubModel from "../../../models/club.model";
import ClubService from "../../../services/club.service";
import {getClubName} from "../../../util/profile.util";
import {useTabs} from "../../../util/data/useTabs";
import CheckIcon from '@material-ui/icons/Check';
import ClubRequestInfo from "./ClubRequestInfo.component";
import {disable} from "mixpanel-browser";
import ClubRequestResolve from "./ClubRequestResolve.component";

interface Params {
    id: string
}

const ClubRequestDetails = () => {
    //region State

    const params = useParams<Params>()
    const id = params.id
    const history = useHistory()
    const {t} = useTranslation()

    const itemData = useItemData<ClubModel>({
        get: () => ClubService.getClubRequest(+id),
        // isValid: item => !!item?.profile,
        deps: [id]
    })


    const tabs = [
        {icon: <InfoOutlinedIcon/>, label: t('Club Request Info')},
        {icon: <CheckIcon/>, label: t('Resolve')},
    ]


    const tabsData = useTabs({
        tabs: tabs,
        showDelete: false,
        showSave: itemData.isDirty,
        canSave: itemData.isValid,
        onSave: disable,
        onDelete: itemData.remove,
        showButtonLoaders: itemData.saving,
        canDelete: false
    })

    const breadcrumbs = useBreadcrumbs({
        path: [
            {label: t('Club Requests'), url: '/admin/clubRequest'},
            {label: getClubName(itemData.item, '-')}
        ]
    })

    //endregion

    //region UI

    return (
        <Box>

            {breadcrumbs}

            <Card>
                {tabsData.renderTabs()}

                {itemData.loading && <CircularProgress/>}

                {
                    !itemData.loading && tabsData.tab === 0 &&
                    <ClubRequestInfo club={itemData.item} setClub={itemData.setItem}/>
                }

                {
                    !itemData.loading && tabsData.tab === 1 &&
                    <ClubRequestResolve club={itemData.item} setClub={itemData.setItem}/>
                }

            </Card>

            {itemData.renderSnackbars()}

        </Box>
    )

    //endregion UI
}

export default ClubRequestDetails
