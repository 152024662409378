import React, {useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CountrySelector from "../../CountrySelector.component";
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {
    addCountries,
    eeaChCountryIds,
    euCountryIds,
    getCountriesByIds,
    removeCountries,
    worldIds
} from "../../../../services/countries";
import CountryModel from "../../../../models/country.model";
import {useHistory} from "react-router";

const CountryFilter = () => {

    //region State

    const history = useHistory()

    const [countries] = useUrlParam<CountryModel[]>({
        param: 'countries',
        dataType: 'complex',
        defaultValue: []
    })

    const [expanded, setExpanded] = useState(!!countries?.length)

    //endregion State

    //region Handlers

    const handleAddCountry = (country: any) => {
        let countriesToAdd
        if (country.countryCode === 'eu' || country.countryCode === 'eeach' || country.countryCode === 'world') {
            switch (country.countryCode) {
                case 'eu':
                    countriesToAdd = getCountriesByIds(euCountryIds)
                    break
                case 'eeach':
                    countriesToAdd = getCountriesByIds(eeaChCountryIds)
                    break
                case 'world':
                    countriesToAdd = getCountriesByIds(worldIds)
                    break
            }
        } else {
            countriesToAdd = [country]
        }

        const updatedCountries = addCountries(countries, countriesToAdd || [])

        setMultipleParams([
            {name: 'countries', value: updatedCountries, dataType: 'complex'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }

    const handleRemoveCountry = (country: any) => {
        let countriesToRemove
        if (country.countryCode === 'eu') {
            countriesToRemove = getCountriesByIds(euCountryIds)
        } else if (country.countryCode === 'eeach') {
            countriesToRemove = getCountriesByIds(eeaChCountryIds)
        } else if (country.countryCode === 'world') {
            countriesToRemove = getCountriesByIds(worldIds)
        } else {
            countriesToRemove = [country]
        }

        const removedCountries = removeCountries(countries, countriesToRemove)
        setMultipleParams([
            {name: 'countries', value: removedCountries, dataType: 'complex'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }

    //endregion Handlers

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="gender-content"
                id="gender-header"
            >
                <Typography>Countries {!!countries?.length && `(${countries?.length})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <CountrySelector
                    countries={countries}
                    onAddCountry={handleAddCountry}
                    onRemoveCountry={handleRemoveCountry}
                    addTitle={'Add country'}
                />
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default CountryFilter