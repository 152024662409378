import {getCurrentClub, getPreviousClubs, getUserMainPosition, getUserProfilePicture} from "../../util/profile.util";
import PositionMapModel from "../../models/positionMap.model";
import Job from "../../models/job.model";
import UserModel from "../../models/user.model";
import {OpportunityAlgoData} from "../../models/opportunityAlgoData.model";
import OpportunityFeedEntryModel from "../../models/opportunityFeedEntry.model";
import OpportunityFeedModel, {UserCalculatedData} from "../../models/opportunityFeed.model";
import {haversineDistance} from "../../util/location.util";

const processFeedEntry = (opportunity?: OpportunityFeedEntryModel, userData?: UserCalculatedData) => {
    const userLocation = userData?.location;

    const result: OpportunityAlgoData = {
        opportunity: opportunity?.opportunity,

        disqualifiedOnAge: opportunity?.ageDisqualification,
        disqualifiedOnLevel: opportunity?.levelDisqualification,
        disqualifiedOnLocation: opportunity?.locationDisqualification,
        disqualifiedOnClub: opportunity?.currentClubDisqualification,
        disqualifiedOnGender: opportunity?.genderDisqualification,
        disqualifiedOnAppliedOrSkipped: opportunity?.appliedOrDismissedDisqualification,
        disqualifiedOnPosition: opportunity?.positionDisqualification,
        disqualifiedOnVerification: opportunity?.verifiedDisqualification,

        positionPoints: opportunity?.positionPoints,
        footPoints: opportunity?.footPoints,
        levelPoints: opportunity?.levelPoints,
        heightPoints: opportunity?.heightPoints,
        nationalityPoints: opportunity?.nationalityPoints,
        languagePoints: opportunity?.languagePoints,
    };

    if (opportunity?.opportunity?.location?.city) {
        const distance = haversineDistance(
            [userLocation?.location?.latitude || 0, userLocation?.location?.longitude || 0],
            [opportunity.opportunity.location.city.latitude || 0, opportunity.opportunity.location.city.longitude || 0],
            false
        );
        result.calculatedDistance = distance;
    } else {
        result.calculatedDistance = 0;
    }

    return result;
};

export const processOpportunititesFeed = (opportunityFeed?: OpportunityFeedModel): OpportunityAlgoData[] => {
    return opportunityFeed?.entries?.map(opportunityFeedEntry => {
        return processFeedEntry(opportunityFeedEntry, opportunityFeed.userCalculatedData);
    }) || [];
};

export const getPositionMatrixScore = (positionMatrix: PositionMapModel[], opportunity?: Job, applicant?: UserModel) => {
    let max = -999999;
    const userPosition = getUserMainPosition(applicant);
    if (!opportunity?.positions?.length || !userPosition) return max;

    opportunity.positions?.forEach(opportunityPosition => {
        const matrixValue = positionMatrix.find(p => p.opportunityPosition === opportunityPosition && p.userPosition === userPosition)
        if ((matrixValue?.value || 0) > max) {
            max = matrixValue?.value || 0;
        }
    })

    return max;
}
