import React from 'react'
import {Box, Grid, Hidden} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Carousel from "./Carousel/Carousel.component";
import {DIMENSIONS, LANDING_COMMON_COLORS} from "./common";
import {useTranslation} from "react-i18next";

const useStyle = makeStyles(_theme => ({
    component: {
        marginTop: 200,
        width: '100%',
        position: 'relative',
        [_theme.breakpoints.down('md')]: {
            marginTop: 100
        }
    },
    background: {
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: -1,
        top: -350,
        width: '100%',
    },
    title: {
        fontSize: DIMENSIONS.LARGE.TITLE_SIZE,
        color: LANDING_COMMON_COLORS.TITLE,
        marginBottom: 105,
        textAlign: "center",
        paddingLeft: 25,
        paddingRight: 25,
        [_theme.breakpoints.down('md')]: {
            fontSize: DIMENSIONS.SMALL.TITLE_SIZE
        }
    },
    infoContainer: {},
    carousel: {
        height: 350,
        width: 500,
        // maxWidth: 1400,
        [_theme.breakpoints.down('sm')]: {
            height: 300
        }
    },
    slideContainer: {
        width: '100%',
        height: '100%',
        boxShadow: '0px 10px 55px 5px rgba(65, 196, 126, 0.35)',
        borderRadius: 16,
        background: '#FFF',
        padding: '0 50px 50px 50px',
        boxSizing: "border-box",
        transformStyle: 'preserve-3d',
        [_theme.breakpoints.down('md')]: {
            padding: '0 30px 30px 30px',
            maxWidth: 400,
            width: 'calc(100vw - 60px)',
            height: 'fit-content',
            marginBottom: 60
        }
    },
    slideImageWrapper: {
        width: 180,
        height: 180,
        minHeight: 180,
        borderRadius: 100,
        marginTop: -40,
        overflow: 'hidden',
        [_theme.breakpoints.down('sm')]: {
            width: 110,
            height: 110,
            minHeight: 110
        }
    },
    slideImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    slideName: {
        marginTop: 25,
        fontWeight: 500,
        fontSize: 22,
        lineHeight: '26px',
        color: LANDING_COMMON_COLORS.TITLE,
        [_theme.breakpoints.down('md')]: {
            fontSize: 20
        }
    },
    slideRole: {
        fontWeight: 300,
        fontSize: 16,
        lineHeight: '28px',
        color: '#716C80',
        whiteSpace: 'pre-wrap',
        textAlign: 'center',
        [_theme.breakpoints.down('md')]: {
            fontSize: 14
        }
    },
    slideQuoteHolder: {
        marginTop: 10,
        position: "relative"
    },
    slideQuoteText: {
        fontSize: 16,
        lineHeight: '28px',
        color: '#716C80',
        [_theme.breakpoints.down('md')]: {
            fontSize: 14
        }
    },
    slideQuoteImage: {
        width: 40,
        height: 40,
        position: 'absolute',
        left: -10,
        top: -25,
        [_theme.breakpoints.down('md')]: {
            width: 30,
            height: 30,
            left: -5,
            top: -15,
        }
    },
    slideText: {
        zIndex: 10,
        [_theme.breakpoints.down('md')]: {
            fontSize: 14
        }
    }
}))

const Testimonials = () => {

    const classes = useStyle()
    const t = useTranslation().t

    const renderSlide = (imageJpg: any, imageWebp: any, name: string, role: string, quote: string) => {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                className={classes.slideContainer}
            >
                <picture className={classes.slideImageWrapper}>
                    <source srcSet={imageWebp}/>
                    <img
                        alt={name + ' image'}
                        src={imageJpg}
                        className={classes.slideImage}
                    />
                </picture>


                <div className={classes.slideName}>
                    {name}
                </div>

                <div className={classes.slideRole}>
                    {role}
                </div>

                <Box
                    className={classes.slideQuoteHolder}
                >
                    <img
                        className={classes.slideQuoteImage}
                        src={require('../../assets/images/landing/quote.svg').default}
                    />
                    <div className={classes.slideText}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {quote}</div>
                </Box>

            </Box>
        )
    }

    const slides = [
        {
            id: '1',
            element: renderSlide(
                require('../../assets/images/landing/tadic.jpg').default,
                require('../../assets/images/landing/tadic.webp').default,
                t('Testimonial 1 Name'),
                t('Testimonial 1 Role'),
                t('Testimonial 1 Message')
            )
        },
        {
            id: '2',
            element: renderSlide(
                require('../../assets/images/landing/ortlechner.jpg').default,
                require('../../assets/images/landing/ortlechner.webp').default,
                t('Testimonial 2 Name'),
                t('Testimonial 2 Role'),
                t('Testimonial 2 Message')
            )
        },
        {
            id: '3',
            element: renderSlide(
                require('../../assets/images/landing/krammer.jpg').default,
                require('../../assets/images/landing/krammer.webp').default,
                t('Testimonial 3 Name'),
                t('Testimonial 3 Role'),
                t('Testimonial 3 Message')
            )
        },
        {
            id: '4',
            element: renderSlide(
                require('../../assets/images/landing/fuchs.jpg').default,
                require('../../assets/images/landing/fuchs.webp').default,
                t('Testimonial 4 Name'),
                t('Testimonial 4 Role'),
                t('Testimonial 4 Message')
            )
        },
        {
            id: '5',
            element: renderSlide(
                require('../../assets/images/landing/ivanschitz.jpg').default,
                require('../../assets/images/landing/ivanschitz.webp').default,
                t('Testimonial 5 Name'),
                t('Testimonial 5 Role'),
                t('Testimonial 5 Message')
            )
        }
    ]

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={classes.component}
        >
            <img
                alt={''}
                src={require('../../assets/images/landing/content-background-2.svg').default}
                className={classes.background}
            />

            <div className={classes.title}>
                What do they say about us?
            </div>

            <Hidden mdDown>
                <Box
                    className={classes.carousel}
                >
                    <Carousel
                        items={slides}
                        active={2}
                        direction={'right'}
                        variant={5}
                    />
                </Box>
            </Hidden>

            <Hidden lgUp>
                <Grid
                    container
                    spacing={4}
                    justify={'center'}
                >
                    {slides.map(slide => {
                        return (
                            <Grid item key={slide.id}>
                                {slide.element}
                            </Grid>
                        )
                    })}
                </Grid>
            </Hidden>

        </Box>
    )
}

export default Testimonials
