import React, {useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";
import CountryModel from "../../../../models/country.model";
import {
    addCountries,
    eeaChCountryIds,
    euCountryIds,
    getCountriesByIds,
    removeCountries,
    worldIds
} from "../../../../services/countries";
import CountrySelector from "../../CountrySelector.component";

const NationalityFilter = () => {

    //region State

    const history = useHistory()

    const [nationalities] = useUrlParam<CountryModel[]>({
        param: 'nationalities',
        dataType: 'complex',
        defaultValue: []
    })

    const [expanded, setExpanded] = useState(!!nationalities?.length)

    //endregion State

    //region Handlers

    const handleAddNationality = (nationality: any) => {
        let nationalitiesToAdd
        if (nationality.nationalityCode === 'eu' || nationality.nationalityCode === 'eeach' || nationality.nationalityCode === 'world') {
            switch (nationality.nationalityCode) {
                case 'eu':
                    nationalitiesToAdd = getCountriesByIds(euCountryIds)
                    break
                case 'eeach':
                    nationalitiesToAdd = getCountriesByIds(eeaChCountryIds)
                    break
                case 'world':
                    nationalitiesToAdd = getCountriesByIds(worldIds)
                    break
            }
        } else {
            nationalitiesToAdd = [nationality]
        }

        const updatedNationalities = addCountries(nationalities, nationalitiesToAdd || [])

        setMultipleParams([
            {name: 'nationalities', value: updatedNationalities, dataType: 'complex'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }

    const handleRemoveNationality = (nationality: any) => {
        let nationalitiesToRemove
        if (nationality.nationalityCode === 'eu') {
            nationalitiesToRemove = getCountriesByIds(euCountryIds)
        } else if (nationality.nationalityCode === 'eeach') {
            nationalitiesToRemove = getCountriesByIds(eeaChCountryIds)
        } else if (nationality.nationalityCode === 'world') {
            nationalitiesToRemove = getCountriesByIds(worldIds)
        } else {
            nationalitiesToRemove = [nationality]
        }

        const removedNationalities = removeCountries(nationalities, nationalitiesToRemove)
        setMultipleParams([
            {name: 'nationalities', value: removedNationalities, dataType: 'complex'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }

    //endregion Handlers

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="gender-content"
                id="gender-header"
            >
                <Typography>Nationalities {!!nationalities?.length && `(${nationalities?.length})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <CountrySelector
                    countries={nationalities}
                    onAddCountry={handleAddNationality}
                    onRemoveCountry={handleRemoveNationality}
                    addTitle={'Add nationality'}
                />
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default NationalityFilter