import React from 'react';
import styles from './JobPreview.module.scss';
import clsx from 'clsx';
import {Box, Card} from "@material-ui/core";

const JobPreviewLoader = () => (
    <Card>
        <Box
            display={'flex'}
            flexDirection={'column'}
            p={2}
            height={300}
        >

            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                className={styles.header}
            >

                <div className={clsx(styles.textLarge, 'boxLoaderMedium', 'boxLoaderLong')}/>
                <div className={clsx(styles.textInfo, 'boxLoaderSmall', 'boxLoaderLong')}/>

            </Box>

            <div className={clsx(styles.textLarge, 'boxLoaderMedium', 'boxLoaderNormal')}/>
            <Box
                display={'flex'}
                flexWrap={'wrap'}
                className={styles.positions}
            >
                <div className={clsx('boxLoaderMedium', 'boxLoaderLong')}/>
            </Box>

            <div className={clsx(styles.textLarge, 'boxLoaderMedium', 'boxLoaderNormal')}/>
            <div className={clsx(styles.textInfo, styles.applicants, 'boxLoaderNormal', 'boxLoaderShort')}/>

            <div className={clsx(styles.actions, 'boxLoaderShort', 'boxLoaderSmall')}/>

        </Box>
    </Card>
);

export default JobPreviewLoader;
