import {makeStyles} from "@material-ui/core";

export const useListUtilStyle = makeStyles(theme => ({
    avatar: {
        width: 32,
        height: 32,
        marginRight: 10,
        fontSize: 14
    },
    noShadow: {
        boxShadow: 'none'
    }
}))