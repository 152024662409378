import React, {useEffect, useRef, useState} from 'react'
import ClubAlreadyClaimed from "./ClubAlreadyClaimed"
import ClubModel from "../../models/club.model"
import debounce from 'lodash/debounce'
import clsx from 'clsx'
import {useTranslation} from "react-i18next"
import ClubsService from "../../services/clubs.service"
import {useDispatch} from "react-redux"
import MixpanelService from "../../services/analytics/mixpanel.service"
import {ScreenName} from "../../services/analytics/screenName"
import {EventName} from "../../services/analytics/eventName"
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Card, CircularProgress, Grid, TextField} from "@material-ui/core";
import CreateClubRequestPopup from "./CreateClubRequestPopup.component";
import {AuthCommon, useAuthCommonStyles} from "./auth.common.";
import {windowHeight, windowWidth} from "../../util/dimension.util";
import {useHistory} from "react-router";
import ClubBadge from "../common/ClubBadge/ClubBadge";
import ClubBadgeLoader from "../common/ClubBadge/ClubBadgeLoader";
import {REGISTRATION_CHOSEN_SUBSCRIPTION_TYPE} from "../../util/constants";
import {useUrlParam} from "../../util/data/url.util";
import {SET_AUTH_CLUB} from "../../store/actions/actionTypes";

const useStyle = makeStyles(_theme => ({
    containerHolder: {
        zIndex: 1,
        width: 'calc(100% - 40px)',
        maxWidth: 700,
        marginBottom: 'auto'
    },
    container: {
        width: '100%',
        padding: 15,
        [_theme.breakpoints.up('md')]: {
            padding: 20,
        }
    },
    done: {
        marginTop: 30
    },
    clubsContainer: {
        width: '100%',
        marginTop: 20,
        justifyContent: 'center'
    },
    clubBadge: {
        width: 120,
        height: 120,
        padding: '6px 3px 6px 3px',
        cursor: 'pointer',
        border: '2px solid #F1F1F1',
        borderRadius: 11
    },
    selected: {
        borderColor: _theme.palette.primary.main
    },
    searchField: {
        width: 'calc(100% - 40px)'
    },
    navHolder: {
        width: 20,
        minWidth: 20,
        height: '100%',
        zIndex: 10,
        [_theme.breakpoints.down('md')]: {}
    },
    navIcon: {
        width: 18,
        cursor: 'pointer'
    },
    noClubFound: {
        margin: '30px 10px 0 10px',
        fontWeight: 300,
        fontSize: 14,
        color: '#656565',
        cursor: 'pointer'
    }
}))

const RegistrationFindClub = () => {

    //region State

    const classes = useStyle()
    const commonClasses = useAuthCommonStyles()
    const [clubAlreadyClaimedOpen, setClubAlreadyClaimedOpen] = useState(false)
    const [clubNameQuery, setClubNameQuery] = useState('')
    const [executedClubNameQuery, setExecutedClubNameQuery] = useState('')
    const [clubs, setClubs] = useState<ClubModel[]>([])
    const [clubsLoading, setClubsLoading] = useState(false)
    const [selectedClub, setSelectedClub] = useState<ClubModel>()
    const [page, setPage] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [createClubRequestOpen, setCreateClubRequestOpen] = useState(false)
    const [registrationInProgress, setRegistrationInProgress] = useState(false)
    const [subscriptionType] = useUrlParam({defaultValue: '', param: 'subscriptionType', dataType: "string"})

    // // @ts-ignore
    // const [registrationError, setRegistrationError] = useState('')
    // const [registrationDone, setRegistrationDone] = useState(false)

    const pageSize = windowHeight() > 600 && windowWidth() > 600 ? 8 : 4
    const history = useHistory()
    const t = useTranslation().t
    const dispatch = useDispatch()

    // const {
    //     registrationFirstName,
    //     registrationLastName,
    //     registrationPassword,
    //     registrationEmail,
    //     registrationPhone,
    //     utm,
    // } = useSelector<any, AuthState>(store => store.auth)

    // if (!registrationEmail || !registrationFirstName || !registrationLastName || !registrationPassword || !registrationPhone) {
    //     history.push('/auth/details')
    // }

    //endregion State

    //region Handlers

    useEffect(() => {
        if (subscriptionType) {
            localStorage.setItem(REGISTRATION_CHOSEN_SUBSCRIPTION_TYPE, subscriptionType as string)
        }
    }, [])

    const findClub = async (query: string, page: number, latitude?: number, longitude?: number) => {
        setClubsLoading(true)
        setSelectedClub(undefined)
        try {
            const clubs = await ClubsService.getClubsByLocation(query, latitude, longitude, {
                size: pageSize,
                page: page
            })
            setPage(page)
            setClubs(clubs.content)

            if (!clubs.content?.[0]?.clubAdmins?.length) {
                setSelectedClub(clubs.content?.[0])
            }
            setTotalPages(clubs.totalPages)
        } catch (e) {
            console.log(e)
            setClubs([])
        } finally {
            setClubsLoading(false)
        }
    }

    const executeSearch = useRef(debounce(async (query: string, page: number) => {
        setExecutedClubNameQuery(query)
        findClub(query, page)
    }, 500)).current

    const getLocation = () => {
        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(position => {
                const {latitude, longitude} = position.coords
                findClub(clubNameQuery, 0, latitude, longitude)
            }, undefined, {
                enableHighAccuracy: true
            })
        }
    }

    useEffect(() => {
        findClub('', 0)

        getLocation()

        MixpanelService.trackScreenVisit(ScreenName.ONBOARDING_FIND_YOUR_CLUB)
    }, [])

    const queryHandler = (event: any) => {
        const query = event.target.value
        setClubNameQuery(query)
        executeSearch(query, 0)
    }

    const clubPickHandler = (club: ClubModel) => {
        if (club.clubAdmins && club.clubAdmins.length) {
            setClubAlreadyClaimedOpen(true)
            MixpanelService.track(EventName.CLUB_ALREADY_TAKEN)
        } else {
            setSelectedClub(club)
        }
    }

    const onContinue = () => {
        if (!selectedClub) return
        dispatch({type: SET_AUTH_CLUB, payload: selectedClub})
        history.push('/auth/details')
    }

    const closePopupHandler = () => {
        setClubAlreadyClaimedOpen(false)
    }

    const onCreateClubRequest = () => {
        MixpanelService.trackScreenVisit(ScreenName.ONBOARDING_CREATE_CLUB_REQUEST)
        setCreateClubRequestOpen(true)
    }

    // const register = async (club: ClubModel) => {
    //     try {
    //         setRegistrationError('')
    //         setRegistrationInProgress(true)
    //         const response = await dispatch(initRegisterClub(
    //             club,
    //             registrationFirstName!,
    //             registrationLastName!,
    //             registrationPhone!,
    //             registrationEmail!,
    //             registrationPassword!,
    //             AgencyPosition.OWNER_CEO
    //         ))
    //
    //         if (!response) {
    //             setRegistrationError('error')
    //         } else {
    //             const mixpanelData: any = {};
    //             (utm || []).forEach((param: any) => {
    //                 const key = param.key.split('_').join(' ')
    //                 mixpanelData[key] = param.value
    //             })
    //
    //             MixpanelService.setUserPropertiesOnce(mixpanelData)
    //
    //             setRegistrationDone(true)
    //         }
    //     } catch (e) {
    //         console.log(e)
    //         setRegistrationError(e.message)
    //     } finally {
    //         setRegistrationInProgress(false)
    //     }
    // }

    //endregion Handlers

    //region UI

    const renderClubs = () => {
        const clubsSliced = clubs?.slice(0, pageSize)
        const clubsBadges = clubsSliced.map(club => {
            return (
                <Grid item key={club.id}>
                    <Box
                        className={clsx(classes.clubBadge, club?.id === selectedClub?.id && classes.selected, 'clubBadgeHover')}
                    >
                        <ClubBadge club={club} clicked={() => clubPickHandler(club)}/>
                    </Box>
                </Grid>
            )
        })

        return (
            <Box
                className={classes.clubsContainer}
                display={'flex'}
                alignItems={'center'}
            >
                <Box className={classes.navHolder}
                     display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}
                >
                    {
                        page > 0 &&
                        <img className={classes.navIcon} src={require('../../assets/images/icons/nav-left.svg').default}
                             onClick={() => {
                                 executeSearch(clubNameQuery, page - 1)
                             }}/>
                    }
                </Box>

                <Grid container justify={'center'} spacing={2}>
                    {clubsBadges}
                </Grid>

                <Box className={classes.navHolder}
                     display={'flex'}
                     justifyContent={'center'}
                     alignItems={'center'}
                >
                    {
                        page < totalPages - 1 &&
                        <img className={classes.navIcon}
                             src={require('../../assets/images/icons/nav-right.svg').default} onClick={() => {
                            executeSearch(clubNameQuery, page + 1)
                        }}/>
                    }
                </Box>

            </Box>
        )
    }

    const renderClubLoaders = () => {
        let loaders = [...Array(pageSize)]
        const loaderViews = loaders.map((_, index) => {
            return (
                <Grid item key={index}>
                    <Box className={classes.clubBadge}>
                        <ClubBadgeLoader/>
                    </Box>
                </Grid>
            )
        })

        return (
            <Box
                className={classes.clubsContainer}
                display={'flex'}
                alignItems={'center'}
            >
                <div className={classes.navHolder}/>

                <Grid container justify={'center'} spacing={2}>
                    {loaderViews}
                </Grid>

                <div className={classes.navHolder}/>

            </Box>
        )
    }

    const clubsUI = (
        <Card
            className={classes.containerHolder}
        >
            <Box
                className={classes.container}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                boxSizing={'border-box'}
            >
                <TextField
                    onChange={queryHandler}
                    value={clubNameQuery}
                    className={classes.searchField}
                    id="outlined-search"
                    label={t("type_club_name")}
                    type="search"
                    margin="normal"
                    variant="outlined"
                />

                {clubsLoading ? renderClubLoaders() : renderClubs()}

                {
                    !!executedClubNameQuery &&
                    <div
                        className={classes.noClubFound}
                        onClick={onCreateClubRequest}
                    >
                        {t('You can not find your club? Click here')}
                    </div>
                }

                {
                    registrationInProgress ?
                        <CircularProgress className={classes.done}/> :
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.done}
                            disabled={!selectedClub}
                            onClick={onContinue}
                        >
                            {t("Continue with", {name: selectedClub?.name || '-'})}
                        </Button>
                }

            </Box>
        </Card>
    )

    const popups = (
        <React.Fragment>
            <ClubAlreadyClaimed open={clubAlreadyClaimedOpen} onClose={closePopupHandler}/>

            <CreateClubRequestPopup
                nameInitialValue={executedClubNameQuery}
                open={createClubRequestOpen}
                onClose={() => setCreateClubRequestOpen(false)}
                onClubRequestCreated={async clubRequest => {
                    if (!clubRequest) return
                    await dispatch({type: SET_AUTH_CLUB, payload: clubRequest})
                    setCreateClubRequestOpen(false)
                    history.push('/auth/details')
                }}
            />

            {/*<RegistrationDone open={registrationDone}/>*/}
        </React.Fragment>
    )

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={commonClasses.component}
            boxSizing={'border-box'}
        >
            <AuthCommon title={t("Find your club")}/>
            {clubsUI}
            {popups}
        </Box>
    )

    //endregion UI

}

export default RegistrationFindClub