import gql from "graphql-tag";
import {
    B2C_ADMIN_SUBSCRIPTION_CONFIG
} from "./queryFragments";

export const GET_ADMIN_SUBSCRIPTION_CONFIG = gql`
    query subscriptionConfig($userType: UserTypeInput!) {
        subscriptionConfig(userType: $userType) {
            ${B2C_ADMIN_SUBSCRIPTION_CONFIG}
        }
    }
`;

export const UPDATE_ADMIN_SUBSCRIPTION_CONFIG = gql`
    mutation subscriptionConfig($subscriptionConfig: SubscriptionConfigInput) {
        subscriptionConfig(subscriptionConfig: $subscriptionConfig) {
            ${B2C_ADMIN_SUBSCRIPTION_CONFIG}
        }
    }
`;