import React from "react";
import UserModel from "../../models/user.model";
import {useTranslation} from "react-i18next";
import {isAgentPlayerViaParent, isClubPlayerViaParent} from "../../util/profile.util";
import {Box, Typography} from "@material-ui/core";
import EuroIcon from "@material-ui/icons/Euro";

interface Props {
    user?: UserModel,
    smallMode?: boolean
}

const TransfermarktValue = ({user, smallMode}: Props) => {

    if (!user?.profile?.transfermarktValue) {
        return null
    }

    return (
        <Box style={{fontSize: 14}} display={'flex'} alignItems={'center'}>

            <img src={require('../../assets/images/logo/transfermarkt_tm.png').default} style={{
                width: smallMode ? 18: 30,
                height: smallMode ? 18: 30,
                marginRight: smallMode ? 4 : 10
            }} />

            <Box
                style={{
                    backgroundColor: '#00aded',
                    boxShadow: 'inset 0 0 50px rgb(49 79 119 / 50%)',
                    padding: smallMode ? '1px 6px' : '4px 12px',
                    borderRadius: smallMode ? 3 : 4
                }}
            >
                <Typography
                    style={{
                        color: 'white',
                        fontSize: smallMode ? 14 : 20
                    }}
                >
                    <span style={{fontSize: smallMode ? 10 : 14}}>€</span>
                    {user.profile.transfermarktValue.toLocaleString('en-US')}
                </Typography>

            </Box>

        </Box>
    )

}

export default TransfermarktValue

