import React, {useState} from 'react'
import {Box, Grid, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import Job from "../../../models/job.model";
import BasicInfo from "./createJob/BasicInfo.component";
import StatisticSection from "./createJob/StatisticSection.component";
import ImportantToKnow from "./createJob/ImportantToKnow.component";
import PublishJob from "./createJob/PublishJob.component";
import EButton from "../../common/EButton";
import OpportunityService from "../../../services/opportunity.service";
import {LocationType} from "../../../models/locationType.enum";
import {isPhAdmin} from "../../../util/profile.util";
import AdminSection from "./createJob/AdminSection.component";
import {useUser} from "../../../store/store.utils";

interface Props {
    job?: Job,
    setJob: (job: Job) => void,
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
    input: {
        width: '100%'
    }
}))

const Overview = ({job, setJob}: Props) => {

    const classes = useStyle()
    const history = useHistory()
    const {t, i18n} = useTranslation()
    const user = useUser()

    const updateFields = (data: { field: string, value: any }[]) => {
        const newJob: any = {...job}
        data.forEach(d => newJob.profile[d.field] = d.value)
        setJob(newJob)
    }

    const updateField = (field: string, value: any) => {
        updateFields([{field, value}])
    }


    //endregion Handlers

    //region UI

    return (

        <Grid item xs={12} style={{maxWidth: 1300}}>
            <Box
                p={3}
                display={'flex'}
                flexDirection={'column'}
            >

                <Box
                    width={'100%'}
                    marginBottom={3}
                    marginTop={2}
                    display={'flex'}
                    justifyContent={'center'}
                >
                    <Typography variant={'h4'}>
                        Basic info
                    </Typography>
                </Box>

                <Box style={{padding: 30}}>
                    <BasicInfo setJob={setJob} job={job}/>
                </Box>

                {
                    isPhAdmin(user) &&
                        <React.Fragment>
                            <Box height={50}/>

                            <Box
                                width={'100%'}
                                marginBottom={3}
                                marginTop={2}
                                display={'flex'}
                                justifyContent={'center'}
                            >
                                <Typography variant={'h4'}>
                                    Playerhunter admin specific
                                </Typography>
                            </Box>

                            <Box style={{padding: 30}}>
                                <AdminSection setJob={setJob} job={job}/>
                            </Box>
                        </React.Fragment>
                }

                <Box height={50}/>

                <Box
                    width={'100%'}
                    marginBottom={3}
                    marginTop={2}
                    display={'flex'}
                    justifyContent={'center'}
                >
                    <Typography variant={'h4'}>
                        Additional requirements
                    </Typography>
                </Box>

                <Box style={{padding: 30}}>
                    <StatisticSection setJob={setJob} job={job}/>
                </Box>

                <Box height={50}/>

                <Box
                    width={'100%'}
                    marginBottom={3}
                    marginTop={2}
                    display={'flex'}
                    justifyContent={'center'}
                >
                    <Typography variant={'h4'}>
                        Important to know
                    </Typography>
                </Box>

                <Box style={{padding: 30}}>
                    <ImportantToKnow setJob={setJob} job={job}/>
                </Box>

                <Box height={50}/>

                <Box
                    width={'100%'}
                    marginBottom={3}
                    marginTop={2}
                    display={'flex'}
                    justifyContent={'center'}
                >
                    <Typography variant={'h4'}>
                        Where to publish
                    </Typography>
                </Box>

                <Grid style={{padding: 30}} container spacing={3}>
                    <PublishJob setJob={setJob} job={job}/>
                </Grid>

                {/*<Box*/}
                {/*    display={'flex'}*/}
                {/*    justifyContent={'center'}*/}
                {/*    margin={5}*/}
                {/*>*/}
                {/*    <EButton*/}
                {/*        variant={'contained'}*/}
                {/*        color={'primary'}*/}
                {/*        size={'large'}*/}
                {/*        disabled={!canPublish}*/}
                {/*        onClick={handlePublish}*/}
                {/*    >*/}
                {/*        Publish*/}
                {/*    </EButton>*/}
                {/*</Box>*/}

            </Box>
        </Grid>
    )

    //endregion UI
}

export default Overview
