import Team from "../models/team.model";

export const generateTeam = (gender: string, levelId: number, teamId: number): Team => {
    let teamTypeName = '';
    // const id: number = 6 * (levelId - 1) + teamId + (gender === 'FEMALE' ? 3 : 0);
    switch (teamId) {
        case 1:
            teamTypeName = 'First Team';
            break;
        case 2:
            teamTypeName = 'Second Team';
            break;
        case 3:
            teamTypeName = 'Youth';
            break;
    }

    return {
        // id: -id,
        gender: gender,
        level: {
            id: levelId,
            value: levelId,
            subjectiveValue: levelId
        },
        teamType: {
            id: teamId,
            name: teamTypeName
        }
    };
};
