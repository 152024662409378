import gql from "graphql-tag";
import {
    AUTH_RESPONSE,
    CLUB_FULL_PRIVATE,
    MEDIA_SIMPLE,
    PAGINATION,
    PAYMENT, USER_ADMIN_INFO,
    USER_FULL,
    USER_PARENT_CONTACT_INFO,
    USER_PROFILE_FULL,
    USER_PUBLIC, USER_WITHOUT_PROFILE
} from "./queryFragments";

export const REGISTER_CLUB_ADMIN = gql`
    mutation registerAsClubAdmin($clubId: Long, $clubRequest: ClubRequestInput, $email: String!, $password: String!, $role: UserRole) {
        registerAsClubAdmin(clubId: $clubId, clubRequest: $clubRequest, email: $email, password: $password, role: $role){
            ${AUTH_RESPONSE}
        }
    }    
`;

export const LOGIN = gql`
    mutation login($email: String!, $password: String!) {
        login(email: $email, password: $password){
            ${AUTH_RESPONSE}
        }
    }    
`;

export const LOGIN_AS_CLUB = gql`
    mutation adminLoginAsClub($clubId: Long!) {
        adminLoginAsClub(clubId: $clubId){
            ${AUTH_RESPONSE}
        }
    }    
`;

export const LOGIN_AS_CLUB_REQUEST = gql`
    mutation adminLoginAsClubRequest($clubRequestId: Long!) {
        adminLoginAsClubRequest(clubRequestId: $clubRequestId){
            ${AUTH_RESPONSE}
        }
    }    
`;

export const RESET_PASSWORD = gql`
    mutation resetPassword($email: String!) {
        resetPassword(email: $email){
            ${AUTH_RESPONSE}
        }
    }    
`;

export const UPDATE_PASSWORD = gql`
    mutation updatePassword($login: UserLoginDTOInput!, $token: String!) {
        updatePassword(login: $login, token: $token){
            ${AUTH_RESPONSE}
        }
    }    
`;


export const GET_ME = gql`
    query {
        me {
            ${USER_FULL}
            externalId
            adminOf {
                ${CLUB_FULL_PRIVATE}
            }
            adminOfRequests {
                id
                name
                payments {
                    ${PAYMENT}
                }
            }
            canCreateOpportunities
            canSearchPlayers
        }
    }
`;

export const UPDATE_USER_PROFILE = gql`
    mutation userProfile($profile: UserProfileInput) {
        userProfile(profile: $profile){
            id
        }
    }    
`;

export const GET_USERS_ADMIN = gql`
    query users($filter: UserSpecificationInput, $paging: PageableInput) {
        users(filter: $filter, paging: $paging) {
            content{ 
                id
                email
                loginType
                profile {
                    createdAt
                    currentClub {
                        logo
                        name
                        location {
                            name
                            country {
                                name
                            }
                        }
                    }
                    currentClubRequest {
                        name
                    }
                    dateOfBirth
                    firstName
                    gender
                    lastName
                    location {
                        name
                        country {
                            name
                        }
                    }
                    mainPosition
                    secondaryPositions
                    profilePicture
                    verified
                    contactInfo {
                        email
                        phone
                    }
                }
                userType {
                    id
                }
                canCreateOpportunities
                canSearchPlayers
            }
            ${PAGINATION}
        }
    }
`;

export const GET_SUBUSERS_ADMIN = gql`
    query subUsers($filter: UserSpecificationInput, $paging: PageableInput) {
        subUsers(filter: $filter, paging: $paging) {
            content{ 
                ${USER_FULL}
            }
            ${PAGINATION}
        }
    }
`;

export const GET_USER = gql`
    query user($id: Long!) {
        user(id: $id) {
            ${USER_FULL}
            ${USER_PARENT_CONTACT_INFO}
        }
    }
`;

export const UPDATE_USER = gql`
    mutation user($user: UserInput) {
        user(user: $user) {
            ${USER_WITHOUT_PROFILE}
        }
    }
`;

export const GET_USER_ADMIN_INFO = gql`
    query userAdminInfo($id: Long!) {
        userAdminInfo(id: $id) {
            ${USER_ADMIN_INFO}
        }
    }
`;

export const UPDATE_USER_ADMIN_INFO = gql`
    mutation updateUserAdminInfo($id: Long!, $userAdminInfo: UserAdminInfoInput) {
        updateUserAdminInfo(id: $id, userAdminInfo: $userAdminInfo) {
            ${USER_ADMIN_INFO}
        }
    }
`;


export const ADMIN_UPDATE_PASSWORD = gql`
    mutation adminUpdatePassword($userId: Long!, $password: String!, $resetPasswordOnNextLogin: Boolean) {
        adminUpdatePassword(userId: $userId, password: $password, resetPasswordOnNextLogin: $resetPasswordOnNextLogin) {
            ${AUTH_RESPONSE}
        }
    }
`;

export const ADMIN_UPDATE_EMAIL = gql`
    mutation adminUpdateEmail($userId: Long!, $email: String!) {
        adminUpdateEmail(userId: $userId, email: $email) {
            ${AUTH_RESPONSE}
        }
    }
`;

export const UPDATE_PROFILE = gql`
    mutation userProfile($profile: UserProfileInput) {
        userProfile(profile: $profile) {
            ${USER_PROFILE_FULL}
        }
    }
`;


export const GET_PUBLIC_USER = gql`
    query publicProfile($id: Long) {
        publicProfile(id: $id) {
            ${USER_PUBLIC}
        }
    }
`;

export const UPDATE_PLAYER_SUBSCRIPTION = gql`
    mutation subscription($subscription: SubscriptionInput){
        subscription(subscription: $subscription){
            expirationDate
            id
            period
            subscriptionDate
            type
        }
    }
`

export const CHECK_EMAIL = gql`
    query checkEmail($email: String!) {
        checkEmail(email: $email){
            exist
        }
    }    
`;

export const SEND_NOTIFICATION = gql`
    mutation notification($type: NotificationStatus!, $user: UserInput!){
        notification(type: $type, user: $user)
    }
`
export const REGISTER_USER_VIA_EMAIL = gql`
    mutation register($email: String!, $firstName: String!, $lastName: String!, $password: String!, $type: UserTypeInput) {
        register(email: $email, firstName: $firstName, lastName: $lastName, password: $password, type: $type){
            id
            email
            firstName
            lastName
            loginType
            token
        }
    }    
`;

export const SOCIAL_LOGIN = gql`
    mutation socialLogin($clubId: Long, $clubRequest: ClubRequestInput, $socialLoginDTO: SocialLoginDTOInput, $type: UserTypeInput){
        socialLogin(clubId: $clubId, clubRequest: $clubRequest, socialLoginDTO: $socialLoginDTO, type: $type){  
                email
                firstName
                id
                lastName
                registered
                token
                loginType
                userType {
                    id
                }
                canCreateOpportunities
                canSearchPlayers
        }   
    }
`;

export const CREATE_SKILL_MEDIA = gql`
    mutation skillMedia ($skillMedia: SkillMediaInput) {
        skillMedia (skillMedia: $skillMedia) {
            id
            media {
                ${MEDIA_SIMPLE}
            }
            skill {
                id
                name
            }
            deleted
        }
    }
`;

export const DELETE_SKILL_MEDIA = gql`
    mutation deleteSkillMedia ($id: Long!) {
        deleteSkillMedia (id: $id) {
            id
            media {
                ${MEDIA_SIMPLE}
            }
            skill {
                id
                name
            }
            deleted
        }
    }
`;

export const REGISTER_SUBUSER = gql`
    mutation registerSubUserAdmin($parentId: Long!, $firstName: String!, $lastName: String!){
        registerSubUserAdmin(parentId: $parentId, firstName: $firstName, lastName: $lastName){
            id
        }
    }
`

export const REMOVE_SUBUSER = gql`
    mutation removeSubUser($userId: Long!){
        removeSubUser(userId: $userId){
            id
        }
    }
`