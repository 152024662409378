

const HelpPage = () => {

    const style = {
        section: {
            marginBottom: 40,
        },
        faq_section: {
            marginBottom: 5,
        },

    }


    return (
        <div style={{marginTop: '100px', padding: '10px'}}>
            <div style={{textAlign: "center", }}>
                <h1>Welcome to our support and page for Playerhunter</h1>
            </div>

            <div style={{marginBottom: 20, }}>
            <p>We're here to help you get the most out of our app, and to answer any questions or concerns you may have.</p>
            </div>
            <div style={style.section}>
            <p>Here are the support options available to you:</p>

            <ul><li>Contact our support team by email at <a href={"mailto:support@playerhunter.com"}><b>support@playerhunter.com</b></a>. We'll get back to you as soon as possible, typically within 24 hours. </li></ul>
            <ul><li>Check out our FAQs below for answers to common questions and issues.</li></ul>
            </div>
            <div style={style.section}>
                <p>If you have any feedback or suggestions for improving our app, please let us know!</p>
            </div>

            <div style={style.section}>
             <p>Thank you for choosing <b>Playerhunter</b>!</p>
            </div>


            <div style={style.faq_section}>
                <h1>FAQ (Players)</h1>
            </div>
            <div style={style.faq_section}>
                <h3>What is Playerhunter 2.0?</h3>
            </div>
            <div style={style.faq_section}>
                <p>Playerhunter 2.0 is a digital ''smart matching'' app that connects players and clubs precisely and quickly.</p>
            </div>
            <div style={style.faq_section}>
                <h3>How do I find a new club?</h3>
            </div>
            <div style={style.faq_section}>
                <p>Very easily. You create your own player card (i.e. your football CV) and can immediately start looking for a club. If you want to apply for a specific club, just click on the green button and your application will be automatically sent to the club.</p>
            </div>
            <div style={style.faq_section}>
                <h3>On which devices can I use the app?</h3>
            </div>
            <div style={style.faq_section}>
                <p>The Playerhunter app is currently available on Android and Iphone devices.</p>
            </div>
            <div style={style.faq_section}>
                <h3>Which Android version do I need to use the Playerhunter app?</h3>
            </div>
            <div style={style.faq_section}>
                <p>The minimum requirement to be able to use the Playerhunter app is Android Version 5.0.1 and IOS version 11.</p>
            </div>
            <div style={style.faq_section}>
                <h3>How can a potential new club contact me?</h3>
            </div>
            <div style={style.faq_section}>
                <p>As soon as an interested club "invites" you, you will receive a notification on your Android mobile phone. The association will automatically receive your mobile number electronically and will then contact you. You will either receive a call, SMS or Whatsapp message from the club.</p>
            </div>
            <div style={style.faq_section}>
                <h1>FAQ (Clubs)</h1>
            </div>
            <div style={style.faq_section}>
                <h3>As a club, how do I find new players?</h3>
            </div>
            <div style={style.faq_section}>
                <p>By simply entering your player search criteria via the web and ''putting a job online''. You will then receive notifications for all players who have applied for your job and select the applicants you find most interesting and contact them.</p>
            </div>
            <div style={style.faq_section}>
                <h3>On which devices can I search for new players?</h3>
            </div>
            <div style={style.faq_section}>
                <p>At the moment only via the web version! In the future you will of course also be able to easily search for players on your mobile phone via Android and iOS.</p>
            </div>
            <div style={style.faq_section}>
                <h3>How do I contact the player I want?</h3>
            </div>
            <div style={style.faq_section}>
                <p>You can call the players directly or send SMS and Whatsapp messages.</p>
            </div>
            <div style={style.faq_section}>
                <h3>What are the advantages of Playerhunter?</h3>
            </div>
            <div style={style.faq_section}>
                <p>You save a lot of time, money and energy and are no longer dependent on “players’ agents” etc. In addition, you can choose from a range of players that are interesting for you from the comfort of your own home and thus carry out potential transfers yourself.</p>
            </div>
            <div style={style.faq_section}>
                <h3>How does Playerhunter search and find the players relevant to me?</h3>
            </div>
            <div style={style.faq_section}>
                <p>Our ''Smart Matching App'' automatically connects you with the player you are looking for using an algorithm!</p>
            </div>
        </div>
    )
};

export default HelpPage;
