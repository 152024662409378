import React, {useState} from 'react';
import {Box, Chip, CircularProgress, Grid, Typography} from "@material-ui/core";
import GooglePlacesAutocomplete, {geocodeByPlaceId} from "react-google-places-autocomplete";
import ClubModel from "../../models/club.model";
import {GeocodeResultsRaw} from "../../models/geocodeResult.model";
import Team from "../../models/team.model";
import {readAndCompressImage} from "browser-image-resizer";
import CloudinaryService from "../../services/cloudinary.service";
import {generateTeam} from "../../util/team.util";
import ClubsService from "../../services/clubs.service";
import ETextField from "./ETextField.component";
import EButton from "./EButton";
import {makeStyles} from "@material-ui/core/styles";
import {getGridSizes} from "./grid.util";
import AddIcon from '@material-ui/icons/Add';
import EditTeamsPopup from "./EditTeamsPopup";
import {convertGeocodeResultsToGoogleGeolocationInput} from "../../util/util";

interface Props {
    onClubCreated: (club?: ClubModel) => void;
}

const useStyle = makeStyles(theme => ({
    input: {
        width: '100%'
    },
    logoHolder: {
        width: 100,
        height: 100,
        position: 'relative'
    },
    logo: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    logoEdit: {
        position: 'absolute',
        top: 0,
        right: '-18px',
        width: '18px',
        height: '18px',
        objectFit: 'contain',
        cursor: 'pointer'
    },
    uploadInputLabel: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    },
    uploadInputField: {
        visibility: 'hidden',
        width: 1,
        height: 1
    }
}))

const CreateClubForm = (props: Props) => {

    // ***** State ***** //

    const [geocodeResults, setGeocodeResults] = useState<GeocodeResultsRaw[]>();
    const [uploading, setUploading] = useState(false);
    const [logo, setLogo] = useState('');
    const [teamPopupOpen, setTeamPopupOpen] = useState(false);
    const [teams, setTeams] = useState<Team[]>([]);
    const [name, setName] = useState('');
    const classes = useStyle()

    // ***** Handlers ***** //

    const onChange = async (e: any) => {
        const files: File[] = Array.from(e.target.files);
        if (!files || !files.length) return;
        setUploading(true);

        let resizedImage = await readAndCompressImage(files[0], {
            quality: 0.8,
            maxWidth: 600,
            maxHeight: 600,
            mimeType: files[0].type
        });

        const cloudinaryResponse = await CloudinaryService.upload(resizedImage);
        const url = cloudinaryResponse.secure_url;

        setLogo(url);
        setUploading(false);
    };

    const handleTeamAdd = (gender: string, levelId: number, teamId: number) => {
        const team = generateTeam(gender, levelId, teamId);
        setTeams([...teams, team]);
        setTeamPopupOpen(false);
    };

    const deleteTeam = (team: Team) => {
        const newTeams = teams.filter(t => t !== team);
        setTeams(newTeams);
    };

    const createNewClubAndLink = async () => {
        const club: ClubModel = {
            name,
            logo,
            teams,
            // country,
            // city,
            googleGeolocation: convertGeocodeResultsToGoogleGeolocationInput(geocodeResults)
        };
        const createdClub = await ClubsService.createClub(club);
        props.onClubCreated(createdClub);
    };

    // ***** Render ***** //

    const nameUI = (
        <Grid container>
            <ETextField
                grid={'1/1'}
                onChange={(event) => setName(event)}
                value={name}
                mandatory
                label="Club name"
            />
        </Grid>
    );

    const locationUI = (
        <Grid container>
            <Grid item {...getGridSizes('1/1')}>
                {
                    geocodeResults?.length ?
                        <Box
                            display={{width: '100%'}}
                            onClick={() => setGeocodeResults([])}
                        >
                            {geocodeResults[0].formatted_address}
                        </Box>
                        :
                        <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_MAPS_KEY!}
                            debounce={1000}
                            selectProps={{
                                placeholder: 'Find city',
                                // value: getProfileCityCountryName(profile),
                                onChange: async (res: any) => {
                                    const results = await geocodeByPlaceId(res.value.place_id)
                                    setGeocodeResults(results as any);
                                }
                            }}
                        />
                }
            </Grid>
        </Grid>
    );

    const logoUI = (
        <Grid
            container
        >

            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                height={100}
                className={classes.logoHolder}
            >
                {
                    uploading
                        ?
                        <Box
                            className={classes.logo}
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <CircularProgress/>
                        </Box>
                        :
                        <img
                            alt={'Club logo'}
                            className={classes.logo}
                            src={logo || require('../../assets/images/fake-club.svg').default}
                        />
                }

                <label htmlFor='clubLogo' className={classes.uploadInputLabel}>
                </label>
                <input type='file' id='clubLogo'
                       className={classes.uploadInputField}
                       accept="image/bmp, image/jpeg, image/jpg, image/png"
                       onChange={onChange}/>

            </Box>

        </Grid>
    )

    const teamsUI = (
        <Grid
            container
            spacing={3}
        >

            {
                teams.map((team: Team) => {
                    return (
                        <Grid item>
                            <Chip
                                key={team.id}
                                label={`${team.gender}, ${team.teamType?.name}, L${team.level?.id}`}
                                onDelete={() => deleteTeam(team)}
                            />
                        </Grid>
                    )
                })
            }

            <Grid item>
                <EButton
                    onClick={() => setTeamPopupOpen(true)}
                >
                    <AddIcon/>
                    Add team
                </EButton>
            </Grid>

        </Grid>
    )

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            width={'100%'}
        >

            <Typography>Create a new club</Typography>

            {nameUI}

            <Box m={1}/>
            {locationUI}

            <Box m={2}/>
            {logoUI}

            <Box m={2}/>
            {teamsUI}

            <Box m={3}/>
            <EButton
                onClick={createNewClubAndLink}
                variant="contained"
                disabled={!name || !geocodeResults?.length || !teams.length || uploading}
                color="primary">
                Create club
            </EButton>

            <EditTeamsPopup
                open={teamPopupOpen}
                onClose={() => setTeamPopupOpen(false)}
                onSave={handleTeamAdd}
                existingTeams={teams}
            />

        </Box>
    )
};

export default CreateClubForm;
