import React, {FunctionComponent, useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from './AddAlbumsPopup.module.scss'
import {Box, Button, DialogActions, TextField} from '@material-ui/core';
import ClubModel from "../../../../../models/club.model";
import AlbumModel from "../../../../../models/album.model";
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean,
    history?: any,
    onCancel: () => void,
    club?: ClubModel,
    onConfirm: (name: string) => void,
    onThumbnail: (album: AlbumModel) => void,
}


const AddAlbumsPopup: FunctionComponent<Props> = (props: Props) => {

    // ***** State ***** //

    const [albumName, setName] = useState('');

    const t = useTranslation().t;

    // ***** Effects ***** //

    useEffect(() => {
        setName('');
    }, [props.open]);

    // ***** Render ***** //

    return (
        <Dialog aria-labelledby="simple-dialog-title" {...props} maxWidth={"xs"} fullWidth={true}>

            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                className={styles.container}
            >
                <TextField
                    onChange={(event) => setName(event.target.value)}
                    value={albumName}
                    className={styles.input}
                    label={t('album_name')}
                    margin="normal"
                    required
                />

            </Box>

            <DialogActions>
                <Button color="primary" onClick={props.onCancel}>
                    {t('cancel')}
                </Button>
                <Button color="primary" onClick={() => props.onConfirm(albumName)}
                        disabled={!albumName || !albumName.trim()}>
                    {t('confirm')}
                </Button>
            </DialogActions>

        </Dialog>
    );
};


export default AddAlbumsPopup;
