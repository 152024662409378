import ClubProfile from "./preview/ClubProfile";
import {useUser} from "../../store/store.utils";
import {useEffect, useState} from "react";
import ClubModel from "../../models/club.model";
import ClubService from "../../services/club.service";
import {useParams} from "react-router";
import {CircularProgress} from "@material-ui/core";

const ClubPublicPreview = () => {

    const [club, setClub] = useState<ClubModel>()
    const [loading, setLoading] = useState(false)
    const user = useUser()
    const params = useParams<{ id?: string }>()

    const fetchClub = async () => {
        setLoading(true)
        const response = await ClubService.getClub(+params.id!, true, false)
        setClub(response)
        setLoading(false)
    }

    useEffect(() => {
        fetchClub()
    }, [params.id])

    if (loading) {
        return <CircularProgress />
    }

    return (
        <ClubProfile
            club={club}
            showPublicCTA={!user}
        />
    )
}

export default ClubPublicPreview