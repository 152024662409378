import {LOGOUT, SET_PROFILE_USER, SET_USER} from "../actions/actionTypes"
import UserModel from "../../models/user.model";

export interface ProfileState {
    user?: UserModel
}

const initialState: ProfileState = {}

const profileReducer = (state = initialState, action: any): ProfileState => {
    switch (action.type) {
        case SET_PROFILE_USER:
            return {
                ...state,
                user: action.payload
            }
        case SET_USER:
            if (action.payload?.id === state.user?.id) {
                return {
                    ...state,
                    user: action.payload
                }
            } else {
                return state
            }
        case LOGOUT:
            return {
                ...initialState
            }
    }
    return state
}

export default profileReducer