import React, {useEffect, useState} from 'react';
import ClubModel from "../../../../models/club.model";
import styles from './ClubLocation.module.scss';
import EditClubNotPossiblePopup from "../EditClubHeaderAndLocationPopup/EditClubNotPossiblePopup";
import clsx from 'clsx';
import UserModel from "../../../../models/user.model";
import GoogleMapReact from 'google-map-react';

import {useTranslation} from "react-i18next";
import {Box, Card, CircularProgress} from "@material-ui/core";
import GooglePlacesAutocomplete, {geocodeByPlaceId} from "react-google-places-autocomplete";
import {
    getClubLocation,
    getClubLocationGeo,
    getClubLocationName,
    isUserApprovedAndClubApproved,
    isUserClubAdmin
} from "../../../../util/profile.util";
import {convertGeocodeResultsToGoogleGeolocationInput} from "../../../../util/util";
import {GeocodeResultsRaw} from "../../../../models/geocodeResult.model";
import {mapStyleGrey} from "../../../../util/map.style";

interface Props {
    club?: ClubModel;
    user?: UserModel;
    onUpdate?: (club: ClubModel) => void;
}

const ClubLocation = (props: Props) => {

    // ***** State ***** //

    const [notAllowedPopupOpen, setNotAllowedPopupOpen] = useState(false);
    const [geocodeState, setGeocodeState] = useState<'edit' | 'save' | 'none'>('none');
    const [map, setMap] = useState();
    const [marker, setMarker] = useState<any>();
    // const [countryPopupOpen, setCountryPopupOpen] = useState(false);
    // const [cityPopupOpen, setCityPopupOpen] = useState(false);
    // const [country, setCountry] = useState<CountryModel>();

    const t = useTranslation().t;

    const drawMarker = () => {
        marker?.setMap(null);

        try {
            setMarker(new google.maps.Marker({
                map,
                position: getClubLocationGeo(props.club)
            }));
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        drawMarker();
    }, [props.club, map]);

    // ***** Handler ***** //

    const closeHandler = () => {
        setNotAllowedPopupOpen(false);
    };

    const editHandler = () => {
        if (!isUserApprovedAndClubApproved(props.user, props.club)) {
            setNotAllowedPopupOpen(true);
        } else {
            setGeocodeState('edit');
        }
    };

    // const onCountryPick = (country: any) => {
    //     setCountryPopupOpen(false);
    //     setCityPopupOpen(true);
    //     setCountry(country);
    //
    //     const club = {
    //         ...props.club,
    //         country: {
    //             id: country.id,
    //             name: country.name
    //         }
    //     };
    //
    //     props.onUpdate?.(club);
    // };

    // const onCityPick = (city: any) => {
    //     setCityPopupOpen(false);
    //
    //     const club = {
    //         ...props.club,
    //         city: {
    //             id: city.id,
    //             name: city.name
    //         }
    //     };
    //
    //     props.onUpdate?.(club);
    // };

    const onLocationPick = async (geocodeResults: GeocodeResultsRaw[]) => {
        setGeocodeState('save');
        const club = {
            ...props.club,
            city: undefined,
            country: undefined,
            googleGeolocation: convertGeocodeResultsToGoogleGeolocationInput(geocodeResults)
        }

        await props.onUpdate?.(club);
        setGeocodeState('none');
    }

    // ***** Render ***** //

    const renderLocation = () => {
        if (geocodeState === 'save') {
            return (
                <CircularProgress/>
            )
        }

        if (geocodeState === 'edit') {
            return (
                <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_MAPS_KEY!}
                    debounce={1000}
                    selectProps={{
                        value: getClubLocation(props.club),
                        onChange: async (res: any) => {
                            const results = await geocodeByPlaceId(res.value.place_id)
                            onLocationPick(results as any);
                        }
                    }}
                />
            )
        }

        return (
            <p className={styles.location}>{getClubLocationName(props.club)}</p>
        )
    }

    return (
        <Card className={styles.component}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}
            >
                <Box
                    alignItems={'center'}
                    display={'flex'}
                    width={'100%'}
                    justifyContent={'space-between'}
                >
                    <p className={styles.sectionTitle}>{t('location')}</p>

                    {
                        isUserClubAdmin(props.user, props.club) &&
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            className={styles.editHolder}
                            onClick={editHandler}
                        >
                            <img alt={'Edit'} src={require('../../../../assets/images/edit-orange.svg').default}/>
                        </Box>
                    }
                </Box>

                {renderLocation()}

                {
                    getClubLocationGeo(props.club) && geocodeState === 'none' &&
                    <div className={styles.map}>
                        <GoogleMapReact
                            bootstrapURLKeys={{key: process.env.mapsKey!}}
                            defaultCenter={getClubLocationGeo(props.club)}
                            center={getClubLocationGeo(props.club)}
                            defaultZoom={7}
                            options={{styles: mapStyleGrey}}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({map}) => setMap(map)}
                        />
                    </div>
                }

                {/*<img src={LocationMap} className={styles.map}/>*/}

                {/*<CountrySearch*/}
                {/*open={countrySearchOpen}*/}
                {/*onCountryPicked={handleAddNationality}*/}
                {/*onClose={() => setState({countrySearchOpen: false})}*/}
                {/*title={lcl('select_nationality')}*/}
                {/*/>*/}


                <EditClubNotPossiblePopup open={notAllowedPopupOpen} onClose={closeHandler}/>

                {/*<CountrySearch open={countryPopupOpen} onCountryPicked={onCountryPick}*/}
                {/*               onClose={() => setCountryPopupOpen(false)}/>*/}

                {/*<CitySearch country={country} open={cityPopupOpen} onCityPicked={onCityPick}*/}
                {/*            onClose={() => setCityPopupOpen(false)}/>*/}
            </Box>
        </Card>
    );
}

export default ClubLocation;
