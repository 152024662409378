import gql from "graphql-tag";
import {
    CITY_FULL,
    CLUB_FULL,
    CLUB_FULL_PRIVATE,
    COUNTRY,
    opportunityBuilder,
    PAGINATION,
    TEAM,
    USER_FULL,
    USER_PARENT_CONTACT_INFO
} from "./queryFragments";

export const APPLICATION = `
id
status
createdBy {
    ${USER_FULL}
    ${USER_PARENT_CONTACT_INFO}
}
createdAt
score
relevance
contacted
`;

export const APPLICATION_WITH_JOB =
`
${APPLICATION}
opportunity {
    id
}
`

export const GET_OPPORTUNITIES = gql`
    query club ($id: Long!) {
        club (id: $id){
            id
            opportunities {
                content{ 
                    ${opportunityBuilder(CLUB_FULL, CITY_FULL, COUNTRY, TEAM)}
                }
                ${PAGINATION}
            }
        }
    }
`;

export const GET_OPPORTUNITIES_TOTAL = gql`
    query club ($id: Long!) {
        club (id: $id){
            id
            opportunities {
                totalElements
            }
        }
    }
`;

export const GET_OPPORTUNITIES_FOR_CLUB_REQUEST = gql`
    query clubRequest($clubRequestId: Long!) {
        clubRequest(clubRequestId: $clubRequestId){
            id
            opportunities {
                content{ 
                    id
                    applicants {
                        totalElements
                    }
                    club {
                        id
                        name
                        logo
                    }
                    clubRequest {
                        id
                        name
                    }
                    positions
                    fromAge
                    toAge
                    opportunityStatus
                }
                ${PAGINATION}
            }
        }
    }
`;

export const GET_TOTAL_OPPORTUNITIES_FOR_CLUB_REQUEST = gql`
    query clubRequest($clubRequestId: Long!) {
        clubRequest(clubRequestId: $clubRequestId){
            id
            opportunities {
                totalElements
            }
        }
    }
`;

export const GET_ADMIN_OPPORTUNITIES = gql`
    query opportunities($filter: OpportunitySpecificationInput, $paging: PageableInput) {
        opportunities(filter: $filter, paging: $paging) {
            content{ 
                ${opportunityBuilder(CLUB_FULL_PRIVATE, CITY_FULL, COUNTRY, TEAM)}
            }
            ${PAGINATION}
        }
    }
`;

export const GET_OPPORTUNITY_NOTIFIABLE_USERS = gql`
    query opportunityNotifiableUsers($opportunityId: Long!, $paging: PageableInput, $userIds: [Long], $areDisqualifiedFiltered: Boolean) {
        opportunityNotifiableUsers(opportunityId: $opportunityId, paging: $paging, userIds: $userIds, areDisqualifiedFiltered: $areDisqualifiedFiltered) {
            content{ 
                ${USER_FULL}
            }
            ${PAGINATION}
        }
    }
`;

export const GET_OPPORTUNITY = gql`
    query opportunity($id: Long!) {
        opportunity(id: $id) { 
            ${opportunityBuilder(CLUB_FULL, CITY_FULL, COUNTRY, TEAM)}
        }
    }
`;

export const CREATE_OPPORTUNITY_QUERY = gql`
    mutation createOpportunity($opportunity: OpportunityInput) {
        createOpportunity(opportunity: $opportunity) {
            ${opportunityBuilder(CLUB_FULL, CITY_FULL, COUNTRY, TEAM)}
        }
    }
`;

export const GET_APPLICANTS_FILTER = gql`
    query applications($opportunityId: Long!, $paging: PageableInput, $status: ApplicationStatus) {
        applications(opportunityId: $opportunityId, paging: $paging, status: $status) {
            content {
                ${APPLICATION}
            }
            ${PAGINATION}
        }
    }
`;

export const GET_APPLICANTS_VIA_OPPORTUNITY = gql`
    query opportunity($id: Long!, $paging: PageableInput) {
        opportunity(id: $id) {
            applicants(paging: $paging) {
                content {
                    ${APPLICATION}
                }
                ${PAGINATION} 
            } 
        }
    }
`;

export const GET_APPLICANTS_NUMBER = gql`
    query applications($opportunityId: Long!, $paging: PageableInput) {
        applications(opportunityId: $opportunityId, paging: $paging) { 
            totalElements
        }
    }
`;

export const SHORTLIST_APPLICANT = gql`
    query shortlistApplicant($opportunityApplicationId: Long!) {
        shortlistApplicant(opportunityApplicationId: $opportunityApplicationId) {
            ${APPLICATION_WITH_JOB}
        }  
    }
`;

export const INVITE_APPLICANT = gql`
    query inviteApplicant ($opportunityApplicationId: Long!){
        inviteApplicant(opportunityApplicationId: $opportunityApplicationId) {
            ${APPLICATION_WITH_JOB}
        }  
    }
`;

export const REMOVE_APPLICANT_FROM_SHORTLIST = gql`
    query removeApplicantFromShortList($opportunityApplicationId: Long!) {
        removeApplicantFromShortList(opportunityApplicationId: $opportunityApplicationId) {
            ${APPLICATION_WITH_JOB}
        }  
    }
`;

export const REJECT_APPLICANT = gql`
    query rejectApplicant($opportunityApplicationId: Long!) {
        rejectApplicant(opportunityApplicationId: $opportunityApplicationId) {
            ${APPLICATION_WITH_JOB}
        }  
    }
`;

export const UPDATE_RELEVANCE = gql`
    mutation relevance($opportunityApplicationId: Long!, $relevance: ApplicationRelevance!) {
        relevance(opportunityApplicationId: $opportunityApplicationId, relevance: $relevance) {
            ${APPLICATION_WITH_JOB}
        }  
    }
`;

export const UPDATE_CONTACTED_STATE = gql`
    mutation contacted($contacted: Boolean!, $opportunityApplicationId: Long!) {
        contacted(contacted: $contacted, opportunityApplicationId: $opportunityApplicationId) {
            ${APPLICATION_WITH_JOB}
        }  
    }
`;

export const GET_SINGLE_APPLICATION = gql`
    query application($id: Long) {
        application(id: $id) {
            ${APPLICATION_WITH_JOB}
        }  
    }
`;

export const REMOVE_APPLICATION_FOR_OPPORTUNITY = gql`
    mutation removeApplicationForOpportunity($id: Long) {
        removeApplicationForOpportunity(id: $id) {
            ${APPLICATION_WITH_JOB}
        }  
    }
`;

