import Job from "../models/job.model";

export interface OpportunityAlgoData {

    opportunity?: Job;

    disqualifiedOnAge?: boolean;
    disqualifiedOnLevel?: boolean;
    disqualifiedOnLocation?: boolean;
    disqualifiedOnClub?: boolean;
    disqualifiedOnGender?: boolean;
    disqualifiedOnAppliedOrSkipped?: boolean;
    disqualifiedOnPosition?: boolean;
    disqualifiedOnVerification?: boolean;

    positionPoints?: number;
    footPoints?: number;
    levelPoints?: number;
    heightPoints?: number;
    nationalityPoints?: number;
    languagePoints?: number;

    calculatedDistance?: number;
}

export const isDisqualified = (data: OpportunityAlgoData) => {
    return data.disqualifiedOnAge ||
        data.disqualifiedOnLevel ||
        data.disqualifiedOnLocation ||
        data.disqualifiedOnClub ||
        data.disqualifiedOnGender ||
        data.disqualifiedOnAppliedOrSkipped ||
        data.disqualifiedOnPosition ||
        data.disqualifiedOnVerification;
};

export const totalPoints = (data: OpportunityAlgoData) => {
    return (data.positionPoints || 0) +
        (data.footPoints || 0) +
        (data.levelPoints || 0) +
        (data.heightPoints || 0) +
        (data.nationalityPoints || 0) +
        (data.languagePoints || 0)
};
