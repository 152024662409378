import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from './PickClubPopup.module.scss'
import {Box, Button, CircularProgress, DialogActions, TextField} from '@material-ui/core';
import debounce from "lodash/debounce";
import ReactPaginate from 'react-paginate';
import ClubModel from "../../../models/club.model";
import {useTranslation} from "react-i18next";
import ClubsService from "../../../services/clubs.service";
import _ from 'lodash';
import ClubBadge from "../ClubBadge/ClubBadge";

interface Props {
    open: boolean;
    onClose: () => void;
    onPick: (club: ClubModel) => void;
}

const PickClubPopup: FunctionComponent<Props> = (props: Props) => {

    const [query, setQuery] = useState('');
    const [clubs, setClubs] = useState<ClubModel[]>([]);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);

    const t = useTranslation().t;

    const getClubs = async (paging: any, query: string) => {
        setClubs([]);
        setLoading(true);
        try {
            const result = await ClubsService.getClubs(paging, query);
            setClubs(_.cloneDeep(result?.content || []));
            setTotalPages(result.totalPages || 0)
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    const getClubsDebounced = useRef(debounce((paging: any, name: string) => getClubs(paging, name), 500)).current;

    useEffect(() => {
        if (props.open) {
            setLoading(true)
            setQuery('');
            getClubsDebounced({page: 0, size: 12}, '');
        }

    }, [props.open]);

    const renderClubs = () => {
        return clubs.slice(0, 12).map(club => {
            return (
                <Box display={'flex'} className={styles.clubBadge}>
                    <ClubBadge club={club} clicked={() => props.onPick(club)}/>
                </Box>
            )
        })
    };

    const queryHandler = (event: any) => {
        setQuery(event.target.value);
        getClubsDebounced({page: 0, size: 12}, event.target.value);
    };

    // @ts-ignore
    const handlePageChange = (data: any) => {
        getClubsDebounced({page: data.selected, size: 12}, query);
    };

    return (
        <Dialog aria-labelledby="simple-dialog-title" {...props} maxWidth={"lg"} fullWidth={true}>

            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                className={styles.container}
            >

                <Box display={'flex'} flexWrap={'wrap'} className={styles.resolveContainer}>

                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        className={styles.resolveWithExistingContainer}
                    >

                        <div>Select an existing club</div>

                        <TextField
                            onChange={queryHandler}
                            value={query}
                            className={styles.searchField}
                            id="outlined-search"
                            label={t("type_club_name")}
                            type="search"
                            margin="normal"
                            // variant="outlined"
                        />

                        <Box
                            flexWrap={'wrap'}
                            className={styles.clubsHolder}
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'center'}
                        >
                            {
                                loading
                                    ?
                                    <CircularProgress/>
                                    :
                                    renderClubs()
                            }
                        </Box>

                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={totalPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName={styles.pagination}
                            // subContainerClassName={styles.paginationSubContainer}
                            activeClassName={styles.paginationActive}
                        />

                    </Box>

                </Box>


            </Box>

            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Close
                </Button>
                {/*<Button onClick={() => props.onSave(text)} color="primary" autoFocus>*/}
                {/*Save*/}
                {/*</Button>*/}
            </DialogActions>
        </Dialog>
    );
};


// **** Redux **** //

export default PickClubPopup;
