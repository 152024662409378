import React from 'react'
import {Box, Grid, Slider, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from "react-i18next";
import Job, {SENIOR_MAX_AGE, SENIOR_MIN_AGE, YOUTH_MAX_AGE, YOUTH_MIN_AGE} from "../../../../models/job.model";
import Team from "../../../../models/team.model";
import GenderSelector from "../GenderSelector.component";
import {generateTeam} from "../../../../util/team.util";
import clsx from "clsx";
import Position from "../../../common/Position/Position";
import _ from "lodash";
import ESwitchField from "../../../common/ESwitchField.component";
import ETextField from "../../../common/ETextField.component";

interface Props {
    job?: Job,
    setJob: (job: Job) => void,
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
    input: {
        width: '100%'
    },
    timeline: {
        // transform: "rotate(90deg)",
        // width: 100,
        height: '100%'
    },
    timelineContentContainer: {
        // textAlign: "left",
        flex: 1
    },
    timelineContent: {
        // display: "inline-block",
        // transform: "rotate(-90deg)",
        // textAlign: "center",
        minWidth: 100
    },
    timelineIcon: {
        // transform: "rotate(-90deg)",
        width: 20,
        height: 20
    },
    team: {
        background: '#FAFAFA',
        borderRadius: 16,
        marginTop: 5,
        marginBottom: 5,
        marginRight: 10,
        border: '2px solid transparent',
        cursor: 'pointer',
        padding: 10,
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.57)'
    },
    teamSelected: {
        background: theme.palette.primary.main,
        color: 'white'
    },
    slider: {
        marginLeft: 2,
        width: '100%',
        maxWidth: '100%'
    },
    sliderMark: {
        width: 24,
        textAlign: 'center',
        fontSize: 12
    },
    sliderMarkLeft: {
        marginRight: 20
    },
    sliderMarkRight: {
        marginLeft: 20
    },
    sliderTop: {
        width: '100% important!'
    },
    option: {
        width: 95,
        height: 50,
        cursor: 'pointer',
        borderBottom: '2px solid #FBFBFB',
        color: 'rgba(0, 0, 0, 0.61)',
        fontSize: '14px',
        background: '#FBFBFB',
        textAlign: 'center'
    },
    genderImg: {
        width: 14,
        height: 14
    },
    selected: {
        //background: linear-gradient(169deg, #e40015 0%, #750075 100%);
        borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    sliderTeamMark: {
        backgroundColor: theme.palette.primary.main,
        width: 3,
        height: 13,
        marginTop: -5,
        marginLeft: -1,
        borderRadius: 2
    },
    radioLabel: {
        fontSize: '14px !important'
    },
    selectLocationCTA: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginTop: 20,
        zIndex: 1
    }
}))

const BasicInfo = ({job, setJob}: Props) => {

    const classes = useStyle()
    const {t, i18n} = useTranslation()
    const youthTeam = job?.teams?.[0]?.teamType?.id === 3;

    const updateFields = (data: { field: string, value: any }[]) => {
        const newJob: any = _.cloneDeep(job)
        data.forEach(d => newJob[d.field] = d.value)
        setJob(newJob)
    }

    const updateField = (field: string, value: any) => {
        updateFields([{field, value}])
    }


    // ***** Handlers ***** //


    const teamHandler = (team: Team) => {
        let age = [job?.fromAge, job?.toAge]
        if (team.teamType?.id === 3) {
            let fromAge = job?.fromAge || YOUTH_MIN_AGE;
            if (fromAge > YOUTH_MAX_AGE) {
                fromAge = YOUTH_MIN_AGE
            }

            let toAge = job?.toAge || YOUTH_MAX_AGE;
            if (toAge > YOUTH_MAX_AGE) {
                toAge = YOUTH_MAX_AGE
            }

            age = [fromAge, toAge];
        }

        if (team.teamType?.id === 1 || team.teamType?.id === 2) {
            let fromAge = job?.fromAge || SENIOR_MIN_AGE;
            if (fromAge < SENIOR_MIN_AGE) {
                fromAge = SENIOR_MIN_AGE
            }

            let toAge = job?.toAge || SENIOR_MAX_AGE;
            if (toAge <= fromAge) {
                toAge = SENIOR_MAX_AGE
            }

            age = [fromAge, toAge];
        }

        const teamLevel = team.level?.value || 5;
        const newMinLevel = Math.max(teamLevel - 2, 1);
        let newMaxLevel;

        if (teamLevel === 1) {
            newMaxLevel = 2
        } else {
            newMaxLevel = Math.min(teamLevel + 2, 15);
        }

        updateFields([
            {field: 'fromAge', value: age[0]},
            {field: 'toAge', value: age[1]},
            {field: 'teams', value: [team]},
            {field: 'fromLevel', value: newMinLevel},
            {field: 'toLevel', value: newMaxLevel},
        ])

    };

    const leagueLevelSliderHandler = (_: any, newValue: any) => {
        updateFields([
            {field: 'fromLevel', value: newValue[0]},
            {field: 'toLevel', value: newValue[1]},
        ])
    };

    const ageSliderHandler = (_: any, newValue: any) => {
        updateFields([
            {field: 'fromAge', value: newValue[0]},
            {field: 'toAge', value: newValue[1]},
        ])
    };


    const positionModeHandler = (mode: string) => {
        updateField('positionMode', mode)
    };

    const positionClickHandler = (position: string) => {
        const newPositions = [position];

        // const newPositions = [...props.jobs.positions];
        // const positionIndex = newPositions.findIndex(p => p === position);
        // if (positionIndex === -1) {
        //     if (newPositions.length < 3) {
        //         newPositions.push(position);
        //     }
        // } else {
        //     newPositions.splice(positionIndex, 3);
        // }

        updateField('positions', newPositions)
    };

    const sectionClickHandler = (section: string) => {
        updateField('section', section)
    };


    //endregion Handlers

    //region UI

    const genderUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>Gender</Typography>
                </Grid>
                <Grid item xs={8}>
                    <GenderSelector gender={job?.gender}
                                    onGenderSelect={gender => updateField('gender', gender)}/>
                </Grid>
            </Grid>
        </Grid>
    )

    const renderTeams = () => {
        let teams = job?.club?.teams || [];
        teams = teams.filter(team => team.gender === job?.gender);
        if (!teams?.length) {
            teams = [
                generateTeam(job?.gender || 'MALE', 5, 1),
                generateTeam(job?.gender || 'MALE', 5, 2),
                generateTeam(job?.gender || 'MALE', 5, 3)
            ]
        }
        teams.sort((a, b) => (a.teamType?.id || 0) - (b.teamType?.id || 0));

        return teams.map(team => {
            const isTeamSelected = team.gender === job?.teams?.[0]?.gender &&
                team.teamType?.id === job?.teams?.[0]?.teamType?.id

            return (
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    key={team.id}
                    className={clsx(classes.team, isTeamSelected && classes.teamSelected)}
                    onClick={() => teamHandler(team)}
                >
                    {/*<p>{lcl("team_1")}</p>*/}
                    <p>{t(team.teamType?.name || 'Unknown')}</p>
                </Box>
            )

        });
    }

    const teamsUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>{t("which_of_your_team")}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box
                        display={'flex'}
                    >
                        {renderTeams()}
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )

    const ageUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>{t("age")}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        className={classes.slider}
                    >
                        <p className={clsx(classes.sliderMark, classes.sliderMarkLeft)}>{job?.fromAge}</p>
                        <Slider
                            className={classes.sliderTop}
                            onChange={ageSliderHandler}
                            value={[job?.fromAge || (youthTeam ? YOUTH_MIN_AGE : SENIOR_MIN_AGE), job?.toAge || (youthTeam ? YOUTH_MAX_AGE : SENIOR_MAX_AGE)]}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            min={youthTeam ? YOUTH_MIN_AGE : SENIOR_MIN_AGE}
                            max={youthTeam ? YOUTH_MAX_AGE : SENIOR_MAX_AGE}
                            step={1}
                        />
                        <p className={clsx(classes.sliderMark, classes.sliderMarkRight)}>{job?.toAge}</p>
                    </Box>
                </Grid>
            </Grid>

        </Grid>
    )

    const team = job?.teams?.[0];
    const teamLevel = team?.level?.value || 5;
    const teamName = team ? t(team.teamType?.name || '-') : '';

    const marks = [];
    if (team) {
        marks.push({
            value: teamLevel,
            label: teamName
        })
    }

    const levelUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>{t("level")}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        className={classes.slider}
                    >
                        <p className={clsx(classes.sliderMark, classes.sliderMarkLeft)}>{job?.fromLevel}</p>
                        <Slider
                            className={classes.sliderTop}
                            classes={{
                                mark: classes.sliderTeamMark
                            }}
                            onChange={leagueLevelSliderHandler}
                            value={[job?.fromLevel || 1, job?.toLevel || 15]}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            min={1}
                            max={15}
                            step={1}
                            marks={marks}
                        />
                        <p className={clsx(classes.sliderMark, classes.sliderMarkRight)}>{job?.toLevel}</p>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )

    const instatIndexUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>{t("Min Instat index value")}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        className={classes.slider}
                    >
                        <p className={clsx(classes.sliderMark, classes.sliderMarkLeft)}>{0}</p>
                        <Slider
                            className={classes.sliderTop}
                            onChange={(_: any, newValue: any) => {
                                updateField('minInstatIndex', newValue)
                            }}
                            value={job?.minInstatIndex || 0}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            min={0}
                            max={900}
                            step={1}
                        />
                        <p className={clsx(classes.sliderMark, classes.sliderMarkRight)}>{900}</p>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )

    const phIndexUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>{t("Min Playerhunter index value")}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        className={classes.slider}
                    >
                        <p className={clsx(classes.sliderMark, classes.sliderMarkLeft)}>{0}</p>
                        <Slider
                            className={classes.sliderTop}
                            onChange={(_: any, newValue: any) => {
                                updateField('minPhIndex', newValue)
                            }}
                            value={job?.minPhIndex || 0}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            min={0}
                            max={1500}
                            step={1}
                        />
                        <p className={clsx(classes.sliderMark, classes.sliderMarkRight)}>{1500}</p>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )

    const minTrasnferMarktValueUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>{t("Min Transfermarkt value")}</Typography>
                </Grid>
                <ETextField
                    grid={'2/3'}
                    value={job?.minTmValue}
                    onChange={e => updateField('minTmValue', e !== '' ? parseInt(e.replace(/[^0-9]/g, '')) : undefined)}
                />
            </Grid>
        </Grid>
    )

    const anonymousUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>{t("Anonymous")}</Typography>
                </Grid>
                <ESwitchField
                    value={job?.anonymous}
                    onChange={event => updateField('anonymous', event)}
                    grid={'1/3'}
                />
            </Grid>
        </Grid>
    )

    const basicLeftSide = (
        <Grid item xs={6}>

            <Grid container spacing={6}>
                {genderUI}
                {teamsUI}
                {ageUI}
                {levelUI}
                {instatIndexUI}
                {phIndexUI}
                {minTrasnferMarktValueUI}
                {anonymousUI}
            </Grid>

        </Grid>
    )

    const positionUI = (
        <Box style={{padding: 10, paddingBottom: 20}} display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
            >
                <Typography>{t("position")}</Typography>

                <Box
                    marginLeft={3}
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        className={[classes.option, 'genderHover', job?.positionMode === 'positions' ? classes.selected : ''].join(' ')}
                        onClick={() => positionModeHandler('positions')}
                    >
                        <p>{t("specific_position")}</p>
                    </Box>

                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        className={[classes.option, 'genderHover', job?.positionMode === 'section' ? classes.selected : ''].join(' ')}
                        onClick={() => positionModeHandler('section')}
                    >
                        <p>{t("general_position")}</p>
                    </Box>
                </Box>
            </Box>


            <Box
                display={'flex'}
                justifyContent={'center'}
            >
                <Box width={350} height={400}>
                    <Position selectedSecondary={job?.positions}
                              selectedSection={job?.section}
                              mode={job?.positionMode}
                              sectionClickHandler={sectionClickHandler}
                        // classes={props.opportunity.positionMode === 'positions' && isOnFreePackage(props.club) && !isAdmin(props.user) ? styles.blurred : ''}
                              positionClickHandler={positionClickHandler}/>

                </Box>
            </Box>

        </Box>
    );

    const basicRightSide = (
        <Grid item xs={6}>
            {positionUI}
        </Grid>
    )


    return (
        <Grid container spacing={4}>
            {basicLeftSide}
            {basicRightSide}
        </Grid>
    )

    //endregion UI
}

export default BasicInfo
