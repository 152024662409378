import React, {FunctionComponent} from 'react';
import styles from './PreviewVideoPopup.module.scss';
import {Box} from "@material-ui/core";

interface Props {
    open: boolean,
    video?: string,
    onClose: () => void,
    onMoveToNext?: () => void,
    onMoveToPrev?: () => void,
}

const PreviewVideoPopup: FunctionComponent<Props> = (props: Props) => {

    if (!props.open) {
        return null;
    }

    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            className={styles.container}
        >

            <div className={styles.innerHolder}>

                {
                    props.onMoveToNext &&
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        className={styles.navigationLeft}
                        onClick={props.onMoveToPrev}

                    >
                        <div className={styles.navigationArrowPrev}>〈</div>
                    </Box>
                }

                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    className={styles.contentHolder}
                >
                    <video autoPlay className={styles.content} controls>
                        <source src={props.video}/>
                    </video>
                </Box>


                {
                    props.onMoveToNext &&
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        className={styles.navigationRight}
                        onClick={props.onMoveToNext}
                    >
                        <div className={styles.navigationArrowNext}>〉</div>
                    </Box>
                }

                <div className={styles.close} onClick={props.onClose}>×</div>

            </div>

        </Box>
    );
};


export default PreviewVideoPopup;
