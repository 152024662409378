import apollo, {createApolloClient, createAuthLinkExplicit} from "./api/apollo";
import {REQUEST_HOF} from "./api/clubQueries";
import {SubscriptionDuration, SubscriptionModel, SubscriptionType} from "../models/subscription.model";
import ClubModel from "../models/club.model";
import {
    GET_STRIPE_CUSTOMER,
    GET_STRIPE_INVOICE,
    GET_STRIPE_INVOICES,
    GET_STRIPE_PAYMENT_METHODS,
    GET_STRIPE_PRODUCTS,
    GET_STRIPE_PROMO_CODES,
    GET_STRIPE_SUBSCRIPTION,
    PAY_STRIPE_INVOICE,
    PURCHASE_SUBSCRIPTION,
    REMOVE_STRIPE_PAYMENT_METHOD,
    SET_COUPON,
    SET_DEFAULT_PAYMENT_METHOD,
    STRIPE_SETUP_INTENT,
    UPGRADE_SUBSCRIPTION
} from "./api/subscription.query";
import {StripeProduct, StripeProductPrice} from "../models/StripeModels/stripeProduct.model";
import {StripeSetupIntent} from "../models/StripeModels/stripeSetupIntent.model";
import {PaymentMethodModel} from "../models/StripeModels/paymentMethod.model";
import {StripeInvoiceModel} from "../models/StripeModels/stripeInvoice.model";
import {
    StripeCustomerModel,
    StripeDiscountModel,
    StripePromoCodeModel
} from "../models/StripeModels/stripeCustomer.model";
import {StripeSubscription} from "../models/StripeModels/stripeSubscription.model";


const PAYMENT_DURATION_ID_TO_ENUM = {
    1: SubscriptionDuration.S_12_MONTHS,
    2: SubscriptionDuration.S_24_MONTHS,
};

export default class SubscriptionService {

    static convertToWeb = (apiPayment: any): SubscriptionModel => {
        return {
            ...apiPayment,
            paymentFrom: apiPayment.paymentFrom && new Date(apiPayment.paymentFrom),
            paymentTo: apiPayment.paymentTo && new Date(apiPayment.paymentTo),
            paymentType: apiPayment.paymentPackage || SubscriptionType.BASIC,
            paymentDuration: apiPayment?.paymentDuration?.id ? PAYMENT_DURATION_ID_TO_ENUM[apiPayment.paymentDuration.id as 1 | 2] : SubscriptionDuration.S_12_MONTHS,
            jobLimit: apiPayment.jobLimit
        }
    };

    static purchaseStripeSubscription = async (club: ClubModel, price?: StripeProductPrice, promoCode?: StripePromoCodeModel): Promise<StripeSubscription | undefined> => {
        try {
            const response = await apollo.mutate({
                mutation: PURCHASE_SUBSCRIPTION,
                variables: {
                    id: club.id,
                    isClub: !club?.isRequest,
                    priceId: price?.id,
                    promotionCodeId: promoCode?.id
                }
            });

            return response.data.stripePurchaseSubscription;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    };

    static upgradeStripeSubscription = async (club?: ClubModel, stripeProduct?: StripeProduct, price?: StripeProductPrice, promoCode?: StripePromoCodeModel): Promise<StripeSubscription | undefined> => {
        console.log(club, stripeProduct);
        try {
            const response = await apollo.mutate({
                mutation: UPGRADE_SUBSCRIPTION,
                variables: {
                    id: club?.id,
                    isClub: !club?.isRequest,
                    priceId: price?.id,
                    autorenew: true,
                    promotionCodeId: promoCode?.id
                }
            });

            return response.data.stripeUpdateSubscription;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    };

    static changeSubscriptionRenewal = async (club?: ClubModel, autorenew?: boolean): Promise<StripeSubscription | undefined> => {
        try {
            const response = await apollo.mutate({
                mutation: UPGRADE_SUBSCRIPTION,
                variables: {
                    id: club?.id,
                    isClub: !club?.isRequest,
                    autorenew: autorenew
                }
            });

            return response.data.stripeUpdateSubscription;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    };

    static sendRequestHof = async (id: number, request: boolean, name: string, email: string, phone: string, clubName: string, message: string) => {
        try {
            const response = await apollo.mutate({
                mutation: REQUEST_HOF,
                variables: {
                    id: id,
                    request: request,
                    club: clubName,
                    email: email,
                    message: message,
                    name: name,
                    phone: phone
                }
            });

            return !!response.data.requestHOF;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    static getStripeProducts = async (): Promise<StripeProduct[]> => {
        try {
            const response = await apollo.query({
                query: GET_STRIPE_PRODUCTS
            });

            let products = response.data.stripeProducts as StripeProduct[];
            products = products.filter(p => p.active);
            return products;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    static getStripeSetupIntent = async (club?: ClubModel): Promise<StripeSetupIntent | undefined> => {
        try {
            const response = await apollo.mutate({
                mutation: STRIPE_SETUP_INTENT,
                variables: {
                    id: club?.id,
                    isClub: !club?.isRequest
                }
            });
            return response.data.stripeSetupIntent
        } catch (error) {
            console.error(error);
        }
    }

    static getStripePaymentMethods = async (club?: ClubModel): Promise<PaymentMethodModel[] | undefined> => {
        try {
            const response = await apollo.query({
                query: GET_STRIPE_PAYMENT_METHODS,
                variables: {
                    id: club?.id,
                    isClub: !club?.isRequest
                }
            });

            return response.data.stripePaymentMethods;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    static removeStripePaymentMethod = async (club?: ClubModel, paymentMethod?: PaymentMethodModel): Promise<PaymentMethodModel | undefined> => {
        try {
            const response = await apollo.mutate({
                mutation: REMOVE_STRIPE_PAYMENT_METHOD,
                variables: {
                    id: club?.id,
                    isClub: !club?.isRequest,
                    paymentMethodId: paymentMethod?.id
                }
            });

            return response.data.stripeDetachPaymentMethod;
        } catch (e) {
            console.error(e);
        }
    }

    static getStripeInvoices = async (club?: ClubModel): Promise<StripeInvoiceModel[] | undefined> => {
        try {
            const response = await apollo.query({
                query: GET_STRIPE_INVOICES,
                variables: {
                    id: club?.id,
                    isClub: !club?.isRequest
                }
            });
            return response.data.stripeInvoices;
        } catch (e) {
            console.error(e);
            return [];
        }
    };

    static getStripeInvoice = async (club?: ClubModel, invoiceId?: string): Promise<StripeInvoiceModel | undefined> => {
        try {
            const response = await apollo.query({
                query: GET_STRIPE_INVOICE,
                variables: {
                    id: club?.id,
                    isClub: !club?.isRequest,
                    invoiceId: invoiceId
                }
            });
            return response.data.stripeInvoice;
        } catch (e) {
            console.error(e);
            return;
        }
    };

    static payStripeInvoice = async (club?: ClubModel, invoiceId?: string): Promise<StripeInvoiceModel | undefined> => {
        try {
            const response = await apollo.mutate({
                mutation: PAY_STRIPE_INVOICE,
                variables: {
                    id: club?.id,
                    isClub: !club?.isRequest,
                    invoiceId: invoiceId
                }
            });
            return response.data.stripePayInvoice;
        } catch (e) {
            console.error(e);
            return;
        }
    };

    static getStripeCustomer = async (club?: ClubModel): Promise<StripeCustomerModel | undefined> => {
        try {
            const response = await apollo.query({
                query: GET_STRIPE_CUSTOMER,
                variables: {
                    id: club?.id,
                    isClub: !club?.isRequest
                }
            });
            return response.data.stripeCustomer;
        } catch (e) {
            console.error(e);
        }
    };


    static setDefaultPaymentMethod = async (club?: ClubModel, paymentMethodId?: string): Promise<StripeCustomerModel | undefined> => {
        try {
            const response = await apollo.mutate({
                mutation: SET_DEFAULT_PAYMENT_METHOD,
                variables: {
                    id: club?.id,
                    isClub: !club?.isRequest,
                    paymentMethodId: paymentMethodId
                }
            });

            return response.data.stripeDefaultPaymentMethod;
        } catch (e) {
            console.error(e);
        }
    };

    static updateCoupon = async (club?: ClubModel, coupon?: string): Promise<StripeDiscountModel | undefined> => {
        try {
            const response = await apollo.mutate({
                mutation: SET_COUPON,
                variables: {
                    id: club?.id,
                    isClub: !club?.isRequest,
                    coupon: coupon
                }
            });

            return response.data.stripeCoupon;
        } catch (e) {
            console.error(e);
        }
    }

    static getStripePromoCodes = async (club?: ClubModel, code?: string): Promise<StripePromoCodeModel[] | undefined> => {
        try {
            const response = await apollo.query({
                query: GET_STRIPE_PROMO_CODES,
                variables: {
                    id: club?.id,
                    isClub: !club?.isRequest,
                    code: code
                }
            });

            return response.data.stripePromotionCodes;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    static getStripeSubscription = async (club?: ClubModel): Promise<StripeSubscription | undefined> => {
        try {
            const response = await apollo.query({
                query: GET_STRIPE_SUBSCRIPTION,
                variables: {
                    id: club?.id,
                    isClub: !club?.isRequest
                }
            });
            return response.data.stripeSubscription;
        } catch (e) {
            console.error(e);
        }
    };

    static getStripeSubscriptionSSR = async (token: string, club?: ClubModel): Promise<StripeSubscription | undefined> => {
        try {
            const apollo = createApolloClient(createAuthLinkExplicit(token))
            const response = await apollo.query({
                query: GET_STRIPE_SUBSCRIPTION,
                variables: {
                    id: club?.id,
                    isClub: !club?.isRequest
                }
            });
            return response.data.stripeSubscription;
        } catch (e) {
            console.error(e);
        }
    };

}
