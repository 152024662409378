import React from 'react'
import {Box, Grid, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from "react-i18next";
import UserModel from "../../../models/user.model";
import {getEmail, getPhone} from "../../../util/profile.util";
import ETextField from "../../common/ETextField.component";

interface Props {
    user?: UserModel,
    setUser: (user: UserModel) => void,
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
    input: {
        width: '100%'
    }
}))


const ClubAdminProfileTab = ({user, setUser}: Props) => {

    const classes = useStyle()
    const {t, i18n} = useTranslation()
    const profile = user?.profile || {}

    const updateProfileFields = (data: { field: string, value: any }[]) => {
        const newUser: any = {
            ...user,
            profile: {
                ...user?.profile
            }
        }
        data.forEach(d => newUser.profile[d.field] = d.value)
        setUser(newUser)
    }

    const updateProfileField = (field: string, value: any) => {
        updateProfileFields([{field, value}])
    }

    //endregion Handlers

    //region UI

    const contactUI = (
        <Grid
            container
            spacing={2}
            className={classes.sectionData}
        >

            <ETextField
                grid={'1/3'}
                label={t('Phone')}
                value={getPhone(user)}
                onChange={event => updateProfileField('contactInfo', {
                    ...user?.profile?.contactInfo,
                    phone: event
                })}
            />

            <ETextField
                grid={'1/3'}
                label={getEmail(user)}
                value={user?.profile?.contactInfo?.email || ''}
                onChange={event => updateProfileField('contactInfo', {
                    ...user?.profile?.contactInfo,
                    email: event
                })}
                disabled
            />


        </Grid>
    )

    return (
        <Box
            p={3}
            display={'flex'}
            flexDirection={'column'}
        >
            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Contact")}
            </Typography>
            {contactUI}

        </Box>
    )

    //endregion UI
}

export default ClubAdminProfileTab
