import React, {useEffect, useState} from 'react';
import styles from './Account.module.scss';
import {Box, Button, CircularProgress, Grid, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {getEmail, getFirstName, getLastName, getPhone} from "../../../util/profile.util";
import {isFormValid, validateForm} from "../../../util/form.util";
import PhoneNumberInput from "../../common/PhoneNumberInput/PhoneNumberInput.component";
import {emailValidator, emptyPhoneNumberValidator, emptyValidator} from "../../../util/validators";
import {useUser} from "../../../store/store.utils";
import AuthService from "../../../services/auth.service";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import ETextField from "../../common/ETextField.component";
import {initLogout, initUpdateProfile} from "../../../store/actions/authActions";
import EButton from "../../common/EButton";

const Account = () => {

    // ***** State ***** //

    const [form, setForm] = useState<any>();
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const [updateError, setUpdateError] = useState(false);
    const user = useUser()
    const club = AuthService.getAdminClub(user)
    const history = useHistory()
    const dispatch = useDispatch()

    const {t, i18n} = useTranslation();

    // ***** Effects ***** //

    useEffect(() => {
        setForm({
            firstName: {
                value: getFirstName(user, ''),
                placeholder: "first_name",
                type: 'text',
                validation: {
                    validators: [emptyValidator],
                    error: '',
                }
            },
            lastName: {
                value: getLastName(user, ''),
                placeholder: "last_name",
                type: 'text',
                validation: {
                    validators: [emptyValidator],
                    error: '',
                }
            },
            phone: {
                value: getPhone(user, ''),
                placeholder: "phone",
                type: 'tel',
                validation: {
                    validators: [emptyPhoneNumberValidator],
                    error: '',
                }
            },
            email: {
                value: getEmail(user, ''),
                placeholder: "email",
                type: 'email',
                validation: {
                    validators: [emptyValidator, emailValidator],
                    error: '',
                }
            }
        })
    }, [user]);

    // ***** Handlers ***** //

    const setNewLanguage = async (languageCode: string) => {
        i18n.changeLanguage(languageCode);
    };

    const updateProfileInfo = async () => {
        let newForm: any = validateForm(form);
        setForm(newForm);
        if (isFormValid(newForm)) {
            setUpdateInProgress(true);
            setUpdateError(false);

            const result: any = await dispatch(initUpdateProfile(user!, form.firstName.value, form.lastName.value, form.phone.value));
            if (result.error) {
                setUpdateError(true);
            }
            setUpdateInProgress(false);
        }
    };


    const logout = () => {
        dispatch(initLogout())
        history.push('/');
    };

    const inputHandler = (key: string, value: string) => {
        const formElement = {
            ...form[key],
            validation: {
                ...form[key].validation,
                error: ''
            }
        };
        formElement.value = value;
        setForm({
            ...form,
            [key]: formElement
        });
    };

    // ***** Render ***** //

    const mapErrorToMessage = (error: string): string => {
        switch (error) {
            case 'empty':
                return t('error_empty_field');
            case 'email_not_valid':
                return t('error_bad_email');
            case 'min_length':
                return t('password_must_be_at_least_8_characters_long');
        }
        return '';
    };

    const renderInputField = (key: string, forceDisable: boolean = false) => {
        if (!form) return null;

        const formElement = form[key];
        if (!formElement) return null;

        switch (key) {
            case 'phone':
                return (
                    <PhoneNumberInput
                        disabled={forceDisable}
                        phoneNumber={formElement.value}
                        onPhoneNumberChange={phone => inputHandler(key, phone)}
                        className={styles.input}
                        required
                        type={formElement.type}
                        label={t(formElement.placeholder)}
                        variant={'outlined'}
                        margin="normal"
                        error={!!formElement.validation.error}
                        helperText={mapErrorToMessage(formElement.validation.error)}
                    />
                );
            default:
                return (
                    <ETextField
                        grid={'1/1'}
                        disabled={forceDisable}
                        onChange={(event) => inputHandler(key, event)}
                        value={formElement.value}
                        className={styles.input}
                        mandatory
                        // required
                        // type={formElement.type}
                        label={t(formElement.placeholder)}
                        // margin="normal"
                        // error={!!formElement.validation.error}
                        // helperText={mapErrorToMessage(formElement.validation.error)}
                    />
                );
        }
    };

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={styles.component}
            p={2}
        >

            <Grid container spacing={5}>

                <Grid item xs={12} md={6}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <Typography variant={'h3'}>{t('My account')}</Typography>

                        <Grid container>
                            {renderInputField('firstName')}
                            {renderInputField('lastName')}
                            {renderInputField('phone')}
                            {renderInputField('email', true)}
                        </Grid>

                        <div className={styles.changeEmailMessage}>{t('contact_support_to_change_email')}</div>

                        {updateError && <div className={styles.updateInfoError}>{t('update_profile_info_error')}</div>}

                        {
                            updateInProgress ? <CircularProgress/> :
                                <Button variant="contained" color="primary"
                                        onClick={updateProfileInfo}>
                                    {t('update_profile_info')}
                                </Button>
                        }

                    </Box>

                </Grid>

                <Grid item xs={12} md={6}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        {/*<Typography variant={'h3'}>{t('Language')}</Typography>*/}

                        {/*<Box display={'flex'} height={25} marginTop={2}>*/}

                        {/*    <div*/}
                        {/*        className={clsx(styles.flag, 'flag-icon', getLanguageFlag({isoCode: 'en'}))}*/}
                        {/*        onClick={() => setNewLanguage('en')}*/}
                        {/*    />*/}

                        {/*    <div*/}
                        {/*        className={clsx(styles.flag, 'flag-icon', getLanguageFlag({isoCode: 'de'}))}*/}
                        {/*        onClick={() => setNewLanguage('de')}*/}
                        {/*    />*/}

                        {/*    <div*/}
                        {/*        className={clsx(styles.flag, 'flag-icon', getLanguageFlag({isoCode: 'sr'}))}*/}
                        {/*        onClick={() => setNewLanguage('sr')}*/}
                        {/*    />*/}

                        {/*</Box>*/}

                        <Box>
                            <EButton
                                variant="contained"
                                color="error"
                                onClick={logout}
                            >
                                {t('logout')}
                            </EButton>
                        </Box>

                    </Box>
                </Grid>

            </Grid>


        </Box>
    );
};

export default Account;
