import React, {useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControl,
    FormControlLabel,
    Typography
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";

const RoleFilter = () => {

    //region State

    const [paramValue] = useUrlParam<string[]>({param: 'userRoles', dataType: 'complex', defaultValue: []})
    const [expanded, setExpanded] = useState(!!paramValue.length)
    const history = useHistory()

    //endregion State

    const handleChangeRole = (role: string) => {
        let updatedRoles = [...paramValue]
        if (!updatedRoles.find(r => r === role)) {
            updatedRoles = [...paramValue, role]
        } else {
            updatedRoles = paramValue.filter(r => r !== role)
        }

        setMultipleParams([
            {name: 'userRoles', value: updatedRoles, dataType: 'complex'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="role-content"
                id="role-header"
            >
                <Typography>Role {!!paramValue.length && `(${paramValue.join(', ')})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <FormControl component="fieldset">

                    <FormControlLabel
                        checked={!!paramValue.find(f => f === 'PLAYER')}
                        onChange={_ => handleChangeRole('PLAYER')}
                        value="PLAYER" control={<Checkbox/>}
                        label="Player"
                    />

                    <FormControlLabel
                        checked={!!paramValue.find(f => f === 'AGENT')}
                        onChange={_ => handleChangeRole('AGENT')}
                        value="AGENT" control={<Checkbox/>}
                        label="Agent"
                    />

                    <FormControlLabel
                        checked={!!paramValue.find(f => f === 'STAFF')}
                        onChange={_ => handleChangeRole('STAFF')}
                        value="STAFF" control={<Checkbox/>}
                        label="Club Admin"
                    />

                    <FormControlLabel
                        checked={!!paramValue.find(f => f === 'ADMIN')}
                        onChange={_ => handleChangeRole('ADMIN')}
                        value="ADMIN" control={<Checkbox/>}
                        label="Ph admin"
                    />

                </FormControl>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default RoleFilter