import React from 'react';
import ReactDOM from 'react-dom';
import "./normalize.css";
import "./index.css";
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider} from '@material-ui/core';
// import 'fontsource-roboto';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {Provider} from "react-redux";
import initLocalization from "./i18n";
import {theme} from "./theme";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import 'flag-icon-css/sass/flag-icon.scss'
import MixpanelService from "./services/analytics/mixpanel.service";
import {store} from "./store/root.reducer";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import IntercomService from "./services/intercom.service";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

require('dotenv').config()

const firebaseConfig = {
    apiKey: "AIzaSyAN0MhmnorI1mqgqHvPl6nsYT-JLI7Hblo",
    authDomain: "crqlar.firebaseapp.com",
    projectId: "crqlar",
    storageBucket: "crqlar.appspot.com",
    messagingSenderId: "351307626928",
    appId: "1:351307626928:web:ce5734bd0656f9e38f7e75",
    measurementId: "G-T295D361QR"
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);
const queryClient = new QueryClient()

const initApp = async () => {
    await initLocalization()
    await MixpanelService.init()
    IntercomService.init();
    ReactDOM.render(
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <Elements stripe={stripePromise}>
                    <Provider store={store}>
                        <ThemeProvider theme={theme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <BrowserRouter>
                                    <App/>
                                </BrowserRouter>
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Provider>
                </Elements>
            </QueryClientProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

initApp()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
