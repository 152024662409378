import React, {useEffect, useState} from "react";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {isDisqualified, OpportunityAlgoData, totalPoints} from "../../models/opportunityAlgoData.model";
import OpportunityService from "../../services/opportunity.service";
import UserModel from "../../models/user.model";
import {processOpportunititesFeed} from "./algorithm.util";
import Job from "../../models/job.model";
import ClubModel from "../../models/club.model";
import {useTranslation} from "react-i18next";
import {
    countriesHaveAllIds,
    eeaChCountryIds,
    euCountryIds,
    getCountriesByIds,
    getRegion,
    removeCountries
} from "../../services/countries";
import {format} from "date-fns";
import {getJobLocation} from "../../util/opportunity.util";
import {LocationType} from "../../models/locationType.enum";
import InfoBox from "./InfoBox.component";
import UserAnalyzer from "./UserAnalyzer.component";
import EButton from "../common/EButton";
import clsx from "clsx";
import {useAlgobugState} from "../../store/store.utils";
import algobugReducer from "../../store/reducers/algobug.reducer";
import {SET_ALGOBUG_FEED_USER} from "../../store/actions/actionTypes";
import {useDispatch} from "react-redux";


const useStyles = makeStyles(theme => ({
    header: {
        width: '100%',
        padding: '10px',
        borderBottom: '1px solid #f6f6f6',
        fontSize: '12px',
    },
    logo: {
        width: '30px',
        minWidth: '30px',
        height: '30px',
        marginRight: '15px',
        objectFit: 'contain'
    },
    standardCol: {
        width: '120px',
        minWidth: '120px !important',
        cursor: 'pointer',
        fontSize: '12px',
    },
    name: {
        // flexGrow: '1 !important',
        minWidth: '150px !important',
        cursor: 'pointer',
    },
    rowOpportunityDetails: {
        width: '100%',
        minHeight: '100px',
        padding: '30px 10px'
    },
    userInfoBoxGreen: {
        boxShadow: '0px 0px 3px 2px rgba(38, 217, 24, 0.8)'
    },
    userInfoBoxRed: {
        boxShadow: '0px 0px 3px 2px rgba(191, 0, 16, 0.8)'
    },
    actionButton: {
        minWidth: 'fit-content',
        marginRight: '20px !important'
    },
    label: {
        marginRight: '10px',
    },
    option: {
        color: 'white',
        height: '100%',
        paddingLeft: '10px',
        paddingRight: '10px',
        minWidth: '100px !important',
        borderRadius: '10px',
        background: theme.palette.secondary.main,
        cursor: 'pointer'
    },
    selected: {
        background: theme.palette.primary.main,
        color: 'white'
    },
    options: {
        height: '35px',
        marginLeft: '10px',
    },
    optionsFirst: {
        marginLeft: 'auto'
    },
    row: {
        width: '100%'
    },
    rowOpportunityInfo: {
        width: '100%',
        padding: '10px',
        borderBottom: '1px solid #f6f6f6',
        cursor: 'pointer',
        fontSize: '14px',
        // hover: {
        //     background: '#fefefe'
        // }
    },
    opportunityStatus: {
        alignSelf: 'center',
        justifySelf: 'center',
        marginTop: '15px !important',
        marginBottom: '20px !important',
    },
    loadMore: {
        marginTop: '15px !important',
        marginBottom: '20px !important',
        maxWidth: 'fit-content !important',
        alignSelf: 'center'
    },
    table: {
        marginTop: '25px',
        background: 'white',
        borderRadius: '4px',
        width: '100%',
        // overflowX: 'auto',
        minHeight: '200px !important',
    }

}))

const OpportunityFeedAlgoBug = () => {

    // region state

    const [savingInProgress, setSavingInProgress] = useState(false);
    const [error, setError] = useState(false);
    const [id, setId] = useState(false);
    const classes = useStyles()
    const {t} = useTranslation()


    const [opportunitiesStatus, setOpportunitiesStatus] = useState('');
    const [opportunitiesAlgoData, setOpportunitiesAlgoData] = useState<OpportunityAlgoData[]>([]);
    const [resultSource, setResultSource] = useState('api');
    const [currentPage, setCurrentPage] = useState(0);
    const [opportunityPool, setOpportunityPool] = useState('all');
    const [canLoadMore, setCanLoadMore] = useState(false);
    const [showDisqualified, setShowDisqualified] = useState(true);
    const [selectedData, setSelectedData] = useState<OpportunityAlgoData>();

    const {algobugFeedUser} = useAlgobugState()
    const dispatch = useDispatch()

    // endregion state


    // region handlers

    const getOpportunities = async (loadMore: boolean) => {
        setOpportunitiesStatus('Downloading results... Please wait, this may take a while...');

        if (!loadMore) setOpportunitiesAlgoData([]);

        const page = loadMore ? currentPage + 1 : 0;

        let ids: number[] = [];
        if (opportunityPool === 'bucket') {
            // ids = props.opportunitiesBucket.filter(o => !!o?.id).map(opportunity => opportunity.id || 0);
        }

        let opportunities = (await OpportunityService.getAllOpportunitiesAdminFeed(
            algobugFeedUser!,
            ids,
            showDisqualified,
            {
                page: page,
                size: 20
            }));
        setCurrentPage(page);
        setCanLoadMore((opportunities?.entries?.length || 0) >= 20);
        setOpportunitiesStatus('Running algorithm simulator');

        if (loadMore) {
            setOpportunitiesAlgoData([...opportunitiesAlgoData, ...processOpportunititesFeed(opportunities)]);
        } else {
            setOpportunitiesAlgoData(processOpportunititesFeed(opportunities));
        }

        setOpportunitiesStatus('');
    };

    const getClubOrRequest = (opportunity?: Job): ClubModel | undefined => {
        return opportunity?.club || opportunity?.clubRequest;
    };

    const getPositions = (opportunity?: Job) => {
        if (opportunity?.positionMode === 'section') {
            return opportunity?.section;
        }

        if (opportunity?.positions?.length) {
            return opportunity?.positions.map((position: any) => t(`position_${position}_short`)).join(', ');
        }

        return '-';
    };

    const prettyGender = (gender?: string) => {
        switch (gender) {
            case 'MALE':
                return 'Male';
            case 'FEMALE':
                return 'Female';
        }
        return '-'
    };

    const prettyStatus = (status?: string) => {
        switch (status) {
            case 'NOT_APPROVED':
                return 'Not approved';
            case 'ACTIVE':
                return 'Active';
            case 'INACTIVE':
                return 'Inactive';
        }
        return '-'
    };

    const getClubName = (opportunity?: Job) => {
        if (opportunity?.club) {
            return opportunity?.club.name || '-';
        }

        if (opportunity?.clubRequest) {
            return (opportunity?.clubRequest.name || '-') + ' (req)';
        }

        return '-';
    };

    const handleRowClick = (data: OpportunityAlgoData) => {
        if (selectedData === data) {
            setSelectedData(undefined);
        } else {
            setSelectedData(data);
        }
    };

    const handleSetUser = (user?: UserModel) => {
        dispatch({
            type: SET_ALGOBUG_FEED_USER,
            payload: user
        })
        setOpportunitiesAlgoData([]);
        setOpportunitiesStatus('');
        setSelectedData(undefined);
    };

    useEffect(() => {
    }, []);


    //endregion handlers


    // region UI

    const renderNationalities = (opportunity?: Job) => {
        let nationalities = [...(opportunity?.nationalities || [])];

        if (countriesHaveAllIds(nationalities, eeaChCountryIds)) {
            const region = getRegion('eeach');
            if (region) {
                nationalities.unshift(region);
                nationalities = removeCountries(nationalities, getCountriesByIds(eeaChCountryIds));
            }
        } else if (countriesHaveAllIds(nationalities, euCountryIds)) {
            const region = getRegion('eu');
            if (region) {
                nationalities.unshift(region);
                nationalities = removeCountries(nationalities, getCountriesByIds(euCountryIds));
            }
        }

        return nationalities.map(nationality => nationality.name).join(', ');
    };

    const renderHeader = () => {
        return (
            <Box
                className={classes.header}
                display={'flex'}
                alignItems={'center'}
            >
                <div className={classes.standardCol}>Id</div>
                <div className={classes.standardCol}>Score</div>
                <div className={classes.name}>Club</div>
                <div className={classes.logo}/>
                <div className={classes.standardCol}>Positions</div>
                <div className={classes.standardCol}>Age</div>
                <div className={classes.standardCol}>Level</div>
                <div className={classes.standardCol}>Gender</div>
                <div className={classes.standardCol}>Foot</div>
                <div className={classes.standardCol}>Status</div>
                <div className={classes.standardCol}>Created At</div>
                <div className={classes.standardCol}>Location</div>
                <div className={classes.standardCol}>Nationalities</div>
            </Box>
        )
    };

    const renderOpportunityBasicInfo = (data: OpportunityAlgoData) => {
        const opportunity = data.opportunity;
        return (
            <Box
                className={classes.rowOpportunityInfo}
                display={'flex'}
                alignItems={'center'}
            >
                <div className={classes.standardCol}>{opportunity?.id}</div>
                <div className={classes.standardCol} style={{color: isDisqualified(data) ? 'red' : 'black'}}>{
                    isDisqualified(data) ? `Disqualified (${totalPoints(data)})` :
                        `${(totalPoints(data) / 120 * 100).toFixed(2)}% (${totalPoints(data)})`
                }</div>

                <img alt={'Club logo'} className={classes.logo}
                     src={getClubOrRequest(opportunity)?.logo || require('../../assets/images/club-logo-hexagon.svg').default}/>
                <div className={classes.name}>{getClubName(opportunity)}</div>
                <div className={classes.standardCol}>{getPositions(opportunity)}</div>
                <div className={classes.standardCol}>{`${opportunity?.fromAge} - ${opportunity?.toAge}`}</div>
                <div className={classes.standardCol}>{`${opportunity?.fromLevel} - ${opportunity?.toLevel}`}</div>
                <div className={classes.standardCol}>{prettyGender(opportunity?.gender)}</div>
                <div className={classes.standardCol}>{opportunity?.preferredFoot || 'None'}</div>
                <div className={classes.standardCol}>{prettyStatus(opportunity?.opportunityStatus)}</div>
                <div
                    className={classes.standardCol}>{opportunity?.createdAt ? format(new Date(opportunity?.createdAt), 'dd.MM.yyyy HH:mm') : "-"}</div>
                <div className={classes.standardCol}>{getJobLocation(t, opportunity)}</div>
                <div className={classes.standardCol}>{renderNationalities(opportunity)}</div>
            </Box>
        );
    };

    const renderOpportunityDetails = (data: OpportunityAlgoData) => {
        let locationTooltip = '';
        switch (data.opportunity?.locationType) {
            case LocationType.Local:
                locationTooltip = `Calculated distance: ${data.calculatedDistance?.toFixed(2)}km`;
                break;
            case LocationType.Country:
                locationTooltip = data.disqualifiedOnLocation ? `Country mismatch!` : `Country match!`;
                break;
            case LocationType.International:
                locationTooltip = `All good - everyone can apply`;
                break;
        }
        return (
            <Box
                display={'flex'}
                flexWrap={'wrap'}
                className={classes.rowOpportunityDetails}>

                <InfoBox
                    title={'level'}
                    value={data.disqualifiedOnLevel ? 'Fail' : 'Pass'}
                    classes={data.disqualifiedOnLevel ? classes.userInfoBoxRed : classes.userInfoBoxGreen}
                />

                <InfoBox
                    title={'gender'}
                    value={data.disqualifiedOnGender ? 'Fail' : 'Pass'}
                    classes={data.disqualifiedOnGender ? classes.userInfoBoxRed : classes.userInfoBoxGreen}
                />

                <InfoBox
                    title={'location'}
                    value={data.disqualifiedOnLocation ? 'Fail' : 'Pass'}
                    classes={data.disqualifiedOnLocation ? classes.userInfoBoxRed : classes.userInfoBoxGreen}
                    tooltip={locationTooltip}
                />

                <InfoBox
                    title={'age'}
                    value={data.disqualifiedOnAge ? 'Fail' : 'Pass'}
                    classes={data.disqualifiedOnAge ? classes.userInfoBoxRed : classes.userInfoBoxGreen}
                />

                <InfoBox
                    title={'club'}
                    value={data.disqualifiedOnClub ? 'Fail' : 'Pass'}
                    classes={data.disqualifiedOnClub ? classes.userInfoBoxRed : classes.userInfoBoxGreen}
                />

                <InfoBox
                    title={'applied or skipped'}
                    value={data.disqualifiedOnAppliedOrSkipped ? 'Fail' : 'Pass'}
                    classes={data.disqualifiedOnAppliedOrSkipped ? classes.userInfoBoxRed : classes.userInfoBoxGreen}
                />

                <InfoBox
                    title={'position pt.'}
                    value={data.disqualifiedOnPosition ? 'Fail' : (data.positionPoints || 0)}
                    classes={data.disqualifiedOnPosition ? classes.userInfoBoxRed : classes.userInfoBoxGreen}
                    tooltip={`Calculated position points: ${data.positionPoints}`}
                />

                <InfoBox
                    title={'verification'}
                    value={data.disqualifiedOnVerification ? 'Fail' : 'Pass'}
                    classes={data.disqualifiedOnVerification ? classes.userInfoBoxRed : classes.userInfoBoxGreen}
                    tooltip={'Job level: ' + data.opportunity?.teams?.[0]?.level?.value}
                />

                <InfoBox
                    title={'team level pt.'}
                    value={data.levelPoints || 0}
                />

                <InfoBox
                    title={'foot pt.'}
                    value={data.footPoints || 0}
                />

                <InfoBox
                    title={'nationality pt.'}
                    value={data.nationalityPoints || 0}
                />

                <InfoBox
                    title={'language pt.'}
                    value={data.languagePoints || 0}
                />

                <InfoBox
                    title={'height pt.'}
                    value={data.heightPoints || 0}
                />

            </Box>
        )
    };

    const renderOpportunities = () => {

        return opportunitiesAlgoData.map(data => {
            return (
                <Box
                    className={classes.row}
                    display={'flex'}
                    key={data.opportunity?.id}
                    onClick={() => handleRowClick(data)}
                    flexDirection={'column'}
                    onMouseDown={e => {
                        if (e.button === 1) {
                            window.open(`${process.env.REACT_APP_WEB_URL}/admin/job/${data.opportunity?.id}`, '_blank')
                        }
                    }}
                >
                    {renderOpportunityBasicInfo(data)}
                    {data === selectedData ? renderOpportunityDetails(data) : null}
                </Box>
            )
        });
    };

    return (

        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            p={3}
        >

            <UserAnalyzer
                user={algobugFeedUser!}
                onUserLoaded={handleSetUser}
            />

            {algobugFeedUser && !opportunitiesStatus &&
            <Box
                width={'100%'}
                marginTop={'25px'}
                display={'flex'}
                alignItems={'center'}
            >

                <EButton variant="contained"
                         className={classes.actionButton}
                         onClick={() => getOpportunities(false)}>
                    Analyze
                </EButton>

                {
                    // opportunityPool === 'bucket' &&
                    // <EButton variant="contained" color="primary"
                    //           className={classes.actionButton}
                    //           onClick={() => setBucketVisible(true)}>
                    //     View bucket ({props.opportunitiesBucket.length})
                    // </EButton>
                }

                <Box
                    marginLeft={'auto'}
                    display={'flex'} alignItems={'center'}
                    className={clsx(classes.options, classes.optionsFirst)}>
                    <div className={classes.label}>Opportunities:</div>
                    <Box className={clsx(classes.option, opportunityPool === 'all' && classes.selected)}
                         display={'flex'} alignItems={'center'} justifyContent={'center'}
                         onClick={() => setOpportunityPool('all')}
                    >
                        <div>All</div>
                    </Box>
                    <Box className={clsx(classes.option, opportunityPool === 'bucket' && classes.selected)}
                         display={'flex'} alignItems={'center'} justifyContent={'center'}
                         onClick={() => setOpportunityPool('bucket')}
                    >
                        <div>Bucket</div>
                    </Box>
                </Box>

                <Box display={'flex'} alignItems={'center'} className={clsx(classes.options)}>
                    <div className={classes.label}>Show disqualified:</div>
                    <Box className={clsx(classes.option, showDisqualified && classes.selected)}
                         display={'flex'} alignItems={'center'} justifyContent={'center'}
                         onClick={() => setShowDisqualified(true)}
                    >
                        <div>Yes</div>
                    </Box>
                    <Box className={clsx(classes.option, showDisqualified === false && classes.selected)}
                         display={'flex'} alignItems={'center'} justifyContent={'center'}
                         onClick={() => setShowDisqualified(false)}
                    >
                        <div>No</div>
                    </Box>
                </Box>

            </Box>
            }


            <Box
                className={classes.table}

                display={'flex'}
                flexDirection={'column'}
                alignSelf={'flex-start'}
            >
                {renderHeader()}
                {renderOpportunities()}

                {opportunitiesStatus && <div className={classes.opportunityStatus}>{opportunitiesStatus}</div>}

                {
                    canLoadMore && !opportunitiesStatus && resultSource === 'api' &&
                    <EButton variant="contained" color="primary"
                             className={classes.loadMore}
                             onClick={() => getOpportunities(true)}>
                        Load More
                    </EButton>
                }

            </Box>

            {/*<Bucket*/}
            {/*    open={bucketVisible}*/}
            {/*    onClose={() => setBucketVisible(false)}*/}
            {/*    opportunities={props.opportunitiesBucket}*/}
            {/*    onClearBucket={props.clearOpportunitiesBucket}*/}
            {/*/>*/}

        </Box>
    );

    // endregion UI
}

export default OpportunityFeedAlgoBug;
