import React, {useEffect, useState} from 'react'
import {Box, Chip, CircularProgress, Grid, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {useHistory} from "react-router"
import {useTranslation} from "react-i18next"
import {
    getClubDescription,
    getClubLocation,
    getClubLocationGeo,
    getClubLogo,
    getClubName
} from "../../../util/profile.util"
import ETextField from "../../common/ETextField.component"
import GoogleMapReact from "google-map-react"
import GooglePlacesAutocomplete, {geocodeByPlaceId} from "react-google-places-autocomplete"
import {mapStyleGrey} from "../../../util/map.style"
import ClubModel from "../../../models/club.model"
import GenericNameAvatar from "../../user/details/GenericNameAvatar.component"
import Team from "../../../models/team.model"
import EButton from "../../common/EButton"
import AddIcon from "@material-ui/icons/Add"
import EditTeamsPopup from "../../common/EditTeamsPopup"
import {generateTeam} from "../../../util/team.util"
import {convertGeocodeResultsToGoogleGeolocationInput} from "../../../util/util";
import AuthService from "../../../services/auth.service";
import {getGridSizes} from "../../common/grid.util";

interface Props {
    club?: ClubModel,
    setClub: (club: ClubModel) => void,
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
    input: {
        width: '100%'
    }
}))


const ClubInfo = ({club, setClub}: Props) => {

    const classes = useStyle()
    const history = useHistory()
    const {t, i18n} = useTranslation()
    const [teamPopupOpen, setTeamPopupOpen] = useState(false)
    const [loginAsClubInProgress, setLoginAsClubInProgress] = useState(false)

    const [map, setMap] = useState()
    const [marker, setMarker] = useState<any>()

    const updateClubFields = (data: { field: string, value: any }[]) => {
        const newClub: any = {
            ...club,
        }
        data.forEach(d => newClub[d.field] = d.value)
        setClub(newClub)
    }

    const updateClubField = (field: string, value: any) => {
        updateClubFields([{field, value}])
    }

    const drawCircle = () => {
        marker?.setMap(null)

        if (!getClubLocationGeo(club)) return

        try {
            setMarker(new google.maps.Marker({
                map,
                position: getClubLocationGeo(club)
            }))
        } catch (e) {
            console.log(e)
        }
    }

    const deleteTeam = (team: Team) => {
        const newTeams = club?.teams?.filter(t => t !== team)
        updateClubField('teams', newTeams)
    }

    const handleTeamAdd = (gender: string, levelId: number, teamId: number) => {
        const team = generateTeam(gender, levelId, teamId)
        updateClubField('teams', ([...(club?.teams || []), team]))
        setTeamPopupOpen(false)
    }

    useEffect(() => {
        drawCircle()
    }, [club, map])

    const handleLoginAsClub = async () => {
        try {
            setLoginAsClubInProgress(true);
            const response = await AuthService.loginAsClub(club);
            if (!response) {
                // TODO - display error?
            } else {
                window.open(process.env.REACT_APP_WEB_URL, "_self");
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoginAsClubInProgress(false);
        }
    };

    //endregion Handlers

    //region UI

    const renderLoginAsClubButton = () => {
        if (!club?.clubAdmins?.length) return null;

        if (loginAsClubInProgress) {
            return <CircularProgress/>;
        }

        return (
            <EButton
                variant="contained"
                color="primary"
                onClick={handleLoginAsClub}
            >
                {t("Login as club")}
            </EButton>
        )
    }

    const commonInfoUI = (
        <Grid
            container
            spacing={2}
            alignItems={'center'}
        >

            <ETextField
                grid={'2/3'}
                label={t('Club name')}
                value={getClubName(club)}
                onChange={event => updateClubField('name', event)}
            />

            <Grid {...getGridSizes('1/3')}>
                {renderLoginAsClubButton()}
            </Grid>

        </Grid>
    )

    const teamsUI = (
        <Grid container spacing={2}>
            {
                club?.teams?.map((team: Team) => {
                    return (
                        <Grid item>
                            <Chip
                                key={team.id}
                                label={`${team.gender}, ${team.teamType?.name}, L${team.level?.id}`}
                                onDelete={() => deleteTeam(team)}
                            />
                        </Grid>
                    )
                })
            }

            <Grid item>
                <EButton
                    onClick={() => setTeamPopupOpen(true)}
                >
                    <AddIcon/>
                    Add team
                </EButton>
            </Grid>

        </Grid>
    )


    const locationUI = (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_MAPS_KEY!}
                    debounce={1000}
                    selectProps={{
                        value: getClubLocation(club),
                        onChange: async (res: any) => {
                            const results = await geocodeByPlaceId(res.value.place_id)
                            updateClubField('googleGeolocation', convertGeocodeResultsToGoogleGeolocationInput(results as any))
                        }
                    }}
                />

                {
                    getClubLocationGeo(club) &&
                    <div style={{
                        width: '100%',
                        height: 300,
                        marginTop: 20,
                        marginBottom: 30
                    }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{key: process.env.REACT_APP_MAPS_KEY!}}
                            defaultCenter={getClubLocationGeo(club)}
                            center={getClubLocationGeo(club)}
                            defaultZoom={7}
                            options={{styles: mapStyleGrey}}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({map}) => setMap(map)}
                        />
                    </div>
                }
            </Grid>

        </Grid>
    )

    return (
        <Box
            p={3}
            display={'flex'}
            flexDirection={'column'}
        >

            <GenericNameAvatar
                name={getClubName(club)}
                image={getClubLogo(club)}
                setImage={media => updateClubField('logo', media?.url)}
            />

            <Box m={1}/>

            {commonInfoUI}

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Teams")}
            </Typography>
            {teamsUI}

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Location")}
            </Typography>
            {locationUI}

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("About club")}
            </Typography>

            <Grid
                container
                className={classes.sectionData}
            >

                <ETextField
                    grid={'1/1'}
                    multiline
                    rows={30}
                    label={t('About club')}
                    value={getClubDescription(club)}
                    onChange={event => updateClubField('about', event)}
                />

            </Grid>

            <EditTeamsPopup
                open={teamPopupOpen}
                onClose={() => setTeamPopupOpen(false)}
                onSave={handleTeamAdd}
                existingTeams={club?.teams || []}
            />


        </Box>
    )

    //endregion UI
}

export default ClubInfo
