import React, {useEffect, useState} from 'react'
import {Box, Grid, Hidden} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles"
import AnimatedBox from "./Animated.component";
import {DIMENSIONS, LANDING_COMMON_COLORS} from "./common";
import {useTranslation} from "react-i18next";
import {windowWidth} from "../../util/dimension.util";

const useStyle = makeStyles(theme => ({
    component: {
        marginTop: 100,
        width: '100%',
        position: 'relative'
    },
    background: {
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: -1,
        top: -100,
        width: '100%',
    },
    title: {
        fontSize: DIMENSIONS.LARGE.TITLE_SIZE,
        color: LANDING_COMMON_COLORS.TITLE,
        marginBottom: 45,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: DIMENSIONS.SMALL.TITLE_SIZE
        }
    },
    tabletHolder: {
        marginLeft: -100,
        marginRight: -100
    },
    tabletWrapper: {
        width: '100%',
    },
    tablet: {
        width: '100%',
        objectFit: 'cover',
    },
    infoContainer: {
        width: '100%',
        maxWidth: 1500
    },
    infoBox: {
        padding: DIMENSIONS.LARGE.INFO_BOX_PADDING,
        cursor: 'default',
        [theme.breakpoints.down('md')]: {
            padding: DIMENSIONS.SMALL.INFO_BOX_PADDING
        }
    },
    infoIcon: {
        width: 44,
        height: 44,
        marginRight: 26,
    },
    infoIconSmall: {
        width: 30,
        height: 30,
        marginRight: 20,
    },
    infoTitle: {
        marginTop: 11,
        marginBottom: 6,
        fontSize: DIMENSIONS.LARGE.INFO_TITLE_SIZE,
        lineHeight: DIMENSIONS.LARGE.INFO_TITLE_LINE_HEIGHT,
        textAlign: 'left',
        color: LANDING_COMMON_COLORS.TITLE,
        [theme.breakpoints.down('md')]: {
            fontSize: DIMENSIONS.SMALL.INFO_TITLE_SIZE,
            lineHeight: DIMENSIONS.SMALL.INFO_TITLE_LINE_HEIGHT,
        }
    },
    infoMessage: {
        fontWeight: 300,
        fontSize: DIMENSIONS.LARGE.INFO_MESSAGE_SIZE,
        lineHeight: DIMENSIONS.LARGE.INFO_MESSAGE_LINE_HEIGHT,
        textAlign: 'left',
        color: LANDING_COMMON_COLORS.BOX_TEXT,
        whiteSpace: 'pre-wrap',
        [theme.breakpoints.down('md')]: {
            fontSize: DIMENSIONS.SMALL.INFO_MESSAGE_SIZE,
            lineHeight: DIMENSIONS.SMALL.INFO_MESSAGE_LINE_HEIGHT,
        }
    },
    numbersHolder: {
        width: '100%'
    },
    numbersBox: {
        marginLeft: 50,
        marginRight: 50,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 35,
            marginLeft: 15,
            marginRight: 15,
        }
    },
    counterCircleWrapHolder: {
        width: 160,
        height: 160,
        borderRadius: '50%',
        position: 'relative',
        // border: '8px solid',
        [theme.breakpoints.down('sm')]: {
            width: 130,
            height: 130,
        }
    },
    counterCircle: {
        position: 'absolute',
        width: '100%',
        height: '100%'
    },
    counterValue: {
        fontSize: 36,
        lineHeight: '42px',
        color: '#656565',
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
            lineHeight: '28px',
        }
    },
    numberTitle: {
        marginTop: 18,
        marginBottom: 10,
        fontWeight: 500,
        fontSize: DIMENSIONS.LARGE.INFO_TITLE_SIZE,
        lineHeight: DIMENSIONS.LARGE.INFO_TITLE_LINE_HEIGHT,
        color: LANDING_COMMON_COLORS.TITLE,
        [theme.breakpoints.down('md')]: {
            marginTop: 10,
            marginBottom: 5,
            fontSize: DIMENSIONS.SMALL.INFO_TITLE_SIZE,
            lineHeight: DIMENSIONS.SMALL.INFO_TITLE_LINE_HEIGHT,
        }
    },
    numberSubtitle: {
        fontWeight: 300,
        fontSize: DIMENSIONS.LARGE.INFO_MESSAGE_SIZE,
        lineHeight: DIMENSIONS.LARGE.INFO_MESSAGE_LINE_HEIGHT,
        color: LANDING_COMMON_COLORS.BOX_TEXT,
        [theme.breakpoints.down('md')]: {
            fontSize: DIMENSIONS.SMALL.INFO_MESSAGE_SIZE,
            lineHeight: DIMENSIONS.SMALL.INFO_MESSAGE_LINE_HEIGHT,
        }
    }
}))

const WhyPh = () => {

    const classes = useStyle()
    const [animationPercent, setAnimationPercent] = useState(0)
    const totalAnimationTimeMs = 1000
    const animationUpdateIntervalMs = 16
    const animationIncrement = 100 / (totalAnimationTimeMs / animationUpdateIntervalMs)
    const t = useTranslation().t

    const startNumbersAnimation = () => {
        setAnimationPercent(0.1)
    }

    useEffect(() => {
        if (animationPercent >= 100) return

        const clear = setTimeout(() => setAnimationPercent(Math.min(animationPercent + animationIncrement, 100)), animationUpdateIntervalMs)
        return () => clearTimeout(clear)

    }, [animationPercent])

    const renderBox = (icon: any, title: string, message: string) => (
        <AnimatedBox
            className={classes.infoBox}
            display={'flex'}
            alignItems={'flex-start'}

            hover
            fadeIn
            fadeInThreshold={100}
        >
            <Hidden smDown>
                <img src={icon} className={classes.infoIcon}/>
            </Hidden>

            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'flex-start'}
            >
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                >
                    <Hidden mdUp>
                        <img src={icon} className={classes.infoIconSmall}/>
                    </Hidden>
                    <div
                        className={classes.infoTitle}
                    >
                        {title}
                    </div>
                </Box>

                <div
                    className={classes.infoMessage}
                >
                    {message}
                </div>
            </Box>
        </AnimatedBox>
    )

    const renderNumbers = (color: string, value: number, title: string, subtitle: string) => {
        return (
            <Grid item>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    className={classes.numbersBox}
                >
                    <Box
                        className={classes.counterCircleWrapHolder}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        // style={{
                        //     borderColor: color,
                        //     backgroundImage: `linear-gradient(${animationPercent * 360}deg, transparent 100%, ${color} 100%)`
                        // }}
                    >
                        <svg viewBox="0 0 36 36" className={classes.counterCircle}>
                            <path
                                d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831"
                                fill="none"
                                stroke={color}
                                stroke-width="1.5"
                                stroke-dasharray={`${Math.max(animationPercent, 10)}, 100`}
                            />
                        </svg>

                        <div className={classes.counterValue}>
                            {Math.min(Math.ceil(value * animationPercent / 100), value)}+
                        </div>
                    </Box>

                    <div className={classes.numberTitle}>{title}</div>

                    <div className={classes.numberSubtitle}>{subtitle}</div>

                </Box>
            </Grid>
        )
    }

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={classes.component}
        >
            <img
                src={require('../../assets/images/landing/content-background-1.svg').default}
                className={classes.background}
            />

            <div className={classes.title}>
                {t("Why Playerhunter?")}
            </div>

            <Grid container spacing={windowWidth() < 600 ? 4 : 10} className={classes.infoContainer}>

                <Grid item xs={12} md={5}>
                    <AnimatedBox
                        className={classes.tabletHolder}
                        fadeIn
                        fadeInType={'static'}
                    >
                        <picture className={classes.tabletWrapper}>
                            <source
                                className={classes.tablet}
                                srcSet={require('../../assets/images/landing/tablet-1.webp').default}
                            />
                            <img
                                className={classes.tablet}
                                src={require('../../assets/images/landing/tablet-1.png').default}
                            />
                        </picture>
                    </AnimatedBox>
                </Grid>

                <Grid item xs={12} md={7}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={6}>
                            {renderBox(
                                require('../../assets/images/landing/check-green.svg').default,
                                t('Quick and Easy'),
                                t('Get quick and easy access to the club requests in Europe, USA, China, the Middle East and RoW.')
                            )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            {renderBox(
                                require('../../assets/images/landing/check-green.svg').default,
                                t('Cost Savings'),
                                t('Cost savings as no need for additional traveling costs due to digital platform solution. Covering the complete world market with information on club requirements in one place available by one click.')
                            )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            {renderBox(
                                require('../../assets/images/landing/check-green.svg').default,
                                t('Fast Screening'),
                                t('Fast and convenient screening of top players due to unbureaucratic application management (posting job in less than 1 minute)')
                            )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            {renderBox(
                                require('../../assets/images/landing/check-green.svg').default,
                                t('FREE of Charge'),
                                t('NO UPFRONT fees or monthly overpayments')
                            )}
                        </Grid>

                    </Grid>
                </Grid>

                <AnimatedBox
                    display={'flex'}
                    alignItems={'flex-start'}
                    justifyContent={'center'}
                    flexWrap={'wrap'}
                    className={classes.numbersHolder}
                    onAnimate={() => startNumbersAnimation()}
                    fadeInThreshold={150}
                >
                    {renderNumbers('#41C47E', 4000, t('Pro players'), t('Looking for a club'))}
                    {renderNumbers('#02602E', 1800, t('Clubs'), t('Looking for players'))}
                    {renderNumbers('#A1CEB6', 1000, t('Football Jobs'), t('& increasing'))}
                    {renderNumbers('#FF966A', 50, t('Countries'), t('& more comming soon'))}
                </AnimatedBox>

            </Grid>

        </Box>
    )
}

export default WhyPh
