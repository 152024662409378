import React, {useCallback, useEffect, useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core"
import debounce from "lodash/debounce"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";
import ETextField from "../../ETextField.component";

const TransferFeeFilter = () => {

    //region State

    const [fromTransferFeeParam] = useUrlParam({param: 'fromTransferFee', dataType: 'numeric', defaultValue: 0})
    const [toTransferFeeParam] = useUrlParam({param: 'toTransferFee', dataType: 'numeric', defaultValue: 0})

    const [fromTransferFeeLocal, setFromTransferFeeLocal] = useState(fromTransferFeeParam)
    const [toTransferFeeLocal, setToTransferFeeLocal] = useState(toTransferFeeParam)
    const [manualInput, setManualInput] = useState(false)

    const [expanded, setExpanded] = useState(!!fromTransferFeeLocal && !!toTransferFeeLocal)
    const history = useHistory()

    let filterType: string
    if (!fromTransferFeeLocal || !toTransferFeeLocal) {
        filterType = 'all'
    } else if (fromTransferFeeLocal === -1 && toTransferFeeLocal === -1) {
        filterType = 'free_agent'
    } else {
        filterType = 'custom'
    }

    //endregion State

    //region Handlers

    const executeSet = useCallback(debounce((fromTransferFee: any, toTransferFee: any) => {
        setMultipleParams([
            {name: 'fromTransferFee', value: fromTransferFee, dataType: 'numeric'},
            {name: 'toTransferFee', value: toTransferFee, dataType: 'numeric'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }, 1000), [])

    useEffect(() => {
        if (!manualInput) return
        executeSet(fromTransferFeeLocal, toTransferFeeLocal)
    }, [fromTransferFeeLocal, toTransferFeeLocal, manualInput])

    //endregion Handlers

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="transfer-fee-content"
                id="transfer-fee-header"
            >
                <Typography>Transfer
                    fee {!!fromTransferFeeLocal && !!toTransferFeeLocal && `(${fromTransferFeeLocal} - ${toTransferFeeLocal})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>

                <Box>

                    <RadioGroup
                        aria-label="fee_filter_type"
                        name="fee_filter_type"
                        value={filterType}
                        onChange={e => {
                            switch (e.target.value) {
                                case 'all':
                                    setManualInput(true)
                                    setFromTransferFeeLocal(0)
                                    setToTransferFeeLocal(0)
                                    break
                                case 'free_agent':
                                    setManualInput(true)
                                    setFromTransferFeeLocal(-1)
                                    setToTransferFeeLocal(-1)
                                    break
                                case 'custom':
                                    setManualInput(true)
                                    setFromTransferFeeLocal(1)
                                    setToTransferFeeLocal(100_000_000)
                                    break
                            }
                        }}
                    >
                        <FormControlLabel value={'all'} control={<Radio/>} label="Show all"/>
                        <FormControlLabel value={'free_agent'} control={<Radio/>} label="Free agent"/>
                        <FormControlLabel value={'custom'} control={<Radio/>}
                                          label="Custom range"/>
                    </RadioGroup>

                    {
                        filterType === 'custom' &&
                        <Grid container spacing={1}>
                            <ETextField
                                label={'From'}
                                grid={'1/2'}
                                value={fromTransferFeeLocal}
                                onChange={e => {
                                    setManualInput(true)
                                    setFromTransferFeeLocal(+e)
                                }}
                            />
                            <ETextField
                                label={'To'}
                                grid={'1/2'}
                                value={toTransferFeeLocal}
                                onChange={e => {
                                    setManualInput(true)
                                    setToTransferFeeLocal(+e)
                                }}
                            />
                        </Grid>
                    }
                </Box>

            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default TransferFeeFilter
