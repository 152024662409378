import ClubProfile from "./preview/ClubProfile";
import {useUser} from "../../store/store.utils";
import AuthService from "../../services/auth.service";
import {useDispatch} from "react-redux";
import {initReloadProfile} from "../../store/actions/authActions";

const UserClubPreview = () => {

    const user = useUser()
    const club = AuthService.getAdminClub(user)
    const dispatch = useDispatch()

    return (
        <ClubProfile
            club={club}
            canEdit
            showPublicCTA={false}
            setClub={() => dispatch(initReloadProfile())}
        />
    )
}

export default UserClubPreview