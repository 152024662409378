import React from 'react'
import {Box, Hidden} from "@material-ui/core";
// import Link from "next/link";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";

const useStyle = makeStyles(theme => ({
    component: {
        position: 'sticky',
        top: 0,
        background: '#303030',
        zIndex: 100,
        width: '100%',
        height: 55,
        paddingLeft: 20,
        paddingRight: 10,
        // boxShadow: '0px 10px 55px 5px rgba(65, 196, 126, 0.35)',
        boxShadow: '0px 0x 4px rgb(0 0 0 / 20%)'
    },
    logo: {
        width: 122,
        height: 26,
        objectFit: 'contain',
        zIndex: 1
    },
    actionGreen: {
        marginLeft: 10,
        marginRight: 10,
        height: 36,
        background: theme.palette.primary.main,
        borderRadius: 20,
        paddingLeft: 30,
        paddingRight: 30,
        cursor: 'pointer'
    },
    actionGreenText: {
        fontWeight: 'bold',
        fontSize: 14,
        color: '#FFFFFF',
        textAlign: 'center'
    },
    actionWhite: {
        marginLeft: 10,
        marginRight: 10,
        height: 36,
        background: '#FFFFFF',
        borderRadius: 20,
        paddingLeft: 30,
        paddingRight: 30,
        cursor: 'pointer'
    },
    actionWhiteText: {
        fontWeight: 'bold',
        fontSize: 14,
        color: '#656565',
        textAlign: 'center'
    },
    hidden: {
        marginTop: -60,
        transition: 'transform 1s cubic-bezier(.02,.54,.58,1), opacity 1s',
        opacity: 0,
        transform: 'translate3d(0,-60px,0)'
    },
    visible: {
        transition: 'transform 1s cubic-bezier(.02,.54,.58,1), opacity 1s',
        opacity: 1,
        transform: 'translate3d(0,0,0)'
    },
    buttonsHolder: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            justifyContent: 'space-between'
        }
    }
}))

interface Props {
    visible: boolean
}

const StickyHeader = ({visible}: Props) => {

    const classes = useStyle()
    const t = useTranslation().t
    const history = useHistory()

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            boxSizing={'border-box'}
            className={clsx(classes.component, visible ? classes.visible : classes.hidden)}
        >

            <Hidden mdDown>
                <img alt={'Playerhunter logo'} className={classes.logo} src={require('../../assets/images/icons/logo-white-normal.svg').default}/>
            </Hidden>

            <Box
                className={classes.buttonsHolder}
                display={'flex'}
            >

                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    className={classes.actionWhite}
                    // onClick={() => {
                    //     (window as any).Intercom('show');
                    // }}
                >
                    <a href={'mailto:support@playerhunter.com'} style={{ textDecoration: 'none' }}
                       className={classes.actionWhiteText}> {t("CONTACT US")}</a>
                </Box>

                    {/*<Box*/}
                    {/*    display={'flex'}*/}
                    {/*    justifyContent={'center'}*/}
                    {/*    alignItems={'center'}*/}
                    {/*    className={classes.actionGreen}*/}
                    {/*    onClick={()=>history.push(`/auth/club?subscriptionType=PRO`)}*/}
                    {/*>*/}
                    {/*    <div className={classes.actionGreenText}>{t("FREE TRIAL")}</div>*/}
                    {/*</Box>*/}

            </Box>
        </Box>
    )

}

export default StickyHeader
