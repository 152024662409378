export const FEED_WEIGHTS = `
absoluteLevelMultiplier
alternatingLevelMultiplier
foot
height
id
language
maxLevelPoints
nationality
primaryPosition
secondaryPosition
`;

export const COMPLETION_WEIGHTS = `
age
basicProfileShare
contactInfoEmail
contactInfoMobile
contractInfo
currentClub
currentTeam
gender
height
language
location
name
nationality
pastClub
position
preferredFoot
profilePicture
skillHighlight
skillHighlightNumber
skillVideoFirst
skillVideoNumber
skillVideoSecond
skillVideoThird
stats
statsShare
videoSkillShare
`;

export const APPLICANTS_WEIGHTS = `
basicProfileShare
foot
height
id
language
levelDiffPointsCaseFive
levelDiffPointsCaseFour
levelDiffPointsCaseOne
levelDiffPointsCaseSix
levelDiffPointsCaseThree
levelDiffPointsCaseTwo
mainPosition
nationality
secondaryPositions
skillVideoNumber
statsShare
videoSkillShare
`;

export const COUNTRY = `
id
name
countryCode
phoneCode
`;

export const CITY_SIMPLE = `
id
name
`;

export const CITY_LOCATION = `
id
country {
    ${COUNTRY}
}
name
location {
    latitude
    longitude
}
`

export const CITY_FULL = `
${CITY_SIMPLE}
latitude
longitude
region {
    id
    name
}
`;

export const PAGINATION = `
first
last
number
numberOfElements
size
totalElements
totalPages
`;

export const MEDIA_SIMPLE = `
id
deleted
url
`;

export const MEDIA_FULL = `
${MEDIA_SIMPLE}
width
height
publicId
thumbnailId
album {
    id
    name
}
`;

export const CLUB_SIMPLE = `
    id
    name
    logo
    city {
        ${CITY_SIMPLE}
    }
    country {
        ${COUNTRY}
    }
    clubAdmins {
        id
    }
    location {
        ${CITY_LOCATION}
    }
`;

export const PAYMENT = `
    id
    club {
        id
    }
    clubRequest {
        id
    }
    paymentFrom
    paymentTo
    paymentPackage
    jobLimit
`;

export const ALBUM = `
id
name
media {
    content {
        ${MEDIA_SIMPLE}
    }
}
`;

export const TEAM = `
id
gender
level {
    id
    name
    value
    subjectiveValue
}
teamType {
    id
    name
}
`;

export const ADMIN_BASIC_INFO = `
id
email
profile {
    firstName
    fullName
    lastName
    createdAt
    contactInfo {
        email
        phone
    }
}
userType {
    id
    name
}
`;

export const CLUB_FULL = `
id
name
logo
albums {
    content {
        ${ALBUM}   
    }
    ${PAGINATION}
}
about
country {
  ${COUNTRY}
}
city {
  ${CITY_FULL}
}
location {
  ${CITY_LOCATION}
}
teams {
  ${TEAM}
}
clubAdmins {
    id
    approved
    user {
        ${ADMIN_BASIC_INFO}    
    }
}
admins {
    ${ADMIN_BASIC_INFO}
}
updatedAt
createdAt
`;


export const CLUB_PUBLIC = `
id
name
logo
albums {
    content {
        ${ALBUM}   
    }
    ${PAGINATION}
}
about
country {
  ${COUNTRY}
}
city {
  ${CITY_FULL}
}
teams {
  ${TEAM}
}
updatedAt
createdAt
`;

export const CLUB_FULL_PRIVATE = `
    ${CLUB_FULL}
    payments {
        ${PAYMENT}
    }
`;

export const USER_PROFILE_FULL = `
agencyName
agencyPosition
city {
    ${CITY_FULL}
}
completion
contactInfo {
    email
    phone
}
country {
    ${COUNTRY}
}
createdAt
currentClub {
    ${CLUB_FULL}
}
currentClubRequest {
    id
    name
}    
currentClubTeams {
    ${TEAM}
}    
currentContractFrom
currentContractTo    
dateOfBirth  
externalUrls
firstName
fullName
gender
hasAgent
height
highlightVideos {
    ${MEDIA_SIMPLE}
}
id
instatIndex
languages {
    id
    isoCode
    name
}    
lastName
leagueLevel
location {
    ${CITY_LOCATION}
}
mainPosition
nationalities {
    ${COUNTRY}
}
preferredFoot
previousClubs {
    club {
        ${CLUB_FULL}
    }
    clubRequest {
        id
        name
    }
    seasonFrom
    seasonTo
    previousClubTeams {
        ${TEAM}
    }
}
profilePicture
secondaryPositions
skillMedia {
    id
    media {
        ${MEDIA_SIMPLE}
    }
    skill {
        id
        name
    }
    deleted
}
skipCurrentClub
skipPastClub
stats {
    appearances
    cleanSheets
    goalsReceived
    goals
    assists
    yellowCards
    redCards
    minutesPercentage
}
previousSeasonStats {
    appearances
    cleanSheets
    goalsReceived
    goals
    assists
    yellowCards
    redCards
    minutesPercentage
} 
phIndex
verified
isPublic
transfermarktValue
transferAvailabilityStatus
transferFee
fromSalary
toSalary
`

export const USER_FULL = `
adminOf {
    ${CLUB_FULL}
}
adminOfRequests {
    id
    name
}
canCreateOpportunities
canSearchPlayers
deleted
deviceType
email
externalId
id
lastSeen
loginType
parent {
    id
    userType {
        id
        name
    }
}
passwordResetTokenExpirationTime
profile {
   ${USER_PROFILE_FULL}
}
subscription {
    id
    subscriptionDate
    expirationDate
    type
}
userType {
    id
    name
}
`;

export const USER_WITHOUT_PROFILE = `
id
email
loginType
userType {
    id
    name
}
subscription {
    id
    subscriptionDate
    expirationDate
    type
}
canCreateOpportunities
canSearchPlayers
`;

export const USER_ADMIN_INFO = `
id
adminNote
`

export const USER_PUBLIC = `
id
profile {
    id
    city {
        ${CITY_FULL}
    }
    country {
        ${COUNTRY}
    }
    location {
        ${CITY_LOCATION}
    }
    externalUrls
    firstName
    fullName
    gender
    lastName
    leagueLevel
    profilePicture
    mainPosition
    secondaryPositions
    currentClub {
        ${CLUB_SIMPLE}
    }
    currentContractFrom
    currentContractTo
    currentClubTeams {
        ${TEAM}
    }
    currentClubRequest {
        id
        name
    }
    previousClubs {
        club {
            ${CLUB_SIMPLE}
        }
        clubRequest {
            id
            name
        }
        seasonFrom
        seasonTo
        previousClubTeams {
            ${TEAM}
        }
    }
    dateOfBirth
    preferredFoot
    contactInfo {
        email
        phone
    }
    highlightVideos {
        ${MEDIA_SIMPLE}
    }
    skillMedia {
        id
        media {
            ${MEDIA_SIMPLE}
        }
        skill {
            id
            name
        }
        deleted
    }
    highlightVideos {
        ${MEDIA_SIMPLE}
    }
    instatIndex
    stats {
        appearances
        cleanSheets
        goalsReceived
        goals
        assists
        yellowCards
        redCards
        minutesPercentage
    }
    previousSeasonStats {
        appearances
        cleanSheets
        goalsReceived
        goals
        assists
        yellowCards
        redCards
        minutesPercentage
    }
    phIndex
    height
    languages {
        id
        isoCode
        name
    }
    nationalities {
        ${COUNTRY}
    }
    hasAgent
    verified
    transfermarktValue
    transferAvailabilityStatus
    transferFee
    fromSalary
    toSalary
}
`;

export const USER_PARENT_CONTACT_INFO = `
parent {
    id
    userProfile {
        firstName
        lastName
        agencyName
        contactInfo {
            email
            phone
        }
    }
    userType {
        id
    }
}
`

export const opportunityBuilder = (club: any, city: any, country: any, team: any) => {
    return `
    additionalInfo
    anonymous
    applicants {
        totalElements
    }
    club {
        ${club}
    }
    clubRequest {
        id
        name
    }
    createdAt
    agent {
        id
        profile {
            firstName
            lastName
            profilePicture
        }   
        userType {
            id
            name
        }
    }
    date
    dateAndTime
    deleted
    fromAge
    fromLevel {
      id
      name
      value
      subjectiveValue
    }
    fromSalary
    toLevel  {
      id
      name
      value
      subjectiveValue
    }
    gender
    id
    languages {
      id
      name
      isoCode
    }
    location {
      id
      city {
        ${city}
      }
      country {
        ${country}
      }
      radius
      location {
        ${CITY_LOCATION}
      }
    }
    logoOverride
    minHeight
    name
    nationalities {
      ${country}
    }
    opportunityStatus
    opportunityType
    positions
    preferredFoot
    requirements {
        id
        name
    }
    skillNeeded
    teams {
      ${team}
    }
    toAge
    toSalary
    transferPeriod {
      id
      period
      year
    }
    updatedAt
    premium
    
    minInstatIndex
    minPhIndex
    minMinutesPercentage
    minGoals
    minAssists
    transferFee
    titleOverride
    jobCreatedByAgent
    minTmValue
`
};




export const POSITION_FIELD = `
id
opportunityPosition
userPosition
value
`;

export const AUTH_RESPONSE = `
id
email
firstName
lastName
loginType
token
`;

export const B2C_ADMIN_SUBSCRIPTION_CONFIG = `
active
activeIn {
    id
    countryCode
    name
}
allowedAppleVersion
allowedGoogleVersion
blurEveryNth
canApplyForIntenationalJobs
freeApplicationsRenewalDays
freeUsersCanApplyForPremiumJobs
id
maxApplicationsOnFree
tryToConvertAfterOnboarding
userType {
    id
}
trialPeriod
`;

export const STRIPE_PRODUCT = `
active
attributes
caption
created
deactivateOn
deleted
description
id
images
livemode
metadata
name
object
prices {
    active
    billingScheme
    created
    currency
    deleted
    id
    livemode
    lookupKey
    metadata
    nickname
    object
    recurring {
        aggregateUsage
        interval
        intervalCount
        trialPeriodDays
        usageType
    }
    tiersMode
    type
    unitAmount
    unitAmountDecimal
}
shippable
statementDescriptor
type
unitLabel
updated
url
`

export const STRIPE_PAYMENT_METHOD = `
billingDetails {
    address {
        city
        country
        line1
        line2
        postalCode
        state
    }
    email
    name
    phone
}
card {
    brand
    country
    description
    expMonth
    expYear
    fingerprint
    funding
    iin
    issuer
    last4
}
created
id
livemode
metadata
object
type
`

export const STRIPE_COUPON = `
amountOff
created
currency
deleted
duration
durationInMonths
id
livemode
maxRedemptions
metadata
name
object
percentOff
redeemBy
timesRedeemed
valid
`;

export const STRIPE_DISCOUNT = `
checkoutSession
coupon {
    ${STRIPE_COUPON}        
}
deleted
end
id
invoice
invoiceItem
object
start
subscription  
`

export const STRIPE_PROMO_CODE = `
active
code
coupon {
    ${STRIPE_COUPON}
}
created
expiresAt
id
livemode
maxRedemptions
metadata
object
timesRedeemed
`;

export const STRIPE_CUSTOMER = `
    balance
    created
    currency
    defaultSource
    deleted
    delinquent
    description
    discount {
      ${STRIPE_DISCOUNT}
    }
    email
    id
    invoicePrefix
    invoiceSettings {
        defaultPaymentMethod
        footer
    }
    livemode
    metadata
    name
    nextInvoiceSequence
    object
    phone
    preferredLocales
    taxExempt
`
export const STRIPE_INVOICE = `
accountCountry
accountName
amountDue
amountPaid
amountRemaining
applicationFeeAmount
attemptCount
attempted
autoAdvance
billingReason
collectionMethod
created
currency
customerEmail
customerName
customerPhone
customerTaxExempt
deleted
description
dueDate
endingBalance
footer
hostedInvoiceUrl
id
invoicePdf
livemode
metadata
nextPaymentAttempt
number
object
paid
periodEnd
periodStart
postPaymentCreditNotesAmount
prePaymentCreditNotesAmount
receiptNumber
startingBalance
statementDescriptor
status
subscriptionProrationDate
subtotal
tax
total
webhooksDeliveredAt
paymentIntent{
    clientSecret
    id
  }
`;

export const STRIPE_SUBSCRIPTION = `
applicationFeePercent
billingCycleAnchor
cancelAt
cancelAtPeriodEnd
canceledAt
collectionMethod
created
currentPeriodEnd
currentPeriodStart
daysUntilDue
discount {
    checkoutSession
    coupon {
        amountOff
        created
        currency
        deleted
        duration
        durationInMonths
        id
        livemode
        maxRedemptions
        metadata
        name
        object
        percentOff
        redeemBy
        timesRedeemed
        valid
    }
    deleted
    end
    id
    invoice
    invoiceItem
    object
    start
    subscription
}
endedAt
id
livemode
metadata
nextPendingInvoiceItemInvoice
object
startDate
status
trialEnd
trialStart
latestInvoice {
    ${STRIPE_INVOICE}
}
`
