import React, {useState} from 'react'
import {Avatar, Box, Grid, makeStyles, Typography} from "@material-ui/core"
import User, {UserTypeId, VerifiedStatus} from "../../models/user.model"
import UserService from "../../services/user.service"
import {useHistory} from "react-router"
import UserFilterActions from "./UserFilterActions.component"
import {ListColumn} from "../common/List/List"
import {useListData} from "../../util/data/useListData"
import {useListUtilStyle} from "../common/List/listUtil.style"
import {useBreadcrumbs} from "../../util/data/useBreadcrumbs"
import {useTranslation} from "react-i18next";
import {
    getCurrentClubLocation,
    getCurrentClubName,
    getFullName,
    getUserAge,
    getUserMainPositionLocalizedShort,
    getUserProfilePicture,
    getUserSecondaryPositionsLocalizedShort, getUserTypeIcon
} from "../../util/profile.util";
import ExportUsersPopup from "./ExportUsersPopup/ExportUsersPopup";
import {useUserFilters} from "./useUserFilters";
import {LoginType} from "../../models/loginType.model";
import {useDispatch} from "react-redux";
import {SET_ALGOBUG_FEED_USER} from "../../store/actions/actionTypes";
import {format} from "date-fns";

export const useStyle = makeStyles(theme => ({
    rowUnverified: {
        background: '#fdf7c5'
    }
}))

const UserList = () => {

    //region State

    const classes = useListUtilStyle()
    const localClasses = useStyle()
    const history = useHistory()
    const {t} = useTranslation()
    const [exportWhich, setExportWhich] = useState('')
    const [filters] = useUserFilters()
    const dispatch = useDispatch()

    const columns: ListColumn[] = [
        {name: t('Id'), id: 'id', width: 'xs'},
        {name: t('Name'), id: 'name', width: 'grow'},
        {name: t('Position'), id: 'position', width: 'm'},
        {name: t('Age'), id: 'age', width: 's'},
        {name: t('Club'), id: 'currentClub', width: 'xl'},
        {name: t('Created at'), id: 'createdAt', width: 'm'},
    ]

    const renderCell = (user: User, column: ListColumn) => {
        let currentClubText = getCurrentClubName(user, '-');
        if (getCurrentClubLocation(user)) {
            currentClubText += ` (${getCurrentClubLocation(user)})`
        }

        let dynamicStyle;
        switch (user.profile?.verified) {
            case VerifiedStatus.VERIFIED:
                dynamicStyle = {
                    background: '#57e82b'
                }
                break;
            case VerifiedStatus.NOT_VERIFIED:
                dynamicStyle = {
                    background: '#f85151'
                }
                break;
            case VerifiedStatus.PENDING:
            default:
                dynamicStyle = {
                    background: '#f6de3d'
                }
        }

        switch (column.id) {
            case 'id':
                return (
                    <Typography variant={'body1'}>{user.id}</Typography>
                )
            case 'name':
                return (
                    <React.Fragment>
                        <Avatar className={classes.avatar}
                                src={getUserProfilePicture(user)}>
                            {user.profile?.firstName?.substring(0, 2)}
                        </Avatar>

                        <Box style={{
                            width: 7,
                            height: 40,
                            ...dynamicStyle,
                            marginRight: 10
                        }}/>
                        <Typography variant={'body1'}>
                            {getFullName(user) || '-'}
                        </Typography>

                        <img
                            style={{
                                marginLeft: 10,
                                width: 15,
                                height: 18
                            }}
                            src={getUserTypeIcon(user)}
                        />

                    </React.Fragment>
                )
            case 'position':
                const position = [getUserMainPositionLocalizedShort(t, user)]
                if (getUserSecondaryPositionsLocalizedShort(t, user)?.length) {
                    position.push(`(${getUserSecondaryPositionsLocalizedShort(t, user)})`)
                }
                return (
                    <Typography variant={'body1'}>{position.join(' ')}</Typography>
                )
            case 'age':
                return (
                    <Typography variant={'body1'}>{getUserAge(user, '-')}</Typography>
                )
            case 'currentClub':
                return (
                    <Typography variant={'body1'}>{currentClubText}</Typography>
                )
            case 'createdAt':
                return (
                    <Typography variant={'body1'}>{user.profile?.createdAt ? format(user.profile.createdAt, "dd.MM.yyyy HH:mm") : '-'}</Typography>
                )
            default:
                return null
        }
    }

    const openUser = (user: User) => {
        history.push(`/admin/user/${user.id}`)
    }

    const listData = useListData<User>({
        list: (page, size, search) => UserService.getUsers({
            page: page,
            size: size,
            sort: {
                orders: [{prop: 'id', dir: 'desc'}]
            }
        }, filters),
        renderCell,
        customRowStyle: item => item.profile?.verified === VerifiedStatus.PENDING ? localClasses.rowUnverified : null,
        columns,
        onItemClick: openUser,
        onItemMiddleClick: user => window.open(`${process.env.REACT_APP_WEB_URL}/admin/user/${user.id}`, '_blank'),
        listId: 'usersList',
        filters: filters
    })

    // useEffect(() => {
    //     listData.fetchItems()
    // }, [filters])

    const breadcrumbs = useBreadcrumbs({
        path: [
            {label: t('Users')}
        ]
    })

    //endregion

    //region UI

    return (
        <Box>

            {breadcrumbs}

            <Grid container spacing={3} style={{flex: 1}}>
                <Grid item xs={3} md={3} lg={3} xl={2}>
                    <UserFilterActions
                        selectedUsers={listData.selected}
                        onExportAll={() => setExportWhich('all')}
                        onExportSelected={() => setExportWhich('selected')}
                        onAnalyzeJobFeedForUser={user => {
                            dispatch({
                                type: SET_ALGOBUG_FEED_USER,
                                payload: user
                            })

                            history.push('/admin/algobug?tab=0')
                        }}
                    />
                </Grid>

                <Grid item xs={9} md={9} lg={9} xl={10}>
                    {listData.renderList()}
                </Grid>

            </Grid>

            <ExportUsersPopup
                open={!!exportWhich}
                onClose={() => setExportWhich('')}
                selected={listData.selected.values()}
                exportType={exportWhich}
                filters={filters}
            />

        </Box>
    )

    //endregion UI
}

export default UserList
