import React, {useEffect, useState} from "react"
import {ScreenName} from "../../services/analytics/screenName"
import {PeopleProperty} from "../../services/analytics/peopleProperty"
import {Box, Typography} from "@material-ui/core";
import {StripeProduct} from "../../models/StripeModels/stripeProduct.model";
import MixpanelService from "../../services/analytics/mixpanel.service";
import StickyHeader from "./StickyHeader.component";
import LandingBanner from "./LandingBanner.component";
import AboutUs from "./AboutUs.component";
import WhyPh from "./WhyPh.component";
import CoreValue from "./CoreValue.component";
import HowToUsePh from "./HowToUsePh.component";
import Testimonials from "./Testimonials.component";
import ForPlayers from "./ForPlayers.component";
import Partners from "./Partners.component";
import Footer from "./Footer.component";
import {useHistory} from "react-router";
import Countdown from "../dashboard/Countdown.component";

const IndexPage = () => {

    //region State

    const [visible, setVisible] = useState(false)
    const history = useHistory()

    //endregion State

    //region Handlers

    const handlePackageSelect = (stripeProduct: StripeProduct) => {
        history.push(`/auth/club?subscriptionType=${stripeProduct.metadata.package}`)
    }

    useEffect(() => {
        MixpanelService.trackScreenVisit(ScreenName.LANDING)
        MixpanelService.setUserPropertiesOnce({
            [PeopleProperty.FIRST_VISIT_DATE]: MixpanelService.getCurrentTimeIso()
        })
    }, [])

    const handleScroll = () => {
        const windowHeight = window.innerHeight
        if (window.scrollY > windowHeight * 0.7) {
            setVisible(true)
        } else {
            setVisible(false)
        }
    }

    useEffect(() => {
        handleScroll()

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    //endregion Handlers

    //region UI

    return (
        <React.Fragment>
            <StickyHeader visible={visible}/>

            <Box
                display={'flex'}
                alignItems={'center'}
                flexDirection={'column'}
                style={{
                    minHeight: '100vh',
                    width: '100%',
                    overflow: 'hidden',
                    position: 'relative'
                }}
            >
                <LandingBanner/>

                <Box marginTop={'100px'}>
                    <Countdown/>
                </Box>
                <AboutUs/>
                <WhyPh/>
                <CoreValue/>
                <HowToUsePh/>
                <Testimonials/>
                {/*<Pricing onPackageSelected={handlePackageSelect} showTitle/>*/}
                <ForPlayers/>
                <Partners/>
                <Footer/>
            </Box>
        </React.Fragment>

    )

    //endregion UI

}

IndexPage.getInitialProps = async () => ({
    namespacesRequired: ['common']
})

export default IndexPage
