import apollo from "./api/apollo";
import {GET_CITIES, GET_CURRENT_COUNTRY} from "./api/commonQueries";
import {SeasonModel} from "../models/season.model";
import LanguageModel from "../models/language.model";
import {getYear, isAfter, isBefore, setMonth, startOfMonth} from "date-fns";
import {countries} from "./countries";
import {languages} from "./languages";
import CountryModel from "../models/country.model";
import CityModel from "../models/city.model";

export default class CommonService {

    public static getCountries = async (name: string) => {
        let result;
        if (name) {
            const input = name.toLowerCase();
            result = countries.filter(country => country.name?.toLowerCase().includes(input) || country.nativeName?.toLowerCase().includes(input))
        } else {
            result = countries;
        }

        return result;
    };

    public static getCities = async (countryId?: number, name?: string) => {
        return (await apollo.query({
            query: GET_CITIES,
            variables: {
                countryId: countryId,
                name: name,
                paging: {
                    page: 0,
                    size: 100
                }
            }
        })).data?.cities?.content;
    };

    public static getLanguages = async (name: string) => {
        let result: LanguageModel[] = languages;

        if (name) {
            result = result.filter(language => language?.name?.toLowerCase().includes(name?.toLowerCase()));
        }

        return result;
    };

    public static getTransferPeriods = (): SeasonModel[] => {
        const currentDate = new Date();
        const february = startOfMonth(setMonth(new Date(), 1));
        const september = startOfMonth(setMonth(new Date(), 8));

        if (isAfter(currentDate, february) && isBefore(currentDate, september)) {
            return [
                {id: getYear(new Date()) * 2, year: getYear(new Date()), period: 'SUMMER'},
                {id: getYear(new Date()), year: getYear(new Date()), period: 'WINTER'},
            ]
        } else {
            return [
                {id: getYear(new Date()), year: getYear(new Date()), period: 'WINTER'},
                {id: (getYear(new Date()) + 1) * 2, year: getYear(new Date()) + 1, period: 'SUMMER'},
            ]
        }
    }

    public static getCurrentCountry = async (): Promise<CountryModel> => {
        return (await apollo.query({
            query: GET_CURRENT_COUNTRY
        })).data?.currentCountry;
    };

    public static covertWebCountryToApiCountry = (country?: CountryModel) => {
        if (!country) return

        return {
            id: country.id,
            name: country.name,
            countryCode: country.countryCode,
            phoneCode: country.phoneCode
        }
    }

    public static covertWebCityToApiCity = (city?: CityModel) => {
        if (!city) return

        return {
            id: city.id,
            name: city.name,
        }
    }

}
