import ClubModel from "./club.model";

export enum SubscriptionType {
    FREE = 'FREE',
    BASIC = 'BASIC',
    PREMIUM = 'PREMIUM',
    PRO = 'PRO',
    TOP_TIER = 'TOP_TIER',
    AMATEUR = 'AMATEUR'
}

export enum SubscriptionDuration {
    S_1_MONTH = '1_MONTH',
    S_12_MONTHS = '12_MONTHS',
    S_24_MONTHS = '24_MONTHS',
}

export interface SubscriptionModel {
    id?: number;
    club?: ClubModel,
    paymentFrom?: Date;
    paymentTo?: Date;
    paymentType?: SubscriptionType;
    paymentDuration?: SubscriptionDuration;
    paymentPackage?: String;
    jobLimit?: number;
}

export const SUBSCRIPTION_PRIORITY = {
    [SubscriptionType.FREE]: 0,
    [SubscriptionType.BASIC]: 100,
    [SubscriptionType.PREMIUM]: 200,
    [SubscriptionType.PRO]: 300,
    [SubscriptionType.TOP_TIER]: 400,
};
