import React, {useEffect, useRef, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from './CountrySearch.module.scss';
import debounce from "lodash/debounce";
import clsx from 'clsx';
import CountryModel from "../../../models/country.model";
import CommonService from "../../../services/common.service";
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@material-ui/core";
import {getCountryFlag} from "../../../util/flag.util";
import {regions} from "../../../services/countries";

interface Props {
    open: boolean;
    onCountryPicked: (country: any) => void;
    onClose: () => void;
    title?: string;
    showRegions?: boolean;
    showCountryPhoneCode?: boolean;
    limitToCountries?: string[];
    excludeCountries?: CountryModel[]
}

export interface OptionType {
    label: string;
    value: any;
    icon?: string;
}

const CountrySearch = (props: Props) => {

    // ***** State ***** //

    const [query, setQuery] = useState('');
    const [countries, setCountries] = useState<{ label: string; country: CountryModel }[]>([]);

    const t = useTranslation().t;

    // ***** Handlers ***** //

    const executeSearch = useRef(debounce((query: string) => getCountries(query), 500)).current;

    const queryHandler = (event: any) => {
        setQuery(event.target.value);
        executeSearch(event.target.value)
    };

    const getCountries = async (query: string) => {
        let countries = await CommonService.getCountries(query);

        if (props.limitToCountries) {
            countries = countries.filter(c => props.limitToCountries?.find(code => code === c.countryCode));
        }

        if (props.excludeCountries?.length) {
            countries = countries.filter(c => !props.excludeCountries?.find(excluded => excluded.id === c.id))
        }

        const suggestions = countries.map((country: any) => {
            let label = '';
            if (props.showCountryPhoneCode) {
                label += `(+${country.phoneCode}) `;
            }

            label += `${country.name} ${country.nativeName ? '(' + country.nativeName + ')' : ''}`;

            return {
                label,
                country
            }
        });

        setCountries(suggestions);
    };

    useEffect(() => {
        if (props.open) {
            setQuery('');
            getCountries('');
        }
    }, [props.open]);

    // ***** Render ***** //

    const renderCountries = () => {
        return countries.map((country) => {
            return (
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    key={country?.label}
                    className={styles.country}
                    onClick={() => props.onCountryPicked(country?.country)}
                >
                    <div className={clsx(styles.flag, 'flag-icon', getCountryFlag(country?.country))}/>
                    <div>{country?.label}</div>
                </Box>
            )
        })
    };

    const renderRegions = () => {
        if (!props.showRegions) {
            return null;
        }

        return (
            <React.Fragment>
                {
                    regions.map(region => (
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            key={region.countryCode} className={styles.country}
                            onClick={() => props.onCountryPicked(region)}
                        >
                            <div className={clsx(styles.flag, 'flag-icon', getCountryFlag(region))}/>
                            <div>{region.name}</div>
                        </Box>
                    ))
                }
                <div className={styles.regionSeparator}/>
            </React.Fragment>
        )
    };

    return (
        <Dialog aria-labelledby="simple-dialog-title" {...props} maxWidth={"sm"} fullWidth={true}
                PaperProps={{
                    style: {
                        margin: 8,
                        width: 'calc(100% - 16px)'
                    },
                }}
        >
            <Box
                display={'flex'}
                flexDirection={'column'}
                className={styles.container}
            >

                {props.title ? <div className={styles.title}>{props.title}</div> : null}

                <TextField
                    onChange={queryHandler}
                    value={query}
                    className={styles.searchField}
                    id="outlined-search"
                    label={t("search")}
                    type="search"
                    margin="normal"
                />

                <Box className={styles.countries}>

                    {renderRegions()}

                    {renderCountries()}

                </Box>

            </Box>
        </Dialog>
    );
};

export default CountrySearch;
