import React, {useEffect, useState} from "react";
import Page from "../../models/page.model";
import {GeneralSet} from "../../components/common/generalSet";
import BaseModel from "../../models/base.model";
import EList, {ListColumn} from "../../components/common/List/List";
import {useUrlParam} from "./url.util";
import {useQuery, useQueryClient} from "@tanstack/react-query";

interface Props<T extends BaseModel> {
    list?: (page: number, size: number, search?: string, filters?: any) => Promise<Page<T>>
    columns?: ListColumn[]
    renderCell?: (item: T, column: ListColumn) => JSX.Element | null,
    renderRowAdditionalComponent?: (item: T) => JSX.Element | null,
    customRowStyle?: (item: T) => string | null,
    onItemClick?: (item: T, index: number) => void,
    onItemMiddleClick?: (item: T, index: number) => void,
    classes?: {
        root?: string
    },
    onAdd?: () => void,
    listId: string,
    filters?: any,
    onNewLoadTriggered?: () => void
}


export const useListData = <T extends BaseModel>(props: Props<T>) => {

    const paramName = (name: string) => {
        return props.listId ? `${props.listId}-${name}` : name
    }

    // const [items, setItems] = useState<Page<T>>()
    const [size, setSize] = useUrlParam({defaultValue: 10, param: paramName('listSize'), dataType: "numeric"})
    const [page, setPage] = useUrlParam({defaultValue: 1, param: paramName('listPage'), dataType: "numeric"})
    const [search, setSearch] = useUrlParam({defaultValue: '', param: paramName('listSearch')})
    const [selected, setSelected] = useState<GeneralSet<T>>(new GeneralSet())
    // const [loading, setLoading] = useState(false)
    const queryClient = useQueryClient()


    const fetchItems = async () => {
        // setLoading(true)
        // setSelected(new GeneralSet())
        const result = await props.list?.(page - 1, size, search)
        // setItems(result)
        // setLoading(false)
        return result
    }


    const queryKey = ['list', props.listId, size, page, search, props.filters]
    const query = useQuery(queryKey, fetchItems, {
        staleTime: 5 * 1000,
    })
    const items = query.data

    const setItems = (newItems: Page<T>) => {
        queryClient.setQueryData(queryKey, _ => newItems)
    }

    useEffect(() => {
        setSelected(new GeneralSet())
        props.onNewLoadTriggered?.()
    }, [size, page, search, props.filters])

    const renderList = (): JSX.Element => {
        return (
            <EList
                items={items?.content}
                columns={props.columns || []}
                renderCell={props.renderCell}
                size={size}
                onSizeChange={setSize}
                page={page}
                onPageChange={setPage}
                totalPages={items?.totalPages || 0}
                totalElements={items?.totalElements || 0}
                onItemClick={props.onItemClick}
                onItemMiddleClick={props.onItemMiddleClick}
                customRowStyle={props.customRowStyle}
                // onSearch={setSearch}
                multiselect
                loading={query.isLoading || query.isFetching}
                selected={selected}
                onSelect={user => {
                    if (selected.has(user)) {
                        setSelected(selected.deleteImmutable(user))
                    } else {
                        setSelected(selected.addImmutable(user))
                    }
                }}
                onSelectAll={() => {
                    if (items?.content?.length && selected.size() === items.content.length) {
                        setSelected(new GeneralSet())
                    } else {
                        setSelected(new GeneralSet(items?.content))
                    }
                }}
                classes={props.classes}
                onAdd={props.onAdd}
                renderRowAdditionalComponent={props.renderRowAdditionalComponent}
            />
        )
    }

    return {
        items,
        setItems,
        size,
        setSize,
        page,
        setPage,
        search,
        setSearch,
        selected,
        setSelected,
        fetchItems,
        renderList,
        loading: query.isLoading || query.isFetching
    }
}
