import {Avatar, Box, Card, makeStyles, Typography} from "@material-ui/core";
import React from "react";
import {useHistory} from "react-router";
import {useDispatch, useStore} from "react-redux";
import {useTranslation} from "react-i18next";
import {getFullName, getProfileProfilePicture} from "../../util/profile.util";
import {useUser} from "../../store/store.utils";
import {SET_USER} from "../../store/actions/actionTypes";
import {initLogout} from "../../store/actions/authActions";
import clsx from "clsx";

const useStyle = makeStyles(theme => ({
    component: {
        flex: 1,
        height: 62,
        position: 'sticky',
        top: 0,
        zIndex: 100
    },
    content: {
        height: '100%',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    avatar: {
        width: 38,
        height: 38,
        marginRight: theme.spacing(2)
    },
    languageHolder: {
        cursor: 'pointer',
        width: 150
    },
    languageIcon: {
        width: 25,
        height: 30,
        objectFit: 'contain',
        marginRight: theme.spacing(1)
    }
}))

const AdminHeader = () => {

    const classes = useStyle()
    const history = useHistory()
    const user = useUser()
    // const image = CloudinaryService.getImage(user?.avatar)?.resize(thumbnail().width(50).height(50))
    const {t, i18n} = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const store = useStore()
    const dispatch = useDispatch()

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng)
    }

    return (
        <Card className={clsx('hide-on-print', classes.component)}>
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                flexGrow={1}
                className={classes.content}
            >

                <Box
                >
                    <Box
                        aria-describedby={!!anchorEl ? 'language-picker' : undefined}
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                            setAnchorEl(event.currentTarget);
                        }}
                        display={'flex'}
                        flexDirection={'row'}
                        className={classes.languageHolder}
                        alignItems={'center'}
                    >

                        {/*<img src={getIconForLanguage(i18n.language)} className={classes.languageIcon}/>*/}
                        {/*<Typography>{getNameForLanguage(i18n.language)}</Typography>*/}
                    </Box>

                    {/*<Popover*/}
                    {/*    id={!!anchorEl ? 'language-picker' : undefined}*/}
                    {/*    open={!!anchorEl}*/}
                    {/*    anchorEl={anchorEl}*/}
                    {/*    onClose={() => {*/}
                    {/*        setAnchorEl(null)*/}
                    {/*    }}*/}
                    {/*    anchorOrigin={{*/}
                    {/*        vertical: 'bottom',*/}
                    {/*        horizontal: 'center',*/}
                    {/*    }}*/}
                    {/*    transformOrigin={{*/}
                    {/*        vertical: 'top',*/}
                    {/*        horizontal: 'center',*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {*/}
                    {/*        ['en', 'de'].map(lng => (*/}
                    {/*            <Box*/}
                    {/*                key={lng}*/}
                    {/*                display={'flex'}*/}
                    {/*                alignItems={'center'}*/}
                    {/*                paddingLeft={2}*/}
                    {/*                paddingRight={2}*/}
                    {/*                paddingTop={1}*/}
                    {/*                paddingBottom={1}*/}
                    {/*                className={classes.languageHolder}*/}
                    {/*                onClick={async () => {*/}
                    {/*                    changeLanguage(lng)*/}
                    {/*                    setAnchorEl(null)*/}
                    {/*                }}*/}
                    {/*            >*/}
                    {/*                <img*/}
                    {/*                    className={classes.languageIcon}*/}
                    {/*                    src={getIconForLanguage(lng)}*/}
                    {/*                />*/}
                    {/*                <Typography>{getNameForLanguage(lng)}</Typography>*/}
                    {/*            </Box>*/}
                    {/*        ))*/}
                    {/*    }*/}
                    {/*</Popover>*/}
                </Box>

                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    marginLeft={'auto'}
                    onClick={() => {
                        dispatch(initLogout())
                        history.push('/auth/login')
                    }}
                >
                    <Avatar
                        className={classes.avatar}
                        src={getProfileProfilePicture(user?.profile)}
                    >
                        {getFullName(user, '-')?.substring(0, 2)}
                    </Avatar>

                    <Box>
                        <Typography variant={'body1'}>{getFullName(user, '-')}</Typography>
                        <Typography variant={'subtitle2'}>{t("Admin")}</Typography>
                    </Box>

                </Box>

                {/*<Button*/}
                {/*    onClick={() => {*/}
                {/*        localStorage.removeItem('token')*/}
                {/*        store.dispatch({type: StateActions.SET_USER, payload: undefined})*/}
                {/*        history.push('/auth/login')*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {t("Logout")}*/}
                {/*</Button>*/}
            </Box>
        </Card>
    )
}

export default AdminHeader
