import React, {useEffect, useState} from 'react'
import {Avatar, Box, Grid, makeStyles, Typography} from "@material-ui/core"
import {useHistory} from "react-router"
import {ListColumn} from "../common/List/List"
import {useListData} from "../../util/data/useListData"
import {useListUtilStyle} from "../common/List/listUtil.style"
import {useBreadcrumbs} from "../../util/data/useBreadcrumbs"
import {useTranslation} from "react-i18next";
import {getClubLogo, getClubName} from "../../util/profile.util";
import ClubModel from "../../models/club.model";
import ClubsService from "../../services/clubs.service";
import {getCurrentSubscriptionType} from "../../util/payment.util";
import {format} from "date-fns"
import {UserTypeId, VerifiedStatus} from "../../models/user.model";

export const useStyle = makeStyles(theme => ({
    createdByClub: {
        background: '#fdc5c5'
    }
}))

const ClubRequestList = () => {

    //region State

    const classes = useListUtilStyle()
    const localClasses = useStyle()
    const history = useHistory()
    const {t} = useTranslation()

    const columns: ListColumn[] = [
        {name: t('Id'), id: 'id', width: 'xs'},
        {name: t('Club'), id: 'club', width: 'grow'},
        {name: t('Country'), id: 'country', width: 'm'},
        {name: t('City'), id: 'city', width: 'm'},
        {name: t('Created At'), id: 'createdAt', width: 'm'},
        {name: t('Subscription'), id: 'subscription', width: 'm'},
    ]

    const renderCell = (club: ClubModel, column: ListColumn) => {

        switch (column.id) {
            case 'id':
                return (
                    <Typography variant={'body1'}>{club.id}</Typography>
                )
            case 'club':
                return (
                    <React.Fragment>
                        <Avatar className={classes.avatar}
                                src={getClubLogo(club)}/>
                        <Box style={{
                            width: 7,
                            height: 40,
                            marginRight: 10
                        }}/>
                        <Typography variant={'body1'}>
                            {getClubName(club) || '-'}
                        </Typography>

                    </React.Fragment>
                )
            case 'country':
                return (
                    <Typography variant={'body1'}>{club.country?.name || '-'}</Typography>
                )
            case 'city':
                return (
                    <Typography variant={'body1'}>{club.city?.name || '-'}</Typography>
                )
            case 'createdAt':
                let createdAt = '-'
                if (club.createdAt) {
                    createdAt = format(new Date(club.createdAt), 'dd.MM.yyyy HH:mm')
                }
                return (
                    <Typography variant={'body1'}>{createdAt}</Typography>
                )
            case 'subscription':
                return (
                    <Typography variant={'body1'}>{getCurrentSubscriptionType(club)}</Typography>
                )
            default:
                return null
        }
    }

    const openClubRequest = (club: ClubModel) => {
        history.push(`/admin/clubRequest/${club.id}`)
    }

    const listData = useListData<ClubModel>({
        list: (page, size, search) => ClubsService.getClubRequests({
            page: page,
            size: size,
            sort: {
                orders: [{prop: 'id', dir: 'desc'}]
            }
        }, true),
        renderCell,
        customRowStyle: item => item.createdBy?.userType?.id === UserTypeId.STAFF_TYPE_ID ? localClasses.createdByClub : null,
        columns,
        onItemClick: openClubRequest,
        onItemMiddleClick: club => window.open(`${process.env.REACT_APP_WEB_URL}/admin/clubRequest/${club.id}`, '_blank'),
        listId: 'clubRequestsList',
    })

    useEffect(() => {
        listData.fetchItems()
    }, [])

    const breadcrumbs = useBreadcrumbs({
        path: [
            {label: t('Requests')}
        ]
    })

    //endregion

    //region UI

    return (
        <Box>

            {breadcrumbs}

            <Grid container spacing={3} style={{flex: 1}}>

                <Grid item xs={12}>
                    {listData.renderList()}
                </Grid>

            </Grid>


        </Box>
    )

    //endregion UI
}

export default ClubRequestList
