export enum EventName {
    SCREEN_VISITED = 'screen_visited',
    BRANCH_INSTALL = '[Branch] INSTALL',
    BRANCH_OPEN = '[Branch] OPEN',
    DISMISS_OPPORTUNITY = 'dismiss_opportunity',
    APPLY_OPPORTUNITY = 'apply_opportunity',
    SHARE_OPPORTUNITY = 'share_opportunity',
    OPPORTUNITY_MORE_INFO = 'opportunity_more_info',
    MOVE_TO_NEXT_OPPORTUNITY = 'move_to_next_opportunity',
    MOVE_TO_PREVIOUS_OPPORTUNITY = 'move_to_previous_opportunity',
    SIGN_UP_TRIGGERED = 'sign_up_triggered',
    SIGN_UP = 'sign_up',
    SIGN_IN = 'sign_in',
    NAME_AND_GENDER = 'name_and_gender',
    BIRTHDATE_AND_NATIONALITY = 'birthdate_and_nationality',
    POSITION = 'position',
    CURRENT_CLUB = 'current_club',
    CURRENT_LOCATION = 'current_location',
    PHONE_NUMBER = 'phone_number',
    STATS_ENTERED = 'stats_entered',
    PAST_EXPERIENCE = 'past_experience',
    PROFILE_PICTURE = 'profile_picture',
    FOOT_HEIGHT = 'foot_height',
    SKILL_VIDEO_UPLOAD_STARTED = 'skill_video_upload_started',
    SKILL_VIDEO_UPLOAD_FINISHED = 'skill_video_upload_finished',
    SKILL_VIDEO_DELETED = 'skill_video_deleted',
    HIGHLIGHT_VIDEOS = 'highlight_videos',
    HIGHLIGHTS_VIDEO_DELETED = 'highlights_video_deleted',
    HIGHLIGHTS_VIDEO_UPLOAD_STARTED = 'highlights_video_upload_started',
    HIGHLIGHTS_VIDEO_UPLOAD_FINISHED = 'highlights_video_upload_finished',
    MATCH_SETTINGS = 'match_settings',
    OPPORTUNITY_NOT_SUCCESSFUL = 'opportunity_not_successful',
    CANCEL_APPLICATION = 'cancel_application',
    OPPORTUNITY_TOP_PICK = 'opportunity_top_pick',
    INVITES = 'invites',
    CHANGED_CLUB_QUESTION = 'changed_club_question',
    EDIT_PLAYER_CARD = 'edit_player_card',
    ONBOARDING_SUCCESS = 'onboarding_success',
    REFERRAL = 'referral',
    SUPPORT_CHAT = 'support_chat',
    NOTIFICATION_SETTING = "notification_setting",
    OPPORTUNITIES_CREATED = 'opportunities created',
    PLAYER_INVITED = 'player invited',
    PLAYER_REJECTED = 'player rejected',
    APPLICANT_DETAIL_REVIEW = 'applicant detail review',
    APPLICANT_CONTACT_STATUS = "applicant contact status",
    APPLICANT_GET_IN_TOUCH = "applicant get in touch",
    CLUB_ALREADY_TAKEN = 'club already taken',
    OPPORTUNITY_CREATION_STARTED = 'start_adding_opportunity',
    APPLICANT_REVIEW = 'applicant review',
    CLUB_EDIT = 'club edit',
    OPPORTUNITY_CREATED = 'opportunity_created',
    APPLICANT_INVITED = 'applicant_invited',
    APPLICANT_REJECTED = 'applicant_rejected',
    APPLICANT_DELETED = 'applicant_deleted',

    // ***** B2C ***** //

    B2C_TRIGGER = 'b2c_trigger',                                        // Event that triggers modal open
    B2C_START_PURCHASE = 'b2c_start_purchase',                          // When user click on "Continue"
    B2C_PURCHASE_SUCCESS = 'b2c_purchase_success',                      // When buying package is done
    B2C_PURCHASE_FAILED = 'b2c_purchase_failed',                        // When buying package failed for any reason
    B2C_PROMO_CODE_ADDED = 'b2c_promo_code_added',                      // When user adds VALID code
    B2C_SPECIAL_OFFER_CARD_SEEN = 'b2c_special_offer_card_seen',        // When user sees special opportunity card

    // ***** ADS ***** //
    ADS_IMPRESSION = 'ads_impression',                                  // Each time user scrolls to ad
    ADS_CLICK = 'ads_click'                                             // When user click on image or button in ad
}
