import React, {useEffect, useState} from 'react'
import Dialog from "@material-ui/core/Dialog";
import {Box, Button, CircularProgress, DialogActions, TextField, Typography} from "@material-ui/core";
import styles from "../club/preview/ClubHeader/EditNamePopup/EditNamePopup.module.scss";
import {useTranslation} from "react-i18next";
import UserModel from "../../models/user.model";
import UserService from "../../services/user.service";

interface Props {
    open: boolean
    onClose: () => void
    user?: UserModel
    onChanged: () => void
}

const ChangeEmail = (props: Props) => {

    const t = useTranslation().t
    const [email, setEmail] = useState('')
    const [state, setState] = useState<'input' | 'saving' | 'error'>('input')

    useEffect(() => {
        if (props.open) {
            setEmail('')
            setState('input')
        }
    }, [props.open])

    const handleChangePassword = async () => {
        setState('saving')
        try {
            const response = await UserService.adminUpdateEmail(props.user!, email)
            setState('input')
            props.onChanged()
            props.onClose()
        } catch (e) {
            setState('error')
        }
    }

    return (
        <Dialog {...props} maxWidth={"xs"} fullWidth={true}>

            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                className={styles.container}>

                <TextField
                    id="filled-multiline-flexible"
                    label={'New email'}
                    placeholder={'New email'}
                    value={email}
                    className={styles.input}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                    autoFocus
                />

                {
                    state === 'error' &&
                    <Typography
                        style={{color: 'red'}}
                    >
                        Error while changing email. Make sure email is not used by other users
                    </Typography>
                }

            </Box>

            <DialogActions>
                {
                    state === 'saving' ?
                        <CircularProgress/> :
                        <React.Fragment>
                            <Button onClick={props.onClose} color="primary">
                                Close
                            </Button>
                            <Button
                                disabled={email.length < 6}
                                onClick={handleChangePassword} color="primary">
                                Save
                            </Button>
                        </React.Fragment>
                }


            </DialogActions>
        </Dialog>
    )
}

export default ChangeEmail