import React from 'react'
import {Avatar, Box, Button, makeStyles, Typography} from "@material-ui/core"
import {useTranslation} from "react-i18next";
import CloudinaryService from "../../../services/cloudinary.service";
import MediaModel from "../../../models/media.model";

interface Props {
    image?: string,
    setImage?: (media?: MediaModel) => void,
    name?: string
}

const useStyle = makeStyles(theme => ({
    avatarImage: {
        width: 200,
        height: 200,
        borderRadius: 10,
        objectFit: 'cover'
    }
}))

const GenericNameAvatar = ({image, setImage, name}: Props) => {

    const classes = useStyle()
    const {t} = useTranslation()

    const handleImageUpload = async () => {
        const uploadedImage = await CloudinaryService.uploadMedia({multiple: false, resourceType: 'image'})
        setImage?.({
            url: uploadedImage.secure_url,
            publicId: uploadedImage.public_id,
        })
    }

    //endregion Handlers

    //region UI

    const avatar = (
        <Avatar src={image} className={classes.avatarImage}>
            {name?.substring(0, 2)}
        </Avatar>
    )

    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginBottom={4}
        >

            {avatar}
            <Box
                display={'flex'}
                flexDirection={'column'}
                marginLeft={3}
            >

                <Typography variant={"h3"}>{name}</Typography>

                <Box m={2}/>

                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={handleImageUpload}
                >
                    {t("Change avatar")}
                </Button>
            </Box>

        </Box>
    )

    //endregion UI

}

export default GenericNameAvatar
