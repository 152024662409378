import {Avatar, Box, Card, makeStyles, Typography} from "@material-ui/core";
import React from "react";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {getClubLogo, getClubName, getFullName} from "../../util/profile.util";
import {useBillingState, useUser} from "../../store/store.utils";
import {
    SET_ADD_PAYMENT_OPEN,
    SET_PRODUCT_PRICE,
    SET_SUBSCRIBE_OPEN,
    SET_SUBSCRIBING_TO_AMATEUR,
    SET_SUBSCRIBING_TO_PRO_TRIAL_IN_PROGRESS,
    SET_SUBSCRIPTION_MESSAGE
} from "../../store/actions/actionTypes";
import SubscribePopup from "../billing/SubscribePopup/SubscribePopup";
import AuthService from "../../services/auth.service";
import Popup from "./Popup/Popup";
import ActivatingProTrialPopup from "../billing/ActivatingProTrialPopup";
import {
    loadInvoices,
    loadStripeCustomer,
    loadStripeSubscription,
    setUpgradePopupOpen
} from "../../store/actions/billing.actions";
import ActivatingAmateurPopup from "../billing/ActivatingAmateurPopup";
import AddPaymentMethod from "../billing/AddPaymentMethod.component";
import SubscribeTo from "../billing/SubscribeTo/SubscribeTo.component";
import {NavLink} from "react-router-dom";

const useStyle = makeStyles(theme => ({
    component: {
        flex: 1,
        height: 62,
        position: 'sticky',
        top: 0,
        zIndex: 100,
        marginTop: 10
    },
    content: {
        height: '100%',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    avatar: {
        width: 38,
        height: 38,
        marginRight: theme.spacing(2)
    },
    languageHolder: {
        cursor: 'pointer',
        width: 150
    },
    languageIcon: {
        width: 25,
        height: 30,
        objectFit: 'contain',
        marginRight: theme.spacing(1)
    },
    navLinkStyle: {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
        marginRight: 40
    },
    navLinkActiveStyle: {
        color: theme.palette.primary.main
    }
}))

const ClubHeader = () => {

    const classes = useStyle()
    const history = useHistory()
    const user = useUser()
    const club = AuthService.getAdminClub(user)
    // const image = CloudinaryService.getImage(user?.avatar)?.resize(thumbnail().width(50).height(50))
    const {t, i18n} = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const dispatch = useDispatch()

    const {
        isActive,
        isTrialing,
        isTryingToCharge,
        subscriptionMessage,
        addPaymentMethodOpen,
        subscribeToPackageOpen,
        product,
        price,
        daysUntilTrialEnds,
        hasAnySubscription,
        upgradePopupTrigger,
        upgradePopupOpen
    } = useBillingState()

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng)
    }

    const renderPopups = () => {

        return (
            <React.Fragment>

                <SubscribePopup
                    open={upgradePopupOpen}
                    onClose={() => dispatch(setUpgradePopupOpen(false))}
                    trigger={upgradePopupTrigger}
                    club={club}
                />

                <Popup
                    open={!!subscriptionMessage}
                    onClose={() => dispatch({type: SET_SUBSCRIPTION_MESSAGE, payload: ''})}
                    text={subscriptionMessage}
                />

                <ActivatingProTrialPopup
                    // onConfirm={(async (product, price) => {
                    //     store.dispatch({type: SET_PRODUCT_PRICE, payload: {product, price}})
                    //     store.dispatch({type: SET_SUBSCRIBING_TO_PRO_TRIAL_IN_PROGRESS, payload: false})
                    //     store.dispatch({type: SET_SUBSCRIBE_OPEN, payload: true})
                    // })}
                    onDone={subscribed => {
                        dispatch({type: SET_SUBSCRIBING_TO_PRO_TRIAL_IN_PROGRESS, payload: false})

                        setTimeout(() => {
                            dispatch(loadStripeCustomer(club) as any)
                            dispatch(loadStripeSubscription(club) as any)
                            dispatch(loadInvoices(club) as any)
                        }, 2000)

                        if (subscribed) {
                            dispatch({
                                type: SET_SUBSCRIPTION_MESSAGE,
                                payload: t('Successfully subscribed to Stripe package', {packageName: product?.name})
                            })
                        }
                    }}
                />

                <ActivatingAmateurPopup
                    onConfirm={((product, price) => {
                        dispatch({type: SET_PRODUCT_PRICE, payload: {product, price}})
                        dispatch({type: SET_SUBSCRIBING_TO_AMATEUR, payload: false})
                        dispatch({type: SET_ADD_PAYMENT_OPEN, payload: true})
                    })}
                />

                <AddPaymentMethod
                    open={addPaymentMethodOpen}
                    onExistingMethodsLoaded={methods => {
                        if (methods.length) {
                            dispatch({type: SET_ADD_PAYMENT_OPEN, payload: false})
                            dispatch({type: SET_SUBSCRIBE_OPEN, payload: true})
                        }
                    }}
                    onDone={added => {
                        dispatch({type: SET_ADD_PAYMENT_OPEN, payload: false})
                        if (added) {
                            dispatch({type: SET_SUBSCRIBE_OPEN, payload: true})
                        }
                    }}
                />

                <SubscribeTo
                    product={product}
                    price={price}
                    open={subscribeToPackageOpen}
                    onDone={subscribed => {
                        dispatch({type: SET_SUBSCRIBE_OPEN, payload: false})

                        setTimeout(() => {
                            dispatch(loadStripeCustomer(club) as any)
                            dispatch(loadStripeSubscription(club) as any)
                            dispatch(loadInvoices(club) as any)
                        }, 2000)

                        if (subscribed) {
                            dispatch({
                                type: SET_SUBSCRIPTION_MESSAGE,
                                payload: t('Successfully subscribed to Stripe package', {packageName: product?.name})
                            })
                        }
                    }}
                />

            </React.Fragment>
        )
    };

    const languageUI = (
        <Box
        >
            <Box
                aria-describedby={!!anchorEl ? 'language-picker' : undefined}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    setAnchorEl(event.currentTarget);
                }}
                display={'flex'}
                flexDirection={'row'}
                className={classes.languageHolder}
                alignItems={'center'}
            >

                {/*<img src={getIconForLanguage(i18n.language)} className={classes.languageIcon}/>*/}
                {/*<Typography>{getNameForLanguage(i18n.language)}</Typography>*/}
            </Box>

            {/*<Popover*/}
            {/*    id={!!anchorEl ? 'language-picker' : undefined}*/}
            {/*    open={!!anchorEl}*/}
            {/*    anchorEl={anchorEl}*/}
            {/*    onClose={() => {*/}
            {/*        setAnchorEl(null)*/}
            {/*    }}*/}
            {/*    anchorOrigin={{*/}
            {/*        vertical: 'bottom',*/}
            {/*        horizontal: 'center',*/}
            {/*    }}*/}
            {/*    transformOrigin={{*/}
            {/*        vertical: 'top',*/}
            {/*        horizontal: 'center',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {*/}
            {/*        ['en', 'de'].map(lng => (*/}
            {/*            <Box*/}
            {/*                key={lng}*/}
            {/*                display={'flex'}*/}
            {/*                alignItems={'center'}*/}
            {/*                paddingLeft={2}*/}
            {/*                paddingRight={2}*/}
            {/*                paddingTop={1}*/}
            {/*                paddingBottom={1}*/}
            {/*                className={classes.languageHolder}*/}
            {/*                onClick={async () => {*/}
            {/*                    changeLanguage(lng)*/}
            {/*                    setAnchorEl(null)*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                <img*/}
            {/*                    className={classes.languageIcon}*/}
            {/*                    src={getIconForLanguage(lng)}*/}
            {/*                />*/}
            {/*                <Typography>{getNameForLanguage(lng)}</Typography>*/}
            {/*            </Box>*/}
            {/*        ))*/}
            {/*    }*/}
            {/*</Popover>*/}
        </Box>
    )

    return (
        <Card className={classes.component}>
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                flexGrow={1}
                className={classes.content}
            >

                <NavLink
                    className={classes.navLinkStyle}
                    activeClassName={classes.navLinkActiveStyle}
                    to={'/club/dashboard'}
                >
                    {t('Jobs')}
                </NavLink>

                <NavLink
                    className={classes.navLinkStyle}
                    activeClassName={classes.navLinkActiveStyle}
                    to={'/club/about'}
                >
                    {t('Club')}
                </NavLink>

                <NavLink
                    className={classes.navLinkStyle}
                    activeClassName={classes.navLinkActiveStyle}
                    to={'/club/account'}
                >
                    {t('Account')}
                </NavLink>

                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    marginLeft={'auto'}
                    onClick={() => {
                        // localStorage.removeItem('token')
                        // dispatch({type: SET_USER, payload: undefined})
                        history.push('/club/account')
                    }}
                >
                    <Avatar
                        className={classes.avatar}
                        src={getClubLogo(club) || require('../../assets/images/fake-club.svg').default}
                    >
                        {getFullName(user, '-')?.substring(0, 2)}
                    </Avatar>

                    <Box>
                        <Typography variant={'body1'}>{getFullName(user, '-')}</Typography>
                        <Typography variant={'subtitle2'}>{getClubName(club)}</Typography>
                    </Box>

                </Box>

                {/*<Button*/}
                {/*    onClick={() => {*/}
                {/*        localStorage.removeItem('token')*/}
                {/*        store.dispatch({type: StateActions.SET_USER, payload: undefined})*/}
                {/*        history.push('/auth/login')*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {t("Logout")}*/}
                {/*</Button>*/}
            </Box>

            {renderPopups()}
        </Card>
    )
}

export default ClubHeader
