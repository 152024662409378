import {useHistory} from "react-router";
import * as queryString from "query-string";
import {handleDates} from "../../services/api/apollo";
import {useEffect, useState} from "react";
import _ from "lodash";
import {decode, encode} from 'js-base64';

interface Props<T> {
    param: string,
    dataType?: 'numeric' | 'string' | 'date' | 'complex' | 'boolean'
    isArray?: boolean,
    defaultValue: T
}

export const useUrlParam = <T>(props: Props<T>): [T, (v: T) => void, boolean] => {

    const history = useHistory()
    const parsed = queryString.parse(history.location.search)
    const exists = !!parsed[props.param]

    let paramValue: T;
    switch (props.dataType) {
        case "numeric":
            paramValue = exists ? +parsed[props.param]! as unknown as T : props.defaultValue
            break;
        case "date":
            paramValue = exists ? new Date(parsed[props.param] as string) as unknown as T : props.defaultValue
            break;
        case "complex": {
            if (exists) {
                paramValue = JSON.parse(decode(parsed[props.param] as string))
                handleDates(paramValue)
            } else {
                paramValue = props.defaultValue
            }
        }
            break;
        case "boolean":
            paramValue = exists ? (parsed[props.param] === 'true') as unknown as T : props.defaultValue
            break;
        case "string":
        default:
            paramValue = exists ? parsed[props.param] as unknown as T : props.defaultValue
            break;

    }

    const [currentValue, setCurrentValue] = useState<T>(paramValue)

    useEffect(() => {
        if (!_.isEqual(paramValue, currentValue)) {
            setCurrentValue(paramValue)
        }
    }, [history.location.search])

    const setParamValue = (newValue: T) => {
        let valueToStore;
        switch (props.dataType) {
            case "numeric":
                valueToStore = newValue
                break;
            case "string":
                valueToStore = newValue
                break;
            case "boolean":
                valueToStore = newValue
                break;
            case "date":
                valueToStore = (newValue as unknown as Date)?.toISOString()
                break;
            case "complex":
                valueToStore = encode(JSON.stringify(newValue))
                break;

        }

        history.replace({
            search: queryString.stringify({
                ...parsed,
                [props.param]: valueToStore
            })
        })
    }

    return [currentValue, setParamValue, exists]
}

const encodeValue = (value?: any, dataType?: 'numeric' | 'string' | 'date' | 'complex' | 'boolean') => {
    let valueToStore = value;
    switch (dataType) {
        case "numeric":
            valueToStore = value
            break;
        case "string":
            valueToStore = value
            break;
        case "boolean":
            valueToStore = value
            break;
        case "date":
            valueToStore = (value as unknown as Date)?.toISOString()
            break;
        case "complex":
            valueToStore = encode(JSON.stringify(value))
            break;
    }
    return valueToStore
}

export const setMultipleParams = (data: { name: string, value: any, dataType?: any }[], history: any) => {
    const parsed = queryString.parse(history.location.search)
    data.forEach(d => {
        parsed[d.name] = encodeValue(d.value, d.dataType)
    })

    history.replace({
        search: queryString.stringify(parsed)
    })
}

export const removeMultipleParams = (params: string[], history: any) => {
    const parsed = queryString.parse(history.location.search)
    params.forEach(d => {
        delete parsed[d]
    })

    history.replace({
        search: queryString.stringify(parsed)
    })
}
