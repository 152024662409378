import {Box, Card, Tab, Tabs} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {useFilterStyles} from "../common/List/list.style"
import DeviceHubOutlinedIcon from '@material-ui/icons/DeviceHubOutlined'
import FilterTiltShiftOutlinedIcon from '@material-ui/icons/FilterTiltShiftOutlined'
import User from "../../models/user.model"
import {GeneralSet} from "../common/generalSet"
import EButton from "../common/EButton"
import {useTranslation} from "react-i18next";
import DashboardFilters2, {FILTERS} from "../common/DashboardFilters/DashboardFilters.component";
import {useHistory} from "react-router";
import {useClubFilters} from "./useClubFilters";

interface Props {
    selected: GeneralSet<User>,

    onExportAll?: () => void,
    onExportSelected?: () => void,
    onFiltersChange?: (filters: any) => void
}

const ClubFilterActions = (props: Props) => {

    const filterClasses = useFilterStyles()
    const [tab, setTab] = useState(0)
    const {t} = useTranslation()
    const history = useHistory()
    const [filters, clearAllFilters] = useClubFilters()

    useEffect(() => {
        if (props.selected.size()) {
            setTab(1)
        }
    }, [props.selected])

    // region Filters

    const renderFilters = () => (
        <Box display={'flex'} flexDirection={'column'}>

            <DashboardFilters2 filters={[
                FILTERS.NAME,
                FILTERS.COUNTRIES,
                FILTERS.SUBSCRIPTION_TYPES,
                FILTERS.ONLY_WITH_UNRESOLVED_ADMINS
            ]}/>

            <EButton
                color={'error'}
                style={{alignSelf: 'center', width: '100%', marginTop: 5}}
                onClick={clearAllFilters}
            >
                Clear all filters
            </EButton>

        </Box>
    )

    const renderActions = () => (
        <Box display={'flex'} flexDirection={'column'} p={2}>

            <EButton
                variant={'contained'}
                color={'primary'}
                onClick={props.onExportAll}
            >
                Export all
            </EButton>

            <Box m={1}/>

            {
                props.selected.size() > 0 &&
                <React.Fragment>
                    <EButton
                        variant={'contained'}
                        color={'primary'}
                        onClick={props.onExportSelected}
                    >
                        Export selected
                    </EButton>
                    <Box m={2}/>
                </React.Fragment>
            }

        </Box>
    )

    return (
        <Card className={filterClasses.container}>
            <Box p={2}>
                <Tabs
                    textColor={'primary'}
                    indicatorColor={'primary'}
                    value={tab}
                    onChange={(_e, selected) => setTab(selected)}
                >
                    <Tab icon={<FilterTiltShiftOutlinedIcon/>} label={t('Filters')} tabIndex={0}/>
                    <Tab icon={<DeviceHubOutlinedIcon/>} label={t('Actions')} tabIndex={1}/>
                </Tabs>
            </Box>

            {tab === 0 && renderFilters()}
            {tab === 1 && renderActions()}

        </Card>
    )
}

export default ClubFilterActions
