import React, {FunctionComponent, useEffect, useRef, useState} from 'react'
import Dialog from "@material-ui/core/Dialog"
import styles from './ExportJobsPopup.module.scss'
import * as Excel from 'exceljs'
import {saveAs} from "file-saver"
import {Box, CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core"
import {useTranslation} from "react-i18next"
import Job from "../../../models/job.model";
import {
    getOpportunityAgeFrom,
    getOpportunityAgeTo,
    getOpportunityClubName,
    getOpportunityGender,
    getOpportunityLanguageNames,
    getOpportunityLevel,
    getOpportunityLevelFrom,
    getOpportunityLevelTo,
    getOpportunityLocationCity,
    getOpportunityLocationCountry,
    getOpportunityLocationRadius,
    getOpportunityMinHeight,
    getOpportunityNationalityNames,
    getOpportunityPositionName,
    getOpportunityPreferredFoot,
    getOpportunitySkillVideosRequired,
    getOpportunityTransferPeriod
} from "../../../util/opportunity.util";
import OpportunityApplicationModel from "../../../models/opportunityApplication.model";
import PositionMapModel from "../../../models/positionMap.model";
import {
    getAllSkillVideosForUser,
    getCityCountryName,
    getCurrentClub,
    getCurrentClubName,
    getCurrentClubTeams,
    getCurrentContractFormatted,
    getEmail,
    getFullName,
    getGender,
    getHighlightVideos,
    getPreferredFoot,
    getPreviousClubName,
    getPreviousClubs,
    getUserAge,
    getUserHeight,
    getUserLanguages,
    getUserMainPosition,
    getUserNationalities,
    getUserProfilePicture,
    getUserSecondaryPositions,
    getUserStat
} from "../../../util/profile.util";
import AlgorithmService from "../../../services/algorithm.service";
import OpportunityService from "../../../services/opportunity.service";
import ApplicationsService from "../../../services/applications.service";
import EButton from "../../common/EButton";

interface Props {
    open: boolean,
    onClose: () => void,
    selected: Job[],
    exportType: string,
    filters: any,
}

const ExportJobsPopup: FunctionComponent<Props> = (props: Props) => {

    //region State

    const [exportLevel, setExportLevel] = useState('opportunity')
    const [opportunitiesStatus, setOpportunitesStatus] = useState('')
    const [applicantsStatus, setApplicantsStatus] = useState('')
    const [downloading, setDownloading] = useState(false)

    const shouldBeCanceled = useRef(false)

    const t = useTranslation().t

    //endregion State

    //region Handlers

    useEffect(() => {
        if (props.open) {
            setApplicantsStatus('')
            setOpportunitesStatus('')
        }
    }, [props.open])

    //endregion Handlers

    //region Data export

    const addWorksheetColumns = (worksheet: Excel.Worksheet) => {
        const opportunityColumns = [
            {header: 'Opportunity id', key: 'opportunityId', width: 32},
            {header: 'Opportunity gender', key: 'opportunityGender', width: 32},
            {header: 'Opportunity age from', key: 'opportunityAgeFrom', width: 32},
            {header: 'Opportunity age to', key: 'opportunityAgeTo', width: 32},
            {header: 'Opportunity level from', key: 'opportunityLevelFrom', width: 32},
            {header: 'Opportunity level to', key: 'opportunityLevelTo', width: 32},
            {header: 'Opportunity team level', key: 'opportunityTeamLevel', width: 32},
            {header: 'Opportunity positions', key: 'opportunityPositions', width: 32},
            {header: 'Opportunity nationalities', key: 'opportunityNationalities', width: 32},
            {header: 'Opportunity languages', key: 'opportunityLanguages', width: 32},
            {header: 'Opportunity min height', key: 'opportunityMinHeight', width: 32},
            {header: 'Opportunity preferred foot', key: 'opportunityPreferredFoot', width: 32},
            {header: 'Opportunity skill video required', key: 'opportunitySkillVideoRequired', width: 32},
            {header: 'Opportunity transfer period', key: 'opportunityTransferPeriod', width: 32},
            {header: 'Opportunity country', key: 'opportunityCountry', width: 32},
            {header: 'Opportunity city', key: 'opportunityCity', width: 32},
            {header: 'Opportunity radius', key: 'opportunityRadius', width: 32},
            {header: 'Opportunity club', key: 'opportunityClub', width: 32},
        ]

        const applicantColumns = [
            {header: 'Applicant id', key: 'applicantId', width: 32},
            {header: 'Applicant name', key: 'applicantName', width: 32},
            {header: 'Applicant email', key: 'applicantEmail', width: 32},
            {header: 'Applicant age', key: 'applicantAge', width: 32},
            {header: 'Applicant current club', key: 'applicantCurrentClub', width: 32},
            {header: 'Applicant current teams', key: 'applicantCurrentTeams', width: 32},
            {header: 'Applicant current contract', key: 'applicantCurrentContract', width: 32},
            {header: 'Applicant level', key: 'applicantLevel', width: 32},
            {header: 'Applicant main position', key: 'applicantMainPosition', width: 32},
            {header: 'Applicant secondary positions', key: 'applicantSecondaryPositions', width: 32},
            {header: 'Applicant appearances', key: 'applicantAppearances', width: 32},
            {header: 'Applicant assists', key: 'applicantAssists', width: 32},
            {header: 'Applicant clean sheets', key: 'applicantCleanSheets', width: 32},
            {header: 'Applicant goals', key: 'applicantGoals', width: 32},
            {header: 'Applicant goals received', key: 'applicantGoalsReceived', width: 32},
            {header: 'Applicant red cards', key: 'applicantRedCards', width: 32},
            {header: 'Applicant yellow cards', key: 'applicantYellowCards', width: 32},
            {header: 'Applicant skill videos', key: 'applicantSkillVideos', width: 32},
            {header: 'Applicant highlight videos', key: 'applicantHighlightVideos', width: 32},
            {header: 'Applicant profile picture', key: 'applicantProfilePicture', width: 32},
            {header: 'Applicant gender', key: 'applicantGender', width: 32},
            {header: 'Applicant location', key: 'applicantLocation', width: 32},
            {header: 'Applicant preferred foot', key: 'applicantPreferredFoot', width: 32},
            {header: 'Applicant previous clubs', key: 'applicantPreviousClubs', width: 32},
            {header: 'Applicant height', key: 'applicantHeight', width: 32},
            {header: 'Applicant languages', key: 'applicantLanguages', width: 32},
            {header: 'Applicant nationalities', key: 'applicantNationalities', width: 32},
            {header: 'Applicant status', key: 'applicantStatus', width: 32},
            // {header: 'Applicant relevance', key: 'applicantRelevance', width: 32},
            {header: 'Applicant algo score (only for non invited)', key: 'applicantScore', width: 32},

            // Relevance related
            {header: 'Position matrix score', key: 'applicantPositionMatrixScore', width: 32},
            {header: 'Has profile picture', key: 'applicantHasProfilePicture', width: 32},
            {header: 'Has current club', key: 'applicantHasCurrentClub', width: 32},
            {header: 'Has current or past club', key: 'applicantHasCurrentOrPastClub', width: 32},
            {header: 'Estimated relevance', key: 'applicantEstimatedRelevance', width: 32},
        ]

        const columns = [...opportunityColumns]
        if (exportLevel === 'full') {
            columns.push(...applicantColumns)
        }

        worksheet.columns = columns
    }

    const generateOpportunityData = (opportunity: Job) => {
        return {
            opportunityId: opportunity.id,
            opportunityGender: getOpportunityGender(opportunity),
            opportunityAgeFrom: getOpportunityAgeFrom(opportunity),
            opportunityAgeTo: getOpportunityAgeTo(opportunity),
            opportunityLevelFrom: getOpportunityLevelFrom(opportunity),
            opportunityLevelTo: getOpportunityLevelTo(opportunity),
            opportunityTeamLevel: getOpportunityLevel(opportunity),
            opportunityPositions: getOpportunityPositionName(opportunity),
            opportunityNationalities: getOpportunityNationalityNames(opportunity, []).join(', '),
            opportunityLanguages: getOpportunityLanguageNames(opportunity, []).join(', '),
            opportunityMinHeight: getOpportunityMinHeight(opportunity),
            opportunityPreferredFoot: getOpportunityPreferredFoot(opportunity),
            opportunitySkillVideoRequired: getOpportunitySkillVideosRequired(opportunity),
            opportunityTransferPeriod: getOpportunityTransferPeriod(opportunity) ? `${getOpportunityTransferPeriod(opportunity).period} ${getOpportunityTransferPeriod(opportunity).year}` : '',
            opportunityCountry: getOpportunityLocationCountry(opportunity),
            opportunityCity: getOpportunityLocationCity(opportunity),
            opportunityRadius: getOpportunityLocationRadius(opportunity),
            opportunityClub: getOpportunityClubName(opportunity)
        }
    }

    const generateApplicantData = (opportunity: Job, application: OpportunityApplicationModel, positionMatrix: PositionMapModel[]) => {
        const user = application.createdBy

        return {
            applicantId: user?.id,
            applicantName: getFullName(user),
            applicantEmail: getEmail(user),
            applicantAge: getUserAge(user),
            applicantCurrentClub: getCurrentClubName(user),
            applicantCurrentTeams: getCurrentClubTeams(user).map(t => t.teamType?.name).join(', '),
            applicantCurrentContract: getCurrentContractFormatted(t, user),
            applicantLevel: 'TODO', // TODO getLevelData(user).value,
            applicantMainPosition: getUserMainPosition(user),
            applicantSecondaryPositions: getUserSecondaryPositions(user).join(', '),
            applicantAppearances: getUserStat(user, 'appearances'),
            applicantAssists: getUserStat(user, 'assists'),
            applicantCleanSheets: getUserStat(user, 'cleanSheets'),
            applicantGoals: getUserStat(user, 'goals'),
            applicantGoalsReceived: getUserStat(user, 'goalsReceived'),
            applicantRedCards: getUserStat(user, 'redCards'),
            applicantYellowCards: getUserStat(user, 'yellowCards'),
            applicantSkillVideos: getAllSkillVideosForUser(user).length,
            applicantHighlightVideos: getHighlightVideos(user).length,
            applicantProfilePicture: getUserProfilePicture(user),
            applicantGender: getGender(user),
            applicantLocation: getCityCountryName(user),
            applicantPreferredFoot: getPreferredFoot(user),
            applicantPreviousClubs: getPreviousClubs(user).map(getPreviousClubName).join(', '),
            applicantHeight: getUserHeight(user),
            applicantLanguages: getUserLanguages(user).map(l => l.name).join(', '),
            applicantNationalities: getUserNationalities(user).map(n => n.name).join(', '),
            applicantStatus: application.status,
            // applicantRelevance: application.relevance,
            applicantScore: application.score || null,

            // Relevance related
            applicantPositionMatrixScore: 'TODO', // TODO getPositionMatrixScore(positionMatrix, opportunity, application.createdBy),
            applicantHasProfilePicture: getUserProfilePicture(user) ? 'YES' : 'NO',
            applicantHasCurrentClub: getCurrentClub(user) ? 'YES' : 'NO',
            applicantHasCurrentOrPastClub: !!getCurrentClub(user) || !!getPreviousClubs(user, []).length ? 'YES' : 'NO',
            applicantEstimatedRelevance: 'TODO' // calculateRelevance(positionMatrix, opportunity, application.createdBy)
        }
    }

    const fillRows = async (worksheet: Excel.Worksheet, opportunities: Job[], opportunityApplicants: any) => {
        let positionMatrix: PositionMapModel[] = []
        if (exportLevel === 'full') {
            positionMatrix = await AlgorithmService.getPositionMatrixApplicants()
        }

        for (let i = 0; i < opportunities.length; i++) {
            const opportunity = opportunities[i]
            const opportunityData = generateOpportunityData(opportunity)

            if (exportLevel === 'opportunity') {
                worksheet.addRow(opportunityData)
            }

            if (exportLevel === 'full') {
                const applicants = opportunityApplicants[opportunity?.id!]

                for (let j = 0; j < applicants.length; j++) {
                    const application: OpportunityApplicationModel = applicants[j]
                    let applicantData = generateApplicantData(opportunity, application, positionMatrix)
                    worksheet.addRow({
                        ...opportunityData,
                        ...applicantData
                    })
                }

            }
        }
    }

    const exportToFile = async (opportunities: Job[], opportunityApplicants: any) => {
        let workbook = new Excel.Workbook()

        const worksheet = workbook.addWorksheet('Opportunities')
        await addWorksheetColumns(worksheet)
        await fillRows(worksheet, opportunities, opportunityApplicants)

        const buf = await workbook.xlsx.writeBuffer()
        saveAs(new Blob([buf]), 'export.xlsx')
    }

    const startExport = async () => {
        let opportunities = []
        let opportunityApplicants: any = {}

        setDownloading(true)
        shouldBeCanceled.current = false

        if (props.exportType === 'all') {
            let allOpportunitiesDownloaded = false
            let page = 0
            let totalElements
            setOpportunitesStatus('Downloading opportunities...')
            while (!allOpportunitiesDownloaded) {
                if (shouldBeCanceled.current) {
                    setOpportunitesStatus('')
                    return
                }

                let downloadedPage = await OpportunityService.getAllJobsAdmin({page, size: 20}, props.filters)

                totalElements = downloadedPage.totalElements
                opportunities.push(...(downloadedPage?.content || []))
                page++

                if ((downloadedPage?.content?.length || 0) < 20) {
                    allOpportunitiesDownloaded = true
                }

                setOpportunitesStatus(`Downloading opportunities... Downloaded ${opportunities.length} opportunities (of total ${totalElements})`)
            }
        } else {
            opportunities = props.selected
        }

        setOpportunitesStatus(`Total opportunities for export: ${opportunities.length}`)

        if (exportLevel === 'full') {
            for (let i = 0; i < opportunities.length; i++) {
                setApplicantsStatus(`Downloading applicants for opportunity ${i}/${opportunities.length}`)
                const opportunity = opportunities[i]
                if (!opportunity.id) continue
                const invited = await ApplicationsService.fetchInvited(opportunity.id, 0, 1000)
                opportunityApplicants[opportunity.id!] = [...(invited?.content || [])]

                let applicantsPage = 0
                let download = true
                while (download) {
                    if (shouldBeCanceled.current) {
                        setOpportunitesStatus('')
                        setApplicantsStatus('')
                        return
                    }

                    const response = await ApplicationsService.fetchOther(opportunity.id, applicantsPage, 20)
                    opportunityApplicants[opportunity.id].push(...(response?.content || []))
                    if (!response?.last) {
                        applicantsPage++
                    } else {
                        download = false
                    }
                }

                applicantsPage = 0
                download = true
                while (download) {
                    if (shouldBeCanceled.current) {
                        setOpportunitesStatus('')
                        setApplicantsStatus('')
                        return
                    }

                    const response = await ApplicationsService.fetchRejected(opportunity.id, applicantsPage, 20)
                    opportunityApplicants[opportunity.id].push(...(response?.content || []))
                    if (!response?.last) {
                        applicantsPage++
                    } else {
                        download = false
                    }
                }
            }

            setApplicantsStatus('All applicants downloaded')
        }

        await exportToFile(opportunities, opportunityApplicants)
        setDownloading(false)
    }

    const cancelExport = async () => {
        setDownloading(false)
        shouldBeCanceled.current = true
    }

    //region Data export

    //region UI

    const titleContent = props.exportType === 'all' ?
        <div className={styles.title}>You want to export data for ALL filtered opportunities</div> :
        <div className={styles.title}>You want to export data for {props.selected.length} opportunities</div>

    const exportLevelPicker = (
        <FormControl component="fieldset" className={styles.exportOptions}>
            <FormLabel component="legend">Export level</FormLabel>
            <RadioGroup aria-label="exportLevel" name="exportLevel" value={exportLevel}
                        onChange={(e: any) => setExportLevel(e.target.value)}>
                <FormControlLabel value="opportunity" control={<Radio/>} label="Opportunities"/>
                <FormControlLabel value="full" control={<Radio/>} label="Opportunities + Applicants"/>
            </RadioGroup>
        </FormControl>
    )

    const exportWarning = exportLevel !== 'opportunity' && (
        <div className={styles.exportWarning}>
            When exporting applicants data the total amount of data downloaded is:
            Number of opportunities x Number of applicants per opportunity.
            This may take a long time, so please be patient (or filter/select less) :)
        </div>
    )

    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            {...props}
            maxWidth={"xs"}
            fullWidth={true}
            disableBackdropClick={downloading}
        >

            <Box className={styles.container}>
                {titleContent}
                {exportLevelPicker}
                {exportWarning}

                {!!opportunitiesStatus && <div className={styles.progressText}>{opportunitiesStatus}</div>}
                {!!applicantsStatus && <div className={styles.progressText}>{applicantsStatus}</div>}

                {downloading && <CircularProgress className={styles.progressIndicator}/>}

                {downloading ?
                    <EButton variant="contained"
                             className={styles.ctaButton}
                             color="primary"
                             onClick={cancelExport}>
                        Cancel
                    </EButton>
                    :
                    <EButton variant="contained"
                             className={styles.ctaButton}
                             color="primary"
                             onClick={startExport}>
                        Start export
                    </EButton>
                }
            </Box>
        </Dialog>
    )

    //endregion UI
}

export default ExportJobsPopup
