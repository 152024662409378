import {isAfter} from "date-fns";
import ClubModel from "../models/club.model";
import {SubscriptionModel, SubscriptionType} from "../models/subscription.model";

export const shouldRenewSubscription = (club?: ClubModel) => {
    const endDate = getCurrentSubscriptionEndDate(club);
    return endDate && isAfter(new Date(), endDate);
};

export const getCurrentSubscription = (club?: ClubModel): SubscriptionModel | undefined => {
    return club?.payments?.[0];
};

export const getCurrentSubscriptionType = (club?: ClubModel): SubscriptionType => {
    const currentSubscription = getCurrentSubscription(club);
    return (currentSubscription && currentSubscription.paymentType) || SubscriptionType.BASIC;
};

export const isOnFreePackage = (club?: ClubModel): boolean => {
    const currentSubscription = getCurrentSubscriptionType(club);
    return !currentSubscription || currentSubscription === SubscriptionType.FREE || currentSubscription === SubscriptionType.BASIC;
};

export const getCurrentSubscriptionMaxJobs = (club?: ClubModel): number => {
    const currentSubscription = getCurrentSubscription(club);
    return (currentSubscription && currentSubscription.jobLimit) || 0;
};

export const getCurrentSubscriptionEndDate = (club?: ClubModel): Date | undefined => {
    const currentSubscription = getCurrentSubscription(club);
    const endDate = currentSubscription && currentSubscription.paymentTo;
    const type = getCurrentSubscriptionType(club);

    if (endDate) {
        return new Date(endDate);
    } else {
        return type === SubscriptionType.BASIC ? undefined : new Date();
    }
};

export const getCurrentSubscriptionStartDate = (club?: ClubModel): Date | undefined => {
    const currentSubscription = getCurrentSubscription(club);
    const startDate = currentSubscription && currentSubscription.paymentFrom;
    const type = getCurrentSubscriptionType(club);

    if (startDate) {
        return new Date(startDate);
    } else {
        return type === SubscriptionType.BASIC ? undefined : new Date();
    }
};
