import React, {useState} from 'react'
import {Avatar, Box, Button, Card, Chip, Grid, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import UserModel, {UserTypeId} from "../../../models/user.model";
import ETextField from "../../common/ETextField.component";
import {getUserMainPosition} from "../../../util/profile.util";
import ESwitchField from "../../common/ESwitchField.component";
import PickClubPopup from "../../common/PickClubPopup/PickClubPopup";
import PastClubModel from "../../../models/pastClub.model";
import _ from 'lodash'
import ClubModel from "../../../models/club.model";
import Team from "../../../models/team.model";
import EButton from "../../common/EButton";
import EDateField from "../../common/EDateField.component";
import {getGridSizes} from "../../common/grid.util";
import {Alert} from "@material-ui/lab";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {LoginType} from "../../../models/loginType.model";

interface Props {
    user?: UserModel,
    setUser: (user: UserModel) => void,
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
    input: {
        width: '100%'
    }
}))


const ClubsStatsTab = ({user, setUser}: Props) => {

    const classes = useStyle()
    const history = useHistory()
    const {t, i18n} = useTranslation()

    const [map, setMap] = useState()
    const [marker, setMarker] = useState<any>()

    const profile = user?.profile || {}
    const isRegularUser = user?.userType?.id === UserTypeId.REGULAR_TYPE_ID
    const isAgent = user?.userType?.id === UserTypeId.AGENT_TYPE_ID
    const isClubAdmin = user?.userType?.id === UserTypeId.STAFF_TYPE_ID
    const isAgentsPlayer = user?.loginType === LoginType.SUBUSER

    const [pickClubPopupOpen, setPickClubPopupOpen] = useState(false)
    const [pickingClubFor, setPickingClubFor] = useState(-1)

    const updateProfileFields = (data: { field: string, value: any }[]) => {
        const newUser: any = {
            ...user,
            profile: {
                ...user?.profile
            }
        }
        data.forEach(d => newUser.profile[d.field] = d.value)
        setUser(newUser)
    }

    const updateProfileField = (field: string, value: any) => {
        updateProfileFields([{field, value}])
    }

    const setStatValue = (e: string, statsField: 'stats' | 'previousSeasonStats', fieldName: string) => {
        let newValue
        if (e !== '') {
            newValue = +e
        }
        setUser({
            ...user,
            profile: {
                ...user?.profile,
                [statsField]: {
                    ...user?.profile?.[statsField],
                    [fieldName]: newValue
                }
            }
        })
    }

    //endregion Handlers

    //region UI

    const statsUI = (field: 'stats' | 'previousSeasonStats') => (
        <Grid
            container
            spacing={2}
            className={classes.sectionData}
        >

            <ETextField
                grid={'1/3'}
                label={t('Appearances')}
                value={user?.profile?.[field]?.appearances}
                onChange={e => setStatValue(e, field, 'appearances')}
            />

            {
                getUserMainPosition(user) === 'Goalkeeper' ?
                    <React.Fragment>
                        <ETextField
                            grid={'1/3'}
                            label={t('Clean Sheets')}
                            value={user?.profile?.[field]?.cleanSheets}
                            onChange={e => setStatValue(e, field, 'cleanSheets')}
                        />
                        <ETextField
                            grid={'1/3'}
                            label={t('Goals Received')}
                            value={user?.profile?.[field]?.goalsReceived}
                            onChange={e => setStatValue(e, field, 'goalsReceived')}
                        />
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <ETextField
                            grid={'1/3'}
                            label={t('Goals')}
                            value={user?.profile?.[field]?.goals}
                            onChange={e => setStatValue(e, field, 'goals')}
                        />
                        <ETextField
                            grid={'1/3'}
                            label={t('Assists')}
                            value={user?.profile?.[field]?.assists}
                            onChange={e => setStatValue(e, field, 'assists')}
                        />
                    </React.Fragment>
            }

            <ETextField
                grid={'1/3'}
                label={t('Yellow Cards')}
                value={user?.profile?.[field]?.yellowCards}
                onChange={e => setStatValue(e, field, 'yellowCards')}
            />
            <ETextField
                grid={'1/3'}
                label={t('Red Cards')}
                value={user?.profile?.[field]?.redCards}
                onChange={e => setStatValue(e, field, 'redCards')}
            />
            <ETextField
                grid={'1/3'}
                label={t('Minutes played (%)')}
                value={user?.profile?.[field]?.minutesPercentage}
                onChange={e => setStatValue(e, field, 'minutesPercentage')}
            />

        </Grid>
    )

    const renderClubInfo = (
        club?: ClubModel,
        onPickClub?: () => void,
        onDeleteClub?: () => void,
        selectedTeams?: Team[],
        onUpdateTeams?: (teams?: Team[]) => void,
        showContract?: boolean,
        contractFrom?: Date,
        onUpdateContractFrom?: (date: Date) => void,
        contractTo?: Date,
        onUpdateContractTo?: (date: Date) => void
    ) => {
        if (!club) return (
            <Card style={{padding: 20, marginBottom: 40}}>
                <Grid container spacing={4} alignItems={'center'}>
                    <Grid item {...getGridSizes('1/2')}>
                        <Alert severity="warning">No club set</Alert>
                    </Grid>
                    <Grid item {...getGridSizes('1/2')}>
                        <EButton
                            variant={'contained'}
                            color={'primary'}
                            onClick={onPickClub}
                        >
                            Add current club
                        </EButton>
                    </Grid>
                </Grid>
            </Card>
        )

        return (
            <Card style={{padding: 20, marginBottom: 40}}>
                <Grid container spacing={5} alignContent={'center'} alignItems={'center'}>

                    <Grid item {...getGridSizes('1/1')}>
                        <Box display={'flex'} alignItems={'center'}>
                            <Avatar src={club.logo} title={club.name}/>
                            <Typography style={{marginLeft: 20}}>{club.name}</Typography>
                        </Box>
                    </Grid>

                    {
                        showContract &&
                        <EDateField
                            grid={'1/3'}
                            label={'Contract from'}
                            value={contractFrom}
                            onChange={d => onUpdateContractFrom?.(d as Date)}
                        />
                    }

                    {
                        showContract &&
                        <EDateField
                            grid={'1/3'}
                            label={'Contract to'}
                            value={contractTo}
                            onChange={d => onUpdateContractTo?.(d as Date)}
                        />
                    }

                    {
                        showContract &&
                        <Grid item {...getGridSizes('1/3')} />
                    }

                    {
                        !!club &&
                        <React.Fragment>
                            <Grid item {...getGridSizes('1/6')}>
                                Selected teams
                            </Grid>

                            <Grid item {...getGridSizes('2/3')}>
                                {selectedTeams?.map(team => {
                                    return (
                                        <Chip
                                            color={'primary'}
                                            key={team.id}
                                            label={`${team.gender}, Level ${team.level?.value}, ${team.teamType?.name}`}
                                            onDelete={() => {
                                                onUpdateTeams?.(selectedTeams.filter(f => f !== team))
                                            }}
                                            variant="outlined"
                                        />
                                    )
                                })}
                            </Grid>

                            <Grid item {...getGridSizes('1/6')} />

                            <Grid item {...getGridSizes('1/6')}>
                                Available teams
                            </Grid>

                            <Grid item {...getGridSizes('2/3')}>
                                {
                                    club?.teams?.filter(team => {
                                        return !selectedTeams?.find(t => t.id === team.id)
                                    })
                                        .map(team => {
                                            return (
                                                <Chip
                                                    key={team.id}
                                                    label={`${team.gender}, Level ${team.level?.value}, ${team.teamType?.name}`}
                                                    onClick={() => {
                                                        onUpdateTeams?.([...(selectedTeams || []), team])
                                                    }}
                                                    variant="outlined"
                                                />
                                            )
                                        })}
                            </Grid>

                            <Grid item {...getGridSizes('1/6')} />

                        </React.Fragment>
                    }

                    {
                        !!club &&
                        <Grid item {...getGridSizes('1/2')}>
                            <Box display={'flex'}>
                                <EButton color={'primary'} onClick={onPickClub}>Change</EButton>
                                <Box m={2}/>
                                <EButton color={'error'} onClick={onDeleteClub}>Remove</EButton>
                            </Box>
                        </Grid>
                    }


                </Grid>
            </Card>
        )

    }

    const clubUI = renderClubInfo(
        user?.profile?.currentClub || user?.profile?.currentClubRequest,
        () => {
            setPickingClubFor(-1)
            setPickClubPopupOpen(true)
        },
        () => {
            setUser({
                ...user,
                profile: {
                    ...user?.profile,
                    currentClub: undefined,
                    currentClubRequest: undefined,
                    currentClubTeams: []
                }
            })
        },
        user?.profile?.currentClubTeams,
        (teams) => setUser({
            ...user,
            profile: {
                ...user?.profile,
                currentClubTeams: teams
            }
        })
    )

    const pastClubUI = user?.profile?.previousClubs?.map((previousClub, index) => {
        return renderClubInfo(
            previousClub.club || previousClub.clubRequest,
            () => {
                setPickingClubFor(index)
                setPickClubPopupOpen(true)
            },
            () => {
                const newPreviousClubs: PastClubModel[] = _.cloneDeep(user?.profile?.previousClubs || [])
                newPreviousClubs.splice(index, 1)

                setUser({
                    ...user,
                    profile: {
                        ...user?.profile,
                        previousClubs: newPreviousClubs
                    }
                })
            },
            previousClub.previousClubTeams,
            (teams) => {
                const newPreviousClub = _.cloneDeep(previousClub)
                newPreviousClub.previousClubTeams = teams

                const newPreviousClubs: PastClubModel[] = _.cloneDeep(user?.profile?.previousClubs || [])
                newPreviousClubs[index] = newPreviousClub

                setUser({
                    ...user,
                    profile: {
                        ...user?.profile,
                        previousClubs: newPreviousClubs
                    }
                })
            },
            true,
            previousClub.seasonFrom,
            date => {
                const newPreviousClub = _.cloneDeep(previousClub)
                newPreviousClub.seasonFrom = date

                const newPreviousClubs: PastClubModel[] = _.cloneDeep(user?.profile?.previousClubs || [])
                newPreviousClubs[index] = newPreviousClub

                setUser({
                    ...user,
                    profile: {
                        ...user?.profile,
                        previousClubs: newPreviousClubs
                    }
                })
            },
            previousClub.seasonTo,
            date => {
                const newPreviousClub = _.cloneDeep(previousClub)
                newPreviousClub.seasonTo = date

                const newPreviousClubs: PastClubModel[] = _.cloneDeep(user?.profile?.previousClubs || [])
                newPreviousClubs[index] = newPreviousClub

                setUser({
                    ...user,
                    profile: {
                        ...user?.profile,
                        previousClubs: newPreviousClubs
                    }
                })
            }
        )
    })

    return (
        <Box
            p={3}
            display={'flex'}
            flexDirection={'column'}
        >
            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Stats - Current season")}
            </Typography>
            {statsUI('stats')}

            <Box
                display={'flex'}
                justifyContent={'center'}
            >
                <EButton
                    variant={'contained'}
                    onClick={() => updateProfileField('previousSeasonStats', {...user?.profile?.stats})}
                >
                    <ArrowDownwardIcon/>
                    Copy current season stats to past season
                </EButton>

                <Box width={100}/>

                <EButton
                    onClick={() => updateProfileField('stats', {...user?.profile?.previousSeasonStats})}
                >
                    <ArrowUpwardIcon/>
                    Copy past season stats to current season
                </EButton>

            </Box>

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Stats - Past season")}
            </Typography>
            {statsUI('previousSeasonStats')}

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Current Club")}
            </Typography>
            <Grid
                container
                spacing={2}
                className={classes.sectionData}
            >
                <ESwitchField
                    grid={"1/1"}
                    label={t('User has no past clubs - explicitly set')}
                    value={!!user?.profile?.skipCurrentClub}
                    onChange={checked => {
                        const fields: { field: string, value: any }[] = []
                        fields.push({field: 'skipCurrentClub', value: checked})

                        if (!checked) {
                            fields.push({field: 'currentClub', value: undefined})
                            fields.push({field: 'currentClubRequest', value: undefined})
                            fields.push({field: 'currentClubTeams', value: undefined})
                        }

                        updateProfileFields(fields)
                    }}
                />
            </Grid>
            {!user?.profile?.skipCurrentClub && clubUI}


            <Box m={4}/>

            <Typography variant={'h4'} className={classes.sectionTitle}>
                {t("Past Clubs")}
            </Typography>
            <Grid
                container
                spacing={2}
                className={classes.sectionData}
            >
                <ESwitchField
                    grid={"1/1"}
                    label={t('User has no past clubs - explicitly set')}
                    value={!!user?.profile?.skipPastClub}
                    onChange={checked => {
                        const fields: { field: string, value: any }[] = []
                        fields.push({field: 'skipPastClub', value: checked})

                        if (checked) {
                            fields.push({field: 'previousClubs', value: undefined})
                        }

                        updateProfileFields(fields)
                    }}
                />
            </Grid>

            {pastClubUI}

            <Button
                variant={'contained'}
                color={'primary'}
                onClick={() => updateProfileField('previousClubs', [...(user?.profile?.previousClubs || []), {}])}
                style={{width: 'fit-content'}}
            >
                Add new
            </Button>

            <PickClubPopup
                open={pickClubPopupOpen}
                onPick={(club) => {
                    if (pickingClubFor === -1) {
                        updateProfileFields([
                            {field: 'currentClub', value: club},
                            {field: 'currentClubTeams', value: []},
                        ])
                    } else {
                        const newPreviousClub = _.cloneDeep(user?.profile?.previousClubs?.[pickingClubFor])
                        newPreviousClub!.club = club
                        newPreviousClub!.previousClubTeams = []

                        const newPreviousClubs: PastClubModel[] = _.cloneDeep(user?.profile?.previousClubs || [])
                        newPreviousClubs![pickingClubFor] = newPreviousClub!

                        updateProfileField('previousClubs', newPreviousClubs)
                    }

                    setPickClubPopupOpen(false)
                }}
                onClose={() => setPickClubPopupOpen(false)}
            />

        </Box>
    )

    //endregion UI
}

export default ClubsStatsTab
