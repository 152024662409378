import React, {useEffect} from 'react'
import {Dialog, Grid, Typography} from "@material-ui/core";
import UserModel from "../../models/user.model";
import User from "../../models/user.model";
import UserService from "../../services/user.service";
import {getFullName, getUserTypeIcon} from "../../util/profile.util";
import UserFilterActions from "../user/UserFilterActions.component";
import {ListColumn} from "./List/List";
import {useListData} from "../../util/data/useListData";
import {useUserFilters} from "../user/useUserFilters";
import {useTranslation} from "react-i18next";
import {setMultipleParams} from "../../util/data/url.util";
import {useHistory} from "react-router";

interface Props {
    open: boolean;
    onClose: () => void;
    onPick: (user: UserModel) => void;
    userRoles?: ('PLAYER' | 'AGENT' | 'STAFF' | 'ADMIN')[]
}

const PickUserPopupAdvanced = (props: Props) => {

    const t = useTranslation().t
    const [filters] = useUserFilters()
    const history = useHistory()

    const columns: ListColumn[] = [
        {name: t('Id'), id: 'id', width: 'xs'},
        {name: t('Name'), id: 'name', width: 'grow'},
    ]

    useEffect(() => {
        if (props.open && props.userRoles?.length) {
            setMultipleParams([
                {name: 'userRoles', value: props.userRoles, dataType: 'complex'},
                {name: 'listPage', value: 1, dataType: 'numeric'}
            ], history)
        }
    }, [props.open])

    const renderCell = (user: User, column: ListColumn) => {
        switch (column.id) {
            case 'id':
                return (
                    <Typography variant={'body1'}>{user.id}</Typography>
                )
            case 'name':
                return (
                    <React.Fragment>

                        <Typography variant={'body1'}>
                            {getFullName(user) || '-'}
                        </Typography>

                        <img
                            style={{
                                marginLeft: 10,
                                width: 15,
                                height: 18
                            }}
                            src={getUserTypeIcon(user)}
                        />

                    </React.Fragment>
                )

            default:
                return null
        }
    }

    const listData = useListData<User>({
        list: (page, size, search) => UserService.getUsers({
            page: page,
            size: size,
            sort: {
                orders: [{prop: 'id', dir: 'desc'}]
            }
        }, filters),
        renderCell,
        columns,
        onItemClick: props.onPick,
        onItemMiddleClick: user => window.open(`${process.env.REACT_APP_WEB_URL}/admin/user/${user.id}`, '_blank'),
        listId: 'usersListPicker',
        filters: filters
    })

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth
            maxWidth={'xl'}
        >
            <Grid container spacing={3} style={{flex: 1}}>
                <Grid item xs={3} md={3} lg={3} xl={2}>
                    <UserFilterActions/>
                </Grid>

                <Grid item xs={9} md={9} lg={9} xl={10}>
                    {listData.renderList()}
                </Grid>

            </Grid>
        </Dialog>
    )

}

export default PickUserPopupAdvanced

