import {LOGOUT, SET_ALGOBUG_FEED_USER, SET_ALGOBUG_NOTIFICATIONS_JOB} from "../actions/actionTypes";
import UserModel from "../../models/user.model";
import Job from "../../models/job.model";

export interface AlgobugState {
    algobugFeedUser?: UserModel;
    algobugNotificationsJob?: Job
}

const initialState: AlgobugState = {};

const algobugReducer = (state = initialState, action: any): AlgobugState => {
    switch (action.type) {
        case SET_ALGOBUG_FEED_USER:
            return {
                ...state,
                algobugFeedUser: action.payload
            };
        case SET_ALGOBUG_NOTIFICATIONS_JOB:
            return {
                ...state,
                algobugNotificationsJob: action.payload
            };
        case LOGOUT:
            return {
                ...state,
            };
    }
    return state;
};

export default algobugReducer;

