export enum PeopleProperty {
    LANGUAGE = 'Language',
    LANGUAGES = 'Languages',
    MEDIA_SOURCE = 'Media Source',
    FIRST_VISIT_DATE = 'First Visit Date',
    LAST_OPPORTUNITY_INTERACTION = 'Last Opportunity Interaction',
    TOTAL_OPPORTUNITIES_DISMISSED = 'Total Opportunities Dismissed',
    TOTAL_OPPORTUNITIES_VIEWED = 'Total Opportunities Viewed',
    TOTAL_OPPORTUNITIES_APPLIED = 'Total Opportunities Applied',
    LAST_OPPORTUNITY_APPLIED = 'Last Opportunity Applied',
    OPPORTUNITIES_SHARED = 'Opportunities Shared',
    OPPORTUNITIES_MORE_INFO_CLICKED = 'Opportunities More Info Clicked',
    SIGN_UP_TRIGGERED = 'Sign Up Triggered',
    SIGN_UP_TYPE = 'Sign Up Type',
    SIGN_UP_DATE = 'Sign Up Date',
    EMAIL = '$email',
    FIRST_NAME = '$first_name',
    LAST_NAME = '$last_name',
    NAME = '$name',
    CREATED = '$created',
    USER_TYPE = 'User Type',
    TOTAL_NUMBER_OF_SIGN_IN = 'Total Number Of Sign In',
    SIGN_IN_DATE = 'Sign In Date',
    GENDER = 'Gender',
    AGE = 'Age',
    BIRTH_DATE = 'Birth Date',
    NATIONALITY = 'Nationality',
    PRIMARY_POSITION = 'Primary Position',
    SECONDARY_POSITION = 'Secondary Position',
    SECONDARY_POSITION_2 = 'Secondary Position 2',
    CURRENT_CLUB = 'Current Club',
    CURRENT_TEAM = 'Current Team',
    STARTING_DATE = 'Starting Date',
    MATCHES = 'Matches',
    GOALS = 'Goals',
    ASSISTS = 'Assists',
    CLEAN_SHEETS = 'Clean Sheets',
    GOALS_RECEIVED = 'Goals Conceded',
    YELLOW_CARDS = 'Yellow Cards',
    RED_CARDS = 'Red Cards',
    LEVEL = 'Level',
    PROFILE_PICTURE_SET = 'Profile Picture Set',
    AVATAR = '$avatar',
    FOOT = 'Foot',
    HEIGHT = 'Height',
    PROFILE_URL = 'Profile Url',
    SKILL_VIDEO_UPLOAD_STARTED = 'Skill Video Upload Started',
    SKILL_VIDEO_UPLOAD_FINISHED = 'Skill Video Upload Finished',
    SHOOTING_VIDEOS = 'Shooting Videos',
    PASSING_VIDEOS = 'Passing Videos',
    TECHNICAL_SKILL_VIDEOS = 'Technical Skill Videos',
    PHYSICAL_ATTRIBUTES_VIDEOS = 'Physical Attributes Videos',
    ATTACKER_VIDEOS = 'Attacker Videos',
    MIDFIELD_VIDEOS = 'Midfield Videos',
    DEFENSIVE_VIDEOS = 'Defensive Videos',
    GOAL_KEEPING_VIDEOS = 'Goal Keeping Videos',
    SKILL_LAST_VIDEO_UPLOADED = 'Skill Last Video Uploaded',
    SKILL_LAST_VIDEO_DELETED = 'Skill Last Video Deleted',
    SKILL_VIDEO_DELETED = 'Skill Video Deleted',
    HIGHLIGHTS_VIDEO_UPLOAD_STARTED = 'Highlights Video Upload Started',
    HIGHLIGHTS_VIDEO_UPLOAD_FINISHED = 'Highlights Video Upload Finished',
    HIGHLIGHT_VIDEOS = 'Highlight Videos',
    HIGHLIGHTS_LAST_VIDEOS_UPLOAD_FINISHED = 'Highlights Last Video Upload Finished',
    HIGHLIGHTS_LAST_VIDEO_UPLOADED = 'Skill Last Video Uploaded',
    HIGHLIGHTS_LAST_VIDEO_DELETE = 'Highlights Last Video Delete',
    HIGHLIGHTS_LAST_VIDEO_DELETED = 'Highlights Last Video Deleted',
    HIGHLIGHTS_VIDEO_DELETED = 'Highlights Video Deleted',
    MATCH_SETTINGS_CHANGED = 'Match Settings Changed',
    NOT_SUCCESSFUL_APPLICATIONS = 'Not Successful Applications',
    LAST_NOT_SUCCESSFUL_APPLICATION = 'Last Not Successful Application',
    NUMBER_OF_CANCELLATIONS = 'Number Of Cancellations',
    NUMBER_OF_TOP_PICK = 'Number Of Top Pick',
    NUMBER_OF_INVITES = 'Number Of Invites',
    LAST_INVITE = 'Last Invite',
    NOT_CHANGED_CLUB_NOT_CONTACTED = 'Not Changed Club - Not Contacted',
    NOT_CHANGED_CLUB_TRIAL_BUT_NO_ACCEPTED = 'Not Changed Club - Trial But Not Accepted',
    NOT_CHANGED_CLUB_OTHER = 'Not Changed Club - Other',
    LAST_NOT_CHANGED_CLUB = 'Last Not Changed Club',
    LAST_CHANGED_CLUB = 'Last Changed Club',
    ENDING_DATE = 'Ending Date',
    SELECTED_CITY = 'Selected City',
    SELECTED_COUNTRY = 'Selected Country',
    SELECTED_REGION = 'Selected Region',
    PAST_CLUBS = 'Past Clubs',
    PHONE = '$phone',
    FRIENDS_INVITED = 'Friends Invited',
    USER_ID = 'User Id',
    GOALS_CONCEDED = 'Goals Conceded',
    TOTAL_APPLICATIONS_CANCELED = 'Total Applications Canceled',
    //PLAYERHUNTER_TEAM = 'Playerhunter Team',
    PLAYERCARD_SHARE = 'Playercard Share',
    VIDEOS = 'Videos',
    CLUB_NAME_LAST_APPLICATION = 'Club Name Last Application',
    LAST_OPPORTUNITY_CREATED = 'Last Opportunity Created',
    LAST_ACTIVITY = 'Last Activity',
    TOTAL_PLAYERS_INVITED = 'total players invited',
    LAST_PLAYER_INVITED = 'last player invited',
    TOTAL_PLAYERS_REJECTED = 'total players rejected',
    LAST_PLAYER_REJECTED = 'last player rejected',
    TOTAL_APPLICANT_DETAIL_REVIEWS = 'total applicant detail reviews',
    LAST_APPLICANT_REVIEW = 'last applicant review',
    LAST_APPLICANT_DETAIL_REVIEW = 'last applicant detail review',
    OPPORTUNITY_CREATION_STARTED = 'opportunity creation started',
    LAST_OPPORTUNITY_CREATION_STARTED = 'last opportunity creation started',
    TOTAL_APPLICANT_REVIEWS = 'total applicant reviews',
    TOTAL_OPPORTUNITIES_CREATED = 'Total Opportunities Created',
    LAST_APPLICANTS_REVIEW = 'Last Applicants Review',
    TOTAL_APPLICANTS_REVIEWED = 'Total Applicants Reviewed',
    LAST_APPLICANT_DETAILS_REVIEW = 'Last Applicant Details Review',
    TOTAL_APPLICANT_DETAILS_REVIEWED = 'Total Applicant Details Reviewed',
    LAST_APPLICANT_INVITE = 'Last Applicant Invite',
    TOTAL_APPLICANTS_INVITED = 'Total Applicants Invited',
    LAST_APPLICANT_REJECT = 'Last Applicant Reject',
    TOTAL_APPLICANTS_REJECTED = 'Total Applicants Rejected',
    LAST_APPLICANT_DELETE = 'Last Applicant Delete',
    TOTAL_APPLICANTS_DELETED = 'Total Applicants Deleted',
    CLUB_NAME = 'Club Name',
    CLUB_POSITION = 'Club Position',
    CLUB_CITY = 'Club City',
    CLUB_COUNTRY = 'Club Country',
    CLUB_LOGO = 'Club Logo',

    BRANCH_CAMPAIGN = 'Branch campaign',
    BRANCH_CHANNEL = 'Branch channel',
    BRANCH_FEATURE = 'Branch feature',
    BRANCH_MARKETING = 'Branch marketing',
    BRANCH_TAGS = 'Branch tags',
    BRANCH_SOURCE = 'Branch source',
    BRANCH_DOMAIN = 'Branch domain',
    BRANCH_REFERRER = 'Branch referrer',
    BRANCH_REFERRING_LINK = 'Branch referring link',
    BRANCH_LINK_TITLE = 'Branch link title',
    LAST_CLUB_EDIT = 'last club edit',

    SUPPORT_CHAT_OPENED = 'Support Chat Opened',
    NOTIFICATION_TURN_ON_OFF = "Notification Turn On-Off",

    LAST_SIGN_IN_PLATFORM = 'Last Sign In Platform',

    // ***** B2C ***** //

    B2C_IS_PREMIUM_USER = 'B2C Is Premium User',                                // true|false;  Does the user have valid, active subscription... Update on purchase
    B2C_CURRENT_PACKAGE_MONTHS = 'B2C Current Package Months',                  // number;      Duration of the current package in months
    B2C_SUBSCRIPTION_EXPIRATION_DATE = 'B2C Subscription Expiration Date',       // dateISO;     Subscription expiration date
    B2C_GROUP = 'B2C Group'

}
