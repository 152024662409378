import Job from "../models/job.model";
import {LocationType} from "../models/locationType.enum";
import {TFunction} from "i18next";
import {latLngRaw} from "../models/geocodeResult.model";

export const getJobLocation = (t?: TFunction, opportunity?: Job) => {
    if (opportunity?.location) {
        switch (opportunity?.locationType) {
            case LocationType.Local:
                const country = opportunity?.location?.location?.country?.name || opportunity.location?.country?.[0]?.name;
                const city = opportunity?.location?.location?.name || opportunity.location?.city?.name;

                const location = [];

                if (country) {
                    location.push(country)
                }

                if (city) {
                    location.push(city);
                    location.push(`${opportunity.location.radius}km`);
                }

                return location.join(', ');
            case LocationType.Country:
                if ((opportunity?.location?.country?.length || 0) > 2) {
                    return `${opportunity?.location?.country?.slice(0, 2).map(c => c.name)?.join(', ')}, +${opportunity?.location?.country?.length! - 2}`
                }

                return opportunity?.location?.country?.map(c => c.name)?.join(', ') || '-';
            default:
                return t?.('international') || 'international';
        }

    }
    return '-';
};

export const getOpportunityIsAnonymous = (opportunity?: Job, defaultValue: boolean = false) => {
    return opportunity?.anonymous || defaultValue;
};

export const getJobClub = (opportunity?: Job, defaultValue: any = null) => {
    return opportunity?.club || defaultValue;
};

export const getJobClubLogo = (opportunity?: Job, defaultValue: any = null) => {
    return opportunity?.logoOverride || opportunity?.club?.logo || defaultValue
};

export const getOpportunityClubName = (opportunity?: Job, defaultValue: any = null) => {
    return (!opportunity?.anonymous && opportunity?.club?.name) || defaultValue;
};

export const getOpportunityType = (opportunity?: Job, defaultValue: any = null) => {
    return opportunity?.jobType || defaultValue;
};

export const getOpportunityLocationCity = (opportunity?: Job, defaultValue: any = null) => {
    return opportunity?.location?.location?.name || opportunity?.location?.city?.name || defaultValue;
};

export const getOpportunityLocationCountry = (opportunity?: Job, defaultValue: any = null) => {
    return opportunity?.location?.location?.country?.name || opportunity?.location?.country?.[0]?.name || defaultValue;
};

export const getOpportunityLocationRadius = (opportunity?: Job, defaultValue: any = null) => {
    return opportunity?.location?.radius || defaultValue;
};

//
// export const getOpportunityLocation = (jobs: JobModel, defaultValue = null) => {
//     const location = jobs && jobs.location;
//     if (!location) return defaultValue;
//
//     const city = location.city && location.city.name;
//     const country = location.country && location.country.name;
//
//     return [city, country].filter(i => !!i).join(', ') || defaultValue;
// };

export const getOpportunityLocationWithRadius = (t: TFunction, opportunity?: Job, defaultValue: any = null) => {
    const location = opportunity?.location;
    if (!location) return defaultValue;

    return `${getJobLocation(t, opportunity)} (${location.radius}km)`;
};

export const getOpportunityFoot = (opportunity?: Job, defaultValue: any = null) => {
    return opportunity?.preferredFoot || defaultValue;
};

export const getOpportunityGender = (opportunity?: Job, defaultValue: any = null) => {
    return opportunity?.gender || defaultValue;
};

export const getOpportunityMinHeight = (opportunity?: Job, defaultValue: any = null) => {
    return opportunity?.minHeight || defaultValue;
};

export const getOpportunityPreferredFoot = (opportunity?: Job, defaultValue: any = null) => {
    return opportunity?.preferredFoot || defaultValue;
};

export const getOpportunitySkillVideosRequired = (opportunity?: Job, defaultValue: any = null) => {
    return opportunity?.skillNeeded || defaultValue;
};


export const getOpportunityAgeFrom = (opportunity?: Job, defaultValue: any = null) => {
    return opportunity?.fromAge || defaultValue;
};

export const getOpportunityAgeTo = (opportunity: Job, defaultValue: any = null) => {
    return opportunity?.toAge || defaultValue;
};

export const getOpportunityLevelFrom = (opportunity: Job, defaultValue: any = null) => {
    return opportunity?.fromLevel || defaultValue;
};

export const getOpportunityLevelTo = (opportunity: Job, defaultValue: any = null) => {
    return opportunity?.toLevel || defaultValue;
};

export const getOpportunityTransferPeriod = (opportunity?: Job, defaultValue: any = null) => {
    return opportunity?.transferPeriod || defaultValue;
};

export const getOpportunityLanguageNames = (opportunity?: Job, defaultValue: string[] = []): string[] => {
    return opportunity?.languages?.map(l => l.name || '') || defaultValue;
};

export const getOpportunityNationalityNames = (opportunity?: Job, defaultValue: string[] = []): string[] => {
    return opportunity?.nationalities?.map(l => l.name || '') || defaultValue;
};

// export const getOpportunitySalaryType = (opportunity?: Job, defaultValue: any = null): string => {
//     return opportunity?.salary || defaultValue;
// };


export const getOpportunityTransferPeriodLocalized = (t?: TFunction, opportunity?: Job, defaultValue: any = null) => {
    if (!opportunity?.transferPeriod?.period || !opportunity?.transferPeriod?.year) {
        return defaultValue;
    }

    const transferPeriod = opportunity.transferPeriod;
    let yearString = `${transferPeriod.year}`;
    if (transferPeriod.period === 'WINTER') {
        yearString += `/${(transferPeriod.year! + 1) % 100}`
    }
    return t?.(`${transferPeriod.period}`) + ` ${yearString}`;
};

export const getOpportunityTeamNames = (opportunity?: Job, defaultValue: any = null): string => {
    if (!opportunity?.teams?.length) {
        return defaultValue;
    }

    const opportunityTeam = opportunity.teams[0];
    if (!opportunityTeam.teamType) {
        return defaultValue;
    }

    return opportunityTeam.teamType.name;
};

export const getOpportunityTeamNameLocalized = (t: TFunction, opportunity?: Job, defaultValue: any = null) => {
    if (!opportunity?.teams?.length) {
        return defaultValue;
    }

    const opportunityTeam = opportunity?.teams[0];
    if (!opportunityTeam.teamType) {
        return defaultValue;
    }

    return t(opportunityTeam.teamType.name);
};

export const getOpportunityLevel = (opportunity?: Job, defaultValue: any = null) => {
    if (!opportunity?.teams?.length) {
        return defaultValue;
    }

    const opportunityTeam = opportunity.teams[0];
    if (!opportunityTeam.level) {
        return defaultValue;
    }

    return opportunityTeam.level.id;
};

export const getOpportunityPositionName = (opportunity?: Job, defaultValue: any = null): string => {
    if (!opportunity) {
        return defaultValue;
    }

    if (opportunity.positionMode === 'section') {
        return opportunity.section || defaultValue;
    } else {
        return opportunity?.positions?.[0] || defaultValue;
    }
};

export const getOpportunityPositionNameLocalized = (t: TFunction, opportunity?: Job, defaultValue: any = null) => {
    if (!opportunity) {
        return defaultValue;
    }

    if (opportunity.positionMode === 'section') {
        return (opportunity.section && t(`Field Section ${opportunity.section}`)) || defaultValue;
    } else {
        return (opportunity.positions?.length && t(`Position ${opportunity.positions[0]} Long`)) || defaultValue;
    }
};

export const getOpportunitySalaryLocalized = (opportunity?: Job, defaultValue?: string) => {
    if (!opportunity) return defaultValue

    let salaryOption = ''
    if (!opportunity.toSalary && !opportunity.fromSalary) {
        salaryOption = '-'
    } else if (!opportunity.fromSalary && opportunity.toSalary === 2_000) {
        salaryOption = '0 - 2.000€'
    } else if (opportunity.fromSalary === 2_000 && opportunity.toSalary === 5_000) {
        salaryOption = '2.000 - 5.000€'
    } else if (opportunity.fromSalary === 5_000 && opportunity.toSalary === 10_000) {
        salaryOption = '5.000 - 10.000€'
    } else if (opportunity.fromSalary === 10_000 && opportunity.toSalary === 25_000) {
        salaryOption = '10.000 - 25.000€'
    } else if (opportunity.fromSalary === 25_000 && opportunity.toSalary === 50_000) {
        salaryOption = '25.000 - 50.000€'
    } else if (opportunity.fromSalary === 50_000 && opportunity.toSalary === 100_000) {
        salaryOption = '50.000 - 100.000€'
    } else if (opportunity.fromSalary === 100_000 && !opportunity.toSalary) {
        salaryOption = '100.000€+'
    } else {
        salaryOption = 'Custom'
    }
    return salaryOption
};

export const getOpportunityTransferFeeLocalized = (opportunity?: Job, defaultValue?: string) => {
    if (!opportunity) return defaultValue

    let transferFeeLocalized = '-'
    if (!opportunity.transferFee) {
        transferFeeLocalized = '-'
    } else if (opportunity.transferFee === -1) {
        transferFeeLocalized = 'Free agent'
    } else if (opportunity.transferFee === 100_000) {
        transferFeeLocalized = 'up to 100.000€'
    } else if (opportunity.transferFee === 200_000) {
        transferFeeLocalized = 'up to 200.000€'
    } else if (opportunity.transferFee === 300_000) {
        transferFeeLocalized = 'up to 300.000€'
    } else if (opportunity.transferFee === 400_000) {
        transferFeeLocalized = 'up to 400.000€'
    } else if (opportunity.transferFee === 500_000) {
        transferFeeLocalized = 'up to 500.000€'
    } else if (opportunity.transferFee === 750_000) {
        transferFeeLocalized = 'up to 750.000€'
    } else if (opportunity.transferFee === 1_000_000) {
        transferFeeLocalized = 'up to 1.000.000€'
    } else {
        transferFeeLocalized = 'Custom'
    }

    return transferFeeLocalized
};

// export const getOpportunitySalaryLocalized = (t: TFunction, opportunity?: Job, defaultValue: any = null) => {
//     return (opportunity?.salary && t(opportunity.salary)) || defaultValue;
// };

export const getOpportunityLocationGeo = (opportunity?: Job): latLngRaw | undefined => {
    if (!opportunity) return;

    if (opportunity.location?.location?.location) {
        return {
            lat: opportunity.location.location.location.latitude,
            lng: opportunity.location.location.location.longitude
        }
    }

    if (opportunity.location?.city?.latitude && opportunity.location?.city?.longitude) {
        return {
            lat: opportunity.location?.city?.latitude,
            lng: opportunity.location?.city?.longitude
        }
    }
}
