import mixpanel from "mixpanel-browser";
import {EventName} from "./eventName";
import {EventProperty} from "./eventProperty";

export default class MixpanelService {

    static isEnabled = true;

    static setEnabled(enabled: boolean) {
        MixpanelService.isEnabled = enabled;
    }

    static execute(action: () => any) {
        setTimeout(action, 0);
    }

    static init() {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN!);
        mixpanel.set_config({
            debug: process.env.REACT_APP_MIXPANEL_DEBUG_ENABLED === 'true'
        });
    }

    static track(eventName: string, params: any = {}) {
        if (MixpanelService.isEnabled) {
            console.log('[Mixpanel] track', eventName, params);
            this.execute(() => mixpanel.track(eventName, params));
        }
    }

    static trackScreenVisit(screenName: string, params: any = {}) {
        this.track(EventName.SCREEN_VISITED, {
            [EventProperty.SCREEN_NAME]: screenName,
            ...params
        });
    }

    static identifyUser(userUid: string, newUser: boolean) {
        if (MixpanelService.isEnabled) {
            // if (newUser) {
            //     this.execute(() => mixpanel.alias(userUid, mixpanel.get_distinct_id()));
            // } else {
                this.execute(() => mixpanel.identify(userUid));
            // }
        }
    }

    static setSuperProperties(props: any, setOnce: boolean) {
        if (MixpanelService.isEnabled) {
            if (setOnce) {
                this.execute(() => mixpanel.register_once(props));
            } else {
                this.execute(() => mixpanel.register(props));
            }
        }
    }

    static setUserProperties(props: any) {
        if (MixpanelService.isEnabled) {
            console.log('[Mixpanel] Set user prop', props);
            this.execute(() => mixpanel.people.set(props));
        }
    }

    static setUserPropertiesOnce(properties: any): void {
        if (MixpanelService.isEnabled) {
            this.execute(() => mixpanel.people.set_once(properties));
        }
    }

    static incrementProperty(propName: string, incrementValue = 1) {
        if (MixpanelService.isEnabled) {
            console.log('[Mixpanel] Increment prop', propName, incrementValue);
            this.execute(() => mixpanel.people.increment(propName, incrementValue));
        }
    }

    static getCurrentTimeIso() {
        return new Date().toISOString();
    }

    static reset(): void {
        mixpanel.reset();
    }
}
