import React, {FunctionComponent, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from './EditAboutUsPopup.module.scss'
import {Box, Button, DialogActions, TextField} from '@material-ui/core';
import ClubModel from "../../../../../models/club.model";
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean,
    history?: any,
    onClose: () => void,
    onSave: (about: string) => void,
    club?: ClubModel,
}

const EditAboutUsPopup: FunctionComponent<Props> = (props: Props) => {
    const [text, setText] = useState(props.club?.about || '');
    const t = useTranslation().t;

    return (
        <Dialog aria-labelledby="simple-dialog-title" {...props} maxWidth={"md"} fullWidth={true}>

            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                className={styles.container}
            >

                <TextField
                    id="filled-multiline-flexible"
                    label={t("write_about_your_club")}
                    multiline
                    rows={10}
                    value={text}
                    className={styles.input}
                    onChange={(e) => setText(e.target.value.substring(0, 1000))}
                    margin="normal"
                />

                <div className={styles.characterCounter}>{text.length}/1000</div>

            </Box>

            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    {t('close')}
                </Button>
                <Button onClick={() => props.onSave(text)} color="primary" autoFocus>
                    {t('save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default EditAboutUsPopup;
