import React from 'react';
import clsx from 'clsx';
import {useTranslation} from "react-i18next";
import Job from "../../../models/job.model";
import ClubModel from "../../../models/club.model";
import {Box, Button, Card, Grid, makeStyles, Switch} from "@material-ui/core";
import {getJobLocation} from "../../../util/opportunity.util";
import {shouldRenewSubscription} from "../../../util/payment.util";
import Position from "../../common/Position/Position";
import {windowWidth} from "../../../util/dimension.util";

interface Props {
    opportunity: Job,
    club?: ClubModel,
    onClick: () => void,
    onDelete: () => void,
    onChangeStatus: (newStatus: string) => void
}

const useStyle = makeStyles(theme => ({
    bottomText: {
        color: 'rgba(41, 45, 60, 0.51)',
        fontSize: 12,
        marginTop: 8,
        textAlign: 'center',
        wordBreak: 'break-word',
        hyphens: 'auto'
    },
    topText: {
        color: '#292D3C',
        fontSze: 14,
        textAlign: 'center'
    },
    clubImg: {
        position: 'absolute',
        width: 50,
        height: 50,
        left: 'calc(50% - 25px)',
        top: 'calc(50% - 25px)',
        objectFit: 'contain'
    },
    clubImgHolder: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
    },
    textInfo: {
        fontSize: 14,
        marginBottom: 5,
        color: 'rgba(0, 0, 0, 0.36)'
    },
    textLarge: {
        fontSize: 16,
        color: '#000'
    },
    underReview: {
        textTransform: 'uppercase'
    },
    boxSmall: {
        background: '#F5F5F5',
        borderRadius: 9,
        padding: 16,
        border: '2px solid white',
        minHeight: 45
    },
    boxLarge: {
        background: '#F5F5F5',
        borderRadius: 9,
        padding: 16,
        border: '2px solid white',
        minHeight: 90
    },
    icon: {
        width: 25,
        height: 25,
        objectFit: 'contain'
    }
}))

const JobPreview = (props: Props) => {

    const t = useTranslation().t;
    const classes = useStyle()

    const renderTeams = () => {
        const teams = props.opportunity.teams;
        if (!teams) {
            return null;
        }
        return teams.map(team => {
            return (
                <React.Fragment>
                    <div className={classes.topText}>{t(team.teamType?.name || '-')}</div>
                    <div
                        className={classes.bottomText}>{t('level', {level: `${props.opportunity.fromLevel} - ${props.opportunity.toLevel}`})}</div>
                </React.Fragment>
            );
        })
    }

    // const editOpportunityHandler = (event: any) => {
    //     event.stopPropagation();
    //     router.push(`/jobs/${props.jobs.id}/details`);
    // };

    const getSalaryText = () => {
        let salaryOption = ''
        if (!props.opportunity.toSalary && !props.opportunity.fromSalary) {
            salaryOption = 'Not public'
        } else if (!props.opportunity.fromSalary && props.opportunity.toSalary === 2000) {
            salaryOption = '0-2k'
        } else if (props.opportunity.fromSalary === 2000 && props.opportunity.toSalary === 5000) {
            salaryOption = '2-5k'
        } else if (props.opportunity.fromSalary === 5000 && props.opportunity.toSalary === 10000) {
            salaryOption = '5-10k'
        } else if (props.opportunity.fromSalary === 10000) {
            salaryOption = '10k+'
        }
        return salaryOption
    };

    return (
        <Card>
            <Box
                display={'flex'}
                flexDirection={'column'}
                boxSizing={'border-box'}
                padding={2}
            >

                <Box
                    display={'flex'}
                    alignItems={'center'}
                    marginBottom={3}
                >

                    <Box
                        width={46}
                        height={54}
                        marginRight={2}
                        position={'relative'}
                    >
                        <img alt={''} src={require('../../../assets/images/icons/club-logo-hexagon.svg').default}
                             className={classes.clubImgHolder}/>
                        <img alt={'Club logo'}
                             src={props.club?.logo || require('../../../assets/images/fake-club.svg').default}
                             className={classes.clubImg}/>
                    </Box>

                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                    >
                        <div className={classes.textInfo}>{t('publish_location')}</div>
                        <div className={classes.textLarge}>
                            {getJobLocation(t, props.opportunity)}
                        </div>
                    </Box>

                    {
                        props.opportunity.opportunityStatus === "NOT_APPROVED" ?
                            <Box
                                marginLeft={'auto'}
                                className={classes.underReview}
                            >
                                {t('under_review')}
                            </Box>

                            :

                            <Box marginLeft={'auto'}>
                                <Switch
                                    onChange={(e) => props.onChangeStatus(e.target.checked ? 'ACTIVE' : 'INACTIVE')}
                                    checked={props.opportunity.opportunityStatus === 'ACTIVE'}
                                    disabled={shouldRenewSubscription(props.club)}
                                    inputProps={{'aria-label': 'secondary checkbox'}}

                                />
                            </Box>

                    }

                </Box>

                <Grid
                    container
                    alignItems={'center'}
                    spacing={3}
                >

                    <Grid item xs={12} md={3} lg={3}>
                        <Box>
                            <Grid
                                container
                            >

                                <Grid item xs={6} md={12}>
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        className={classes.boxLarge}
                                    >
                                        {renderTeams()}
                                    </Box>
                                </Grid>

                                <Grid item xs={6} md={12}>
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        className={classes.boxLarge}
                                    >
                                        <img alt={'Gender'} className={classes.icon}
                                             src={props.opportunity.gender === 'MALE' ? require('../../../assets/images/male.svg').default : require('../../../assets/images/female.svg').default}/>
                                        <div
                                            className={classes.bottomText}>{props.opportunity.fromAge} - {props.opportunity.toAge}</div>
                                    </Box>
                                </Grid>

                                <Grid item xs={6} md={12}>
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        className={classes.boxSmall}
                                    >
                                        <div className={classes.topText}>{getSalaryText()}</div>
                                        <div className={classes.bottomText}>{t('salary')}</div>
                                    </Box>
                                </Grid>

                                <Grid item xs={6} md={12}>
                                    <Box
                                        onClick={() => {
                                            if (props.opportunity.opportunityStatus !== 'NOT_APPROVED') {
                                                props.onClick();
                                            }
                                        }}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        justifyContent={'center'}
                                        className={clsx(classes.boxSmall)}
                                    >
                                        <div
                                            className={classes.topText}>{props.opportunity?.applicants?.totalElements}</div>
                                        <div className={classes.bottomText}>{t('total_applicants')}</div>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>
                    </Grid>

                    <Grid
                        item xs={12} md={9} lg={9}
                        style={{
                            height: '350px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Box
                            height={'100%'}
                            width={'100%'}
                            maxWidth={350}
                        >
                            <Position
                                selectedSecondary={props.opportunity.positions}
                                selectedSection={props.opportunity.section}
                                readOnly
                                mode={props.opportunity.positionMode}
                            />
                        </Box>
                    </Grid>

                </Grid>

                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    marginTop={5}
                    width={'100%'}
                    marginBottom={0.5}
                >
                    {/*<div onClick={this.editOpportunityHandler}>{lcl("edit")}</div>*/}
                    {/*<div onClick={this.props.onDelete}>{lcl("DELETE")}</div>*/}

                    <Button
                        disabled={props.opportunity.opportunityStatus === 'NOT_APPROVED'}
                        onClick={() => {
                            props.onClick();
                        }}
                        variant={'contained'}
                        color={'primary'}
                        style={{
                            width: 'calc(100% - 40px)',
                            maxWidth: 400
                        }}
                    >
                        <div>{t('View all applicants')}</div>
                    </Button>


                </Box>

            </Box>
        </Card>
    );
}

export default JobPreview;
