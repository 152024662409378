import React, {useState} from 'react'
import {Avatar, Box, Button, Grid, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import Job from "../../../../models/job.model";
import _ from "lodash";
import ESwitchField from "../../../common/ESwitchField.component";
import {useTranslation} from "react-i18next";
import ETextField from "../../../common/ETextField.component";
import CloudinaryService from "../../../../services/cloudinary.service";
import {getFullName, getUserProfilePicture, getUserTypeIcon, getUserTypeName} from "../../../../util/profile.util";
import PickUserPopupAdvanced from "../../../common/PickUserPopupAdvanced";

interface Props {
    job?: Job,
    setJob: (job: Job) => void,
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
}))

const AdminSection = ({job, setJob}: Props) => {

    const classes = useStyle()
    const t = useTranslation().t

    const [pickUserOpen, setPickUserOpen] = useState(false)

    const updateFields = (data: { field: string, value: any }[]) => {
        const newJob: any = _.cloneDeep(job)
        data.forEach(d => newJob[d.field] = d.value)
        setJob(newJob)
    }

    const updateField = (field: string, value: any) => {
        updateFields([{field, value}])
    }

    const handleImageUpload = async () => {
        const uploadedImage = await CloudinaryService.uploadMedia({multiple: false, resourceType: 'image'})
        updateField('logoOverride', uploadedImage.secure_url)
    }

    const handleOverwriteJobCreator = () => {
        setPickUserOpen(true)
    }

    // ***** Handlers ***** //

    //endregion Handlers

    //region UI

    const titleOverrideUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>{t("Job title override")}</Typography>
                </Grid>
                <ETextField
                    value={job?.titleOverride}
                    onChange={event => updateField('titleOverride', event)}
                    grid={'1/3'}
                />
            </Grid>
        </Grid>
    )

    const jobByAgentUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>{t("Indicate that this is job from agent")}</Typography>
                </Grid>
                <ESwitchField
                    value={job?.jobCreatedByAgent}
                    onChange={event => updateField('jobCreatedByAgent', event)}
                    grid={'1/3'}
                />
            </Grid>
        </Grid>
    )

    const overrideLogoUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>

                <Grid item xs={4}>
                    <Typography>{t("Override logo")}</Typography>
                </Grid>

                <Grid item xs={8}>
                    <Avatar src={job?.logoOverride} variant={'square'} style={{width: 100, height: 100}}>

                    </Avatar>

                    {
                        job?.logoOverride ?
                            <Box
                                display={'flex'}
                                marginTop={2}
                            >
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={handleImageUpload}
                                >
                                    {t('Change image')}
                                </Button>

                                <Button
                                    variant={'contained'}
                                    onClick={() => updateField('logoOverride', undefined)}
                                >
                                    {t('Remove image')}
                                </Button>
                            </Box>
                            :
                            <Box>
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={handleImageUpload}
                                >
                                    {t('Set override image')}
                                </Button>
                            </Box>
                    }

                </Grid>

            </Grid>
        </Grid>
    )

    const jobByUserUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>{t("Agent")}</Typography>
                </Grid>

                <Grid item>
                    <Box
                        display={'flex'}
                        mb={2}
                        alignItems={'center'}
                    >

                        <Avatar
                            style={{
                                width: 32,
                                height: 32,
                                marginRight: 10,
                                fontSize: 14
                            }}
                            src={getUserProfilePicture(job?.agent)}>
                            {job?.agent?.profile?.firstName?.substring(0, 2)}
                        </Avatar>

                        <Typography>
                            {getFullName(job?.agent, '-')}
                        </Typography>
                        <img
                            style={{
                                marginLeft: 10,
                                marginRight: 10,
                                width: 15,
                                height: 18
                            }}
                            src={getUserTypeIcon(job?.agent)}
                        />
                        <Typography>{getUserTypeName(job?.agent?.userType?.id)}</Typography>
                    </Box>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleOverwriteJobCreator}
                    >
                        {t('Change agent')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )


    return (
        <Grid container spacing={3}>
            {titleOverrideUI}
            {jobByAgentUI}
            {overrideLogoUI}
            {jobByUserUI}

            <PickUserPopupAdvanced
                open={pickUserOpen}
                onClose={() => setPickUserOpen(false)}
                onPick={user => {
                    updateField('agent', user)
                    setPickUserOpen(false)
                }}
                userRoles={['AGENT']}
            />

        </Grid>
    )

    //endregion UI
}

export default AdminSection
