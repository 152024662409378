const Imprint = () => {

    const style = {
        container: {
            marginTop: '100px',
            padding: '20px'
        },
        section: {
            marginBottom: 30,
            maxWidth: '470px',
            fontSize: '0.9em'
        }
    }

    return (
        <div style={{marginTop: '100px', padding: '20px'}}>

            <h1>IMPRINT/IMPRESSUM</h1>

            <div style={style.section}>
                <p>Playerhunter GmbH Internethandel</p>
            </div>

            <div style={style.section}>
                <p>Wattgasse 48</p>
                <p>A-1170 Wien</p>
            </div>

            <div style={style.section}>
                <p>E-Mail: sr@playerhunter.com</p>
                <p>Telefon: +43 664 5154196</p>
            </div>

            <div style={style.section}>
                <p>Firmenbuchnummer: FN 400891x</p>
                <p>Firmenbuchgericht: Handelsgericht Wien</p>
                <p>UID Nummer: ATU68299389</p>
            </div>

            <div style={style.section}>
                <p>Bankverbindung</p>
                <p>Erste Bank</p>
                <p>IBAN: AT222011182975163200</p>
                <p>BIC: GIBAATWWXXX</p>
            </div>

            <div style={style.section}>
                <p>Mitglied der Wirtschaftskammer Wien Zuständige Aufsichtsbehörde: Magistrat der Stadt Wien -
                    Magistratisches Bezirksamt für den 09./17. Bezirk</p>
            </div>

            <div style={style.section}>
                <p>Anwendbare Vorschriften: Gewerbeordnung, abrufbar unter www.ris.bka.gv.at</p>
            </div>

            <div style={style.section}>
                <p>Verbraucher haben die Möglichkeit, Beschwerden an die Online-Streitbeilegungsplattform der EU zu
                    richten:
                    http://ec.europa.eu/odr</p>
            </div>

            <div style={style.section}>
                <p>Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.</p>
            </div>

            <div style={style.section}>
                <p>Geschäftsführer: Mag. Stevan Radak</p>
            </div>

            <div style={style.section}>
                <p>Playerhunter ist ein digitales Fussballnetzwerk, welches mittels "Smart Matching" Vereine und Spieler
                    rasch und einfach zusammenbringt.</p>
            </div>

            <div style={style.section}>
                <p>Für den redaktionellen Inhalt ist ausschließlich der Herausgeber verantwortlich!</p>
            </div>
        </div>
    )
};

export default Imprint;