import React from 'react';
import styles from './AdditionalInfo.module.scss';
import clsx from 'clsx';
import {Box, Chip} from "@material-ui/core";
import CountryModel from "../../../../models/country.model";
import {useTranslation} from "react-i18next";
import UserModel from "../../../../models/user.model";
import {
    getCurrentContractFormatted,
    getUserDateOfBirthFormatted,
    getUserHeight,
    getUserLanguages,
    getUserNationalities,
    isAgentPlayer
} from "../../../../util/profile.util";
import {getCountryFlag, getLanguageFlag} from "../../../../util/flag.util";

interface Props {
    player?: UserModel
}

const AdditionalInfo = (props: Props) => {

    const t = useTranslation().t;
    const isAgentsPlayer = isAgentPlayer(props.player)

    const renderLanguages = () => {
        return getUserLanguages(props.player, []).map((language: any) => (
            <Chip
                key={language.isoCode}
                avatar={<div
                    className={clsx(styles.flag, 'flag-icon', getLanguageFlag(language))}/>}
                label={language.name}
                size="small"
                className={styles.multiselectChip}
            />
        ))
    }

    const renderNationalities = () => {
        return getUserNationalities(props.player, []).map((country: CountryModel) => (
            <Chip
                key={country.countryCode}
                avatar={<div
                    className={clsx(styles.flag, 'flag-icon', getCountryFlag(country))}/>}
                label={country.name}
                size="small"
                className={styles.multiselectChip}
            />
        ))
    }

    return (
        <Box className={styles.container}>
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                className={styles.currentStats}
            >
                <div>{t("additional_info")}</div>
                {/*<div className={styles.edit}>Edit</div>*/}
            </Box>

            <Box
                display={'flex'}
                flexDirection={'row'}
                className={clsx(styles.greyBox, styles.statsFullWidth)}
            >
                <img alt={'Height'} src={require('../../../../assets/images/height.svg').default} className={styles.icon}/>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                >
                    <div className={styles.statTitle}>{t("height")}</div>
                    <div className={styles.statValue}>{getUserHeight(props.player, '-')} cm</div>
                </Box>
            </Box>

            <Box
                display={'flex'}
                flexDirection={'row'}
                className={clsx(styles.greyBox, styles.statsFullWidth)}
            >
                <img alt={'Languages'} src={require('../../../../assets/images/languages.svg').default} className={styles.icon}/>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                >
                    <div className={styles.statTitle}>{t("languages")}</div>
                    <div className={styles.statValue}>{renderLanguages()}</div>
                </Box>
            </Box>

            <Box
                display={'flex'}
                flexDirection={'row'}
                className={clsx(styles.greyBox, styles.statsFullWidth)}
            >
                <img alt={'Contract'} src={require('../../../../assets/images/contact-info.svg').default} className={styles.icon}/>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                >
                    <div className={styles.statTitle}>{t("contract_info")}</div>
                    <div className={styles.statValue}>{getCurrentContractFormatted(t, props.player)}</div>
                </Box>
            </Box>

            <Box
                display={'flex'}
                flexDirection={'row'}
                className={clsx(styles.greyBox, styles.statsFullWidth)}
            >
                <img alt={'Contract'} src={require('../../../../assets/images/agent-info.svg').default} className={styles.icon}/>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                >
                    <div className={styles.statTitle}>{t("Agent status")}</div>
                    <div className={styles.statValue}>{isAgentsPlayer || props.player?.profile?.hasAgent ? t('Yes') : t('No')}</div>
                </Box>
            </Box>

            <Box
                display={'flex'}
                flexDirection={'row'}
                className={clsx(styles.greyBox, styles.statsFullWidth)}
            >
                <img alt={'Birthdate'} src={require('../../../../assets/images/birthdate.svg').default} className={styles.icon}/>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                >
                    <div className={styles.statTitle}>{t("date_of_birth")}</div>
                    <div className={styles.statValue}>{getUserDateOfBirthFormatted(props.player, '-')}</div>
                </Box>
            </Box>

            <Box
                display={'flex'}
                flexDirection={'row'}
                className={clsx(styles.greyBox, styles.statsFullWidth)}
            >
                <img alt={'Nationalities'} src={require('../../../../assets/images/nationalities.svg').default} className={styles.icon}/>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                >
                    <div className={styles.statTitle}>{t("nationalities")}</div>
                    <div className={styles.statValue}>{renderNationalities()}</div>
                </Box>
            </Box>

        </Box>
    );
}

export default AdditionalInfo;
