import React, {useState} from 'react'
import {Box, Button, CircularProgress, Grid, Typography} from "@material-ui/core"
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import Job from "../../../models/job.model";
import OpportunityApplicationModel, {ApplicationStatus} from "../../../models/opportunityApplication.model";
import {generateProfileURL, getPreviousClubs, getUserProfilePicture, isPhAdmin} from "../../../util/profile.util";
import ApplicantOverview from "./ApplicantOverview";
import EButton from "../../common/EButton";
import Popup from "../../common/Popup/Popup";
import Share from "../../common/Share/Share";
import ApplicantContactInfo from "../ApplicantContactInfo/ApplicantContactInfo";
import {Alert} from "@material-ui/lab";
import {useApplicationsState, useUser} from "../../../store/store.utils";
import {UserTypeId} from "../../../models/user.model";
import {
    initAddToTopPicks,
    initFullyDeleteApplication,
    initInviteApplication,
    initRejectApplication,
    initRemoveFromTopPicks,
    initUpdateApplicationContactState
} from "../../../store/actions/applicationsActions";
import {useDispatch} from "react-redux";
import ApplicationsService from "../../../services/applications.service";
import {useInfiniteQuery, useQueryClient} from "@tanstack/react-query";
import Page from "../../../models/page.model";
import {getQueryKey} from "../utils";
import {ADD_TO_SELECTED_FOR_EXPORT, REMOVE_FROM_SELECTED_FOR_EXPORT} from "../../../store/actions/actionTypes";

interface Props {
    job?: Job,
    status: ApplicationStatus,
    noApplicantsText?: string,
    backgroundColor?: string,
    title?: string,
    titleColor?: string,
    multiselectable?: boolean
}

const Applicants = ({title, titleColor, job, status, noApplicantsText, backgroundColor, multiselectable}: Props) => {

    const history = useHistory()
    const {t} = useTranslation()
    const user = useUser()
    const dispatch = useDispatch()
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const queryClient = useQueryClient()

    const fetchApplications = async ({pageParam = 0}): Promise<Page<OpportunityApplicationModel> | undefined> => {
        if (!job?.id) return

        let fetchFunction: any;
        switch (status) {
            case ApplicationStatus.CREATED:
                fetchFunction = ApplicationsService.fetchOther
                break;
            case ApplicationStatus.INVITED:
                fetchFunction = ApplicationsService.fetchInvited
                break;
            case ApplicationStatus.REJECTED:
                fetchFunction = ApplicationsService.fetchRejected
                break;
            case ApplicationStatus.SHORTLISTED:
                fetchFunction = ApplicationsService.fetchTopPicks
                break;

        }
        return await fetchFunction?.(job?.id, pageParam, 6);
    };

    const query = useInfiniteQuery(getQueryKey(status, job), fetchApplications, {
        getNextPageParam: lastPage => lastPage?.last ? undefined : (lastPage?.number || 0) + 1
    })
    const applications = query.data

    const applicationsState = useApplicationsState()
    const applicationsWithActionInProgress = applicationsState.applicationsWithActionInProgress

    const [selectedApplication, setSelectedApplication] = useState<OpportunityApplicationModel>();
    const [applicantContactInfoOpen, setApplicantContactInfoOpen] = useState(false);
    const [confirmRejectPopupOpen, setConfirmRejectPopupOpen] = useState(false);
    const [confirmDeletePopupOpen, setConfirmDeletePopupOpen] = useState(false);

    const rejectApplicantHandler = (application: OpportunityApplicationModel) => {
        setSelectedApplication(application);
        setConfirmRejectPopupOpen(true);
    };

    const rejectApplicantConfirmHandler = async () => {
        setConfirmRejectPopupOpen(false);
        selectedApplication && dispatch(initRejectApplication(selectedApplication, job!, queryClient))
    };

    const inviteApplicantHandler = async (application: OpportunityApplicationModel) => {
        const response = await dispatch(initInviteApplication(application, job!, queryClient) as any)
        setApplicantContactInfoOpen(true)
        setSelectedApplication(response)
    };

    const deleteApplicantHandler = (application: OpportunityApplicationModel) => {
        setSelectedApplication(application);
        setConfirmDeletePopupOpen(true);
    };

    const deleteApplicantConfirmedHandler = async () => {
        setConfirmDeletePopupOpen(false)
        selectedApplication && dispatch(initFullyDeleteApplication(selectedApplication, job!, queryClient))
    };

    const removeFromTopPicksHandler = async (application: OpportunityApplicationModel) => {
        dispatch(initRemoveFromTopPicks(application, job!, queryClient))
    };

    const addToTopPicksHandler = async (application: OpportunityApplicationModel) => {
        dispatch(initAddToTopPicks(application, job!, queryClient))
    };

    const handleChangeContactStatus = async (contacted: boolean) => {
        selectedApplication && dispatch(initUpdateApplicationContactState(selectedApplication, contacted, status))
    };

    const handleContactInfo = (application: OpportunityApplicationModel) => {
        setApplicantContactInfoOpen(true);
        setSelectedApplication(application);
    };

    const isApplicationActionInProgress = (application?: OpportunityApplicationModel): boolean => {
        if (!application) return false;
        return !!applicationsWithActionInProgress.find(a => a.id === application.id);
    };

    const handleOpen = (application: OpportunityApplicationModel) => {
        // props.setCurrentApplication(application);
        history.push(
            `/application/${application.id}/${application.createdBy?.id}`);
    };

    //endregion Handlers

    //region UI

    let buttonOptions: ('contact' | 'invite' | 'reject' | 'delete' | 'info' | 'moveToTopPicks' | 'removeFromTopPicks')[];
    switch (status) {
        case ApplicationStatus.CREATED:
            buttonOptions = ['invite', 'reject']
            if (isPhAdmin(user)) {
                buttonOptions.push('delete')
                buttonOptions.push('moveToTopPicks')
            }
            break;
        case ApplicationStatus.INVITED:
            buttonOptions = isPhAdmin(user) ? ['contact', 'delete'] : ['contact']
            break;
        case ApplicationStatus.REJECTED:
            buttonOptions = ['delete']
            break;
        case ApplicationStatus.SHORTLISTED:
            buttonOptions = ['invite', 'reject']
            if (isPhAdmin(user)) {
                buttonOptions.push('delete')
                buttonOptions.push('removeFromTopPicks')
            }
            break;
    }

    const allApplicants: OpportunityApplicationModel[] = []
    applications?.pages.forEach(page => allApplicants.push(...(page?.content || [])))

    const renderApplications = () => {
        if (!applications || !applications.pages.length) {
            return !query.isLoading && !!noApplicantsText ? (
                <Alert severity={'info'}>
                    <Typography>{noApplicantsText}</Typography>
                </Alert>
            ) : null
        }

        return allApplicants.map(application => {
            return (
                <ApplicantOverview
                    key={application.id}
                    actions={buttonOptions}
                    application={application}
                    displayAdminUI={user?.userType?.id === UserTypeId.ADMIN_TYPE_ID}
                    onDelete={() => deleteApplicantHandler(application)}
                    onReject={() => rejectApplicantHandler(application)}
                    onInvite={() => inviteApplicantHandler(application)}
                    onOpen={() => handleOpen(application)}
                    onShowContactInfo={() => handleContactInfo(application)}
                    // onVideoPlayed={handleVideoUrl}
                    actionInProgress={isApplicationActionInProgress(application)}
                    onShare={() => {
                        setSelectedApplication(application);
                        setShareDialogOpen(true);
                    }}
                    onRemoveFromTopPicks={() => removeFromTopPicksHandler(application)}
                    onMoveToTopPicks={() => addToTopPicksHandler(application)}
                    // onUpdateRelevance={relevance => setRelevance(application, relevance)}
                    numberOfPastClubs={getPreviousClubs(application.createdBy, []).length}
                    onClick={() => history.push(`/club/job/${job?.id}/application/${application.id}`)}
                    onMiddleClick={() => {
                        window.open(`${process.env.REACT_APP_WEB_URL}/club/job/${job?.id}/application/${application.id}`, '_blank')
                    }}
                    multiselectable={multiselectable}
                    selected={applicationsState.selectedForExport.has(application)}
                    onChangeSelected={selected => {
                        dispatch({
                            type: selected ? ADD_TO_SELECTED_FOR_EXPORT : REMOVE_FROM_SELECTED_FOR_EXPORT,
                            payload: {
                                ...application,
                                opportunity: job
                            } as OpportunityApplicationModel
                        })
                    }}
                />
            )
        });
    }

    return (
        <Box
            p={3}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            style={{backgroundColor: backgroundColor}}
        >
            {
                title &&
                <Box
                    marginBottom={3}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                    flexDirection={'column'}
                >
                    <Typography variant={'h3'} style={{color: titleColor}}>{title}</Typography>
                    {
                        multiselectable && (
                            <Box>
                                <Button
                                    style={{color: titleColor}}
                                    onClick={() => allApplicants.forEach(a => {
                                        dispatch({
                                            type: ADD_TO_SELECTED_FOR_EXPORT,
                                            payload: {
                                                ...a,
                                                opportunity: job
                                            } as OpportunityApplicationModel
                                        })
                                    })}
                                >
                                    Select all visible
                                </Button>
                                <Button
                                    style={{color: titleColor}}
                                    onClick={() => allApplicants.forEach(a => {
                                        dispatch({
                                            type: REMOVE_FROM_SELECTED_FOR_EXPORT,
                                            payload: a
                                        })
                                    })}
                                >
                                    Unselect all in section
                                </Button>
                            </Box>

                        )
                    }
                </Box>
            }

            {/* Top picks */}
            <Grid container spacing={2}>
                {renderApplications()}
            </Grid>

            {
                (query.isFetchingNextPage || query.isLoading) &&
                <Box marginTop={4}>
                    <CircularProgress/>
                </Box>
            }

            <Box m={2}/>

            {
                !query.isFetchingNextPage && query.hasNextPage &&
                <EButton
                    color={'secondary'}
                    variant={'contained'}
                    onClick={() => query.fetchNextPage()}
                    style={{
                        width: 'fit-content'
                    }}
                >
                    <div>{t("Load more Players")}</div>
                </EButton>
            }


            <Popup
                open={confirmRejectPopupOpen}
                onClose={() => setConfirmRejectPopupOpen(false)}
                onConfirm={rejectApplicantConfirmHandler}
                text={t('confirm_delete_applicant')}
                confirmText={t('yes')}
                cancelText={t('no')}
            />

            <Popup
                open={confirmDeletePopupOpen}
                onClose={() => setConfirmDeletePopupOpen(false)}
                onConfirm={deleteApplicantConfirmedHandler}
                text={t('Do you really want to FULLY delete this applicant?')}
                confirmText={t('yes')}
                cancelText={t('no')}
            />

            <Share
                open={shareDialogOpen}
                onClose={() => setShareDialogOpen(false)}
                title={t('share_profile_title_club')}
                text={t('share_profile_message_club')}
                url={generateProfileURL(selectedApplication?.createdBy)}
                shareTo={['facebook', 'twitter', 'email', 'whatsapp', 'linkedin',
                    'pinterest', 'viber', 'telegram', 'vk', 'ok', 'reddit', 'pocket']}
                media={getUserProfilePicture(selectedApplication?.createdBy)}
            />

            <ApplicantContactInfo
                open={applicantContactInfoOpen}
                application={selectedApplication}
                onClose={() => setApplicantContactInfoOpen(false)}
                actionInProgress={isApplicationActionInProgress(selectedApplication)}
                onUpdateContactStatus={handleChangeContactStatus}
            />

        </Box>
    )

    //endregion UI
}

export default Applicants
