import apollo, {createApolloClient, createAuthLinkExplicit} from "./api/apollo";
import {
    ADD_CLUB_ADMIN,
    APPROVE_CLUB_ADMIN,
    CREATE_ALBUM,
    CREATE_MEDIA,
    DELETE_ALBUM,
    DELETE_MEDIA,
    GET_CLUB_ADMIN,
    GET_CLUB_PUBLIC_QUERY,
    GET_CLUB_QUERY,
    GET_CLUB_REQUEST_ADMIN,
    REMOVE_CLUB_ADMIN,
    UPDATE_CLUB_INFO
} from "./api/clubQueries";
import SubscriptionService from "./subscription.service";
import ClubAdminModel from "../models/clubAdmin.model";
import ClubModel from "../models/club.model";
import UserModel from "../models/user.model";
import AlbumModel from "../models/album.model";
import MediaModel from "../models/media.model";

export default class ClubService {

    static async getClub(id: number, viaPublic: boolean, admin: boolean, token?: string): Promise<ClubModel> {
        let query;
        if (viaPublic) {
            query = GET_CLUB_PUBLIC_QUERY;
        } else if (admin) {
            query = GET_CLUB_ADMIN;
        } else {
            query = GET_CLUB_QUERY;
        }

        const api = token ? createApolloClient(createAuthLinkExplicit(token)) : apollo;

        const response = await api.query({
            query: query,
            variables: {
                id
            }
        });

        return {
            ...response.data.club,
            payments: response?.data?.club?.payments?.map(SubscriptionService.convertToWeb)
        }
    }


    static async getClubRequest(id: number): Promise<ClubModel> {
        const response = await apollo.query({
            query: GET_CLUB_REQUEST_ADMIN,
            variables: {
                clubRequestId: id
            }
        });

        return response.data.clubRequest
    }

    static async addClubAdmin(club: ClubModel, user: UserModel): Promise<ClubAdminModel> {
        const response = await apollo.mutate({
            mutation: ADD_CLUB_ADMIN,
            variables: {
                clubId: club.id,
                userId: user.id
            }
        });

        return response?.data?.addClubAdmin;
    }

    static async approveClubAdmin(clubAdmin: ClubAdminModel) {
        const response = await apollo.mutate({
            mutation: APPROVE_CLUB_ADMIN,
            variables: {
                clubAdminId: clubAdmin.id
            }
        });

        return response?.data?.approveClubAdmin;
    }

    static async removeClubAdmin(clubAdmin: ClubAdminModel) {
        const response = await apollo.mutate({
            mutation: REMOVE_CLUB_ADMIN,
            variables: {
                clubAdminId: clubAdmin.id
            }
        });

        return response?.data?.removeClubAdmin;
    }

    static async updateClubInfo(club: ClubModel): Promise<ClubModel> {
        const response = await apollo.mutate({
            mutation: UPDATE_CLUB_INFO,
            variables: {
                club: {
                    id: club.id,
                    name: club.name,
                    country: club.country,
                    city: club.city,
                    about: club.about,
                    logo: club.logo,
                    teams: club.teams,
                    googleGeolocation: club.googleGeolocation
                    // teams: club.teams?.map(team => {
                    //     return {
                    //         id: team.id
                    //     }
                    // })
                }
            }
        });

        return response?.data?.club;
    }

    static async addAlbum(club: ClubModel, name: string): Promise<AlbumModel> {
        const response = await apollo.mutate({
            mutation: CREATE_ALBUM,
            variables: {
                album: {
                    club: {
                        id: club.id
                    },
                    name: name
                }
            }
        });

        return response?.data?.album;
    }

    static async deleteAlbum(album: AlbumModel): Promise<AlbumModel> {
        const response = await apollo.mutate({
            mutation: DELETE_ALBUM,
            variables: {
                mediaAlbumId: album.id
            }
        });

        return response?.data?.deleteAlbum;
    }

    static async addMedia(album: AlbumModel, cloudinaryResponse: any): Promise<MediaModel> {
        const response = await apollo.mutate({
            mutation: CREATE_MEDIA,
            variables: {
                media: {
                    album: {
                        id: album.id
                    },
                    url: cloudinaryResponse.secure_url,
                    publicId: cloudinaryResponse.public_id
                }
            }
        });

        return response.data.media;
    }

    static async deleteMedia(media: MediaModel): Promise<MediaModel> {
        const response = await apollo.mutate({
            mutation: DELETE_MEDIA,
            variables: {
                id: media.id
            }
        });

        return response?.data?.deleteMedia;
    }

}

