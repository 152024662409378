import {StripeInvoiceModel} from "./stripeInvoice.model"

export const StripeSubscriptionStatus = {
    ACTIVE: 'active',
    PAST_DUE: 'past_due',                      // Cannot be paid past due date
    UNPAID: 'unpaid',                          // Past due over, and deadline over, and cannot charge
    CANCELED: 'canceled',                      // Past due over, and deadline over, and cannot charge
    INCOMPLETE: 'incomplete',                  // Auto-charge but initial payment fails
    INCOMPLETE_EXPIRED: 'incomplete_expired',  // Cannot auto-charge for more than 23h
    TRIALING: 'trialing'                       // Trial period
}

export interface StripeSubscription {
    cancelAt?: number
    cancelAtPeriodEnd?: boolean
    canceledAt?: number
    collectionMethod?: string
    created?: number
    currentPeriodEnd?: number
    currentPeriodStart?: number
    daysUntilDue?: number
    endedAt?: number
    id: string
    latestInvoice?: StripeInvoiceModel
    livemode?: boolean
    metadata?: any
    startDate?: number
    status?: string,
    trialStart: number,
    trialEnd?: number
}