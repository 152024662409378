import React, {useCallback, useEffect, useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@material-ui/core"
import debounce from "lodash/debounce"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";
import ETextField from "../../ETextField.component";

const EmailFilter = () => {

    //region State

    const [emailParam] = useUrlParam({param: 'email', dataType: 'string', defaultValue: ''})

    const [emailLocal, setEmailLocal] = useState(emailParam)
    const [manualInput, setManualInput] = useState(false)

    const [expanded, setExpanded] = useState(!!emailLocal)
    const history = useHistory()

    //endregion State

    //region Handlers

    const executeSet = useCallback(debounce((email: any) => {
        setMultipleParams([
            {name: 'email', value: email, dataType: 'string'},
            {name: 'listPage', value: 1, dataType: 'numeric'}
        ], history)
    }, 1000), [])

    useEffect(() => {
        if (!manualInput) return
        executeSet(emailLocal || '')
    }, [emailLocal, manualInput])

    //endregion Handlers

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="email-content"
                id="email-header"
            >
                <Typography>Email {!!emailLocal && `(${emailLocal})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container spacing={1}>
                    <ETextField
                        label={'Email'}
                        grid={'1/1'}
                        value={emailLocal}
                        onChange={e => {
                            setManualInput(true)
                            setEmailLocal(e)
                        }}
                    />
                </Grid>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default EmailFilter