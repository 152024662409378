import React, {useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import Position from "../../Position/Position";
import {useHistory} from "react-router";

export const useStyle = makeStyles(theme => ({
    positions: {
        width: 250,
        height: 300
    },
}))

const PositionsFilter = () => {

    //region State

    const history = useHistory()
    const classes = useStyle()

    const [positions] = useUrlParam<string[]>({
        param: 'positions',
        dataType: 'complex',
        defaultValue: []
    })

    const [expanded, setExpanded] = useState(!!positions?.length)

    //endregion State

    //region Handlers

    //endregion Handlers

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="gender-content"
                id="gender-header"
            >
                <Typography>Positions {!!positions?.length && `(${positions?.length})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Position
                    selectedPrimary={positions}
                    positionClickHandler={position => {
                        let newPositions: string[]
                        if (positions.find(p => p === position)) {
                            newPositions = positions.filter(p => p !== position)
                        } else {
                            newPositions = [...positions, position]
                        }

                        setMultipleParams([
                            {name: 'positions', value: newPositions, dataType: 'complex'},
                            {name: 'listPage', value: 1, dataType: 'numeric'}
                        ], history)
                    }}
                    classes={classes.positions}
                />
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default PositionsFilter