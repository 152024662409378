import React from 'react'
import {Box, Grid, Hidden, Link} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import AnimatedBox from "./Animated.component";
import Carousel from "./Carousel/Carousel.component";
import {useHistory} from "react-router";

const useStyle = makeStyles(theme => ({
    component: {
        width: '100%',
        minHeight: '100vh',
        position: "relative"
    },
    coverBackgroundHolder: {
        position: 'absolute',
        // top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        minHeight: '100vh'
    },
    coverBackground: {
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        objectFit: 'cover'
    },
    logo: {
        width: 122,
        height: 26,
        objectFit: 'contain',
        marginTop: 30,
        zIndex: 1
    },
    title: {
        fontWeight: 300,
        fontSize: 48,
        lineHeight: '56px',
        textAlign: 'center',
        color: '#FFFFFF',
        zIndex: 1,
        maxWidth: 650,
        padding: '0 15px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 36,
            lineHeight: '42px'
        }
    },
    numbersHolder: {
        zIndex: 1
    },
    numbersValue: {
        fontWeight: 'bold',
        fontSize: 24,
        lineHeight: '28px',
        marginLeft: 15,
        marginRight: 15,
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            lineHeight: '23px'
        }
    },
    actionsHolder: {
        zIndex: 1
    },
    actionGreen: {
        height: 48,
        background: theme.palette.primary.main,
        borderRadius: 24,
        paddingLeft: 50,
        paddingRight: 50,
        cursor: 'pointer'
    },
    actionGreenText: {
        fontWeight: 'bold',
        fontSize: 16,
        color: '#FFFFFF',
        textAlign: 'center'
    },
    actionWhite: {
        height: 48,
        background: '#FFFFFF',
        borderRadius: 24,
        paddingLeft: 50,
        paddingRight: 50,
        cursor: 'pointer'
    },
    actionWhiteText: {
        fontWeight: 'bold',
        fontSize: 16,
        // color: LANDING_COMMON_COLORS.TITLE,
        textAlign: 'center',
        color: '#000000',
        textDecoration: 'none'
    },
    carousel: {
        zIndex: 1,
        width: '50%',
        // maxWidth: 1600,
        height: '30vw',
        // marginBottom: -50,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0
        }
    },
    carouselSlide: {
        width: '100%',
        zIndex: 2,
        borderRadius: 7,
        overflow: 'hidden',
        filter: 'drop-shadow(0px 10px 30px rgba(65, 196, 126, 0.45))'
    },
    carouselSlideImage: {
        objectFit: "contain",
        borderRadius: 7,
        width: '100%',
        height: '100%'
    },
    carouselLeft: {
        marginRight: -340
    },
    carouselRight: {
        marginLeft: -340
    },
    loginHolder: {
        position: 'absolute',
        top: 0,
        zIndex: 1,
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            top: 100
        }
    },
    loginCta: {
        height: 38,
        background: theme.palette.primary.main,
        borderRadius: 19,
        paddingLeft: 35,
        paddingRight: 35,
        cursor: 'pointer'
    },
    loginText: {
        fontWeight: 'bold',
        fontSize: 14,
        color: '#FFFFFF',
        textAlign: 'center',
        cursor: 'pointer'
    },
    bottomSpacer: {
        flex: 1
    }
}))

const LandingBanner = () => {

    const classes = useStyle()
    const t = useTranslation().t
    const history = useHistory()


    const handleLogin = () => {
        history.push(`/auth/login`)
    }

    const handleProSubscription = () => {
        history.push(`/auth/club?subscriptionType=PRO`)
    }

    const carousel = (
        <Box
            className={classes.carousel}
        >
            <Carousel
                active={1}
                items={[
                    {
                        id: '1',
                        element: (
                            <picture className={classes.carouselSlide}>
                                <source
                                    srcSet={require('../../assets/images/landing/carousel-2.webp').default}
                                    type="image/webp"
                                    className={classes.carouselSlideImage}
                                />
                                <img
                                    src={require('../../assets/images/landing/carousel-2.jpg').default}
                                    className={classes.carouselSlideImage}
                                    alt={''}
                                />
                            </picture>
                        )
                    },
                    {
                        id: '2',
                        element: (
                            <picture className={classes.carouselSlide}>
                                <source
                                    srcSet={require('../../assets/images/landing/carousel-1.webp').default}
                                    type="image/webp"
                                    className={classes.carouselSlideImage}
                                />
                                <img
                                    src={require('../../assets/images/landing/carousel-1.jpg').default}
                                    className={classes.carouselSlideImage}
                                    alt={''}
                                />
                            </picture>
                        )
                    },
                    {
                        id: '3',
                        element: (
                            <picture className={classes.carouselSlide}>
                                <source
                                    srcSet={require('../../assets/images/landing/carousel-3.webp').default}
                                    type="image/webp"
                                    className={classes.carouselSlideImage}
                                />
                                <img
                                    src={require('../../assets/images/landing/carousel-3.jpg').default}
                                    className={classes.carouselSlideImage}
                                    alt={''}
                                />
                            </picture>
                        )
                    }
                ]}
                direction={'right'}
                variant={3}
            />
        </Box>
    )

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            className={classes.component}
            alignItems={'center'}
            justifyContent={'space-between'}
        >
            <picture className={classes.coverBackgroundHolder}>
                <source
                    srcSet={require('../../assets/images/landing/cover-background.webp').default}
                    type="image/webp"
                    className={classes.coverBackground}
                />
                <img
                    src={require('../../assets/images/landing/cover-background.jpg').default}
                    className={classes.coverBackground}
                    alt={''}
                />
            </picture>

            <img alt={'Playerhunter logo'} className={classes.logo} src={require('../../assets/images/icons/logo-white-normal.svg').default}/>

            <Box
                className={classes.loginHolder}
                display={'flex'}
                width={'100%'}
                p={2}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <Link>
                    {/*<div className={classes.loginText}>{t("I'm a Player")}</div>*/}
                </Link>

                <React.Fragment>
                    <Hidden smDown>
                        <Box onClick={()=>handleLogin()}>
                            <Box
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                className={classes.loginCta}
                            >
                                <div className={classes.loginText}>{t("Have an account? Log in")}</div>
                            </Box>
                        </Box>
                    </Hidden>
                    <Hidden mdUp>
                        <Box onClick={()=>handleLogin()}>
                            <div className={classes.loginText}>{t("Have an account? Log in")}</div>
                        </Box>
                    </Hidden>
                </React.Fragment>

            </Box>

            <Hidden lgUp>
                <div className={classes.bottomSpacer}/>
            </Hidden>

            <AnimatedBox
                className={classes.title}
                fadeIn
                fadeInType={'static'}
            >
                {t("Digital Transfer Matching Platform")}
            </AnimatedBox>

            <AnimatedBox
                display={'flex'}
                className={classes.numbersHolder}

                fadeIn
                fadeInType={'static'}
            >
                <div className={classes.numbersValue}>
                    {t("1800+  clubs")}
                </div>
                <div className={classes.numbersValue}>
                    {t("600+  agencies")}
                </div>
                <div className={classes.numbersValue}>
                    {t("20,000+ players")}
                </div>
                <div className={classes.numbersValue}>
                    {t("4000+ pro players")}
                </div>
            </AnimatedBox>

            <Hidden lgUp>
                <div className={classes.bottomSpacer}/>
            </Hidden>

            <Grid
                container
                justify={'center'}
                spacing={2}
                className={classes.actionsHolder}
            >

                <Grid item>
                    <AnimatedBox
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        className={classes.actionWhite}
                        // onClick={() => {
                        //     (window as any).Intercom('show');
                        // }}

                        fadeIn
                        fadeInType={'static'}
                    >

                        <a href={'mailto:support@playerhunter.com'}
                           className={classes.actionWhiteText}> {t("CONTACT US")}</a>

                    </AnimatedBox>
                </Grid>
            </Grid>

            <Hidden mdDown>
                {carousel}
            </Hidden>

            <Hidden lgUp>
                <div className={classes.bottomSpacer}/>
            </Hidden>


        </Box>
    )
}

export default LandingBanner
