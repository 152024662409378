import React from 'react';
import clsx from 'clsx';
import {Box, Card, CircularProgress, Grid, IconButton} from "@material-ui/core";
import {
    getCityCountryName,
    getCurrentClub,
    getCurrentClubCountry,
    getCurrentClubLogo,
    getCurrentClubName,
    getCurrentClubTeamLevels,
    getFullName,
    getUserAgeLocalized,
    getUserMainPositionLocalizedLong,
    getUserNationalities,
    getUserProfilePicture,
    isAgentPlayer,
    isPhAdmin,
    isPlayerPremium
} from "../../../util/profile.util";
import {useTranslation} from "react-i18next";
import {differenceInDays, format} from "date-fns";
import OpportunityApplicationModel from "../../../models/opportunityApplication.model";
import {getCountryFlag} from "../../../util/flag.util";
import {makeStyles} from "@material-ui/core/styles";
import EButton from "../../common/EButton";
import PlayerType from "../../common/PlayerType.component";
import TransfermarktValue from "../../common/TransfermarktValue.component";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
// import Relevance from "../../../common/components/Relevance/Relevance.component";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyle = makeStyles(theme => ({
    header: {
        width: '100%',
        marginBottom: '10px',
        position: 'relative'
    },
    premiumHeader: {
        width: '100%',
        height: '45px',
        marginTop: '-45px'
    },
    premiumIcon: {
        width: '20px',
        height: '20px'
    },
    premiumText: {
        color: '#FF8D24',
        fontSize: '10px',
        marginTop: '3px'
    },
    agentHeader: {
        width: '100%',
        height: '45px',
        marginTop: '45px',
        justifyContent: 'flex-end'
    },
    hexagon: {
        width: 130,
        height: 130,
        position: 'relative'
    },
    hexagonBackground: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
    },
    profilePic: {
        width: 80,
        height: 80,
        borderRadius: 60,
        objectFit: 'cover',
        left: 'calc(50% - 40px)',
        top: 'calc(50% - 40px)',
        position: 'absolute'
    },
    name: {
        fontWeight: 'bold',
        fontSize: 16,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    flag: {
        marginRight: '10px',
        borderRadius: '2px',
        width: '18px',
    },
    clubInfoWrapper: {
        background: '#F5F5F5',
        borderRadius: '16px',
        padding: '2px 10px',
        maxWidth: 170,
        boxSizing: "border-box"
    },
    clubLogo: {
        width: '17px',
        minHeight: '17px',
        height: '17px',
        marginRight: '10px'
    },
    info: {
        margin: '5px',
        fontSize: '14px',
        // whiteSpace: 'nowrap',
        // textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: 130
    },
    newButton: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '50px',
        height: '30px',
        background: '#ee3f49',
        borderRadius: '5px'
    },
    newText: {
        color: '#ffffff'
    },
    shareIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        width: '30px',
        height: '30px',
        cursor: 'pointer'
    },
    dataLabel: {
        fontSize: '14px',
        color: 'rgba(0, 0, 0, 0.29)',
        marginRight: '10px',
        minWidth: '130px'
    },
    applicantsContainer: {
        width: '100%',
        background: 'white',
        borderRadius: '10px',
        boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.08)',
        padding: '4px 4px 4px',
        cursor: 'pointer',
    },
    premiumContainer: {
        background: 'linear-gradient(to bottom, #eaaa4a, #FF8D24)'
    },
    actionButton: {}
}))

interface Props {
    application: OpportunityApplicationModel;
    onReject?: () => void;
    onInvite?: () => void;
    onShowContactInfo?: () => void;
    onDelete?: () => void;
    onOpen?: () => void;
    actions?: ('contact' | 'invite' | 'reject' | 'delete' | 'info' | 'moveToTopPicks' | 'removeFromTopPicks')[]
    previewType?: string;
    onVideoPlayed?: (videoUrl: string) => void;
    actionInProgress?: boolean;
    displayAdminUI?: boolean;
    onShare?: () => void;
    onMoveToTopPicks?: () => void;
    onRemoveFromTopPicks?: () => void;
    // onUpdateRelevance: (relevance: ApplicationRelevance) => void;
    relevance?: string;
    positionMatrixScore?: number;
    numberOfPastClubs: number;
    onClick: () => void,
    onMiddleClick: () => void,
    multiselectable?: boolean,
    selected?: boolean,
    onChangeSelected?: (selected?: boolean) => void
}

const ApplicantOverview = (props: Props) => {

    // ***** State ***** //

    const user = props.application?.createdBy;
    const t = useTranslation().t;
    const classes = useStyle()

    const premium = isPlayerPremium(props.application.createdBy);
    const isAgentsPlayer = isAgentPlayer(props.application.createdBy)

    // ***** Handlers ***** //

    const handleOpen = (event: any) => {
        event.stopPropagation();
        props.onOpen?.();
    };

    const handleInvite = (event: any) => {
        event.stopPropagation();
        props.onInvite?.();
    };

    const handleMoveToTopPicks = (event: any) => {
        event.stopPropagation();
        props.onMoveToTopPicks?.();
    };

    const handleRemoveFromTopPicks = (event: any) => {
        event.stopPropagation();
        props.onRemoveFromTopPicks?.();
    };

    /**
     * Obsolete
     * @param event
     */
    const handleReject = (event: any) => {
        event.stopPropagation();
        props.onReject?.();
    };

    const handleDelete = (event: any) => {
        event.stopPropagation();
        props.onDelete?.();
    };

    const handleShowContactInfo = (event: any) => {
        event.stopPropagation();
        props.onShowContactInfo?.();
    };

    const handleShare = (event: any) => {
        event.stopPropagation();
        props.onShare?.();
    };

    const handleVideoPlay = (videoUrl?: string) => {
        videoUrl && props.onVideoPlayed?.(videoUrl);
    };

    // ***** Render ***** //

    const renderActions = () => {
        if (props.actionInProgress) {
            return (
                <CircularProgress/>
            )
        }

        return (
            <Grid container spacing={2} alignItems={'center'} justify={'center'}
                // display={'flex'}
                // flexDirection={'row'}
                // justifyContent={'space-between'}
                // style={{height: 120}}
            >
                {
                    props.actions?.map(action => {
                        let text;
                        let onClick;
                        let color: 'primary' | 'error' | 'secondary' = 'primary'
                        let variant: 'text' | 'contained' | 'outlined' = 'text'
                        let size: 'small' | 'medium' | 'large' = 'medium'
                        switch (action) {
                            case 'contact':
                                text = t('Contact info')
                                variant = 'contained'
                                onClick = handleShowContactInfo
                                size = 'large'
                                break;
                            case 'delete':
                                text = t('Delete')
                                onClick = handleDelete
                                color = 'error'
                                size = 'small'
                                break;
                            case 'reject':
                                text = isPhAdmin(user) ? t('Reject') : t('Delete')
                                onClick = handleReject
                                variant = 'text'
                                size = 'small'
                                color = 'secondary'
                                break;
                            case 'info':
                                text = t('info_applicant')
                                variant = 'contained'
                                onClick = handleOpen
                                break;
                            case 'invite':
                                text = t('Contact applicant')
                                variant = 'contained'
                                onClick = handleInvite
                                size = 'large'
                                break
                            case 'moveToTopPicks':
                                text = t('Make top pick')
                                variant = 'text'
                                onClick = handleMoveToTopPicks
                                size = 'small'
                                color = 'secondary'
                                break
                            case 'removeFromTopPicks':
                                text = t('Remove top pick')
                                variant = 'text'
                                onClick = handleRemoveFromTopPicks
                                size = 'small'
                                color = 'secondary'
                                break

                        }
                        return (
                            <Grid item>
                                <EButton
                                    variant={variant}
                                    color={color}
                                    className={classes.actionButton}
                                    onClick={onClick}
                                    size={size}
                                >
                                    {text}
                                </EButton>
                            </Grid>
                        )
                    })
                }

            </Grid>
        )
    };

    const profileImageUI = (
        <Box className={classes.hexagon}>
            <img alt={''}
                 src={premium ? require('../../../assets/images/hexagon-gold.svg').default : require('../../../assets/images/hexagon.svg').default}
                 className={classes.hexagonBackground}/>
            <img alt={'Profile'}
                 src={getUserProfilePicture(user, require('../../../assets/images/avatar.svg').default)}
                 className={classes.profilePic}/>
        </Box>
    )

    const nameAndNationalityUI = (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-start'}
        >
            <div className={classes.name}>{getFullName(user, '-')}</div>

            <PlayerType user={user} showForRegular/>

            <TransfermarktValue user={user} smallMode/>

            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginTop={2}
            >
                {
                    getUserNationalities(user, [])
                        .slice(0, 3)
                        .map(nationality =>
                            <div
                                key={nationality.id}
                                className={clsx(classes.flag, 'flag-icon', getCountryFlag(nationality))}
                            />
                        )
                }
            </Box>
        </Box>
    )

    const clubNameUI = !!getCurrentClub(user) && !user?.profile?.skipCurrentClub ?
        (
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                className={classes.clubInfoWrapper}
            >
                <img alt={'Club logo'} className={classes.clubLogo}
                     src={getCurrentClubLogo(user, require('../../../assets/images/fake-club.svg').default)}/>
                <p className={classes.info}>{getCurrentClubName(user, '-')}</p>
                {
                    getCurrentClubCountry(user) &&
                    <div
                        className={clsx(classes.flag, 'flag-icon', getCountryFlag(getCurrentClubCountry(user)!))}
                    />
                }
            </Box>
        )
        :
        (
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                className={classes.clubInfoWrapper}
            >
                <img alt={'Club logo'} className={classes.clubLogo}
                     src={require('../../../assets/images/fake-club.svg').default}/>
                <p className={classes.info}>-</p>
            </Box>
        )

    const newApplicationUI = props.application.createdAt && differenceInDays(new Date(), props.application.createdAt) < 7 &&
        (
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                className={classes.newButton}
            >
                <div className={classes.newText}>{t('New')}</div>
            </Box>
        )


    return (
        <Grid item xs={12} lg={6} xl={6}>
            <Box
                style={{width: '100%', cursor: 'pointer'}}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                onClick={props.onClick}
                onMouseDown={e => {
                    if (e.button === 1) {
                        props.onMiddleClick()
                    }
                }}
                position={'relative'}
            >
                {
                    props.multiselectable &&
                    <IconButton
                        style={{
                            position: 'absolute',
                            left: 0,
                            top: 0
                        }}
                        onClick={e => {
                            e.stopPropagation()
                            props.onChangeSelected?.(!props.selected)
                        }}
                    >
                        {
                            props.selected ? <CheckCircleIcon /> :<RadioButtonUncheckedIcon/>
                        }

                    </IconButton>
                }

                <Card style={{flex: 1}}>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        p={3}
                    >
                        {profileImageUI}

                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                        >
                            {nameAndNationalityUI}
                            <Box m={1}/>
                            {clubNameUI}
                        </Box>

                        <Box marginLeft={'auto'} marginRight={'auto'}>
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                <div className={classes.dataLabel}>{t('league_level')}</div>
                                <p className={classes.info}>{getCurrentClubTeamLevels(user, '-')}</p>
                            </Box>

                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                <div className={classes.dataLabel}>{t('age')}</div>
                                <p className={classes.info}>{getUserAgeLocalized(t, user, '-')}</p>
                            </Box>

                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                <div className={classes.dataLabel}>{t('location')}</div>
                                <p className={classes.info}>{getCityCountryName(user, '-')}</p>
                            </Box>

                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                <div className={classes.dataLabel}>{t('positions')}</div>
                                <div
                                    className={classes.info}>{getUserMainPositionLocalizedLong(t, user, '-')}</div>
                            </Box>

                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                <div className={classes.dataLabel}>{t('Application Date')}</div>
                                <div
                                    className={classes.info}>{props.application.createdAt ? format(props.application.createdAt, 'dd.MM.yyyy') : '-'}</div>
                            </Box>
                        </Box>

                    </Box>

                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-around'}
                        height={'fit-content'}
                        marginBottom={2}
                        // minWidth={100}
                        // height={'100%'}
                    >
                        {renderActions()}
                    </Box>

                </Card>

            </Box>
        </Grid>
    );

}

export default ApplicantOverview;
