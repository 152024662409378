import React, {useEffect, useState} from 'react'
import Dialog from "@material-ui/core/Dialog"
import {useTranslation} from "react-i18next"
import {Box, Button, Card, CircularProgress, Typography} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"
import SubscriptionService from "../../services/subscription.service";
import {SET_SUBSCRIBING_TO_PRO_TRIAL_IN_PROGRESS, SET_SUBSCRIPTION} from "../../store/actions/actionTypes";
import {useSelector, useStore} from "react-redux";
import {BillingState} from "../../store/reducers/billing.reducer";
import {StripeProduct, StripeProductPrice} from "../../models/StripeModels/stripeProduct.model";
import {StripeSubscriptionStatus} from "../../models/StripeModels/stripeSubscription.model";
import {useUser} from "../../store/store.utils";
import AuthService from "../../services/auth.service";

const useStyles = makeStyles(_theme => ({
    container: {
        width: 300,
        minHeight: 200
    },
    messageLarge: {
        fontWeight: 'bold',
        marginBottom: 20
    },
    messageSmall: {
        marginTop: 20
    },
    confirmText1: {
        textAlign: 'center'
    },
    confirmText2: {
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 25
    },
    activateButton: {
        marginLeft: 50
    }
}))

interface Props {
    // onConfirm: (product: StripeProduct, price: StripeProductPrice) => void
    onDone: (subscribed: boolean) => void
}

const ActivatingProTrialPopup = (props: Props) => {

    const t = useTranslation().t
    const classes = useStyles()
    const store = useStore()
    const {subscribingToProTrialInProgress} = useSelector<any, BillingState>(store => store.billing)
    const [product, setProduct] = useState<StripeProduct>()
    const [price, setPrice] = useState<StripeProductPrice>()
    const [fetchingInProgress, setFetchingInProgress] = useState(false)
    const [subscribeInProgress, setSubscribeInProgress] = useState(false)
    const user = useUser()
    const club = AuthService.getAdminClub(user)

    const getStripeProducts = async () => {
        setFetchingInProgress(true);
        try {
            const response = await SubscriptionService.getStripeProducts();
            const product = response.find(product => product.metadata.package === 'PRO');
            setProduct(product);
            setPrice(product?.prices?.[0])
        } catch (e) {
            console.error(e);
        } finally {
            setFetchingInProgress(false);
        }
    }

    const onPurchase = async () => {
        if (!product || !price) return

        setSubscribeInProgress(true)
        let subscribed
        let stripeSubscirption
        stripeSubscirption = await SubscriptionService.purchaseStripeSubscription(club!, price)

        if (stripeSubscirption?.status === StripeSubscriptionStatus.INCOMPLETE) {
            // const invoice = await SubscriptionService.getStripeInvoice(club, stripeSubscirption.latestInvoice?.id!)
            // const response = await stripe?.confirmCardPayment(invoice?.paymentIntent?.clientSecret!, {
            //     return_url: window.location.href
            // })
            // if (response?.paymentIntent?.status === 'succeeded') {
            //     subscribed = true
            // }
            subscribed = false
        } else {
            subscribed = true
        }

        if (subscribed && stripeSubscirption && stripeSubscirption.currentPeriodStart && stripeSubscirption.currentPeriodEnd) {
            props.onDone(true)
        } else {
            props.onDone(false)
        }
    }

    useEffect(() => {
        if (subscribingToProTrialInProgress) {
            getStripeProducts()
        }
    }, [subscribingToProTrialInProgress])

    const renderConfirmState = () => {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                className={classes.container}
                p={4}
            >
                <Typography className={classes.messageLarge}>{t('Activate your PRO trial?')}</Typography>
                <Typography
                    variant={"body1"}
                    className={classes.confirmText1}>{t('Pro popup message 1', {days: product?.metadata?.trialLengthDays})}
                </Typography>
                <Typography
                    variant={"body1"}
                    className={classes.confirmText2}>{t('Pro popup message 2')}
                </Typography>

                {
                    subscribeInProgress ?
                        <CircularProgress/> :
                        <Box display={'flex'}>
                            <Button onClick={() => {
                                store.dispatch({type: SET_SUBSCRIBING_TO_PRO_TRIAL_IN_PROGRESS, payload: false})
                            }}>
                                {t('Cancel')}
                            </Button>
                            <Button
                                className={classes.activateButton}
                                onClick={onPurchase}
                                variant={'contained'}
                                color={'primary'}
                            >
                                {t('Activate')}
                            </Button>
                        </Box>
                }

            </Box>
        )
    }

    return (
        <Dialog open={subscribingToProTrialInProgress}>
            <Card>
                <Box
                    className={classes.container}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    {
                        fetchingInProgress ? <CircularProgress/> : renderConfirmState()
                    }
                </Box>
            </Card>
        </Dialog>
    )
}

export default ActivatingProTrialPopup
