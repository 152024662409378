import {Box, Card, Tab, Tabs} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {useFilterStyles} from "../common/List/list.style"
import DeviceHubOutlinedIcon from '@material-ui/icons/DeviceHubOutlined'
import FilterTiltShiftOutlinedIcon from '@material-ui/icons/FilterTiltShiftOutlined'
import {GeneralSet} from "../common/generalSet"
import EButton from "../common/EButton"
import {useTranslation} from "react-i18next";
import DashboardFilters2, {FILTERS} from "../common/DashboardFilters/DashboardFilters.component";
import {useHistory} from "react-router";
import {useJobFilters} from "./useJobFilters";
import Job, {JobStatus} from "../../models/job.model";
import {useApplicationsState} from "../../store/store.utils";

interface Props {
    selected: GeneralSet<Job>,

    onExportAll?: () => void,
    onExportSelected?: () => void,
    onActivateSelected?: () => void,
    onDeactivateSelected?: () => void,
    onDeleteSelected?: () => void,
    onFiltersChange?: (filters: any) => void
    onAnalyzeNotificationRecipients?: (job?: Job) => void
        onPrintApplicants?: () => void
}

const JobFilterActions = (props: Props) => {

    const filterClasses = useFilterStyles()
    const [tab, setTab] = useState(0)
    const {t} = useTranslation()
    const history = useHistory()
    const [filters, clearAllFilters] = useJobFilters()
    const {selectedForExport} = useApplicationsState()

    useEffect(() => {
        if (props.selected.size()) {
            setTab(1)
        }
    }, [props.selected])

    // region Filters


    const renderFilters = () => (
        <Box display={'flex'} flexDirection={'column'}>

            <DashboardFilters2 filters={[
                FILTERS.CLUB,
                FILTERS.AGENT,
                FILTERS.TEAM_LEVEL,
                FILTERS.INTERNATIONAL_ONLY,
                FILTERS.COUNTRIES,
                FILTERS.AGE,
                FILTERS.APPLICANTS,
                FILTERS.STATUS,
                FILTERS.GENDER,
                FILTERS.POSITIONS,
                FILTERS.APPLICATION_STATUS,
                FILTERS.TRANSFER_FEE,
                FILTERS.SALARY
            ]}/>

            <EButton
                color={'error'}
                style={{alignSelf: 'center', width: '100%', marginTop: 5}}
                onClick={clearAllFilters}
            >
                Clear all filters
            </EButton>

        </Box>
    )

    const renderActions = () => (
        <Box display={'flex'} flexDirection={'column'} p={2}>

            <EButton
                variant={'contained'}
                color={'primary'}
                onClick={props.onExportAll}
            >
                Export all
            </EButton>

            <Box m={1}/>

            {
                props.selected.size() > 0 &&
                <React.Fragment>
                    <EButton
                        variant={'contained'}
                        color={'primary'}
                        onClick={props.onExportSelected}
                    >
                        Export selected
                    </EButton>
                    <Box m={2}/>
                </React.Fragment>
            }


            {
                props.selected.size() > 0 &&
                <React.Fragment>
                    <EButton
                        variant={'outlined'}
                        color={'primary'}
                        onClick={props.onActivateSelected}
                    >
                        Activate {props.selected.size()} jobs
                    </EButton>
                    <Box m={1}/>
                </React.Fragment>
            }

            {
                selectedForExport.size() > 0 &&
                <React.Fragment>
                    <EButton
                        variant={'outlined'}
                        color={'primary'}
                        onClick={props.onPrintApplicants}
                    >
                        Print selected applicants
                    </EButton>
                    <Box m={1}/>
                </React.Fragment>
            }


            {
                props.selected.size() > 0 &&
                <React.Fragment>
                    <EButton
                        variant={'outlined'}
                        color={'warning'}
                        onClick={props.onDeactivateSelected}
                    >
                        Deactivate {props.selected.size()} jobs
                    </EButton>
                    <Box m={1}/>
                </React.Fragment>
            }

            {
                props.selected.size() > 0 &&
                <React.Fragment>
                    <EButton
                        variant={'outlined'}
                        color={'error'}
                        onClick={props.onDeleteSelected}
                    >
                        Delete {props.selected.size()} jobs
                    </EButton>
                    <Box m={1}/>
                </React.Fragment>
            }

            {
                props.selected.size() === 1 &&
                props.selected.values()?.[0]?.opportunityStatus === JobStatus.NOT_APPROVED &&
                <EButton
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => props.onAnalyzeNotificationRecipients?.(props.selected.values()?.[0])}
                >
                    AlgoBug - Analyse notification recipients
                </EButton>
            }

        </Box>
    )

    return (
        <Card className={filterClasses.container}>
            <Box p={2}>
                <Tabs
                    textColor={'primary'}
                    indicatorColor={'primary'}
                    value={tab}
                    onChange={(_e, selected) => setTab(selected)}
                >
                    <Tab icon={<FilterTiltShiftOutlinedIcon/>} label={t('Filters')} tabIndex={0}/>
                    <Tab icon={<DeviceHubOutlinedIcon/>} label={t('Actions')} tabIndex={1}/>
                </Tabs>
            </Box>

            {tab === 0 && renderFilters()}
            {tab === 1 && renderActions()}

        </Card>
    )
}

export default JobFilterActions
