export const getPositionsForSection = (section?: string) => {
    switch (section) {
        case 'forward':
            return ['Forward', 'LeftWing', 'RightWing'];
        case 'midfield':
            return ['AttackingMidfield', 'LeftMidfield', 'CentralMidfield', 'RightMidfield', 'DefensiveMidfield'];
        case 'defense':
            return ['LeftFullBack', 'CentralDefender', 'RightFullBack'];
        case 'goalkeeper':
            return ['Goalkeeper'];
        default:
            return [];
    }
};

export const getSectionForPositions = (positions: string[]) => {
    const positionsSet = new Set<string>(positions);
    if (positionsSet.has('Forward') &&
        positionsSet.has('LeftWing') &&
        positionsSet.has('RightWing') &&
        positionsSet.size === 3
    ) {
        return 'forward';
    } else if (
        positionsSet.has('AttackingMidfield') &&
        positionsSet.has('LeftMidfield') &&
        positionsSet.has('CentralMidfield') &&
        positionsSet.has('RightMidfield') &&
        positionsSet.has('DefensiveMidfield') &&
        positionsSet.size === 5
    ) {
        return 'midfield'
    } else if (
        positionsSet.has('LeftFullBack') &&
        positionsSet.has('CentralDefender') &&
        positionsSet.has('RightFullBack') &&
        positionsSet.size === 3) {
        return 'defense';
    }
    return null;
};