import React from 'react';
import styles from './ClubBadge.module.scss';
import clsx from 'clsx';
import {Box} from "@material-ui/core";

const ClubBadgeLoader = () => {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={clsx(styles.component)}
        >
            <div className={clsx(styles.logo, 'ovalLoader')}/>
            <div className={clsx(styles.clubName, 'boxLoaderMedium', 'boxLoaderLong')}/>
            <div className={clsx(styles.clubLocation, 'boxLoaderSmall', 'boxLoaderNormal')}/>
        </Box>
    )
};

export default ClubBadgeLoader;
