import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {Box, Card, CircularProgress} from "@material-ui/core";
import PlayerProfilePreview from "./preview/PlayerProfilePreview";
import {isPhAdmin} from "../../util/profile.util";
import EButton from "../common/EButton";
import OpportunityApplicationModel, {ApplicationStatus} from "../../models/opportunityApplication.model";
import {
    initAddToTopPicks,
    initFullyDeleteApplication,
    initInviteApplication,
    initLoadApplication,
    initRejectApplication,
    initRemoveFromTopPicks,
    initUpdateApplicationContactState
} from "../../store/actions/applicationsActions";
import MixpanelService from "../../services/analytics/mixpanel.service";
import {EventName} from "../../services/analytics/eventName";
import {EventProperty} from "../../services/analytics/eventProperty";
import {PeopleProperty} from "../../services/analytics/peopleProperty";
import {useDispatch} from "react-redux";
import {useApplicationsState, useUser} from "../../store/store.utils";
import ApplicantContactInfo from "../job/ApplicantContactInfo/ApplicantContactInfo";
import Popup from "../common/Popup/Popup";
import {useTranslation} from "react-i18next";
import {ScreenName} from "../../services/analytics/screenName";
import {useQueryClient} from "@tanstack/react-query";


const PlayerApplicantInfo = () => {

    //region State

    const [applicantContactInfoOpen, setApplicantContactInfoOpen] = useState(false);
    const [confirmRejectPopupOpen, setConfirmRejectPopupOpen] = useState(false);
    const {application, applicationsWithActionInProgress, applicationLoading} = useApplicationsState()
    const loggedInUser = useUser()
    const params = useParams<{ id?: string }>()
    const id = +params.id!
    const dispatch = useDispatch()
    const actionInProgress = !!applicationsWithActionInProgress.find(a => a.id === application?.id)
    const t = useTranslation().t
    const history = useHistory()
    const queryClient = useQueryClient()

    //endregion State

    //region Handlers

    useEffect(() => {
        const get = async () => {
            try {
                const application: OpportunityApplicationModel = await dispatch(initLoadApplication(id) as any)

                MixpanelService.trackScreenVisit(ScreenName.VIEW_APPLICANT_DETAILS, {
                    [EventProperty.APPLICANT_USER_ID]: application?.createdBy?.id
                })
                MixpanelService.setUserProperties({
                    [PeopleProperty.LAST_APPLICANT_DETAILS_REVIEW]: MixpanelService.getCurrentTimeIso(),
                })
                MixpanelService.incrementProperty(PeopleProperty.TOTAL_APPLICANT_DETAILS_REVIEWED, 1)

            } catch (e) {
                console.log(e);
            }
        }

        if (id) {
            get();
        }
    }, [id])

    const rejectApplicantHandler = () => {
        setConfirmRejectPopupOpen(true);
    };

    const rejectApplicantConfirmHandler = async () => {
        setConfirmRejectPopupOpen(false);
        dispatch(initRejectApplication(application!, application?.opportunity!, queryClient))
    };

    const inviteApplicantHandler = async () => {
        const response = await dispatch(initInviteApplication(application!, application?.opportunity!, queryClient) as any)
        setApplicantContactInfoOpen(true)
    };

    // const deleteApplicantHandler = (application: OpportunityApplicationModel) => {
    //     setSelectedApplication(application);
    //     setConfirmDeletePopupOpen(true);
    // };

    const deleteApplicantConfirmedHandler = async () => {
        // setConfirmDeletePopupOpen(false)
        await dispatch(initFullyDeleteApplication(application!, application?.opportunity!, queryClient))
        history.goBack()
    };

    const removeFromTopPicksHandler = async () => {
        dispatch(initRemoveFromTopPicks(application!, application?.opportunity!, queryClient))
    };

    const addToTopPicksHandler = async () => {
        dispatch(initAddToTopPicks(application!, application?.opportunity!, queryClient))
    };

    const handleContactInfo = () => {
        setApplicantContactInfoOpen(true);
    };

    const handleChangeContactStatus = async (contacted: boolean) => {
        const response = await dispatch(initUpdateApplicationContactState(application!, contacted) as any)

        MixpanelService.track(EventName.APPLICANT_CONTACT_STATUS, {
            [EventProperty.PLAYER_ID]: response.createdBy?.id,
            [EventProperty.CONTACTED]: response.contacted
        })
    };

    //endregion Handlers

    //region UI

    let buttonOptions: ('contact' | 'invite' | 'reject' | 'delete' | 'moveToTopPicks' | 'removeFromTopPicks')[];
    switch (application?.status) {
        case ApplicationStatus.CREATED:
            buttonOptions = ['invite', 'reject']
            if (isPhAdmin(loggedInUser)) {
                buttonOptions.push('delete')
                buttonOptions.push('moveToTopPicks')
            }
            break;
        case ApplicationStatus.INVITED:
            buttonOptions = isPhAdmin(loggedInUser) ? ['contact', 'delete'] : ['contact']
            break;
        case ApplicationStatus.REJECTED:
            buttonOptions = ['delete']
            break;
        case ApplicationStatus.SHORTLISTED:
            buttonOptions = ['invite', 'reject']
            if (isPhAdmin(loggedInUser)) {
                buttonOptions.push('delete')
                buttonOptions.push('removeFromTopPicks')
            }
            break;
    }

    const renderActions = () => {
        if (actionInProgress) {
            return (
                <Box
                    display={'flex'}
                    flexDirection={'flex'}
                    justifyContent={'alignCenter'}
                    p={3}
                >
                    <CircularProgress/>
                </Box>
            )
        }

        return (
            <Box
                display={'flex'}
                flexDirection={'flex'}
                justifyContent={'alignCenter'}
                p={3}
            >
                {
                    buttonOptions?.map(action => {
                        let text;
                        let onClick;
                        let color: 'primary' | 'error' | 'secondary' = 'primary'
                        let variant: 'text' | 'contained' | 'outlined' = 'text'
                        let size: 'small' | 'medium' | 'large' = 'medium'
                        switch (action) {
                            case 'contact':
                                text = t('Contact info')
                                variant = 'contained'
                                onClick = handleContactInfo
                                size = 'large'
                                break;
                            case 'delete':
                                text = t('Delete')
                                onClick = deleteApplicantConfirmedHandler
                                color = 'error'
                                size = 'small'
                                break;
                            case 'reject':
                                text = isPhAdmin(loggedInUser) ? t('Reject') : t('Delete')
                                onClick = rejectApplicantHandler
                                variant = 'text'
                                size = 'small'
                                color = 'secondary'
                                break;
                            case 'invite':
                                text = t('Contact applicant')
                                variant = 'contained'
                                onClick = inviteApplicantHandler
                                size = 'large'
                                break
                            case 'moveToTopPicks':
                                text = t('Make top pick')
                                variant = 'text'
                                onClick = addToTopPicksHandler
                                size = 'small'
                                color = 'secondary'
                                break
                            case 'removeFromTopPicks':
                                text = t('Remove top pick')
                                variant = 'text'
                                onClick = removeFromTopPicksHandler
                                size = 'small'
                                color = 'secondary'
                                break

                        }
                        return (
                            <EButton
                                variant={variant}
                                color={color}
                                onClick={onClick}
                                size={size}
                            >
                                {text}
                            </EButton>
                        )
                    })
                }

            </Box>
        )
    };

    if (applicationLoading) {
        return (
            <CircularProgress/>
        )
    }

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
        >

            <Box
                marginTop={3}
                marginBottom={3}
                maxWidth={1100}
                width={'100%'}
                alignSelf={'center'}
            >
                <Card>
                    {renderActions()}
                </Card>
            </Box>

            <PlayerProfilePreview
                user={application?.createdBy}
            />

            <ApplicantContactInfo
                open={applicantContactInfoOpen}
                application={application}
                onClose={() => setApplicantContactInfoOpen(false)}
                actionInProgress={false}
                onUpdateContactStatus={handleChangeContactStatus}
            />

            <Popup
                open={confirmRejectPopupOpen}
                onClose={() => setConfirmRejectPopupOpen(false)}
                onConfirm={rejectApplicantConfirmHandler}
                text={t('confirm_delete_applicant')}
                confirmText={t('yes')}
                cancelText={t('no')}
            />


        </Box>
    )

    //endregion UI
}

export default PlayerApplicantInfo
