import {differenceInYears, format, isAfter} from "date-fns"
import {TFunction} from "i18next"
import ClubModel from "../models/club.model"
import Team from "../models/team.model"
import SkillModel from "../models/skill.model"
import MediaModel from "../models/media.model"
import LanguageModel from "../models/language.model"
import CountryModel from "../models/country.model"
import PastClubModel from "../models/pastClub.model"
import {latLngRaw} from "../models/geocodeResult.model"
import {UserSubscriptionModel, UserSubscriptionType} from "../models/userSubscription.model"
import UserModel, {UserProfile, UserTypeId} from "../models/user.model";
import {LoginType} from "../models/loginType.model";
import Job from "../models/job.model";

export function getProfilePicture(user?: UserModel, defaultValue?: string) {
    return user?.profile?.profilePicture || defaultValue;
}

export function getProfile(user?: UserModel) {
    return user?.profile
}

export function getProfileProfilePicture(profile?: UserProfile, defaultValue: any = null) {
    const picture = profile?.profilePicture
    if (picture) {
        if (picture.startsWith('https')) {
            return picture
        } else {
            return picture.replace('http:', 'https:')
        }
    }

    return defaultValue
}

export function getUserProfilePicture(user?: UserModel, defaultValue: any = null) {
    return getProfileProfilePicture(getProfile(user), defaultValue)
}

export function getFirstName(user?: UserModel, defaultValue: any = null) {
    const profile = getProfile(user)
    return profile?.firstName || defaultValue
}

export function getLastName(user?: UserModel, defaultValue: any = null) {
    const profile = getProfile(user)
    return profile?.lastName || defaultValue
}

export function getFullName(user?: UserModel, defaultValue: any = null) {
    const parts = []
    if (getFirstName(user)) parts.push(getFirstName(user))
    if (getLastName(user)) parts.push(getLastName(user))
    return parts.join(' ') || defaultValue
}

export function getGender(user?: UserModel, defaultValue: any = null) {
    const profile = getProfile(user)
    return profile?.gender || defaultValue
}

export function getPhone(user?: UserModel, defaultValue: any = null) {
    const profile = getProfile(user)
    return profile?.contactInfo?.phone || user?.parent?.userProfile?.contactInfo?.phone || defaultValue
}

export function getEmail(user?: UserModel, defaultValue: any = null) {
    const profile = getProfile(user)
    return (profile && (profile.contactInfo?.email || user?.email)) || user?.parent?.userProfile?.contactInfo?.email || defaultValue
}

export function getRole(user?: UserModel, defaultValue: any = null) {
    return user?.userType?.name || defaultValue
}

export function getPassword(user?: UserModel, defaultValue: any = null) {
    return user?.password || defaultValue
}

export function getPreferredFoot(user?: UserModel, defaultValue: any = null) {
    const profile = getProfile(user)
    return profile?.preferredFoot || defaultValue
}

export function getGenderLocalized(t: TFunction, user?: UserModel, defaultValue: any = null) {
    const gender = getGender(user)
    return (gender && t(gender.toLowerCase())) || defaultValue
}

export function getGenderLocalizedEng(t: TFunction, user?: UserModel, defaultValue: any = null) {
    const gender = getGender(user)
    return (gender && t(gender.toLowerCase(), {lng: 'en'})) || defaultValue
}

export function getUserDateOfBirth(user?: UserModel, defaultValue: any = null): Date {
    const profile = getProfile(user)
    return profile?.dateOfBirth && new Date(profile.dateOfBirth) || defaultValue
}


export function getUserDateOfBirthFormatted(user?: UserModel, defaultValue: any = null) {
    const profile = getProfile(user)
    if (profile?.dateOfBirth) {
        return format(new Date(profile.dateOfBirth), 'dd.MM.yyyy')
    } else {
        return defaultValue
    }
}

export function getUserBirthdate(user?: UserModel, defaultValue?: Date) {
    return user?.profile?.dateOfBirth && new Date(user.profile.dateOfBirth) || defaultValue;
}

export function getUserAge(user?: UserModel, defaultValue: any = null) {
    const profile = getProfile(user)
    if (profile?.dateOfBirth) {
        return differenceInYears(new Date(), new Date(profile?.dateOfBirth))
    } else {
        return defaultValue
    }
}

export function getCountry(user?: UserModel, defaultValue: any = null) {
    const profile = getProfile(user)
    return profile?.location?.country || profile?.country || profile?.city?.country || defaultValue
}

export function getCountryName(user?: UserModel, defaultValue: any = null) {
    const country = getCountry(user)
    return country?.name || defaultValue
}

export const getProfileLocationGeo = (profile?: UserProfile): latLngRaw | undefined => {
    const geocodeLocation = profile?.googleGeolocation?.results?.[0]?.geometry?.location
    if (geocodeLocation?.lat && geocodeLocation?.lng) {
        return {
            lat: geocodeLocation.lat,
            lng: geocodeLocation.lng
        }
    }

    if (!profile?.city?.longitude || !profile.city.latitude) return

    return {
        lng: profile.city.longitude,
        lat: profile.city.latitude
    }
}

export const getUserLocationGeo = (user?: UserModel): latLngRaw | undefined => {
    return getProfileLocationGeo(getProfile(user))
}

export function getCityName(user?: UserModel, defaultValue: any = null) {
    const profile = getProfile(user)
    return profile?.location?.name || profile?.city?.name || defaultValue
}

export function getProfileCityCountryName(profile?: UserProfile, defaultValue: any = null) {
    const location = [profile?.location?.name, profile?.location?.country?.name]
    return location.filter(i => !!i).join(', ') || defaultValue
}

export function getCityCountryName(user?: UserModel, defaultValue: any = null) {
    const location = [getCityName(user), getCountryName(user)]
    return location.filter(i => !!i).join(', ') || defaultValue
}

export function getUserAgeLocalized(t: TFunction, user?: UserModel, defaultValue: any = null) {
    const age = getUserAge(user, 0)
    if (age) {
        return t('age_applicant', {'age': age})
    } else {
        return defaultValue
    }
}

export function getProfileMainPosition(profile?: UserProfile, defaultValue: any = null) {
    return profile?.mainPosition || defaultValue
}

export function getUserMainPosition(user?: UserModel, defaultValue: any = null) {
    return getProfileMainPosition(getProfile(user), defaultValue)
}

export function getUserMainPositionLocalizedShort(t: TFunction, user?: UserModel, defaultValue: any = null) {
    const mainPosition = getUserMainPosition(user)
    return (mainPosition && t(`position_${mainPosition}_short`)) || defaultValue
}

export function getUserMainPositionLocalizedLong(t: TFunction, user?: UserModel, defaultValue: any = null) {
    const mainPosition = getUserMainPosition(user)
    return (mainPosition && t(`position_${mainPosition}_long`)) || defaultValue
}

export function getUserMainPositionLocalizedLongEng(t: TFunction, user?: UserModel, defaultValue: any = null) {
    const mainPosition = getUserMainPosition(user)
    return (mainPosition && t(`position_${mainPosition}_long`, {lng: 'en'})) || defaultValue
}

export function getUserSecondaryPositionsLocalizedShort(t: TFunction, user?: UserModel, defaultValue: any = null) {
    const secondaryPositions = getUserSecondaryPositions(user)
    return secondaryPositions?.map(pos => t(`position_${pos}_short`)) || defaultValue
}

export function getUserSecondaryPositionsLocalizedLong(t: TFunction, user?: UserModel, defaultValue: any = null) {
    const secondaryPositions = getUserSecondaryPositions(user)
    return secondaryPositions && secondaryPositions.map(pos => t(`position_${pos}_long`) || defaultValue)
}

export function getProfileSecondaryPositions(profile?: UserProfile, defaultValue: any = []): string[] {
    return profile?.secondaryPositions || defaultValue
}

export function getUserSecondaryPositions(user?: UserModel, defaultValue: any = []): string[] {
    return getProfileSecondaryPositions(getProfile(user), defaultValue)
}

export function getCurrentClub(user?: UserModel): ClubModel | undefined {
    return user?.profile?.currentClub || user?.profile?.currentClubRequest
}

export function getCurrentContractFrom(user?: UserModel, defaultValue?: Date): Date | undefined {
    return (user?.profile?.currentContractFrom && new Date(user?.profile?.currentContractFrom)) || defaultValue
}

export function getCurrentContractTo(user?: UserModel, defaultValue?: Date): Date | undefined {
    return (user?.profile?.currentContractTo && new Date(user?.profile?.currentContractTo)) || defaultValue
}

export function getCurrentContractFormatted(t: TFunction, user?: UserModel) {
    if (!user?.profile?.currentContractFrom && !user?.profile?.currentContractTo) {
        return t('Free agent')
    }

    if (user?.profile?.currentContractTo && isAfter(new Date(), user?.profile?.currentContractTo)) {
        return t('Free agent')
    }

    const parts = []
    const currentContractFrom = getCurrentContractFrom(user)
    if (currentContractFrom) {
        parts.push(format(currentContractFrom, 'MM/yyyy'))
        parts.push('-')
    }

    const currentContractTo = getCurrentContractTo(user)
    if (currentContractTo) {
        parts.push(format(currentContractTo, 'MM/yyyy'))
    } else {
        parts.push(t('present'))
    }

    return parts.join(' ')
}

export function getClubLogo(club?: ClubModel, defaultValue: any = null) {
    return club?.logo || defaultValue
}

export function getClubDescription(club?: ClubModel, defaultValue: any = null) {
    return club?.about || defaultValue
}

export function getCurrentClubLogo(user?: UserModel, defaultValue: any = null) {
    return getClubLogo(getCurrentClub(user), defaultValue)
}

export function getClubName(club?: ClubModel, defaultValue: any = null): string {
    return club?.name || defaultValue
}

export function getCurrentClubName(user?: UserModel, defaultValue: any = null) {
    return getClubName(getCurrentClub(user), defaultValue)
}

export function getClubTeams(club?: ClubModel, defaultValue: any = []): Team[] {
    return club?.teams || defaultValue
}

export function getClubLocation(club?: ClubModel, defaultValue: any = null) {
    const location = []
    location.push(club?.city?.name)
    location.push(club?.country?.name)
    const locationJoined = location.filter(i => !!i).join(', ')
    return locationJoined || defaultValue
}

export function getCurrentClubLocation(user?: UserModel, defaultValue: any = null) {
    return getClubLocation(getCurrentClub(user), defaultValue)
}

export function getCurrentClubCountry(user?: UserModel) {
    return user?.profile?.currentClub?.location?.country || user?.profile?.currentClub?.country
}

export function getCurrentClubTeams(user?: UserModel, defaultValue: any = []): Team[] {
    return user?.profile?.currentClubTeams || defaultValue
}

export function getCurrentClubTeamLevels(user?: UserModel, defaultValue: any = null): string {
    const teams = getCurrentClubTeams(user)
    if (!teams.length) {
        return defaultValue
    }

    if (teams.length === 1) {
        return `${teams?.[0]?.level?.id}`
    }

    let min = 100, max = 0
    teams.forEach(team => {
        if ((team?.level?.id || 0) < min) {
            min = team?.level?.id || 0
        }
        if ((team?.level?.id || 0) > max) {
            max = team?.level?.id || 0
        }
    })

    if (min === max) {
        return `${min}`
    }

    return `${min} - ${max}`
}

export function getSkillsForUser(user?: UserModel, defaultValue: any = []): SkillModel[] {
    const mainPosition = getUserMainPosition(user)
    if (!mainPosition) {
        return defaultValue
    }

    const skills = [
        {
            id: 1,
            name: 'hit_the_crossbar',
        },
        {
            id: 2,
            name: 'pass_and_shoot',
        },
        {
            id: 3,
            name: 'wall_passes',
        },
        {
            id: 4,
            name: 'short_and_long_pass'
        },
        {
            id: 5,
            name: 'juggling_and_first_touch'
        },
        {
            id: 6,
            name: 'first_touch'
        },
        {
            id: 7,
            name: 'speed_t_test'
        },
        {
            id: 8,
            name: 'jumping_header_and_volley'
        }
    ]

    switch (mainPosition) {
        case 'Goalkeeper':
            skills.push({
                id: 9,
                name: 'gk_skill_video'
            })
            skills.push({
                id: 10,
                name: 'gk_skill_video'
            })
            break

        case 'DefensiveMidfield':
        case 'LeftFullBack':
        case 'CentralDefender':
        case 'RightFullBack':
            skills.push({
                id: 11,
                name: '8_tackle_drill'
            })
            skills.push({
                id: 12,
                name: '1_on_1_battle'
            })
            break

        case 'LeftMidfield':
        case 'CentralMidfield':
        case 'RightMidfield':
            skills.push({
                id: 13,
                name: 'obstacle_course'
            })
            skills.push({
                id: 14,
                name: 'dribble_and_finish'
            })
            break

        case 'Forward':
        case 'LeftWing':
        case 'RightWing':
        case 'AttackingMidfield':
            skills.push({
                id: 15,
                name: '1_touch_finish'
            })
            skills.push({
                id: 16,
                name: '2_touch_finish'
            })
            break
    }

    return skills
}

export function getVideosForSkill(user?: UserModel, skill?: SkillModel, defaultValue: any = []): MediaModel[] {
    const profile = getProfile(user)

    return profile?.skillMedia?.filter(skillMedia => skillMedia.skill && skillMedia.skill.id === skill?.id)
        .filter(skillMedia => !skillMedia.deleted)
        .map(skillMedia => skillMedia.media) || defaultValue
}

export function getAllSkillVideosForUser(user?: UserModel, defaultValue: any = []): MediaModel[] {
    const skills = getSkillsForUser(user)
    if (!skills) {
        return defaultValue
    }

    return skills
        .map(skill => getVideosForSkill(user, skill))
        .filter(videos => videos && videos.length)
        .flat()
}

export function getUserStat(user?: UserModel, statName?: 'appearances' | 'assists' | 'cleanSheets' | 'goals' | 'goalsReceived' | 'redCards' | 'yellowCards', defaultValue: any = null): number {
    const profile = getProfile(user)
    const stats = profile?.stats
    return (statName && stats?.[statName]) || defaultValue
}

export function getPreviousClubs(user?: UserModel, defaultValue: any = []): PastClubModel[] {
    const profile = getProfile(user)
    return profile?.previousClubs || defaultValue
}

export function getClubFromPreviousClub(previousClub?: PastClubModel): ClubModel | undefined {
    return previousClub?.club || previousClub?.clubRequest
}

export function getPreviousClubName(previousClub?: PastClubModel, defaultValue: any = null) {
    const club = getClubFromPreviousClub(previousClub)
    return getClubName(club, defaultValue)
}

export function getPreviousClubLogo(previousClub?: PastClubModel, defaultValue: any = []) {
    const club = getClubFromPreviousClub(previousClub)
    return getClubLogo(club, defaultValue)
}

export function getHighlightVideos(user?: UserModel, defaultValue: any = []): MediaModel[] {
    const profile = getProfile(user)
    return profile?.highlightVideos?.filter(video => !video.deleted) || defaultValue
}

export function getUserHeight(user?: UserModel, defaultValue: any = null): number {
    const profile = getProfile(user)
    return profile?.height || defaultValue
}

export function getAgencyPosition(user?: UserModel, defaultValue?: string) {
    return user?.profile?.agencyPosition || defaultValue
}

export function getUserTransfermarktValue(user?: UserModel, defaultValue: any = null): number {
    const profile = getProfile(user)
    return profile?.transfermarktValue || defaultValue
}

export function getUserLanguages(user?: UserModel, defaultValue: any = []): LanguageModel[] {
    const profile = getProfile(user)
    return profile?.languages || defaultValue
}

export function getUserNationalities(user?: UserModel, defaultValue: any = []): CountryModel[] {
    const profile = getProfile(user)
    return profile?.nationalities || defaultValue
}

export function isUserClubAdmin(user?: UserModel, club?: ClubModel) {
    return user?.userType?.id === UserTypeId.ADMIN_TYPE_ID || !!(user && club && club?.clubAdmins?.find(admin => admin.user?.email?.toLowerCase()?.trim() === getEmail(user)?.toLowerCase()?.trim()))
}

export function isUserClubApprovedAdmin(user?: UserModel, club?: ClubModel): boolean {
    return user?.userType?.id === UserTypeId.ADMIN_TYPE_ID || !!club?.clubAdmins?.find(admin => admin.user?.email?.toLowerCase()?.trim() === getEmail(user)?.toLowerCase()?.trim())?.approved
}


export function isUserApprovedAndClubApproved(user?: UserModel, club?: ClubModel): boolean {
    return !club?.isRequest && isUserClubApprovedAdmin(user, club)
}

export function generateProfileURL(user?: UserModel): string {
    if (!user) {
        return process.env.REACT_APP_WEB_URL || ''
    }

    let userName = getFullName(user, '').split('\/').join(' ').split(' ').join('-')
    userName = encodeURI(userName)

    return (`${process.env.REACT_APP_WEB_URL}/player/${user.id}/${userName}`)
}

export function generateClubURLShort(club?: ClubModel): string {
    if (!club) {
        return ''
    }

    let clubName = getClubName(club, '').split('\/').join(' ').split(' ').join('-')
    clubName = encodeURI(clubName)

    return (`/admin/club/${club.id}/${clubName}`)
}

export function generateClubURL(club?: ClubModel): string {
    if (!club) {
        return process.env.REACT_APP_WEB_URL || ''
    }

    return (`${process.env.REACT_APP_WEB_URL}${generateClubURLShort(club)}}`)
}

export function isPhAdmin(user?: UserModel) {
    return user?.userType?.id === UserTypeId.ADMIN_TYPE_ID
}

export const getClubLocationGeo = (club?: ClubModel): latLngRaw | undefined => {
    if (!club) return

    const geocodeLocation = club?.googleGeolocation?.results?.[0]?.geometry?.location
    if (geocodeLocation?.lat && geocodeLocation?.lng) {
        return {
            lat: geocodeLocation.lat,
            lng: geocodeLocation.lng
        }
    }

    if (club.location?.location) {
        return {
            lat: club.location.location.latitude,
            lng: club.location.location.longitude
        }
    }

    if (club.city && club.city.latitude && club.city.longitude) {
        return {
            lat: club.city.latitude,
            lng: club.city.longitude
        }
    }
}

export const getClubLocationName = (club?: ClubModel): string => {
    if (!club) return ''

    if (club.location?.name) {
        return club.location.name || ''
    }

    if (club) {
        const location = []
        if (club.country) {
            location.push(club.country.name)
        }
        if (club.city) {
            location.push(club.city.name)
        }

        return location.join(', ')
    }
    return ''
}

export const isUserSubscriptionActive = (subscription?: UserSubscriptionModel) => {
    return subscription?.type === UserSubscriptionType.PREMIUM &&
        subscription.expirationDate &&
        isAfter(subscription.expirationDate, new Date()) || false
}

export const isPlayerPremium = (user?: UserModel) => {
    return isUserSubscriptionActive(user?.subscription)
}

export const isAgentPlayer = (user?: UserModel) => {
    return user?.loginType === LoginType.SUBUSER
}
export const isAgentPlayerViaParent = (user?: UserModel) => {
    return user?.parent?.userType?.id === UserTypeId.AGENT_TYPE_ID
}

export const getUserTypeName = (userTypeId?: number) => {
    let userTypeName = ''
    switch (userTypeId) {
        case UserTypeId.ADMIN_TYPE_ID:
            userTypeName = 'admin'
            break;
        case UserTypeId.REGULAR_TYPE_ID:
            userTypeName = 'player'
            break;
        case UserTypeId.STAFF_TYPE_ID:
            userTypeName = 'club'
            break;
        case UserTypeId.AGENT_TYPE_ID:
            userTypeName = 'agent'
            break;
        default:
            return 'unknown'
    }
    return userTypeName
}

export const isClubPlayerViaParent = (user?: UserModel) => {
    return user?.parent?.userType?.id === UserTypeId.STAFF_TYPE_ID
}

export const getUserTypeIcon = (user?: UserModel) => {
    const isRegularUser = user?.userType?.id === UserTypeId.REGULAR_TYPE_ID && user?.loginType !== LoginType.SUBUSER
    const isAgent = user?.userType?.id === UserTypeId.AGENT_TYPE_ID
    const isClubAdmin = user?.userType?.id === UserTypeId.STAFF_TYPE_ID
    const isAgentsPlayer = user?.userType?.id === UserTypeId.REGULAR_TYPE_ID && user?.loginType === LoginType.SUBUSER
    let userTypeIcon;
    if (isRegularUser) {
        userTypeIcon = require('../assets/images/user-type-player-regular.svg').default
    } else if (isAgent) {
        userTypeIcon = require('../assets/images/user-type-agent.svg').default
    } else if (isClubAdmin) {
        userTypeIcon = require('../assets/images/user-type-player-club-admin.svg').default
    } else if (isAgentsPlayer) {
        userTypeIcon = require('../assets/images/user-type-player-agent.svg').default
    }

    return userTypeIcon
}

export const getProfileSalaryLocalized = (profile?: UserProfile, defaultValue?: string) => {
    if (!profile) return defaultValue

    let salaryOption = ''
    if (!profile.toSalary && !profile.fromSalary) {
        salaryOption = '-'
    } else if (!profile.fromSalary && profile.toSalary === 2_000) {
        salaryOption = '0 - 2.000€'
    } else if (profile.fromSalary === 2_000 && profile.toSalary === 5_000) {
        salaryOption = '2.000 - 5.000€'
    } else if (profile.fromSalary === 5_000 && profile.toSalary === 10_000) {
        salaryOption = '5.000 - 10.000€'
    } else if (profile.fromSalary === 10_000 && profile.toSalary === 25_000) {
        salaryOption = '10.000 - 25.000€'
    } else if (profile.fromSalary === 25_000 && profile.toSalary === 50_000) {
        salaryOption = '25.000 - 50.000€'
    } else if (profile.fromSalary === 50_000 && profile.toSalary === 100_000) {
        salaryOption = '50.000 - 100.000€'
    } else if (profile.fromSalary === 100_000 && !profile.toSalary) {
        salaryOption = '100.000€+'
    } else {
        salaryOption = 'Custom'
    }
    return salaryOption
};

export const getProfileTransferFeeLocalized = (profile?: UserProfile, defaultValue?: string) => {
    if (!profile) return defaultValue

    let transferFeeLocalized = '-'
    if (!profile.transferFee) {
        transferFeeLocalized = '-'
    } else if (profile.transferFee === -1) {
        transferFeeLocalized = 'Free agent'
    } else if (profile.transferFee === 100_000) {
        transferFeeLocalized = 'up to 100.000€'
    } else if (profile.transferFee === 200_000) {
        transferFeeLocalized = 'up to 200.000€'
    } else if (profile.transferFee === 300_000) {
        transferFeeLocalized = 'up to 300.000€'
    } else if (profile.transferFee === 400_000) {
        transferFeeLocalized = 'up to 400.000€'
    } else if (profile.transferFee === 500_000) {
        transferFeeLocalized = 'up to 500.000€'
    } else if (profile.transferFee === 750_000) {
        transferFeeLocalized = 'up to 750.000€'
    } else if (profile.transferFee === 1_000_000) {
        transferFeeLocalized = 'up to 1.000.000€'
    } else if (profile.transferFee === 2_000_000) {
        transferFeeLocalized = '1.000.000€ or more'
    } else {
        transferFeeLocalized = 'Custom'
    }

    return transferFeeLocalized
};
