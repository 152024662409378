import React from "react";
import UserModel from "../../models/user.model";
import {useTranslation} from "react-i18next";
import {isAgentPlayerViaParent, isClubPlayerViaParent} from "../../util/profile.util";
import {Box} from "@material-ui/core";

interface Props {
    user?: UserModel,
    showForRegular?: boolean
}

const styles = {
    header: {
        marginTop: '10px',
    },
    premiumIcon: {
        width: '15px',
        height: '15px'
    },
    premiumText: {
        fontSize: '10px',
        marginLeft: 5
    },
}

const PlayerType = ({user, showForRegular}: Props) => {

    let playerTypeUI = null;
    const t = useTranslation().t

    if (isAgentPlayerViaParent(user)) {
        playerTypeUI = (
            <Box
                display={'flex'}
                alignItems={'center'}
                marginTop={1}
                marginBottom={1}
            >
                <img
                    alt={''}
                    src={require('../../assets/images/user-type-agent.svg').default}
                    style={styles.premiumIcon}
                />

                <div style={styles.premiumText}>{t("Agent's player")}</div>
            </Box>
        )
    } else if (isClubPlayerViaParent(user)) {
        playerTypeUI = (
            <Box
                display={'flex'}
                alignItems={'center'}
                marginTop={1}
                marginBottom={1}
            >
                <img
                    alt={''}
                    src={require('../../assets/images/user-type-player-club-admin.svg').default}
                    style={styles.premiumIcon}
                />

                <div style={styles.premiumText}>{t("Club's player")}</div>
            </Box>
        )
    } else if (showForRegular) {
        playerTypeUI = (
            <Box
                display={'flex'}
                alignItems={'center'}
                marginTop={1}
                marginBottom={1}
            >
                <img
                    alt={''}
                    src={require('../../assets/images/user-type-player-regular.svg').default}
                    style={styles.premiumIcon}
                />

                <div style={styles.premiumText}>{t("Regular player")}</div>
            </Box>
        )
    }

    return playerTypeUI
}

export default PlayerType

