import React, {useEffect, useState} from 'react'
import {Box, Checkbox, FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from "react-i18next";
import Job from "../../../../models/job.model";
import clsx from "clsx";
import _ from "lodash";
import PreferredFootSelector from "../PreferredFootSelector.component";
import {SeasonModel} from "../../../../models/season.model";
import CommonService from "../../../../services/common.service";
import {getOpportunitySalaryLocalized, getOpportunityTransferFeeLocalized} from "../../../../util/opportunity.util";
import ETextField from "../../../common/ETextField.component";

interface Props {
    job?: Job,
    setJob: (job: Job) => void,
}

const useStyle = makeStyles(theme => ({
    sectionTitle: {
        marginBottom: theme.spacing(1)
    },
    sectionData: {
        marginBottom: theme.spacing(4),
    },
    input: {
        width: '100%'
    },
    timeline: {
        // transform: "rotate(90deg)",
        // width: 100,
        height: '100%'
    },
    timelineContentContainer: {
        // textAlign: "left",
        flex: 1
    },
    timelineContent: {
        // display: "inline-block",
        // transform: "rotate(-90deg)",
        // textAlign: "center",
        minWidth: 100
    },
    timelineIcon: {
        // transform: "rotate(-90deg)",
        width: 20,
        height: 20
    },
    team: {
        background: '#FAFAFA',
        borderRadius: 16,
        marginTop: 5,
        marginBottom: 5,
        marginRight: 10,
        border: '2px solid transparent',
        cursor: 'pointer',
        padding: 10,
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.57)'
    },
    teamSelected: {
        background: theme.palette.primary.main,
        color: 'white'
    },
    slider: {
        marginLeft: 2,
        width: '100%',
        maxWidth: '100%'
    },
    sliderMark: {
        width: 24,
        textAlign: 'center',
        fontSize: 12
    },
    sliderMarkLeft: {
        marginRight: 20
    },
    sliderMarkRight: {
        marginLeft: 20
    },
    sliderTop: {
        width: '100% important!'
    },
    option: {
        width: 95,
        height: 50,
        cursor: 'pointer',
        borderBottom: '2px solid #FBFBFB',
        color: 'rgba(0, 0, 0, 0.61)',
        fontSize: '14px',
        background: '#FBFBFB',
        textAlign: 'center'
    },
    genderImg: {
        width: 14,
        height: 14
    },
    selected: {
        //background: linear-gradient(169deg, #e40015 0%, #750075 100%);
        borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    sliderTeamMark: {
        backgroundColor: theme.palette.primary.main,
        width: 3,
        height: 13,
        marginTop: -5,
        marginLeft: -1,
        borderRadius: 2
    },
    radioLabel: {
        fontSize: '14px !important'
    },
    selectLocationCTA: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginTop: 20,
        zIndex: 1
    }
}))

const ImportantToKnow = ({job, setJob}: Props) => {

    const [customInputState, setCustomInputState] = useState(false)
    const [customTransferFeeInputState, setCustomTransferFeeInputState] = useState(false)
    const classes = useStyle()
    const {t} = useTranslation()

    useEffect(() => {
        if (job) {
            setCustomInputState(getOpportunitySalaryLocalized(job) === 'Custom')
            setCustomTransferFeeInputState(getOpportunityTransferFeeLocalized(job) === 'Custom')
        }
    }, [job?.id])

    const updateFields = (data: { field: string, value: any }[]) => {
        const newJob: any = _.cloneDeep(job)
        data.forEach(d => newJob[d.field] = d.value)
        setJob(newJob)
    }

    const updateField = (field: string, value: any) => {
        updateFields([{field, value}])
    }


    // ***** Handlers ***** //

    const salaryHandler = (newFrom?: number, newTo?: number) => {
        updateFields([
            {field: 'fromSalary', value: newFrom},
            {field: 'toSalary', value: newTo},
        ])
    };

    const preferredFootHandler = (preferredFoot: string | null) => {
        updateField('preferredFoot', preferredFoot)
    };

    //endregion Handlers

    //region UI

    const preferredFootUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>{t("Preferred foot")}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <PreferredFootSelector
                        preferredFoot={job?.preferredFoot}
                        onPreferredFootSelected={preferredFootHandler}
                    />
                </Grid>
            </Grid>
        </Grid>
    )

    const getSeasonName = (season: SeasonModel) => {
        let yearString = `${season.year}`;
        if (season.period === 'WINTER') {
            yearString += `/${(season.year! + 1) % 100}`
        }
        return t(`transfer_period_${season?.period?.toLowerCase()}`) + ` ${yearString}`;
    };

    const transferPeriodUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>{t("transfer_period")}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box
                        display={'flex'}
                    >
                        {
                            CommonService.getTransferPeriods().map(season => (
                                <Box
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    key={season.id}
                                    className={clsx(classes.option, job?.transferPeriod && job?.transferPeriod.id === season.id ? classes.selected : '')}
                                    onClick={() => updateField('transferPeriod', season)}
                                    style={{width: 180}}
                                >
                                    <p>{getSeasonName(season)}</p>
                                </Box>
                            ))
                        }
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )

    const salaryUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>Salary range</Typography>
                </Grid>
                <Grid item xs={8}>
                    <RadioGroup
                        aria-label="salary" name="gender1"
                        value={getOpportunitySalaryLocalized(job)}
                        onChange={e => {
                            const newValue = e.target.value
                            switch (newValue) {
                                case '-':
                                    setCustomInputState(false)
                                    salaryHandler(0, 0)
                                    break
                                case '0 - 2.000€':
                                    setCustomInputState(false)
                                    salaryHandler(0, 2_000)
                                    break
                                case '2.000 - 5.000€':
                                    setCustomInputState(false)
                                    salaryHandler(2_000, 5_000)
                                    break
                                case '5.000 - 10.000€':
                                    setCustomInputState(false)
                                    salaryHandler(5_000, 10_000)
                                    break
                                case '10.000 - 25.000€':
                                    setCustomInputState(false)
                                    salaryHandler(10_000, 25_000)
                                    break
                                case '25.000 - 50.000€':
                                    setCustomInputState(false)
                                    salaryHandler(25_000, 50_000)
                                    break
                                case '50.000 - 100.000€':
                                    setCustomInputState(false)
                                    salaryHandler(50_000, 100_000)
                                    break
                                case '100.000€+':
                                    setCustomInputState(false)
                                    salaryHandler(100_000, 0)
                                    break
                                case 'Custom':
                                    setCustomInputState(true)
                                    salaryHandler(undefined, undefined)
                                    break
                            }
                        }}
                    >
                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'-'}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="Salary is not public"
                        />

                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'0 - 2.000€'}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="0 - 2.000€"
                        />

                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'2.000 - 5.000€'}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="2.000€ - 5.000€"
                        />

                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'5.000 - 10.000€'}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="5.000€ - 10.000€"
                        />

                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'10.000 - 25.000€'}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="10.000€ - 25.000€"
                        />

                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'25.000 - 50.000€'}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="20.000€ - 50.000€"
                        />

                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'50.000 - 100.000€'}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="50.000€ - 100.000€"
                        />

                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'100.000€+'}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="100.000€ or more"
                        />

                        <FormControlLabel
                            classes={{label: classes.radioLabel}}
                            value={'Custom'}
                            checked={customInputState}
                            control={<Radio color={'primary'} size={'small'}/>}
                            label="Custom"
                        />

                        {
                            customInputState &&
                            <Grid container spacing={2}>
                                <ETextField
                                    grid={'1/2'}
                                    value={job?.fromSalary || ''}
                                    onChange={e => updateField('fromSalary', e !== '' ? parseInt(e.replace(/[^0-9]/g, '')) : undefined)}
                                />
                                <ETextField
                                    grid={'1/2'}
                                    value={job?.toSalary || ''}
                                    onChange={e => updateField('toSalary', e !== '' ? parseInt(e.replace(/[^0-9]/g, '')) : undefined)}
                                />
                            </Grid>
                        }

                    </RadioGroup>
                </Grid>
            </Grid>
        </Grid>
    )

    const transferFee = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>Transfer fee</Typography>
                </Grid>
                <Grid item xs={8}>
                    <RadioGroup
                        aria-label="salary" name="gender1"
                        value={job?.transferFee || 0}
                        onChange={e => {
                            if (e.target.value === 'custom') {
                                setCustomTransferFeeInputState(true)
                            } else {
                                setCustomTransferFeeInputState(false)
                                const newValue = e.target.value !== undefined ? +e.target.value : undefined
                                updateField('transferFee', newValue)
                            }
                        }}
                    >
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={0}
                            control={<Radio color={'primary'} size={'small'}/>} label="Don't disclose"/>
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={-1} control={<Radio color={'primary'} size={'small'}/>}
                            label="Free agent"/>
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={100_000} control={<Radio color={'primary'} size={'small'}/>}
                            label="up to 100.000€"/>
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={200_000} control={<Radio color={'primary'} size={'small'}/>}
                            label="up to 200.000€"/>
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={300_000} control={<Radio color={'primary'} size={'small'}/>}
                            label="up to 300.000€"/>
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={400_000} control={<Radio color={'primary'} size={'small'}/>}
                            label="up to 400.000€"/>
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={500_000} control={<Radio color={'primary'} size={'small'}/>}
                            label="up to 500.000€"/>
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={750_000} control={<Radio color={'primary'} size={'small'}/>}
                            label="up to 750.000€"/>
                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={1_000_000} control={<Radio color={'primary'} size={'small'}/>}
                            label="up to 1.000.000€"/>

                        <FormControlLabel
                            classes={{
                                label: classes.radioLabel
                            }}
                            value={'custom'} control={<Radio color={'primary'} checked={customTransferFeeInputState} size={'small'}/>}
                            label="Custom"/>

                        {
                            customTransferFeeInputState &&
                            <Grid container spacing={2}>
                                <ETextField
                                    grid={'1/1'}
                                    value={job?.transferFee || ''}
                                    onChange={e => updateField('transferFee', e !== '' ? parseInt(e.replace(/[^0-9]/g, '')) : undefined)}
                                />
                            </Grid>
                        }


                    </RadioGroup>
                </Grid>
            </Grid>
        </Grid>
    )

    const specialRequirementsUI = (
        <Grid item xs={12}>
            <Grid container alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography>Special notes</Typography>
                </Grid>
                <Grid item xs={8}>
                    <FormControlLabel
                        classes={{
                            label: classes.radioLabel
                        }}
                        value={'private'}
                        control={<Checkbox
                            checked={!!job?.requirements?.find(r => r.id === 1)}
                            onChange={e => {
                                if (e.target.checked) {
                                    const newRequirements = [...(job?.requirements || [])]
                                    newRequirements.push({id: 1})
                                    updateField('requirements', newRequirements)
                                } else {
                                    const newRequirements = job?.requirements?.filter(r => r.id !== 1)
                                    updateField('requirements', newRequirements)
                                }
                            }}
                            color={'primary'}
                            size={'small'}/>}
                        label={t('Player must be available for trial')}
                    />
                    <FormControlLabel
                        classes={{
                            label: classes.radioLabel
                        }}
                        value={'private'}
                        control={<Checkbox
                            checked={!!job?.requirements?.find(r => r.id === 2)}
                            onChange={e => {
                                if (e.target.checked) {
                                    const newRequirements = [...(job?.requirements || [])]
                                    newRequirements.push({id: 2})
                                    updateField('requirements', newRequirements)
                                } else {
                                    const newRequirements = job?.requirements?.filter(r => r.id !== 2)
                                    updateField('requirements', newRequirements)
                                }
                            }}
                            color={'primary'}
                            size={'small'}/>}
                        label={t('Club is open for loan')}
                    />
                </Grid>
            </Grid>
        </Grid>
    )


    const importantLeftUI = (
        <Grid item xs={6}>

            <Grid container spacing={4}>
                {transferFee}
                {specialRequirementsUI}
            </Grid>

        </Grid>
    )

    const importantRightUI = (
        <Grid item xs={6}>

            <Grid container spacing={4}>
                {salaryUI}
                {transferPeriodUI}
                {preferredFootUI}
            </Grid>

        </Grid>
    )


    return (
        <Grid container spacing={4}>
            {importantLeftUI}
            {importantRightUI}
        </Grid>
    )

    //endregion UI
}

export default ImportantToKnow
