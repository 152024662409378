import {
    AUTH_IN_PROGRESS,
    LOGOUT,
    SET_AGENT_PROFILE,
    SET_AUTH_ERROR,
    SET_LOGIN_TYPE,
    SET_REGISTRATION_BASIC_INFO,
    SET_TOKEN_VALIDATION_DONE,
    SET_USER
} from "./actionTypes";
import {isPhAdmin} from "../../util/profile.util";
import {LoginType} from "../../models/loginType.model";
import UserModel, {AgencyPosition, UserTypeId} from "../../models/user.model";
import ClubModel from "../../models/club.model";
import AuthService from "../../services/auth.service";
import ProfileService from "../../services/profile.service";
import {AuthError} from "../reducers/auth.reducer";
import IntercomService from "../../services/intercom.service";

export const authInProgress = (authInProgress: boolean) => {
    return {
        type: AUTH_IN_PROGRESS,
        payload: authInProgress
    }
};

export const setLoginType = (loginType: LoginType) => {
    return {
        type: SET_LOGIN_TYPE,
        payload: loginType
    }
};

export const setAuthError = (authError?: AuthError) => {
    return {
        type: SET_AUTH_ERROR,
        payload: authError
    }
};

export const handlePostAuth = async (dispatch: any, user: UserModel, history: any, keepSameRoute: boolean = false) => {
    // dispatch({type: SET_USER, payload: user});
    // dispatch({type: SET_ONBOARDING_FIRST_NAME, payload: getFirstName(user)});
    // dispatch({type: SET_ONBOARDING_LAST_NAME, payload: getLastName(user)});
    // dispatch({type: SET_ONBOARDING_GENDER, payload: getGender(user, 'MALE')});

    switch (user.userType?.id) {
        case UserTypeId.REGULAR_TYPE_ID: {
            dispatch(setAuthError(AuthError.USER_NOT_CLUB))
            break
        }
        case UserTypeId.AGENT_TYPE_ID: {
            dispatch(setAuthError(AuthError.USER_NOT_CLUB))
            break
        }
        case UserTypeId.STAFF_TYPE_ID:
        case UserTypeId.ADMIN_TYPE_ID: {
            dispatch({type: SET_USER, payload: user});
            dispatch({type: SET_AGENT_PROFILE, payload: user});
            if (!keepSameRoute) {
                history.replace(isPhAdmin(user) ? '/admin/job' : '/club/dashboard')
            } else {
                const path = history.location.pathname
                console.log(path)
                if (path === '/') {
                    history.replace(isPhAdmin(user) ? '/admin/job' : '/club/dashboard')
                }
            }
            break
        }
    }

    dispatch(authInProgress(false));
}

export const initEmailAuth = (
    email: string,
    password: string,
    isRegistration: boolean,
    firstName?: string,
    lastName?: string,
    userTypeId?: UserTypeId,
    history?: any
) => {
    return async (dispatch: any) => {

        dispatch(setLoginType(LoginType.EMAIL));
        dispatch(authInProgress(true));
        dispatch(setAuthError())

        try {
            let user;
            if (isRegistration) {
                user = await AuthService.emailUserRegistration(email, password, firstName || '', lastName || '', userTypeId!)
            } else {
                user = await AuthService.emailUserLogin(email, password)
            }
            await handlePostAuth(dispatch, user, history)

            return user
        } catch (e: any) {
            console.log(e);
            dispatch(authInProgress(false));
            dispatch(setAuthError(AuthError.GENERIC))
        }
    }
};

export const initSocialAuth = (
    loginType: LoginType,
    userTypeId?: UserTypeId,
    clubId?: number,
    clubRequest?: ClubModel,
    firstName?: string,
    lastName?: string,
    phone?: string,
    agencyPosition?: AgencyPosition,
    history?: any,
    createGenericAccount?: boolean
) => {
    return async (dispatch: any) => {

        dispatch(setLoginType(loginType));
        dispatch(authInProgress(true));
        dispatch(setAuthError())

        try {
            const user = await AuthService.socialLogin(loginType, userTypeId, clubId, clubRequest, firstName, lastName, phone, agencyPosition, createGenericAccount);
            await handlePostAuth(dispatch, user, history)
            return user
        } catch (e) {
            console.log(e);
            dispatch(authInProgress(false));
            dispatch(setAuthError(AuthError.GENERIC))
        }
    }
};

export const initRegisterClub = (club: ClubModel, firstName: string, lastName: string, phone: string, email: string, password: string, agencyPosition?: AgencyPosition, history?: any) => {
    return async (dispatch: any) => {

        dispatch(setLoginType(LoginType.EMAIL));
        dispatch(authInProgress(true));
        dispatch(setAuthError())

        try {
            const user = await AuthService.emailClubRegistration(firstName, lastName, '', phone, email, password, 'STAFF', club, agencyPosition);

            handlePostAuth(dispatch, user, history)
            dispatch(authInProgress(false));

            return user
        } catch (e: any) {
            console.log(e);
            dispatch(authInProgress(false));
            dispatch(setAuthError(AuthError.GENERIC))
        }

    }
};

export const initTokenAuth = (history: any) => {
    return async (dispatch: any) => {

        dispatch(setLoginType(LoginType.TOKEN));
        dispatch(authInProgress(true));
        dispatch(setAuthError())

        try {
            const user = await AuthService.tokenLogin();
            await dispatch({type: SET_TOKEN_VALIDATION_DONE});
            if (user) {
                await handlePostAuth(dispatch, user, history, true)
            }
            return user
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(authInProgress(false));
        }
        await dispatch({type: SET_TOKEN_VALIDATION_DONE});
    }
};

export const initUpdatePassword = (history: any, resetToken: string, newPassword: string) => {
    return async (dispatch: any) => {

        dispatch(authInProgress(true));

        try {
            const user = await AuthService.updatePassword(resetToken, newPassword);
            if (user) {
                await handlePostAuth(dispatch, user, history)
            } else {
                dispatch(setAuthError(AuthError.PASSWORD_RESET_GENERIC))
            }
            return user
        } catch (e) {
            console.log(e);
            dispatch(setAuthError(AuthError.PASSWORD_RESET_GENERIC))
        } finally {
            dispatch(authInProgress(false));
        }
    }
};


export const initLogout = () => {
    return async (dispatch: any) => {
        await AuthService.logout();
        dispatch({type: LOGOUT})
    };
};

export const setRegistrationBasicInfo = (
    registrationFirstName?: string,
    registrationLastName?: string,
    registrationPhone?: string
) => {
    return {
        type: SET_REGISTRATION_BASIC_INFO,
        payload: {
            firstName: registrationFirstName,
            lastName: registrationLastName,
            phone: registrationPhone
        }
    }
}

export const initUpdateProfile = (user: UserModel, firstName: string, lastName: string, phone: string) => {
    return async (dispatch: any) => {
        const response = await ProfileService.updateProfile({
            ...user.profile,
            firstName,
            lastName,
            contactInfo: {
                ...user.profile?.contactInfo,
                phone
            }
        })

        if (response) {
            const user = await ProfileService.getMe()
            dispatch({
                type: SET_USER,
                payload: user
            })

            return user
        }
    }
}

export const initReloadProfile = () => {
    return async (dispatch: any) => {

        try {
            const user = await ProfileService.getMe();
            dispatch({type: SET_USER, payload: user})
        } catch (e) {
            console.log(e);
        } finally {
        }
    }
};
