export enum EventProperty {
    SCREEN_NAME = 'screen_name',
    LANGUAGE = 'language',
    SHARED_OPPORTUNITIES = 'shared_opportunities',
    SIGN_UP_TRIGGERED = 'sign_up_triggered',
    SIGN_UP_TYPE = 'sign_up_type',
    SIGN_UP_DATE = 'sign_up_date',
    SIGN_IN_TYPE = 'sign_in_type',
    SIGN_IN_DATE = 'sign_in_date',
    GENDER = 'gender',
    NATIONALITY = 'nationality',
    BIRTH_DATE = 'birth_date',
    BIRTH_YEAR = 'birth_year',
    PRIMARY_POSITION = 'primary_position',
    SECONDARY_POSITION = 'secondary_position',
    SECONDARY_POSITION_2 = 'secondary_position_2',
    CURRENT_CLUB = 'current_club',
    CURRENT_TEAM = 'current_team',
    STARTING_DATE = 'starting_date',
    STATS = 'stats',
    PAST_EXPERIENCE = 'past_experience',
    PROFILE_PICTURE_SET = 'profile_picture_set',
    FOOT = 'foot',
    HEIGHT = 'height',
    VIDEO_UPLOAD_STARTED = 'video_upload_started',
    VIDEO_UPLOAD_FINISHED = 'video_upload_finished',
    SHOOTING_VIDEOS = 'shooting_videos',
    PASSING_VIDEOS = 'passing_videos',
    TECHNICAL_SKILL_VIDEOS = 'technical_skill_videos',
    PHYSICAL_ATTRIBUTES_VIDEOS = 'physical_attributes_videos',
    ATTACKER_VIDEOS = 'attacker_videos',
    MIDFIELD_VIDEOS = 'midfield_videos',
    DEFENSIVE_VIDEOS = 'defensive_videos',
    GOAL_KEEPING_VIDEOS = 'goal_keeping_videos',
    VIDEO_DELETED = 'video_deleted',
    MATCH_SETTINGS_CHANGED = 'match_settings_changed',
    NOT_SUCCESSFUL_APPLICATIONS = 'not_successful_applications',
    NUMBER_OF_TOP_PICKS = 'number_of_top_picks',
    NUMBER_OF_INVITES = 'number_of_invites',
    CHANGED_CLUB = 'changed_club',
    NOT_CHANGED_CLUB_NOT_CONTACTED = 'not_changed_club_- not_contacted',
    NOT_CHANGED_CLUB_TRIAL_BUT_NO_ACCEPTED = 'not_changed_club_- trial_but_not_accepted',
    NOT_CHANGED_CLUB_OTHER = 'not_changed_club_- other',
    PLAYER_CARD_UPDATES = 'player_card_updates',
    CHANGE = 'change',
    PHONE = '$phone',
    SELECTED_CITY = 'selected_city',
    SELECTED_COUNTRY = 'selected_country',
    SUCCESS = "success",
    PLAYERCARD_SHARE = 'playercard_share',
    CLUB_ID = 'club_id',
    CLUB_NAME = 'club_name',
    OPPORTUNITY_ID = 'opportunity_id',
    OPPORTUNITY_ANONYMOUS = 'opportunity_anonymous',
    OPPORTUNITY_LEVEL_MIN = 'opportunity level min',
    OPPORTUNITY_LEVEL_MAX = 'opportunity level max',
    OPPORTUNITY_CITY = 'opportunity_city',
    OPPORTUNITY_COUNTRY = 'opportunity_country',
    OPPORTUNITY_FOOT = 'opportunity_foot',
    OPPORTUNITY_GENDER = 'opportunity_gender',
    OPPORTUNITY_LANGUAGE = 'opportunity_language',
    OPPORTUNITY_LEVEL = 'opportunity_level',
    OPPORTUNITY_MIN_AGE = 'opportunity_min_age',
    OPPORTUNITY_MAX_AGE = 'opportunity_max_age',
    OPPORTUNITY_MIN_LEVEL = 'opportunity_min_level',
    OPPORTUNITY_MAX_LEVEL = 'opportunity_max_level',
    OPPORTUNITY_NATIONALITY = 'opportunity_nationality',
    OPPORTUNITY_PAID = 'opportunity_paid',
    OPPORTUNITY_POSITION = 'opportunity_position',
    OPPORTUNITY_SKILL_VIDEO = 'opportunity_skill_video',
    OPPORTUNITY_TEAM = 'opportunity_team',
    OPPORTUNITY_TRANSFER_PERIOD = 'opportunity_transfer_period',
    OPPORTUNITY_TYPE = 'opportunity_type',
    ASSISTS = 'assists',
    CLEAN_SHEET = 'clean_sheet',
    GOALS = 'goals',
    GOALS_CONCEDED = 'goals_conceded',
    MATCHES = 'matches',
    RED_CARDS = 'red_cards',
    YELLOW_CARDS = 'yellow_cards',
    NOTIFICATION_TURN_ON_OFF = "turn_on-off",
    AGE_MAX = 'age max',
    AGE_MIN = 'age min',
    TEAM = 'team',
    SALARY = 'salary',
    ANONYMOUS = 'anonymous',
    PREFERRED_FOOT = 'preferred foot',
    MIN_HEIGHT = 'min height',
    SKILL_VIDEO_REQUIRED = 'skill video required',
    TRANSFER_PERIOD = 'transfer period',
    CLUB_IS_REQUEST = 'club is request',
    TEAM_LEVEL = 'team level',
    RADIUS = 'radius',
    TYPE = 'type',
    PLAYER_NAME = "player name",
    PLAYER_NATIONALITY = "player nationality",
    PLAYER_CITY = "player city",
    PLAYER_LEVEL = "player level",
    PLAYER_HEIGHT = "player height",
    PLAYER_GENDER = "player gender",
    PLAYER_GOALS = "player goals",
    PLAYER_ASSISTS = "player assists",
    PLAYER_YELLOW_CARDS = "player yellow cards",
    PLAYER_RED_CARDS = "player red cards",
    PLAYER_CLEAN_SHEETS = "player clean sheets",
    PLAYER_GOALS_RECEIVED = "player goals received",
    PLAYER_CURRENT_CLUB = "player current club",
    PLAYER_CURRENT_TEAM = "player current team",
    PLAYER_VIDEO = "player video",
    PLAYER_AGE = "player age",
    PLAYER_FOOT = "player foot",
    PLAYER_MATCHES = "player matches",
    PLAYER_POSITION = "player position",
    PLAYER_COUNTRY = "player country",
    CONTACTED = "contacted",
    APPLICANT_ID = "applicant id",
    CONTACT_CHANNEL = "contact channel",
    TRIGGER = 'trigger',
    USER_TYPE = 'user_type',
    PLAYER_ID = 'player_id',
    APPLICANT_USER_ID = 'applicant_user_id',
    MIN_GOALS = 'min_goals',
    MIN_ASSISTS = 'min_assists',
    MIN_MINUTES_PERCENTAGE = 'min_minutes_percentage',
    TRANSFER_FEE = 'transfer_fee',
    MUST_BE_AVAILABLE_FOR_TRIAL = 'must_be_available_for_trial',
    CLUB_OPEN_FOR_LOAN = 'club_open_for_loan',
    PH_INDEX = 'ph_index',
    INSTAT_INDEX = 'instat_index',

    // ***** B2C ***** //

    B2C_TRIGGER_REASON = 'b2c_trigger_reason',    // enum;
    // "Go Premium AdminHeader", "Go Premium Profile", "After Application Modal", "Blurred",
    // "Go Premium Card", "Reject Popup", "Apply Card", "Premium Card", "Special Offer Card", "Onboarding Finish"

    B2C_PACKAGE_ID = 'b2c_package_id',          // string;  SKU Id from the app store
    B2C_PACKAGE_MONTHS = 'b2c_package_months',  // number;  Number of months, 1, 6, 12
    B2C_PROMO_CODE = 'b2c_promo_code',          // string;  Promo code user enters
    B2C_GROUP = 'b2c_group',
    B2C_PACKAGE_PRICE = 'b2c_package_price',   // String: 12.99 EUR

    // ***** Ads ***** //

    ADS_PARTNER_ID = 'ads_partner_id',
    ADS_LINK = 'ads_link'
}
