import React, {FunctionComponent} from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from './Popup.module.scss';
import {useTranslation} from "react-i18next";
import EButton from "../EButton";
import {Box} from "@material-ui/core";

interface Props {
    open: boolean,
    onClose: () => void,
    onConfirm?: () => void,
    confirmText?: string,
    cancelText?: string,
    text: string,
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const Popup: FunctionComponent<Props> = (props: Props) => {
    const t = useTranslation().t;

    return (
        <Dialog aria-labelledby="simple-dialog-title" maxWidth={"xs"} fullWidth={true} {...props}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                className={styles.alertContainer}
            >
                <Box className={styles.alertText}>
                    <div>{t(props.text)}</div>
                </Box>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'flex-end'}
                    className={styles.buttonContainer}>
                    <EButton color="primary" onClick={props.onClose}>
                        {props.cancelText || t("close")}
                    </EButton>
                    {
                        props.onConfirm &&
                        <EButton color="primary" onClick={props.onConfirm}>
                            {props.confirmText || t("confirm")}
                        </EButton>
                    }
                </Box>
            </Box>
        </Dialog>
    )
};

export default Popup;
