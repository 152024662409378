import React, {useEffect, useState} from 'react'
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {isBefore, parseISO} from "date-fns";
import {formatTimeDiff} from "../../util/text.util";

const useStyle = makeStyles(theme => ({
    countdownHolder: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    countdownLabelTop: {
        fontSize: 20,
        // color: 'white'
    },
    countdownLabelBottom: {
        fontSize: 14,
        // color: 'white'
    },
    countdownTimer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '15px 0'
    },
    countdownTimerSectionHolder: {
        margin: '0 5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    countdownTimerDigisHolder: {
        display: 'flex',
        flexDirection: 'row'
    },
    countdownTimerDigitHolder: {
        backgroundColor: theme.palette.primary.main,
        width: 50,
        color: 'white',
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 6,
        margin: '0 1px'
    },
    countdownTimerDigitText: {
        fontSize: 32
    },
    countdownTimerPeriod: {
        fontSize: 14,
        // color: 'white'
    },
    [theme.breakpoints.down('md')]: {
        countdownLabelTop: {
            fontSize: 14,
        },
        countdownTimer: {
            margin: '6px 0'
        },
        countdownTimerDigitHolder: {
            width: 30,
            height: 30,
        },
        countdownTimerDigitText: {
            fontSize: 16
        },
    },
}))

const Countdown = () => {

    const [timer, setTimer] = useState(0);
    const proJobsLaunch = parseISO('2021-12-01T12:00:00')
    const showTimer = isBefore(new Date(), proJobsLaunch)

    useEffect(() => {
        if (!showTimer) return;

        const id = setTimeout(() => {
            setTimer(timer + 1);
        }, 500);

        return () => clearTimeout(id);
    }, [timer]);

    const classes = useStyle()
    const t = useTranslation().t

    if (!showTimer) return null;

    const timeDiff = formatTimeDiff(proJobsLaunch, new Date())

    return (
        <Box className={classes.countdownHolder}>
            <div className={classes.countdownLabelTop}>{t('Countdown header top')}</div>
            <Box className={classes.countdownTimer}>

                <Box className={classes.countdownTimerSectionHolder}>
                    <Box className={classes.countdownTimerDigisHolder}>
                        <Box className={classes.countdownTimerDigitHolder}>
                            <div className={classes.countdownTimerDigitText}>{timeDiff.charAt(0)}</div>
                        </Box>
                        <Box className={classes.countdownTimerDigitHolder}>
                            <div className={classes.countdownTimerDigitText}>{timeDiff.charAt(1)}</div>
                        </Box>
                    </Box>
                    <div className={classes.countdownTimerPeriod}>{t('days')}</div>
                </Box>


                <Box className={classes.countdownTimerSectionHolder}>
                    <Box className={classes.countdownTimerDigisHolder}>
                        <Box className={classes.countdownTimerDigitHolder}>
                            <div className={classes.countdownTimerDigitText}>{timeDiff.charAt(4)}</div>
                        </Box>
                        <Box className={classes.countdownTimerDigitHolder}>
                            <div className={classes.countdownTimerDigitText}>{timeDiff.charAt(5)}</div>
                        </Box>
                    </Box>
                    <div className={classes.countdownTimerPeriod}>{t('hours')}</div>
                </Box>


                <Box className={classes.countdownTimerSectionHolder}>
                    <Box className={classes.countdownTimerDigisHolder}>
                        <Box className={classes.countdownTimerDigitHolder}>
                            <div className={classes.countdownTimerDigitText}>{timeDiff.charAt(8)}</div>
                        </Box>
                        <Box className={classes.countdownTimerDigitHolder}>
                            <div className={classes.countdownTimerDigitText}>{timeDiff.charAt(9)}</div>
                        </Box>
                    </Box>
                    <div className={classes.countdownTimerPeriod}>{t('minutes')}</div>
                </Box>


                <Box className={classes.countdownTimerSectionHolder}>
                    <Box className={classes.countdownTimerDigisHolder}>
                        <Box className={classes.countdownTimerDigitHolder}>
                            <div className={classes.countdownTimerDigitText}>{timeDiff.charAt(12)}</div>
                        </Box>
                        <Box className={classes.countdownTimerDigitHolder}>
                            <div className={classes.countdownTimerDigitText}>{timeDiff.charAt(13)}</div>
                        </Box>
                    </Box>
                    <div className={classes.countdownTimerPeriod}>{t('seconds')}</div>
                </Box>


            </Box>
        </Box>

    )
}

export default Countdown