import {createMuiTheme} from "@material-ui/core";

const defaultTheme = createMuiTheme();
export const theme = createMuiTheme({
    overrides: {
        MuiTextField: {
            root: {}
        },
        MuiInputBase: {},
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: '#D8D6DE'
            }
        },
        MuiContainer: {
            maxWidthLg: {
                [defaultTheme.breakpoints.up('xs')]: {
                    maxWidth: '1920px'
                },
                paddingLeft: 0,
                paddingRight: 0,
                maxWidth: '1920px'
            },
            root: {
                paddingLeft: 0,
                paddingRight: 0
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none'
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)'
            }
        },
        MuiFormLabel: {
            root: {
                fontWeight: 'bold',
                marginBottom: 10
            }
        },
        MuiCheckbox: {
            colorSecondary: {
                '&$checked': {
                    color: defaultTheme.palette.primary.main,
                },
            },
            root: {
                '&$checked': {
                    color: defaultTheme.palette.primary.main,
                },
            }
        },
        MuiTab: {
            wrapper: {
                justifyContent: 'flex-start',
                flexDirection: 'row',
                '& > *:first-child': {
                    marginBottom: '0 !important',
                    marginRight: 6
                }
            },
            labelIcon: {
                height: 38,
                minHeight: 38,
            },
            root: {
                height: 38,
                minHeight: 38,
                textTransform: 'none',
                "@media (min-width: 600px)": {
                    minWidth: 'unset'
                }
            },
            textColorPrimary: {
              '&$selected': {
                  background: '#46d98a',
                  color: 'white',
                  borderRadius: 6,
                  paddingTop: 0,
                  paddingBottom: 0
              }
            },
            textColorSecondary: {
                '&$selected': {
                    background: '#82868B',
                    color: 'white',
                    borderRadius: 6,
                    paddingTop: 0,
                    paddingBottom: 0
                }
            },
        },
        MuiTabs: {
            root: {
                height: 38,
                minHeight: 38,
            },
            indicator: {
                display: 'none'
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 28
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: 18
            }
        },
        MuiBreadcrumbs: {
            root: {
                marginTop: defaultTheme.spacing(4),
                marginBottom: defaultTheme.spacing(4),
            }
        },
        MuiAccordion: {
            root: {
                boxShadow: 'none'
            }
        }
    },
    palette: {
        primary: {
            main: '#46d98a',
            contrastText: '#fff'
        },
        secondary: {
            main: '#82868B',
        },
        success: {
            main: '#28C76F',
            contrastText: 'white'
        },
        error: {
            main: '#EA5455',
        },
        warning: {
            main: '#FF9F43'
        },
        info: {
            main: '#00CFE8'
        },
        text: {
            primary: '#6e6b7b',
            disabled: '#B9B9C3'
        }
    },
    typography: {
        body1: {
            fontSize: 14,
            color: '#6E6B7B',
            fontWeight: 'normal',
        },
        h1: {
            fontSize: 28,
            color: '#5E5873',
            fontWeight: 500
        },
        h2: {
            fontSize: 24,
            color: '#5E5873',
            fontWeight: 500
        },
        h3: {
            fontSize: 21,
            color: '#5E5873',
            fontWeight: 500
        },
        h4: {
            fontSize: 18,
            color: '#5E5873',
            fontWeight: 500
        },
        h5: {
            fontSize: 15,
            color: '#5E5873',
            fontWeight: 500
        },
        h6: {
            fontSize: 14,
            color: '#5E5873',
            fontWeight: 500
        },
        subtitle2: {
            color: '#B9B9C3',
            fontSize: 12
        }
    },
});
