import {LOGOUT, SET_TUTORIAL_ACTIVE} from "../actions/actionTypes";

export interface CommonState {
    isTutorialActive: boolean;
}

const initialState: CommonState = {
    isTutorialActive: false
};

const commonReducer = (state = initialState, action: any): CommonState => {
    switch (action.type) {
        case SET_TUTORIAL_ACTIVE:
            return {
                ...state,
                isTutorialActive: action.payload
            };
        case LOGOUT:
            return {
                ...state,
            };
    }
    return state;
};

export default commonReducer;

