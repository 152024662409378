import React, {CSSProperties, useState} from 'react'
import UserModel from "../../../models/user.model"
import {Box, Card, CircularProgress, Grid} from "@material-ui/core"
import CloudinaryService from "../../../services/cloudinary.service"
import MediaModel from "../../../models/media.model"
import DeleteIcon from '@material-ui/icons/Delete';
import BackupIcon from '@material-ui/icons/Backup';
import _ from 'lodash'
import {getHighlightVideos} from "../../../util/profile.util";
import EButton from "../../common/EButton";
import PreviewVideoPopup from "../../common/PreviewVideoPopup/PreviewVideoPopup";

interface Props {
    user?: UserModel,
    loading?: boolean,
    setUser: (user: UserModel) => void,
}

const VideoTab = (props: Props) => {

    //region State

    const highlights = getHighlightVideos(props.user)

    const [videoPreviewOpen, setVideoPreviewOpen] = useState(false)
    const [currentVideo, setCurrentVideo] = useState<MediaModel>()

    //endregion State

    //region Handlers

    const handleThumbnailClick = (video: MediaModel) => {
        setVideoPreviewOpen(true)
        setCurrentVideo(video)
    }

    const handleDeleteVideo = (index: number) => {
        if (!props.user) return

        const updatedUser = _.cloneDeep(props.user)
        updatedUser.profile?.highlightVideos?.splice(index, 1)
        props.setUser(updatedUser)
    }

    //endregion Handlers

    //region UI

    const styles = {
        thumbnailHolder: {
            position: 'relative',
            width: '300px',
            height: '200px',
            borderRadius: '6px',
            overflow: 'hidden',
            cursor: 'pointer',
            padding: '1px'
        } as CSSProperties,
        thumbnail: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            objectFit: 'cover'
        } as CSSProperties,
        playButton: {
            width: 30,
            height: 30,
            position: "absolute",
            left: 'calc(50% - 15px)',
            top: 'calc(50% - 15px)',
        } as CSSProperties,
        deleteButtons: {
            position: 'absolute',
            top: 0,
            width: '100%'
        } as CSSProperties
    }

    const renderVideos = () => {
        if (!props.user) return null
        return (
            <Box p={3}>
                <Grid container spacing={3}>
                    {
                        highlights.map((video, index) => {
                            return (
                                <Grid item key={video.url}>
                                    <Box
                                        style={styles.thumbnailHolder}
                                        onClick={_ => handleThumbnailClick(video)}
                                    >
                                        <img
                                            style={styles.thumbnail}
                                            alt={'Video thumbnail'}
                                            src={CloudinaryService.getVideoPreviewUrl(video?.url)}
                                        />

                                        <img
                                            style={styles.playButton}
                                            alt={'Play video'}
                                            src={'/icons/play-video.svg'}
                                        />

                                        <Box display={'flex'} style={styles.deleteButtons}>
                                            {/*{*/}
                                            {/*    index > 0 &&*/}
                                            {/*    <Button*/}
                                            {/*        variant={'contained'}*/}
                                            {/*        color={"primary"}*/}
                                            {/*    >*/}
                                            {/*        <ChevronLeftIcon/>*/}
                                            {/*    </Button>*/}
                                            {/*}*/}

                                            {/*{*/}
                                            {/*    index < highlights.length - 1 &&*/}
                                            {/*    <Button*/}
                                            {/*        variant={'contained'}*/}
                                            {/*        color={"primary"}*/}
                                            {/*    >*/}
                                            {/*        <ChevronRightIcon/>*/}
                                            {/*    </Button>*/}
                                            {/*}*/}

                                            <EButton
                                                variant={'contained'}
                                                color={"error"}
                                                style={{marginLeft: 'auto'}}
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    handleDeleteVideo(index)
                                                }}
                                            >
                                                <DeleteIcon/>
                                            </EButton>

                                        </Box>

                                    </Box>
                                </Grid>
                            )
                        })
                    }

                    <Grid item key={'upload'}>
                        <Box
                            style={styles.thumbnailHolder}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <label htmlFor='clubLogo' style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                height: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                border: '1px dashed grey'
                            }}
                                   onClick={async () => {
                                       const response = await CloudinaryService.uploadMedia({resourceType: 'video'})
                                       const updatedUser = _.cloneDeep(props.user)
                                       updatedUser!.profile!.highlightVideos = [
                                           ...(updatedUser!.profile?.highlightVideos || []),
                                           {
                                               publicId: response.public_id,
                                               url: response.secure_url
                                           }
                                       ]
                                       props.setUser(updatedUser!)
                                   }}
                            >
                                <BackupIcon style={{fontSize: 40, marginBottom: 10}}/>
                                <div>Add new video</div>
                            </label>
                            {/*<input*/}
                            {/*    type='file' id='clubLogo' style={{*/}
                            {/*    visibility: 'hidden',*/}
                            {/*    width: 1,*/}
                            {/*    height: 1,*/}
                            {/*}}*/}
                            {/*    accept="video/mp4, application/x-mpegURL, video/3gpp, video/x-ms-wmv, video/x-msvideo, video/quicktime"*/}
                            {/*    onChange={onChange}/>*/}

                        </Box>
                    </Grid>

                </Grid>

                <PreviewVideoPopup
                    open={videoPreviewOpen}
                    video={currentVideo?.url}
                    onClose={() => setVideoPreviewOpen(false)}
                />

            </Box>
        )
    }

    const renderLoaders = () => {
        return (
            <Card>
                <CircularProgress/>
            </Card>
        )
    }

    return props.loading ? renderLoaders() : renderVideos()

    //endregion UI

}

export default VideoTab
