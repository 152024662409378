import React, {FunctionComponent, useEffect} from 'react';
import Dialog from "@material-ui/core/Dialog";
import {makeStyles} from "@material-ui/core/styles";
import ClubModel from "../../../models/club.model";
import MixpanelService from "../../../services/analytics/mixpanel.service";
import {ScreenName} from "../../../services/analytics/screenName";
import {EventProperty} from "../../../services/analytics/eventProperty";
import Pricing from "../../landing/Pricing.component";
import {SET_SUBSCRIBING_TO_AMATEUR, SET_SUBSCRIBING_TO_PRO_TRIAL_IN_PROGRESS} from "../../../store/actions/actionTypes";
import {useStore} from "react-redux";
import {Box} from "@material-ui/core";
import {PremiumPopupTrigger} from "../../../store/reducers/billing.reducer";

interface Props {
    open: boolean,
    onClose: () => void,
    club?: ClubModel,
    trigger?: PremiumPopupTrigger
}

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: '0 !important',
        width: '100%',
        padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
        height: '100%',
        [theme.breakpoints.down('md')]: {
            padding: 0
        }
        // overflow: 'hidden'
    },
    pricing: {
        marginTop: '0 !important'
    },
    closeDialog: {
        position: 'absolute',
        right: 4,
        top: 12,
        width: 40,
        height: 40,
        background: '#f5f5f5',
        padding: 10,
        borderRadius: 100,
        zIndex: 150,
        cursor: 'pointer'
    },
    paper: {
        // 'margin': '10px',
        // 'width': 'calc(100% - 20px)',
        // 'max-width': 'calc(100% - 20px)',
        // 'height': 'calc(100% - 20px)',
        // 'max-height': 'calc(100% - 20px)',
        justifyContent: 'center',
        position: 'relative',
        // overflow: 'hidden',
        [theme.breakpoints.down('lg')]: {
            'margin': '10px',
            'width': 'calc(100% - 20px)',
            'max-width': 'calc(100% - 20px)',
            'height': 'calc(100% - 20px)',
            'max-height': 'calc(100% - 20px)',
        },
        [theme.breakpoints.down('md')]: {
            'margin': 0,
            'width': '100%',
            'max-width': '100%',
            'height': '100%',
            'max-height': '100%'
        }
    },
}));

const SubscribePopup: FunctionComponent<Props> = (props: Props) => {

    const store = useStore()
    const classes = useStyles()

    useEffect(() => {
        if (props.open) {
            MixpanelService.trackScreenVisit(ScreenName.PRICING_POPUP, {
                [EventProperty.TRIGGER]: props.trigger
            });
        }
    }, [props.open]);

    return (
        <React.Fragment>
            <Dialog
                aria-labelledby="simple-dialog-title"
                maxWidth={false}
                fullWidth={true}
                // style={{overflow: 'hidden'}}
                classes={{paper: classes.paper}}
                {...props}
            >

                <Box
                    className={classes.container}
                    // display={'flex'}
                    // alignItems={'center'}
                >
                    <Pricing
                        onPackageSelected={async (stripeProduct, _price) => {
                            if (stripeProduct.metadata.package === 'PRO') {
                                store.dispatch({type: SET_SUBSCRIBING_TO_PRO_TRIAL_IN_PROGRESS, payload: true})
                            } else if (stripeProduct.metadata.package === 'AMATEUR') {
                                store.dispatch({type: SET_SUBSCRIBING_TO_AMATEUR, payload: true})
                            }
                            props.onClose();
                        }}
                        showTitle={false}
                        extraClass={classes.pricing}
                    />
                </Box>

                <img alt={'Close'} src={require('../../../assets/images/x-primary.svg').default}
                     className={classes.closeDialog} onClick={props.onClose}/>

            </Dialog>

        </React.Fragment>
    )
};

export default SubscribePopup;
