import UserModel from "./user.model";
import Job from "./job.model";

export enum ApplicationRelevance {
    HIGHLY_RELEVANT = "HIGHLY_RELEVANT",
    NOT_RELEVANT = "NOT_RELEVANT",
    RELEVANT = "RELEVANT",
    UNGRADED = "UNGRADED",
}

export enum ApplicationStatus {
    CREATED = "CREATED",
    INVITED = "INVITED",
    REJECTED = "REJECTED",
    SHORTLISTED = "SHORTLISTED",
    SKIPPED = "SKIPPED",
}

export default interface OpportunityApplicationModel {
    id?: number;
    createdBy?: UserModel;
    status?: ApplicationStatus;
    score?: number;
    relevance?: ApplicationRelevance;
    deleted?: boolean;
    createdAt?: Date;
    contacted?: boolean;
    opportunity?: Job
}
