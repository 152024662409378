import React from 'react'
import {Box, Grid, Hidden} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import AnimatedBox from "./Animated.component";
import {DIMENSIONS, LANDING_COMMON_COLORS} from "./common";
import {useTranslation} from "react-i18next";

const useStyle = makeStyles(_theme => ({
    component: {
        marginTop: 200,
        width: '100%',
        position: 'relative',
        [_theme.breakpoints.down('md')]: {
            marginTop: 100
        }
    },
    background: {
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: -1,
        top: -100,
        width: '100%',
    },
    title: {
        fontSize: DIMENSIONS.LARGE.TITLE_SIZE,
        color: LANDING_COMMON_COLORS.TITLE,
        marginBottom: 105,
        textAlign: "center",
        paddingLeft: 25,
        paddingRight: 25,
        [_theme.breakpoints.down('md')]: {
            fontSize: DIMENSIONS.SMALL.TITLE_SIZE,
            marginBottom: 40
        }
    },
    infoBox: {
        padding: DIMENSIONS.LARGE.INFO_BOX_PADDING,
        width: 270,
        marginBottom: 80,
        '&:hover': {
            '& $infoIcon': {
                filter: 'invert(42%) sepia(0%) saturate(564%) hue-rotate(181deg) brightness(91%) contrast(87%)',
            }
        },
        [_theme.breakpoints.down('md')]: {
            padding: DIMENSIONS.SMALL.INFO_BOX_PADDING
        },
        [_theme.breakpoints.down('sm')]: {
            marginBottom: 10
        }
    },
    infoBoxContent: {
        flex: 1,
        [_theme.breakpoints.down('sm')]: {
            alignItems: 'center'
        }
    },
    mainImageHolder: {
        flex: 1,
        order: 2,
        [_theme.breakpoints.down('sm')]: {
            flex: 'unset',
            order: 1,
            width: 'calc(100% + 100px)',
            marginLeft: -50,
            marginRight: -50
        }
    },
    infoBoxesLeft: {
        order: 1,
        alignItems: 'flex-end',
        [_theme.breakpoints.down('sm')]: {
            order: 2,
            width: '100%',
            alignItems: 'center'
        }
    },
    infoBoxesRight: {
        order: 3,
        alignItems: 'flex-start',
        [_theme.breakpoints.down('sm')]: {
            order: 3,
            width: '100%',
            alignItems: 'center'
        }
    },
    mainImageWrapper: {
        width: '100%',
        maxWidth: 1000,
    },
    mainImage: {
        width: '100%',
        objectFit: 'contain'
    },
    infoTitle: {
        fontWeight: 500,
        fontSize: DIMENSIONS.LARGE.INFO_TITLE_SIZE,
        lineHeight: DIMENSIONS.LARGE.INFO_TITLE_LINE_HEIGHT,
        color: LANDING_COMMON_COLORS.TITLE,
        marginBottom: 7,
        [_theme.breakpoints.down('md')]: {
            fontSize: DIMENSIONS.SMALL.INFO_TITLE_SIZE,
            lineHeight: DIMENSIONS.SMALL.INFO_TITLE_LINE_HEIGHT,
        }
    },
    infoSubtitle: {
        color: LANDING_COMMON_COLORS.BOX_TEXT,
        fontWeight: 300,
        fontSize: DIMENSIONS.LARGE.INFO_MESSAGE_SIZE,
        lineHeight: DIMENSIONS.LARGE.INFO_MESSAGE_LINE_HEIGHT,
        [_theme.breakpoints.down('md')]: {
            fontSize: DIMENSIONS.SMALL.INFO_MESSAGE_SIZE,
            lineHeight: DIMENSIONS.SMALL.INFO_MESSAGE_LINE_HEIGHT,
        }
    },
    infoIconMobile: {
        width: 50,
        height: 50,
        marginBottom: 12
    },
    infoBoxLeft: {
        marginRight: 20,
        alignItems: 'flex-end',
        '& $infoTitle': {
            textAlign: 'right'
        },
        '& $infoSubtitle': {
            textAlign: 'right'
        },
        [_theme.breakpoints.down('sm')]: {
            marginRight: 0,
            alignItems: 'center',
            '& $infoTitle': {
                textAlign: 'center'
            },
            '& $infoSubtitle': {
                textAlign: 'center'
            },
        }
    },
    infoBoxRight: {
        marginLeft: 20,
        alignItems: 'flex-start',
        '& $infoTitle': {
            textAlign: 'left'
        },
        '& $infoSubtitle': {
            textAlign: 'left'
        },
        [_theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            alignItems: 'center',
            '& $infoTitle': {
                textAlign: 'center'
            },
            '& $infoSubtitle': {
                textAlign: 'center'
            },
        }
    }
}))

const ForPlayers = () => {

    const classes = useStyle()
    const t = useTranslation().t

    const renderInfo = (icon: any, title: string, subtitle: string, left: boolean) => {
        return (
            <AnimatedBox
                display={'flex'}
                alignItems={'flex-start'}
                className={classes.infoBox}

                hover
                fadeIn
                fadeInThreshold={150}
            >
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    className={clsx(left ? classes.infoBoxLeft : classes.infoBoxRight, classes.infoBoxContent)}
                    style={{order: left ? 1 : 2}}
                >
                    <Hidden mdUp>
                        <img className={classes.infoIconMobile} src={icon}/>
                    </Hidden>
                    <div className={classes.infoTitle}>{title}</div>
                    <div className={classes.infoSubtitle}>{subtitle}</div>
                </Box>

                <Hidden smDown>
                    <img style={{order: left ? 2 : 1}} src={icon}/>
                </Hidden>
            </AnimatedBox>
        )
    }

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={classes.component}
        >
            <img
                src={require('../../assets/images/landing/content-background-3.svg').default}
                className={classes.background}
            />

            <div className={classes.title}>
                {t("For Agents")}
            </div>

            <a id={'for_players'}/>

            <Box
                display={'flex'}
                alignItems={'center'}
                width={'100%'}
                flexWrap={'wrap'}
                maxWidth={'1600px'}
            >

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    className={classes.infoBoxesLeft}
                >
                    {renderInfo(require('../../assets/images/landing/for-players-worldwide.svg').default, t('Wordlwide Jobs'), t('Enjoy thousands of worldwide opportunities'), true)}
                    {renderInfo(require('../../assets/images/landing/for-players-pro.svg').default, t('PRO Clubs'), t('Be seen by professional clubs'), true)}
                    {renderInfo(require('../../assets/images/landing/for-players-alert.svg').default, t('Up to date'), t('Get notification each time there is a job for you'), true)}
                </Box>

                <AnimatedBox
                    className={classes.mainImageHolder}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    fadeIn
                >
                    <picture className={classes.mainImageWrapper}>
                        <source
                            srcSet={require('../../assets/images/landing/for-players.webp').default}
                            className={classes.mainImage}
                        />
                        <img
                            alt={'Mobile app screenshots'}
                            className={classes.mainImage}
                            src={require('../../assets/images/landing/for-players.png').default}
                        />
                    </picture>
                </AnimatedBox>


                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-start'}
                    className={classes.infoBoxesRight}
                >
                    {renderInfo(require('../../assets/images/landing/for-players-cv.svg').default, t('Football CV'), t('Create your digital football CVs for your players'), false)}
                    {renderInfo(require('../../assets/images/landing/for-players-fast.svg').default, t('Easy Application'), t('Apply with ONE CLICK for international and national jobs'), false)}
                    {renderInfo(require('../../assets/images/landing/for-players-direct.svg').default, t('Increase visibility'), t('Increase your base of clubs to which you can offer your players'), false)}
                </Box>

            </Box>

            <Grid container justify={'center'} spacing={7}>

                <Grid item>
                    <a href={'https://apps.apple.com/us/app/playerhunter/id1151813385'} target={'_blank'}>
                        <AnimatedBox
                            fadeIn
                            fadeInThreshold={0}
                        >
                            <img src={require('../../assets/images/landing/app-store.svg').default}/>
                        </AnimatedBox>
                    </a>
                </Grid>

                <Grid item>
                    <a href={'https://play.google.com/store/apps/details?id=com.playerhunter&hl=en'} target={'_blank'}>
                        <AnimatedBox
                            fadeIn
                        >
                            <img src={require('../../assets/images/landing/google-play.svg').default}/>
                        </AnimatedBox>
                    </a>
                </Grid>

            </Grid>

        </Box>
    )
}

export default ForPlayers
