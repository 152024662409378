import React from 'react'
import {Box, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {format} from "date-fns";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";

const useStyle = makeStyles(_theme => ({
    component: {
        marginTop: 200,
        width: '100%',
        position: 'relative'
    },
    background: {
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: 0,
        bottom: 0,
        width: '100%',
        minHeight: 'calc(100% + 100px)',
        objectFit: "cover",
        maxHeight: 350
    },
    footerContent: {
        maxWidth: '100%',
        zIndex: 1
    },
    contentBox: {
        width: 'fit-content',
        marginLeft: "auto",
        marginRight: "auto",
        [_theme.breakpoints.down('md')]: {
            width: 300
        }
    },
    logo: {
        width: 184,
        height: 39
    },
    moto: {
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '21px',
        color: '#FFF',
        marginTop: 12,
        marginBottom: 22
    },
    socialIconHolder: {
        width: 50,
        height: 50,
        marginRight: 12
    },
    socialIcon: {
        width: 50,
        height: 50,
    },
    lineSeparator: {
        width: '100%',
        height: 1,
        background: '#5C5C5C',
        marginTop: 50
    },
    copyright: {
        fontSize: 14,
        color: '#FFF'
    },
    sectionInfoTitle: {
        fontSize: 18,
        fontWeight: 500,
        color: '#FFF',
        marginBottom: 14
    },
    contactInfoRow: {
        marginBottom: 14
    },
    contentInfoText: {
        marginLeft: 9,
        fontSize: 14,
        color: '#FFF'
    },
    legalLink: {
        fontSize: 14,
        color: '#FFF',
        marginBottom: 14
    },
    languageChange: {
        fontSize: 14,
        color: '#FFF',
        marginBottom: 14,
        cursor: 'pointer'
    }
}))

const Footer = () => {

    const classes = useStyle()

    // const i18n = useTranslation();

    const t = useTranslation().t

    // const changeLanguage = async (languageCode: string) => {
    //     i18n.i18n.changeLanguage(languageCode);
    // };

    const history = useHistory()


    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            className={classes.component}
            width={'100%'}
        >
            <img
                alt={''}
                src={require('../../assets/images/landing/content-background-4.svg').default}
                className={classes.background}
            />

            <Grid
                container
                justify={'center'}
                spacing={10}
                className={classes.footerContent}
            >

                <Grid item xs={12} md={6} lg={4}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        className={classes.contentBox}
                    >
                        <img alt={'Playerhunter logo'} className={classes.logo} src={require('../../assets/images/icons/logo-white-normal.svg').default}/>
                        <div className={classes.moto}>We Match. You Play.</div>
                        <Box display={'flex'}>
                            <a
                                href="https://www.facebook.com/playerhunterworld"
                                target="_blank"
                                className={classes.socialIconHolder}
                                rel={'noopener noreferrer'}
                            >
                                <img alt={'Facebook logo'} src={require('../../assets/images/landing/social-facebook.svg').default}
                                     className={classes.socialIcon}/>
                            </a>

                            <a
                                href="https://www.linkedin.com/company/playerhunter"
                                target="_blank"
                                className={classes.socialIconHolder}
                                rel={'noopener noreferrer'}
                            >
                                <img alt={'Linkedin logo'} src={require('../../assets/images/landing/social-linkedin.svg').default}
                                     className={classes.socialIcon}/>
                            </a>

                            <a
                                href="https://www.instagram.com/playerhunterworld/"
                                target="_blank"
                                rel={'noopener noreferrer'}
                                className={classes.socialIconHolder}
                            >
                                <img alt={'Instagram logo'} src={require('../../assets/images/landing/social-instagram.svg').default}
                                     className={classes.socialIcon}/>
                            </a>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        className={classes.contentBox}
                    >
                        <div className={classes.sectionInfoTitle}>{t("Contact Info")}</div>

                        <Box className={classes.contactInfoRow} display={'flex'}>
                            <img alt={''} src={require('../../assets/images/landing/footer-map-marker.svg').default}/>
                            <div className={classes.contentInfoText}>Wattgasse 48</div>
                        </Box>

                        <Box className={classes.contactInfoRow} display={'flex'}>
                            <img alt={''} src={require('../../assets/images/landing/footer-pin.svg').default}/>
                            <div className={classes.contentInfoText}>A-1170 Wien, Austria</div>
                        </Box>

                        <Box className={classes.contactInfoRow} display={'flex'}>
                            <img alt={''} src={require('../../assets/images/landing/footer-email.svg').default}/>
                            <a href={'mailto:support@playerhunter.com'}
                               className={classes.contentInfoText}>support@playerhunter.com</a>
                        </Box>

                        <Box className={classes.contactInfoRow} display={'flex'}>
                            <img alt={''} src={require('../../assets/images/landing/footer-phone.svg').default}/>
                            <a href={'tel:+436645154196'} className={classes.contentInfoText}> +43 664 5154196</a>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        className={classes.contentBox}
                    >
                        <div className={classes.sectionInfoTitle}>{t("Contact Info")}</div>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Box onClick={()=> history.push(`/privacy`)}><a className={classes.legalLink}>{t("Privacy Policy")}</a></Box>
                            <Box onClick={()=>history.push(`/terms-of-services`)}><a  className={classes.legalLink}>{t("Terms of Service")}</a></Box>
                            <Box onClick={()=> history.push(`/imprint`)}><a className={classes.legalLink}>{t("Impressum")}</a></Box>
                            {/*<Box onClick={()=> history.push(`/team`)}><p className={classes.legalLink}>{t("Team")}</p></Box>*/}
                        </Box>
                    </Box>
                </Grid>

                {/*<Grid item xs={12} md={6} lg={3}>*/}
                {/*    <Box*/}
                {/*        display={'flex'}*/}
                {/*        flexDirection={'column'}*/}
                {/*        className={classes.contentBox}*/}
                {/*    >*/}
                {/*        <div className={classes.sectionInfoTitle}>{t("Language")}</div>*/}
                {/*        <Box display={'flex'} flexDirection={'column'}>*/}
                {/*            <div className={classes.languageChange} onClick={() => changeLanguage('en')}>{t("English")}</div>*/}
                {/*            <div className={classes.languageChange} onClick={() => changeLanguage('de')}>{t("Deutsch")}</div>*/}
                {/*            <div className={classes.languageChange} onClick={() => changeLanguage('sr')}>{t("Srpski")}</div>*/}
                {/*        </Box>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}

            </Grid>

            <div className={classes.lineSeparator}/>

            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                minHeight={'68px'}
                width={'100%'}
            >
                <div className={classes.copyright}>Playerhunter @ {format(new Date(), 'yyyy')}</div>
            </Box>


        </Box>
    )
}

export default Footer
