import React, {useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {setMultipleParams, useUrlParam} from "../../../../util/data/url.util";
import {useHistory} from "react-router";

const UnresolvedAdminsFilter = () => {

    //region State

    const [paramValue] = useUrlParam({param: 'unresolvedAdmins', dataType: 'string', defaultValue: ''})
    const [expanded, setExpanded] = useState(paramValue === 'true')
    const history = useHistory()

    //endregion State

    //region UI

    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="unresolvedAdmins-content"
                id="unresolvedAdmins-header"
            >
                <Typography>Only with unresolved admins {paramValue !== '' && `(${paramValue === 'true' ? 'yes' : 'no, show all'})`}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="unresolvedAdmins-content"
                        name="verification_foot"
                        value={paramValue}
                        onChange={e => {
                            setMultipleParams([
                                {name: 'unresolvedAdmins', value: e.target.value || '', dataType: 'string'},
                                {name: 'listPage', value: 1, dataType: 'numeric'}
                            ], history)
                        }}
                    >
                        <FormControlLabel value={"true"} control={<Radio/>} label="Yes, show only with unresolved admins"/>
                        <FormControlLabel value={"false"} control={<Radio/>} label="No, show all"/>
                    </RadioGroup>
                </FormControl>
            </AccordionDetails>

        </Accordion>
    )

    //endregion UI
}

export default UnresolvedAdminsFilter