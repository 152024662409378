import React, {useState} from 'react';
import {Box, CircularProgress, Grid} from "@material-ui/core";
import InfoBox from "./InfoBox.component";
import {useTranslation} from "react-i18next";
import EButton from "../common/EButton";
import ETextField from "../common/ETextField.component";
import {makeStyles} from "@material-ui/core/styles";
import {getGridSizes} from "../common/grid.util";
import Job from "../../models/job.model";
import OpportunityService from "../../services/opportunity.service";
import {
    getOpportunityAgeFrom,
    getOpportunityAgeTo,
    getOpportunityClubName,
    getOpportunityGender,
    getOpportunityLevel,
    getOpportunityLevelFrom,
    getOpportunityLevelTo,
    getOpportunityLocationWithRadius,
    getOpportunityPositionName,
    getOpportunityPreferredFoot
} from "../../util/opportunity.util";

const useStyles = makeStyles(theme => ({

    userInput: {
        width: '100%',
    },
    userIdInput: {
        width: '200px',
        marginBottom: '0 !important',
        marginTop: '0 !important',
    },
    loadUserButton: {
        width: '150px',
        marginLeft: '20px !important',
        height: 'fit-content !important',
    },
    userInfoBoxesHolder: {
        width: 'calc(100% + 20px)',
        marginLeft: '-10px',
    },
    visibilityCTA: {
        // marginLeft: 'auto !important',
    },
    currentUserName: {
        marginRight: '10px',
        cursor: 'pointer',
    },
}));

interface Props {
    job?: Job;
    onJobLoaded: (job?: Job) => void;
}

const JobAnalyzer = (props: Props) => {

    const [jobId, setJobId] = useState<string>();
    const [fetchingJob, setFetchingJob] = useState(false);
    const [expanded, setExpanded] = useState(true);

    const t = useTranslation().t;
    const classes = useStyles()

    const fetchJob = async () => {
        props.onJobLoaded(undefined);
        const id = jobId && parseInt(jobId);
        if (id && !isNaN(id)) {
            try {
                setFetchingJob(true);
                const response = await OpportunityService.getOpportunity(id)
                props.onJobLoaded(response);
                setExpanded(true);
            } finally {
                setFetchingJob(false);
            }
        }
    };

    const openUserHandler = () => {
        if (props.job) {
            window.open(`${process.env.REACT_APP_WEB_URL}/admin/user/${props.job.id}`, '_blank')
        }
    };

    const renderJobInfo = (job?: Job) => {
        if (fetchingJob) {
            return <CircularProgress/>;
        }

        if (!job || !expanded) return null;

        return (
            <Box
                display={'flex'}
                flexWrap={'wrap'}
                justifyContent={'left'}
                className={classes.userInfoBoxesHolder}>

                <InfoBox
                    title='id'
                    value={job.id || '-'}
                />

                <InfoBox
                    title='club'
                    value={getOpportunityClubName(job) || '-'}
                />

                <InfoBox
                    title='positions'
                    value={getOpportunityPositionName(job, '-')}
                />

                <InfoBox
                    title='age'
                    value={`${getOpportunityAgeFrom(job)} - ${getOpportunityAgeTo(job)}`}
                    // tooltip={getLevelData(user).tooltip}
                />

                <InfoBox
                    title='level from-to'
                    value={`${getOpportunityLevelFrom(job)} - ${getOpportunityLevelTo(job)}`}
                    // tooltip={getLocationData(user).tooltip}
                />

                <InfoBox
                    title='team level'
                    value={getOpportunityLevel(job)}
                />

                <InfoBox
                    title='gender'
                    value={getOpportunityGender(job, '-')}
                />

                <InfoBox
                    title='preferred foot'
                    value={getOpportunityPreferredFoot(job, '-')}
                />

                <InfoBox
                    title='location'
                    value={getOpportunityLocationWithRadius(t, job, '-')}
                />

            </Box>
        )
    };

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
        >

            <Grid
                container
                alignItems={'center'}
            >

                <ETextField
                    className={classes.userIdInput}
                    onChange={e => setJobId(e)}
                    value={jobId}
                    label={'Job id'}
                    // margin="normal"
                    // variant="outlined"
                    grid={'1/3'}
                />

                <Grid item {...getGridSizes('1/3')}>
                    <EButton variant="contained" color="primary"
                             className={classes.loadUserButton}
                             onClick={fetchJob}>
                        Load job
                    </EButton>
                </Grid>

                <Grid item  {...getGridSizes('1/3')}>
                    <Box display={'flex'}
                         justifyContent={'center'}
                         className={classes.visibilityCTA}
                         alignItems={'center'}
                    >

                        <div className={classes.currentUserName} onClick={openUserHandler}>
                            {props.job ? getOpportunityClubName(props.job) : 'No job selected'}
                        </div>

                        {
                            props.job && <EButton
                                variant="contained"
                                color="primary"
                                onClick={() => setExpanded(!expanded)}>
                                {expanded ? 'Hide details' : 'Show details'}
                            </EButton>
                        }

                    </Box>
                </Grid>

            </Grid>

            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                {renderJobInfo(props.job)}
            </Box>

        </Box>
    )
};

export default JobAnalyzer;
